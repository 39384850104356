import api from "../../utils/api";
import { GET_MASTER_MENU_DETAILS, GET_POST_ACCOUNT_LOGIN } from "./constants";

/**
 * Function for getting some data
 */
export const fetchMasterMenuData = (lookUp: any) => {
  return api.get(`${GET_MASTER_MENU_DETAILS}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchLoginData = () => {
  return api.post(`${GET_POST_ACCOUNT_LOGIN}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};
