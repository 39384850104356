import Typography from "@material-ui/core/Typography";
import {
  Button,
  CircularProgress,
  Snackbar,
  InputLabel,
  Tooltip,
  TableHead,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import styled, { css } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommentIcon from "@material-ui/icons/Comment";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import {
  PRIMARY_BUTTON_BG,
  TOASTER_ERROR,
  TOASTER_SUCCESS,
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  BLACK_COLOR,
  DARK_GRAY,
  WHITE_COLOR,
  UNICEF_BLUE,
  UNICEF_GREEN,
  DARK_GRAY_BG,
  BAR_FILLED,
  LIGHT_GRAY,
  RED_COLOR,
  PRIMARY_BUTTON_COLOR,
} from "../constants/color";
import UnicefBlueImage from "./images/UNICEF_Logo_Light.png";
import { FormControlLabel } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormControl from "@material-ui/core/FormControl";
import SendIcon from "@material-ui/icons/Send";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const FullWidthContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const PageWrapper = styled.div`
  padding: 10px 25px;
`;

export const FormWrapper = styled.div`
  padding: 15px;
`;

export const FormToolbar = styled.div`
  padding: 10px 0;
`;

export const SubHeaderWrapper = styled.div`
  padding: 10px 0;
`;

export const PrimaryButton = css`
    background: ${PRIMARY_BUTTON_BG};
    color: ${SECONDARY_COLOR}
    &:hover {
        background: ${PRIMARY_BUTTON_BG};
    }
`;

export const Close: any = styled(CloseIcon)`
  && {
    font-size: 16px;
    color: #fff;
  }
`;

export const ComponentWrapper = styled.div`
  padding: 0 10px 10px 10px;
  position: relative;
  .attachment {
    max-height: 200px;
    overflow-y: auto;
  }
`;

export const FieldProgress: any = styled(CircularProgress)`
  && {
    position: absolute;
    top: ${(props: any) => (props.isContact ? "none" : "50px")};
    right: ${(props: any) => (props.isContact ? "20px" : "20px")};
    z-index: 11;
    color: #1c3342;
    bottom: ${(props: any) => (props.isContact ? "20px" : 0)};
  }
`;

export const FieldProgressDelete: any = styled(CircularProgress)`
  && {
    position: absolute;
    top: 18px;
    right: 10px;
    z-index: 11;
    color: #1c3342;
    bottom: 0;
  }
`;

export const LoaderContainer: any = styled.div`
  && {
    display: ${(props: any) => (props.visible ? "block" : "none")};
  }
`;

export const OverlayContainer: any = styled.div`
  && {
    width: 100%;
    height: 100%;
    background: #333;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 999999;
  }
`;

export const PageProgress = styled(CircularProgress)`
  && {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    color: #1c3342;
    animation: none !important;
  }
`;

export const SectionPageProgress = styled(PageProgress)`
  && {
    position: absolute;
  }
`;

export const StyledButton: any = styled(Button)`
  && {
    border-radius: 0;
    margin-right: 10px;
    ${PrimaryButton}
  }
`;

export const StyledToaster: any = styled(Snackbar)`
  && {
    > div {
      color: #fff;
      line-height: 36px;
      max-width: none;
      .check-error {
        position: relative;
        .err-icon {
          position: absolute;
          top: 5px;
          left: -6px;
        }
        .msg {
          margin-left: 24px;
        }
      }
      button.close {
        top: 8px;
        position: absolute;
        right: 0;
      }
    }
    @media only screen and (max-width: 420px) {
      width: 90%;
    }
  }
`;

export const StyledToasterError: any = styled(StyledToaster)`
  && {
    > div {
      background: ${TOASTER_ERROR};
      background-color: ${TOASTER_ERROR};
    }
  }
`;

export const StyledToasterSuccess: any = styled(StyledToaster)`
  && {
    > div {
      background: ${TOASTER_SUCCESS};
      background-color: ${TOASTER_SUCCESS};
    }
  }
`;

export const styles = (theme: any) => ({
  inputLabel: {
    "&.shrink": {
      fontSize: "18px",
    },
  },
});

export const TabContainer: any = styled.div`
  && {
    display: flex;
    width: 100%;

    .MuiTypography-root.MuiTypography-body1 {
      width: 100%;
    }
  }
`;

export const DragDropContainer: any = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const QuestionListContainer: any = styled.div`
  && {
    flex-grow: 1;
    min-height: 100px;
  }
`;

export const QuestionDataContainer = styled.div`
  && {
    border-bottom: 1px solid #c9c9c9;
    padding: 10px 8px;
    background-color: white;

    @media screen and (max-width: 1080px) {
      .width95 {
        width: 90% !important;
      }
    }
  }
`;

export const WarningLabel = styled.span`
  && {
    color: ${RED_COLOR};
    font-size: 12px !important;
    margin-bottom: 7px;
    display: block;
  }
`;

export const BigContainer = styled.div`
  && {
    min-height: 400px;
  }
`;

export const DialogClose: any = styled(IconButton)`
  && {
    float: right;
    padding: 0;
  }
`;

export const ResponsiveImage: any = styled.img`
  && {
    width: 100%;
    height: auto;
  }
`;

export const CustomTable: any = styled.div`
  && {
    flex-direction: column !important;
    .users-table-row{
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      width: 100% !important;

      .users-table-cell{
        display: flex !important;
        justify-content: flex-start !important;
        border-bottom: 1px solid #e0e0e0;
        width: auto !important;
        box-sizing: border-box !important;
        padding: 10px 10px;
        span{
          font-size: 14px !important;
          text-align: left !important;
        }

        &:nth-child(1){
          width: 780px !important;
        }
        &:nth-child(2){
          width: 100px !important;
        }
        &:nth-child(3){
          width: 100px !important;
        }
        &:nth-child(4){
          width: 131.38px !important;
        }
        &:nth-child(5){
          width: 100px !important;
        }
        &:nth-child(6){
          width: 100px !important;
        }
        &:nth-child(7){
          width: 207.55px !important;
        }

        @media screen and (min-width: 1536px) and (max-width: 1536px){
          &:nth-child(1){
            width: 781px !important;
          }
          &:nth-child(2){
            width: 100px !important;
          }
          &:nth-child(3){
            width: 100px !important;
          }
          &:nth-child(4){
            width: 131.38px !important;
          }
          &:nth-child(5){
            width: 100px !important;
          }
          &:nth-child(6){
            width: 100px !important;
          }
          &:nth-child(7){
            width: 207.55px !important;
          }
        }

        @media screen and (min-width: 1920px) and (max-width: 1920px){
          &:nth-child(1){
            width: 930px !important;
          }
          &:nth-child(2){
            width: 137px !important;
          }
          &:nth-child(3){
            width: 137px !important;
          }
          &:nth-child(4){
            width: 146px !important;
          }
          &:nth-child(5){
            width: 136px !important;
          }
          &:nth-child(6){
            width: 135px !important;
          }
          &:nth-child(7){
            width: 228px !important;
          }
        }
        @media screen and (min-width: 2560px){
          &:nth-child(1){
            width: 937px !important;
          }
          &:nth-child(2){
            width: 138px !important;
          }
          &:nth-child(3){
            width: 138px !important;
          }
          &:nth-child(4){
            width: 149px !important;
          }
          &:nth-child(5){
            width: 138px !important;
          }
          &:nth-child(6){
            width: 135px !important;
          }
          &:nth-child(7){
            width: 235px !important;
          }
        }
      }
    }
  }
`;
export const CustomDevider: any = styled.hr`
  && {
    margin: 0;
    border: 1px solid #f0f0f0 !important;
  }
`;

export const CommonBigCard: any = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 380px;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    .req-label {
      color: #f44336;
    }
    font-size: 14px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
    display: block;
    position: relative;
    font-size: 14px;
  }
`;
export const StyledAutoSelectInputLabel = styled(InputLabel)`
  && {
    padding-left: 5px;
    .req-label {
      color: #f44336;
    }
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    font-size: 14px;
  }
`;
export const VerticalLine = styled.div`
  && {
    width: 1px;
    height: 35px;
    background-color: #fff;
  }
`;

export const CustomNavBar = styled.div`
  && {
    position: absolute;
    top: 0;
    z-index: 1299;
    left: 50%;
    transform: translateX(-50%);
    background: ${PRIMARY_COLOR};
    max-height: 64px;

    header {
      box-shadow: none;
    }
    .MuiTabs-indicator {
      background-color: #fff;
      height: 3px;
    }
    button {
      min-width: auto;
      max-width: none;
      padding: 18px 25px;
      font-size: 1rem;
      font-weight: normal;
    }
    .Menubar {
      display: flex;
      align-items: center;

      .MenuItem {
        padding: 19px 18px;
        font-size: 1rem;
        color: #fff;
        opacity: 0.8;
        cursor: pointer;
        border-bottom: 4px solid transparent;
        text-transform: capitalize;

        &:hover{
          background-color: transparent;
        }
      }
      .ActiveMenuItem {
        border-bottom-color: #fff;
        cursor: auto;
        opacity: 1;
      }
    }
    .SubMenuBlock{
      li{ 
        a{
          color: ${DARK_GRAY};
          font-size: 14px !important;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    @media only screen and (max-width: 1500px) {
      position: relative;
      z-index: 999;
      .Menubar {
        .MenuItem {
          padding: 10px 10px;
        }
      }
    }
  }
`;

export const CustomNavBarSdAdmin = styled.div`
  && {
    padding-right: 0;
    max-height: 64px;
    header {
      box-shadow: none;
    }
    .MuiTabs-indicator {
      background-color: #fff;
      height: 3px;
    }
    button {
      min-width: auto;
      max-width: none;
      padding: 18px 25px;
      font-size: 1rem;
      font-weight: normal;
    }
    .Menubar {
      display: flex;
      align-items: center;

      .MenuItem {
        padding: 19px 10px;
        font-size: 1rem;
        color: #fff;
        opacity: 0.8;
        cursor: pointer;
        border-bottom: 4px solid transparent;
        text-transform: capitalize;

        &:hover{
          background-color: transparent;
        }
      }
      .ActiveMenuItem {
        border-bottom-color: #fff;
        cursor: auto;
        opacity: 1;
      }
    }
    .SubMenuBlock{
      li{ 
        a{
          color: ${DARK_GRAY};
          font-size: 14px !important;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    @media only screen and (max-width: 1280px) {
      position: relative;
      z-index: 999;
      .Menubar {
        .MenuItem {
          padding: 10px 10px;
        }
      }
    }
  }
`;

export const PreLoginContainer = styled.div`
  && {
    h2 {
      margin: 0 0 20px;
      text-align: center;
      font-size: 25px;
      color: ${BLACK_COLOR};
      padding: 20px 80px 0;
    }
    .prelogin-body {
      padding: 0 80px;
      height: 55vh;
      overflow-y: auto;
      p {
        font-size: 15px;
        line-height: 26px;
      }
      span {
        font-size: 15px;
        font-weight: bold;
        color: ${PRIMARY_COLOR};
      }
      a {
        color: ${PRIMARY_COLOR};
        text-decoration: none;
      }
      i {
        font-style: normal;
        color: #8a8a8a;
      }
    }
    button {
      min-width: 221px;
    }
    .btn-backtohome {
      color: ${PRIMARY_COLOR} !important;
      border-color: ${PRIMARY_COLOR} !important;
      box-shadow: 0px 0px 3px #00000029;

      &:hover {
        background-color: rgba(28, 171, 226, 0.04) !important;
      }
    }
    .btn-login {
      background-color: ${PRIMARY_COLOR} !important;
    }
    .prelogin-footer {
      .txt-checkbox {
        padding: 24px 80px 0;

        span {
          font-size: 15px;
          font-weight: bold;
          color: ${PRIMARY_COLOR};
        }
      }
    }
  }
`;

export const ListTitle = styled.h4`
  && {
    margin: 0;
    background-color: ${DARK_GRAY};
    color: ${WHITE_COLOR};
    font-size: 14px;
    padding: 15px 15px;
  }
`;
export const ListItemIconCustom = styled(ListItemIcon)`
  && {
    min-width: auto;
  }
`;
export const BookmarkIconCustom: any = styled(BookmarkIcon)`
  && {
    fill: ${(props: any) => props.fillColor};
    width: 16px;
    height: 20px;
    vertical-align: middle;
    margin-right: 4px;
  }
`;
export const BookmarkBorderIconCustom: any = styled(BookmarkBorderIcon)`
  && {
    fill: ${(props: any) => props.fillColor};
    width: 16px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const EditAssessmentAppBar = styled(AppBar)`
  && {
    background-color: ${UNICEF_BLUE} !important;
    box-shadow: none;

    button {
      color: ${DARK_GRAY};
      opacity: 1;
      text-transform: capitalize;
      width: 20%;
      text-align: center;

      &.Mui-disabled {
        pointer-events: auto;

        span {
          color: #c9c9c9 !important;
        }
      }

      .MuiTab-wrapper {
        flex-direction: row;
        justify-content: center;
      }
    }
    .MuiTabs-flexContainer {
      justify-content: space-evenly;
    }
    .MuiTabs-indicator {
      background-color: ${PRIMARY_COLOR};
    }
    .MuiTabs-scrollButtons {
      svg {
        fill: ${DARK_GRAY};
        font-size: 22px;
      }
    }
    @media screen and (max-width: 1080px) {
      .MuiTabs-flexContainer {
        display: block;
      }
    }
  }
`;

export const ListCustom: any = styled(List)`
  && {
    overflow: auto;
    max-height: 560px;
    padding: 0;
    
    li {
      cursor: pointer;

      padding-left: 15px;

      &:hover,
      &.selected-area-of-work {
        background-color: #fff;
      }
      span {
        font-size: 14px;
      }
      &.selected-area-of-work {
        span {
          font-weight: 500;
        }
      }
    }
    @media screen and (max-height: 657px) {
      max-height: 460px;
    }
    @media screen and (min-height: 658px) and (max-height: 991px) {
      max-height: 300px;
    }
    @media screen and (min-height: 992px) and (max-height: 1024px) {
      max-height: 340px;
    }
    @media screen and (min-height: 1025px) and (max-height: 1080px) {
      max-height: 400px;
    }
    @media screen and (min-height: 1081px) and (max-height: 1280px) {
      max-height: 460px;
    }
    @media screen and (min-height: 1281px) and (max-height: 1366px) {
      max-height: 560px;
    }
    @media screen and (min-height: 1367px) and (max-height: 1440px) {
      max-height: 560px;
    }
    @media screen and (min-height: 1441px) and (max-height: 1536px) {
      max-height: 600px;
    }
    @media screen and (min-height: 1537px) and (max-height: 1920px) {
      max-height: 680px;
    }
    @media screen and (min-height: 1921px) and (max-height: 2560px) {
      max-height: 740px;
    }
    @media screen and (min-height: 2561px) {
      max-height: 740px;
    }

    @media screen and (min-width: 1280px) and (max-height: 913px) {
      max-height: 520px;
    }
    @media screen and (min-width: 1366px) and (max-height: 657px) {
      max-height: 560px;
    }
    @media screen and (min-width: 1440px) and (max-height: 969px) {
      max-height: 560px;
    }
    @media screen and (min-width: 1536px) and (max-height: 754px) {
      max-height: 410px;
    }
    @media screen and (min-width: 1920px) and (max-height: 969px) {
      max-height: 620px;
    }
    @media screen and (min-width: 2560px) and (min-height: 969px) {
      max-height: 740px;
    }
  }
`;

export const GrayRadio = styled(Radio)`
  && {
    color: rgba(${DARK_GRAY_BG}, 0.54) !important;
    padding: 0;
    &.Mui-checked {
      color: rgb(${DARK_GRAY_BG}) !important;
    }
    &:hover {
      background-color: rgba(${DARK_GRAY_BG}, 0.1) !important;
    }
  }
`;

export const GreenRadio = styled(Radio)`
  && {
    color: rgba(${DARK_GRAY_BG}, 0.54) !important;
    padding: 0;
    &.Mui-checked {
      color: rgb(${UNICEF_GREEN}) !important;
    }
    &:hover {
      background-color: rgba(${UNICEF_GREEN}, 0.1) !important;
    }
  }
`;

export const AssessmentTable = styled(Table)`
  && {
    th,
    td {
      padding: 8px;
      font-size: 14px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 0;
    }
    th:nth-child(2),
    th:nth-child(3),
    td:nth-child(2),
    td:nth-child(3) {
      width: 40px !important;
      text-align: center;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 20px !important;
      text-align: center;
    }
    tr {
      background-color: ${WHITE_COLOR} !important;
    }
    th {
      white-space: nowrap;
    }
  }
`;

export const AssessmentTabularData: any = styled.div`
  && {
    max-height: 450px;
    overflow: auto;

    .ATD-Row {
      position: relative;
      padding: 10px 0;

      .MuiFormControlLabel-root{
        margin: 0 !important;
      }
    }
    .solid-border-bottom{
      border-bottom: 1px solid #c9c9c9;
    }
    .ATD-Ind-Row{
      .dashed-border-bottom{
        border-bottom: 1px dashed #c9c9c9;
        
        &:last-child{
          border-bottom-color: transparent;
        }
      }
    }
    strong {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .width90 {
      width: 90px;
      text-align: center;
    }
    .width50 {
      width: 50px;
      text-align: center;
    }
    .width70 {
      width: 70px;
      text-align: center;
    }
    .width96 {
      width: 96px;
      text-align: center;
    }
    .width132 {
      width: 132px;
      text-align: center;
    }

    @media screen and (max-width: 1080px) {
      .width90,
      .width70,
      .width96,
      .width132 {
        width: 70px;
      }
    }
    @media screen and (max-height: 657px) {
      max-height: 400px;
    }
    @media screen and (min-height: 658px) and (max-height: 991px) {
      max-height: 450px;
    }
    @media screen and (min-height: 992px) and (max-height: 1024px) {
      max-height: 450px;
    }
    @media screen and (min-height: 1025px) and (max-height: 1080px) {
      max-height: 400px;
    }
    @media screen and (min-height: 1081px) and (max-height: 1280px) {
      max-height: 460px;
    }
    @media screen and (min-height: 1281px) and (max-height: 1440px) {
      max-height: 400px;
    }
    @media screen and (min-height: 1441px) and (max-height: 1536px) {
      max-height: 500px;
    }
    @media screen and (min-height: 1537px) and (max-height: 1920px) {
      max-height: 580px;
    }
    @media screen and (min-height: 1921px) and (max-height: 2560px) {
      max-height: 640px;
    }
    @media screen and (min-height: 2561px) {
      max-height: 640px;
    }

    @media screen and (min-width: 1280px) and (max-height: 913px) {
      max-height: 420px;
    }
    @media screen and (min-width: 1366px) and (max-height: 657px) {
      max-height: 450px;
    }
    @media screen and (min-width: 1440px) and (max-height: 969px) {
      max-height: 460px;
    }
    @media screen and (min-width: 1536px) and (max-height: 754px) {
      max-height: 310px;
    }
    @media screen and (min-width: 1920px) and (max-height: 969px) {
      max-height: 520px;
    }
    @media screen and (min-width: 2560px) and (min-height: 969px) {
      max-height: 620px;
    }
  }
`;

export const ValidationTabularData = styled.div`
  && {
    max-height: 450px;
    overflow: auto;

    .VTD-Row {
      position: relative;
      padding: 10px 0;

      .MuiFormControlLabel-root{
        margin: 0 !important;
      }
    }
    .solid-border-bottom{
      border-bottom: 1px solid #c9c9c9;
    }
    .VTD-Ind-Row{
      .dashed-border-bottom{
        border-bottom: 1px dashed #c9c9c9;
        
        &:last-child{
          border-bottom-color: transparent;
        }
      }
    }
    strong {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .width150 {
      width: 150px;
      text-align: center;
    }
    .width100 {
      width: 100px;
      text-align: center;
    }
    .width124 {
      width: 124px;
      text-align: center;
    }
    .width140 {
      width: 140px;
      text-align: center;
    }
    .width90 {
      width: 90px;
      text-align: center;
    }
    @media screen and (max-width: 1080px) {
      .width124,
      .width150,
      .width140 {
        width: 112px;
      }
      .MuiSelect-select.MuiSelect-select {
        width: 70px !important;
      }
    }

    @media screen and (max-height: 657px) {
      max-height: 400px;
    }
    @media screen and (min-height: 658px) and (max-height: 991px) {
      max-height: 450px;
    }
    @media screen and (min-height: 992px) and (max-height: 1024px) {
      max-height: 450px;
    }
    @media screen and (min-height: 1025px) and (max-height: 1080px) {
      max-height: 400px;
    }
    @media screen and (min-height: 1081px) and (max-height: 1280px) {
      max-height: 460px;
    }
    @media screen and (min-height: 1281px) and (max-height: 1440px) {
      max-height: 400px;
    }
    @media screen and (min-height: 1441px) and (max-height: 1536px) {
      max-height: 500px;
    }
    @media screen and (min-height: 1537px) and (max-height: 1920px) {
      max-height: 580px;
    }
    @media screen and (min-height: 1921px) and (max-height: 2560px) {
      max-height: 640px;
    }
    @media screen and (min-height: 2561px) {
      max-height: 640px;
    }

    @media screen and (min-width: 1280px) and (max-height: 913px) {
      max-height: 420px;
    }
    @media screen and (min-width: 1366px) and (max-height: 657px) {
      max-height: 450px;
    }
    @media screen and (min-width: 1440px) and (max-height: 969px) {
      max-height: 460px;
    }
    @media screen and (min-width: 1536px) and (max-height: 754px) {
      max-height: 310px;
    }
    @media screen and (min-width: 1920px) and (max-height: 969px) {
      max-height: 520px;
    }
    @media screen and (min-width: 2560px) and (min-height: 969px) {
      max-height: 620px;
    }
  }
`;

export const TabWrapper = styled.div`
  padding: 0 16px 16px;
`;

export const Areaofworklabel = styled(Typography)`
  && {
    font-size: 20px;
    font-weight: 500;
    color: ${DARK_GRAY};
  }
`;

export const ScmmAppBar = styled(AppBar)`
  && {
    background-color: ${WHITE_COLOR} !important;
    box-shadow: none;

    button {
      color: ${DARK_GRAY};
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 400;
      opacity: 1;
      min-width: auto !important;
      padding: 6px 15px !important;
    }
    .MuiTab-textColorInherit.Mui-selected {
      font-weight: 500;
    }
    .MuiTabs-indicator {
      background-color: ${PRIMARY_COLOR};
    }
  }
`;

export const ScrollableScmmAppBar = styled(ScmmAppBar)`
  && {
    button {
      min-width: auto;
      max-width: none;
      padding: 6px 15px;

      &:hover {
        background-color: #f3f3f3;
      }
    }
    .Mui-selected {
      span {
        font-weight: 500;
      }
    }
    .MuiTabs-scrollButtons {
      svg {
        fill: ${DARK_GRAY};
        font-size: 22px;
      }
    }
  }
`;

export const PrintIconCustom = styled(PrintIcon)`
  && {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
`;

export const DownloadIconCustom = styled(GetAppIcon)`
  && {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
`;

export const AssessmentSubHeader = styled.div`
  h3 {
    color: ${DARK_GRAY};
    font-size: 20px;
    font-weight: bold;
  }
  strong {
    color: ${DARK_GRAY};
    font-size: 14px;
  }
  @media screen and (max-width: 1080px) {
    .region-info-text {
      padding: 0 10px;
    }
  }
`;

export const EditIconCustom = styled(EditIcon)`
  && {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;
export const AddIconCustom = styled(AddIcon)`
  && {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;
export const DeleteIconCustom = styled(DeleteIcon)`
  && {
    width: 18px;
    height: 18px;
    cursor: pointer;

    "&:hover": {
      fill: ${RED_COLOR} !important;
    }
  }
`;

export const HtmlTooltip = styled(Tooltip)`
  && {
    color: "rgba(0, 0, 0, 0.87)";
    font-size: 17px;
    border: "1px solid #dadde9";
    font-weight: 500;
  }
`;

export const AssessmentTooltipDetails = styled.div`
  margin: -10px;
  padding: 0;
  border-radius: 0;
  .maturity-inner {
    -webkit-box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 10px 20px 0px rgba(0, 0, 0, 0.2);
    background-color: ${WHITE_COLOR};
    width: 280px;
    display: flex;
    .maturity_no {
     
      color: #fff;
      text-align: center;
      padding: 6px 0 0;
      p {
        font-size: 14px;
        padding: 5px 10px 0;
      }
      h1 {
        font-size: 55px;
        font-weight: 500;
        margin: 0;
        padding: 0 10px 5px;
      }

    }
    .level1{
      background-color: #BCD6F1;
     }
     .level2{
      background-color: #97C3F0;
     }
     .level3{
      background-color: #59A7F1;
     }
     .level4{
      background-color: #1E8CED;
     }
     .level5{
      background-color: #0073F5;
     }
   
    .maturity_details {
      background-color: ${WHITE_COLOR};
      .maturity_title {
        background-color: ${DARK_GRAY};
        color: ${WHITE_COLOR};
        font-size: 12px;
        padding: 6px 16px;
      }
      .maturity_desc {
        color: ${DARK_GRAY};
        font-size: 14px;
        padding: 8px 16px;
      }
    }
  }
`;

export const InfoOutlinedIconCustom = styled(InfoOutlinedIcon)`
  && {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

export const BoxShadowWrapper = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #d1d6e0;
  border-radius: 2px;
  background-color: ${WHITE_COLOR};
  margin-bottom: 20px;
`;

export const ArrowBackIosIconCustom = styled(ArrowBackIosIcon)`
  && {
    color: ${BAR_FILLED};
    width: 16px;
    height: 16px;
  }
`;

export const DialogButtonDefault = styled(Button)`
  && {
    background-color: ${LIGHT_GRAY} !important;
    color: ${BLACK_COLOR} !important;
    text-transform: capitalize;
  }
`;

export const DialogButtonPrimary = styled(Button)`
  && {
    background-color: ${PRIMARY_COLOR} !important;
    &.MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
`;

export const ResponsiveBgImage = styled.img`
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
`;

export const ApplicationTitle = styled.span`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 24px !important;
  color: #ffffffde;
  font-weight: bold;
`;

export const WelcomeText = styled.span`
  display: block;
  text-align: center;
  font-size: 24px !important;
  color: ${DARK_GRAY};
  font-weight: bold;
  padding-bottom: 30px;
`;

export const HomeRightSideWrapper = styled.div`
  background-color: ${WHITE_COLOR};
  background-image: url(${UnicefBlueImage});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
`;

export const ActiveStatus = styled.span`
  display: inline-block;
  vertical-align: bottom;
  width: 18px;
  height: 18px;
  background-color: rgb(${UNICEF_GREEN});
  border-radius: 50%;
  border: 1px solid transparent;
`;

export const InActiveStatus = styled.span`
  display: inline-block;
  vertical-align: bottom;
  width: 18px;
  height: 18px;
  background-color: #f1f1f1;
  border-radius: 50%;
  border: 1px solid transparent;
`;

export const ARStatus = styled.span`
  display: inline-block;
  vertical-align: bottom;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #c9c9c9;
`;

export const BlueSubHeader = styled.div`
  background-color: #e0f2f9;
  padding: 10px 15px;

  strong {
    font-weight: 500 !important;
  }
`;

export const GreenFormControlLabel = styled(FormControlLabel)`
  && {
    position: relative;
    .MuiSwitch-root {
      margin-left: 40px;
    }
    .MuiSwitch-colorSecondary.Mui-checked {
      color: rgb(${UNICEF_GREEN});
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: rgba(${UNICEF_GREEN}, 0.5);
    }
    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      position: absolute;
      left: 0;
      font-size: 14px;
    }
  }
`;

export const UserContainer = styled.div`
  && {
    width: 80%;
    margin: 0 auto;
    padding: 15px 0;

    @media screen and (max-width: 1080px) {
      width: 90%;
    }
  }
`;

export const AddRoleButton = styled(Button)`
  && {
    color: #374ea1;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }
`;

export const CommentIconCustom = styled(CommentIcon)`
  && {
    width: 18px;
    height: auto;
    cursor: pointer;
  }
  &&.validationCommentBlue {
    fill: #1baae1 !important;
  }

  &&.validationCommentGrey {
    fill: #4a494a !important;
  }
`;

export const VisibilityIconCustom = styled(VisibilityIcon)`
  && {
    width: 18px;
    height: auto;
  }
`;

export const FileCopyIconCustom = styled(FileCopyIcon)`
  && {
    width: 18px;
    height: auto;
  }
`;

export const BreadcrumbsCustom = styled(Breadcrumbs)`
  && {
    padding-top: 10px;
    .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorInherit {
      font-size: 12px;
      color: ${DARK_GRAY};
      cursor: pointer;
    }
    .MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextPrimary {
      font-size: 12px;
    }
  }
`;

export const DragIndicatorIconCustom = styled(DragIndicatorIcon)`
  && {
    fill: #d8d8d8;
    width: 18px;
    height: 20px;
    vertical-align: middle;
  }
`;

export const DialogTitleCustom = styled(DialogTitle)`
  && {
    h2 {
      font-size: 16px;
      color: ${DARK_GRAY} !important;
    }
  }
`;

export const LandingLanguageBar = styled.div`
  > div {
    color: ${WHITE_COLOR} !important;
    width: 100px !important;
    margin: 0 !important;
  }
  .MuiInput-underline:before {
    border: none !important;
  }
  .MuiInput-underline:after {
    border: none !important;
  }
  .MuiSelect-icon {
    color: ${WHITE_COLOR} !important;
  }
  &.errorpage{
    > div {
      color: ${DARK_GRAY} !important;
    }
   .MuiSelect-icon {
      color: ${DARK_GRAY} !important;
   }
  }
`;

export const IndicatorsSubHeader = styled.div`
  h3 {
    color: ${DARK_GRAY};
    font-size: 20px;
    font-weight: bold;
  }
  strong {
    color: #818181 !important;
    font-size: 14px;
  }
`;

export const OverviewBlock = styled.div`
  && {
    margin-bottom: 10px;
    display: flex;
  }
`;

//backup
// export const AssessmentOverviewBlock = styled.div`
//   && {
//     padding: 20px 20px 13px;
//     display: flex;
//     align-items: flex-end;
//     justify-content: space-between;
//     height: 80px;
//     width: 20%;
//     margin-right: 20px;
//     box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.25);

//     span {
//       font-size: 20px !important;
//       font-weight: 500;
//       margin-bottom: 7px;
//     }
//     strong {
//       padding-right: 0 !important;
//       font-size: 48px;
//       font-weight: bolder !important;
//       line-height: normal !important;
//       width: 100px;
//       text-align: right;
//     }
//     @media only screen and (max-width: 1200px) {
//       margin-right: 10px;
//       padding: 15px 10px 8px;
//       height: 60px;
//       span {
//         font-size: 14px !important;
//       }
//       strong {
//         font-size: 30px;
//         width: 50px;
//       }
//     }
//   }
// `;

export const AssessmentOverviewBlock = styled.div`
  && {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
    height: 70px;
    // width: 20%;
    margin-right: 10px;
    box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.25);

    span {
      font-size: 16px !important;
      font-weight: 500;
      margin-bottom: 7px;
      white-space: nowrap;
    }
    strong {
      padding-right: 0 !important;
      font-size: 36px !important;
      font-weight: bolder !important;
      line-height: normal !important;
    }
    @media only screen and (max-width: 1200px) {
      margin-right: 10px;
      padding: 15px 10px 8px;
      height: 60px;
      span {
        font-size: 14px !important;
      }
      strong {
        font-size: 30px;
        width: 50px;
      }
    }
  }
`;

export const TotalBlock = styled(AssessmentOverviewBlock)`
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};

  strong {
    color: ${WHITE_COLOR} !important;
  }
`;

export const AvgMMIndexBlock = styled(AssessmentOverviewBlock)`
  background-color: ${WHITE_COLOR};

  strong {
    color: #9d1ab5 !important;
  }
`;
export const ProgramBlock = styled(AssessmentOverviewBlock)`
  background-color: ${WHITE_COLOR};

  strong {
    color: #ce3715 !important;
  }
`;

export const DraftBlock = styled(AssessmentOverviewBlock)`
  background-color: ${WHITE_COLOR};

  strong {
    color: #374ea1 !important;
  }
`;

export const ReAssignedBlock = styled(AssessmentOverviewBlock)`
  background-color: ${WHITE_COLOR};

  strong {
    color: #80bd41 !important;
  }
`;

export const SentVBlock = styled(AssessmentOverviewBlock)`
  background-color: ${WHITE_COLOR};

  strong {
    color: #00833d !important;
  }
`;

export const ValidatedBlock = styled(AssessmentOverviewBlock)`
  margin-right: 0 !important;
  background-color: ${WHITE_COLOR};

  strong {
    color: #ffc10d !important;
  }
  @media only screen and (max-width: 1200px) {
    margin-right: 0;
  }
`;

export const IndicatorViewTable = styled.table`
  && {
    width: 100%;

    th {
      text-align: left;
      font-weight: 500;
      color: ${DARK_GRAY};
    }
    tr:hover {
      background-color: transparent;
    }
    th,
    td {
      font-size: 14px;
      padding: 10px 0;
      border-bottom: 1px solid #c9c9c9 !important;
    }
  }
`;

export const UserInfoPanel = styled.div`
  && {
    text-align: center;

    p {
      display: block;
      color: ${DARK_GRAY};
      font-size: 14px;
    }

    > span {
      display: block;
      color: ${DARK_GRAY};
      font-size: 14px;
    }
    .txt-user-name {
      margin-bottom: 20px;
    }
  }
`;

export const RotatetIconCustom = styled(RotateRightIcon)`
  && {
    width: 18px;
    height: auto;
    cursor: pointer;
  }
`;

export const RotateButton = styled.button`
  && {
    background: transparent;
    border: none;
    &&:focus {
      outline: none;
    }
    &&:disabled {
      svg {
        fill: #c9c9c9;
        cursor: default;
      }
    }
  }
`;

export const FormControlValidation = styled(FormControl)`
  &&.disabledValidation {
    opacity: 0.5;
  }
  &&.agreeValidation {
    background-color: #80bd41;

    .MuiSelect-select.MuiSelect-select,
    .MuiSelect-icon {
      color: #fff;
    }
  }
  &&.disAgreeValidation {
    background-color: #e1221a;

    .MuiSelect-select.MuiSelect-select,
    .MuiSelect-icon {
      color: #fff;
    }
  }
  &&.notSelectValidation {
    background-color: #f3f3f3;
  }

  && {
    border-radius: 3px;

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      content: none;
    }
    .MuiSelect-select.MuiSelect-select {
      width: 92px;
      padding-left: 10px;
      padding-right: 16px;
      text-align: left;
      font-weight: 500;
      height: 20px;
      line-height: 22px;
    }
    .MuiSelect-icon {
      right: 4px;
    }
  }
`;

export const CommentContextWindow = styled.div`
  && {
    position: absolute;
    width: 318px;
    box-shadow: 1px 1px 9px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    z-index: 9999;
    top:0;
    right: 9%;

    .comment-header {
      background-color: ${DARK_GRAY};
      display: flex;
      justify-content: flex-end;
      padding: 10px 15px;
    }
    .comment-body {
      max-height: 250px;
      min-height: 100px;
      overflow-y: auto;
      text-align: left;

      .comment-block {
        padding: 10px 15px;
        border-bottom: 1px solid #c9c9c9;

        span {
          display: block;
        }
        .commented-by {
          font-size: 16px !important;
          font-weight: 500;
          color: ${DARK_GRAY};
        }
        .comment-date {
          font-size: 12px !important;
          color: #c9c9c9;
        }
        .comment-desc {
          color: #9b9b9b;
          padding-top: 10px;
        }
      }
    }
    .comment-footer {
      border-top: 1px solid #c9c9c9;
      padding: 10px 15px;
      .MuiFormControl-root {
        width: 250px;
        .MuiInputLabel-formControl {
          font-size: 14px !important;
          color: #9b9b9b;
          font-weight: 500;
        }
        .MuiInputBase-fullWidth.MuiInputBase-formControl {
          color: #9b9b9b !important;
          font-weight: 500;
          font-size: 14px;
          padding: 0 !important;

          .MuiOutlinedInput-inputMultiline {
            padding: 0 15px;
            margin: 15px 0;
            line-height: 24px;
          }
        }
      }
      .btn-send {
        background: transparent;
        border: none;
        padding: 0;

        &:focus {
          outline: none;
        }
      }
      .blue-button {
        .MuiSvgIcon-root {
          fill: ${PRIMARY_BUTTON_COLOR};
          cursor: pointer;
        }
      }
      button:disabled .MuiSvgIcon-root {
        fill: #c9c9c9;
        cursor: default;
      }
    }
  }
`;

export const CloseIconCustom = styled(CloseIcon)`
  && {
    width: 18px;
    height: auto;
    cursor: pointer;
    fill: ${WHITE_COLOR};
  }
`;

export const SendIconCustom = styled(SendIcon)`
  && {
    width: 16px;
    height: auto;
    cursor: default;
    fill: #c9c9c9;
  }
  &&.disabledButton {
    fill: #c9c9c9;
  }
`;

export const LPTitle = styled.div`
  && {
    text-align: center;
    h1 {
      font-size: 28px;
      font-weight: 600;
      color: ${DARK_GRAY};
      padding: 20px 4% 10px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: ${DARK_GRAY};
      padding: 0 4%;
    }
    @media screen and (max-width: 1200px) {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;

export const AboutUsContent = styled.div`
  && {
    padding-bottom: 50px;
    .page-title {
      padding: 36px 4%;
      text-align: center;
      color: ${DARK_GRAY};
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }
    .image-section {
      text-align: center;
      padding: 0 4% 20px;
    }

    .MuiAccordion-root.Mui-expanded {
      margin: 0 !important;
    }
    .MuiAccordion-root.Mui-expanded:before {
      opacity: 1 !important;
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiAccordion-root:after {
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      opacity: 1;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.12);
    }
    .MuiAccordion-root.Mui-expanded:after {
      bottom: 0;
    }
    .MuiAccordion-root:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 0;
    }
    .MuiAccordionSummary-root {
      border-left: 4px solid transparent;
    }
    .MuiAccordionSummary-root:hover {
      border-left-color: ${PRIMARY_COLOR};
    }
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: auto;
      border-left-color: ${PRIMARY_COLOR};
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
    .MuiAccordionSummary-content {
      span {
        font-weight: 500;
      }
    }
    p {
      color: ${DARK_GRAY};
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    ol {
      padding: 0 20px;
      li {
        color: ${DARK_GRAY};
        font-size: 14px;
      }
    }
    h5 {
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: 500;
    }
    @media screen and (max-width: 1200px) {
      .page-title {
        font-size: 22px;
      }
    }
  }
`;

export const AboutBgImage = styled.img`
  && {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

export const GeneralResponsiveImage = styled.img`
  && {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

export const HomeFooter = styled.div`
  && {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const UserProfileTab = styled.div`
  && {
    @media screen and (max-width: 1080px) {
      > .MuiGrid-container {
        flex-direction: column-reverse;
        > .MuiGrid-root.MuiGrid-item {
          width: 100%;
        }
      }
      .paddingR20 {
        padding: 20px;
      }
    }
  }
`;

export const ArrowDropUpIconCustom = styled(ArrowDropUpIcon)`{
  &&{
    fill: green;
    width: 1.5em;
    height: 1.5em;
  }
}`;

export const RemoveIconCustom = styled(RemoveIcon)`{
  &&{
    height: 1.5em;
  }
}`;

export const ArrowDropDownIconCustom = styled(ArrowDropDownIcon)`{
  &&{
    fill: red;
    width: 1.5em;
    height: 1.5em;
  }
}`;
export const RecommendedToolsSection = styled.div`
  &&{
    
    display: flex;
    flex-direction: column; 
    .RTName{
      font-weight: 500;
      white-space: normal;
    }
    .RTDesc{
      white-space: normal;
      padding-left: 10px;

      &:before{
        content: '-';
        padding-right: 5px;
      }
    }
    .RTLink{
      white-space: normal;
      padding-left: 10px;
      &:before{
        content: '-';
        padding-right: 5px;
      }
      a{
        text-decoration: none;
        font-size: 14px;
        color: #1cabe2;
      }
    }
  }
`;

export const HowToJoinContent = styled.div`
  && {
    padding-bottom: 50px;
    .page-title {
      padding: 36px 4% 5px;
      text-align: center;
      color: ${DARK_GRAY};
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }
    .main-desc{
      text-align: center;
      margin: 0;
      padding: 0 4% 36px;
    }
    
    p {
      color: ${DARK_GRAY};
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    a {
      color: ${PRIMARY_COLOR};
      text-decoration: none;
    }
    .step-title {
      margin-top: 0;
      margin-bottom: 10px;
      font-weight: 500;
      color: ${PRIMARY_COLOR};
    }
    ol {
      padding: 0 20px;
      li {
        color: ${DARK_GRAY};
        font-size: 14px;
        list-style-type: lower-roman;
      }
    }
    strong {
      font-weight: 500 !important;
      color: ${DARK_GRAY};
    }
    @media screen and (max-width: 1200px) {
      .page-title {
        font-size: 22px;
      }
    }
  }
`;

export const SCSHubAppList = styled.div`
  &&{
    display: flex;
    flex-direction: column;
    width: 320px;
    margin: 0 auto;
    padding: 10px 0 20px;

    .app-item{
      margin: 10px 0;
      border: 2px solid #dadde9;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 14px;
      text-align: center;

      &:hover{
        background-color: ${LIGHT_GRAY};
      }
    }
    .app-item-active{
      cursor: pointer;
      background-color: ${PRIMARY_COLOR};
      color: ${WHITE_COLOR};

      &:hover{
        background-color: #0982b1;
      }
    }
  }
`;

export const RequestAccessArea = styled.div`
  &&{
    position: absolute;
    top: 20px;
    right: 20px;

    @media screen and (max-width: 767px) {
      position: initial;
      text-align: right;
      padding-right: 20px;
    }
  }
`;

export const AppTable = styled.div`
  &&{
    display: flex;
    flex-direction: column;

    .app-table-row{
      display: flex;
      flex-direction: row !important;
      width: 100%;

      &:nth-child(even){
        background-color: #FFF;
      }
      &:nth-child(odd){
        background-color: #EFF2FF;
      }
      .app-table-head{
        display: flex;
        justify-content: flex-start;
        width: 150px;
        box-sizing: border-box;
        padding: 10px 15px;
        background-color: ${UNICEF_BLUE};
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #000;
        border-right: 1px solid #ABBDFF;
        border-top: 1px solid #ABBDFF;
        &:last-child{
          border-right: none;
        }
      }
      .app-table-cell{
        display: flex;
        justify-content: flex-start;
        width: 150px;
        box-sizing: border-box;
        padding: 10px 15px;
        font-size: 14px !important;
        border-right: 1px solid #ABBDFF;
        span{
          font-size: 14px !important;
          text-align: left;
        }
        &:last-child{
          border-right: none;
        }
      }
    }
  }
`;

export const CheckIconCustom = styled(CheckIcon)`
  &&{
    width: 18px;
    height: auto;
    fill: ${DARK_GRAY};
  }
`;
export const CloseIconGray = styled(CloseIcon)`
  && {
    width: 18px;
    height: auto;
    fill: ${DARK_GRAY};
  }
`;

export const ArrowForwardIosIconCustom = styled(ArrowForwardIosIcon)`
   &&{
    width: 16px;
    height: 16px;
    cursor: pointer;
    vertical-align: text-top;
    fill:#374ea1;
   }
`;

export const CountrySelectWrapper = styled.div`
   &&{
     width: 200px;
   }
`;


export const MainPageWrapper = styled.div`
   &&{
     min-height: calc(100vh - 64px);
   }
`;

export const UsersTypeSwitch = styled.label`
   &&{
     cursor: pointer;
     .selected-user-type{
       color: #ffff50;
       font-weight: 500;
     }
     .MuiSwitch-sizeSmall .MuiSwitch-thumb {
        background-color: #ffff50;
      }
   }
`;

export const CustomDropdown = styled.div`
  &&{
    min-width: 150px;
    .MuiOutlinedInput-input{
      background-color: #FFF;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #374ea1 !important;
    }
    .MuiInputLabel-formControl{
      top: -7px;
    }
    .MuiInputLabel-formControl.MuiInputLabel-shrink{
      top: 0;
    }
  }
`;


export const AssessmentOverviewSubTable = styled.div`
  &&{
    border: 1px solid #002060;

    div, p, span{
      font-size: 14px !important;
      word-break: break-word;
    }
    .overview-header{
      background-color: #002060;
      color: #FFF;
    }
    .saow-link-text{
      color: #000 !important;
      font-size: 15px !important;
    }
    .txt-aow-name{
      font-weight: 500 !important;
      text-align: center;
    }
    .txt-aow-count{
      font-size: 28px !important;
    }
    .txt-score{
      font-size: 17px !important;
    }
    .score-val-col{
      align-self: normal;
      padding-left: 7px;
    }
    button{
      &:hover{
        text-decoration: none !important;
      }
    }
    .border-bottom-blue {
        border-bottom: 1px solid #002060;
    }
    .border-right-blue {
      border-right: 1px solid #002060;
    }
    .aow-position{
      display: flex;
      flex-direction: column;
      height: 100%;

      .txt-aow-count{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const LevelsLegend = styled.div`
  &&{
    .legend-item:before{
      content: '';
      width: 15px;
      height: 15px;
      background-color: #ddd;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      vertical-align: middle;
      border-radius: 3px;
      border: 1px solid transparent;
      box-sizing: border-box;
    }
    .legend-na:before{
      background-color: #9e9e9e;
    }
    .legend-level0:before{
      background-color: #FFF;
      border-color: #ddd;
    }
    .legend-level1:before{
      background-color: #C91414;
    }
    .legend-level2:before{
      background-color: #FD706B;
    }
    .legend-level3:before{
      background-color: #FF7800;
    }
    .legend-level4:before{
      background-color: #75AD6F;
    }
    .legend-level5:before{
      background-color: #1D7044;
    }
  }
`;

export const TableHeadIndicator = styled(TableHead)`
  &&{
    .black-bg{
      background-color: #4a494a !important;
      color: #fff !important;
    }
    .blue-bg{
      background-color: #c7eaf7 !important;
    }
    th{
      white-space: nowrap;
      padding: 10px 15px;
    }
    tr{
      &:nth-child(2){
        th:nth-child(1){
          width: 4% !important;
        }
        th:nth-child(2){
          width: 20% !important;
        }
        th:nth-child(3){
          width: 20% !important;
        }
        th:nth-child(4){
          width: 20% !important;
        }
        th:nth-child(5){
          width: 9% !important;
        }
        th:nth-child(6){
          width: 9% !important;
        }
        th:nth-child(7){
          width: 9% !important;
        }
        th:nth-child(8){
          width: 9% !important;
        }
        th:nth-child(9){
          width: 9% !important;
        }
        th:nth-child(10){
          width: 11% !important;
        }
      }
    }
  }
`;

export const ScrollToTopButton = styled.div`
   position: fixed; 
   width: 30px;
   right: 5%;
   bottom: 40px;
   height: 30px;
   z-index: 1;
   cursor: pointer;
   background-color: #1CABE2;
   border-radius: 50%;
   text-align: center;
   line-height: 36px;
  box-shadow: 1px 1px 3px 2px rgb(0 0 0 / 50%);

   svg{
     color: #fff;
     font-size: 16px !important;
   }
   @media screen and (min-width: 1920px) and (max-width: 2559px){
    right: 4%;
   }
   @media screen and (min-width: 2560px){
    right: 15%;
   }
`;

export const SCSBackLink = styled.div`
   &&{
      position: absolute;
      top: 14px;
      z-index: 9999;
      padding: 0 10px;

      .MuiButton-textPrimary {
        color: ${BAR_FILLED};
        text-transform: inherit;
      }
   }
`;

export const StatisticsTile = styled.div`
   &&{
      border: 1px solid #1cabe2;
      border-radius: 3px;
      padding: 20px;
      height: 100%;
      box-sizing: border-box;
      background-color: #e8f9ff;

      .stat-title{
        display: block;
      }
      .stat-count{
        display: block;
        font-size: 36px !important;
        text-align: center;
        padding: 10px 0;
        font-weight: 500;
        color: #374ea1;
      }
      .stat-subtitle-block{
        display: flex;
        justify-content: space-between;

        .stat-subtitle{

        }
        .stat-subtitle-count{
          font-size: 16px !important;
          font-weight: 500;
        }
      }
   }
`;