import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getLocalDateFromMoment } from "../../../utils/data";
import { useTranslation } from "react-i18next";
import FormSelect from "../../../components/form-controls/select";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import {
  DialogClose,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../../styles/common";
import { FullWidthContainer } from "../../../styles/common";
import FormInput from "../../../components/form-controls/input";
import {
  actionSaveQuestionSetDataDetail,
} from "../actions";
import { openToaster } from "../../../store/common/actions";
import * as yup from "yup";
import { STATUS_DRAFT_CONSTANT, STATUS_COMPLETED_CONSTANT } from "../constants";
import { COMMON_CONSTANT, INDICATOR } from "constants/data";
import { testInputValueWithRegex } from "utils/security";

function QuestionSetFormComponent(props: any) {
  const {
    dialogClose,
    closeToasterFun,
    supplyChainData,
    scopeData,
    questionSetData,
    userId,
    isEditQuestionSet,
    dialogOpen,
    dialogTitle,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allPrograms: any = (window as any).allPrograms;
  const allScopeData: any = (window as any).allScopeData;

  const questionSetCode = useSelector(
    (state: any) => state.question.questionSetCode
  );
  const questionSetDataById = useSelector(
    (state: any) => state.question.questionSetDataById
  );
  const listAreaOfWork = useSelector((state: any) => state.common.listAreaOfWork);
  const [editQuestionSetCode, setEditQuestionSetCode] = useState("");
  const createdDate = getLocalDateFromMoment(null);
  const [editCreatedDate, setEditCreatedDate] = useState("");
  const [checkStatusCompleted, setCheckStatusCompleted] = useState(false);
  const [isScopeDisabled, setScopeDisabled] = useState(false);
  const [isVCIndicator, setVCIndicator] = useState(false);
  const editDialog: string = t("indicators.editIndicatorSetComment");
  const detailsDialog: string = t("indicators.indicatorDetails");

  const validateSchema = yup.object().shape({
    name: yup.string().trim().required(t("indicators.pleaseEnterComment"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    supplyChain: yup.string().required(t("validationMessage.programSelect")),
    scope: !isVCIndicator
      ? yup.string().required(t("validationMessage.scopeSelect"))
      : yup.string(),
    areaOfWork: isVCIndicator
      ? yup.string().required(t("validationMessage.selectAreaOfWork"))
      : yup.string(),
  });
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors, setValue } = methods;

  useEffect(() => {
    setCheckStatusCompleted(false);
  }, [dialogOpen]);

  useEffect(() => {
    if (
      questionSetDataById &&
      questionSetDataById.length &&
      isEditQuestionSet
    ) {

      if (allPrograms.length && Number(questionSetDataById[0].supplyChainId) === Number(allPrograms[1].id)) {
        setVCIndicator(true);
      }
      else
        setVCIndicator(false);
      setValue("supplyChain", questionSetDataById[0].supplyChainId);
      setValue("name", questionSetDataById[0].name);
      setValue("areaOfWork", questionSetDataById[0].areaOfWorkId);

      setEditQuestionSetCode(questionSetDataById[0].code);
      setEditCreatedDate(
        getLocalDateFromMoment(questionSetDataById[0].createdDate)
      );
      if (questionSetDataById[0].statusId === STATUS_COMPLETED_CONSTANT) {
        setCheckStatusCompleted(true);
      } else if (questionSetDataById[0].statusId === STATUS_DRAFT_CONSTANT) {
        setCheckStatusCompleted(false);
      }
    }
  }, [questionSetDataById]);

  useEffect(() => {
    if (isEditQuestionSet && questionSetDataById.length) {
      if (isVCIndicator) {
        setValue("areaOfWork", questionSetDataById[0].areaOfWorkId);
      }
      else {
        setValue("scope", questionSetDataById[0].scopeId);
      }
    }
  }, [isVCIndicator])

  const onSubmit = (data: any) => {
    if (data.scope) {
      const checkData = questionSetData.filter(
        (questionSet: any) =>
        (questionSet.supplyChainId === +data.supplyChain &&
          questionSet.scopeId === +data.scope &&
          questionSet.statusId === INDICATOR.STATUS.DRAFT)
      );

      if (
        (checkData.length && !isEditQuestionSet) ||
        (isEditQuestionSet &&
          +data.supplyChain !== questionSetDataById[0].supplyChainId &&
          +data.scope !== questionSetDataById[0].scopeId)
      ) {
        dispatch(
          openToaster(t("validationMessage.differentProgramScopeSelect"), false)
        );
        closeToasterParticularTimeInterval();
        return;
      }
    }
    if (data.areaOfWork) {
      const checkData = questionSetData.filter(
        (questionSet: any) =>
        (questionSet.supplyChainId === +data.supplyChain &&
          questionSet.areaOfWorkId === +data.areaOfWork &&
          questionSet.statusId === INDICATOR.STATUS.DRAFT)
      );

      if (
        (checkData.length && !isEditQuestionSet) ||
        (isEditQuestionSet &&
          +data.supplyChain !== questionSetDataById[0].supplyChainId &&
          +data.scope !== questionSetDataById[0].scopeId)
      ) {
        dispatch(
          openToaster(t("validationMessage.differentProgramAreaOfWorkSelect"), false)
        );
        closeToasterParticularTimeInterval();
        return;
      }
    }
    const formData: any = {
      supplyChainId: data.supplyChain,
      scopeId: data.scope,
      name: data.name.trim(),
      code: questionSetCode,
      statusId: INDICATOR.STATUS.DRAFT,
      areaOfWorkId: data.areaOfWork,
      createdBy: userId,
    };

    if (isEditQuestionSet) {
      formData.id = questionSetDataById[0].id;
      formData.code = questionSetDataById[0].code;
      formData.updatedBy = userId;
    }
    dispatch(actionSaveQuestionSetDataDetail(formData));
    dialogClose();
  };
  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFun();
    }, COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
  };

  const handleProgramChange = ([event]: any) => {
    const { value } = event.target;
    if (allPrograms.length && Number(value) === Number(allPrograms[1].id)) {
      setVCIndicator(true);
      setScopeDisabled(false);
    }
    else if (allPrograms.length && Number(value) === Number(allPrograms[0].id)) {
      setValue("scope", allScopeData[0].id);
      setScopeDisabled(true);
      setVCIndicator(false);
    }
    else {
      setScopeDisabled(false);
      setValue("scope", '');
      setVCIndicator(false);
    }
    return value
  }

  return (
    <>
      <FullWidthContainer>
        <Dialog
          open={dialogOpen}
          onClose={dialogClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            {checkStatusCompleted
              ? detailsDialog
              : isEditQuestionSet
                ? editDialog
                : dialogTitle}
            <DialogClose aria-label="close" onClick={() => dialogClose()}>
              <CloseIcon />
            </DialogClose>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="alert-dialog-description"
              className="nomargin"
            >
              <FormContext {...methods}>
                <form>
                  <Grid container spacing={2}>
                    {isEditQuestionSet && (
                      <>
                        <Grid item xs={6}>
                          <Typography component="label">
                            <Typography component="strong" className="strong-text">
                              {t("indicators.indicatorSetId")} :
                            </Typography>
                            {!isEditQuestionSet
                              ? questionSetCode
                              : editQuestionSetCode}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography component="label">
                            <Typography component="strong" className="strong-text">
                              {t("common.createdDate")} :
                            </Typography>
                            {!isEditQuestionSet ? createdDate : editCreatedDate}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <FormSelect
                        name="supplyChain"
                        label={t("common.program")}
                        options={supplyChainData}
                        onChange={handleProgramChange}
                        defaultValue=''
                        hideselectoption="true"
                        required={true}
                        errorobj={errors}
                        disabled={isEditQuestionSet}
                      />
                    </Grid>

                    {!isVCIndicator ? (
                      <Grid item xs={6}>
                        <FormSelect
                          name="scope"
                          label={t("common.scope")}
                          options={scopeData}
                          required={true}
                          errorobj={errors}
                          disabled={isEditQuestionSet || isScopeDisabled}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <FormSelect
                          name="areaOfWork"
                          label={t("common.areaOfWork")}
                          options={listAreaOfWork}
                          required={true}
                          errorobj={errors}
                          disabled={isEditQuestionSet}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormInput
                        type="text"
                        name="name"
                        label={t("common.comment")}
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 100 }}
                        disabled={checkStatusCompleted}
                      />
                      {!checkStatusCompleted && (
                        <Typography component="label" className="maxchar">
                          {t("indicators.maxChar")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="padding16-24">
            <Grid container alignItems="center" justify="flex-end" spacing={2}>
              <Grid item>
                <DialogButtonDefault
                  variant="contained"
                  color="default"
                  onClick={dialogClose}
                  className="text-capitalize"
                >
                  {t("common.cancel")}
                </DialogButtonDefault>
              </Grid>
              <Grid item>
                {!isEditQuestionSet ? (
                  <DialogButtonPrimary
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="text-capitalize"
                  >
                    {t("common.add")}
                  </DialogButtonPrimary>
                ) : (
                  <DialogButtonPrimary
                    variant="contained"
                    color={checkStatusCompleted ? "default" : "primary"}
                    type="submit"
                    disabled={checkStatusCompleted}
                    onClick={handleSubmit(onSubmit)}
                    className="text-capitalize"
                  >
                    {t("common.update")}
                  </DialogButtonPrimary>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </FullWidthContainer>
    </>
  );
}

export default QuestionSetFormComponent;
