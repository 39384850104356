import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { BigContainer, CustomDevider, DialogButtonDefault, DialogButtonPrimary, UserContainer } from "../../../../styles/common";
import { FormContext, useForm } from "react-hook-form";
import FormInput from "components/form-controls/input";
import { actionSaveAOWOrtopicDetails, actionSaveAOWOrtopicDetailsSuccess } from "containers/master/actions";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AOW_MANAGEMENT_CONST } from "constants/data";
import { Typography } from "@material-ui/core";
import { testInputValueWithRegex } from "utils/security";

function AowManegmentFormComponent(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showHideGridAndForm, IsEdit, currentItem, areaOfWorklist, subAreaOfWorklist } = props;
  const [validateSchema, setValidateSchema]: any = useState({});
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors, setValue } = methods;
  const aowTopicDetals = useSelector((state: any) => state.masterForm.aowTopicDetals);
  const aowOrTopicSaved = useSelector((state: any) => state.masterForm.aowOrTopicSaved);
  const [show, setShow] = useState(true);
  const [currentAOW, setCurrentAOW] = useState("");
  const [currentSubAOW, setCurrentSubAOW] = useState("");
  const [currentAOWId, setCurrentAOWId] = useState("");
  const [currentSubAOWId, setCurrentSubAOWId] = useState("");
  const [currentType, setCurrentType] = useState(0);

  const showHideButton = (request: boolean) => {
    setShow(!request);
  };

  useEffect(() => {
    if (aowOrTopicSaved) {
      showHideGridAndForm(false);
      dispatch(actionSaveAOWOrtopicDetailsSuccess(false));
    }
  }, [aowOrTopicSaved])

  useEffect(() => {
    if (aowTopicDetals && IsEdit == true) {
      showHideButton(true);
      setValue("areaOfWorkName", aowTopicDetals.areaOfWorkName);
      setValue("subAreaOfWorkName", aowTopicDetals.subAreaOfWorkName);
      setValue("areaOfWorkId", aowTopicDetals.areaOfWorkId);
      setValue("subAreaOfWorkId", aowTopicDetals.subAreaOfWorkId);
      setValue("topicId", aowTopicDetals.topicId);
      setValue("type", aowTopicDetals.type);
      setCurrentType(aowTopicDetals.type);
      setValue("en_name", aowTopicDetals.enName);
      setValue("fr_name", aowTopicDetals.frName);
      setValue("sp_name", aowTopicDetals.spName);
      setValue("ru_name", aowTopicDetals.ruName);
      setValue("ar_name", aowTopicDetals.arName);
      setValue("desc_en", aowTopicDetals.enDescription);
      setValue("desc_fr", aowTopicDetals.frDescription);
      setValue("desc_sp", aowTopicDetals.spDescrption);
      setValue("desc_ru", aowTopicDetals.ruDescription);
      setValue("desc_ar", aowTopicDetals.arDescription);
    }
  }, [aowTopicDetals]);

  useEffect(() => {
    showHideButton(false)
    setValidateSchema(
      yup.object().shape({
        en_name: yup.string().trim().required(t("validationMessage.nameValidation"))
          .max(500, t("validationMessage.max500Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        fr_name: yup.string().trim().required(t("validationMessage.nameValidation"))
          .max(500, t("validationMessage.max500Validation"))
          //  .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        sp_name: yup.string().trim().required(t("validationMessage.nameValidation"))
          .max(500, t("validationMessage.max500Validation"))
          //  .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ru_name: yup.string().trim().required(t("validationMessage.nameValidation"))
          .max(500, t("validationMessage.max500Validation"))
          //  .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ar_name: yup.string().trim().required(t("validationMessage.nameValidation"))
          .max(500, t("validationMessage.max500Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value))
      })
    );
  }, []);

  useEffect(() => {
    if (areaOfWorklist.length && subAreaOfWorklist.length) {
      const filteredCurrentAOW = areaOfWorklist.filter((item: any) => parseInt(currentItem.areaOfWorkId) === parseInt(item.id));
      const filteredCurrentSubAOW = subAreaOfWorklist.filter((item: any) => parseInt(currentItem.subAreaOfWorkId) === parseInt(item.id));
      if (filteredCurrentAOW.length) {
        setCurrentAOW(filteredCurrentAOW[0].label);
        setCurrentAOWId(filteredCurrentAOW[0].id);
      }
      if (filteredCurrentSubAOW.length) {
        setCurrentSubAOW(filteredCurrentSubAOW[0].label);
        setCurrentSubAOWId(filteredCurrentSubAOW[0].id);
      }
      setCurrentType(currentItem.type);
    }
  }, [currentItem, areaOfWorklist, subAreaOfWorklist]);

  const onSubmit = (data: any) => {
    const dataForm: any = {
      "topicId": data.topicId ? data.topicId : null,
      "areaOfWorkId": currentAOWId,
      "subAreaOfWorkId": currentSubAOWId,
      "type": IsEdit ? currentType : AOW_MANAGEMENT_CONST.TOPIC,
      "enName": data.en_name,
      "enDescription": data.desc_en,
      "frName": data.fr_name,
      "frDescription": data.desc_fr,
      "spName": data.sp_name,
      "spDescription": data.desc_sp,
      "ruName": data.ru_name,
      "ruDescription": data.desc_ru,
      "arName": data.ar_name,
      "arDescription": data.desc_ar
    };
    dispatch(actionSaveAOWOrtopicDetails(dataForm));
  };

  return (
    <>
      <FormContext {...methods}>
        <BigContainer>
          <UserContainer>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography component={"strong"} className="strong-text">
                  {t("common.areaOfWork")} :
                </Typography>
                <Typography component={"span"}>
                  {currentAOW}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {currentSubAOW ? (
                  <>
                    <Typography component={"strong"} className="strong-text">
                      {t("common.subAreaOfWork")} :
                    </Typography>
                    <Typography component={"span"}>
                      {currentSubAOW}
                    </Typography>
                  </>
                ) : null}

                <FormInput
                  type="text"
                  name="areaOfWorkId"
                  className="display-hidden"
                />
                <FormInput
                  type="text"
                  name="subAreaOfWorkId"
                  className="display-hidden"
                />
                <FormInput
                  type="text"
                  name="topicId"
                  className="display-hidden"
                />
                <FormInput
                  type="text"
                  name="type"
                  className="display-hidden"
                />
              </Grid>
            </Grid>
          </UserContainer>
          <CustomDevider />
          <UserContainer>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span">{t("common.language")}: {t("language.english")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="text"
                      name="en_name"
                      label={currentType === AOW_MANAGEMENT_CONST.AOW
                        ? t("common.areaOfWork")
                        : currentType === AOW_MANAGEMENT_CONST.SUB_AOW && IsEdit
                          ? t("common.subAreaOfWork")
                          : t("common.topic")}
                      required={true}
                      errorobj={errors}
                      className="direction-ltr"
                    />
                    <FormInput
                      type="text"
                      name="id_en"
                      className="display-hidden"
                    />
                    <FormInput
                      type="text"
                      name="desc_en"
                      className="display-hidden"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span">{t("common.language")}: {t("language.french")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="text"
                      name="fr_name"
                      label={currentType === AOW_MANAGEMENT_CONST.AOW
                        ? t("common.areaOfWork")
                        : currentType === AOW_MANAGEMENT_CONST.SUB_AOW && IsEdit
                          ? t("common.subAreaOfWork")
                          : t("common.topic")}
                      required={true}
                      errorobj={errors}
                      className="direction-ltr"
                    />
                    <FormInput
                      type="text"
                      name="id_fr"
                      className="display-hidden"
                    />
                    <FormInput
                      type="text"
                      name="desc_fr"
                      className="display-hidden"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UserContainer>
          <UserContainer>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span">{t("common.language")}: {t("language.spanish")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="text"
                      name="sp_name"
                      label={currentType === AOW_MANAGEMENT_CONST.AOW
                        ? t("common.areaOfWork")
                        : currentType === AOW_MANAGEMENT_CONST.SUB_AOW && IsEdit
                          ? t("common.subAreaOfWork")
                          : t("common.topic")}
                      required={true}
                      errorobj={errors}
                      className="direction-ltr"
                    />
                    <FormInput
                      type="text"
                      name="id_sp"
                      className="display-hidden"
                    />
                    <FormInput
                      type="text"
                      name="desc_sp"
                      className="display-hidden"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span">{t("common.language")}: {t("language.russian")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="text"
                      name="ru_name"
                      label={currentType === AOW_MANAGEMENT_CONST.AOW
                        ? t("common.areaOfWork")
                        : currentType === AOW_MANAGEMENT_CONST.SUB_AOW && IsEdit
                          ? t("common.subAreaOfWork")
                          : t("common.topic")}
                      required={true}
                      errorobj={errors}
                      className="direction-ltr"
                    />
                    <FormInput
                      type="text"
                      name="id_ru"
                      className="display-hidden"
                    />
                    <FormInput
                      type="text"
                      name="desc_ru"
                      className="display-hidden"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UserContainer>
          <UserContainer>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography component="span">{t("common.language")}: {t("language.arabic")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      type="text"
                      name="ar_name"
                      label={currentType === AOW_MANAGEMENT_CONST.AOW
                        ? t("common.areaOfWork")
                        : currentType === AOW_MANAGEMENT_CONST.SUB_AOW && IsEdit
                          ? t("common.subAreaOfWork")
                          : t("common.topic")}
                      required={true}
                      errorobj={errors}
                      className="direction-rtl"
                    />
                    <FormInput
                      type="text"
                      name="id_ar"
                      className="display-hidden"
                    />
                    <FormInput
                      type="text"
                      name="desc_ar"
                      className="display-hidden"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UserContainer>
        </BigContainer>
      </FormContext>
      <CustomDevider />
      <Grid container spacing={2} justify="flex-end" className="padding20">
        <Grid item>
          <DialogButtonDefault
            variant="contained"
            color="default"
            className="text-capitalize"
            onClick={() => showHideGridAndForm(false)}
          >
            {t("common.cancel")}
          </DialogButtonDefault>
        </Grid>
        <Grid item>
          {
            show ? (
              <DialogButtonPrimary
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="text-capitalize"
              >
                {t("common.add")}
              </DialogButtonPrimary>
            ) : (
              <DialogButtonPrimary
                variant="contained"
                color="primary"
                type="submit"
                className="text-capitalize"
                onClick={handleSubmit(onSubmit)}
              >
                {t("common.update")}
              </DialogButtonPrimary>
            )
          }
        </Grid>
      </Grid>
    </>
  );
}

export default AowManegmentFormComponent;
