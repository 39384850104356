import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchMasterMenuData, fetchLoginData } from "./api";
import {
  actionFetchMasterMenuDataSuccess,
  actionFetchLoginDataSuccess,
  actionFetchMasterMenuData,
} from "./actions";
import { actionFetchCountryData } from "../../store/common/actions";
import { ActionTypes } from "./constants";
import { AUTH_TOKEN } from "../../constants/api";
import { setLoaderVisibility, openToaster } from "../../store/common/actions";
import { API_ERROR_MESSAGE } from "../../store/common/message";

import { actionFetchUserIdSettingProfileData } from "../user-profile/actions";
import {
  actionFetchDynamicFormDataDetail,
  actionFetchMasterMaturityIndexData,
} from "containers/master/actions";
import { actionFetchAssesmentValidationStatusData } from "containers/assessment-create/actions";

function* watchMasterMenuData() {
  yield takeLatest(
    ActionTypes.HEADER_MASTER_MENU_FETCH_DATA,
    getMasterMenuData
  );
}

function* getMasterMenuData(action: any) {
  try {
    const response = yield call(fetchMasterMenuData, action.payload.lookUp);
    const responseData = response.data;
    yield put(actionFetchMasterMenuDataSuccess(responseData));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchLoginUserData() {
  yield takeLatest(ActionTypes.HEADER_LOGIN_USER_FETCH_DATA, getUserLoginData);
}

function* getUserLoginData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    yield put(actionFetchMasterMenuData("-1"));
    yield put(actionFetchCountryData());
    yield put(actionFetchMasterMaturityIndexData(-1));
    yield put(actionFetchDynamicFormDataDetail(-1));
    yield put(actionFetchAssesmentValidationStatusData("-1"));
    const response = yield call(fetchLoginData);
    const responseData = response.data;
    sessionStorage.setItem(AUTH_TOKEN, responseData.token);
    yield put(actionFetchLoginDataSuccess(responseData));
    if (responseData.role) {
      yield put(actionFetchUserIdSettingProfileData(responseData.id));
    }
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

export default function* headerSaga() {
  yield all([watchMasterMenuData(), watchLoginUserData()]);
}
