import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import {
  featchUserAutheticationStatus,
  featchUserSCSaccessRequest,
  fetchUserProfileData,
  postUserProcessAccessRequest,
  saveUserProfileData,
  saveUserProfileSettingData,
} from "./api";
import {
  actionFetchUserProfileDataSuccess,
  actionSaveUserProfileDataSuccess,
  actionFetchUserProfileData,
  actionFetchUserIdProfileDataSuccess,
  actionSaveUnRegisterUserDataSuccess,
  actionFetchUserIdSettingProfileDataSuccess,
  actionUpdateUserSettingSuccess,

  actionSetAuthenticationSuccess,
  actionFetchSCSuserAccessRequestedSuccess,
  actionUserProcessRequestSuccess,
  actionFetchUserIdProfileData,
} from "./actions";
import { ActionTypes } from "./constants";
import {
  setLoaderVisibility,
  openToaster,
  closeToaster,
} from "../../store/common/actions";
import {
  API_ERROR_MESSAGE,
  API_ERROR_USER_EXIST,
  DATA_SAVE_SUCCESS,
  DATA_UPDATE_SUCCESS,
} from "../../store/common/message";
import { getLocalDateFromMoment } from "../../utils/data";
import { COMMON_CONSTANT, IS_USER_EXIST } from "constants/data";

function* watchGetUserProfileData() {
  yield takeLatest(
    ActionTypes.USER_PROFILE_USER_FETCH_DATA,
    getUserProfileData
  );
}

function* getUserProfileData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(fetchUserProfileData, action.payload.lookUp);
    const responseData = response.data;
    if (responseData && responseData.length) {
      responseData.map(
        (up: any) => (up.createdDate = getLocalDateFromMoment(up.createdDate))
      );
    }
    yield put(actionFetchUserProfileDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetUserIdProfileData() {
  yield takeLatest(
    ActionTypes.USER_PROFILE_USER_ID_FETCH_DATA,
    getUserIdProfileData
  );
}

function* getUserIdProfileData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(fetchUserProfileData, action.payload.lookUp);
    const responseData = response.data;
    yield put(actionFetchUserIdProfileDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchSaveUserProfileData() {
  yield takeLatest(
    ActionTypes.USER_PROFILE_SAVE_USER_DATA,
    saveUSerProfileData
  );
}

function* saveUSerProfileData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveUserProfileData,
      action.payload.userProfileData
    );
    if (response) {
      const responseData = response.data;
      yield put(actionSaveUserProfileDataSuccess(responseData));
      if (responseData === "") {
        yield put(actionFetchUserProfileData("-1"));

        if (
          action.payload.userProfileData.id !== undefined &&
          action.payload.userProfileData.id !== ""
        ) {
          yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
        } else {
          yield put(openToaster(DATA_SAVE_SUCCESS(), true));
        }
      } else {
        yield put(openToaster(responseData, false));
      }
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    if (err.response.data.detail === IS_USER_EXIST) {
      yield put(openToaster(API_ERROR_USER_EXIST(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }

    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchSaveUnRegisterUserData() {
  yield takeLatest(
    ActionTypes.UNREGISTER_USER_SAVE_DATA,
    saveUnRegisterUSerData
  );
}

function* saveUnRegisterUSerData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveUserProfileData,
      action.payload.userProfileData
    );
    const responseData = response.data;
    yield put(actionSaveUnRegisterUserDataSuccess(responseData));

    if (responseData !== undefined && responseData !== "") {
      yield put(openToaster(responseData, true));
    } else {
      yield put(openToaster("User registration sent", true));
    }

    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetUserIdProfileSettingData() {
  yield takeLatest(
    ActionTypes.USER_PROFILE_SETTING_USER_ID_DATA,
    getUserIdProfileSettingData
  );
}

function* getUserIdProfileSettingData(action: any) {
  try {
    const response = yield call(fetchUserProfileData, action.payload.lookUp);
    const responseData = response.data;
    yield put(actionFetchUserIdSettingProfileDataSuccess(responseData));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateUserSetting() {
  yield takeLatest(ActionTypes.USER_PROFILE_UPDATE_SETTING, updateUserSetting);
}

function* updateUserSetting(action: any) {
  try {
    const response = yield call(
      saveUserProfileSettingData,
      action.payload.userSetting
    );
    const responseData = response.data;
    yield put(actionUpdateUserSettingSuccess(responseData));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetIsUserIsAuthenticated() {
  yield takeLatest(ActionTypes.USER_PROFILE_FETCH_IS_USER_AUTHETICATED, getIsUserIsAuthenticated);
}

function* getIsUserIsAuthenticated(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(featchUserAutheticationStatus);
    const responseData = response.data;
    yield put(actionSetAuthenticationSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchGetScsUserAccessRequestedData() {
  yield takeLatest(ActionTypes.USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED, getScsUserAccessRequestedData);
}

function* getScsUserAccessRequestedData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(featchUserSCSaccessRequest);
    const responseData = response.data;
    yield put(actionFetchSCSuserAccessRequestedSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchProcessScsAccessRequest() {
  yield takeLatest(ActionTypes.USER_PROFILE_SCS_PROCESS_REQUESTED, processScsAccessRequest);
}

function* processScsAccessRequest(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(postUserProcessAccessRequest, action.payload.data);
    const responseData = response.data;
    if (responseData.isSuccess) {
      yield put(actionUserProcessRequestSuccess(responseData));
      if (responseData.userId) {
        yield put(actionFetchUserIdProfileData(responseData.userId));
      } else {
        yield put(setLoaderVisibility(false));
      }
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

export default function* userProfileSaga() {
  yield all([
    watchGetUserProfileData(),
    watchSaveUserProfileData(),
    watchGetUserIdProfileData(),
    watchSaveUnRegisterUserData(),
    watchGetUserIdProfileSettingData(),
    watchUpdateUserSetting(),
    watchGetIsUserIsAuthenticated(),
    watchGetScsUserAccessRequestedData(),
    watchProcessScsAccessRequest()
  ]);
}
