import React from "react";
import { Typography } from "@material-ui/core";
import CarouselComponent from "components/common/carousel.component";

import { ScsImageSlider } from "./assets/styles";
import ImageOne from "./assets/slideimages/UN043497.jpg";
import ImageTwo from "./assets/slideimages/UN043869.jpg";
import ImageThree from "./assets/slideimages/UN0309454.jpg";
import ImageFour from "./assets/slideimages/UN0339383.jpg";
import ImageFive from "./assets/slideimages/UN0428895.jpg";
import ImageSix from "./assets/slideimages/UN0509466.jpg";
import ImageSeven from "./assets/slideimages/UN0549928.jpg";
import ImageEight from "./assets/slideimages/UN0579231.jpg";
import ImageNine from "./assets/slideimages/UN0610440.jpg";
import ImageTen from "./assets/slideimages/UNI159379.jpg";
import ImageEleven from "./assets/slideimages/UNI366076.jpg";

function ScsLandingPage(props: any) {
  const imageList = [
    {
      id: 1,
      url: ImageOne,
      caption: "ImageOne",
    },
    {
      id: 2,
      url: ImageTwo,
      caption: "ImageTwo",
    },
    {
      id: 3,
      url: ImageThree,
      caption: "ImageThree",
    },
    {
      id: 4,
      url: ImageFour,
      caption: "ImageFour",
    },
    {
      id: 5,
      url: ImageFive,
      caption: "ImageFive",
    },
    {
      id: 6,
      url: ImageSix,
      caption: "ImageSix",
    },
    {
      id: 7,
      url: ImageSeven,
      caption: "ImageSeven",
    },
    {
      id: 8,
      url: ImageEight,
      caption: "ImageEight",
    },
    {
      id: 9,
      url: ImageNine,
      caption: "ImageNine",
    },
    {
      id: 10,
      url: ImageTen,
      caption: "ImageTen",
    },
    {
      id: 11,
      url: ImageEleven,
      caption: "ImageEleven",
    },
  ];

  return (
    <>
      <ScsImageSlider>
        <Typography component="div" className="slider-container">
          <CarouselComponent imageList={imageList} />
        </Typography>
      </ScsImageSlider>
    </>
  );
}

export default ScsLandingPage;
