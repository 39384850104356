import React from "react";
import { Typography } from "@material-ui/core";
import { LPTitle, SCSHubAppBlock } from "../assets/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionSetApplicationDetails } from "containers/header/actions";
import { actionPostApplicationLog } from "scs-hub/actions";
// import logo from "../assets/images/uniceflogo.svg";

function ApplicationList(props: any) {
    const { goToSCMM, listOfApp, scsUserDetails } = props;
    const { t } = useTranslation();
    const applicationData: any = (window as any).applicationData;
    const dispatch = useDispatch();

    const handleAppClick = (appDetails: any, appPresent: any) => {
        const isActiveAppExists = (appDetails.aliaseName === applicationData[0].aliaseName) || (appDetails.aliaseName === applicationData[1].aliaseName);
        if (appPresent.length && isActiveAppExists) {
            dispatch(actionSetApplicationDetails(appDetails))
            goToSCMM(appDetails);
            dispatch(actionPostApplicationLog({ applicationId: appDetails.id }));
        }
    }

    const createListOfApplication = () => {
        return listOfApp.map((appName: any, index: any) => {
            let isAppClickable = false;
            const appPresent = scsUserDetails.applications.filter((aap: any) =>
                aap.id === appName.id
            )||[];

            isAppClickable = appPresent.length>0 && appPresent[0].roles.length>0

            const currentApp = applicationData.filter((aap: any) =>
                aap.id === appName.id
            );

            return (
                <Typography
                    key={index}
                    component="div"
                    style={{ backgroundColor: currentApp[0].color }}
                    className={`scs-app-item ${isAppClickable ? 'scs-app-item-active' : ''}`}
                    onClick={(event: any) => handleAppClick(appName, appPresent)}>
                    <Typography component="div" className="scs-header">
                        <Typography component="div" className="scs-app-icon">
                            <Typography component="img" src={currentApp[0].iconPath} alt={appName.name} />
                        </Typography>
                        <Typography component="h2" className="scs-app-title">{appName.name}</Typography>
                    </Typography>
                    <Typography component="div" className="scs-details">
                        <Typography component="h2" className="scs-app-title">{appName.name}</Typography>
                        <Typography component="p" className="scs-app-desc">
                            {t(`scsHub.${currentApp[0].descKey}`)}
                        </Typography>
                    </Typography>
                </Typography>
            )
        })
    }

    return (
        <>
            <LPTitle>
                <Typography component="h1">{t("scsHub.appListTitle")}</Typography>
                <Typography component="p">{t("scsHub.appListDesc")}</Typography>
            </LPTitle>
            <SCSHubAppBlock>
                {createListOfApplication()}
            </SCSHubAppBlock >
        </>
    );
}
export default ApplicationList;