import { MAP_CONSTANTS } from "constants/data";
import { ActionTypes } from "./constants";

const initialState = {
  adminAssesmentData: [],
  adminSelectedRegion: "",
  adminSelectedCountry: "",
  adminMapView: MAP_CONSTANTS.REGION,
  adminAllAssesmentStatus: [],
  miCountryData: [],
  miRegionData: [],
};

export const adminRsaReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.ADMIN_SELECTED_REGION:
      return {
        ...state,
        adminSelectedRegion: action.payload.data || [],
      };

    case ActionTypes.ADMIN_SELECTED_COUNTRY:
      return {
        ...state,
        adminSelectedCountry: action.payload.data || [],
      };

    case ActionTypes.ADMIN_FETCH_ASSESSMENT_DATA_SUCESS:
      return {
        ...state,
        adminAssesmentData: action.payload.data || [],
      };

    case ActionTypes.ADMIN_MAP_VIEW:
      return {
        ...state,
        adminMapView: action.payload.data || [],
      };

    case ActionTypes.ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT_SUCESS:
      return {
        ...state,
        adminAllAssesmentStatus: action.payload.data || [],
      };
    case ActionTypes.ADMIN_MAP_COUNTRY_AVG_MI_DATA:
      return {
        ...state,
        miCountryData: action.payload.data || [],
      };
    case ActionTypes.ADMIN_MAP_REGION_AVG_MI_DATA:
      return {
        ...state,
        miRegionData: action.payload.data || [],
      };
    default:
      return state;
  }
};
