import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { GridTableWrapper } from "../style";
import AssesmentCreateTableComponent from "../assessment-create-table";
import AssessmentOverviewDetails from "containers/assessment-overview-details";
import AssessmentOverviewTools from "containers/assessment-overview-tools";
import { useTranslation } from "react-i18next";
import { ScmmAppBar, BoxShadowWrapper } from "styles/common";
import { useSelector, useDispatch } from 'react-redux';
import { actionSaveClickedStatus } from "../actions";
import { ASSESSMENT, USER } from "constants/data";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AssessmentCreateTabComponent(props: any) {
  const {
    assesmentData,
    functionEditAssesmentDetailData,
    functionAssesmentLevelData,
    formTitle,
    setIsEditAssessment,
    selectedRole,
    checkAssessmentStatusLabel,
    setIsPendingValidation,
    setAssessmentTotalCount,
    canShowOverview,
    setCanShowOverview,
    setTabValueSelected,
    tabValueSelected,
    setLoaderVisibility
  } = props;
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const [editorDraftData, setEditorDraftData] = useState([]);
  const [lableEditorDraft, setlableEditorDraft]: any = useState(0);
  const [reAssignedData, setReAssignedData] = useState([]);
  const [lableReAssigened, setlableReAssigened]: any = useState(0);
  const [sendForValidationData, setsendForValidationData] = useState([]);
  const [lableSendForValidation, setlableSendForValidation]: any = useState(0);
  const [validatedData, setValidatedData] = useState([]);
  const [lableValidated, setlableValidated]: any = useState(0);
  const [
    validatorInProgressDraftData,
    setValidatorInProgressDraftData,
  ] = useState([]);
  const [
    labelValidatorInProgressDraft,
    setlabelValidatorInProgressDraft,
  ]: any = useState(0);
  const dispatch = useDispatch();

  const clickedStatus = useSelector(
    (state: any) => state.assesmentCreate.clickedStatus
  );

  useEffect(() => {
    if (clickedStatus !== null) {
      if (tabValue === clickedStatus) {
        setCanShowOverview(canShowOverview);
      } else {
        setCanShowOverview(false);
      }
      setTabValue(clickedStatus);
      setTabValueSelected(clickedStatus);
      dispatch(actionSaveClickedStatus(null));
    }
  }, [clickedStatus])
  useEffect(() => {
    handleTabLevelData_RoleWise();
  }, [assesmentData]);

  const handleTabLevelData_RoleWise = () => {
    if (
      selectedRole.toLowerCase().trim() ===
      USER.ROLE.ASSESSOR.toLowerCase().trim()
    ) {
      tabLevelData_Assessor();
    } else {
      tabLevelData_Validator();
    }
    setTabValue(tabValueSelected);
    dispatch(setLoaderVisibility(false));
  };

  const tabLevelData_Assessor = () => {
    const assDraftData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.DRAFT
    );
    setEditorDraftData(assDraftData);
    setlableEditorDraft(`(${assDraftData.length})`);

    const assReAssgined = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED
    );
    setReAssignedData(assReAssgined);
    setlableReAssigened(`(${assReAssgined.length})`);

    const assSendForValidation = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId ===
        ASSESSMENT.STATUS.SEND_FOR_VALIDATION ||
        assesement.assessmentStatusId ===
        ASSESSMENT.STATUS.IN_PROGRESS_VALIDATION
    );
    setsendForValidationData(assSendForValidation);
    setlableSendForValidation(`(${assSendForValidation.length})`);

    const assValidated = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.VALIDATED
    );
    setValidatedData(assValidated);
    setlableValidated(`(${assValidated.length})`);
    setAssessmentTotalCount(assesmentData.length);
  };

  const tabLevelData_Validator = () => {
    const assDraftData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.DRAFT
    );

    const valDraftData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.SEND_FOR_VALIDATION
    );
    setEditorDraftData(valDraftData);
    setlableEditorDraft(`(${valDraftData.length})`);

    const valDraftInProgressData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId ===
        ASSESSMENT.STATUS.IN_PROGRESS_VALIDATION
    );
    setValidatorInProgressDraftData(valDraftInProgressData);
    setlabelValidatorInProgressDraft(`(${valDraftInProgressData.length})`);

    const assReAssgined = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED
    );
    setReAssignedData(assReAssgined);
    setlableReAssigened(`(${assReAssgined.length})`);

    const assValidated = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.VALIDATED
    );
    setValidatedData(assValidated);
    setlableValidated(`(${assValidated.length})`);
    const totalAssessment = assesmentData.length - assDraftData.length;
    setAssessmentTotalCount(`${totalAssessment}`);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (tabValue === newValue) {
      setCanShowOverview(canShowOverview);
    } else {
      setCanShowOverview(false);
    }
    setTabValue(newValue);
    setTabValueSelected(newValue);
  };

  const tabs_Assessor = () => {
    return (
      <>
        <ScmmAppBar position="static">
          <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs">
            <Tab
              label={`${t("assessment.assessorsDraft")} ${lableEditorDraft}`}
              {...a11yProps(0)}
            />
            <Tab
              label={`${t("assessment.reAssigned")} ${lableReAssigened}`}
              {...a11yProps(1)}
            />
            <Tab
              label={`${t(
                "assessment.sentForValidation"
              )} ${lableSendForValidation}`}
              {...a11yProps(2)}
            />
            <Tab
              label={`${t("assessment.validated")} ${lableValidated}`}
              {...a11yProps(3)}
            />
          </Tabs>
        </ScmmAppBar>
        <TabPanel value={tabValue} index={0}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={editorDraftData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={true}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
              isDraftTab={true}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={reAssignedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={true}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
              isDraftTab={false}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={sendForValidationData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={false}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
              isDraftTab={false}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={validatedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={false}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
              isDraftTab={false}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
      </>
    );
  };

  const tabs_Validator = () => {
    return (
      <>
        <ScmmAppBar position="static">
          <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs">
            <Tab
              label={`${t("assessment.pendingValidation")} ${lableEditorDraft}`}
              {...a11yProps(0)}
            />
            <Tab
              label={`${t(
                "assessment.inProgressValidation"
              )} ${labelValidatorInProgressDraft}`}
              {...a11yProps(1)}
            />
            <Tab
              label={`${t("assessment.reAssigned")} ${lableReAssigened}`}
              {...a11yProps(2)}
            />
            <Tab
              label={`${t("assessment.validated")} ${lableValidated}`}
              {...a11yProps(3)}
            />
          </Tabs>
        </ScmmAppBar>
        <TabPanel value={tabValue} index={0}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={editorDraftData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={true}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={true}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={validatorInProgressDraftData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={true}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={reAssignedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={false}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <GridTableWrapper>
            <AssesmentCreateTableComponent
              assessmentFilterData={validatedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              isEditAssessment={false}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
            ></AssesmentCreateTableComponent>
          </GridTableWrapper>
        </TabPanel>
      </>
    );
  };
  return (
    <>
      <BoxShadowWrapper>
        {selectedRole.toLowerCase().trim() ===
          USER.ROLE.ASSESSOR.toLowerCase().trim()
          ? tabs_Assessor()
          : tabs_Validator()}
      </BoxShadowWrapper>
      {
        tabValue === 3 && selectedRole.toLowerCase().trim() !==
          USER.ROLE.GLOBAL_ADVISOR.toLowerCase().trim() ? (
          <>
            <AssessmentOverviewTools
              canShowOverview={canShowOverview}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
            ></AssessmentOverviewTools>
          </>) : (
          <>
            <AssessmentOverviewDetails
              canShowOverview={canShowOverview}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
            ></AssessmentOverviewDetails>
          </>)
      }

    </>
  );
}
