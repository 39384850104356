import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector,useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import "./index.css";
import { useHistory } from "react-router-dom";
import { HOME_PATH } from "../../constants/api";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { PreLoginContainer } from "../../styles/common";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { actionSetCurrentRoute } from "store/common/actions";

function PreLoginPage(props: any) {
  const { dialogClose, loginRedirectMethod } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();  
  const loginUserData = useSelector((state: any) => state.header.loginUserData);

  const [checkBoxState, setcheckBoxState] = useState(false);
  const handleProceedToLogin = (event: any) => {
    loginRedirectMethod();
    dialogClose();
  };

  const handleBackToHome = (event: any) => {
    history.push(HOME_PATH);
    dispatch(actionSetCurrentRoute(HOME_PATH));
    dialogClose();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkBoxState) {
      setcheckBoxState(false);
      localStorage.setItem("checkBoxState", "false");
    } else {
      setcheckBoxState(true);
      localStorage.setItem("checkBoxState", "true");
    }
  };

  return (
    <>
      <PreLoginContainer>
        <Typography component="div" className="prelogin-inner">
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: t("preLogin.pageHeader") }}
          />
          <Typography component="div" className={"prelogin-body"}>
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: t("preLogin.pageText") }}
            />
          </Typography>
        </Typography>
        <Typography component="div" className={"prelogin-footer"}>
          <Grid container>
            <Grid item xs={12} className={"txt-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={t("preLogin.dialogMsg")}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="paddingB24 padding-top-10"
          >
            <Grid item className="paddingLR24">
              <Button
                variant="outlined"
                color="primary"
                className="btn-backtohome"
                onClick={handleBackToHome}
              >
                {t("preLogin.backToHome")}
              </Button>
            </Grid>
            {loginUserData === null && (
              <Grid item className="paddingLR24">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProceedToLogin}
                  className="btn-login"
                >
                  {t("preLogin.proceedToLogin")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Typography>
      </PreLoginContainer>
    </>
  );
}

export default PreLoginPage;
