import { ActionTypes } from "./constants";


export const actionFetchApplicationsList = () => ({
  payload: {},
  type: ActionTypes.SCS_HUB_GET_APPLICATIONS_LIST,
});

export const actionFetchApplicationsListSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_GET_APPLICATIONS_LIST_SUCCESS,
});

export const actionFetchSCSUserDetails = () => ({
  payload: {},
  type: ActionTypes.SCS_HUB_GET_USER_DETAILS,
});

export const actionFetchSCSUserDetailsSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_GET_USER_DETAILS_SUCCESS,
});

export const actionPostAccessRequest = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_POST_ACCESS_REQUEST,
});

export const actionPostAccessRequestSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_POST_ACCESS_REQUEST_SUCCESS,
});

export const actionPostApplicationLog = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_POST_APP_LOG_HISTORY,
});

export const actionSetShowSCMM = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_SHOW_SCMM,
});

export const actionSetShowAppList = (data: any) => ({
  payload: { data },
  type: ActionTypes.SCS_HUB_SHOW_APP_LIST,
});
