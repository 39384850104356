import { ActionTypes } from "./constants";

export const actionFetchMasterMenuFormFieldDetail = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA,
});

export const actionFetchMasterMenuFormFieldDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA_SUCCESS,
});

export const actionFetchMasterMenuAllDynamicFieldAndDataDetail = (
  lookUp: any
) => ({
  payload: { lookUp },
  type: ActionTypes.MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA,
});

export const actionFetchMasterMenuAllDynamicFieldAndDataDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA_SUCCESS,
});

export const actionFetchDynamicFormDataDetail = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA,
});

export const actionFetchDynamicFormDataDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA_SUCCESS,
});

export const actionSaveMasterDynamicFormFieldDataDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA,
});

export const actionSaveMasterDynamicFormFieldDataDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA_SUCCESS,
});

export const actionDeleteMasterDynamicFormFieldDataDetail = (
  formData: any
) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA,
});

export const actionDeleteMasterDynamicFormFieldDataDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA_SUCCESS,
});

export const actionFetchMasterMaturityIndexData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.MASTER_MATURITY_INDEX_DATA,
});

export const actionFetchMasterMaturityIndexDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MATURITY_INDEX_DATA_SUCCESS,
});

export const actionSaveMasterMaturityIndexDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MATURITY_INDEX_SAVE,
});

export const actionSaveMasterMaturityIndexDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MATURITY_INDEX_SAVE_SUCCESS,
});

export const actionDeleteMasterMaturityIndexDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MATURITY_INDEX_DELETE,
});

export const actionDeleteMasterMaturityIndexDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MATURITY_INDEX_DELETE_SUCCESS,
});

export const actionIsMaturityIndexCall = (payload: boolean) => ({
  payload,
  type: ActionTypes.MASTER_MATURITY_INDEX_CALL,
});

export const actionUpdateMasterMaturityIndexData = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MATURITY_INDEX_DATA_UPDATE,
});


export const actionFetchMasterAreaOfWorkToolData = (lookUp: any, roleId: any) => ({
  payload: { lookUp, roleId },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA,
});

export const actionFetchMasterAreaOfWorkToolDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA_SUCCESS,
});

export const actionFetchMasterAreaOfWorkToolSingleData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA,
});

export const actionFetchMasterAreaOfWorkToolSingleDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA_SUCCESS,
});

export const actionSaveMasterAreaOfWorkToolDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_SAVE,
});

export const actionSaveMasterAreaOfWorkToolDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_SAVE_SUCCESS,
});

export const actionDeleteMasterAreaOfWorkToolDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_DELETE,
});

export const actionDeleteMasterAreaOfWorkToolDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_DELETE_SUCCESS,
});

export const actionAreaOfWorkToolCall = (payload: boolean) => ({
  payload,
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_CALL,
});

export const actionUpdateMasterAreaOfWorkToolData = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA_UPDATE,
});

export const actionUpdateMasterAreaOfWorkToolDataSuccess = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOL_UPDATE_SUCCESS,
});

export const actionFetchAOWtopicHierarchy = () => ({
  payload: {},
  type: ActionTypes.MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY,
});
export const actionFetchAOWtopicHierarchySuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY_SUCCESS,
});

export const actionFetchAOWtopicDetails = (type: any, formId: any, topicId: any) => ({
  payload: { type, formId, topicId },
  type: ActionTypes.MASTER_AOW_TOPIC_DETAILS,
});
export const actionFetchAOWtopicDetailsSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_AOW_TOPIC_DETAILS_SUCCESS,
});

export const actionFetchMasterAreaOfWorkToolsData = () => ({
  payload: {},
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOLS_DATA,
});

export const actionFetchMasterAreaOfWorkToolsDataSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_AREA_OF_WORK_TOOLS_DATA_SUCCESS,
});

export const actionSaveAOWOrtopicDetails = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_SAVE_AOW_OR_TOPIC,
});
export const actionSaveAOWOrtopicDetailsSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_SAVE_AOW_OR_TOPIC_SUCCESS,
});

export const actionFetchMasterCountryHierarchyData = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_GET_COUNTRY_HIERARCHY,
});

export const actionFetchMasterCountryHierarchyDataSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_GET_COUNTRY_HIERARCHY_SUCCESS,
});

export const actionSaveMasterCountryHierarchyData = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_SAVE_COUNTRY_HIERARCHY,
});

export const actionUpdateMasterCountryHierarchyData = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_UPDATE_COUNTRY_HIERARCHY,
});

export const actionDeleteMasterCountryHierarchyData = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_DELETE_COUNTRY_HIERARCHY,
});

export const actionDeleteMasterTopicAowHierarchyData = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_DELETE_TOPIC_AOW_HIERARCHY,
});

export const actionExportReportTableData = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_MENU_EXPORT_REPORT_TABLE_DATA,
});

export const actionExportReportTableDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.MASTER_MENU_EXPORT_REPORT_TABLE_DATA_SUCCESS,
});

export const actionUserAndCountryList = () => ({
  payload: {},
  type: ActionTypes.MASTER_USER_COUNTRY_LIST,
});

export const actionUserAndCountryListSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_USER_COUNTRY_LIST_SUCCESS,
});

export const actionGetVCReportStats = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.MASTER_VC_REPORT_STATS,
});

export const actionGetVCReportStatsSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.MASTER_VC_REPORT_STATS_SUCCESS,
});