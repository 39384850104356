import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import "../index.css";
import { commonCellStyle, commonHeaderStyle, } from "../../../styles/materialStyles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from "@material-ui/core";
import { CheckIconCustom, CloseIconGray, DialogButtonDefault, DialogButtonPrimary } from "styles/common";
import { FormContext, useForm } from "react-hook-form";
import FormInput from "components/form-controls/input";
import _ from "lodash";
import { actionFetchSCSuserAccessRequested, actionUserProcessRequest, actionUserProcessRequestSuccess } from "../actions";

function UserRequestedTableComponent(props: any) {
  const { scsHubAccessRequestedList } = props;
  const { t } = useTranslation();
  let selectedRow: any = null;
  const dispatch = useDispatch();
  const methods = useForm();
  const { handleSubmit, setValue } = methods;

  const userRequestResponce = useSelector(
    (state: any) => state.userProfile.userRequestResponce
  );
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [showApprove, setShowApprove] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(userRequestResponce)) {
      if (!userRequestResponce.userId) {
        handleClose();
      }
      dispatch(actionFetchSCSuserAccessRequested());
      dispatch(actionUserProcessRequestSuccess(null));
    }
  }, [userRequestResponce])

  const handlerSerialNumberRowData = (row: any) => {
    return row["tableData"].id + 1;
  };

  const handleClickOpen = () => {
    setOpenCommentDialog(true);
  };

  const handleClose = () => {
    setOpenCommentDialog(false);
  };

  const handelOpenComment = (row: any, isApprove: any) => {
    setShowApprove(isApprove);
    handleClickOpen();
    setTimeout(() => {
      setValue("id", row.id);
    }, 1000);
  }

  const requestProccess = (data: any) => {
    const formData = {
      "requestId": data.id,
      "acceptRequest": showApprove,
      "comment": data.comment
    }
    dispatch(actionUserProcessRequest(formData));
  }


  return (
    <>
      <Dialog
        open={openCommentDialog}
        onClose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={"sm"}
        fullWidth={true}>
        {showApprove ? (
          <DialogTitle>{t("userProfile.approveAccessRequest")}</DialogTitle>
        ) : (
          <DialogTitle>{t("userProfile.rejectAccessRequest")}</DialogTitle>
        )}
        <DialogContent dividers>
          <FormContext {...methods}>
            <FormInput
              className="hiddenTextFiled"
              type="hidden"
              name="id"
            />
            <FormInput
              type="text"
              name="comment"
              label="Comment"
              fullWidth
            />
          </FormContext>

        </DialogContent>
        <DialogActions className="padding16-24">
          <DialogButtonPrimary variant="contained" color="primary" onClick={handleSubmit(requestProccess)}>{showApprove ? t("common.approve") : t("common.reject")}</DialogButtonPrimary>
          <DialogButtonDefault onClick={handleClose}>{t("common.cancel")}</DialogButtonDefault>
        </DialogActions>
      </Dialog>
      <MaterialTable
        columns={[
          {
            title: "No.",
            field: "id",
            filtering: false,
            sorting: false,
            render: (tblProps) => {
              let rowData = handlerSerialNumberRowData(tblProps);
              return <Tooltip title={rowData}><Typography component={"span"}>{rowData}</Typography></Tooltip>;
            },
            cellStyle: commonCellStyle,
          },
          {
            title: t("userProfile.firstName"),
            field: "firstName",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "150px",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.firstName}>
                <Typography component="span">
                  {rowData.firstName}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("userProfile.lastName"),
            field: "lastName",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "150px",
              maxWidth: "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.lastName}>
                <Typography component="span">
                  {rowData.lastName}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("common.email"),
            field: "email",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "180px",
              maxWidth: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.email}>
                <Typography component="span">
                  {rowData.email}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("common.org"),
            field: "orgnization",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "100px",
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.orgnization}>
                <Typography component="span">
                  {rowData.orgnization}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("userProfile.countryDutyStation"),
            field: "countryDutyStation",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "100px",
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.countryDutyStation}>
                <Typography component="span">
                  {rowData.countryDutyStation}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("userProfile.reasonForApplying"),
            field: "reasonForApplying",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "170px",
              maxWidth: "170px",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.reasonForApplying}>
                <Typography component="span">
                  {rowData.reasonForApplying}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("common.system"),
            field: "applicationName",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "100px",
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (tblProps) => {
              return <>
                {
                  <Tooltip title={tblProps.applicationName}>
                    <Typography component="span">
                      {tblProps.applicationName}
                    </Typography>
                  </Tooltip>
                }
              </>;
            },
          },
          {
            title: t("userProfile.preferredRole"),
            field: "preferredRole",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "100px",
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.preferredRole}>
                <Typography component="span">
                  {rowData.preferredRole}
                </Typography>
              </Tooltip>
            ),
          },
          {
            title: t("userProfile.preferredProgram"),
            field: "preferredProgram",
            filtering: true,
            cellStyle: {
              ...commonCellStyle,
              minWidth: "100px",
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            render: (rowData: any) => (
              <Tooltip title={rowData.preferredProgram}>
                <Typography component="span">
                  {rowData.preferredProgram}
                </Typography>
              </Tooltip>
            ),
          }
        ]}
        data={scsHubAccessRequestedList}
        options={{
          filtering: true,
          toolbar: false,
          pageSizeOptions: [10, 20, 50],
          pageSize: 10,
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          detailPanelColumnAlignment: "left",
          detailPanelType: "single",
          doubleHorizontalScroll: true,
          actionsCellStyle: commonCellStyle,
          headerStyle: {
            ...commonHeaderStyle,
            whiteSpace: "normal"
          },
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#f6fbfd" : "#FFF",
            borderBottomColor: "#fff",
          }),
        }}
        actions={[
          {
            icon: () => <CheckIconCustom className="arRotate270" />,
            tooltip: t("common.approve"),
            onClick: (event, rowData) => handelOpenComment(rowData, true),
          },
          {
            icon: () => <CloseIconGray className="arRotate270" />,
            tooltip: t("common.reject"),
            onClick: (event, rowData) => handelOpenComment(rowData, false),
          },
        ]}
        localization={{
          header: {
            actions: t("common.action"),
          },
          body: {
            filterRow: {
              filterTooltip: t("pagination.filter"),
            },
            emptyDataSourceMessage: t("common.noDataAvailable")
          },
          pagination: {
            firstTooltip: t("pagination.firstPage"),
            previousTooltip: t("pagination.prevPage"),
            nextTooltip: t("pagination.nextPage"),
            lastTooltip: t("pagination.lastPage"),
            labelRowsSelect: t("common.rows"),
          },
        }}
      />
    </>
  );
}

export default UserRequestedTableComponent;
