export const ActionTypes = {
  QUESTION_GET_QUESTION_SET_DATA: "QUESTION_GET_QUESTION_SET_DATA",
  QUESTION_GET_QUESTION_SET_DATA_SUCCESS:
    "QUESTION_GET_QUESTION_SET_DATA_SUCCESS",

  QUESTION_GET_QUESTION_SET_BY_ID_DATA: "QUESTION_GET_QUESTION_SET_BY_ID_DATA",
  QUESTION_GET_QUESTION_SET_BY_ID_DATA_SUCCESS:
    "QUESTION_GET_QUESTION_SET_BY_ID_DATA_SUCCESS",

  QUESTION_GET_QUESTION_SET_CODE_DATA: "QUESTION_GET_QUESTION_SET_CODE_DATA",
  QUESTION_GET_QUESTION_SET_CODE_DATA_SUCCESS:
    "QUESTION_GET_QUESTION_SET_CODE_DATA_SUCCESS",

  QUESTION_SAVE_QUESTION_SET_DATA: "QUESTION_SAVE_QUESTION_SET_DATA",
  QUESTION_SAVE_QUESTION_SET_DATA_SUCCESS:
    "QUESTION_SAVE_QUESTION_SET_DATA_SUCCESS",

  QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA:
    "QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA",
  QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA_SUCCESS:
    "QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA_SUCCESS",

  QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA:
    "QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA",
  QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA_SUCCESS:
    "QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA_SUCCESS",

  QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA:
    "QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA",
  QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA_SUCCESS:
    "QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA_SUCCESS",

  QUESTION_SAVE_QUESTION_DATA: "QUESTION_SAVE_QUESTION_DATA",
  QUESTION_SAVE_QUESTION_DATA_SUCCESS: "QUESTION_SAVE_QUESTION_DATA_SUCCESS",

  QUESTION_SWIPE_QUESTION_SEQUENCE_DATA:
    "QUESTION_SWIPE_QUESTION_SEQUENCE_DATA",
  QUESTION_SWIPE_QUESTION_SEQUENCE_DATA_SUCCESS:
    "QUESTION_SWIPE_QUESTION_SEQUENCE_DATA_SUCCESS",

  QUESTION_DELETE_QUESTION_DATA: "QUESTION_DELETE_QUESTION_DATA",
  QUESTION_DELETE_QUESTION_DATA_SUCCESS:
    "QUESTION_DELETE_QUESTION_DATA_SUCCESS",

  QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA:
    "QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA",
  QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA_SUCCESS:
    "QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA_SUCCESS",

  QUESTION_COPY_QUESTION_SET_DATA: "QUESTION_COPY_QUESTION_SET_DATA",
  QUESTION_COPY_QUESTION_SET_DATA_SUCCESS:
    "QUESTION_COPY_QUESTION_SET_DATA_SUCCESS",

  QUESTION_DELETE_QUESTION_SET_DATA: "QUESTION_DELETE_QUESTION_SET_DATA",

  QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA:
    "QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA",
  QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS:
    "QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS",

  QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA:
    "QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA",
  QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS:
    "QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS",

  QUESTION_DELETE_INDICATOR_SET_TOPIC: "QUESTION_DELETE_INDICATOR_SET_TOPIC",
  QUESTION_DELETE_INDICATOR_SET_TOPIC_SUCCESS: "QUESTION_DELETE_INDICATOR_SET_TOPIC_SUCCESS",

  QUESTION_GET_NON_ASSIGNED_TOPIC: "QUESTION_GET_NON_ASSIGNED_TOPIC",
  QUESTION_GET_NON_ASSIGNED_TOPIC_SUCCESS: "QUESTION_GET_NON_ASSIGNED_TOPIC_SUCCESS",

  QUESTION_SAVE_NON_ASSIGNED_TOPIC: "QUESTION_SAVE_NON_ASSIGNED_TOPIC",
  QUESTION_SAVE_NON_ASSIGNED_TOPIC_SUCCESS: "QUESTION_SAVE_NON_ASSIGNED_TOPIC_SUCCESS",
};

export const GET_QUESTION_SET_DATA: string = `/api/QuestionSet/GetQuestionSetDetails`;
export const GET_QUESTION_SET_CODE: string = `/api/QuestionSet/GetQuestionSetCode`;
export const SAVE_QUESTION_SET_DATA: string = `/api/QuestionSet/SaveQuestionSetDetails`;
export const COPY_QUESTION_SET_DATA: string = `/api/QuestionSet/CopyQuestionSetDetails`;
export const DELETE_QUESTION_SET_DATA: string = `/api/QuestionSet/DeleteQuestionSetDetails`;

export const GET_QUESTION_BY_QUESTION_SET_ID: string = `/api/QuestionMaster/GetAllDynamicQuestionMasterData`;
export const GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK: string = `/api/QuestionMaster/GetQuestionSetTopicDetails`;
export const SAVE_QUESTION_DATA: string = `/api/QuestionMaster/SaveQuestionDetails`;
export const SWIPE_QUESTION_SEQUENCE_DATA: string = `/api/QuestionMaster/ChangeQuestionSequenceDetails`;
export const DELETE_QUESTION_DATA: string = `/api/QuestionMaster/DeleteQuestionDetails`;
export const GET_QUESTION_BY_QUESTION_ID_QUESTION_DATA: string = `/api/QuestionMaster/GetQuestionDetails`;

export const GET_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA: string = `/api/QuestionSet/GetQuestionAreaLevelComment`;
export const UPDATE_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA: string = `/api/QuestionMaster/UpdateQuestionSetAreaLevelComment`;

export const DELETE_INDICATOR_SET_TOPIC: string = `/api/QuestionMaster/DeleteQuestionSetTopic`;
export const GET_NON_ASSIGNED_TOPICS: string = `/api/QuestionMaster/GetNonAssignedTopicsToQuestionSet`;
export const SAVE_NON_ASSIGNED_TOPICS: string = `/api/QuestionMaster/AddQuestionSetTopic`;

export const LAST_MODIFIED_DATE: string = "Last Modified Date";
export const AREA_OF_WORK: string = "Area of Work";
export const STATUS_DRAFT_CONSTANT: number = 1;
export const STATUS_COMPLETED_CONSTANT: number = 2;

