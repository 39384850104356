import api from "../../utils/api";
import { GET_COUNTRY_DETAIL, GET_USER_ROLE_DETAILS } from "./constants";

/**
 * Function for getting Country data
 */
export const fetchCountryDetails = () => {
  let url = `${GET_COUNTRY_DETAIL}`;
  return api.get(url).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

/**
 * Function for getting User Role Detail data
 */
export const fetchUserRoleData = (lookUp: any) => {
  return api.get(`${GET_USER_ROLE_DETAILS}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};
