import { ActionTypes } from "./constants";

export const actionFetchAllAssesmentData = (data: any) => ({
  payload: { data },
  type: ActionTypes.FETCH_ALL_ASSESSMENT_DETAILS,
});

export const actionFetchAllAssesmentDataSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.FETCH_ALL_ASSESSMENT_DETAILS_SUCCESS,
});

export const actionFetchAssesmentData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ASSESMENT_CREATE_FETCH_ASSEMENT_DATA,
});

export const actionFetchAssesmentDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_CREATE_FETCH_ASSEMENT_DATA_SUCCESS,
});

export const actionSaveAssesmentData = (assesmentData: any) => ({
  payload: { assesmentData },
  type: ActionTypes.ASSESMENT_CREATE_SAVE_ASSESMENT_DATA,
});

export const actionSaveAssesmentDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_CREATE_SAVE_ASSESMENT_DATA_SUCCESS,
});

export const actionFetchAssesmentByIdData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA,
});

export const actionFetchAssesmentByIdDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA_SUCCESS,
});

export const actionUpdateAssesmentValidationStatusData = (
  assesmentData: any
) => ({
  payload: { assesmentData },
  type: ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA,
});

export const actionUpdateAssesmentValidationStatusDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS,
});

export const actionUpdateAssesmentQuestionDetailData = (
  assesmentQuestionData: any
) => ({
  payload: { assesmentQuestionData },
  type: ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA,
});

export const actionUpdateAssesmentQuestionDetailDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA_SUCCESS,
});

export const actionUpdateAssesmentQuestionCommentData = (
  assesmentQuestionCommentData: any
) => ({
  payload: { assesmentQuestionCommentData },
  type: ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA,
});

export const actionUpdateAssesmentQuestionCommentDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA_SUCCESS,
});

export const actionFetchAssesmentLevelByIdData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA,
});

export const actionFetchAssesmentLevelByIdDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA_SUCCESS,
});

export const actionDetailsSaveData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ASSESMENT_DETAILS_SAVED_DATA,
});

export const actionFetchAssesmentValidationStatusData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA,
});

export const actionFetchAssesmentValidationStatusDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA_SUCCESS,
});

export const actionUpdateAssesmentQuestionValidationCommentDetailData = (
  assesmentQuestionData: any
) => ({
  payload: { assesmentQuestionData },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA,
});

export const actionUpdateAssesmentQuestionValidationCommentDetailDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA_SUCCESS,
});

export const actionFetchAssesmentValidationCommentData = (lookUp: any) => ({
  payload: { lookUp },
  type:
    ActionTypes.ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA,
});

export const actionFetchAssesmentValidationCommentDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA_SUCCESS,
});

export const actionUpdateAssesmentQuestionResteValidationStatusData = (
  assesmentQuestionData: any
) => ({
  payload: { assesmentQuestionData },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA,
});

export const actionUpdateAssesmentQuestionResteValidationStatusDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA_SUCCESS,
});

export const actionUpdateAssesmentInProgressValidationStatusData = (
  assesmentData: any
) => ({
  payload: { assesmentData },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA,
});

export const actionUpdateAssesmentInProgressValidationStatusDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS,
});

export const actionUpdateAssesmentQuestionResetAssessorStatusData = (
  assesmentQuestionData: any
) => ({
  payload: { assesmentQuestionData },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA,
});

export const actionUpdateAssesmentQuestionResetAssessorStatusDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA_SUCCESS,
});

export const actionExportAssesmentData = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA,
});

export const actionExportAssesmentDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA_SUCCESS,
});

export const actionSaveClickedStatus = (data: any) => ({
  payload: { data },
  type: ActionTypes.SAVE_CLICKED_STATUS,
});

export const actionAssessmentScoringData = (
  assessmentRequestData: any
) => ({
  payload: { assessmentRequestData },
  type: ActionTypes.ASSESSMENT_SCORING_DATA,
});

export const actionAllAssessmentOverviewData = (
  requestData: any
) => ({
  payload: { requestData },
  type: ActionTypes.ALL_ASSESSMENT_OVERVIEW_DATA,
});


export const actionAssessmentScoringDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESSMENT_SCORING_DATA_SUCCESS,
});

export const actionFetchAssessmentProgramScoreByIdData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID,
});

export const actionFetchAssessmentProgramScoreByIdDataSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID_SUCCESS,
});

export const actionFetchAssessmentReportData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESSMENT_REPORT_DATA,
});

export const actionFetchAssessmentReportSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESSMENT_REPORT_SUCCESS,
});

export const actionFetchAssessmentReportRegionData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.ASSESSMENT_REPORT_REGION_DATA,
});

export const actionFetchAssessmentReportRegionSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESSMENT_REPORT_REGION_SUCCESS,
});

export const actionAssessmentOverviewAVData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ASSESSMENT_OVERVIEW_AV_DATA,
});

export const actionDeleteAssessmentData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ASSESSMENT_DATA_DELETE_BY_ID,
});

export const actionSetEditableScreen = (data: any) => ({
  payload: { data },
  type: ActionTypes.SHOW_ASSESSMENT_TABLE,
});
