import { ActionTypes } from "./constants";

export const actionFetchUserProfileData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.USER_PROFILE_USER_FETCH_DATA,
});

export const actionFetchUserProfileDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.USER_PROFILE_USER_FETCH_DATA_SUCCESS,
});

export const actionSaveUserProfileData = (userProfileData: any) => ({
  payload: { userProfileData },
  type: ActionTypes.USER_PROFILE_SAVE_USER_DATA,
});

export const actionSaveUserProfileDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.USER_PROFILE_SAVE_USER_DATA_SUCCESS,
});

export const actionSaveRegisterUserRoleData = (userRoleData: any) => ({
  payload: { userRoleData },
  type: ActionTypes.REGISTER_USER_SAVE_ROLE_DATA,
});

export const actionFetchUserIdProfileData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.USER_PROFILE_USER_ID_FETCH_DATA,
});

export const actionFetchUserIdProfileDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.USER_PROFILE_USER_ID_FETCH_DATA_SUCCESS,
});

export const actionSaveUnRegisterUserData = (userProfileData: any) => ({
  payload: { userProfileData },
  type: ActionTypes.UNREGISTER_USER_SAVE_DATA,
});

export const actionSaveUnRegisterUserDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.UNREGISTER_USER_SAVE_DATA_SUCCESS,
});

export const actionSaveRegisterUserSelectedRoleData = (selectedObj: any) => ({
  payload: { selectedObj },
  type: ActionTypes.REGISTER_USER_SAVE_SELECTED_ROLE_DATA,
});

export const actionFetchUserIdSettingProfileData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.USER_PROFILE_SETTING_USER_ID_DATA,
});

export const actionFetchUserIdSettingProfileDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.USER_PROFILE_SETTING_USER_ID_DATA_SUCESS,
});

export const actionUpdateUserSetting = (userSetting: any) => ({
  payload: { userSetting },
  type: ActionTypes.USER_PROFILE_UPDATE_SETTING,
});

export const actionUpdateUserSettingSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.USER_PROFILE_UPDATE_SETTING_SUCCESS,
});

export const actionUserProfileSettingPageShow = (payload: boolean) => ({
  payload,
  type: ActionTypes.USER_PROFILE_SETTING_PAGE_SHOW,
});

export const actionFetchAuthentication = () => ({
  type: ActionTypes.USER_PROFILE_FETCH_IS_USER_AUTHETICATED,
});

export const actionSetAuthenticationSuccess = (payload: any) => ({
  payload,
  type: ActionTypes.USER_PROFILE_FETCH_IS_USER_AUTHETICATED_SUCCESS,
});

export const actionFetchSCSuserAccessRequested = () => ({
  type: ActionTypes.USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED,
});

export const actionFetchSCSuserAccessRequestedSuccess = (payload: any) => ({
  payload,
  type: ActionTypes.USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED_SUCCESS,
});

export const actionUserProcessRequest = (data: any) => (
  {
    payload: { data },
    type: ActionTypes.USER_PROFILE_SCS_PROCESS_REQUESTED,
  });

export const actionUserProcessRequestSuccess = (payload: any) => (
  {
    payload,
    type: ActionTypes.USER_PROFILE_SCS_PROCESS_REQUESTED_SUCCESS,
  });