import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionSetPageTitle,
  setLoaderVisibility,
} from "../../store/common/actions";
import { actionHeaderUserSetting } from "../header/actions";
import UnRegisterUserPage from "../unregistered-user";
import RegisterUserPage from "../registered-user";
import { useTranslation } from "react-i18next";
import { Button, Grid, } from "@material-ui/core";
import BackgroundImage from "../../styles/images/HomeBackground.png";
import {
  ResponsiveBgImage,
  HomeRightSideWrapper,
  ApplicationTitle,
  WelcomeText,
  ArrowBackIosIconCustom,
  SCSBackLink,
} from "styles/common";
import Typography from "@material-ui/core/Typography";
import "./index.css";
import { HomeFooterComponent } from "containers/footer";
import { SCS_FULL_NAME } from "scs-hub/constants";
import { actionSetShowAppList, actionSetShowSCMM } from "scs-hub/actions";

function HomePage(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const title = t("menuItems.home");

  const [showPage, setShowPage] = useState(false);

  const isRoleAssigned = useSelector(
    (state: any) => state.header.isRoleAssigned
  );
  const isRegisteredUser = useSelector(
    (state: any) => state.header.isRegisteredUser
  );
  const loginUserData = useSelector((state: any) => state.header.loginUserData);
  const isSettingPageShow = useSelector(
    (state: any) => state.userProfile.isSettingPageShow
  );

  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );

  useEffect(() => {
    if (isSettingPageShow) {
      dispatch(actionSetPageTitle(title));
      const userToken = localStorage.getItem("msal.idtoken");
      if (userToken) {
        dispatch(actionHeaderUserSetting(false));
      }
      dispatch(setLoaderVisibility(false));
      setShowPage(true);
    }
  }, [isSettingPageShow]);

  const handleBackClick = () => {
    dispatch(actionSetShowSCMM(false));
    dispatch(actionSetShowAppList(true));
  }

  return (
    <>
      {showPage && (
        <Grid container alignItems="stretch">
          <Grid item xs={7} className="position-relative">
            <Typography component="div" className="display-flex">
              <ResponsiveBgImage src={BackgroundImage} alt="Background Image" />
              <Typography component="span">
                <ApplicationTitle>
                  {selectedAppDetails ? `${selectedAppDetails.name} (${selectedAppDetails.aliaseName})` : SCS_FULL_NAME}
                </ApplicationTitle>
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={5} className="position-relative">
            {
              !isRoleAssigned &&
              <SCSBackLink className="arSCSBackLink">
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => handleBackClick()}
                >
                  <ArrowBackIosIconCustom className="arRotate180" />
                  {t("common.backToSCSHub")}
                </Button>
              </SCSBackLink>
            }
            <HomeRightSideWrapper>
              <Grid container direction="row" justify="space-around">
                <Grid item lg={8} xs={11}>
                  <Typography component="div">
                    <Typography component="span">
                      <WelcomeText>
                        {t("applicationTitle.welcomeText")}
                      </WelcomeText>
                    </Typography>
                    {!isRoleAssigned && (
                      <UnRegisterUserPage
                        loginUserData={loginUserData}
                        isRoleAssigned={isRoleAssigned}
                        isRegisteredUser={isRegisteredUser}
                      ></UnRegisterUserPage>
                    )}
                    {isRoleAssigned && (
                      <RegisterUserPage
                        loginUserData={loginUserData}
                        isRoleAssigned={isRoleAssigned}
                        isRegisteredUser={isRegisteredUser}
                      ></RegisterUserPage>
                    )}
                  </Typography>
                  <HomeFooterComponent />
                </Grid>
              </Grid>
            </HomeRightSideWrapper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HomePage;
