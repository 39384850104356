import * as React from "react";
import { getDocHeight } from "../../utils/data";
import {
  LoaderContainer,
  OverlayContainer,
  PageProgress
} from "../../styles/common";

export const Loader: any = (props: any) => (
  <LoaderContainer visible={props.visible}>
    <OverlayContainer height={getDocHeight()} />
    <PageProgress thickness={8} />
  </LoaderContainer>
);
