import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { actionCopyQuestionSetDataDetail, actionDeleteQuestionSetData } from "../actions";
import MaterialTable from "material-table";
import { ConfirmDialog } from "../../../components/common/confirm-dialog.component";
import { Link, Tooltip } from "@material-ui/core";
import { commonHeaderStyle, commonCellStyle, headerStyleLighter, } from "../../../styles/materialStyles";
import Typography from "@material-ui/core/Typography";
import {
  CommentIconCustom,
  VisibilityIconCustom,
  FileCopyIconCustom,
  DeleteIconCustom,
} from "styles/common";
import { openToaster } from "../../../store/common/actions";
import { STATUS_COMPLETED_CONSTANT, STATUS_DRAFT_CONSTANT } from "../constants";
import { COMMON_CONSTANT } from "constants/data";

function QuestionSetTableComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    showQuestionSetTable,
    showQuestionSetQuestionTable,
    questionSetData,
    setIsHistoryMode,
    getQuestionSetDataById,
    userId,
    closeToasterFun,
  } = props;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [copyRowData, setCopyRowData] = useState({});
  const [deleteRowData, setDeleteRowData]: any = useState({});

  const handlerOpenConfirmDialogCopy = (event: any, value: any) => {
    if (questionSetData.length) {
      const draftQuestions = questionSetData.filter(
        (question: any) => question.statusId === 1
      );
      if (draftQuestions.length) {
        if (value.scopeId) {
          const draftQuestionCombination = draftQuestions.filter(
            (question: any) =>
              question.supplyChainId === value.supplyChainId &&
              question.scopeId === value.scopeId
          );
          if (draftQuestionCombination.length) {
            dispatch(
              openToaster(
                t("validationMessage.differentProrgamScopeCopySelect"),
                false
              )
            );
            closeToasterParticularTimeInterval();
            return;
          }
        }
        if (value.areaOfWorkId) {
          const draftQuestionCombination = draftQuestions.filter(
            (question: any) =>
              question.supplyChainId === value.supplyChainId &&
              question.areaOfWorkId === value.areaOfWorkId
          );
          if (draftQuestionCombination.length) {
            dispatch(
              openToaster(
                t("validationMessage.differentProrgamAreaOfWorkCopySelect"),
                false
              )
            );
            closeToasterParticularTimeInterval();
            return;
          }
        }
      }
    }
    value.Id = value.id;
    value.CreatedBy = userId;
    setCopyRowData(value);
    setOpenConfirmDialog(true);
  };

  const handlerCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handlerCopyRowData = () => {
    dispatch(actionCopyQuestionSetDataDetail(copyRowData));
    handlerCloseConfirmDialog();
    showQuestionSetTable();
  };

  const handleOpenDeleteConfirmDialog = (event: any, value: any) => {
    setDeleteRowData(value);
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteConfirmDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleDeleteRowData = () => {
    setOpenDeleteConfirmDialog(true);
    const data = {
      id: deleteRowData.id
    };
    dispatch(actionDeleteQuestionSetData(data));
    handleCloseDeleteConfirmDialog();
  };

  const handleDraftQuestion = (data: any) => {
    setIsHistoryMode(false);
    showQuestionSetQuestionTable(data);
  };

  const handleCommentIndicatorSet = (data: any) => {
    getQuestionSetDataById(data);
  };

  const handlerSerialNumberRowData = (row: any) => {
    return row["tableData"].id + 1;
  };

  const handleViewQuestion = (data: any) => {
    setIsHistoryMode(true);
    showQuestionSetQuestionTable(data);
  };

  const handlerCopyButtonDisabled = (row: any): any => {
    if (row && row.statusId === STATUS_DRAFT_CONSTANT) {
      return true;
    } else {
      return false;
    }
  };

  const handlerDeleteButtonDisabled = (row: any): any => {
    if (row && row.statusId === STATUS_COMPLETED_CONSTANT) {
      return true;
    } else {
      return false;
    }
  };

  const handlerCommentButtonDisabled = (row: any): any => {
    if (row && row.statusId === STATUS_COMPLETED_CONSTANT) {
      return false;
    } else {
      return false;
    }
  };

  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFun();
    }, COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
  };
  return (
    <>
      <ConfirmDialog
        open={openConfirmDialog}
        close={handlerCloseConfirmDialog}
        title={t("indicators.areYouSureCopyIndicatorSet")}
        content=""
        ok={handlerCopyRowData}
      />
      <ConfirmDialog
        open={openDeleteConfirmDialog}
        close={handleCloseDeleteConfirmDialog}
        title={t("indicators.areYouSureDelete")}
        content=""
        ok={handleDeleteRowData}
      />
      <>
        {questionSetData && (
          <MaterialTable
            columns={[
              {
                title: t("common.number"),
                field: "id",
                filtering: false,
                sorting: false,
                render: (tblProps) => {
                  let rowData = handlerSerialNumberRowData(tblProps);
                  return <label> {rowData}</label>;
                },
                headerStyle: {
                  ...headerStyleLighter,
                  textAlign: "center",
                },
                cellStyle: {
                  ...commonCellStyle,
                  textAlign: "center",
                },
              },
              {
                title: t("indicators.indicatorSetCode"),
                field: "code",
                filtering: true,
                cellStyle: commonCellStyle,
                headerStyle: headerStyleLighter,
              },
              {
                title: t("common.program"),
                field: "supplyChain",
                filtering: true,
                cellStyle: commonCellStyle,
                headerStyle: headerStyleLighter,
              },
              {
                title: t("common.scope") + "/" + t("common.areaOfWork"),
                field: "scope",
                filtering: true,
                render: (tblProps: any) => {
                  if (tblProps["scope"] === "") {
                    return (
                      <Typography
                        component="span"
                        className="font-size-14"
                      >
                        {tblProps.areaOfWork}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography
                        component="span"
                        className="font-size-14"
                      >
                        {tblProps.scope}
                      </Typography>
                    );
                  }
                },
                cellStyle: commonCellStyle,
                headerStyle: headerStyleLighter,
              },
              {
                title: t("common.createdDate"),
                field: "createdDate",
                filtering: true,
                cellStyle: commonCellStyle,
              },
              {
                title: t("indicators.lastModifiedDate"),
                field: "updatedDate",
                filtering: true,
                cellStyle: commonCellStyle,
              },
              {
                title: t("common.status"),
                field: "statusName",
                filtering: true,
                render: (tblProps: any) => {
                  if (tblProps["statusId"] === 1) {
                    return (
                      <Tooltip title={tblProps.statusName}>
                        <Link
                          component="button"
                          onClick={() => {
                            handleDraftQuestion(tblProps);
                          }}
                          className="text-navyblue font-size-14"
                        >
                          {tblProps.statusName}
                        </Link>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Tooltip title={tblProps.statusName}>
                        <Typography
                          component="span"
                          className="font-size-14"
                        >
                          {tblProps.statusName}
                        </Typography>
                      </Tooltip>
                    );
                  }
                },
                cellStyle: commonCellStyle,
              },
            ]}
            data={questionSetData}
            title={t("indicators.allIndicatorSets")}
            options={{
              filtering: true,
              search: false,
              pageSizeOptions: [10, 20, 50],
              pageSize: 10,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              draggable: false,
              toolbar: false,
              actionsCellStyle: {
                ...commonCellStyle,
                textAlign: "center",
              },
              headerStyle: commonHeaderStyle,
            }}
            localization={{
              header: {
                actions: t("common.actions"),
              },
              body: {
                filterRow: {
                  filterTooltip: t("pagination.filter"),
                },
                emptyDataSourceMessage: t("common.noDataAvailable")
              },
              pagination: {
                firstTooltip: t("pagination.firstPage"),
                previousTooltip: t("pagination.prevPage"),
                nextTooltip: t("pagination.nextPage"),
                lastTooltip: t("pagination.lastPage"),
                labelRowsSelect: t("common.rows"),
              }
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <CommentIconCustom />
                ),
                tooltip: t("common.comments"),
                disabled: handlerCommentButtonDisabled(rowData),
                onClick: (event, rowData) => handleCommentIndicatorSet(rowData),
              }),
              {
                icon: () => (
                  <VisibilityIconCustom />
                ),
                tooltip: t("common.view"),
                onClick: (event, rowData) => handleViewQuestion(rowData),
              },
              (rowData) => ({
                icon: () => <FileCopyIconCustom />,
                tooltip: t("indicators.cloneIndicatorSet"),
                onClick: (event, rowData) =>
                  handlerOpenConfirmDialogCopy(event, rowData),
                disabled: handlerCopyButtonDisabled(rowData),
              }),
              (rowData) => ({
                icon: () => <DeleteIconCustom />,
                tooltip: t("indicators.deleteIndicatorSet"),
                onClick: (event, rowData) => handleOpenDeleteConfirmDialog(event, rowData),
                disabled: handlerDeleteButtonDisabled(rowData),
              }),
            ]}
          />
        )}
      </>
    </>
  );
}

export default QuestionSetTableComponent;
