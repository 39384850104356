import * as moment from "moment";
import decodeJWT from "jwt-decode";
/**
 * Function for getting the document's height
 */
export const getDocHeight = () => {
  const documentHeight = document.body.scrollHeight;
  const windowHeight = window.outerHeight;
  if (documentHeight > windowHeight) {
    return documentHeight * 2.65;
  } else {
    return windowHeight * 2.65;
  }
};

export const getLoggedInUserName = () => {
  if (!localStorage.getItem("msal.idtoken")) {
    const userToken: any = localStorage.getItem("msal.idtoken");
    const decoded: any = decodeJWT(userToken);
    const name = decoded.name;
    return name;
  }
};

/**
 * Function for converting moment date to local date
 * @param date Date
 */
export const getLocalDateFromMoment = (date: any) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY");
  } else {
    return moment().format("DD-MM-YYYY");
  }
};

export const getLocalDateInUTCFormat = (date: any) => {
  if (date) {
    return moment(date).utc();
  } else {
    return moment().utc();
  }
};
export const getLocatDateInDateTimeFormat = (date: any) => {
  if (date) {
    return moment(date).local().format("DD-MM-YYYY HH:mm:ss");
  } else {
    return moment().local().format("DD-MM-YYYY HH:mm:ss");
  }
};

/**
 * Function for converting UTC to local date format
 * @param date Date
 */
export const getLocalDateFromUTC = (date: any) => {
  if (date) {
    const localDate =
      new Date(date).getTime() - new Date().getTimezoneOffset() * 60000;
    const momentLocalData = moment(localDate).format("DD-MM-YYYY hh:mm:ss A");
    return momentLocalData;
  } else {
    return "";
  }
};

export const getCurrentUser = (token: any) => {
  const decoded: any = decodeJWT(token);
  return {
    name: decoded.name ? decoded.name : "",
    firstName: decoded.given_name ? decoded.given_name : "",
    lastName: decoded.family_name ? decoded.family_name : "",
    emails:
      decoded.email !== undefined
        ? [decoded.email]
        : decoded.emails !== undefined
          ? decoded.emails
          : decoded["signInNames.emailAddress"] != undefined
            ? [decoded["signInNames.emailAddress"]]
            : [""],
    city: decoded.city,
    country: decoded.country,
  };
};

export const getDuplicateArrayElements = (arr: any): [] => {
  var sorted_arr = arr.slice().sort();
  var results: any = [];
  for (var i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

/**
 * Function returning the build date(as per provided epoc)
 * @param epoc Time in milliseconds
 */
export const getBuildDate = (epoc: any) => {
  const buildDate = new Date(epoc);
  return `${formatDate(buildDate.getDate())}-${formatDate(
    buildDate.getMonth() + 1
  )}-${buildDate.getFullYear()} ${formatDate(
    buildDate.getHours()
  )}:${formatDate(buildDate.getMinutes())}`;
};

/**
 * Function returning month in desired format
 * @param val Number Month in numeric type
 */
const formatDate = (val: any) => {
  if (val < 10) {
    return `0${val}`;
  } else {
    return val;
  }
};

export const splitStringToArrayWithComma = (data: any) => {
  if (!Array.isArray(data)) {
    const seprator = /,\s/; // split on colon space or comma space
    return data.split(seprator);
  } else {
    return data;
  }
};

export const sortObjectDataByDescending = (data: any, field: any) => {
  if (data && data.length) {
    data.sort(function (a: any, b: any) {
      if (a.hasOwnProperty(field)) {
        var nameA = a[field] ? a[field] : "";
        var nameB = b[field] ? b[field] : "";
        if (nameA > nameB)
          //sort string descending
          return -1;
        if (nameA < nameB) return 1;
        return 0; //default return value (no sorting)
      }
      return 0;
    });
  }
  return data;
};

export const sortObjectDataByAsending = (data: any, field: any) => {
  if (data && data.length) {
    data.sort(function (a: any, b: any) {
      if (a.hasOwnProperty(field)) {
        var nameA = a[field] ? a[field] : "";
        var nameB = b[field] ? b[field] : "";
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      }
      return 0;
    });
  }
  return data;
};

/* Maturity Index Calculation*/

//AreaOfWork AoW = Max Score * Weight
export const calculateMaxWeightedScore = (
  maxScore: number,
  weight: number
): any => {
  const maxWeightScore = maxScore * weight;
  return maxWeightScore;
};

//Calculate weighted score for areaOfWork Aow = Max Score * (100 / total score)
export const calculateWeightedScore = (
  maxScore: number,
  totalScore: number
): any => {
  const weightedScore = maxScore * (100 / totalScore);
  return weightedScore;
};


//MM Index = (current level) * (weight) * (100/Total Max Weighted Score)
export const calculateMaturityModelIndex = (
  currentLevelMMScore: number,
  weight: number,
  totalMaxWeightedScore: number
) => {
  const mmIndex = currentLevelMMScore * weight * (100 / totalMaxWeightedScore);
  return mmIndex;
};

export const removeWhiteSpaceInString = (inputStr: string) => {
  return (inputStr = inputStr.replace(/ +/g, ""));
};

export const getStringAfterSubstring = (
  parentString: string,
  substring: string
) => {
  return parentString.substring(
    parentString.indexOf(substring) + substring.length
  );
};

export const getStringBeforeSubstring = (
  parentString: string,
  substring: string
) => {
  return parentString.substring(0, parentString.indexOf(substring));
};
