import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { actionSetPageTitle } from "../../store/common/actions";
import { actionHeaderUserSetting } from "../header/actions";
import LandingImage from "../../styles/images/landing_img.png";
import {
  ResponsiveImage,
  BoxShadowWrapper,
  LPTitle,
} from "../../styles/common";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function LandingPage(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = "SCMS"; 
  useEffect(() => {    
    dispatch(actionSetPageTitle(title));
    dispatch(actionHeaderUserSetting(true));
  }, [dispatch]);

  return (
    <>
      <Container maxWidth={"xl"}>
        <Typography component="div" className="paddingT20">
          <BoxShadowWrapper>
            <LPTitle>
              <Typography component="h1">
                {t("landingPage.pageTitle")}
              </Typography>
              <Typography component="p">{t("landingPage.pageDesc")}</Typography>
            </LPTitle>
            <Typography component="div" className="padding5P">
              <ResponsiveImage src={LandingImage} alt="" />
            </Typography>
          </BoxShadowWrapper>
        </Typography>
      </Container>
    </>
  );
}

export default LandingPage;
