import { ActionTypes } from "./constants";

export const actionSaveAdminSelectedRegion = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_SELECTED_REGION,
});

export const actionSaveAdminSelectedCountry = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_SELECTED_COUNTRY,
});

export const actionFetchAdminAssessmentData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_FETCH_ASSESSMENT_DATA,
});

export const actionFetchAdminAssessmentDataSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_FETCH_ASSESSMENT_DATA_SUCESS,
});

export const actionSetAdminMapView = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_MAP_VIEW,
});

export const actionFetchAdminAllAssesmentStatusCount = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT,
});

export const actionFetchAdminAllAssesmentStatusCountSuccess = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT_SUCESS,
});

export const actionSetAdminMapCountryAvgMIData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_MAP_COUNTRY_AVG_MI_DATA,
});

export const actionSetAdminMapRegionAvgMIData = (data: any) => ({
  payload: { data },
  type: ActionTypes.ADMIN_MAP_REGION_AVG_MI_DATA,
});