import { ActionTypes } from "./constants";
const initialState = {
    questionSetAreaOfWorkData: []
};

export const vcAssessmentReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.MASTER_GET_QUESTION_SET_AREA_OF_WORK_SUCCESS:
            return {
                ...state,
                questionSetAreaOfWorkData: action.payload.data || [],
            };

        default:
            return state;
    }
};