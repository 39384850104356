import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormInput from "../../../components/form-controls/input";
import { actionUpdateAssesmentQuestionValidationCommentDetailData } from "../actions";
import * as yup from "yup";
import {
  CommentContextWindow,
  CloseIconCustom,
  SendIconCustom,
} from "../../../styles/common";
import {
  getLocalDateInUTCFormat,
  getLocatDateInDateTimeFormat,
} from "utils/data";
import { SectionLoader } from "../../../components/common/section-loader.component";

function AssessmentQuestionCommentComponent(props: any) {
  const {
    userId,
    questionCommentDialogOpen,
    hideQuestionCommentDialogForm,
    questionCommentData,
    loginUserData,
    editFormValues,
    isEditAssessment,
    setQuestionComment,
    selectedAowData,
    setSelectedAowData,
    handleReAssignButton,
    areaOfWorkDataList,
    tabData
  } = props;

  const assessmentValidationCommentData = useSelector(
    (state: any) => state.assesmentCreate.assessmentValidationCommentData
  );
  const isSectionLoaderVisible = useSelector(
    (state: any) => state.common.isSectionLoaderVisible
  );

  const [
    prevValidationCommentData,
    setPrevValidationCommentData,
  ]: any = useState([]);
  useEffect(() => {
    if (assessmentValidationCommentData) {
      setPrevValidationCommentData(
        assessmentValidationCommentData.ValidationComment
      );
    }
  }, [assessmentValidationCommentData]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(t("indicators.pleaseEnterComment")),
  });
  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { handleSubmit, errors, setValue } = methods;

  const onSubmit = (data: any) => {
    let validationComment: any = prevValidationCommentData;
    const comment: any = [
      {
        UserName: `${loginUserData.name} ${loginUserData.lastName}`,
        Message: data.name,
        Date: getLocalDateInUTCFormat(null),
      },
    ];
    validationComment = [...validationComment, ...comment];
    setPrevValidationCommentData(validationComment);
    const formData = {
      validationComment: validationComment,
      id: questionCommentData.assessmentDetailId,
      updatedBy: userId,
      assessmentId: editFormValues.id,
    };

    dispatch(
      actionUpdateAssesmentQuestionValidationCommentDetailData(formData)
    );
    setValue("name", "");
    setIsCommentText(false);
    if (assessmentValidationCommentData.ValidationComment.length === 0) {
      setQuestionComment(validationComment);
    } else {
      setQuestionComment("");
    }
    const validationCommentStr = JSON.stringify(validationComment);
    updateCurrentSelectedAOWobj(questionCommentData, validationCommentStr);
    handleReAssignButton(areaOfWorkDataList, tabData)
  };

  const [isCommentText, setIsCommentText] = useState(false);

  const handleCommentTextChange = ([event]: any) => {
    if (event.target.value.trim() !== "") {
      setIsCommentText(true);
    } else {
      setIsCommentText(false);
    }
    return event.target.value;
  };

  const updateCurrentSelectedAOWobj = ((queData: any, validationComment: any) => {
    selectedAowData.levels.map((level: any) => {
      //looping though topics to update their coment
      level.subAreaOfWorks.map((subAow: any) => {
        subAow.questionDetails.map((que: any) => {
          if (que.questionId === queData.questionId) {
            que.validationComment = validationComment
          }
        });
      });
    });
    setSelectedAowData({
      ...selectedAowData
    });
  });

  return (
    <>
      {questionCommentDialogOpen ? (
        <CommentContextWindow className="comments-from-right-side">
          <SectionLoader visible={isSectionLoaderVisible} />
          <FormContext {...methods}>
            <Typography component="div" className="comment-header">
              <CloseIconCustom onClick={hideQuestionCommentDialogForm} />
            </Typography>
            <Typography component="div" className="comment-body">
              {prevValidationCommentData && prevValidationCommentData.length
                ? prevValidationCommentData.map((comment: any, index: any) => {
                  return (
                    comment && (
                      <Typography component="div" className="comment-block" key={index}>
                        <Typography component="span" className="commented-by">
                          {comment.UserName}
                        </Typography>
                        <Typography component="span" className="comment-date">
                          {getLocatDateInDateTimeFormat(comment.Date)}
                        </Typography>
                        <Typography component="span" className="comment-desc">
                          {comment.Message}{" "}
                        </Typography>
                      </Typography>
                    )
                  );
                })
                : null}
            </Typography>
            <Typography component="div" className="comment-footer">
              <Grid container spacing={2}>
                <Grid item>
                  <FormInput
                    name="name"
                    label={t("common.comment")}
                    multiline
                    errorobj={errors}
                    inputProps={{ maxLength: 250 }}
                    variant="outlined"
                    onChange={handleCommentTextChange}
                    rows={2}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    component="button"
                    disabled={!isEditAssessment}
                    onClick={handleSubmit(onSubmit)}
                    className={
                      isCommentText ? "blue-button btn-send" : "btn-send"
                    }
                  >
                    <SendIconCustom />
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </FormContext>
        </CommentContextWindow>
      ) : null}
    </>
  );
}

export default AssessmentQuestionCommentComponent;
