import { ActionTypes } from "./constants";

const initialState = {
  userProfileData: [],
  useProfileEditData: [],
  saveUserData: null,
  saveUnRegisterUserData: false,
  isRegisterUser: false,
  selectedObj: null,
  userIdProfileSettingData: [],
  isSettingPageShow: false,
  isAuthenticated: null,
  scsHubAccessRequestedList: [],
  userRequestResponce: null
};

export const userProfileReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.USER_PROFILE_USER_FETCH_DATA_SUCCESS:
      return {
        ...state,
        userProfileData: action.payload.data || [],
      };
    case ActionTypes.USER_PROFILE_SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        saveUserData: action.payload.data || [],
      };
    case ActionTypes.USER_PROFILE_USER_ID_FETCH_DATA_SUCCESS:
      return {
        ...state,
        useProfileEditData: action.payload.data || [],
      };
    case ActionTypes.UNREGISTER_USER_SAVE_DATA_SUCCESS:
      return {
        ...state,
        saveUnRegisterUserData: action.payload.data || [],
      };
    case ActionTypes.REGISTER_USER_SAVE_ROLE_DATA:
      return {
        ...state,
        saveRegisterUserRoleData: action.payload.userRoleData || [],
      };
    case ActionTypes.REGISTER_USER_SAVE_SELECTED_ROLE_DATA:
      return {
        ...state,
        selectedObj: action.payload.selectedObj || [],
      };
    case ActionTypes.USER_PROFILE_SETTING_USER_ID_DATA_SUCESS:
      return {
        ...state,
        userIdProfileSettingData: action.payload.data || [],
      };
    case ActionTypes.USER_PROFILE_SETTING_PAGE_SHOW:
      return {
        ...state,
        isSettingPageShow: action.payload,
      };
    case ActionTypes.USER_PROFILE_FETCH_IS_USER_AUTHETICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case ActionTypes.USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED_SUCCESS:
      return {
        ...state,
        scsHubAccessRequestedList: action.payload,
      };
    case ActionTypes.USER_PROFILE_SCS_PROCESS_REQUESTED_SUCCESS:
      return {
        ...state,
        userRequestResponce: action.payload,
      };
    default:
      return state;
  }
};
