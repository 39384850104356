import { ActionTypes } from "./constants";

const initialState = {
  assesmentData: [],
  saveAssesmentData: null,
  assesmentDetailData: [],
  updateAssesmentValidationStatusData: null,
  updateAssesmentQuestionStatusData: null,
  updateAssesmentQuestionCommentData: null,
  assessmentLevelDetailData: null,
  assesmentDetailSavedData: null,
  assessmentValidationStatusData: [],
  assessmentValidationCommentData: null,
  updateAssessmentValidationCommentData: null,
  updateAssessmentResetValidationData: null,
  assessmentInProgressValidationStatusData: null,
  updateAssessmentResetAssessorData: null,
  exportAssessmentData: [],
  clickedStatus: null,
  dynamicAssessmentLevelStatusData: [],
  ProgramCurrentScoreData: null,
  assessmentReportData: [],
  assessmentReportRegionData: [],
  allAssessmentDetails: [],
  showAssessmentTable: false,
  assessmentScoringData: []
};

export const assesementCreateReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.ASSESMENT_CREATE_FETCH_ASSEMENT_DATA_SUCCESS:
      return {
        ...state,
        assesmentData: action.payload.data || [],
      };
    case ActionTypes.FETCH_ALL_ASSESSMENT_DETAILS_SUCCESS:
      return {
        ...state,
        allAssessmentDetails: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_SAVE_ASSESMENT_DATA_SUCCESS:
      return {
        ...state,
        saveAssesmentData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        assesmentDetailData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS:
      return {
        ...state,
        updateAssesmentValidationStatusData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        updateAssesmentQuestionStatusData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        updateAssesmentQuestionCommentData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        assessmentLevelDetailData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_DETAILS_SAVED_DATA:
      return {
        ...state,
        assesmentDetailSavedData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA_SUCCESS:
      return {
        ...state,
        assessmentValidationStatusData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA_SUCCESS:
      return {
        ...state,
        assessmentValidationCommentData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA_SUCCESS:
      return {
        ...state,
        updateAssessmentValidationCommentData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA_SUCCESS:
      return {
        ...state,
        updateAssessmentResetValidationData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS:
      return {
        ...state,
        assessmentInProgressValidationStatusData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA_SUCCESS:
      return {
        ...state,
        updateAssessmentResetAssessorData: action.payload.data || [],
      };
    case ActionTypes.ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        exportAssessmentData: action.payload.data || [],
      };
    case ActionTypes.SAVE_CLICKED_STATUS:
      return {
        ...state,
        clickedStatus: action.payload.data || null,
      };
    case ActionTypes.ASSESMENT_DYNAMIC_LEVEL_STATUS_DATA_SUCCESS:
      return {
        ...state,
        dynamicAssessmentLevelStatusData: action.payload.data || [],
      };
    case ActionTypes.ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID_SUCCESS:
      return {
        ...state,
        ProgramCurrentScoreData: action.payload.data || [],
      };
    case ActionTypes.ASSESSMENT_REPORT_SUCCESS:
      return {
        ...state,
        assessmentReportData: action.payload.data || [],
      };
    case ActionTypes.ASSESSMENT_REPORT_REGION_SUCCESS:
      return {
        ...state,
        assessmentReportRegionData: action.payload.data || [],
      };
    case ActionTypes.SHOW_ASSESSMENT_TABLE:
      return {
        ...state,
        showAssessmentTable: action.payload.data || false
      }
    case ActionTypes.ASSESSMENT_SCORING_DATA_SUCCESS:
      return {
        ...state,
        assessmentScoringData: action.payload.data || [],
      };
    default:
      return state;
  }
};
