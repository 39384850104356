export const ActionTypes = {
  HEADER_MASTER_MENU_FETCH_DATA: "HEADER_MASTER_MENU_FETCH_DATA",
  HEADER_MASTER_MENU_FETCH_DATA_SUCCESS:
    "HEADER_MASTER_MENU_FETCH_DATA_SUCCESS",
  HEADER_LOGIN_USER_FETCH_DATA: "HEADER_LOGIN_USER_FETCH_DATA",
  HEADER_LOGIN_USER_FETCH_DATA_SUCCESS: "HEADER_LOGIN_USER_FETCH_DATA_SUCCESS",
  HEADER_LANGUAGE_SET_DATA: "HEADER_LANGUAGE_SET_DATA",
  HEADER_SET_LOGIN_USER_DATA_SUCCESS: "HEADER_SET_LOGIN_USER_DATA_SUCCESS",
  HEADER_IS_USER_SETTING_SUCCESS: "HEADER_IS_USER_SETTING_SUCCESS",
  HEADER_USER_DROPDOWN_SHOW: "HEADER_USER_DROPDOWN_SHOW",
  HEADER_USER_SETTING_POPUP_SHOW: "HEADER_USER_SETTING_POPUP_SHOW",
  HEADER_USER_SETTING_CLICKED: "HEADER_USER_SETTING_CLICKED",
  HEADER_SET_ORGANIZATION: "HEADER_SET_ORGANIZATION",
  ASSESSMENT_TYPE_FILTER_TOGGLE: "ASSESSMENT_TYPE_FILTER_TOGGLE",
  HEADER_SET_APP_DETAILS: "HEADER_SET_APP_DETAILS",
  HEADER_SET_REGISTER_USER: "HEADER_SET_REGISTER_USER"
};

export const GET_MASTER_MENU_DETAILS: string = `/api/MasterDetails/GetMasterMenuDetails`;
export const GET_POST_ACCOUNT_LOGIN: string = `/api/Accounts/Login`;

export const GET_ACCOUNT_IS_AUTHENTICATED: string = `/api/Accounts/IsAuthenticated`;