import React from "react";
import { useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import "./index.css";

import Setting from "./setting";
import { actionHeaderUserSetting } from "../header/actions";
import { UserInfoPanel } from "styles/common";
import { Grid } from "@material-ui/core";
function RegisterUserPage(props: any) {
  const { loginUserData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isInactive = loginUserData.isDeleted;

  const gotoOverview = () => {
    dispatch(actionHeaderUserSetting(true));
  };

  return (
    <UserInfoPanel>
      <Typography component="p" className="txt-user-name">
        {t("unRegisterUser.hiText")} {" "} {loginUserData.name} {loginUserData.lastName}
      </Typography>
      {isInactive ? (
        <Typography
          component="p"
          dangerouslySetInnerHTML={{
            __html: t("registerUser.inactiveUser"),
          }}
        />
      ) : (
        <>
          <Typography
            component="p"
            dangerouslySetInnerHTML={{
              __html: t("registerUser.select"),
            }}
          />
          <Grid container justify="center">
            <Grid item lg={8} xs={10}>
              <Setting
                applyBtn={gotoOverview}
                showBtn={true}
                directionValue={"column"}
              />
            </Grid>
          </Grid>
        </>
      )}
    </UserInfoPanel>
  );
}

export default RegisterUserPage;
