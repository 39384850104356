import { ActionTypes } from "./constants";
import { LANGUAGE } from "constants/data";
const initialState = {
  masterMenuData: [],
  loginUserData: null,
  isRegisteredUser: false,
  isRoleAssigned: false,
  isDeleted: true,
  isUserDropDownShow: false,
  isUserSettingPopUpShow: false,
  languageSelected:
    localStorage.getItem("language") === null
      ? LANGUAGE.ENGLISH
      : localStorage.getItem("language"),
  isUserSelectedData:
    localStorage.getItem("userSelectedData") === null ? false : true,
  setUserLoginData: null,
  isUserSetting: true,
  isUserSettingClicked: false,
  organizationSelected: "",
  assessmentTypeFilter: false,
  appDetails: ""
};

export const headerReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.HEADER_MASTER_MENU_FETCH_DATA_SUCCESS:
      return {
        ...state,
        masterMenuData: action.payload.data || [],
      };
    case ActionTypes.ASSESSMENT_TYPE_FILTER_TOGGLE:
      return {
        ...state,
        assessmentTypeFilter: action.payload.data || false
      }
    case ActionTypes.HEADER_LOGIN_USER_FETCH_DATA_SUCCESS:
      return {
        ...state,
        loginUserData: action.payload.data,
        isRegisteredUser: action.payload.data.id !== "" ? true : false,
        isDeleted: action.payload.data.isDeleted ? false : true,
        isUserSelectedData:
          localStorage.getItem("userSelectedData") === null ? false : true,
      };
    case ActionTypes.HEADER_LANGUAGE_SET_DATA:
      return {
        ...state,
        languageSelected: action.payload.language,
      };
    case ActionTypes.HEADER_SET_LOGIN_USER_DATA_SUCCESS:
      return {
        ...state,
        setUserLoginData: action.payload.data,
      };
    case ActionTypes.HEADER_IS_USER_SETTING_SUCCESS:
      return {
        ...state,
        isUserSetting: action.payload.data,
      };
    case ActionTypes.HEADER_USER_DROPDOWN_SHOW:
      return {
        ...state,
        isUserDropDownShow: action.payload.data,
      };
    case ActionTypes.HEADER_USER_SETTING_POPUP_SHOW:
      return {
        ...state,
        isUserSettingPopUpShow: action.payload.data,
      };
    case ActionTypes.HEADER_USER_SETTING_CLICKED:
      return {
        ...state,
        isUserSettingClicked: action.payload.data,
      };
    case ActionTypes.HEADER_SET_ORGANIZATION:
      return {
        ...state,
        organizationSelected: action.payload.data,
      };
    case ActionTypes.HEADER_SET_APP_DETAILS:
      return {
        ...state,
        appDetails: action.payload.data,
      }
    case ActionTypes.HEADER_SET_REGISTER_USER:
      return {
        ...state,
        isRoleAssigned: action.payload.data,
      }
    default:
      return state;
  }
};
