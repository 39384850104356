import { ActionTypes } from "./constants";
const initialState = {
  masterMenuFormFieldDetail: [],
  masterMenuFormFieldAndDataDetail: [],
  masterDynamiceMenuFormDataSave: [],
  masterDynamicMenuFormRecordDelete: [],
  dynamicMasterFormData: [],
  mastermaturityIndexData: [],
  maturityIndexDataSave: null,
  maturityIndexDataDelete: null,
  isMasterMaturityIndexCall: false,
  masterAreaOfWorkToolSingleData: null,
  masterAreaOfWorkToolData: [],
  aowHirerarchyData: [],
  aowTopicDetals: null,
  masterAreaOfWorkToolsData: [],
  aowOrTopicSaved: false,
  countryHierarchyData: [],
  topicSaveData: false,
  areaOfWorkToolDataSave: false,
  isMasterAreaOfWorkToolUpdate: false,
  userAndCountryList: "",
  vcReportData: ""
};

export const masterFormReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA_SUCCESS:
      return {
        ...state,
        masterMenuFormFieldDetail: action.payload.data || [],
      };
    case ActionTypes.MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA_SUCCESS:
      return {
        ...state,
        masterMenuFormFieldAndDataDetail: action.payload.data || [],
      };
    case ActionTypes.MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
      return {
        ...state,
        dynamicMasterFormData: action.payload.data || [],
      };
    case ActionTypes.MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
      return {
        ...state,
        masterDynamiceMenuFormDataSave: action.payload.data || [],
      };
    case ActionTypes.MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
      return {
        ...state,
        masterDynamicMenuFormRecordDelete: action.payload.data || [],
      };
    case ActionTypes.MASTER_MATURITY_INDEX_DATA_SUCCESS:
      return {
        ...state,
        mastermaturityIndexData: action.payload.data || [],
      };
    case ActionTypes.MASTER_MATURITY_INDEX_SAVE_SUCCESS:
      return {
        ...state,
        maturityIndexDataSave: action.payload.data || null,
      };
    case ActionTypes.MASTER_MATURITY_INDEX_DELETE_SUCCESS:
      return {
        ...state,
        maturityIndexDataDelete: action.payload.data || null,
      };
    case ActionTypes.MASTER_MATURITY_INDEX_CALL:
      return {
        ...state,
        isMasterMaturityIndexCall: action.payload || false,
      };

    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA_SUCCESS:
      return {
        ...state,
        masterAreaOfWorkToolData: action.payload.data || [],
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        masterAreaOfWorkToolSingleData: action.payload.data || null,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_SAVE_SUCCESS:
      return {
        ...state,
        areaOfWorkToolDataSave: action.payload.data || null,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_DELETE_SUCCESS:
      return {
        ...state,
        areaOfWorkToolDataDelete: action.payload.data || null,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_UPDATE_SUCCESS:
      return {
        ...state,
        isMasterAreaOfWorkToolUpdate: action.payload.formData || false,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOL_CALL:
      return {
        ...state,
        isMasterAreaOfWorkToolCall: action.payload || false,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY_SUCCESS:
      return {
        ...state,
        aowHirerarchyData: action.payload.data || false,
      };
    case ActionTypes.MASTER_AOW_TOPIC_DETAILS_SUCCESS:
      return {
        ...state,
        aowTopicDetals: action.payload.data || false,
      };
    case ActionTypes.MASTER_AREA_OF_WORK_TOOLS_DATA_SUCCESS:
      return {
        ...state,
        masterAreaOfWorkToolsData: action.payload.data || [],
      };
    case ActionTypes.MASTER_SAVE_AOW_OR_TOPIC_SUCCESS:
      return {
        ...state,
        aowOrTopicSaved: action.payload.data || false,
      };
    case ActionTypes.MASTER_GET_COUNTRY_HIERARCHY_SUCCESS:
      return {
        ...state,
        countryHierarchyData: action.payload.data || false,
      };
    case ActionTypes.MASTER_USER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        userAndCountryList: action.payload.data || "",
      };

    case ActionTypes.MASTER_VC_REPORT_STATS_SUCCESS:
      return {
        ...state,
        vcReportData: action.payload.data || "",
      };
    default:
      return state;
  }
};
