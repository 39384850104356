import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { FullWidthContainer, FormToolbar, WarningLabel } from "../../../styles/common";
import FormInput from "../../../components/form-controls/input";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import {
  DialogClose,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../../styles/common";
import { actionSaveQuestionDataDetail } from "../actions";
import * as yup from "yup";
import { testInputValueWithRegex } from "utils/security";
function QuestionFormComponent(props: any) {
  const {
    dialogClose,
    isEditQuestion,
    areaOfWorkId,
    questionSetId,
    tabId,
    questionBySetIdAreaOfWorkData,
    userId,
    dialogOpen,
    currentIndicatorTopicId
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validateSchema = yup.object().shape({
    name: yup.string().trim().required(t("indicators.pleaseEnterIndicator"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameFr: yup.string().trim().required(t("indicators.pleaseEnterIndicator"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameEs: yup.string().trim().required(t("indicators.pleaseEnterIndicator"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameRu: yup.string().trim().required(t("indicators.pleaseEnterIndicator"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameAr: yup.string().trim().required(t("indicators.pleaseEnterIndicator"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
  });
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors } = methods;

  const { setValue } = methods;
  let levelId = 1;
  const questionDataByQuestionId = useSelector(
    (state: any) => state.question.questionDataByQuestionId
  );
  const [indicatorTitle, setIndicatorTitle] = useState("");
  const [indicatorDescrition, setIndicatorDescrition] = useState("");
  useEffect(() => {

    if (questionDataByQuestionId && questionDataByQuestionId.length) {
      setIndicatorTitle(t("indicators.editIndicator"));
      setIndicatorDescrition(t("indicators.editIndicatorDesc"));
      setValue("name", questionDataByQuestionId[0].name);
      setValue("nameEs", questionDataByQuestionId[0].nameEs);
      setValue("nameFr", questionDataByQuestionId[0].nameFr);
      setValue("nameRu", questionDataByQuestionId[0].nameRu);
      setValue("nameAr", questionDataByQuestionId[0].nameAr);


    } else {
      setIndicatorTitle(t("indicators.addIndicator"));
      setIndicatorDescrition(t("indicators.dialogIndicatorDesc"));
      setValue("name", "");
      setValue("nameEs", "");
      setValue("nameFr", "");
      setValue("nameRu", "");
      setValue("nameAr", "");
    }
  }, [questionDataByQuestionId]);

  const onSubmit = (data: any) => {
    let countSequence = 1;
    let tabDatafilter = [];
    if (isEditQuestion) {
      data.id = questionDataByQuestionId[0].id;
      data.questionSetId = questionDataByQuestionId[0].questionSetId;
      data.areaOfWorkId = questionDataByQuestionId[0].areaOfWorkId;
      data.levelId = questionDataByQuestionId[0].levelId;
      data.sequenceNo = questionDataByQuestionId[0].sequenceNo;
      data.updatedBy = userId;
      data.questionSetTopicId = currentIndicatorTopicId
    } else {
      let levelType = `${"Level" + tabId}`;
      tabDatafilter = questionBySetIdAreaOfWorkData.filter(
        (a: any) => a.field === levelType
      );
      if (tabDatafilter.length) {
        countSequence = tabDatafilter[0].questionData.length + 1;
        levelId = tabDatafilter[0].fielId;
      }
      data.questionSetId = questionSetId;
      data.areaOfWorkId = areaOfWorkId;
      data.levelId = levelId;
      data.sequenceNo = countSequence;
      data.createdBy = userId;
      data.questionSetTopicId = currentIndicatorTopicId
    }
    dispatch(actionSaveQuestionDataDetail(data));
    dialogClose();
  };

  return (
    <>
      <FullWidthContainer>
        <Dialog
          open={dialogOpen}
          onClose={dialogClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title" className="padding16-24 arfloatLeft">
            {indicatorTitle}
            <DialogClose aria-label="close" onClick={() => dialogClose()}>
              <CloseIcon />
            </DialogClose>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="alert-dialog-description"
              className="nomargin"
            ></DialogContentText>
            <Typography component="span">
              <WarningLabel>
                {indicatorDescrition}
              </WarningLabel>
            </Typography>
            <FormToolbar>
              <FormContext {...methods}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">{t("common.language")}: {t("language.english")}</Typography>
                      <FormInput
                        type="text"
                        name="name"
                        label={t("common.indicator")}
                        multiline
                        required={true}
                        errorobj={errors}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">{t("common.language")}: {t("language.french")}</Typography>
                      <FormInput
                        type="text"
                        name="nameFr"
                        label={t("common.indicator")}
                        multiline
                        required={true}
                        errorobj={errors}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">{t("common.language")}: {t("language.spanish")}</Typography>
                      <FormInput
                        type="text"
                        name="nameEs"
                        label={t("common.indicator")}
                        multiline
                        required={true}
                        errorobj={errors}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">{t("common.language")}: {t("language.russian")}</Typography>
                      <FormInput
                        type="text"
                        name="nameRu"
                        label={t("common.indicator")}
                        multiline
                        required={true}
                        errorobj={errors}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">{t("common.language")}: {t("language.arabic")}</Typography>
                      <FormInput
                        type="text"
                        name="nameAr"
                        label={t("common.indicator")}
                        multiline
                        required={true}
                        errorobj={errors}
                        rows={2}
                        className="direction-rtl"
                      />
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </FormToolbar>
          </DialogContent>
          <DialogActions className="padding16-24">
            <Grid container alignItems="center" justify="flex-end" spacing={2}>
              <Grid item>
                <DialogButtonDefault
                  variant="contained"
                  color="default"
                  onClick={dialogClose}
                >
                  {t("common.cancel")}
                </DialogButtonDefault>
              </Grid>
              <Grid item>
                {!isEditQuestion ? (
                  <DialogButtonPrimary
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("common.add")}
                  </DialogButtonPrimary>
                ) : (
                  <DialogButtonPrimary
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t("common.update")}
                  </DialogButtonPrimary>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </FullWidthContainer>
    </>
  );
}

export default QuestionFormComponent;
