export const ActionTypes = {
  USER_PROFILE_USER_FETCH_DATA: "USER_PROFILE_USER_FETCH_DATA",
  USER_PROFILE_USER_FETCH_DATA_SUCCESS: "USER_PROFILE_USER_FETCH_DATA_SUCCESS",

  USER_PROFILE_USER_ID_FETCH_DATA: "USER_PROFILE_USER_ID_FETCH_DATA",
  USER_PROFILE_USER_ID_FETCH_DATA_SUCCESS:
    "USER_PROFILE_USER_ID_FETCH_DATA_SUCCESS",

  USER_PROFILE_SAVE_USER_DATA: "USER_PROFILE_SAVE_USER_DATA",
  USER_PROFILE_SAVE_USER_DATA_SUCCESS: "USER_PROFILE_SAVE_USER_DATA_SUCCESS",

  UNREGISTER_USER_SAVE_DATA: "UNREGISTER_USER_SAVE_DATA",
  UNREGISTER_USER_SAVE_DATA_SUCCESS: "UNREGISTER_USER_SAVE_DATA_SUCCESS",

  REGISTER_USER_SAVE_ROLE_DATA: "REGISTER_USER_SAVE_ROLE_DATA",
  REGISTER_USER_SAVE_ROLE_DATA_SUCCESS: "REGISTER_USER_SAVE_ROLE_DATA_SUCCESS",

  REGISTER_USER_SAVE_SELECTED_ROLE_DATA:
    "REGISTER_USER_SAVE_SELECTED_ROLE_DATA",

  USER_PROFILE_SETTING_USER_ID_DATA: "USER_PROFILE_SETTING_USER_ID_DATA",
  USER_PROFILE_SETTING_USER_ID_DATA_SUCESS:
    "USER_PROFILE_SETTING_USER_ID_DATA_SUCESS",

  USER_PROFILE_UPDATE_SETTING: "USER_PROFILE_UPDATE_SETTING",
  USER_PROFILE_UPDATE_SETTING_SUCCESS: "USER_PROFILE_UPDATE_SETTING_SUCCESS",

  USER_PROFILE_SETTING_PAGE_SHOW: "USER_PROFILE_SETTING_PAGE_SHOW",

  USER_PROFILE_FETCH_IS_USER_AUTHETICATED: "USER_PROFILE_FETCH_IS_USER_AUTHETICATED",
  USER_PROFILE_FETCH_IS_USER_AUTHETICATED_SUCCESS: "USER_PROFILE_FETCH_IS_USER_AUTHETICATED_SUCCESS",

  USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED: "USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED",
  USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED_SUCCESS: "USER_PROFILE_SCS_FETCH_ACCESS_REQUESTED_SUCCESS",

  USER_PROFILE_SCS_PROCESS_REQUESTED: "USER_PROFILE_SCS_PROCESS_REQUESTED",
  USER_PROFILE_SCS_PROCESS_REQUESTED_SUCCESS: "USER_PROFILE_SCS_PROCESS_REQUESTED_SUCCESS",
};

export const GET_USER_PROFILE_DETAILS: string = `/api/UserDetails/GetUserProfileDetails`;
export const POST_USER_PROFILE_DETAILS: string = `/api/UserDetails/SaveUserDetails`;
export const POST_USER_PROFILE_SETTING: string = `/api/UserDetails/UpdateUserProfileSettings`;
export const GET_AUTHENTICATION_STATUS: string = `/api/Accounts/IsAuthenticated`;
export const GET_SCS_USER_ACCESS_REQUEST: string = `/api/UserDetails/GetPendingRequests`
export const POST_SCS_USER_PROCESS_REQUEST: string = `/api/UserDetails/ProcessRequest`
