import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  NEW_REQUEST,
  HOME_PATH,
  HOME_PATH_AFTER_LOGIN,
  ALL_REQUESTS,
  ABOUT_US,
  PRE_LOGIN,
  MASTER,
  QUESTION,
  USER_PROFILE,
  ASSESMENT,
  ASSESSMENT_OVERVIEW,
  HOW_TO_JOIN,
  MATURITY_INDEX,
  AREA_WORK_TOOL,
  REPORT,
  HOME,
  ERROR_PATH,
  AOW_MANAGEMENT,
  COUNTRY_HIERARCHY,
  VC_ASSESMENT,
  VC_REPORT,
} from "../../constants/api";
import NewRequestComponent from "../new-request";
import Header from "../header";
import AboutUs from "../about-us";
import AllRequestsComponent from "../all-requests";
import LandingPage from "../landingpage";
import PreLoginPage from "../pre-login";
import MasterMenuComponent from "../master";
import QuestionSetComponent from "../question-set";
import UserProfileComponent from "../user-profile";
import AssesmentCreateComponent from "../assessment-create";
import AdminRSAOverview from "../admin-rsa-overview";
import HomePage from "../home";
import { Loader } from "../../components/common/loader.component";
import { withTranslation } from "react-i18next";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import AssessmentOverview from "../assessment-overview";
import { sortObjectDataByAsending } from "utils/data";
import HowToJoin from "containers/howtojoin";
import { history } from "../../store/config";
import FooterComponent from "containers/footer";
import RsaAssessmentsComponent from "containers/rsa-assessments";
import { actionFetchUserRoleData } from "store/common/actions";
import {
  actionSaveRegisterUserSelectedRoleData,
  actionUpdateUserSetting,
  actionUserProfileSettingPageShow,
} from "containers/user-profile/actions";
import { useLocation } from "react-router-dom";
import MaturityIndexValueComponent from "containers/master/maturity-index-value";
import AreaOfWorkToolComponent from "containers/master/area-of-work-tool";
import AdminAssessmentsComponent from "containers/admin-assessments";
import { LANGUAGE, USER } from "constants/data";
import AssessmentReportComponent from "containers/assessment-report";
import ReportComponent from "containers/master/report";
import { actionFetchLoginData, actionSetApplicationDetails, actionSetApplicationRegisterUser } from "containers/header/actions";
import ErrorComponent from "../../components/error";
import AreaOfWorkManagementComponent from "containers/master/area-of-work-management"
import CountryHierarchyComponent from "containers/master/country-hierarchy"
import { MainPageWrapper } from "styles/common";
import VCAssesmentComponent from "containers/vc-assessment";
import VCReportComponent from "containers/master/vc-report";

const changeLanguage = (lng: any) => {
  i18n.changeLanguage(lng);
};

function MainHomeComponent(props: any) {
  const { i18n } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const applicationData: any = (window as any).applicationData;

  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const isLoaderVisible = useSelector(
    (state: any) => state.common.isLoaderVisible
  );
  const selectedObj = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const localSelectedData = JSON.parse(
    localStorage.getItem("userSelectedData") || "{}"
  );
  const loginUserData = useSelector((state: any) => state.header.loginUserData);
  const roleData = useSelector((state: any) => state.common.roleData);
  const [currentRole, setCurrentRole] = useState("");

  const NEW_USER: string = "new-user";
  const NOT_LOGGED_IN: string = "not-logged-in";
  const currentRoute = window.location.pathname;
  const isHomePath = currentRoute.includes("/home");

  const userIdProfileSettingData = useSelector(
    (state: any) => state.userProfile.userIdProfileSettingData
  );
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const listOrgWiseRegion = useSelector(
    (state: any) => state.common.listOrgWiseRegion
  );
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const isUserAuthenticated = useSelector(
    (state: any) => state.userProfile.isAuthenticated
  );
  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );
  const applicationsList = useSelector(
    (state: any) => state.scsHub.applicationsList
  );
  const scsUserDetails = useSelector(
    (state: any) => state.scsHub.scsUserDetails
  );

  let userSelectedData: any = null;
  let listOfCountry: any = [];
  let listOfRoles: any = [];
  let listOfRegion: any = [];
  let userRoleDetails: any = [];
  let allCountryList: any = [];
  let userId: any = "";

  useEffect(() => {
    dispatch(actionFetchUserRoleData("-1"));
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      if (!localStorage.getItem("language")) {
        localStorage.setItem("language", LANGUAGE.ENGLISH);
      }
      localStorage.setItem("roleChanged", "false");
      dispatch(actionFetchLoginData());
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (loginUserData) {
      dispatch(actionSetApplicationRegisterUser(loginUserData.role.length ? true : false));
    }
  }, [loginUserData])

  useEffect(() => {
    if (languageSelected !== "") {
      changeLanguage(languageSelected);
    }
  }, [languageSelected]);


  useEffect(() => {
    if (
      roleData.length &&
      listOrgWiseCountry.length &&
      loginUserData &&
      listOrgWiseRegion.length &&
      isUserAuthenticated
    ) {
      if (localSelectedData) {
        if (localSelectedData.userSelectedApp) {
          const appDetails = applicationsList.filter((app: any) => app.id === localSelectedData.userSelectedApp);
          dispatch(actionSetApplicationDetails(appDetails[0]));
        }
        if (localSelectedData.hasOwnProperty("userSelectedRole")) {
          checkRole(localSelectedData.userSelectedRole);
          if (loginUserData.isDeleted === false && currentRole === USER.ROLE.VC_ASSESSOR) {
            history.push(VC_ASSESMENT)
          }
          else if (
            loginUserData.isDeleted === false &&
            (location.pathname === "/" ||
              location.pathname.toLowerCase().trim().includes("/home"))
          ) {
            history.push(ASSESSMENT_OVERVIEW);
          } else if (loginUserData.isDeleted === true) {
            history.push(HOME_PATH_AFTER_LOGIN);
            dispatch(actionUserProfileSettingPageShow(true));
          }
        } else {
          if (loginUserData) {
            const validRoles = getValidAppRoles(loginUserData.role);
            if (
              validRoles.length === 1 &&
              loginUserData.isDeleted === false
            ) {
              setCurrentRole(validRoles[0]);
            } else if (
              loginUserData.userSettings &&
              loginUserData.isDeleted === false
            ) {
              //check if user has selected "scmm" but login api userSettings has vc assessor role saved 
              //then clear the user settings data
              let userSelectedData = JSON.parse(loginUserData.userSettings);
              if (!selectedAppDetails && userSelectedData.userSelectedApp) {
                const appDetails = applicationsList.filter((app: any) => app.id === userSelectedData.userSelectedApp);
                dispatch(actionSetApplicationDetails(appDetails[0]));
              }
              //if selected app and settings app do not match then clear the user settings
              if (selectedAppDetails.id !== userSelectedData.userSelectedApp) {
                const obj: any = {
                  id: userId,
                  UserSettings: null
                }
                actionUpdateUserSetting(obj);

                const validRoles = getValidAppRoles(loginUserData.role);
                if (
                  validRoles.length === 1 &&
                  loginUserData.isDeleted === false
                ) {
                  setCurrentRole(validRoles[0]);
                }
                else if (!validRoles.length) {
                  dispatch(actionSetApplicationRegisterUser(false))
                  setCurrentRole(NEW_USER);
                  dispatch(actionUserProfileSettingPageShow(true));
                  history.push(HOME_PATH_AFTER_LOGIN);
                }
                else setCurrentRole(NEW_USER)
              }
              else {
                const selectedObj = {
                  userSelectedData: userSelectedData,
                };
                localStorage.setItem(
                  "userSelectedData",
                  JSON.stringify(userSelectedData)
                );
                dispatch(actionSaveRegisterUserSelectedRoleData(selectedObj));
                setCurrentRole(userSelectedData.userSelectedRole);
              }

            } else if (selectedAppDetails.id === applicationData[1].id) {
              if (loginUserData.role.find((r: any) => r === USER.ROLE.VC_ASSESSOR)) {
                setCurrentRole(USER.ROLE.VC_ASSESSOR);
              } else {
                setCurrentRole(NEW_USER);
              }
            }
            else {
              setCurrentRole(NEW_USER);
            }
          }
          else {
            setCurrentRole(NOT_LOGGED_IN);
          }
        }
      } else {
        if (selectedObj && selectedObj.hasOwnProperty("userSelectedData")) {
          checkRole(selectedObj.userSelectedData.userSelectedRole);
        } else {
          setCurrentRole(NEW_USER);
        }
      }
      if (userIdProfileSettingData && userIdProfileSettingData.length) {
        if (
          localSelectedData === null ||
          !localSelectedData.hasOwnProperty("userSelectedRole")
        ) {
          userId = userIdProfileSettingData[0].id;
          createRegionDataFun();
          createRoleDataFun();
          userRoleDetails = getValidAppRoles(listOfRoles);
          createRoleDataForEachRole();
          if (userRoleDetails.length === 1) {
            const app = scsUserDetails.applications.filter((app: any) => app.id === selectedAppDetails.id);
            const found = app.length && app[0].roles.filter((role: any) => role.id === userRoleDetails[0].id);
            if (found.length > 0)
              roleSelectionSetting();
          }
          else if (userRoleDetails.length > 1) {
            history.push(HOME_PATH_AFTER_LOGIN);
            dispatch(actionUserProfileSettingPageShow(true));
          }
          else {
            dispatch(actionSetApplicationRegisterUser(false));
            setCurrentRole(NEW_USER);
            history.push(HOME_PATH_AFTER_LOGIN);
            dispatch(actionUserProfileSettingPageShow(true));
          }
        }
      }
    }
  }, [
    selectedObj,
    loginUserData,
    roleData,
    listOrgWiseCountry,
    userIdProfileSettingData,
    listOrgWiseRegion,
    isUserAuthenticated
  ]);

  const getValidAppRoles = (listOfRoles: any) => {
    const app = scsUserDetails.applications.filter((app: any) => app.id === selectedAppDetails.id);
    if (app.length > 0) {
      let userValidRoles = listOfRoles.filter((role: any) => {
        const isPresent = app[0].roles.find((appRole: any) => typeof role === 'string' ? appRole.name === role : appRole.id === role.id);
        if (isPresent) {
          return role;
        }
      })
      return userValidRoles;
    }
    return listOfRoles;
  }

  const checkRole = (role: any) => {
    if (role && loginUserData.isDeleted === false) {
      const tempRoleData: any[] = roleData.filter(
        (roleE: any) => roleE.id === role
      );
      setCurrentRole(tempRoleData[0].name.trim());
    } else {
      if (loginUserData) {
        setCurrentRole(NEW_USER);
      } else {
        setCurrentRole(NOT_LOGGED_IN);
      }
    }
  };

  const createRegionDataFun = () => {
    if (Array.isArray(userIdProfileSettingData)) {
      userIdProfileSettingData[0].countryRoles.forEach(function (value: any) {
        //finding roles assigned region
        const tempRoleData = roleData.filter(
          (roleE: any) =>
            roleE.name.toLowerCase().trim() === value.role.toLowerCase().trim()
        );

        if (tempRoleData.length) {
          listOfRegion.push({
            value: value.region,
            label: value.region,
            id: value.region,
            key: tempRoleData[0].id,
          });
        }
      });
    }
  };
  const createRoleDataFun = () => {
    if (Array.isArray(userIdProfileSettingData)) {
      userIdProfileSettingData[0].countryUsers.forEach((value: any) => {
        const tempRoleData = roleData.filter(
          (roleE: any) =>
            roleE.id.toLowerCase().trim() === value.roleId.toLowerCase().trim()
        );
        const checkRoleMatchLength = listOfRoles.filter(
          (role: any) => role.id === tempRoleData[0].id
        ).length;
        if (tempRoleData.length) {
          if (listOfRoles.length === 0) {
            listOfRoles.push({
              key: tempRoleData[0].id,
              id: tempRoleData[0].id,
              label: tempRoleData[0].name.trim(),
              regions: [],
              countries: [],
            });
          } else if (checkRoleMatchLength === 0) {
            listOfRoles.push({
              key: tempRoleData[0].id,
              id: tempRoleData[0].id,
              label: tempRoleData[0].name.trim(),
              regions: [],
              countries: [],
            });
          }
        }
        const tempCountryData = listOrgWiseCountry.filter(
          (country: any) => country.id === value.countryId
        );
        if (tempCountryData.length) {
          listOfCountry.push({
            key: tempCountryData[0].id,
            id: tempCountryData[0].id,
            value: tempCountryData[0].id,
            label: tempCountryData[0].label,
            region: tempCountryData[0].region,
            roleId: value.roleId,
          });
        }
      });
    }
  };
  const createRoleDataForEachRole = () => {
    userRoleDetails.forEach(function (role: any) {
      const tempRoleMap = roleData.filter((roleM: any) => roleM.id === role.id);
      if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.SD_ADMIN.toLowerCase()
      ) {
        role.regions = listOrgWiseRegion;
        role.countries = listOrgWiseCountry;
      } else if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
      ) {
        const filterRegionList = listOfRegion.filter(
          (region: any) => region.key === role.id
        );
        if (filterRegionList.length) {
          role.regions = sortObjectDataByAsending(filterRegionList, "label");
        }
        const filterCountryList = listOrgWiseCountry.filter(
          (country: any) => country.region === role.regions[0].label
        );
        if (filterCountryList.length) {
          role.countries = sortObjectDataByAsending(filterCountryList, "label");
        }
      } else if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
      ) {
        role.regions = listOrgWiseRegion;
        role.countries = listOrgWiseCountry;
      } else {
        const filterRegionList = listOfRegion.filter(
          (region: any) => region.key === role.id
        );
        if (filterRegionList.length) {
          role.regions = sortObjectDataByAsending(filterRegionList, "label");
        }

        const filterCountryList = listOfCountry.filter(
          (country: any) => country.roleId === role.id
        );
        if (filterCountryList.length) {
          role.countries = sortObjectDataByAsending(filterCountryList, "label");
        }
      }
    });
  };
  const roleSelectionSetting = () => {
    const roleMap = roleData.filter(
      (roleE: any) => roleE.id === userRoleDetails[0].id
    );
    if (
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.SD_ADMIN.toLowerCase()
    ) {
      reArrangeData(
        userRoleDetails[0].id,
        userRoleDetails[0].regions,
        userRoleDetails[0].countries,
        roleMap[0].name
      );
    } else if (
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
    ) {
      listOrgWiseCountry.map((country: any) => {
        if (userRoleDetails[0].regions[0].id === country.region) {
          allCountryList.push({
            key: country.id,
            id: country.id,
            value: country.id,
            label: country.label,
            region: country.region,
            roleId: userRoleDetails[0].regions[0].id,
          });
        }
      });

      if (userRoleDetails[0].regions.length === 1) {
        reArrangeData(
          userRoleDetails[0].id,
          userRoleDetails[0].regions[0].id,
          userRoleDetails[0].countries,
          roleMap[0].name
        );
      } else {
        dispatch(actionUserProfileSettingPageShow(true));
        history.push(HOME_PATH_AFTER_LOGIN);
      }
    } else if (
      roleMap[0].name.toLowerCase().trim() ===
      USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
    ) {
      reArrangeData(
        userRoleDetails[0].id,
        userRoleDetails[0].regions,
        userRoleDetails[0].countries,
        roleMap[0].name
      );
    } else if (
      roleMap.length &&
      (roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.ASSESSOR.toLowerCase() ||
        roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.VALIDATOR.toLowerCase())
    ) {
      //cheking if user has one role one region and one country assigned
      if (userRoleDetails[0].regions.length === 1) {
        if (userRoleDetails[0].countries.length === 1) {
          reArrangeData(
            userRoleDetails[0].id,
            userRoleDetails[0].regions[0].id,
            userRoleDetails[0].countries[0].id,
            roleMap[0].name
          );
        } else {
          dispatch(actionUserProfileSettingPageShow(true));
          history.push(HOME_PATH_AFTER_LOGIN);
        }
      } else {
        dispatch(actionUserProfileSettingPageShow(true));
        history.push(HOME_PATH_AFTER_LOGIN);
      }
    } else if (roleMap[0].name.toLowerCase().trim() === USER.ROLE.VC_ASSESSOR.toLowerCase()) {
      if (selectedAppDetails.id !== applicationData[1].id)
        return
      reArrangeData(
        userRoleDetails[0].id,
        userRoleDetails[0].regions,
        userRoleDetails[0].countries,
        roleMap[0].name
      );
    }
  };
  const reArrangeData = (
    Role: any,
    Region: any,
    Country: any,
    roleName: any
  ) => {
    let regionArr: any = [];
    let countryArr: any = [];
    if (Array.isArray(Region)) {
      Region.map((region: any) => {
        regionArr.push(region.id);
      });
    } else {
      regionArr.push(Region);
    }
    if (Array.isArray(Country)) {
      Country.map((country: any) => {
        countryArr.push(country.id);
      });
    } else {
      countryArr.push(Country);
    }
    userSelectedData = {
      userSelectedRole: Role,
      userSelectedRegion: regionArr,
      userSelectedCountry: countryArr,
      organization: organizationSelected,
      userSelectedApp: selectedAppDetails.id || null
    };

    const selectedObj = {
      userSelectedData: userSelectedData
    };
    localStorage.setItem("userSelectedData", JSON.stringify(userSelectedData));

    const shareSetting = {
      id: userId,
      UserSettings: JSON.stringify(userSelectedData),
    };
    dispatch(actionSaveRegisterUserSelectedRoleData(selectedObj));
    dispatch(actionUpdateUserSetting(shareSetting));
  };

  return (
    <>
      <MainPageWrapper>
        <Header />
        {(isUserAuthenticated) || currentRole != "" ? (
          <I18nextProvider i18n={i18n}>
            <Loader visible={isLoaderVisible} />
            {roleData.length ?
              (
                <>
                  {currentRole === USER.ROLE.SD_ADMIN && (
                    <Switch>
                      <Route
                        exact={true}
                        path={NEW_REQUEST}
                        component={NewRequestComponent}
                      />
                      <Route
                        exact={true}
                        path={ALL_REQUESTS}
                        component={AllRequestsComponent}
                      />

                      <Route
                        exact={true}
                        path={MASTER + "/:id"}
                        component={MasterMenuComponent}
                      />
                      <Route
                        exact={true}
                        path={MATURITY_INDEX}
                        component={MaturityIndexValueComponent}
                      />
                      <Route
                        exact={true}
                        path={AREA_WORK_TOOL}
                        component={AreaOfWorkToolComponent}
                      />
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />

                      <Route
                        exact={true}
                        path={QUESTION}
                        component={QuestionSetComponent}
                      />
                      <Route
                        exact={true}
                        path={USER_PROFILE}
                        component={UserProfileComponent}
                      />
                      <Route
                        exact={true}
                        path={ASSESSMENT_OVERVIEW}
                        component={AdminRSAOverview}
                      />
                      <Route
                        exact={true}
                        path={ASSESMENT}
                        component={AdminAssessmentsComponent}
                      />
                      <Route
                        exact={true}
                        path={AOW_MANAGEMENT}
                        component={AreaOfWorkManagementComponent}
                      />
                      <Route
                        exact={true}
                        path={COUNTRY_HIERARCHY}
                        component={CountryHierarchyComponent}
                      />
                      <Route exact={true} path={REPORT} component={ReportComponent} />
                      <Route exact={true} path={VC_REPORT} component={VCReportComponent} />
                      <Route exact={true} path={HOME_PATH} component={HomePage} />
                      <Redirect to={ASSESSMENT_OVERVIEW} />
                    </Switch>
                  )}
                  {currentRole === USER.ROLE.RSA && (
                    <Switch>
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />
                      <Route
                        exact={true}
                        path={ASSESSMENT_OVERVIEW}
                        component={AdminRSAOverview}
                      />
                      <Route
                        exact={true}
                        path={ASSESMENT}
                        component={RsaAssessmentsComponent}
                      />
                      <Route exact={true} path={REPORT} component={ReportComponent} />
                      <Route exact={true} path={HOME_PATH} component={HomePage} />
                      <Redirect to={ASSESSMENT_OVERVIEW} />
                    </Switch>
                  )}
                  {currentRole === USER.ROLE.GLOBAL_ADVISOR && (
                    <Switch>
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />
                      <Route
                        exact={true}
                        path={ASSESSMENT_OVERVIEW}
                        component={AdminRSAOverview}
                      />
                      <Route
                        exact={true}
                        path={ASSESMENT}
                        component={AdminAssessmentsComponent}
                      />
                      <Route exact={true} path={REPORT} component={ReportComponent} />
                      <Route exact={true} path={HOME_PATH} component={HomePage} />
                      <Redirect to={AdminRSAOverview} />
                    </Switch>
                  )}
                  {currentRole === USER.ROLE.VALIDATOR && (
                    <Switch>
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />
                      <Route
                        exact={true}
                        path={ASSESSMENT_OVERVIEW}
                        component={AssessmentOverview}
                      />
                      <Route
                        exact={true}
                        path={ASSESMENT}
                        component={AssesmentCreateComponent}
                      />
                      <Route
                        exact={true}
                        path={REPORT}
                        component={AssessmentReportComponent}
                      />
                      <Route exact={true} path={HOME_PATH} component={HomePage} />
                      <Redirect to={ASSESSMENT_OVERVIEW} />
                    </Switch>
                  )}
                  {currentRole === USER.ROLE.ASSESSOR && (
                    <Switch>
                      <Route exact={true} path={HOME_PATH} component={HomePage} />
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />
                      <Route
                        exact={true}
                        path={ASSESSMENT_OVERVIEW}
                        component={AssessmentOverview}
                      />
                      <Route
                        exact={true}
                        path={ASSESMENT}
                        component={AssesmentCreateComponent}
                      />
                      <Route
                        exact={true}
                        path={REPORT}
                        component={AssessmentReportComponent}
                      />
                      <Redirect to={ASSESSMENT_OVERVIEW} />
                    </Switch>
                  )}
                  {currentRole === USER.ROLE.VC_ASSESSOR && (
                    <Switch>
                      <Route
                        exact={true}
                        path={VC_ASSESMENT}
                        component={VCAssesmentComponent}
                      />
                      <Redirect to={VC_ASSESMENT} />
                    </Switch>
                  )}
                  {currentRole === NOT_LOGGED_IN && (
                    <Switch>
                      <Route exact={true} path={HOME} component={LandingPage} />
                      <Route exact={true} path={ABOUT_US} component={AboutUs} />
                      <Route exact={true} path={PRE_LOGIN} component={PreLoginPage} />
                      <Route exact={true} path={HOW_TO_JOIN} component={HowToJoin} />
                      <Redirect to={HOME} />
                    </Switch>
                  )}
                  {currentRole === NEW_USER && (
                    <Switch>
                      <Route
                        exact={true}
                        path={HOME_PATH_AFTER_LOGIN}
                        component={HomePage}
                      />
                      <Redirect to={HOME_PATH_AFTER_LOGIN} />
                    </Switch>
                  )}
                </>
              ) : null}
          </I18nextProvider>) : (
          <React.Fragment>
            <Switch>
              <Route exact={true} path={HOME_PATH} component={LandingPage} />
              <Route exact={true} path={HOME} component={LandingPage} />
              <Route exact={true} path={HOW_TO_JOIN} component={HowToJoin} />
              <Route exact={true} path={ABOUT_US} component={AboutUs} />
              <Route exact={true} path={ERROR_PATH} component={ErrorComponent} />
            </Switch>
          </React.Fragment>
        )}
      </MainPageWrapper>
      {!isHomePath && isLoaderVisible === false && <FooterComponent />}
    </>
  );
}
export default withTranslation()(MainHomeComponent);
