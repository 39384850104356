import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { actionSetPageTitle, closeToaster } from "../../store/common/actions";
import { sortObjectDataByAsending } from "../../utils/data";
import { actionHeaderUserSetting } from "../header/actions";
import {
    actionFetchUserProfileData,
    actionFetchUserIdProfileData,
    actionFetchUserIdProfileDataSuccess,
    actionFetchSCSuserAccessRequested,
} from "./actions";
import { Toaster } from "../../components/common/toaster.component";
import { useTranslation } from "react-i18next";
import UserProfileFormComponent from "./user-profile-form";
import UserProfileTabComponent from "./user-profile-tab";
import "./index.css";
import { Grid, Typography, Container, IconButton } from "@material-ui/core";
import { getLocalDateFromMoment } from "../../utils/data";
import {
    BoxShadowWrapper,
    SubHeaderWrapper,
    DownloadIconCustom,
    ArrowBackIosIconCustom,
} from "styles/common";
import { USER } from "constants/data";

function UserProfileComponent(props: any) {
    const dispatch = useDispatch();
    const useProfileData = useSelector(
        (state: any) => state.userProfile.userProfileData
    );
    const useProfileEditData = useSelector(
        (state: any) => state.userProfile.useProfileEditData
    );
    const countryData = useSelector((state: any) => state.common.countryData);
    const roleData = useSelector((state: any) => state.common.roleData);
    const loginUserData = useSelector((state: any) => state.header.loginUserData);
    const toasterMessage = useSelector(
        (state: any) => state.common.toasterMessage
    );
    const isToasterVisible = useSelector(
        (state: any) => state.common.isToasterVisible
    );
    const languageSelected = useSelector(
        (state: any) => state.header.languageSelected
    );
    const { t } = useTranslation();
    const isSuccess = useSelector((state: any) => state.common.isSuccess);
    const formTitle = useSelector((state: any) => state.common.pageTitle);
    const listUnicefRegion = useSelector(
        (state: any) => state.common.listUnicefRegion
    );
    const listUnicefCountry = useSelector(
        (state: any) => state.common.listUnicefCountry
    );
    const listWhoRegion = useSelector((state: any) => state.common.listWhoRegion);
    const listWhoCountry = useSelector(
        (state: any) => state.common.listWhoCountry
    );
    const listPrograms = useSelector((state: any) => state.common.listProgram);
    const scsHubAccessRequestedList = useSelector(
        (state: any) => state.userProfile.scsHubAccessRequestedList
    );
    const [showTable, setShowTable] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editFormValues, setEditFormValues] = useState({});
    const [listRoles, setListRoles] = useState([]);
    const [counterRegion, setCounterRegion] = useState(0);
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [modifiedDate, setModifiedDate] = useState("");
    const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
    const [firstTimeCall, setFirstTimeCall] = useState(true);
    const [organizationValue, setOrganizationValue]: any = useState("Unicef");
    const [countryListOrgWise, setCountryListOrgWise]: any = useState([]);
    const [regionListOrgWise, setRegionListOrgWise]: any = useState([]);
    const [fieldsRegionArray, setFieldsRegionArray] = useState([]);
    const [isRefreshTable, setIsRefreshTable] = useState(false);
    const allOrganization: any = (window as any).allOrganization;

    let listOfRoles: any = [];

    useEffect(() => {
        return () => {
            dispatch(actionFetchUserIdProfileDataSuccess([]));
        };
    }, []);

    useEffect(() => {
        defaultMethodCall();
        setFirstTimeCall(false);
    }, [dispatch]);

    useEffect(() => {
        if (loginUserData) {
            const user = `${loginUserData.email} (${loginUserData.name !== null ? loginUserData.name : ""
                }  ${loginUserData.lastName !== null ? loginUserData.lastName : ""})`;
            setUserId(loginUserData.id);
            setUserName(user);
        }
    }, [loginUserData]);

    useEffect(() => {
        if (useProfileEditData && useProfileEditData.length) {
            editFormValuesGenrateFieldArray(useProfileEditData[0]);
        }
    }, [useProfileEditData]);

    useEffect(() => {
        populateDataRegionCountry();
    }, [
        countryData,
        listUnicefRegion,
        listUnicefCountry,
        listWhoCountry,
        listWhoRegion,
    ]);

    useEffect(() => {
        populateDataRole();
    }, [roleData]);

    const defaultMethodCall = () => {
        const title = t("userProfile.title");
        dispatch(actionSetPageTitle(title));
        dispatch(actionFetchUserProfileData("-1"));
        dispatch(actionFetchSCSuserAccessRequested());
        dispatch(actionHeaderUserSetting(true));
    };

    useEffect(() => {
        if (firstTimeCall === false) {
            dispatch(actionFetchUserProfileData("-1"));
        }
    }, [languageSelected]);

    useEffect(() => {
        if (isRefreshTable === true) {
            dispatch(actionFetchUserProfileData("-1"));
        }
    }, [isRefreshTable]);

    const showHideTableAndForm = (request: boolean) => {
        setCreatedDate(getLocalDateFromMoment(null));
        setModifiedDate(getLocalDateFromMoment(null));
        setEditFormValues([]);
        setIsEdit(false);
        setShowTable(!request);
        functionSetDefaultRegionArray();
    };

    //call function through the table Edit row value
    const functionEditForm = (rowValues: any) => {
        dispatch(actionFetchUserIdProfileData(rowValues.id));
    };

    const editFormValuesGenrateFieldArray = (editRowValues: any) => {
        let roleEdit: any = [];
        let countryMapUser: any = [];
        let user = "";
        let regionListOrgWise: any = listUnicefRegion;
        let countryListOrgWise: any = listUnicefCountry;

        if (editRowValues.updatedByUserEmail) {
            user = `${editRowValues.updatedByUserEmail} (${editRowValues.updatedByName !== null ? editRowValues.updatedByName : ""
                })`;
        }

        if (editRowValues.organization) {
            setOrganizationValue(editRowValues.organization);
            if (
                editRowValues.organization.toLowerCase() ===
                allOrganization[1].id.toLowerCase()
            ) {
                regionListOrgWise = listWhoRegion;
                countryListOrgWise = listWhoCountry;
            }
        }
        setRegionListOrgWise(regionListOrgWise);
        setCountryListOrgWise(countryListOrgWise);
        setUserName(user);
        setCreatedDate(getLocalDateFromMoment(editRowValues.createdDate));
        setModifiedDate(getLocalDateFromMoment(editRowValues.updatedDate));
        editRowValues.countryUsers.map((user: any) => {
            if (!roleEdit.includes(user.roleId)) {
                roleEdit.push(user.roleId);
            }
        });
        if (roleEdit.length) {
            roleEdit.map((role: any) => {
                let regionMap: any = [];
                let countryMap: any = [];
                let rolename = roleData.filter((roleE: any) => roleE.id === role);
                if (
                    rolename[0].name.toLowerCase().trim() ===
                    USER.ROLE.SD_ADMIN.toLowerCase()
                ) {
                    countryMapUser.push({
                        countryMap: countryMap,
                        regionMap: regionMap,
                        roleMap: rolename,
                    });
                }

                if (
                    rolename[0].name.toLowerCase().trim() ===
                    USER.ROLE.ASSESSOR.toLowerCase() ||
                    rolename[0].name.toLowerCase().trim() ===
                    USER.ROLE.VALIDATOR.toLowerCase()
                ) {
                    editRowValues.countryUsers.filter((c: any) => {
                        if (c.roleId === rolename[0].id) {
                            let country: any = countryListOrgWise.filter(
                                (a: any) => a.id === c.countryId
                            );
                            countryMap.push({
                                label: country[0].label,
                                value: country[0].id,
                            });
                            if (
                                regionMap.length === 0 ||
                                regionMap.filter((r: any) => r.value === c.region).length === 0
                            ) {
                                let region: any = regionListOrgWise.filter(
                                    (a: any) => a.id === c.region
                                );
                                regionMap.push({
                                    label: region[0].label,
                                    value: region[0].id,
                                });
                            }
                        }
                    });

                    countryMapUser.push({
                        countryMap: countryMap,
                        regionMap: regionMap,
                        roleMap: rolename,
                        organization: editRowValues.organization,
                    });
                }
                if (
                    rolename[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
                ) {
                    editRowValues.countryUsers.filter((c: any) => {
                        if (c.roleId === rolename[0].id) {
                            if (
                                regionMap.length === 0 ||
                                regionMap.filter((r: any) => r.value == c.region).length === 0
                            ) {
                                let region: any = regionListOrgWise.filter(
                                    (a: any) => a.id === c.region
                                );
                                regionMap.push({
                                    label: region[0].label,
                                    value: region[0].id,
                                });
                            }
                        }
                    });

                    countryMapUser.push({
                        countryMap: countryMap,
                        regionMap: regionMap,
                        roleMap: rolename,
                        organization: editRowValues.organization,
                    });
                }
                if (
                    rolename[0].name.toLowerCase().trim() ===
                    USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
                ) {
                    countryMapUser.push({
                        countryMap: [],
                        regionMap: [],
                        roleMap: rolename,
                        organization: editRowValues.organization,
                    });
                }
                if (
                    rolename[0].name.toLowerCase().trim() ===
                    USER.ROLE.VC_ASSESSOR.toLowerCase()
                ) {
                    countryMapUser.push({
                        countryMap: [],
                        regionMap: [],
                        roleMap: rolename,
                        organization: editRowValues.organization,
                    });
                }
            });
        }

        if (countryMapUser.length) {
            setFieldsRegionArray([]);
            let fieldsArray: any = [];
            setCounterRegion(0);
            countryMapUser.map((countryuser: any, index: any) => {
                if (
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.SD_ADMIN.toLowerCase()
                ) {
                    fieldsArray.push({
                        region: "",
                        country: "",
                        roleList: listRoles,
                        countryList: countryListOrgWise,
                        regionList: regionListOrgWise,
                        counterRegion: index,
                        isCountryDisable: true,
                        isRegionDisable: true,
                        setCountryUser: countryuser.countryMap,
                        setRegionUser: countryuser.regionMap,
                        setRoleUser: countryuser.roleMap,
                        isRoleRequired: true,
                        isRegionRequired: false,
                        isCountryRequired: false,
                        isProgramRequired: false,
                        setPrograms: []
                    });
                    setFieldsRegionArray(fieldsArray);
                    setCounterRegion(1);
                }
                if (
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.ASSESSOR.toLowerCase() ||
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.VALIDATOR.toLowerCase()
                ) {
                    let filterCountryList: any = [];
                    countryuser.regionMap.map((regionM: any) => {
                        const countryFilterData = countryListOrgWise.filter(
                            (countryF: any) => countryF.region === regionM.value
                        );
                        filterCountryList = [...filterCountryList, ...countryFilterData];
                    });

                    filterCountryList = sortObjectDataByAsending(
                        filterCountryList,
                        "label"
                    );

                    let selectedPrograms: any = [];
                    let userRoleProgram = editRowValues.userPrograms.filter(
                        (prog: any) => prog.roleId === countryuser.roleMap[0].id
                    );
                    if (userRoleProgram.length) {
                        userRoleProgram[0].programIds.filter((progId: any) => {
                            let program: any = listPrograms.filter(
                                (a: any) => a.id === progId.toString()
                            );
                            selectedPrograms.push({
                                label: program[0].label,
                                value: program[0].id,
                            });
                        });
                    }
                    fieldsArray.push({
                        region: "",
                        country: "",
                        role: "",
                        roleList: listRoles,
                        countryList: filterCountryList,
                        regionList: regionListOrgWise,
                        counterRegion: index,
                        isCountryDisable: false,
                        isRegionDisable: false,
                        setCountryUser: countryuser.countryMap,
                        setRegionUser: countryuser.regionMap,
                        setRoleUser: countryuser.roleMap,
                        isRoleRequired: true,
                        isRegionRequired: true,
                        isCountryRequired: true,
                        organization: editRowValues.organization,
                        isProgramRequired: true,
                        setPrograms: selectedPrograms
                    });
                }
                if (
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.RSA.toLowerCase()
                ) {
                    fieldsArray.push({
                        region: "",
                        country: "",
                        role: "",
                        roleList: listRoles,
                        countryList: countryListOrgWise,
                        regionList: regionListOrgWise,
                        counterRegion: index,
                        isCountryDisable: true,
                        isRegionDisable: false,
                        setCountryUser: countryuser.countryMap,
                        setRegionUser: countryuser.regionMap,
                        setRoleUser: countryuser.roleMap,
                        isRoleRequired: true,
                        isRegionRequired: true,
                        isCountryRequired: false,
                        organization: editRowValues.organization,
                        isProgramRequired: false,
                        setPrograms: []
                    });
                }
                if (
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
                ) {
                    fieldsArray.push({
                        region: "",
                        country: "",
                        roleList: listRoles,
                        countryList: countryListOrgWise,
                        regionList: regionListOrgWise,
                        counterRegion: index,
                        isCountryDisable: true,
                        isRegionDisable: true,
                        setCountryUser: countryuser.countryMap,
                        setRegionUser: countryuser.regionMap,
                        setRoleUser: countryuser.roleMap,
                        isRoleRequired: true,
                        isRegionRequired: false,
                        isCountryRequired: false,
                        isProgramRequired: false,
                        setPrograms: []
                    });
                }
                if (
                    countryuser.roleMap[0].name.toLowerCase().trim() ===
                    USER.ROLE.VC_ASSESSOR.toLowerCase()
                ) {
                    fieldsArray.push({
                        region: "",
                        country: "",
                        roleList: listRoles,
                        countryList: countryListOrgWise,
                        regionList: regionListOrgWise,
                        counterRegion: index,
                        isCountryDisable: true,
                        isRegionDisable: true,
                        setCountryUser: countryuser.countryMap,
                        setRegionUser: countryuser.regionMap,
                        setRoleUser: countryuser.roleMap,
                        isRoleRequired: true,
                        isRegionRequired: false,
                        isCountryRequired: false,
                        isProgramRequired: false,
                        setPrograms: []
                    });
                }
            });
            setFieldsRegionArray(fieldsArray);
            setCounterRegion((prevCounter) => countryMapUser.length);
        } else {
            functionSetDefaultRegionArray();
        }

        setEditFormValues(editRowValues);
        setIsEdit(true);
        setShowTable(false);
    };

    // Populate 1st time Data Organization Wise
    const populateDataRegionCountry = () => {
        if (
            countryData &&
            countryData.length &&
            listUnicefRegion.length &&
            listUnicefCountry.length &&
            listWhoRegion.length &&
            listWhoCountry.length
        ) {
            setIsAddButtonDisable(false);
            setRegionListOrgWise(listUnicefRegion);
            setCountryListOrgWise(listUnicefCountry);
        }
    };
    // populate role data
    const populateDataRole = () => {
        if (roleData && roleData.length) {
            roleData.map((role: any) => {
                listOfRoles.push({
                    key: role.id,
                    id: role.id,
                    label: role.name,
                });
            });

            setListRoles(listOfRoles);
        }
    };

    const functionSetDefaultRegionArray = () => {
        setFieldsRegionArray([]);
        let values: any = [];

        setCounterRegion(0);
        values.push({
            region: "",
            country: "",
            roleList: listRoles,
            countryList: [],
            regionList: listUnicefRegion,
            counterRegion: 0,
            isCountryDisable: false,
            isRegionDisable: false,
            isRoleRequired: true,
            isRegionRequired: false,
            isCountryRequired: false,
            setCountryUser: [],
            setRegionUser: [],
            setRoleUser: [],
            organization: null,
            isProgramRequired: false,
            setPrograms: []
        });
        setFieldsRegionArray(values);
        setCounterRegion(1);
    };
    const closeToasterFun = () => {
        dispatch(closeToaster());
    };
    return (
        <Container maxWidth={"xl"}>
            <Toaster
                message={toasterMessage}
                open={isToasterVisible}
                close={closeToasterFun}
                success={isSuccess}
            />
            {showTable ? (
                <>
                    <SubHeaderWrapper>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography component="span" className="common-label">
                                    {t("userProfile.manageUser")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className="display-hidden"
                                        >
                                            <DownloadIconCustom className="arMarginR0 arMarginL10" />
                                            {t("common.exportToExcel")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => showHideTableAndForm(showTable)}
                                            disabled={isAddButtonDisable}
                                        >
                                            {t("userProfile.createNewUser")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubHeaderWrapper>
                    <BoxShadowWrapper>
                        <UserProfileTabComponent
                            useProfileData={useProfileData}
                            formTitle={formTitle}
                            functionEditForm={functionEditForm}
                            showHideTableAndForm={showHideTableAndForm}
                            scsHubAccessRequestedList={scsHubAccessRequestedList}
                        ></UserProfileTabComponent>
                    </BoxShadowWrapper>
                </>
            ) : (
                <>
                    <SubHeaderWrapper>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <IconButton
                                            className="padding10"
                                            color="default"
                                            onClick={() => showHideTableAndForm(showTable)}
                                        >
                                            <ArrowBackIosIconCustom className="arRotate180" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            component="span"
                                            className="common-label padding10-5"
                                        >
                                            {isEdit ? t("common.edit") : t("common.add")}{" "}
                                            {t("userProfile.title")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubHeaderWrapper>
                    <BoxShadowWrapper>
                        <UserProfileFormComponent
                            useProfileData={useProfileData}
                            formTitle={formTitle}
                            editFormValues={editFormValues}
                            isEdit={isEdit}
                            showHideTableAndForm={showHideTableAndForm}
                            countryData={countryData}
                            roleData={roleData}
                            listRoles={listRoles}
                            fieldsRegionArray={fieldsRegionArray}
                            counterRegion={counterRegion}
                            setCounterRegion={setCounterRegion}
                            setFieldsRegionArray={setFieldsRegionArray}
                            closeToasterFun={closeToasterFun}
                            userName={userName}
                            userId={userId}
                            createdDate={createdDate}
                            modifiedDate={modifiedDate}
                            organizationValue={organizationValue}
                            setOrganizationValue={setOrganizationValue}
                            allOrganization={allOrganization}
                            listRegions={listUnicefRegion}
                            listCountry={listUnicefCountry}
                            listWhoRegions={listWhoRegion}
                            listWhoCountry={listWhoCountry}
                            regionListOrgWise={regionListOrgWise}
                            setRegionListOrgWise={setRegionListOrgWise}
                            setCountryListOrgWise={setCountryListOrgWise}
                            countryListOrgWise={countryListOrgWise}
                            setIsRefreshTable={setIsRefreshTable}
                        ></UserProfileFormComponent>
                    </BoxShadowWrapper>
                </>
            )}
        </Container>
    );
}

export default UserProfileComponent;
