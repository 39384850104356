import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";

import { actionFetchData } from "./actions";
import { actionSetPageTitle } from "../../store/common/actions";
import { PageWrapper } from "../../styles/common";
import { history } from "../../store/config";
import { NEW_REQUEST } from "../../constants/api";
import MaterialTableGrid from "./grid";
import { GridTableWrapper } from "./style";

function AllRequestsComponent(props: any) {
  const gridData = useSelector((state: any) => state.allRequests.postData);
  const dispatch = useDispatch();

  useEffect(() => {
    const title = "All Requests";
    dispatch(actionFetchData());
    dispatch(actionSetPageTitle(title));
  }, [dispatch]);

  const goToNewRequest = () => {
    history.push(NEW_REQUEST);
  };

  return (
    <PageWrapper>
      <Button variant="contained" color="primary" onClick={goToNewRequest}>
        NEW REQUEST
      </Button>

      <GridTableWrapper>
        <MaterialTableGrid gridData={gridData} showFilter={true} />
      </GridTableWrapper>
    </PageWrapper>
  );
}

export default AllRequestsComponent;
