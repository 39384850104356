import { ActionTypes } from "./constants";

export const actionSetPageTitle = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.COMMON_SET_PAGE_TITLE,
});

export const actionFetchCountryData = () => ({
  payload: {},
  type: ActionTypes.COMMON_FETCH_COUNTRY_DATA,
});

export const actionFetchCountryDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.COMMON_FETCH_COUNTRY_DATA_SUCCESS,
});

export const closeToaster = () => ({
  payload: {
    data: "",
    success: true,
    visible: false,
  },
  type: ActionTypes.COMMON_SET_TOASTER_VISIBILITY,
});

export const openToaster = (message: string, success: boolean) => ({
  payload: {
    data: message,
    success,
    visible: true,
  },
  type: ActionTypes.COMMON_SET_TOASTER_VISIBILITY,
});

export const closeDialog = () => ({
  payload: {
    data: "",
    visible: false,
  },
  type: ActionTypes.COMMON_SET_DIALOG_VISIBILITY,
});

export const openDialog = (message: string) => ({
  payload: {
    data: message,
    visible: true,
  },
  type: ActionTypes.COMMON_SET_DIALOG_VISIBILITY,
});

export const setLoaderVisibility = (payload: boolean) => (
  {
    payload,
    type: ActionTypes.COMMON_SET_LOADER_VISIBILITY,
  });

export const setSectionLoaderVisibility = (payload: boolean) => ({
  payload,
  type: ActionTypes.COMMON_SET_SECTION_LOADER_VISIBILITY,
});

export const actionGetUserDetails = (email: string) => ({
  type: ActionTypes.COMMON_GET_USER_DETAILS,
  payload: email,
});

export const actionUserLoggedIn = (status: boolean) => ({
  payload: {
    status,
  },
  type: ActionTypes.COMMON_USER_LOGGED_IN,
});

export const setBrowserTitle = (title: string) => ({
  payload: {
    data: title,
  },
  type: ActionTypes.COMMON_SET_BROWSER_TITLE,
});

export const actionSetCurrentRoute = (routeName: any) => ({
  payload: { routeName },
  type: ActionTypes.COMMON_SET_CURRENT_ROUTE,
});

export const actionFetchUserRoleData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.COMMON_GET_USER_ROLE_DETAILS,
});

export const actionFetchUserRoleDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.COMMON_GET_USER_ROLE_DETAILS_SUCCESS,
});

export const actionSetUnicefRegion = (region: []) => ({
  payload: {
    data: region,
  },
  type: ActionTypes.COMMON_SET_UNICEF_REGION,
});

export const actionSetUnicefCountry = (country: []) => ({
  payload: {
    data: country,
  },
  type: ActionTypes.COMMON_SET_UNICEF_COUNTRY,
});

export const actionSetWhoRegion = (region: []) => ({
  payload: {
    data: region,
  },
  type: ActionTypes.COMMON_SET_WHO_REGION,
});

export const actionSetWhoCountry = (country: []) => ({
  payload: {
    data: country,
  },
  type: ActionTypes.COMMON_SET_WHO_COUNTRY,
});

export const actionSetProgramList = (program: []) => ({
  payload: {
    data: program,
  },
  type: ActionTypes.COMMON_SET_PROGRAM,
});

export const actionSetScopeList = (scope: []) => ({
  payload: {
    data: scope,
  },
  type: ActionTypes.COMMON_SET_SCOPE,
});

export const actionSetAreaOfWorkList = (areaOfWork: []) => ({
  payload: {
    data: areaOfWork,
  },
  type: ActionTypes.COMMON_SET_AREA_OF_WORK,
});

export const actionSetSubAreaOfWorkList = (subAreaOfWork: []) => ({
  payload: {
    data: subAreaOfWork,
  },
  type: ActionTypes.COMMON_SET_SUB_AREA_OF_WORK,
});

export const actionSetOrgWiseRegion = (region: []) => ({
  payload: {
    data: region,
  },
  type: ActionTypes.COMMON_SET_ORG_WISE_REGION,
});

export const actionSetOrgWiseCountry = (country: []) => ({
  payload: {
    data: country,
  },
  type: ActionTypes.COMMON_SET_ORG_WISE_COUNTRY,
});

export const actionSetValidationStatusList = (validationStatusList: []) => ({
  payload: {
    data: validationStatusList,
  },
  type: ActionTypes.COMMON_SET_VALIDATION_STATUS_LIST,
});

export const actionSetUserAssignedProgramList = (data: []) => ({
  payload: {
    data: data,
  },
  type: ActionTypes.COMMON_SET_USER_ASSIGNED_PROGRAMS,
});