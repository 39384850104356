import {
    DeleteIconCustom,
    EditIconCustom,
    FullWidthContainer,
    VisibilityIconCustom
} from "../../../styles/common";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import {
    commonCellStyle,
    commonHeaderStyle,
} from "../../../styles/materialStyles";
import React, { useState } from "react";
import { ConfirmDialog } from "components/common/confirm-dialog.component";
import { actionSetEditableScreen } from "containers/assessment-create/actions";
import { useDispatch } from "react-redux";
import { actionDeleteAssessment } from "../actions";

export default function AssessmentTableComponent(props: any) {
    const {
        assessmentFilterData,
        isDraftTab,
        isEditAssessment,
        functionEditAssesmentDetailData,
        setIsEditAssessment,
        functionAssesmentLevelData,
        setCanShowOverview,
        setSelectedAssessmentRow,
        formTitle,
        setAssessmentStatusId
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteForm, setDeleteForm]: any = useState(null);

    const handlerSerialNumberRowData = (row: any) => {
        const serialNo = row["tableData"].id + 1;
        return serialNo;
    };

    const handlerDeleteRowData = (event: any, rowData: any) => {
        setOpenConfirmDialog(true);
        setDeleteForm({ id: rowData.id });
    };

    const handlerCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const deleteRowItem = () => {
        dispatch(actionDeleteAssessment(deleteForm));
        handlerCloseConfirmDialog();
    };

    const handlerEditRowData = (event: any, rowData: any) => {
        functionEditAssesmentDetailData(rowData);
        setIsEditAssessment(isEditAssessment);
        if (rowData)
            // set editable screen globally in redux state
            dispatch(actionSetEditableScreen(true));
    };

    const showAssessmentLevel = (rowData: any) => {
        setAssessmentStatusId(rowData.assessmentStatusId);
        functionAssesmentLevelData(rowData);
        setCanShowOverview(true);
        setSelectedAssessmentRow(rowData.id);
    };

    return (
        <FullWidthContainer>
            {assessmentFilterData && (
                <>
                    <MaterialTable
                        columns={[
                            {
                                title: t("common.number"),
                                field: "id",
                                filtering: false,
                                sorting: false,
                                render: (tblProps) => {
                                    let rowData = handlerSerialNumberRowData(tblProps);
                                    return <label> {rowData}</label>;
                                },
                                headerStyle: {
                                    textAlign: "center",
                                },
                                cellStyle: {
                                    ...commonCellStyle,
                                    textAlign: "center",
                                },
                            },
                            {
                                title: t("common.assessmentCode"),
                                field: "assessmentCode",
                                filtering: true,
                                cellStyle: commonCellStyle,
                            },
                            {
                                title: t("common.program"),
                                field: "supplyChain",
                                filtering: true,
                                cellStyle: commonCellStyle,
                            },
                            {
                                title: t("common.areaOfWork"),
                                field: "areaOfWork",
                                filtering: true,
                                cellStyle: commonCellStyle,
                            },
                            {
                                title: t("common.createdDate"),
                                field: "createdDate",
                                filtering: true,
                                cellStyle: commonCellStyle,
                                render: (tblProps) => {
                                    return <span>{tblProps.createdDate}</span>;
                                },
                            },
                            {
                                title: t("common.modifiedDate"),
                                field: "updatedDate",
                                filtering: true,
                                cellStyle: commonCellStyle,
                                render: (tblProps) => {
                                    return <span>{tblProps.updatedDate}</span>;
                                },
                            },
                            {
                                title: t("common.modifiedBy"),
                                field: "updatedByName",
                                filtering: true,
                                cellStyle: commonCellStyle,
                                render: (tblProps) => {
                                    return <span>{tblProps.updatedByName}</span>;
                                },
                            },
                        ]}
                        data={assessmentFilterData}
                        title={formTitle}
                        onRowClick={(event: any, rowData: any) => {
                            showAssessmentLevel(rowData);
                        }}
                        options={{
                            filtering: true,
                            pageSizeOptions: [5, 10, 20, 50],
                            pageSize: 5,
                            emptyRowsWhenPaging: false,
                            showTitle: false,
                            actionsColumnIndex: -1,
                            draggable: false,
                            search: false,
                            toolbar: false,
                            actionsCellStyle: commonCellStyle,
                            headerStyle: commonHeaderStyle,
                        }}
                        localization={{
                            header: {
                                actions: t("common.action"),
                            },
                            body: {
                                filterRow: {
                                    filterTooltip: t("pagination.filter"),
                                },
                                emptyDataSourceMessage: t("common.noDataAvailable"),
                            },
                            pagination: {
                                firstTooltip: t("pagination.firstPage"),
                                previousTooltip: t("pagination.prevPage"),
                                nextTooltip: t("pagination.nextPage"),
                                lastTooltip: t("pagination.lastPage"),
                                labelRowsSelect: t("common.rows"),
                            },
                        }}
                        actions={[
                            {
                                icon: () =>
                                    isEditAssessment ? (
                                        <EditIconCustom className="arRotate270" />
                                    ) : (
                                        <VisibilityIconCustom />
                                    ),
                                tooltip: isEditAssessment ? t("common.edit") : t("common.view"),
                                onClick: (event, rowData) => handlerEditRowData(event, rowData),
                            },
                            {
                                icon: () => <DeleteIconCustom />,
                                tooltip: t("common.delete"),
                                onClick: (event, rowData) =>
                                    handlerDeleteRowData(event, rowData),
                                hidden: !isDraftTab,
                            }
                        ]}
                    />
                </>
            )}
            <ConfirmDialog
                open={openConfirmDialog}
                close={handlerCloseConfirmDialog}
                title={t("indicators.areYouSureDelete")}
                content=""
                yes={t("common.yes")}
                no={t("common.cancel")}
                ok={deleteRowItem}
            />
        </FullWidthContainer>
    );
}
