import api from "../../utils/api";
import { GET_POSTS } from "./constants";

/**
 * Function for getting some data
 */
export const fetchData = () => {
  return api.get(`${GET_POSTS}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};
