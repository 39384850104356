import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { commonReducer } from "./common/reducers";
import { allRequestsReducer } from "../containers/all-requests/reducers";
import { masterFormReducer } from "../containers/master/reducers";
import { headerReducer } from "../containers/header/reducers";
import { questionReducer } from "../containers/question-set/reducers";
import { userProfileReducer } from "../containers/user-profile/reducers";
import { assesementCreateReducer } from "../containers/assessment-create/reducers";
import { adminRsaReducer } from "containers/admin-rsa-overview/reducers";
import { scsHubReducer } from "../scs-hub/reducers";
import { vcAssessmentReducer } from "containers/vc-assessment/reducer";

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    allRequests: allRequestsReducer,
    masterForm: masterFormReducer,
    header: headerReducer,
    question: questionReducer,
    userProfile: userProfileReducer,
    assesmentCreate: assesementCreateReducer,
    admin: adminRsaReducer,
    scsHub: scsHubReducer,
    vcAssessment: vcAssessmentReducer
    // rest of your reducers
  });
