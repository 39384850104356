import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import {
  fetchQuestionSetCode,
  fetchQuestionSetDataDetails,
  saveQuestionSetDataDetails,
  fetchQuestionByQuestionSetIdDetail,
  fetchQuestionByQuestionSetIdAndAreaOfWorkDetail,
  saveQuestionDataDetails,
  swipeQuestionSequenceDataDetails,
  deleteQuestionDataDetails,
  fetchQuestionByQuestionIdDetail,
  copyQuestionSetDataDetails,
  getQuestionAreaLevelCommentByIdDataDetail,
  updateQuestionAreaLevelCommentByIdDataDetail,
  deleteIndicatorSetTopic,
  getNonAssignedTopicList,
  saveNonAssignedTopicList,
  deleteIndicatorSet,
} from "./api";
import {
  actionFetchQuestionSetCodeSuccess,
  actionFetchQuestionSetDataDetailsSuccess,
  actionGetQuestionSetDataByIdDetailSuccess,
  actionSaveQuestionSetDataDetailSuccess,
  actionGetQuestionByQuestionSetIdDetailSuccess,
  actionGetQuestionByQuestionSetIdAndAreaOfWorkDetailSuccess,
  actionSaveQuestionDataDetailSuccess,
  actionDeleteQuestionDataDetailsSuccess,
  actionSwipeQuestionSequenceDataDetailSuccess,
  actionFetchQuestionSetDataDetails,
  actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail,
  actionGetQuestionByQuestionSetIdDetail,
  actionGetQuestionByQuestionIdDetailSuccess,
  actionSaveQuestionCompletedDetailSuccess,
  actionSaveQuestionSetDataDetail,
  actionCopyQuestionSetDataDetailSuccess,
  actionGetQuestionAreaLevelCommentByIdDataDetailSuccess,
  actionUpdateQuestionAreaLevelCommentByIdDetailSuccess,
  actionGetNonAssignedTopicsSuccess,
  actionGetNonAssignedTopics,
} from "./actions";
import { ActionTypes } from "./constants";
import {
  setLoaderVisibility,
  openToaster,
  closeToaster,
} from "../../store/common/actions";
import {
  API_ERROR_MESSAGE,
  DATA_SAVE_SUCCESS,
  DATA_DELETE_SUCCESS,
  DATA_UPDATE_SUCCESS,
} from "../../store/common/message";
import { COMMON_CONSTANT } from "constants/data";

function* watchQuestionSetCode() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_QUESTION_SET_CODE_DATA,
    getQuestionSetCode
  );
}

function* getQuestionSetCode(action: any) {
  try {
    const response = yield call(fetchQuestionSetCode);
    const responseData = response.data;
    yield put(actionFetchQuestionSetCodeSuccess(responseData));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchQuestionSetDataDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_QUESTION_SET_DATA,
    getQuestionSetDataDetail
  );
}

function* getQuestionSetDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchQuestionSetDataDetails,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchQuestionSetDataDetailsSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchQuestionSetDataByIdDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_QUESTION_SET_BY_ID_DATA,
    getQuestionSetDataByIdDetail
  );
}

function* getQuestionSetDataByIdDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchQuestionSetDataDetails,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionGetQuestionSetDataByIdDetailSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchSaveQuestionSetDataDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_SAVE_QUESTION_SET_DATA,
    saveQuestionSetdDataDetail
  );
}

function* saveQuestionSetdDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveQuestionSetDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionSaveQuestionSetDataDetailSuccess(responseData));
    yield put(actionFetchQuestionSetDataDetails(-1));
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchCopyQuestionSetDataDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_COPY_QUESTION_SET_DATA,
    copyQuestionSetdDataDetail
  );
}

function* copyQuestionSetdDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      copyQuestionSetDataDetails,
      action.payload.formData
    );
    let copyDataSuccess = false;
    if (response.data === "") {
      copyDataSuccess = true;
      yield put(actionFetchQuestionSetDataDetailsSuccess([]));
    }
    yield put(actionCopyQuestionSetDataDetailSuccess(copyDataSuccess));
    yield put(actionFetchQuestionSetDataDetails("-1"));
    yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchDeleteIndicatorSetData() {
  yield takeLatest(
    ActionTypes.QUESTION_DELETE_QUESTION_SET_DATA,
    deleteIndicatorSetData
  );
}

function* deleteIndicatorSetData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      deleteIndicatorSet,
      action.payload.data
    );
    if (response) {
      yield put(openToaster(DATA_DELETE_SUCCESS(), true));
      yield put(actionFetchQuestionSetDataDetails("-1"));
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchQuestionSetDataCompletedBySetIdSaveDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA,
    getQuestionSetDataCompletedBySetIdSaveDetail
  );
}

function* getQuestionSetDataCompletedBySetIdSaveDetail(action: any) {
  try {
    let formData: any = {};
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchQuestionSetDataDetails,
      action.payload.formData.questionSetId
    );
    const responseData = response.data;
    if (responseData && responseData.length > 0) {
      formData = {
        id: responseData[0].id,
        statusId: action.payload.formData.statusId,
        name: responseData[0].name,
        code: responseData[0].code,
        supplyChainId: responseData[0].supplyChainId,
        scopeId: responseData[0].scopeId,
        updatedBy: action.payload.formData.userId,
      };
    }
    yield put(actionSaveQuestionSetDataDetail(formData));
    yield put(actionSaveQuestionCompletedDetailSuccess(responseData));

    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watcGetQuestionByQuestionSetIdDataDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA,
    getQuestionByQuestionSetIdDataDetails
  );
}

function* getQuestionByQuestionSetIdDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchQuestionByQuestionSetIdDetail,
      action.payload.questionSetId
    );
    const responseData = response.data;
    yield put(actionGetQuestionByQuestionSetIdDetailSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watcGetQuestionByQuestionSetIdAndAreaOfWorkDataDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA,
    getQuestionByQuestionSetIdAndAreaOfWorkDataDetails
  );
}

function* getQuestionByQuestionSetIdAndAreaOfWorkDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchQuestionByQuestionSetIdAndAreaOfWorkDetail,
      action.payload.data
    );
    const responseData = response.data;
    yield put(
      actionGetQuestionByQuestionSetIdAndAreaOfWorkDetailSuccess(responseData)
    );
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchQuestionSaveDataDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_SAVE_QUESTION_DATA,
    saveQuestionDataDetail
  );
}

function* saveQuestionDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveQuestionDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionSaveQuestionDataDetailSuccess(responseData));
    yield put(
      actionGetQuestionByQuestionSetIdDetail(
        action.payload.formData.questionSetId
      )
    );
    yield put(
      actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(
        action.payload.formData.questionSetTopicId
      )
    );
    yield put(actionFetchQuestionSetDataDetails(-1));
    yield put(setLoaderVisibility(false));
    if (
      action.payload.formData.formId !== undefined &&
      action.payload.formData.formId > 0
    ) {
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    } else {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}
function* watchQuestionSequenceSwipeDataDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_SWIPE_QUESTION_SEQUENCE_DATA,
    questionSequenceSwipeDataDetails
  );
}

function* questionSequenceSwipeDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      swipeQuestionSequenceDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionSwipeQuestionSequenceDataDetailSuccess(responseData));
    yield put(
      actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(
        action.payload.formData.questionSetId
      )
    );
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}
function* watchDeleteQuestionData() {
  yield takeLatest(
    ActionTypes.QUESTION_DELETE_QUESTION_DATA,
    deleteQuestionData
  );
}

function* deleteQuestionData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      deleteQuestionDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionDeleteQuestionDataDetailsSuccess(responseData));
    yield put(actionGetQuestionByQuestionSetIdDetail(action.payload.formData.questionSetId));
    yield put(actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(action.payload.formData.currentIndicatorTopicId));
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_DELETE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchQuestionByQuestionIdDataDetail() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA,
    getQuestionByQuestionIdData
  );
}

function* getQuestionByQuestionIdData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    let responseData = [];
    if (action.payload.questionId !== "-1") {
      const response = yield call(
        fetchQuestionByQuestionIdDetail,
        action.payload.questionId
      );
      responseData = response.data;
    } else {
      responseData = [];
    }
    yield put(actionGetQuestionByQuestionIdDetailSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchGetQuestionAreaLevelCommentByIdDataByIdDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA,
    getQuestionAreaLevelCommentByIdDetails
  );
}

function* getQuestionAreaLevelCommentByIdDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      getQuestionAreaLevelCommentByIdDataDetail,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(
      actionGetQuestionAreaLevelCommentByIdDataDetailSuccess(responseData)
    );
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchUpdateQuestionAreaLevelCommentByIdDataDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA,
    updateQuestionAreaLevelCommentByIdDataDetails
  );
}

function* updateQuestionAreaLevelCommentByIdDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateQuestionAreaLevelCommentByIdDataDetail,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(
      actionUpdateQuestionAreaLevelCommentByIdDetailSuccess(responseData)
    );
    yield put(
      actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(
        action.payload.formData.questionSetTopicId
      )
    );
    yield put(
      actionGetQuestionByQuestionSetIdDetail(
        action.payload.formData.questionSetId
      )
    );
    yield put(actionFetchQuestionSetDataDetails(-1));
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchDeleteQuestionSetTopicData() {
  yield takeLatest(
    ActionTypes.QUESTION_DELETE_INDICATOR_SET_TOPIC,
    deleteQuestionSetTopicData
  );
}

function* deleteQuestionSetTopicData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(deleteIndicatorSetTopic, action.payload.form.id);
    if (response.data.isSuccess) {
      yield put(setLoaderVisibility(false));
      yield put(openToaster(DATA_DELETE_SUCCESS(), true));
      yield put(actionGetQuestionByQuestionSetIdDetail(action.payload.form.questionSetId));
      yield put(actionGetNonAssignedTopics(action.payload.form.questionSetId));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchGetNonAssignedTopicListDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_GET_NON_ASSIGNED_TOPIC,
    getNonAssignedTopicListDetails
  );
}

function* getNonAssignedTopicListDetails(action: any) {
  try {
    const response = yield call(getNonAssignedTopicList, action.payload.data);
    const responseData = response.data;
    yield put(actionGetNonAssignedTopicsSuccess(responseData));
  } catch (err) {
    console.log(err);
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

function* watchPostNonAssignedTopicListDetails() {
  yield takeLatest(
    ActionTypes.QUESTION_SAVE_NON_ASSIGNED_TOPIC,
    postNonAssignedTopicListDetails
  );
}

function* postNonAssignedTopicListDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(saveNonAssignedTopicList, action.payload.data);
    if (response.data.isSuccess) {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      yield put(actionGetQuestionByQuestionSetIdDetail(action.payload.data.questionSetId));
      yield put(actionGetNonAssignedTopics(action.payload.data.questionSetId))
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  }
}

export default function* questionSaga() {
  yield all([
    watchQuestionSetCode(),
    watchQuestionSetDataDetail(),
    watchQuestionSetDataByIdDetail(),
    watchSaveQuestionSetDataDetail(),
    watcGetQuestionByQuestionSetIdDataDetails(),
    watcGetQuestionByQuestionSetIdAndAreaOfWorkDataDetails(),
    watchQuestionSaveDataDetails(),
    watchQuestionSequenceSwipeDataDetails(),
    watchDeleteQuestionData(),
    watchQuestionByQuestionIdDataDetail(),
    watchQuestionSetDataCompletedBySetIdSaveDetail(),
    watchCopyQuestionSetDataDetail(),
    watchGetQuestionAreaLevelCommentByIdDataByIdDetails(),
    watchUpdateQuestionAreaLevelCommentByIdDataDetails(),
    watchDeleteQuestionSetTopicData(),
    watchGetNonAssignedTopicListDetails(),
    watchPostNonAssignedTopicListDetails(),
    watchDeleteIndicatorSetData()
  ]);
}
