import api from "../../utils/api";
import { DELETE_ASSESSMENT, GET_EXPORTED_ASSESSMENT_DATA, GET_QUESTION_SET_AREA_OF_WORK, UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS } from "./constants";


/**
 * Function for getting some data
 */
export const getQuestionSetAreaOfWorks = async () => {
    const response = await api.get(`${GET_QUESTION_SET_AREA_OF_WORK}`);
    return response;
};

export const updateAssesmentValidationStatusData = (assesmentData: any) => {
    return api
        .post(`${UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS}`, assesmentData)
        .then(
            (resp: any) => {
                return resp;
            },
            (err: any) => {
                console.log(err);
                return err;
            }
        );
};

export const getExportedAssessmentData = (assessmentId: any) => {
    return api
        .get(`${GET_EXPORTED_ASSESSMENT_DATA}?assessmentId=${assessmentId}`, { responseType: "blob" })
        .then(
            (resp: any) => {
                return resp;
            },
            (err: any) => {
                console.log(err);
                return err;
            }
        );
};

export const deleteAssesmentById = async (data: any) => {
    const response = await api.delete(`${DELETE_ASSESSMENT}`, { data: JSON.stringify(data) });
    return response;
};