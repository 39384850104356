import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import UserProfileTableComponent from "../user-profile-table";
import {
  ScmmAppBar,
  ActiveStatus,
  InActiveStatus,
  ARStatus,
  UserProfileTab,
} from "styles/common";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import UserRequestedTableComponent from "../user-requested-table";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="nopadding">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserProfileTabComponent(props: any) {
  const { useProfileData, functionEditForm, scsHubAccessRequestedList } = props;
  const { t } = useTranslation();

  const [tabValue, setTabValue] = React.useState(0);
  const [allUserData, setAllUserData] = useState([]);
  const [lableAllUserData, setLableAllUserData]: any = useState(0);
  const [accessReqUserData, setAccessReqUserData] = useState([]);
  const [lableAccessReqUserData, setLableAccessReqUserData]: any = useState(0);
  const [activeUserData, setActiveUserData] = useState([]);
  const [lableActiveUserData, setLableActiveUserData]: any = useState(0);
  const [inActiveUserData, setInActiveUserData] = useState([]);
  const [lableInActiveUserData, setLableInActiveUserData]: any = useState(0);

  useEffect(() => {
    if (useProfileData && useProfileData.length) {

      setAllUserData(useProfileData);
      setLableAllUserData(`(${useProfileData.length})`);
      const accessRequestedData = useProfileData.filter(
        (up: any) => up.countryUsers.length === 0 && up.isDeleted === false
      );
      setAccessReqUserData(accessRequestedData);
      setLableAccessReqUserData(`(${accessRequestedData.length})`);

      const activeUserdData = useProfileData.filter(
        (up: any) => up.isDeleted === false && up.countryUsers.length > 0
      );
      setActiveUserData(activeUserdData);
      setLableActiveUserData(`(${activeUserdData.length})`);

      const inActiveUserdData = useProfileData.filter(
        (up: any) => up.isDeleted === true
      );
      setInActiveUserData(inActiveUserdData);
      setLableInActiveUserData(`(${inActiveUserdData.length})`);
    }
  }, [useProfileData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <UserProfileTab>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <ScmmAppBar position="static">
              <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs">
                <Tab
                  label={`${t("userProfile.allUser")} ${lableAllUserData}`}
                  {...a11yProps(0)}
                />
                <Tab
                  label={`${t(
                    "userProfile.pendingForRoleAssignement"
                  )} ${lableAccessReqUserData}`}
                  {...a11yProps(1)}
                />
                <Tab
                  label={`${t("common.active")} ${lableActiveUserData}`}
                  {...a11yProps(2)}
                />
                <Tab
                  label={`${t("common.inactive")} ${lableInActiveUserData}`}
                  {...a11yProps(3)}
                />
                <Tab
                  label={`${t("common.scsAccessRequested")} (${scsHubAccessRequestedList.length})`}
                  {...a11yProps(4)}
                />
              </Tabs>
            </ScmmAppBar>
          </Grid>
          <Grid item>
            <Grid
              container
              alignItems="center"
              justify="flex-end"
              spacing={2}
              className="paddingR20"
            >
              <Grid item>
                <Tooltip title={`${t("common.active")}`}>
                  <ActiveStatus />
                </Tooltip>
                {" "}
                <Typography component="span" className="marginL10 font-size-14">
                  {t("common.active")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={`${t("common.inactive")}`}>
                  <InActiveStatus />
                </Tooltip>
                {" "}
                <Typography component="span" className="marginL10 font-size-14">
                  {t("common.inactive")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={`${t("common.accessRequired")}`}>
                  <ARStatus />
                </Tooltip>
                {" "}
                <Typography component="span" className="marginL10 font-size-14">
                  {t("common.accessRequired")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </UserProfileTab>
      <TabPanel value={tabValue} index={0}>
        <UserProfileTableComponent
          useProfileFilterData={allUserData}
          functionEditForm={functionEditForm}
        ></UserProfileTableComponent>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UserProfileTableComponent
          useProfileFilterData={accessReqUserData}
          functionEditForm={functionEditForm}
        ></UserProfileTableComponent>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <UserProfileTableComponent
          useProfileFilterData={activeUserData}
          functionEditForm={functionEditForm}
        ></UserProfileTableComponent>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <UserProfileTableComponent
          useProfileFilterData={inActiveUserData}
          functionEditForm={functionEditForm}
        ></UserProfileTableComponent>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <UserRequestedTableComponent
          scsHubAccessRequestedList={scsHubAccessRequestedList}
          functionEditForm={functionEditForm}
        ></UserRequestedTableComponent>
      </TabPanel>
    </>
  );
}
