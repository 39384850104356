import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { HOME_PATH } from "../../../constants/api";
import MasterMenuDrawerComponent from "../master-menu";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { history } from "../../../store/config";
import { getBuildDate } from "../../../utils/data";
import packageJson from "../../../../package.json";

function DrawerComponent(props: any) {
  const { setVisibility, visible } = props;
  const [masterMenuOpen, setMasterMenuOpen] = useState(false);
  const masterMenuData = useSelector(
    (state: any) => state.header.masterMenuData
  );
  const closeDrawer = () => {
    setVisibility(false);
  };
  const handleClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setMasterMenuOpen(!masterMenuOpen);
  };
  const goToPath = (path: any) => (event: any) => {
    history.push(path);
  };

  return (
    <Drawer open={visible} onClose={closeDrawer}>
      <div
        className="drawer-container"
        tabIndex={0}
        role="button"
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
      >
        <List>
          <ListItem button={false}>
            <Typography variant="h5" color="inherit" noWrap={true}>
              SCMS
            </Typography>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={goToPath(HOME_PATH)}>
            <ListItemText primary="Home" />
          </ListItem>
          {/* This menu is not required for SCMS project */}
          {/* <ListItem button={true} onClick={goToPath(PRE_LOGIN)}>
            <ListItemText primary="About PROJECT" />
          </ListItem> 
          <ListItem button={true} onClick={goToPath(ALL_REQUESTS)}>
            <ListItemText primary="All Requests" />
          </ListItem>
          <ListItem button={true} onClick={goToPath(NEW_REQUEST)}>
            <ListItemText primary="New Request" />
          </ListItem> */}
          <ListItem button={true} onClick={handleClick}>
            <ListItemText primary="Master Entry" />
            {masterMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={masterMenuOpen}>
            <MasterMenuDrawerComponent masterMenuData={masterMenuData} />
          </Collapse>
        </List>
        <p style={{ paddingLeft: "10px", fontSize: "14px" }}>
          Build date: {getBuildDate(packageJson.buildDate)}
        </p>
      </div>
    </Drawer>
  );
}

export default DrawerComponent;
