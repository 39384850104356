import React from "react";
import { FullWidthContainer } from "../../../styles/common";
import QuestionTabsComponent from "../question-tab";

function QuestionTableComponent(props: any) {
  const {
    questionBySetIdAreaOfWorkData,
    questionBySetIdAreaOfWorkTabData,
    questionSetGenCode,
    questionSetId,
    areaOfWorkId,
    isHistoryMode,
    closeToasterFun,
    userId,
    supplyChainName,
    scopeName,
    questionSetAreaOfWork,
    areaOfWork,
    currentIndicatorTopicId,
    topicName,
    aowName,
    subAOWName
  } = props;

  return (
    <>
      <FullWidthContainer>
        {questionBySetIdAreaOfWorkData && (
          <QuestionTabsComponent
            questionBySetIdAreaOfWorkData={questionBySetIdAreaOfWorkData}
            questionBySetIdAreaOfWorkTabData={questionBySetIdAreaOfWorkTabData}
            questionSetId={questionSetId}
            questionSetGenCode={questionSetGenCode}
            areaOfWorkId={areaOfWorkId}
            isHistoryMode={isHistoryMode}
            closeToasterFun={closeToasterFun}
            userId={userId}
            supplyChainName={supplyChainName}
            scopeName={scopeName}
            questionSetAreaOfWork={questionSetAreaOfWork}
            areaOfWork={areaOfWork}
            currentIndicatorTopicId={currentIndicatorTopicId}
            topicName={topicName}
            aowName={aowName}
            subAOWName={subAOWName}
          ></QuestionTabsComponent>
        )}
      </FullWidthContainer>
    </>
  );
}

export default QuestionTableComponent;
