import React from "react";
import Chart from "react-google-charts";
import { AssessmentColorList } from "../../constants/data";

function ColumnChartComponent(props: any) {
    const { keyCount, chartData, yAxisScaleLength } = props;
    let yAxisArray: any = [];
    for (let x = 0; x <= yAxisScaleLength; x++) {
        yAxisArray.push(x);
    }
    return (
        <Chart
            key={keyCount}
            width={"100%"}
            height={"400px"}
            chartType="ColumnChart"
            data={chartData}

            options={{
                chart: {
                    title: "Trend Analysis",
                },
                hAxis: {
                    textStyle: {
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: 10
                    },
                },
                vAxis: {

                    textStyle: {
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: 10
                    },
                    minValue: 0,
                    ticks: yAxisArray,
                },
                legend: {
                    textStyle: {
                        fontSize: 10,
                        fontWeight: 'bold',
                    },
                    position: "bottom",
                },
                tooltip: { isHtml: true, trigger: "selection", textStyle: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: 12, } },
                annotations: {
                    position: "top",
                    textStyle: {
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        color: "#000",
                        fontSize: 10,
                    },
                    alwaysOutside: true,
                },

                colors: AssessmentColorList,
            }}

            rootProps={{ "data-testid": "2" }}

        />
    )

}
export default ColumnChartComponent