import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actionHeaderSetOrganization,
  actionHeaderUserDropDownShow,
} from "../actions";
import { StyledSelect } from "../styles";
import MenuItem from "@material-ui/core/MenuItem";
import {
  actionSaveRegisterUserSelectedRoleData,
  actionUpdateUserSetting,
} from "containers/user-profile/actions";

function OrganizationSelectionComponent(props: any) {
  const { selectedRole, userId } = props;
  const dispatch = useDispatch();
  const allOrganization: any = (window as any).allOrganization;
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const listUnicefRegion = useSelector(
    (state: any) => state.common.listUnicefRegion
  );
  const listUnicefCountry = useSelector(
    (state: any) => state.common.listUnicefCountry
  );
  const listWhoRegion = useSelector((state: any) => state.common.listWhoRegion);
  const listWhoCountry = useSelector(
    (state: any) => state.common.listWhoCountry
  );
  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );
  const roleData = useSelector((state: any) => state.common.roleData);
  const [roleId, setRoleId] = useState("");
  let regionArr: any = [];
  let countryArr: any = [];
  useEffect(() => {
    if (roleData.length) {
      const tempRoleData: any[] = roleData.filter(
        (roleE: any) =>
          roleE.name.toLowerCase().trim() === selectedRole.toLowerCase()
      );
      setRoleId(tempRoleData[0].id);
    }
  }, [roleData]);

  const handleChangeOrganization = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    if (
      event.target.value.toLowerCase() === allOrganization[1].id.toLowerCase()
    ) {
      getOrgWiseRegionCountryArrayData(listWhoRegion, listWhoCountry);
    } else {
      getOrgWiseRegionCountryArrayData(listUnicefRegion, listUnicefCountry);
    }
    const userSelectedData = {
      userSelectedRole: roleId,
      userSelectedRegion: regionArr,
      userSelectedCountry: countryArr,
      organization: event.target.value,
      userSelectedApp: selectedAppDetails.id || null
    };
    const selectedObj = {
      userSelectedData: userSelectedData
    };
    const shareSetting = {
      id: userId,
      UserSettings: JSON.stringify(userSelectedData),
    };
    localStorage.setItem("userSelectedData", JSON.stringify(userSelectedData));
    dispatch(actionHeaderSetOrganization(event.target.value));
    dispatch(actionSaveRegisterUserSelectedRoleData(selectedObj));
    dispatch(actionUpdateUserSetting(shareSetting));
    dispatch(actionHeaderUserDropDownShow(false));
  };

  const getOrgWiseRegionCountryArrayData = (Region: any, Country: any) => {
    if (Array.isArray(Region)) {
      Region.map((region: any) => {
        regionArr.push(region.id);
      });
    } else {
      regionArr.push(Region);
    }
    if (Array.isArray(Country)) {
      Country.map((country: any) => {
        countryArr.push(country.id);
      });
    } else {
      countryArr.push(Country);
    }
  };
  return (
    <>
      <StyledSelect
        value={organizationSelected}
        onChange={handleChangeOrganization}
      >
        {allOrganization &&
          allOrganization.length &&
          allOrganization.map((organization: any) => {
            return (
              <MenuItem
                key={organization.id}
                value={organization.id}
                className="LanguageSelect font-size-14"
              >
                {organization.label}
              </MenuItem>
            );
          })}
      </StyledSelect>
    </>
  );
}

export default OrganizationSelectionComponent;
