import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import UserDropdown from "./user-dropdown";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HOME_PATH_AFTER_LOGIN, HOME_PATH, ERROR_PATH } from "constants/api";
import OutsideAlerter from "./click-outside";
import { actionHeaderUserDropDownShow } from "./actions";
import { USER } from "constants/data";

function Account(props: any) {
  const {
    loggedInUser,
    logout,
    selectedRole,
    selectedRegion,
    selectedCountry,
    selectedPrograms,
    userId,
  } = props;
  const dispatch = useDispatch();
  const [showUserDropdownVisibility, setUserDropdownVisibility] = useState(
    false
  );
  const currentRoute = window.location.pathname;
  const isHomePath = currentRoute.includes("/home");
  const isUserDropDownShow = useSelector(
    (state: any) => state.header.isUserDropDownShow
  );
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const iconButtonClick = () => {
    dispatch(actionHeaderUserDropDownShow(showUserDropdownVisibility));
  };
  useEffect(() => {
    if (!isUserDropDownShow) {
      setUserDropdownVisibility(false);
    } else {
      setUserDropdownVisibility(true);
    }
  }, [isUserDropDownShow]);
  return (
    <Grid
      container
      justify="flex-end"
      className={`${currentRoute === HOME_PATH_AFTER_LOGIN ||
        currentRoute === HOME_PATH || currentRoute === ERROR_PATH ||
        isHomePath
        ? "position-absolute"
        : ""
        }`}
    >
      <Grid item>
        <Typography
          component="div"
          className="user-name-block"
          onClick={iconButtonClick}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar>
                {loggedInUser.name
                  ? loggedInUser.name.substring(0, 1).toUpperCase()
                  : ""}
                {loggedInUser.lastName
                  ? loggedInUser.lastName.substring(0, 1).toUpperCase()
                  : ""}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography component="span" className="un-username">
                {`${loggedInUser.name} ${loggedInUser.lastName}`}
              </Typography>
              {selectedRole !== "" && (
                <Typography component="span" className="un-RRC">
                  {`${selectedRole} ${selectedRole === USER.ROLE.SD_ADMIN ||
                    selectedRole === USER.ROLE.GLOBAL_ADVISOR
                    ? `| ${organizationSelected}`
                    : selectedRole === USER.ROLE.VC_ASSESSOR
                      ? ``
                      : `| ${selectedRegion} ${selectedCountry ? `-` : ``} ${selectedCountry}`}`}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <ExpandMoreIcon />
            </Grid>
          </Grid>
        </Typography>

        {showUserDropdownVisibility ? (
          <OutsideAlerter>
            <UserDropdown
              loggedInUser={loggedInUser}
              logout={logout}
              selectedRole={selectedRole}
              selectedRegion={selectedRegion}
              selectedCountry={selectedCountry}
              selectedPrograms={selectedPrograms}
              userId={userId}
            />
          </OutsideAlerter>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default Account;
