import { ASSESSMENT } from "constants/data";

// filter function
export const getFilteredAOWData = (initialData: any, subAowData: any, topicData: any) => {
  let tempAowData: any = { ...initialData };
  const subAowId = subAowData.subAreaOfWorkId ? subAowData.subAreaOfWorkId : null;
  const topicId = topicData.topicId ? topicData.topicId : null;

  if (subAowId) {
    const levelsData = tempAowData.levels.map((data: any) => {
      let subAreaOfWorkDataClone = data.subAreaOfWorks.slice();
      const subAreaOfWorkData = subAreaOfWorkDataClone.filter(
        (sub: any) => sub.subAreaOfWorkId === subAowId
      );
      return { ...data, subAreaOfWorks: subAreaOfWorkData };
    });
    tempAowData = { ...tempAowData, levels: [...levelsData] };
  }

  if (topicId) {
    const levelsData = tempAowData.levels.map((data: any) => {
      //sub area of work
      const newData = data.subAreaOfWorks.map((sub: any) => {
        //questions
        const questions = sub.questionDetails.filter(
          (q: any) => q.topicId === topicId
        );
        return { ...sub, questionDetails: [...questions] };
      }).filter(
        (sub: any) => sub.questionDetails.length > 0
      );
      return { ...data, subAreaOfWorks: [...newData] };
    })
    tempAowData = { ...tempAowData, levels: [...levelsData] };
  }
  return tempAowData;
};


export const getValidationStatusFlag = (aowData: any) => {
  let tempAowData: any = { ...aowData };
  let isDisaggreed: Boolean = false;
  tempAowData.levels.some((level: any) => {
    level.subAreaOfWorks.some((subAow: any) => {
      subAow.questionDetails.some((que: any) => {
        if (que.otherLevelValidationStatusId === ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE) {
          isDisaggreed = true
        }
      })
    })
  })
  return isDisaggreed;
}
