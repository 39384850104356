import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

import { ArrowBackIosIconCustom, BigContainer, BoxShadowWrapper, CustomDevider, DialogButtonDefault, DialogButtonPrimary, SubHeaderWrapper, UserContainer } from "../../../../styles/common";
import { FormContext, useForm } from "react-hook-form";
import FormInput from "components/form-controls/input";
import { actionSaveMasterAreaOfWorkToolDetail, actionSaveMasterAreaOfWorkToolDetailSuccess, actionUpdateMasterAreaOfWorkToolData, actionUpdateMasterAreaOfWorkToolDataSuccess } from "containers/master/actions";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { LANGUAGE } from "constants/data";
import { IconButton, Typography } from "@material-ui/core";
import { testInputValueWithRegex } from "utils/security";
import FormSelect from "components/form-controls/select";

function AreaOfWorkFormComponent(props: any) {
  const { userId, showHideGridAndForm, IsEdit, currentItem, setCurrentItem, areaOfWorklist, subAreaOfWorklist, applicationsList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validateSchema, setValidateSchema]: any = useState({});
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors, setValue } = methods;
  const editAreaOfWorkTool = useSelector((state: any) => state.masterForm.masterAreaOfWorkToolSingleData);
  const areaOfWorkToolDataSave = useSelector((state: any) => state.masterForm.areaOfWorkToolDataSave);
  const isMasterAreaOfWorkToolUpdate = useSelector((state: any) => state.masterForm.isMasterAreaOfWorkToolUpdate);
  const [show, setShow] = useState(true);
  const [currentAOW, setCurrentAOW] = useState("");
  const [currentSubAOW, setCurrentSubAOW] = useState("");
  const [currentAOWId, setCurrentAOWId] = useState("");
  const [currentSubAOWId, setCurrentSubAOWId] = useState("");

  let list: any = applicationsList.filter((app: any) => app.isActive);
  list.unshift({ id: '-1', aliaseName: "All" });

  let listOfAreaOfWorkData: any = [];

  const showHideButton = (request: boolean) => {
    setShow(!request);
  };

  useEffect(() => {
    if (editAreaOfWorkTool && IsEdit == true) {
      showHideButton(true);
      editAreaOfWorkTool.map((item: any) => {
        setValue("link", editAreaOfWorkTool[0].link);
        setValue("applicationId", editAreaOfWorkTool[0].applicationId || "-1");
        setValue("id", editAreaOfWorkTool[0].id);
        setValue("toolLanguageId", editAreaOfWorkTool[0].toolLanguageId);
        setValue("en_name", editAreaOfWorkTool[0].name);
        setValue("en_description", editAreaOfWorkTool[0].description);
        setValue("fr_name", editAreaOfWorkTool[1].name);
        setValue("fr_description", editAreaOfWorkTool[1].description);
        setValue("sp_name", editAreaOfWorkTool[2].name);
        setValue("sp_description", editAreaOfWorkTool[2].description);
        setValue("ru_name", editAreaOfWorkTool[3].name);
        setValue("ru_description", editAreaOfWorkTool[3].description);
        setValue("ar_name", editAreaOfWorkTool[4].name);
        setValue("ar_description", editAreaOfWorkTool[4].description);
      });
    }
  }, [editAreaOfWorkTool]);

  useEffect(() => {
    showHideButton(false)
    setValidateSchema(
      yup.object().shape({
        en_name: yup.string().trim().required(t("validationMessage.toolMsg"))
          .max(50, t("validationMessage.max50Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        fr_name: yup.string().trim().required(t("validationMessage.toolMsg"))
          .max(50, t("validationMessage.max50Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        sp_name: yup.string().trim().required(t("validationMessage.toolMsg"))
          .max(50, t("validationMessage.max50Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ru_name: yup.string().trim().required(t("validationMessage.toolMsg"))
          .max(50, t("validationMessage.max50Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ar_name: yup.string().trim().required(t("validationMessage.toolMsg"))
          .max(50, t("validationMessage.max50Validation"))
          // .matches(/^[aA-zZ0-9\s]+$/, t("validationMessage.alphaNumericValidation"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        link: yup.string().trim().required(t("validationMessage.link"))
          .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        applicationId: yup.string().trim().required(t("validationMessage.applicationMsg")),
        en_description: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        fr_description: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        sp_description: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ru_description: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
        ar_description: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
      })
    );
  }, []);

  useEffect(() => {
    if (areaOfWorklist.length && subAreaOfWorklist.length) {
      const filteredCurrentAOW = areaOfWorklist.filter((item: any) => parseInt(currentItem.areaOfWorkId) === parseInt(item.id));
      const filteredCurrentSubAOW = subAreaOfWorklist.filter((item: any) => parseInt(currentItem.subAreaOfWorkId) === parseInt(item.id));
      if (filteredCurrentAOW.length) {
        setCurrentAOW(filteredCurrentAOW[0].label);
        setCurrentAOWId(filteredCurrentAOW[0].id);
      }
      if (filteredCurrentSubAOW.length) {
        setCurrentSubAOW(filteredCurrentSubAOW[0].label);
        setCurrentSubAOWId(filteredCurrentSubAOW[0].id);
      }
    }
  }, [currentItem, areaOfWorklist, subAreaOfWorklist]);

  useEffect(() => {
    if (areaOfWorkToolDataSave) {
      setCurrentItem({});
      showHideGridAndForm(false);
      dispatch(actionSaveMasterAreaOfWorkToolDetailSuccess(false));
    }
  }, [areaOfWorkToolDataSave])

  useEffect(() => {
    if (isMasterAreaOfWorkToolUpdate) {
      setCurrentItem({});
      showHideGridAndForm(false);
      dispatch(actionUpdateMasterAreaOfWorkToolDataSuccess(false));
    }
  }, [isMasterAreaOfWorkToolUpdate])


  const onSubmit = (data: any) => {
    listOfAreaOfWorkData.push(setformObj(data, data.en_name, data.en_description, LANGUAGE.ENGLISH))
    listOfAreaOfWorkData.push(setformObj(data, data.fr_name, data.fr_description, LANGUAGE.FRENCH))
    listOfAreaOfWorkData.push(setformObj(data, data.sp_name, data.sp_description, LANGUAGE.SPANISH))
    listOfAreaOfWorkData.push(setformObj(data, data.ru_name, data.ru_description, LANGUAGE.RUSSIAN))
    listOfAreaOfWorkData.push(setformObj(data, data.ar_name, data.ar_description, LANGUAGE.ARABIC))
    if (data.toolLanguageId != "") {
      dispatch(actionUpdateMasterAreaOfWorkToolData(listOfAreaOfWorkData));
    } else {
      dispatch(actionSaveMasterAreaOfWorkToolDetail(listOfAreaOfWorkData));
    }
  };

  const setformObj = (data: any, name: string, description: string, language: string) => {
    const formdata: any = {
      id: data.id,
      toolLanguageId: data.toolLanguageId,
      link: data.link,
      isDeleted: false,
      updatedBy: userId,
      createdBy: userId,
      name: name.trim(),
      description: description.trim(),
      language: language,
      areaOfWorkId: currentAOWId,
      subAreaOfWorkId: currentSubAOWId,
      applicationId: data.applicationId === "-1" ? null : data.applicationId
    };
    return formdata
  }

  const handleSectionChange = ([event]: any) => {
    setValue("applicationId", event.target.value)
    return event.target.value;
  }

  return (
    <>
      <SubHeaderWrapper>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton
                  className="padding10"
                  color="default"
                  onClick={() => showHideGridAndForm(false)}
                >
                  <ArrowBackIosIconCustom className="arRotate180" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  component="span"
                  className="common-label padding10-5"
                >
                  {show ? t("areaOfWorkTool.addTool") : t("areaOfWorkTool.editTool")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SubHeaderWrapper>
      <BoxShadowWrapper>
        <FormContext {...methods}>
          <BigContainer>
            <UserContainer>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography component={"strong"} className="strong-text">
                    {t("common.areaOfWork")} :
                  </Typography>
                  <Typography component={"span"}>
                    {currentAOW}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography component={"strong"} className="strong-text">
                    {t("common.subAreaOfWork")} :
                  </Typography>
                  <Typography component={"span"}>
                    {currentSubAOW}
                  </Typography>
                </Grid>
              </Grid>
            </UserContainer>
            <CustomDevider />
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormSelect
                    name="applicationId"
                    label={t("areaOfWorkTool.section")}
                    options={list}
                    optionsLabelKey="aliaseName"
                    hideselectoption={false}
                    onChange={handleSectionChange}
                    variant="standard"
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="link"
                    label={t("areaOfWorkTool.link")}
                    className="direction-ltr"
                    required={true}
                    errorobj={errors}
                  />
                  <FormInput
                    type="text"
                    name="id"
                    className="display-hidden"
                  />
                  <FormInput
                    type="text"
                    name="toolLanguageId"
                    className="display-hidden"
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <CustomDevider />
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span">{t("common.language")}: {t("language.english")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    type="text"
                    name="en_name"
                    label={t("areaOfWorkTool.toolName")}
                    required={true}
                    errorobj={errors}
                    className="direction-ltr"
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="en_description"
                    label={t("areaOfWorkTool.description")}
                    className="direction-ltr"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span">{t("common.language")}: {t("language.french")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    type="text"
                    name="fr_name"
                    label={t("areaOfWorkTool.toolName")}
                    required={true}
                    errorobj={errors}
                    className="direction-ltr"
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="fr_description"
                    label={t("areaOfWorkTool.description")}
                    className="direction-ltr"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span">{t("common.language")}: {t("language.spanish")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    type="text"
                    name="sp_name"
                    label={t("areaOfWorkTool.toolName")}
                    required={true}
                    errorobj={errors}
                    className="direction-ltr"
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="sp_description"
                    label={t("areaOfWorkTool.description")}
                    className="direction-ltr"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span">{t("common.language")}: {t("language.russian")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    type="text"
                    name="ru_name"
                    label={t("areaOfWorkTool.toolName")}
                    required={true}
                    errorobj={errors}
                    className="direction-ltr"
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="ru_description"
                    label={t("areaOfWorkTool.description")}
                    className="direction-ltr"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <UserContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="span">{t("common.language")}: {t("language.arabic")}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormInput
                    type="text"
                    name="ar_name"
                    label={t("areaOfWorkTool.toolName")}
                    required={true}
                    errorobj={errors}
                    className="direction-rtl"
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormInput
                    type="text"
                    name="ar_description"
                    label={t("areaOfWorkTool.description")}
                    className="direction-rtl"
                    errorobj={errors}
                  />
                </Grid>
              </Grid>
            </UserContainer>
          </BigContainer>
        </FormContext>
        <CustomDevider />
        <Grid container spacing={2} justify="flex-end" className="padding20">
          <Grid item>
            <DialogButtonDefault
              variant="contained"
              color="default"
              className="text-capitalize"
              onClick={() => showHideGridAndForm(false)}
            >
              {t("common.cancel")}
            </DialogButtonDefault>
          </Grid>
          <Grid item>
            {
              show ? (
                <DialogButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="text-capitalize"
                >
                  {t("common.add")}
                </DialogButtonPrimary>
              ) : (
                <DialogButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="text-capitalize"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("common.update")}
                </DialogButtonPrimary>
              )
            }
          </Grid>
        </Grid>
      </BoxShadowWrapper>
    </>
  );
}

export default AreaOfWorkFormComponent;
