import styled from "styled-components";
import Select from "@material-ui/core/Select";
import AppBar from "@material-ui/core/AppBar";

export const StyledSelect: any = styled(Select)`
  && {
    width: calc(100% - 32px);
    margin: 8px 16px;

    > div{
      font-size: 14px !important;
    }
  }
`;

export const UHeader = styled(AppBar)`
  && {
    background-color: #fff !important ;
    height: 64px;
    box-shadow: none;
  }
`;