import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";
import { DialogButtonDefault, DialogButtonPrimary, DialogTitleCustom } from "styles/common";
import { Grid } from "@material-ui/core";

export const ConfirmDialog = (props: any) => {
  const { title, open, close, ok, isContinue, content } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={close} disableBackdropClick={true} disableEscapeKeyDown={true} >
        <DialogTitleCustom id="alert-dialog-title">{title}</DialogTitleCustom>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="padding16-24">
          <Grid container alignItems="center" justify="flex-end" spacing={2}>
            <Grid item>
              <DialogButtonDefault variant="contained" color="default" onClick={close}>
                {isContinue? t("common.cancel") : t("common.no")}
              </DialogButtonDefault>
            </Grid>
            <Grid item>
              <DialogButtonPrimary variant="contained" color="primary" onClick={ok} autoFocus={true}>
                {isContinue? t("common.continue") : t("common.yes")}
              </DialogButtonPrimary>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
