import React from "react";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import { CustomTable, EditIconCustom } from "../../../styles/common";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Typography, Tooltip } from "@material-ui/core";
import "../index.css";
import { commonCellStyle, commonHeaderStyle, commonHeaderStyleDarker, } from "../../../styles/materialStyles";
import { ActiveStatus, InActiveStatus, ARStatus } from "styles/common";
import _ from "lodash";
import { USER } from "constants/data";

function UserProfileTableComponent(props: any) {
  const { functionEditForm, useProfileFilterData } = props;
  const { t } = useTranslation();
  let selectedRow: any = null;
  const handlerEditRowData = (event: any, rowData: any) => {
    functionEditForm(rowData);
  };

  const handlerSerialNumberRowData = (row: any) => {
    return row["tableData"].id + 1;
  };

  const renderTableData = (countryRolesMapping: any, rowData: any) => {
    const userApplications = rowData.userApplications.map((item: any) => {
      return item.name;
    });
    return (
      <>
        {
          countryRolesMapping.length > 0 ?
            countryRolesMapping.map((roleData: any, index: any) => {
              return (
                <Typography component="div" className="users-table-row" key={index}>
                  <Typography component="div" className="users-table-cell">
                    &nbsp;
                  </Typography>
                  <Typography component="div" className="users-table-cell arTextRight">
                    <Tooltip title={roleData.role}>
                      <Typography component="span">{roleData.role}</Typography>
                    </Tooltip>
                  </Typography>
                  <Typography component="div" className="users-table-cell arTextRight">
                    <Tooltip title={roleData.region}>
                      <Typography component="span">{roleData.region}</Typography>
                    </Tooltip>
                  </Typography>
                  <Typography component="div" className="users-table-cell arTextRight">
                    <Tooltip title={roleData.country}>
                      <Typography component="span">{roleData.country}</Typography>
                    </Tooltip>
                  </Typography>
                  <Typography component="div" className="users-table-cell arTextRight">
                    <Tooltip title={roleData.application}>
                      <Typography component="span">{rowData.roles === USER.ROLE.SD_ADMIN ? userApplications[0] : roleData.application}</Typography>
                    </Tooltip>
                  </Typography>
                  <Typography component="div" className="users-table-cell arTextRight">
                    <Typography component={"div"} className="programs-div">
                      {
                        roleData.isAllPrograms ?
                          <Tooltip title={` ${t('common.all')}`}>
                            <Typography component="span">
                              {t('common.all')}
                            </Typography>
                          </Tooltip>
                          :
                          <>
                            {
                              roleData.programs.map((program: any, index: any) =>
                                <>
                                  <Tooltip title={program.name}>
                                    <Typography component="span" key={index}>
                                      {program.name + (index < roleData.programs.length - 1 ? ', ' : '')}
                                    </Typography>
                                  </Tooltip>
                                </>
                              )
                            }
                          </>
                      }
                    </Typography>
                  </Typography>
                  <Typography component="div" className="users-table-cell">
                    &nbsp;
                  </Typography>
                </Typography>
              )
            }) : null
        }
        {
          countryRolesMapping.length > 0 && rowData.roles === USER.ROLE.SD_ADMIN &&

          <Typography component="div" className="users-table-row" key={countryRolesMapping.length + 1}>
            <Typography component="div" className="users-table-cell">
              &nbsp;
            </Typography>
            <Typography component="div" className="users-table-cell arTextRight">
              <Tooltip title={rowData.roles}>
                <Typography component="span">{rowData.roles}</Typography>
              </Tooltip>
            </Typography>
            <Typography component="div" className="users-table-cell arTextRight">
              &nbsp;
            </Typography>
            <Typography component="div" className="users-table-cell arTextRight">
              &nbsp;
            </Typography>
            <Typography component="div" className="users-table-cell arTextRight">
              <Tooltip title={userApplications[1]}>
                <Typography component="span">{userApplications[1]}</Typography>
              </Tooltip>
            </Typography>
            <Typography component="div" className="users-table-cell arTextRight">
              &nbsp;
            </Typography>
            <Typography component="div" className="users-table-cell">
              &nbsp;
            </Typography>
          </Typography>


        }
      </>
    )
  };

  const clickRow = (rowData: any) => {
    selectedRow = rowData.tableData.id;
    return <ExpandLessIcon />;
  };

  const handleDisable = (rowData: any) => {
    if (
      Object.keys(rowData).length > 1 &&
      (rowData.hasOwnProperty("countryUsers") && rowData.countryUsers.length > 1) ||
      (rowData.hasOwnProperty("progamCount") && rowData.progamCount > 1 && !rowData.isAllProgramsAssigned) ||
      (rowData.hasOwnProperty("applicationCount") && rowData.applicationCount > 1)
    ) {
      return <ExpandMoreIcon />;
    } else {
      return <></>;
    }
  };

  const handleUserStatusIcon = (rowData: any) => {
    if (
      Object.keys(rowData).length > 1 &&
      rowData.hasOwnProperty("countryUsers") &&
      rowData.countryUsers.length
    ) {
      if (rowData.isDeleted) {
        return <Tooltip title={`${t("common.inactive")}`}><InActiveStatus /></Tooltip>;
      } else {
        return <Tooltip title={`${t("common.active")}`}><ActiveStatus /></Tooltip>;
      }
    } else {
      return <Tooltip title={`${t("common.accessRequired")}`}><ARStatus /></Tooltip>;
    }
  };

  return (
    <>
      <Typography component={"div"} className="users-main-table-outer fixed-column-table-outer">
        <MaterialTable
          columns={[
            {
              title: t("common.status"),
              field: "isActive",
              filtering: false,
              sorting: false,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                textAlign: "center",
              },
              headerStyle: {
                textAlign: "center",
              },
              render: (tblProps) => {
                return handleUserStatusIcon(tblProps);
              },
            },
            {
              title: "No.",
              field: "id",
              filtering: false,
              sorting: false,
              render: (tblProps) => {
                let rowData = handlerSerialNumberRowData(tblProps);
                return <Tooltip title={rowData}><Typography component={"span"}>{rowData}</Typography></Tooltip>;
              },
              cellStyle: {
                ...commonCellStyle,
                minWidth: "50px",
                maxWidth: "50px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            },
            {
              title: t("userProfile.firstName"),
              field: "firstName",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "150px",
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              render: (rowData: any) => (
                <Tooltip title={rowData.firstName}>
                  <Typography component="span">
                    {rowData.firstName}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("userProfile.lastName"),
              field: "lastName",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "150px",
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              render: (rowData: any) => (
                <Tooltip title={rowData.lastName}>
                  <Typography component="span">
                    {rowData.lastName}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.email"),
              field: "userEmail",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "180px",
                maxWidth: "180px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              render: (rowData: any) => (
                <Tooltip title={rowData.userEmail}>
                  <Typography component="span">
                    {rowData.userEmail}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.org"),
              field: "organization",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              render: (rowData: any) => (
                <Tooltip title={rowData.organization}>
                  <Typography component="span">
                    {rowData.organization}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.role"),
              field: "rolesName",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              headerStyle: commonHeaderStyleDarker,
              render: (rowData: any) => (
                <Tooltip title={rowData.roles}>
                  <Typography component="span">
                    {rowData.rolesCount > 0 ? rowData.roles : t("common.roles") + " (0)"}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.region"),
              field: "regionsName",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              headerStyle: commonHeaderStyleDarker,
              render: (rowData: any) => (
                <Tooltip title={rowData.regions}>
                  <Typography component="span">
                    {rowData.regions}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.countryArea"),
              field: "countriesName",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "120px",
                maxWidth: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              headerStyle: commonHeaderStyleDarker,
              render: (rowData: any) => (
                <Tooltip title={rowData.countries}>
                  <Typography component="span">
                    {rowData.countries}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              title: t("common.system"),
              field: "applications",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              headerStyle: commonHeaderStyleDarker,
              render: (tblProps) => {
                return <>
                  {
                    tblProps.applicationCount > 1
                      ?
                      <Tooltip title={`${t('common.system')}(${tblProps.applicationCount})`}>
                        <Typography component="span">
                          {`${t('common.system')}(${tblProps.applicationCount})`}
                        </Typography>
                      </Tooltip>
                      :
                      <Tooltip title={tblProps.applications}>
                        <Typography component="span">
                          {tblProps.applications}
                        </Typography>
                      </Tooltip>
                  }
                </>;
              },
            },
            {
              title: t("common.program"),
              field: "programs",
              filtering: true,
              cellStyle: {
                ...commonCellStyle,
                minWidth: "100px",
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              headerStyle: commonHeaderStyleDarker,
              render: (tblProps) => {
                return <>
                  {
                    tblProps.progamCount <= 1
                      ?
                      <Tooltip title={tblProps.programs}>
                        <Typography component="span">
                          {tblProps.programs}
                        </Typography>
                      </Tooltip>
                      : tblProps.isAllProgramsAssigned
                        ?
                        <Tooltip title={`${t('common.all')}`}>
                          <Typography component="span">
                            {t('common.all')}
                          </Typography>
                        </Tooltip>
                        :
                        <Tooltip title={`${t('common.program')}(${tblProps.progamCount})`}>
                          <Typography component="span">
                            {`${t('common.program')}(${tblProps.progamCount})`}
                          </Typography>
                        </Tooltip>
                  }
                </>;
              },
            },
            {
              title: t("userProfile.regDate"),
              field: "createdDate",
              filtering: true,
              render: (rowData: any) => (
                <Tooltip title={rowData.createdDate}>
                  <Typography component="span">
                    {rowData.createdDate}
                  </Typography>
                </Tooltip>
              ),
              cellStyle: {
                ...commonCellStyle,
                minWidth: "120px",
                maxWidth: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
            },

          ]}

          data={useProfileFilterData}
          detailPanel={[
            (rowData: any) => {
              return ({
                icon: () => handleDisable(rowData),
                openIcon: () => clickRow(rowData),
                render: () => (
                  <>
                    <CustomTable className="cls-users-table">
                      {renderTableData(rowData.countryRoles, rowData)}
                    </CustomTable>
                  </>
                ),
                disabled:
                  ((parseInt(rowData.countriesCount) === 1
                    && parseInt(rowData.regionsCount) === 1
                    && parseInt(rowData.rolesCount) === 1
                    && parseInt(rowData.progamCount) === 1
                    && parseInt(rowData.applicationCount) === 1))
                  || ((parseInt(rowData.countriesCount) === 1
                    && parseInt(rowData.regionsCount) === 1
                    && parseInt(rowData.rolesCount) === 1
                    && (rowData.isAllProgramsAssigned)
                    && parseInt(rowData.applicationCount) === 1))
                  || (rowData.isActive === "Access Required"),
              })
            },
          ]}
          options={{
            filtering: true,
            toolbar: false,
            pageSizeOptions: [10, 20, 50],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
            detailPanelColumnAlignment: "left",
            detailPanelType: "single",
            actionsCellStyle: commonCellStyle,
            headerStyle: commonHeaderStyle,
            doubleHorizontalScroll: true,
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? "#f6fbfd" : "#FFF",
              borderBottomColor: "#fff",
            }),
          }}
          actions={[
            {
              icon: () => <EditIconCustom className="arRotate270" />,
              tooltip: t("common.edit"),
              onClick: (event, rowData) => handlerEditRowData(event, rowData),
            },
          ]}
          localization={{
            header: {
              actions: t("common.action"),
            },
            body: {
              filterRow: {
                filterTooltip: t("pagination.filter"),
              },
              emptyDataSourceMessage: t("common.noDataAvailable")
            },
            pagination: {
              firstTooltip: t("pagination.firstPage"),
              previousTooltip: t("pagination.prevPage"),
              nextTooltip: t("pagination.nextPage"),
              lastTooltip: t("pagination.lastPage"),
              labelRowsSelect: t("common.rows"),
            },
          }}
        />
      </Typography>
    </>
  );
}

export default UserProfileTableComponent;
