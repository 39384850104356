import { Box, Container, Grid, Typography, Button, Tooltip } from "@material-ui/core";
import FormDatePicker from "../../../components/form-controls/datepicker";
import FormSelect from "../../../components/form-controls/select";
import { AssessmentCountList, ASSESSMENT, USER, ASSESSMENT_TYPE_CONST } from "../../../constants/data";
import {
  actionFetchAssessmentReportData,
  actionFetchAssessmentReportRegionData,
  actionFetchAssessmentReportRegionSuccess,
  actionFetchAssessmentReportSuccess,
} from "../../../containers/assessment-create/actions";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../../store/common/actions";
import {
  AssessmentSubHeader,
  BoxShadowWrapper,
  DialogButtonPrimary,
  DownloadIconCustom,
} from "../../../styles/common";
import {
  calculateMaturityModelIndex,
  calculateMaxWeightedScore,
} from "../../../utils/data";
import * as yup from "yup";
import ColumnChartComponent from "../../../components/common/column-chart.component";
import ReportTableComponent from "components/common/report-table.component";
import { actionExportReportTableData } from "../actions";

function ReportComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userSelectedSettingData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const assessmentReportList = useSelector(
    (state: any) => state.assesmentCreate.assessmentReportData
  );
  const assessmentReportRegionList = useSelector(
    (state: any) => state.assesmentCreate.assessmentReportRegionData
  );
  const maturityIndexData = useSelector(
    (state: any) => state.masterForm.mastermaturityIndexData
  );
  const listOrgWiseRegion = useSelector(
    (state: any) => state.common.listOrgWiseRegion
  );
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );

  const listProgram = useSelector((state: any) => state.common.listProgram);
  const listScope = useSelector((state: any) => state.common.listScope);
  const allScopeData: any = (window as any).allScopeData;
  const roleData = useSelector((state: any) => state.common.roleData);
  const allPrograms: any = (window as any).allPrograms;

  const validationSchema = yup.object().shape({
    supplyChain: yup.string().required(t("validationMessage.programSelect")),
    scope: yup.string().required(t("validationMessage.scopeSelect")),
    dateFrom: yup.date().typeError(t("validationMessage.dateValid")),
    dateTo: yup
      .date()
      .typeError(t("validationMessage.dateValid"))
      .min(yup.ref("dateFrom"), t("validationMessage.dateRange")),
  });
  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { handleSubmit, errors } = methods;
  const { setValue, getValues } = methods;

  const [userCountryList, setUserCountryList]: any = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [keyCount, setKeyCount] = useState(0);
  const [show, setShow] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedSupplyChainData, setSelectedSupplyChainData] = useState();
  const [selectedScope, setSelectedScope] = useState();
  const [selectedScopeId, setSelectedScopeId] = useState();
  const [labelAvgMMIndex, setLabelAvgMMIndex]: any = useState(0);
  const [programList, setProgramList]: any = useState([]);
  const [scopeList, setScopeList]: any = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [selectedRole, setSelectedRole] = useState("");
  const [reportChartData, setReportChartData] = useState("");
  const [isProgramDisabled, setProgramDisabled] = useState(false);
  const [isScopeDisabled, setScopeDisabled] = useState(false);

  let areaOfWorks: any = [];
  let areaWorkDataList: any = [];
  let chartHeaders: any = [];
  let assessmentList: any = [];
  let countrys: any = [];
  let listOfScope: any = [];

  let allOption: any = {
    id: "-1",
    label: t("common.all"),
  };

  useEffect(() => {
    return () => {
      dispatch(actionFetchAssessmentReportRegionSuccess([]));
      dispatch(actionFetchAssessmentReportSuccess([]));
    };
  }, []);

  useEffect(() => {
    if (programList.length && scopeList.length) {
      if (userSelectedSettingData && programList) {
        fetchAssesmeDataByCountry(userSelectedSettingData.userSelectedData);
      } else if (localStorage.getItem("userSelectedData")) {
        const userSettingData: any = JSON.parse(
          localStorage.getItem("userSelectedData") || "{}"
        );
        if (userSettingData && userSettingData.hasOwnProperty("userSelectedCountry")) {
          fetchAssesmeDataByCountry(userSettingData);
        }
      }
    }
  }, [userSelectedSettingData, programList, scopeList, assessmentTypeFilter]);

  useEffect(() => {
    if (
      !firstTimeCall &&
      organizationSelected &&
      listOrgWiseRegion.length &&
      listOrgWiseCountry.length
    ) {
      getRegionWiseCountryList(listOrgWiseRegion[0].label);
    }
  }, [organizationSelected, listOrgWiseRegion, listOrgWiseCountry]);

  useEffect(() => {
    populateChart();
    if (assessmentReportList.length > 0) {
      maturityIndexCalculated(assessmentReportList);
      setReportChartData(assessmentReportList);
    } else if (assessmentReportRegionList.length > 0) {
      maturityIndexCalculated(assessmentReportRegionList);
      setReportChartData(assessmentReportRegionList);
    }
  }, [assessmentReportList, assessmentReportRegionList]);

  useEffect(() => {
    if (chartData.length) {
      showHideChart(true);
      dispatch(setLoaderVisibility(false));
    } else {
      showHideChart(false);
      dispatch(setLoaderVisibility(false));
    }
  }, [chartData]);

  useEffect(() => {
    if (listProgram.length && listScope.length) {
      listOfScope.push(allOption);
      listOfScope = [...listOfScope, ...listScope];

      setScopeList(listOfScope);
      getProgramList(listProgram, listScope, assessmentTypeFilter);
    }

  }, [listProgram, listScope]);

  const getProgramList = (listProgram: any, listScope: any, assessmentTypeFilter: Boolean) => {
    if (listProgram.length && listScope.length) {
      let programs: any = [...listProgram];
      programs.unshift(allOption);

      if (!assessmentTypeFilter)
        programs = programs.filter((prog: any) => {
          const data = allPrograms.filter((a:any)=>a.id===prog.id);
          if(data.length===0)
            return prog;
        })||[];

      setProgramList(programs);
    }
  }

  const handleAssessmentTypeFilterChange = () => {
    let supplyChain: any = "-1";
    let scope: any = "-1"
    if (!assessmentTypeFilter) {
      // noraml type
      setProgramDisabled(false);
      setScopeDisabled(false);
    } else {
      supplyChain = allPrograms.length ? allPrograms[0].id : '';
      scope = allScopeData[0].id
      setProgramDisabled(true);
      setScopeDisabled(true);
    }
    setValue("supplyChain", supplyChain);
    setValue("scope", scope);
  }

  useEffect(() => {
    getProgramList(listProgram, listScope, assessmentTypeFilter);
    handleAssessmentTypeFilterChange();
  }, [assessmentTypeFilter])


  const fetchAssesmeDataByCountry = (userSelectedData: any) => {
    const userRegion = userSelectedData.userSelectedRegion;
    const suppyChain: any = allPrograms.length && assessmentTypeFilter ? allPrograms[0].id : programList[0].id;
    const scope: any = assessmentTypeFilter ? allScopeData[0].id : scopeList[0].id;

    setValue("supplyChain", suppyChain);
    setValue("scope", scope);
    setValue("dateFrom", moment().subtract(15, "d").format("YYYY-MM-DD"));
    setValue("dateTo", moment().format("YYYY-MM-DD"));
    setValue("noAssessment", AssessmentCountList[0].id);
    setValue("region", userRegion[0]);

    let countryList: any = listOrgWiseCountry.filter(
      (x: any) => x.region == userRegion[0]
    );
    setCountryList(countryList);

    const tempRoleData = roleData.filter(
      (role: any) => role.id === userSelectedData.userSelectedRole
    );
    if (tempRoleData.length) {
      setSelectedRole(tempRoleData[0].name.trim());
    }

    setFirstTimeCall(false);
  };

  const showHideChart = (request: boolean) => {
    setShow(request);
  };

  useEffect(() => {
    if (programList.length && scopeList.length) {
      if (firstTimeCall === false) {
        callFunctionOnLanguageChange();
      }
    }
  }, [languageSelected]);

  const callFunctionOnLanguageChange = () => {
    const programId = getValues("supplyChain");
    const scopeId = getValues("scope");
    const dateFrom = getValues("dateFrom");
    const dateTo = getValues("dateTo");
    const noAssessment = getValues("noAssessment");
    const region = getValues("region");
    const country = getValues("country");
    if (country) countrys.push(country);

    const assesmentRequestData = {
      supplyChain: programId,
      scope: scopeId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      noAssessment: noAssessment,
      countrys: countrys,
      country: country,
      status: ASSESSMENT.STATUS.VALIDATED,
      region: region,
    };
    getReportData(assesmentRequestData);
  };

  const onSubmit = (data: any) => {
    if (data.country.length) {
      countrys.push(data.country);
      getUserCountryList(data.country);
      getReportData(data);
    } else {
      getReportData(data);
    }
  };

  const getReportData = (data: any) => {
    if (data.region && !data.country) {
      const assesmentRequestData = {
        programId: data.supplyChain,
        scopeId: data.scope,
        dateFrom: moment(data.dateFrom).format("DD-MM-YYYY"),
        dateTo: moment(data.dateTo).format("DD-MM-YYYY"),
        noAssessment: data.noAssessment,
        region: data.region,
        organisation: organizationSelected,
        status: ASSESSMENT.STATUS.VALIDATED,
        assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      };
      getRegionWiseCountryList(data.region);
      fetchAssessmentReport(assesmentRequestData);
    } else {
      const assesmentRequestData = {
        programId: data.supplyChain,
        scopeId: data.scope,
        dateFrom: moment(data.dateFrom).format("DD-MM-YYYY"),
        dateTo: moment(data.dateTo).format("DD-MM-YYYY"),
        noAssessment: data.noAssessment,
        countrys: countrys,
        region: data.region,
        status: ASSESSMENT.STATUS.VALIDATED,
        assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      };
      fetchAssessmentReport(assesmentRequestData);
    }
  };
  const fetchAssessmentReport = (data: any) => {
    setLabelAvgMMIndex(0);
    if (data.organisation) {
      dispatch(actionFetchAssessmentReportRegionData(data));
    } else {
      dispatch(actionFetchAssessmentReportData(data));
    }
    listOrgWiseRegion.map((item: any) => {
      if (item.id === data.region) {
        setSelectedRegion(item.label);
      }
    });
    programList.map((x: any) => {
      if (x.id === data.programId) {
        setSelectedSupplyChainData(x.label);
      }
    });
    scopeList.map((item: any) => {
      if (item.id === data.scopeId) {
        setSelectedScope(item.label);
        setSelectedScopeId(item.id);
      }
    });
  };

  const handelRegionChange = ([event]: any) => {
    if (event.target.value != "") {
      getRegionWiseCountryList(event.target.value);
    }
    handleAssessmentTypeFilterChange();
    return event.target.value;
  };

  const handelCountryChange = ([event]: any) => {
    handleAssessmentTypeFilterChange();
    return event.target.value;
  };

  const getRegionWiseCountryList = (region: any) => {
    setValue("country", "");
    setUserCountryList([]);
    setCountryList([]);
    let countryList: any = listOrgWiseCountry.filter(
      (x: any) => x.region == region
    );
    setCountryList(countryList);
  };

  const getUserCountryList = (id: any) => {
    let countryList: any = listOrgWiseCountry.filter((x: any) => x.id == id);
    setUserCountryList(countryList);
  };

  const maturityIndexCalculated = (assessmentReportList: any) => {
    let bindMaturityIndexCalcTable: any = [];
    const scopeId = getValues("scope");
    if (
      assessmentReportList.length &&
      scopeId === allScopeData[0].id.toString()
    ) {
      assessmentReportList.map((assessments: any) => {
        let bindMaturityTableData: any = [];
        let tempTotalMaxWightedScore = 0;
        assessments.assessmentChartList.map((item: any, index: any) => {
          maturityIndexData.filter((maturityRow: any) => {
            if (maturityRow.areaId === item.areaOfWorkId && item.currentScore != 0) {
              maturityRow.mmScore = item.currentScore;
              maturityRow.maxWeightScore = calculateMaxWeightedScore(
                maturityRow.maxScore,
                maturityRow.weight
              );
              tempTotalMaxWightedScore += maturityRow.maxWeightScore;
              bindMaturityTableData.push(maturityRow);
            }
          });
        });
        if (bindMaturityTableData.length) {
          let maturitIndexValue: any = 0;
          bindMaturityTableData.map((item: any) => {
            maturitIndexValue += calculateMaturityModelIndex(
              item.mmScore,
              item.weight,
              tempTotalMaxWightedScore
            );
          });
          bindMaturityIndexCalcTable.push(Number(maturitIndexValue.toFixed(1)));
        }
      });
      if (bindMaturityIndexCalcTable.length) {
        let avgMaturitIndexValue: any = 0;
        bindMaturityIndexCalcTable.map((item: any) => {
          avgMaturitIndexValue += Number(item);
        });

        const countryId = getValues("country");
        if (countryId) {
          avgMaturitIndexValue =
            avgMaturitIndexValue / bindMaturityIndexCalcTable.length;
        } else {
          avgMaturitIndexValue = avgMaturitIndexValue / countryList.length;
        }
        setLabelAvgMMIndex(avgMaturitIndexValue.toFixed(1));
      }
    }
  };

  const populateChart = () => {
    if (assessmentReportRegionList.length && !userCountryList.length) {
      let chartDataList: any = [];
      let counter: any;
      counter = assessmentReportRegionList.length;
      setKeyCount(counter);
      chartHeaders.push("areaOfWork");
      assessmentReportRegionList[0].assessmentChartList.map(
        (item: any, index: any) => {
          areaOfWorks.push(item.areaOfWork);
        }
      );
      for (let i = 0; i < assessmentReportRegionList.length; i++) {
        chartHeaders.push(assessmentReportRegionList[i].assessmentCode);
        chartHeaders.push({ role: "annotation" });
        chartHeaders.push({ role: "tooltip", type: "string", p: { html: true } })
        assessmentList.push(assessmentReportRegionList[i].assessmentCode);
      }
      counter++;
      chartDataList.push(chartHeaders);
      areaOfWorks.forEach((x: any) => {
        areaWorkDataList = [];
        areaWorkDataList.push(x);
        assessmentReportRegionList.map((items: any) => {
          for (let i = 0; items.assessmentChartList; i++) {
            if (x == items.assessmentChartList[i].areaOfWork) {
              let currentScore: any = 0;
              if (items.assessmentChartList[i].currentScore > 0) {
                currentScore = Number(items.assessmentChartList[i].currentScore.toFixed(1));
              }
              areaWorkDataList.push(currentScore);
              areaWorkDataList.push(
                items.assessmentChartList[i].isNA ? `${t("common.na")}` : `${currentScore}`
              );
              // custom tooltip with subarea of works
              let str = "";
              items.assessmentChartList[i].subAreaOfWorks.forEach((sub: any) => {
                let subAreaScore: any = sub.isNA ? `${t("common.na")}` : 0;
                if (sub.score > 0)
                  subAreaScore = Number(sub.score.toFixed(1));
                str += `<div class="tlp-saow">${sub.subAreaOfWork}: ${subAreaScore}</div>`
              })
              areaWorkDataList.push(
                `<div class="report-tooltip">
                  <div class="tlp-asscode">${items.assessmentCode}</div>
                  <div class="tlp-aow">${items.assessmentChartList[i].areaOfWork}: ${currentScore}</div>
                  <div class="tlp-saow-outer">${str}</div>
                  </div> `
              );
              break;
            }
          }
        });
        chartDataList.push(areaWorkDataList);
      });
      setChartData(chartDataList);
    } else if (assessmentReportList && assessmentReportList.length) {
      let chartDataList: any = [];
      let counter: any;
      setChartData([]);
      counter = assessmentReportList.length;
      setKeyCount(counter);
      chartHeaders.push("areaOfWork");
      assessmentReportList[0].assessmentChartList.map((item: any) => {
        areaOfWorks.push(item.areaOfWork);
      });
      for (let i = 0; i < assessmentReportList.length; i++) {
        chartHeaders.push(assessmentReportList[i].assessmentCode);
        chartHeaders.push({ role: "annotation" });
        chartHeaders.push({ role: "tooltip", type: "string", p: { html: true } })
        assessmentList.push(assessmentReportList[i].assessmentCode);
      }
      counter++;
      chartDataList.push(chartHeaders);
      areaOfWorks.forEach((x: any) => {
        areaWorkDataList = [];
        areaWorkDataList.push(x);
        assessmentReportList.map((items: any) => {
          for (let i = 0; items.assessmentChartList; i++) {
            if (x == items.assessmentChartList[i].areaOfWork) {
              let currentScore: any = 0;
              if (items.assessmentChartList[i].currentScore > 0) {
                currentScore = Number(items.assessmentChartList[i].currentScore.toFixed(1))
              }
              areaWorkDataList.push(currentScore);
              areaWorkDataList.push(
                items.assessmentChartList[i].isNA ? `${t("common.na")}` : `${currentScore}`
              );
              // custom tooltip with subarea of works
              let str = "";
              items.assessmentChartList[i].subAreaOfWorks.forEach((sub: any) => {
                let subAreaScore: any = sub.isNA ? `${t("common.na")}` : 0;
                if (sub.score > 0) {
                  subAreaScore = Number(sub.score.toFixed(1));
                }
                str += `<div class="tlp-saow">${sub.subAreaOfWork}: ${subAreaScore}</div>`
              })
              areaWorkDataList.push(
                `<div class="report-tooltip">
                  <div class="tlp-asscode">${items.assessmentCode}</div>
                  <div class="tlp-aow">${items.assessmentChartList[i].areaOfWork}: ${currentScore}</div>
                  <div class="tlp-saow-outer">${str}</div>
                  </div> `
              );
              break;
            }
          }
        });
        chartDataList.push(areaWorkDataList);
      });
      setChartData(chartDataList);
    } else {
      setChartData([]);
    }
  };

  const exportReports = () => {
    // this function will used for export excel.
    let assesmentRequestData: any = {};
    const programId = getValues("supplyChain");
    const scopeId = getValues("scope");
    const dateFrom = getValues("dateFrom");
    const dateTo = getValues("dateTo");
    const noAssessment = getValues("noAssessment");
    const region = getValues("region");
    const country = getValues("country");
    if (country) countrys.push(country);

    assesmentRequestData = {
      programId,
      scopeId,
      dateFrom: moment(dateFrom).format("DD-MM-YYYY"),
      dateTo: moment(dateTo).format("DD-MM-YYYY"),
      noAssessment: noAssessment,
      countries: !country ? [] : countrys,
      region: region,
      organisation: organizationSelected || "",
      status: ASSESSMENT.STATUS.VALIDATED,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionExportReportTableData(assesmentRequestData));
  }

  return (
    <Container maxWidth={"xl"}>
      <FormContext {...methods}>
        <>
          <BoxShadowWrapper className="margin-top-20">
            <Grid container alignItems="center" justify="center">
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  className="padding0-16-24 margin-top-0"
                >
                  <Grid item sm={2}>
                    <FormSelect
                      name="region"
                      label={t("common.region")}
                      options={listOrgWiseRegion}
                      onChange={(event: any) => handelRegionChange(event)}
                      required={true}
                      errorobj={errors}
                      disabled={selectedRole === USER.ROLE.RSA ? true : false}
                      hideselectoption="true"
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormSelect
                      name="country"
                      label={t("common.countryArea")}
                      onChange={(event: any) => handelCountryChange(event)}
                      options={countryList}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  className="padding0-16-24 margin-top-0"
                >
                  <Grid item sm={2}>
                    <FormSelect
                      name="supplyChain"
                      label={t("common.program")}
                      options={programList}
                      required={true}
                      errorobj={errors}
                      hideselectoption="true"
                      disabled={isProgramDisabled}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormSelect
                      name="scope"
                      label={t("common.scope")}
                      options={scopeList}
                      required={true}
                      errorobj={errors}
                      disabled={isScopeDisabled}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormSelect
                      name="noAssessment"
                      label={t("assessment.noOfAssessment")}
                      options={AssessmentCountList}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateFrom"
                      label={t("common.fromDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateTo"
                      label={t("common.toDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>
                  <Grid item sm={2} className="text-center">
                    <DialogButtonPrimary
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("setting.apply")}
                    </DialogButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BoxShadowWrapper>
          {
            show ? (
              <>
                <Box pb={2}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Tooltip title={`${t("common.downloadReport")}`}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => exportReports()}
                        >
                          <DownloadIconCustom className="arMarginR0 arMarginL10" />
                          {t("common.exportToExcel")}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <BoxShadowWrapper>
                  <AssessmentSubHeader>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      className="padding20-15-0"
                    >
                      <Grid item>
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item className="region-info-text">
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="strong" className="strong-text">
                          {selectedSupplyChainData}
                          {"  |  "}
                          {selectedScope}

                          {userCountryList.length
                            ? ` | ${selectedRegion} 
                            -
                            ${userCountryList[0].label}`
                            : ` | ${selectedRegion}`}

                          {selectedScopeId === allScopeData[0].id.toString() && !assessmentTypeFilter
                            ? ` | ${t("common.overAllMaturityIndex") + labelAvgMMIndex
                            }`
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AssessmentSubHeader>
                  <Box>
                    <ColumnChartComponent
                      keyCount={keyCount}
                      chartData={chartData}
                      yAxisScaleLength={6}
                    />
                  </Box>
                  <Box pt={2}>
                    <ReportTableComponent
                      reportChartData={reportChartData}
                    />
                  </Box>
                </BoxShadowWrapper>
              </>
            ) : (
              <>
                <BoxShadowWrapper>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="minHeight340"
                  >
                    <Grid item>
                      <Typography component="strong" className="strong-text">
                        {t("common.noDataAvailable")}
                      </Typography>
                    </Grid>
                  </Grid>
                </BoxShadowWrapper>
              </>
            )
          }
        </>
      </FormContext>
    </Container>
  );
}
export default ReportComponent;
