import React, { forwardRef, useEffect, useState } from "react";
import Container from "@material-ui/core/Container/Container";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import { commonCellStyle, commonHeaderStyle, maxWidthCellStyle } from "../../../styles/materialStyles";
import { useTranslation } from "react-i18next";
import { EditIconCustom, AddIconCustom, SubHeaderWrapper, BoxShadowWrapper, ArrowBackIosIconCustom, ArrowForwardIosIconCustom, DeleteIconCustom } from "styles/common";
import { useDispatch, useSelector } from 'react-redux';
import { actionDeleteMasterTopicAowHierarchyData, actionFetchAOWtopicDetails, actionFetchAOWtopicHierarchy } from "../actions";
import { AOW_MANAGEMENT_CONST } from "constants/data";
import AowManegmentFormComponent from "./aow-management-form";
import { ConfirmDialog } from "components/common/confirm-dialog.component";


function AreaOfWorkManagementComponent(props: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const aowHirerarchyData = useSelector(
        (state: any) => state.masterForm.aowHirerarchyData
    );
    const areaOfWorklist = useSelector(
        (state: any) => state.common.listAreaOfWork
    );
    const subAreaOfWorklist = useSelector(
        (state: any) => state.common.listSubAreaOfWork
    );
    const languageSelected = useSelector(
        (state: any) => state.header.languageSelected
    );
    const [showGrid, setShowGrid] = useState(true);
    const [IsEdit, setIsEdit] = useState(false);
    const [currentItem, setCurrentItem]: any = useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteForm, setDeleteForm]: any = useState(null);
    const [expandedAOWId, setExpandedAOWId] = useState(0);
    const [expandedSubAOWId, setExpandedSubAOWId] = useState(0);

    useEffect(() => {
        if (languageSelected) {
            dispatch(actionFetchAOWtopicHierarchy());
        }
    }, [languageSelected]);

    const showHideGridAndForm = (request: boolean) => {
        setShowGrid(!request);
        setIsEdit(false);
    };

    const editFormvalues = (request: boolean) => {
        setIsEdit(request);
    };

    const handlerEditRowData = (event: any, rowData: any) => {
        setExpandedAOWId(rowData.areaOfWorkId);
        setExpandedSubAOWId(rowData.subAreaOfWorkId);
        setCurrentItem(rowData);
        dispatch(actionFetchAOWtopicDetails(rowData.type, rowData.type !== AOW_MANAGEMENT_CONST.SUB_AOW ? rowData.areaOfWorkId : rowData.subAreaOfWorkId, rowData.topicId));
        showHideGridAndForm(true);
        editFormvalues(true);
    };

    const handlerAddRowData = (event: any, rowData: any) => {
        setExpandedAOWId(rowData.areaOfWorkId);
        setExpandedSubAOWId(rowData.subAreaOfWorkId);
        setCurrentItem(rowData);
        showHideGridAndForm(true);
        editFormvalues(false);
    };

    const isLastTopicToDelete = (rowData: any) => {
        const topics = aowHirerarchyData.filter((row: any) => (
          rowData.areaOfWorkId === row.areaOfWorkId &&
          rowData.subAreaOfWorkId === row.subAreaOfWorkId &&
          rowData.topicId && row.topicId &&
          rowData.topicId !== row.topicId
        ))
        return !topics.length ? true : false
      }

    const handlerDeleteRowData = (event: any, rowData: any) => {
        setExpandedAOWId(rowData.areaOfWorkId);
        setExpandedSubAOWId(rowData.subAreaOfWorkId);
        setOpenConfirmDialog(true);
        setDeleteForm(rowData.topicId);
    };

    const handlerCloseConfirmDialog = () => {
        setDeleteForm(null);
        setOpenConfirmDialog(false);
    }

    const deleteRowItem = () => {
        dispatch(actionDeleteMasterTopicAowHierarchyData(deleteForm));
        handlerCloseConfirmDialog();
    }

    return (
        <Container maxWidth={"xl"}>
            {showGrid ? (
                <>
                    <SubHeaderWrapper>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography component="span" className="common-label">
                                    {t("master.aowMananegement")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </SubHeaderWrapper>
                    <BoxShadowWrapper>
                        <Typography component={"div"} className="hide-table-scrolling ">
                            <MaterialTable
                                columns={[
                                    {
                                        field: "areaOfWork",
                                        title: t("common.areaOfWork"),
                                        editable: 'never',
                                        render: (rowData: any) => {
                                            return <>
                                                <Tooltip title={rowData.areaOfWork}>
                                                    <Typography component={"span"}>
                                                        {rowData.areaOfWork}
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        },
                                        cellStyle: {
                                            ...commonCellStyle,
                                            ...maxWidthCellStyle,
                                            minWidth: "300px",
                                            maxWidth: "300px",
                                        },
                                        filtering: false,
                                    },
                                    {
                                        field: "subAreaOfWork",
                                        title: t("common.subAreaOfWork"),
                                        render: (rowData: any) => {
                                            return <>
                                                <Tooltip title={rowData.subAreaOfWork}>
                                                    <Typography component={"span"}>
                                                        {rowData.subAreaOfWork}
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        },
                                        cellStyle: {
                                            ...commonCellStyle,
                                            ...maxWidthCellStyle,
                                            minWidth: "300px",
                                            maxWidth: "300px",
                                        },
                                    },
                                    {
                                        field: "topicName",
                                        title: t("common.topic"),
                                        render: (rowData: any) => {
                                            return <>
                                                <Tooltip title={rowData.topicName}>
                                                    <Typography component={"span"}>
                                                        {rowData.topicName}
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        },
                                        cellStyle: {
                                            ...commonCellStyle,
                                            ...maxWidthCellStyle,
                                            minWidth: "300px",
                                            maxWidth: "300px",
                                        },
                                    }
                                ]}
                                data={aowHirerarchyData}
                                options={{
                                    addRowPosition: 'first',
                                    pageSizeOptions: [50, 100, 150, 200, 250],
                                    pageSize: 50,
                                    emptyRowsWhenPaging: false,
                                    grouping: false,
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: aowHirerarchyData.length > 5 ? true : false,
                                    toolbar: false,
                                    headerStyle: commonHeaderStyle,
                                    defaultExpanded: (rowData: any) => (rowData.id === expandedAOWId || rowData.id === expandedSubAOWId)
                                        ? true
                                        : false,
                                    draggable: false,
                                    filtering: false,
                                }}
                                actions={[
                                    (rowData: any) => ({
                                        icon: () => <EditIconCustom />,
                                        tooltip: (rowData.areaOfWork != null)
                                            ? t("master.editAOW")
                                            : (rowData.type !== AOW_MANAGEMENT_CONST.SUB_AOW)
                                                ? t("master.editTopic")
                                                : t("master.editSubAOW"),
                                        onClick: (event, rowData) => handlerEditRowData(event, rowData),
                                    }),
                                    (rowData: any) => ({
                                        icon: () => <AddIconCustom />,
                                        tooltip: t("master.addTopic"),
                                        onClick: (event, rowData: any) => { handlerAddRowData(event, rowData) },
                                        hidden: (rowData.type !== AOW_MANAGEMENT_CONST.SUB_AOW)
                                    }),
                                    (rowData: any) => ({
                                        icon: () => <DeleteIconCustom />,
                                        tooltip: t("master.deleteTopic"),
                                        onClick: (event, rowData: any) => { handlerDeleteRowData(event, rowData) },
                                        hidden: (rowData.type !== AOW_MANAGEMENT_CONST.TOPIC),
                                        disabled: isLastTopicToDelete(rowData)
                                    }),
                                ]}
                                icons={{
                                    DetailPanel: forwardRef((props: any, ref: any) => (
                                        <ArrowForwardIosIconCustom {...props} ref={ref} />
                                    ))
                                }}
                                parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}
                                localization={{
                                    header: {
                                        actions: t("common.actions"),
                                    },
                                    body: {
                                        editTooltip: t("common.edit"),
                                        deleteTooltip: t("common.delete"),
                                        filterRow: {
                                            filterTooltip: t("pagination.filter"),
                                        },
                                        editRow: {
                                            saveTooltip: t("common.save"),
                                            cancelTooltip: t("common.cancel"),
                                            deleteText: t("common.confirmationMsg")
                                        },
                                        emptyDataSourceMessage: t("common.noRecords"),
                                    },
                                    pagination: {
                                        firstTooltip: t("pagination.firstPage"),
                                        previousTooltip: t("pagination.prevPage"),
                                        nextTooltip: t("pagination.nextPage"),
                                        lastTooltip: t("pagination.lastPage"),
                                        labelRowsSelect: t("common.rows"),
                                    }
                                }}
                            />
                            <ConfirmDialog
                                open={openConfirmDialog}
                                close={handlerCloseConfirmDialog}
                                title={t("master.topicDeleteMsg")}
                                content=""
                                yes={t("common.yes")}
                                no={t("common.cancel")}
                                ok={deleteRowItem}
                            />
                        </Typography>
                    </BoxShadowWrapper>
                </>
            ) : (
                <>
                    <SubHeaderWrapper>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <IconButton
                                            className="padding10"
                                            color="default"
                                            onClick={() => showHideGridAndForm(false)}
                                        >
                                            <ArrowBackIosIconCustom className="arRotate180" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            component="span"
                                            className="common-label padding10-5"
                                        >
                                            {!IsEdit ? t("master.addTopic")
                                                : currentItem.type === AOW_MANAGEMENT_CONST.AOW ? t("common.edit") + ' ' + t("common.areaOfWork")
                                                    : currentItem.type === AOW_MANAGEMENT_CONST.SUB_AOW ? t("common.edit") + ' ' + t("common.subAreaOfWork")
                                                        : t("common.edit") + ' ' + t("common.topic")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubHeaderWrapper>
                    <BoxShadowWrapper>
                        <AowManegmentFormComponent
                            IsEdit={IsEdit}
                            showHideGridAndForm={showHideGridAndForm}
                            areaOfWorklist={areaOfWorklist}
                            subAreaOfWorklist={subAreaOfWorklist}
                            currentItem={currentItem}
                            setCurrentItem={setCurrentItem}
                        />
                    </BoxShadowWrapper>
                </>
            )}
        </Container>
    );
}

export default AreaOfWorkManagementComponent;
