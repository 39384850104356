import { ActionTypes } from "./constants";

const initialState = {
  postData: [],
  emailId: "",
  name: "",
  roleId: "",
};

export const allRequestsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.ALL_REQUESTS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        postData: action.payload.data || [],
      };
    case ActionTypes.ALL_REQUESTS_SET_USER_DETAILS:
      return {
        ...state,
        emailId: action.payload.emailId,
        name: action.payload.name,
        roleId: action.payload.roleId,
      };
    default:
      return state;
  }
};
