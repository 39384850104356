import { useDispatch } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions, Grid, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { DialogButtonPrimary, DialogClose } from "../../../../styles/common"
import _ from "lodash";
import React from "react";
import FormInput from "components/form-controls/input";
import { testInputValueWithRegex } from "utils/security";
import { actionSaveMasterCountryHierarchyData, actionUpdateMasterCountryHierarchyData } from "containers/master/actions";
import { COUNTRY_HIERARCHY_CONST } from "constants/data";

function HierarchyCreateFormComponent(props: any) {
    const {
        dialogClose,
        hideDialoagForm,
        countryId,
        currentItem,
        IsEdit,
        currentType
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        name: yup.string().trim().required(t("validationMessage.nameValidation"))
            .max(50, t("validationMessage.max50Validation"))
            .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    });

    const methods = useForm({
        validationSchema: validationSchema,
    });
    const { handleSubmit, errors, setValue } = methods;

    useEffect(() => {
        setTimeout(function () {
            if (!_.isEmpty(currentItem) && IsEdit) {
                setValue("name", currentItem.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? currentItem.subnationalName : currentItem.districtName);
            } else {
                setValue("name", "");
            }
        }, 0);
    }, [currentItem]);

    const onSubmit = (data: any) => {
        let formData: any = {
            "id": !IsEdit ? null : currentItem.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? currentItem.subnationalId : currentItem.districtId,
            "countryId": countryId,
            "name": data.name,
            "type": currentType,
            "parentId": currentType === COUNTRY_HIERARCHY_CONST.DISTRICT ? parseInt(currentItem.subnationalId) : null
        }
        if (IsEdit) {
            dispatch(actionUpdateMasterCountryHierarchyData(formData))
        } else {
            dispatch(actionSaveMasterCountryHierarchyData(formData));
        }
        dialogClose();
    }

    return (
        <>

            <DialogTitle id="alert-dialog-title">
                {
                    IsEdit ? t("common.edit") : t("common.add")
                } {" "}
                {
                    currentType === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? t("common.subNational") : t("common.district")
                }
                <DialogClose aria-label="close" onClick={hideDialoagForm}>
                    <CloseIcon />
                </DialogClose>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText component="div" id="alert-dialog-description" className="nomargin">
                    <FormContext {...methods}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <FormInput
                                    name="name"
                                    label={currentType === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL
                                        ? t("common.subNational") : t("common.district")}
                                    required={true}
                                    errorobj={errors}
                                />
                            </Grid>
                        </Grid>
                    </FormContext>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="padding16-24">
                <Grid container alignItems="center" justify="flex-end" spacing={2}>
                    <Grid item>
                        <DialogButtonPrimary
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {IsEdit ? t("common.update") : t("common.create")}
                        </DialogButtonPrimary>
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
}

export default HierarchyCreateFormComponent;
