import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormSelect from "../../../components/form-controls/select";
import FormSelectAutoComplete from "../../../components/form-controls/select-autocomplete";
import { DeleteIconCustom } from "styles/common";
import { Typography } from "@material-ui/core";

function UserProfileRegionComponent(props: any) {
  const { t } = useTranslation();
  const {
    fieldsRegionArray,
    listRoles,
    handleSelectRoleChange,
    handleSelectRegionChange,
    handleSelectCountryChange,
    handleRegionRemove,
    listPrograms,
    handleSelectProgramChange
  } = props;

  return (
    <>
      {fieldsRegionArray.map((fieldItem: any, fieldIndex: any) => (
        <Typography
          component="div"
          className="paddingB10 marginB10 dashed-border"
          key={fieldIndex}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={fieldItem.counterRegion}
          >
            <Grid item xs={11}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="span">
                        {t("common.role")}
                        <Typography component="span" className="required-field"> *</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="dd-users-role-outer">
                      <Typography component={"div"} className={"dd-users-role"}>
                        <FormSelect
                          name={`userRegion[${fieldItem.counterRegion}].role`}
                          options={listRoles}
                          onChange={(event: any) =>
                            handleSelectRoleChange(event, fieldItem.counterRegion)
                          }
                          required={fieldItem.isRoleRequired}
                          label={t("common.role")}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      {!fieldItem.isRegionDisable
                        && (
                          <Typography component="span">
                            {t("common.region")}
                            <Typography component="span" className="required-field"> *</Typography>
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      {!fieldItem.isRegionDisable && (
                        <FormSelectAutoComplete
                          name={`userRegion[${fieldItem.counterRegion}].region`}
                          options={fieldItem.regionList}
                          isMulti
                          onChange={(event: any) =>
                            handleSelectRegionChange(
                              event,
                              fieldItem.counterRegion
                            )
                          }
                          isDisabled={fieldItem.isRegionDisable}
                          required={fieldItem.isRegionRequired}
                          label={t("common.region")}
                          controlbgColor={"#FFF"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      {!fieldItem.isCountryDisable &&
                        (
                          <Typography component="span">
                            {t("common.countryArea")}
                            <Typography component="span" className="required-field"> *</Typography>
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      {!fieldItem.isCountryDisable && (
                        <FormSelectAutoComplete
                          name={`userRegion[${fieldItem.counterRegion}].country`}
                          options={fieldItem.countryList}
                          onChange={(event: any) =>
                            handleSelectCountryChange(
                              event,
                              fieldItem.counterRegion
                            )
                          }
                          isDisabled={fieldItem.isCountryDisable}
                          isMulti
                          required={fieldItem.isCountryRequired}
                          label={t("common.countryArea")}
                          controlbgColor={"#FFF"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      {fieldItem.isProgramRequired
                        && (
                          <Typography component="span">
                            {t("common.program")}
                            <Typography component="span" className="required-field"> *</Typography>
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      {fieldItem.isProgramRequired && (
                        <FormSelectAutoComplete
                          name={`userRegion[${fieldItem.counterRegion}].program`}
                          options={listPrograms}
                          isMulti
                          required={fieldItem.isProgramRequired}
                          label={t("common.program")}
                          onChange={(event: any) =>
                            handleSelectProgramChange(
                              event,
                              fieldItem.counterRegion
                            )
                          }
                          controlbgColor={"#FFF"}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Typography
                component="span"
                aria-label="delete"
                title={t("common.remove")}
                onClick={() => handleRegionRemove(fieldItem.counterRegion)}
              >
                <DeleteIconCustom />
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      ))}
    </>
  );
}

export default UserProfileRegionComponent;
