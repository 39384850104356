import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actionSetApplicationDetails, actionSetApplicationRegisterUser,
} from "../actions";
import { StyledSelect } from "../styles";
import MenuItem from "@material-ui/core/MenuItem";
import { actionSaveRegisterUserSelectedRoleData, actionUserProfileSettingPageShow } from "containers/user-profile/actions";
import { HOME_PATH_AFTER_LOGIN } from "constants/api";
import { history } from "../../../store/config";

function SystemSelectionComponent(props: any) {
  const dispatch = useDispatch();

  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );

  const applicationsList = useSelector(
    (state: any) => state.scsHub.applicationsList
  );

  const handleChangeApplication = (
    event: React.ChangeEvent<{ value: any }>, appDetails: any
  ) => {
    dispatch(actionSetApplicationDetails(event.target.value));
    localStorage.removeItem(
      "userSelectedData"
    );
    dispatch(actionSaveRegisterUserSelectedRoleData(null));
    dispatch(actionSetApplicationRegisterUser(true));
    dispatch(actionUserProfileSettingPageShow(true));
    history.push(HOME_PATH_AFTER_LOGIN);
  };

  return (
    <>
      <StyledSelect value={selectedAppDetails} onChange={(event: React.ChangeEvent<{ value: unknown }>, app: any) => handleChangeApplication(event, app)}>
        {applicationsList.filter((app: any) => app.isActive).map((application: any) => {
          return (
            <MenuItem
              key={application.id}
              value={application}
              className="LanguageSelect font-size-14"
            >
              {application.name}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </>
  );
}

export default SystemSelectionComponent;
