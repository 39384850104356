import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  actionFetchMasterMenuAllDynamicFieldAndDataDetail,
  actionSaveMasterMaturityIndexDetail,
} from "./actions";
import { actionSetPageTitle, closeToaster } from "../../store/common/actions";
import { PageWrapper } from "../../styles/common";
import { GridTableWrapper } from "./style";
import MasterMenuTableComponent from "./master-table/index";
import MasterMenuFormComponent from "./master-form/index";
import { Loader } from "../../components/common/loader.component";
import { Toaster } from "../../components/common/toaster.component";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./index.css";
import { sortObjectDataByDescending } from "utils/data";

function MasterMenuComponent(props: any) {
  const [openDilogue, setOpenDilogue] = React.useState(false);

  const handleClickOpenDilogue = () => {
    setEditFormValues({});
    setOpenDilogue(true);
  };

  const handleCloseDilogue = () => {
    setOpenDilogue(false);
  };
  const dispatch = useDispatch();
  const loginUserData = useSelector((state: any) => state.header.loginUserData);
  const masterMenuFormFieldAndData = useSelector(
    (state: any) => state.masterForm.masterMenuFormFieldAndDataDetail
  );
  const isMasterMaturityIndexCall = useSelector(
    (state: any) => state.masterForm.isMasterMaturityIndexCall
  );
  const isLoaderVisible = useSelector(
    (state: any) => state.common.isLoaderVisible
  );
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const formTitle = useSelector((state: any) => state.common.pageTitle);
  const [formKey, setFormKey] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editFormValues, setEditFormValues] = useState({});
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const routeParam = props.match.params.id;
    setFormKey(routeParam);
    dispatch(actionFetchMasterMenuAllDynamicFieldAndDataDetail(routeParam));
    dispatch(actionSetPageTitle(`Master ${routeParam}`));
  }, [dispatch]);

  useEffect(() => {
    if (loginUserData) {
      setUserId(loginUserData.id);
    }
  }, [loginUserData]);

  useEffect(() => {
    if (
      isMasterMaturityIndexCall &&
      masterMenuFormFieldAndData &&
      masterMenuFormFieldAndData.dynamicFormDetailsList.length
    ) {
      const data = sortObjectDataByDescending(
        masterMenuFormFieldAndData.dynamicFormDetailsList,
        "formId"
      );
      const formDataMaturityIndex = {
        areaId: data[0].formId,
        priority: 1,
        weight: 0,
        maxScore: 5,
        createdBy: userId,
      };
      dispatch(actionSaveMasterMaturityIndexDetail(formDataMaturityIndex));
    }
  }, [isMasterMaturityIndexCall, masterMenuFormFieldAndData]);

  //call function through the table Edit row value
  const functionEditForm = (rowValues: any) => {
    setEditFormValues(rowValues);
    setIsEdit(true);
    setOpenDilogue(true);
  };
  return (
    <>
      <PageWrapper>
        <Loader visible={isLoaderVisible} />
        <Toaster
          message={toasterMessage}
          open={isToasterVisible}
          close={closeToaster}
          success={isSuccess}
        />
        {masterMenuFormFieldAndData &&
          masterMenuFormFieldAndData.dynamicFormFieldsList && (
            <>
              <div className="text-right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpenDilogue}
                >
                  Add New
                </Button>
              </div>
              <GridTableWrapper>
                <MasterMenuTableComponent
                  masterMenuFormFieldAndData={masterMenuFormFieldAndData}
                  formTitle={formTitle}
                  formKey={formKey}
                  functionEditForm={functionEditForm}
                  handleClickOpen={handleClickOpenDilogue}
                  userId={userId}
                ></MasterMenuTableComponent>
              </GridTableWrapper>
            </>
          )}
      </PageWrapper>
      <Dialog
        open={openDilogue}
        onClose={handleCloseDilogue}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="form-dialog-title">Add {formTitle} </DialogTitle>
        <DialogContent>
          {masterMenuFormFieldAndData &&
            masterMenuFormFieldAndData.dynamicFormFieldsList && (
              <MasterMenuFormComponent
                masterMenuFormFieldAndData={masterMenuFormFieldAndData}
                formTitle={formTitle}
                formKey={formKey}
                editFormValues={editFormValues}
                isEdit={isEdit}
                handleCloseDilogue={handleCloseDilogue}
                userId={userId}
              ></MasterMenuFormComponent>
            )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MasterMenuComponent;
