import React, { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Link, Tooltip, Box } from "@material-ui/core";
import { SectionLoader } from "../../components/common/section-loader.component";
import { actionFetchMasterAreaOfWorkToolData } from "containers/master/actions";
import { setLoaderVisibility } from "store/common/actions";
import MaterialTable from "material-table";
import { commonCellStyle, commonHeaderStyle } from "styles/materialStyles";

import {
    FullWidthContainer,
    BoxShadowWrapper,
    AssessmentSubHeader,
    RecommendedToolsSection,
    AssessmentOverviewSubTable
} from "../../styles/common";
import { calculateMaturityModelIndex, calculateMaxWeightedScore } from "utils/data";
import { ASSESSMENT_TYPE_CONST } from "constants/data";

function AssessmentOverviewTools(props: any) {
    const { checkAssessmentStatusLabel, canShowOverview } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const colorRed: any = "red"
    const colorGray: any = "grey"
    const colorLightRed: any = "lightRed"
    const colorOrange: any = "orange"
    const colorLightGreen: any = "lightGreen"
    const colorGreen: any = "green"

    const assessmentLevelDetailData = useSelector(
        (state: any) => state.assesmentCreate.assessmentLevelDetailData
    );

    const assesmentDetailSavedData = useSelector(
        (state: any) => state.assesmentCreate.assesmentDetailSavedData
    );

    const isSectionLoaderVisible = useSelector(
        (state: any) => state.common.isSectionLoaderVisible
    );

    const masterAreaOfWorkToolData = useSelector(
        (state: any) => state.masterForm.masterAreaOfWorkToolData
    );

    const languageSelected = useSelector(
        (state: any) => state.header.languageSelected
    );

    const maturityIndexData = useSelector(
        (state: any) => state.masterForm.mastermaturityIndexData
    );

    const assessmentTypeFilter = useSelector(
        (state: any) => state.header.assessmentTypeFilter
    );
    const [assessmentScoring, setassessmentScoring] = useState([]);
    const [assessmentType, setassessmentType] = useState(null);
    const [selectedAssessmentCode, setSelectedAssessmentCode] = useState("");
    const [selectedSupplyChain, setSelectedSupplyChain] = useState("");
    const [selectedScope, setSelectedScope] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedAssessmentStatus, setSelectedAssessmentStatus] = useState("");
    const [selectedAssessmentRegion, setSelectedAssessmentRegion] = useState("");
    const [maturityIndexValue, setMaturityIndexValue]: any = useState(0);
    const [maturityIndexDataTable, setMaturityIndexDataTable] = useState([]);
    const [totalMaxWeightedScore, setTotalMaxWeightedScore] = useState(0);
    const [selectedSubAreaOfWorkName, setSelectedSubAreaOfWorkName] = useState("");
    const [selectedAreaOfWorkName, setSelectedAreaOfWorkName] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [toolList, setToolList] = useState([]);
    const [gridToolData, setGridToolData]: any = useState([]);
    const [firstTimeCall, setFirstTimeCall] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [showRecommendedTools, setShowRecommendedTools] = useState({ isOpen: false });

    const userLocalData: any = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
    );

    useEffect(() => {
        if (assessmentLevelDetailData) {
            setassessmentScoring(assessmentLevelDetailData.assessmentScoring);
            setassessmentType(assessmentLevelDetailData.assessmentData.assesementType);
            setShowTable(true);
            setFirstTimeCall(false);

            if (maturityIndexData) {
                let maturyIndexDataTable: any = [];
                let totalMaxWeightedScore: any = 0;

                maturityIndexData.forEach((maturityRow: any) => {
                    const areaOfWork = assessmentLevelDetailData.assessmentScoring.filter((item: any) => {
                        return item.areaOfWorkId === maturityRow.areaId
                    })

                    if (areaOfWork.length && areaOfWork[0].score != 0) {
                        maturityRow.mmScore = areaOfWork[0].score;
                        maturityRow.maxWeightScore = calculateMaxWeightedScore(
                            maturityRow.maxScore,
                            maturityRow.weight
                        );

                        totalMaxWeightedScore += maturityRow.maxWeightScore;
                        maturyIndexDataTable.push(maturityRow);
                    }
                });


                setTotalMaxWeightedScore(totalMaxWeightedScore);
                setMaturityIndexDataTable(maturyIndexDataTable);
            }
        }
    }, [assessmentLevelDetailData])

    useEffect(() => {
        if (assesmentDetailSavedData) {
            setAssesmentEditLable(assesmentDetailSavedData);
        }
    }, [assesmentDetailSavedData]);

    useEffect(() => {
        if (maturityIndexDataTable.length) {
            let maturitIndexValue: any = 0;
            maturityIndexDataTable.map((item: any) => {
                maturitIndexValue += calculateMaturityModelIndex(
                    item.mmScore,
                    item.weight,
                    totalMaxWeightedScore
                );
            });
            setMaturityIndexValue(maturitIndexValue.toFixed(1));
        } else {
            setMaturityIndexValue(0);
        }
    }, [maturityIndexDataTable]);

    useEffect(() => {
        dispatch(actionFetchMasterAreaOfWorkToolData(-1, userLocalData.userSelectedRole));
    }, [dispatch]);

    useEffect(() => {
        if (masterAreaOfWorkToolData.length) {
            let toolList: any = [];
            toolList = masterAreaOfWorkToolData.filter((x: any) => x.language == languageSelected)
            setGridToolData(toolList);
            dispatch(setLoaderVisibility(false));
        }
    }, [masterAreaOfWorkToolData]);

    useEffect(() => {
        if (masterAreaOfWorkToolData.length && firstTimeCall === false) {
            let toolList: any = [];
            toolList = masterAreaOfWorkToolData.filter((x: any) => x.language == languageSelected)
            setGridToolData(toolList);
            dispatch(setLoaderVisibility(false));
        }
    }, [languageSelected]);

    const setAssesmentEditLable = (data: any) => {
        setSelectedAssessmentCode(data.assessmentCode);
        setSelectedAssessmentStatus(data.assessmentStatusId);
        setSelectedSupplyChain(data.supplyChain);
        setSelectedScope(data.scope);
        setSelectedRegion(data.region);
        setSelectedCountry(data.country);
        setSelectedAssessmentRegion(data.countryHierarchyName);
    };

    const getBackGroundColor = (level: any, score: any, isNA: boolean) => {

        let colorClass: string = "";
        let levelNo = 0;
        if (score == 0 && isNA == true) {
            colorClass = colorGray;
        }
        else if (score >= 1 && score < 2) {
            colorClass = colorRed;
            levelNo = 1;
        }
        else if (score >= 2 && score < 3) {
            colorClass = colorLightRed;
            levelNo = 2;
        }
        else if (score >= 3 && score < 4) {
            colorClass = colorOrange;
            levelNo = 3;
        }
        else if (score >= 4 && score < 5) {
            colorClass = colorLightGreen;
            levelNo = 4;
        }
        else if (score == 5) {
            colorClass = colorGreen;
            levelNo = 5;
        }

        return level == 0 || level == levelNo ? colorClass : "";
    };


    const handleSubAreaOfWorkTools = (areaOfWork: any, subAreaOfWorkItem: any) => {
        let toolList: any = [];
        if (subAreaOfWorkItem) {
            setSelectedSubAreaOfWorkName(subAreaOfWorkItem.subAreaOfWork);
            setSelectedAreaOfWorkName(areaOfWork);
            setSelectedRowId(subAreaOfWorkItem.subAreaOfWorkId);
            setShowRecommendedTools((state: any) => ({ id: subAreaOfWorkItem.subAreaOfWorkId, isOpen: state.id === subAreaOfWorkItem.subAreaOfWorkId ? !state.isOpen : true }));

            gridToolData.map((item: any, index: any) => {
                if (item.subAreaOfWorkId == subAreaOfWorkItem.subAreaOfWorkId) {
                    toolList.push(item);
                }
            });
            setToolList(toolList);
        }
    };

    const createTableHeader = () => {
        return (
            <>
                <AssessmentSubHeader>
                    <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        className="padding20-15"
                    >
                        <Grid item>
                            <Typography component="strong" className="strong-text">
                                {`${t("common.assessmentCode")}: `}
                            </Typography>
                            <Typography component="span">{selectedAssessmentCode}</Typography>
                        </Grid>
                        {
                            assessmentType !== ASSESSMENT_TYPE_CONST.VC && (
                                <Grid item className="region-info-text">
                                    <Typography component="strong" className="strong-text">
                                        {selectedRegion}
                                        {"  |  "} {selectedCountry}
                                        {"  |  "} {selectedSupplyChain}
                                        {"  |  "} {selectedScope}
                                        {selectedAssessmentRegion ? "  |  " : ""}
                                        {selectedAssessmentRegion}
                                        {"  |  "}{!assessmentTypeFilter
                                            ? t("common.normal")
                                            : t("common.resiliency")}
                                    </Typography>
                                </Grid>
                            )
                        }
                        <Grid item>
                            <Typography component="strong" className="strong-text">
                                {`${t("common.maturityIndex")}: `}
                            </Typography>
                            <Typography component="span">{maturityIndexValue}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="strong" className="strong-text">
                                {`${t("common.status")}: `}
                            </Typography>
                            <Typography component="span">
                                {checkAssessmentStatusLabel(selectedAssessmentStatus)}
                            </Typography>
                        </Grid>
                    </Grid>
                </AssessmentSubHeader>
            </>
        );
    };


    return (
        <FullWidthContainer className="margin-top-20">
            <SectionLoader visible={isSectionLoaderVisible} />
            {
                canShowOverview && showTable && assessmentScoring && (
                    <>
                        <BoxShadowWrapper>
                            {createTableHeader()}
                            <Grid container justify="center">
                                <Grid item md={12} lg={10} xl={8}>
                                    <Box p={2} className="border-blue">
                                        <Typography component="strong" className="font-size-12 strong-text">{`${t("common.note")}: `} </Typography>
                                        <Typography component="span" className="font-size-12">
                                            {t("assessment.toolsNote")}
                                        </Typography>
                                    </Box>
                                    <Typography component="div" className="position-relative overflow-hidden">
                                        <Grid container>
                                            <Grid item md={12}>
                                                <AssessmentOverviewSubTable style={{ minHeight: "550px" }}>
                                                    <Grid container className="overview-header border-bottom-blue">
                                                        <Grid item md={2} className="border-right-blue">
                                                            <Typography component={"span"}>{""}</Typography>
                                                        </Grid>
                                                        <Grid item md={10} className="">
                                                            <Grid container>
                                                                <Grid item md={6} className="">
                                                                    <Typography component={"span"}>{""}</Typography>
                                                                </Grid>
                                                                <Grid item md={1} className="">
                                                                    <Typography component={"span"}>{""}</Typography>
                                                                </Grid>
                                                                <Grid item md={1} className="">
                                                                    <Typography component="div" className="text-center padding5">
                                                                        <Typography component={"p"}>1</Typography>
                                                                        <Typography component={"p"}>{t("common.adhoc")}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={1} className="">
                                                                    <Typography component="div" className="text-center padding5">
                                                                        <Typography component={"p"}>2</Typography>
                                                                        <Typography component={"p"}>{t("common.weak")}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={1} className="">
                                                                    <Typography component="div" className="text-center padding5">
                                                                        <Typography component={"p"}>3</Typography>
                                                                        <Typography component={"p"}>{t("common.ok")}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={1} className="">
                                                                    <Typography component="div" className="text-center padding5">
                                                                        <Typography component={"p"}>4</Typography>
                                                                        <Typography component={"p"}>{t("common.good")}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={1}>
                                                                    <Typography component="div" className="text-center padding5">
                                                                        <Typography component={"p"}>5</Typography>
                                                                        <Typography component={"p"}>{t("common.excellent")}</Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        assessmentScoring.map((areaOfWorkItem: any) => {
                                                            return (
                                                                <>
                                                                    <Grid container alignItems="stretch" className="border-bottom-blue ">
                                                                        <Grid item md={2} className="border-right-blue">
                                                                            <Typography component={"div"} className={"aow-position"}>
                                                                                <Typography component="div" className="txt-aow-name padding10"> {areaOfWorkItem.areaOfWork} </Typography>
                                                                                <Typography component="div" className={getBackGroundColor(0, areaOfWorkItem.score.toFixed(1), areaOfWorkItem.isNA) + " txt-aow-count text-center  padding10 "}>
                                                                                    {areaOfWorkItem.isNA ? t("common.na") : areaOfWorkItem.score.toFixed(1)}
                                                                                </Typography>
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item md={10}>
                                                                            {
                                                                                areaOfWorkItem.subAreaOfWorks.map((subAreaOfWorkItem: any) => {
                                                                                    return (
                                                                                        <Grid key={subAreaOfWorkItem.subAreaOfWorkId} container alignItems="center" style={selectedRowId === subAreaOfWorkItem.subAreaOfWorkId ? { backgroundColor: "rgba(0, 0, 0, 0.10)" } : { backgroundColor: "#FFF" }}>
                                                                                            <Grid item md={6}>
                                                                                                <Typography component={"div"} className="">
                                                                                                    <Tooltip title={`${t("common.showTools")}`}>
                                                                                                        <Link
                                                                                                            component="button"
                                                                                                            onClick={() => {
                                                                                                                handleSubAreaOfWorkTools(areaOfWorkItem.areaOfWork, subAreaOfWorkItem);
                                                                                                            }}
                                                                                                            className="saow-link-text padding10"
                                                                                                        >
                                                                                                            {subAreaOfWorkItem.subAreaOfWork}
                                                                                                        </Link>
                                                                                                    </Tooltip>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(0, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA) + " txt-score text-center padding10"}>
                                                                                                    {subAreaOfWorkItem.isNA ? t("common.na") : subAreaOfWorkItem.score.toFixed(1)}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(1, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA) + " text-center padding10"}>
                                                                                                    <Typography component="span" className="textV">{""}</Typography>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(2, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA) + " text-center padding10"}>
                                                                                                    <Typography component="span" className="textV">{""}</Typography>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(3, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA) + " text-center padding10"}>
                                                                                                    <Typography component="span" className="textV">{""}</Typography>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(4, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA) + " text-center padding10"}>
                                                                                                    <Typography component="span" className="textV" >{""}</Typography>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item md={1} className="score-val-col">
                                                                                                <Typography component={"div"} className={getBackGroundColor(5, subAreaOfWorkItem.score, subAreaOfWorkItem.isNA)}>
                                                                                                    <Typography component="span" className="textV" > </Typography>
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </AssessmentOverviewSubTable>
                                            </Grid>
                                        </Grid>
                                        <Typography component={"div"} className={showRecommendedTools.isOpen ? "recommended-toolbox show-tools" : "recommended-toolbox hide-tools"}>
                                            <Typography component="span" className="lblAoWName">
                                                {selectedAreaOfWorkName}
                                            </Typography>
                                            <Typography component="span" className="lblAoWName">
                                                {selectedSubAreaOfWorkName}
                                            </Typography>
                                            <Typography component="span" className="lblRecTool">
                                                {t("areaOfWorkTool.recommendedTools")}
                                            </Typography>
                                            <Typography component="div" className="RT_Table_Outer">
                                                <MaterialTable
                                                    columns={[
                                                        {
                                                            field: "name",
                                                            title: t("areaOfWorkTool.tool"),
                                                            cellStyle: commonCellStyle,
                                                            render: (tblProps: any) => {
                                                                return (
                                                                    <RecommendedToolsSection>
                                                                        <Typography component="span" className="RTName">
                                                                            {" "}
                                                                            {tblProps.name}
                                                                        </Typography>
                                                                        <Typography component="span" className="RTDesc">
                                                                            {" "}
                                                                            {tblProps.description}
                                                                        </Typography>
                                                                        <Typography component="span" className="RTLink">
                                                                            <Typography
                                                                                component="a"
                                                                                href={tblProps.link}
                                                                                target="_blank"
                                                                                className="text-navyblue"
                                                                            >
                                                                                {" "}
                                                                                {tblProps.link}
                                                                            </Typography>
                                                                        </Typography>
                                                                    </RecommendedToolsSection>
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                    data={toolList}
                                                    options={{
                                                        actionsColumnIndex: -1,
                                                        draggable: false,
                                                        search: false,
                                                        paging: false,
                                                        emptyRowsWhenPaging: false,
                                                        actionsCellStyle: commonCellStyle,
                                                        toolbar: false,
                                                        headerStyle: commonHeaderStyle,
                                                        header: false,
                                                        maxBodyHeight: 400,
                                                    }}
                                                    localization={{
                                                        body: {
                                                            filterRow: {
                                                                filterTooltip: t("pagination.filter"),
                                                            },
                                                            emptyDataSourceMessage: t("common.noToolsDataAvailable")
                                                        },
                                                        pagination: {
                                                            firstTooltip: t("pagination.firstPage"),
                                                            previousTooltip: t("pagination.prevPage"),
                                                            nextTooltip: t("pagination.nextPage"),
                                                            lastTooltip: t("pagination.lastPage"),
                                                            labelRowsSelect: t("common.rows"),
                                                        },
                                                    }}
                                                />
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </BoxShadowWrapper>
                    </>
                )
            }
        </FullWidthContainer >
    );

}

export default AssessmentOverviewTools;