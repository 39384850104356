import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { HomeFooter } from "styles/common";
import { getBuildDate } from "utils/data";
import packageJson from "../../../package.json";

function FooterComponent(props: any) {
  const { t } = useTranslation();
  const buildDate = `${t("landingPage.buildDate")}: ${getBuildDate(
    packageJson.buildDate
  )}`;
  return (
    <>
      <Typography component="div" className="text-center paddingTB20">
        <Typography
          component="span"
          className="copytight-text"
          dangerouslySetInnerHTML={{ __html: t("landingPage.copyRight") }}
          title={buildDate}
        />
      </Typography>
    </>
  );
}
export default FooterComponent;

export function HomeFooterComponent(props: any) {
  const { t } = useTranslation();

  return (
    <>
      <HomeFooter>
        <Typography
          component="span"
          className="copytight-text paddingB20"
          dangerouslySetInnerHTML={{ __html: t("landingPage.copyRight") }}
        />
      </HomeFooter>
    </>
  );
}
