import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, Controller } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledInputLabel } from "../../../styles/common";

const MuiSelect = (props: any) => {
  const {
    label,
    name,
    options,
    variant,
    required,
    errorobj,
    hideselectoption,
    optionsLabelKey
  } = props;
  const { t } = useTranslation();

  let isError = false;
  let errorMessage = "";
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }
  return (
    <FormControl fullWidth={true} variant={variant} error={isError}>
      <StyledInputLabel htmlFor={name} className="font-size-14">
        {label} {required ? <span className="req-label">*</span> : null}
      </StyledInputLabel>
      <Select id={name} {...props} className="font-size-14">
        {!hideselectoption && (
          <MenuItem value="" className="font-size-14">
            <em>{t("common.pleaseSelect")}</em>
          </MenuItem>
        )}
        {options.map((item: any) => (
          <MenuItem key={item.id} value={item.id} className="font-size-14">
            {optionsLabelKey ? item[optionsLabelKey] : item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className="font-size-14">{errorMessage}</FormHelperText>
    </FormControl>
  );
};

function FormSelect(props: any) {
  const { control } = useFormContext();
  const { name, label, defaultValue } = props;
  return (
    <React.Fragment>
      <Controller
        as={MuiSelect}
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        label={label}
        {...props}
      />
    </React.Fragment>
  );
}

export default FormSelect;
