import { UNICEF_BLUE, DARK_GRAY } from "../constants/color"

export const commonHeaderStyle:any = {
    backgroundColor: `${UNICEF_BLUE}`,
    color: `${DARK_GRAY}`,
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontSize: "14px",
    padding: "10px 10px",
};

export const commonCellStyle: any = {
    padding: "2px 10px",
    fontSize: "14px",
};

export const actionCellStyle: any = {
    padding: "2px 10px",
    fontSize: "14px",
};

export const maxWidthCellStyle: any = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}

export const headerStyleLighter = {
    backgroundColor: `${UNICEF_BLUE}`,
}

export const commonHeaderStyleDarker ={
    backgroundColor: "#c7eaf7",
}