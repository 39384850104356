import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import {
  getApplicationsList, getSCSUserDetails, postUserAccessReques, postUserApplicationClick,
} from "./api";
import {
  actionFetchApplicationsListSuccess, actionFetchSCSUserDetailsSuccess, actionPostAccessRequestSuccess,
} from "./actions";
import { setLoaderVisibility, openToaster, closeToaster } from "../store/common/actions";
import { API_ERROR_MESSAGE, ACCESS_REQUEST_SUCCESS } from "../store/common/message";
import { ActionTypes } from './constants';
import { COMMON_CONSTANT } from "constants/data";

function* watchGetApplicationListData() {
  yield takeLatest(
    ActionTypes.SCS_HUB_GET_APPLICATIONS_LIST,
    getApplicationListData
  );
}

function* getApplicationListData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getApplicationsList);
    const responseData = response.data;
    if (responseData) {
      yield put(actionFetchApplicationsListSuccess(responseData));
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetScsUserData() {
  yield takeLatest(
    ActionTypes.SCS_HUB_GET_USER_DETAILS,
    getScsUserData
  );
}

function* getScsUserData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getSCSUserDetails);
    const responseData = response.data;
    if (responseData) {
      yield put(actionFetchSCSUserDetailsSuccess(responseData));
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}


function* watchSendScsUserAccessRequest() {
  yield takeLatest(
    ActionTypes.SCS_HUB_POST_ACCESS_REQUEST,
    sendScsUserAccessRequest
  );
}

function* sendScsUserAccessRequest(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(postUserAccessReques, action.payload.data);
    const responseData = response.data;
    yield put(actionPostAccessRequestSuccess(responseData));
    if (responseData) {
      yield put(openToaster(ACCESS_REQUEST_SUCCESS(), true));
    } else {
      yield put(openToaster(responseData, false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}


function* watchSendScsUserApplicationLogHistory() {
  yield takeLatest(
    ActionTypes.SCS_HUB_POST_APP_LOG_HISTORY,
    sendScsUserApplicationLogHistory
  );
}

function* sendScsUserApplicationLogHistory(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    yield call(postUserApplicationClick, action.payload.data);
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

export default function* scsHubSaga() {
  yield all([
    watchGetApplicationListData(),
    watchGetScsUserData(),
    watchSendScsUserAccessRequest(),
    watchSendScsUserApplicationLogHistory()
  ]);
}
