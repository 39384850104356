import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { FullWidthContainer } from 'styles/common';
import { commonCellStyle, commonHeaderStyle } from 'styles/materialStyles';
import React, { useEffect, useState } from "react";

export default function ReportTableComponent(props: any) {
  const { reportChartData } = props;
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    let tempData: any = [];
    if (reportChartData && reportChartData.length) {
      reportChartData.forEach((assess: any) => {
        assess.assessmentChartList.forEach((area: any) => {
          const index: any = tempData.findIndex((d: any) => d.areaOfWorkId === area.areaOfWorkId);
          if (index !== -1) {
            tempData[index][`scoreValue_${assess.assessmentCode}`] = Number(area.currentScore.toFixed(1));
            tempData[index][`isNA_${assess.assessmentCode}`] = area.isNA;
          }
          else {
            let rowData = { ...area };
            rowData[`scoreValue_${assess.assessmentCode}`] = Number(area.currentScore.toFixed(1))
            rowData[`isNA_${assess.assessmentCode}`] = area.isNA;
            tempData.push(rowData)
          }
          area.subAreaOfWorks.forEach((sub: any) => {
            const index: any = tempData.findIndex((d: any) => d.subAreaOfWorkId === sub.subAreaOfWorkId);
            let subAreaScore: any = sub.isNA ? `${t("common.na")}` : 0;
            if (sub.score > 0)
              subAreaScore = Number(sub.score.toFixed(1));

            if (index !== -1) {
              tempData[index][`scoreValue_${assess.assessmentCode}`] = subAreaScore;
            }
            else {
              let rowData = { ...sub };
              rowData[`scoreValue_${assess.assessmentCode}`] = subAreaScore;
              tempData.push(rowData)
            }
          })
        })
      })
      setData(tempData);
      setColumns();
    }
  }, [reportChartData]);

  const setColumns = (): any => {
    const columns: any = [
      {
        title: t("common.areaOfWork"),
        field: "areaOfWork",
        sorting: false,
        cellStyle: {
          ...commonCellStyle,
          padding: "10px 10px",
        }
      },
      {
        title: t("common.subAreaOfWork"),
        field: "subAreaOfWork",
        sorting: false,
        cellStyle: {
          ...commonCellStyle,
          padding: "10px 10px",
        }
      }
    ]

    reportChartData.forEach((assess: any) => {
      columns.push({
        title: assess.assessmentCode,
        field: `scoreValue_${assess.assessmentCode}`,
        render: (rowData: any) => {
          return rowData[`isNA_${assess.assessmentCode}`] ? `${t("common.na")}` : rowData[`scoreValue_${assess.assessmentCode}`]
        },
        sorting: false,
        cellStyle: {
          ...commonCellStyle,
          textAlign: "center",
        },
        headerStyle: {
          textAlign: "center",
        }
      })
    })
    setHeaders(columns);
  };

  return (
    <FullWidthContainer className="reports-table">
      <MaterialTable
        columns={headers}
        data={data}
        options={{
          filtering: false,
          pageSizeOptions: [20, 50, 100, 150],
          pageSize: 20,
          emptyRowsWhenPaging: false,
          showTitle: false,
          actionsColumnIndex: -1,
          draggable: false,
          search: false,
          toolbar: false,
          headerStyle: commonHeaderStyle,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.areaOfWork && "#f3f3f3",
          }),
        }}
        localization={{
          body: {
            filterRow: {
              filterTooltip: t("pagination.filter"),
            },
            emptyDataSourceMessage: t("common.noDataAvailable"),
          },
          pagination: {
            firstTooltip: t("pagination.firstPage"),
            previousTooltip: t("pagination.prevPage"),
            nextTooltip: t("pagination.nextPage"),
            lastTooltip: t("pagination.lastPage"),
            labelRowsSelect: t("common.rows"),
          },
        }}
      />
    </FullWidthContainer>
  )
}
