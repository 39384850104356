import { all } from "redux-saga/effects";
import allRequestSaga from "../containers/all-requests/sagas";
import masterFormSaga from "../containers/master/sagas";
import headerSaga from "../containers/header/sagas";
import commonSaga from "../../src/store/common/sagas";
import questionSaga from "../containers/question-set/sagas";
import userProfileSaga from "../containers/user-profile/sagas";
import assesementCreateSaga from "../containers/assessment-create/sagas";
import adminRsaSaga from "containers/admin-rsa-overview/sagas";
import scsHubSaga from "../scs-hub/sagas"
import vcAssessmentSaga from "containers/vc-assessment/sagas";
export default function* rootSaga() {
  yield all([
    allRequestSaga(),
    commonSaga(),
    masterFormSaga(),
    headerSaga(),
    questionSaga(),
    userProfileSaga(),
    assesementCreateSaga(),
    adminRsaSaga(),
    scsHubSaga(),
    vcAssessmentSaga()
    // sagas will come here
  ]);
}
