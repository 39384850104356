export const ActionTypes = {
  ASSESMENT_CREATE_FETCH_ASSEMENT_DATA: "ASSESMENT_CREATE_FETCH_ASSEMENT_DATA",

  FETCH_ALL_ASSESSMENT_DETAILS: "FETCH_ALL_ASSESSMENT_DETAILS",
  FETCH_ALL_ASSESSMENT_DETAILS_SUCCESS: "FETCH_ALL_ASSESSMENT_DETAILS_SUCCESS",

  ASSESMENT_CREATE_FETCH_ASSEMENT_DATA_SUCCESS:
    "ASSESMENT_CREATE_FETCH_ASSEMENT_DATA_SUCCESS",

  ASSESMENT_CREATE_SAVE_ASSESMENT_DATA: "ASSESMENT_CREATE_SAVE_ASSESMENT_DATA",
  ASSESMENT_CREATE_SAVE_ASSESMENT_DATA_SUCCESS:
    "ASSESMENT_CREATE_SAVE_ASSESMENT_DATA_SUCCESS",

  ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA:
    "ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA",
  ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA_SUCCESS:
    "ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA_SUCCESS",

  ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA:
    "ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA",
  ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA_SUCCESS:
    "ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA_SUCCESS",

  ASSESMENT_DETAILS_SAVED_DATA: "ASSESMENT_DETAILS_SAVED_DATA",

  ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA:
    "ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA",
  ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA_SUCCESS",

  ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA:
    "ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA",
  ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA_SUCCESS:
    "ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA:
    "ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA",
  ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA_SUCCESS",

  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA",
  ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA_SUCCESS:
    "ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA_SUCCESS",

  ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA:
    "ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA",
  ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA_SUCCESS:
    "ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA_SUCCESS",
  SAVE_CLICKED_STATUS: "SAVE_CLICKED_STATUS",

  ASSESMENT_DYNAMIC_LEVEL_STATUS_DATA: "ASSESMENT_DYNAMIC_LEVEL_STATUS_DATA",
  ASSESMENT_DYNAMIC_LEVEL_STATUS_DATA_SUCCESS:
    "ASSESMENT_DYNAMIC_LEVEL_STATUS_DATA_SUCCESS",

  ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID: "ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID",
  ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID_SUCCESS: "ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID_SUCCESS",

  ASSESSMENT_REPORT_DATA: "ASSESSMENT_REPORT_DATA",
  ASSESSMENT_REPORT_SUCCESS: "ASSESSMENT_REPORT_SUCCESS",

  ASSESSMENT_REPORT_REGION_DATA: "ASSESSMENT_REPORT_REGION_DATA",
  ASSESSMENT_REPORT_REGION_SUCCESS: "ASSESSMENT_REPORT_REGION_SUCCESS",

  ASSESSMENT_OVERVIEW_AV_DATA: "ASSESSMENT_OVERVIEW_AV_DATA",
  ASSESSMENT_DATA_DELETE_BY_ID: "ASSESSMENT_DATA_DELETE_BY_ID",

  SHOW_ASSESSMENT_TABLE: "SHOW_ASSESSMENT_TABLE",

  ASSESSMENT_SCORING_DATA: "ASSESSMENT_SCORING_DATA",

  ALL_ASSESSMENT_OVERVIEW_DATA: "ALL_ASSESSMENT_OVERVIEW_DATA",
  ASSESSMENT_SCORING_DATA_SUCCESS: "ASSESSMENT_SCORING_DATA_SUCCESS"
};

export const GET_ASSESSMENT_CREATE_DETAILS: string = `/api/Assessment/GetAssessmentDetails`;
export const SAVE_ASSESSMENT_CREATE_DETAILS: string = `/api/Assessment/SaveAssessment`;
export const GET_ASSESSMENT_BY_ASSESSMENT_ID_DETAILS: string = `/api/Assessment/GetDynamicAssessment`;
export const UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS: string = `/api/Assessment/UpdateAssessmentDetailsValidationStatus`;
export const UPDATE_ASSESMENT_QUESTION_DETAIL: string = `/api/Assessment/UpdateAssessmentDetails`;
export const UPDATE_ASSESMENT_QUESTION_COMMENT: string = `/api/Assessment/UpdateAssessmentAreaLevelComment`;
export const GET_ASSESSMENT_LEVEL_DETAILS: string = `/api/Assessment/GetDynamicAssessmentLevelStatus`;
export const GET_ASSESSMENT_VALIDATION_STATUS: string = `/api/Assessment/GetValidationStatus`;
export const GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA: string = `/api/Assessment/GetValidationComment`;
export const UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA: string = `/api/Assessment/SaveAssessmentDetailsComment`;
export const UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA: string = `/api/Assessment/ResetAssessmentValidationStatus`;
export const UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA: string = `/api/Assessment/ResetAssessmentIsCheckStatus`;
export const GET_EXPORT_ASSESMENT_DETAILS: string = `/api/Assessment/ExportAssessmentDetails`;
export const GET_ASSESSMENT_CURRENT_SCORE_BY_PROGRAM_ID_DETAILS: string = `/api/Assessment/GetDynamicAssessmentLevelWiseScoreList`;
export const GET_ASSESSMENT_REPORT: string = `/api/Assessment/GetAssessmentChartList`;
export const GET_ASSESSMENT_REGION_REPORT: string = `/api/Assessment/GetAssessmentChartRegionWiseList`;
export const DELETE_ASSESSMENT_BY_ID: string = `/api/Assessment/Delete`;
export const GET_ASSESSMENT_SCORING: string = `/api/Assessment/GetAssessmentScoreList`;