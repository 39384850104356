import { Container, Grid, Typography } from "@material-ui/core";
import FormInput from "components/form-controls/input";
import FormSelect from "components/form-controls/select";
import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  BoxShadowWrapper,
  DialogButtonPrimary,
  SubHeaderWrapper,
} from "styles/common";
import {
  actionFetchMasterMaturityIndexData,
  actionUpdateMasterMaturityIndexData,
} from "../actions";
import "../index.css";
import { useTranslation } from "react-i18next";
import { PRIORITY_LIST } from "constants/data";
import { setLoaderVisibility } from "store/common/actions";

function MaturityIndexValueComponent(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm();
  const { setValue } = methods;
  const maturityIndexData = useSelector(
    (state: any) => state.masterForm.mastermaturityIndexData
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );

  const [updatedDataArray, setUpdatedDataArray] = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);

  useEffect(() => {
    if (firstTimeCall) {
      dispatch(setLoaderVisibility(true));
      dispatch(actionFetchMasterMaturityIndexData(-1));
      setFirstTimeCall(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (maturityIndexData.length) {
      setValueOfList();
      dispatch(setLoaderVisibility(false));
    }
  }, [maturityIndexData]);

  useEffect(() => {
    if (firstTimeCall === false) {
      dispatch(setLoaderVisibility(true));
      dispatch(actionFetchMasterMaturityIndexData(-1));
    }
  }, [languageSelected]);

  const handelUpdateMaturityData = (id: any, type: any) => ([event]: any) => {
    let tempArr: any = [];
    maturityIndexData.map((areaOfWork: any) => {
      if (areaOfWork.id === id) {
        if (type === "weight") {
          areaOfWork.weight = parseFloat(event.target.value);
        } else {
          areaOfWork.priority = parseInt(event.target.value);
        }
      }
      tempArr.push(areaOfWork);
      setUpdatedDataArray(tempArr);
    });

    return event.target.value;
  };

  const handelSubmit = () => {
    if (updatedDataArray.length) {
      dispatch(actionUpdateMasterMaturityIndexData(updatedDataArray));
    }
  };

  const setValueOfList = () => {
    maturityIndexData.map((item: any) => {
      setValue(`priority_${item.id}`, item.priority);
      setValue(`weight_${item.id}`, item.weight);
      setValue(`maxScore_${item.id}`, item.maxScore);
    });
  };

  const listAreaOfWork = () => {
    return (
      <>
        <FormContext {...methods}>
          <Grid
            container
            alignItems="flex-start"
            justify="flex-start"
            className="padding0-8"
            spacing={0}
          >
            {maturityIndexData.map((item: any, index: any) => (
              <Grid
                container
                item
                alignItems="center"
                spacing={2}
                key={index}
                className="padding8-16 maturity-index-list-body"
              >
                <Grid item sm={6}>
                  <Typography component="span">{item.areaName}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <FormSelect
                    name={`priority_${item.id}`}
                    options={PRIORITY_LIST}
                    onChange={handelUpdateMaturityData(item.id, "priorty")}
                  />
                </Grid>
                <Grid item sm={3}>
                  <FormInput
                    type="number"
                    name={`weight_${item.id}`}
                    className=""
                    onChange={handelUpdateMaturityData(item.id, "weight")}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </FormContext>
      </>
    );
  };

  return (
    <Container maxWidth={"sm"}>
      <SubHeaderWrapper>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item>
            <Typography component="span" className="common-label">
              {t("common.maturityIndex")}
            </Typography>
          </Grid>
        </Grid>
      </SubHeaderWrapper>
      <BoxShadowWrapper>
        <Grid
          container
          alignItems="flex-start"
          justify="flex-start"
          className="padding16-24 maturity-index-list-header"
        >
          <Grid container item spacing={2}>
            <Grid item sm={6}>
              <Typography component="strong" className="strong-text">
                {t("common.areaOfWork")}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography component="strong" className="strong-text">
                {t("maturityIndex.priority")}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography component="strong" className="strong-text">
                {t("maturityIndex.weight")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {listAreaOfWork()}
        <Grid
          container
          alignItems="center"
          justify="flex-end"
          className="padding16-24"
        >
          <Grid item>
            <DialogButtonPrimary
              variant="contained"
              color="primary"
              type="submit"
              onClick={handelSubmit}
            >
              {t("common.save")}
            </DialogButtonPrimary>
          </Grid>
        </Grid>
      </BoxShadowWrapper>
    </Container>
  );
}

export default MaturityIndexValueComponent;
