import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("8080808080808080");
const iv = CryptoJS.enc.Utf8.parse("8080808080808080");

export const encryptData = (data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(data),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return encryptedData.toString();
};

export const decryptData = (encryptedData: any) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const orgData = decryptedData.toString(CryptoJS.enc.Utf8);
  return orgData;
};

export const htmlTagsPatternRegex  = /(&nbsp;|<([^>]+)>)/ ;
export const onlyAlphabetsRegex = /^[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/;

export const testInputValueWithRegex = (value:any) => {
    return htmlTagsPatternRegex.test(value)
}

export const testOnlyAlphabetsRegex = (value:any) => {
    return onlyAlphabetsRegex.test(value)
}
