import api from "../../utils/api";
import {
  GET_AUTHENTICATION_STATUS,
  GET_SCS_USER_ACCESS_REQUEST,
  GET_USER_PROFILE_DETAILS,
  POST_SCS_USER_PROCESS_REQUEST,
  POST_USER_PROFILE_DETAILS,
  POST_USER_PROFILE_SETTING,
} from "./constants";

/**
 * Function for getting some data
 */
export const fetchUserProfileData = (lookUp: any) => {
  return api.get(`${GET_USER_PROFILE_DETAILS}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveUserProfileData = async (userProfileData: any) => {
  const response = await api.post(`${POST_USER_PROFILE_DETAILS}`, userProfileData);
  return response;
};

export const saveUserProfileSettingData = (userSetting: any) => {
  return api.post(`${POST_USER_PROFILE_SETTING}`, userSetting).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const featchUserAutheticationStatus = async () => {
  const response = await api.get(`${GET_AUTHENTICATION_STATUS}`);
  return response;
};

export const featchUserSCSaccessRequest = async () => {
  const response = await api.get(`${GET_SCS_USER_ACCESS_REQUEST}`);
  return response;
};

export const postUserProcessAccessRequest = async (userData: any) => {
  const response = await api.post(`${POST_SCS_USER_PROCESS_REQUEST}`, userData,);
  return response;
};