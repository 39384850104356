import { ActionTypes } from "./constants";
import { ICommonState } from "./common-state-interface";
import { ASSESSMET_APPLICATION_STATUS } from "../../constants/data";
const initialState: ICommonState = {
  pageTitle: "",
  countryData: [],
  roleData: [],
  isLoaderVisible: false,
  isSectionLoaderVisible: false,
  isSuccess: false,
  isToasterVisible: false,
  toasterMessage: "",
  userDetails: {},
  dialogTitle: "",
  isDialogVisible: false,
  isUserLoggedIn: false,
  browserTitle: ASSESSMET_APPLICATION_STATUS,
  listUnicefRegion: [],
  listUnicefCountry: [],
  listWhoRegion: [],
  listWhoCountry: [],
  listProgram: [],
  listScope: [],
  listAreaOfWork: [],
  listSubAreaOfWork: [],
  listOrgWiseRegion: [],
  listOrgWiseCountry: [],
  listValidationStatus: [],
  currentRoute: window.location.pathname,
  userAssignedPrograms:[]
};

export const commonReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.COMMON_SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload.data,
      };
    case ActionTypes.COMMON_GET_USER_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        roleData: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_LOADER_VISIBILITY:
      return {
        ...state,
        isLoaderVisible: action.payload,
      };
    case ActionTypes.COMMON_SET_SECTION_LOADER_VISIBILITY:
      return {
        ...state,
        isSectionLoaderVisible: action.payload,
      };
    case ActionTypes.COMMON_SET_TOASTER_VISIBILITY:
      return {
        ...state,
        isSuccess: action.payload.success,
        isToasterVisible: action.payload.visible,
        toasterMessage: action.payload.data,
      };
    case ActionTypes.COMMON_SET_DIALOG_VISIBILITY:
      return {
        ...state,
        isDialogVisible: action.payload.visible,
        dialogTitle: action.payload.data,
      };
    case ActionTypes.COMMON_SUCCESS_GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case ActionTypes.COMMON_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.payload.status,
      };
    case ActionTypes.COMMON_SET_BROWSER_TITLE:
      return {
        ...state,
        browserTitle: action.payload.data,
      };
    case ActionTypes.COMMON_SET_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: action.payload.data || window.location.pathname,
      };
    case ActionTypes.COMMON_FETCH_COUNTRY_DATA_SUCCESS:
      return {
        ...state,
        countryData: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_UNICEF_REGION:
      return {
        ...state,
        listUnicefRegion: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_UNICEF_COUNTRY:
      return {
        ...state,
        listUnicefCountry: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_WHO_REGION:
      return {
        ...state,
        listWhoRegion: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_WHO_COUNTRY:
      return {
        ...state,
        listWhoCountry: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_ORG_WISE_REGION:
      return {
        ...state,
        listOrgWiseRegion: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_ORG_WISE_COUNTRY:
      return {
        ...state,
        listOrgWiseCountry: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_PROGRAM:
      return {
        ...state,
        listProgram: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_SCOPE:
      return {
        ...state,
        listScope: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_AREA_OF_WORK:
      return {
        ...state,
        listAreaOfWork: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_SUB_AREA_OF_WORK:
      return {
        ...state,
        listSubAreaOfWork: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_VALIDATION_STATUS_LIST:
      return {
        ...state,
        listValidationStatus: action.payload.data || [],
      };
    case ActionTypes.COMMON_SET_USER_ASSIGNED_PROGRAMS:
      return {
        ...state,
        userAssignedPrograms: action.payload.data || [],
      };
    default:
      return state;
  }
};
