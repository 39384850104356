import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.css";
import { actionHeaderUserSetting } from "../header/actions";
import { Container, Grid, Typography, Button, Box, Tooltip } from "@material-ui/core";
import AdminRsaOverviewTabComponent from "./admin-rsa-overview-tab";
import {
  actionFetchAssesmentLevelByIdData,
  actionDetailsSaveData,
  actionExportAssesmentData,
  actionAssessmentScoringDataSuccess,
  actionAllAssessmentOverviewData,
} from "containers/assessment-create/actions";
import { SubHeaderWrapper, DownloadIconCustom, CustomDropdown, } from "styles/common";
import {
  actionFetchAdminAllAssesmentStatusCount,
  actionFetchAdminAllAssesmentStatusCountSuccess,
  actionFetchAdminAssessmentData,
  actionFetchAdminAssessmentDataSuccess,
  actionSetAdminMapCountryAvgMIData,
  actionSetAdminMapRegionAvgMIData,
} from "./actions";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, USER } from "constants/data";
import {
  calculateMaxWeightedScore,
  calculateMaturityModelIndex,
} from "utils/data";
import { FormContext, useForm } from "react-hook-form";
import FormSelect from "components/form-controls/select";
import { SectionLoader } from "components/common/section-loader.component";

const MapComponent = React.lazy(() => import('./map'));

function AdminRSAOverview(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const methods = useForm();
  const { setValue, watch } = methods;
  const { selectedProgramFilter } = watch();

  const allPrograms: any = (window as any).allPrograms;

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );
  const userSelectedData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const roleData = useSelector((state: any) => state.common.roleData);
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const adminAssesmentData = useSelector(
    (state: any) => state.admin.adminAssesmentData
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const screenWidth = window.screen.width;
  const assessmentScoringData = useSelector(
    (state: any) => state.assesmentCreate.assessmentScoringData
  );

  const maturityIndexData = useSelector(
    (state: any) => state.masterForm.mastermaturityIndexData
  );

  const isSectionLoaderVisible = useSelector(
    (state: any) => state.common.isSectionLoaderVisible
  );

  const listPrograms = useSelector((state: any) => state.common.listProgram);

  const miRegionData = useSelector((state: any) => state.admin.miRegionData);
  const miCountryData = useSelector((state: any) => state.admin.miCountryData);

  const [currentAssessmentId, setCurrentAssessmentId] = useState(null);
  const [userRoleData, setUserRoleData]: any = useState(null);
  const [allAssessmentCount, setAllAssessmentCount] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [scale, setScale] = useState(150);
  const [showTabs, setShowTabs] = useState(false);
  const [userOrg, setUserOrg] = useState("");
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [programList, setProgramList] = useState([{ id: "0", label: t("common.all") }]);

  const allScopeData: any = (window as any).allScopeData;
  let tempRegionMi: number = 0;
  let totalCountry: number = 0;
  let countryMiArray: any = [];

  useEffect(() => {
    const tempProgramList = listPrograms.filter((prog: any) => {
      const data = allPrograms.filter((a:any)=>a.id===prog.id);
      if(data.length===0)
        return prog;
    })||[];
    const selectAllOption = { id: "0", label: t("common.all") };
    tempProgramList.splice(0, 0, selectAllOption);
    setProgramList(tempProgramList);
  }, [listPrograms]);

  useEffect(() => {
    if (screenWidth > 1024) {
      setScale(150);
    } else if (screenWidth > 768 && screenWidth < 1025) {
      setScale(125);
    } else if (screenWidth <= 768) {
      setScale(90);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(actionAssessmentScoringDataSuccess([]));
      dispatch(actionFetchAdminAssessmentDataSuccess([]));
      dispatch(actionFetchAdminAllAssesmentStatusCountSuccess([]));
    };
  }, []);

  useEffect(() => {
    if (userSelectedData) {
      setUserRoleDataFun(userSelectedData.userSelectedData);
    } else if (localStorage.getItem("userSelectedData") && firstTimeCall) {
      const userSelectedData: any = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
      );
      setUserRoleDataFun(userSelectedData);
    }
  }, [userSelectedData, roleData, userRoleData]);

  const setUserRoleDataFun = (userSelectedData: any) => {
    setSelectedRole("");
    const tempRoleData = roleData.filter(
      (role: any) => role.id === userSelectedData.userSelectedRole
    );
    if (tempRoleData.length) {
      setSelectedRole(tempRoleData[0].name.trim());
    }
    setFirstTimeCall(false);
    setUserRoleData(userSelectedData);
    setUserOrg(userSelectedData.organization);
  };

  useEffect(() => {
    if (userRoleData && userRoleData.userSelectedRole !== null && roleData && listOrgWiseCountry.length) {
      const userRole = roleData.filter(
        (role: any) => role.id === userRoleData.userSelectedRole
      );
      let userRequestData = {
        organization: userRoleData.organization,
        country: listOrgWiseCountry,
        isAdmin: true,
        programId: null,
        assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      };
      if (
        userRole.length &&
        (userRole[0].name.toLowerCase().trim() ===
          USER.ROLE.SD_ADMIN.toLowerCase() || userRole[0].name.toLowerCase().trim() ===
          USER.ROLE.GLOBAL_ADVISOR.toLowerCase())
      ) {
        setIsUserAdmin(true);
      } else {
        setIsUserAdmin(false);
        let userCountry = listOrgWiseCountry.filter((country: any) => country.region == userRoleData.userSelectedRegion[0])
        userRequestData = {
          organization: userRoleData.organization,
          isAdmin: false,
          country: userCountry,
          programId: null,
          assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        };
      }

      const assessmentRequestData = {
        country: userRoleData.userSelectedCountry,
        status: ASSESSMENT.STATUS.VALIDATED,
        scopeId: allScopeData[0].id,
        assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      };
      const adminAssessmentRequestData = {
        "countryIds": userRoleData.userSelectedCountry,
        "roleId": userRoleData.userSelectedRole,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      };

      const reponseData = {
        assessmentRequestData: assessmentRequestData,
        userRequestData: userRequestData,
        adminAssessmentRequestData: adminAssessmentRequestData
      }
      dispatch(actionAllAssessmentOverviewData(reponseData))
    }
  }, [userRoleData, listOrgWiseCountry, assessmentTypeFilter]);

  useEffect(() => {
    if (adminAssesmentData) {
      setAllAssessmentCount(adminAssesmentData.length);
      adminAssesmentData.map((assessment: any) => {
        assessment.assessmentStatus = checkAssessmentStatusLabel(
          assessment.assessmentStatusId
        );
      });
    }
  }, [adminAssesmentData]);

  useEffect(() => {
    dispatch(actionHeaderUserSetting(true));
  }, [dispatch]);

  useEffect(() => {
    if (

      languageSelected &&
      userRoleData &&
      userRoleData.userSelectedCountry.length &&
      showTabs
    ) {
      dispatch(actionFetchAdminAssessmentData({
        "countryIds": userRoleData.userSelectedCountry,
        "roleId": userRoleData.userSelectedRole,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      }));
    }
  }, [languageSelected, assessmentTypeFilter]);

  useEffect(() => {
    if (
      assessmentScoringData &&
      userRoleData &&
      userRoleData.userSelectedCountry.length &&
      maturityIndexData.length
    ) {
      let bindMaturityIndexCalcTable: any = [];
      userRoleData.userSelectedCountry.map((country: any) => {
        bindMaturityIndexCalcTable = [];
        let countryWiseAssementData: any = [];
        let isValidatedAssessment = false;
        // if program is selected then filter it with selected program supply chain id + country
        if (assessmentTypeFilter || selectedProgramFilter === undefined || selectedProgramFilter === "0")
          countryWiseAssementData = assessmentScoringData.filter(
            (item: any) => item.assessmentData.countryId === country
          );
        else {
          countryWiseAssementData = assessmentScoringData.filter(
            (item: any) => {
              if (item.assessmentData.countryId === country && item.assessmentData.supplyChainId === Number(selectedProgramFilter))
                return item;
            }
          );
        }
        countryWiseAssementData.map((assessmentData: any) => {
          let bindMaturityTableData: any = [];
          let tempTotalMaxWightedScore = 0;
          isValidatedAssessment = true;
          assessmentData.assessmentScoring.map((item: any, index: any) => {
            maturityIndexData.filter((maturityRow: any) => {
              if (maturityRow.areaId === item.areaOfWorkId) {
                maturityRow.mmScore = item.score;
                maturityRow.maxWeightScore = calculateMaxWeightedScore(
                  maturityRow.maxScore,
                  maturityRow.weight
                );
                tempTotalMaxWightedScore += maturityRow.maxWeightScore;
                bindMaturityTableData.push(maturityRow);
              }
            });
          });
          if (bindMaturityTableData.length) {
            let maturitIndexValue: any = 0;
            bindMaturityTableData.map((item: any) => {
              maturitIndexValue += calculateMaturityModelIndex(
                item.mmScore,
                item.weight,
                tempTotalMaxWightedScore
              );
            });
            bindMaturityIndexCalcTable.push(
              Number(maturitIndexValue.toFixed(1))
            );
          }
        });
        let avgMaturitIndexValue: any = 0;
        if (bindMaturityIndexCalcTable.length) {
          bindMaturityIndexCalcTable.map((item: any) => {
            avgMaturitIndexValue += Number(item);
          });
          avgMaturitIndexValue =
            avgMaturitIndexValue / bindMaturityIndexCalcTable.length;
        }
        countryMiArray.push({
          id: country,
          mi: avgMaturitIndexValue > 0 ? avgMaturitIndexValue.toFixed(1) : 0,
          validated: isValidatedAssessment,
        });
      });

      let countryMIData: any = [];
      let regionMIData: any = [];
      userRoleData.userSelectedRegion.map((reg: any) => {
        tempRegionMi = 0;
        totalCountry = 0;
        let regionWiseCountry: any = listOrgWiseCountry.filter(
          (country: any) => country.region === reg
        );
        regionWiseCountry.map((country: any) => {
          const tempCountry = countryMiArray.filter(
            (item: any) => item.id === country.id
          );
          totalCountry++;
          if (tempCountry.length && tempCountry[0].validated) {
            tempRegionMi += Number(tempCountry[0].mi);
          }

          countryMIData.push({
            countryId: country.id,
            label: country.label,
            region: country.region,
            avgCountryMI: tempCountry.length > 0 && (tempCountry[0].validated ? tempCountry[0].mi : 0) || 0,
            code: country.isoalpha3code,
          });
        });
        regionMIData.push({
          region: reg,
          avgRegionMI:
            tempRegionMi > 0 ? (tempRegionMi / totalCountry).toFixed(1) : 0,
        });
      });
      dispatch(actionSetAdminMapCountryAvgMIData(countryMIData));
      dispatch(actionSetAdminMapRegionAvgMIData(regionMIData));
    }
  }, [
    assessmentScoringData,
    userRoleData,
    maturityIndexData,
    listOrgWiseCountry,
    selectedProgramFilter
  ]);

  const functionAssesmentLevelData = (rowData: any) => {
    dispatch(actionDetailsSaveData(rowData));
    dispatch(actionFetchAssesmentLevelByIdData(rowData.id));
    setCurrentAssessmentId(rowData.id);
  };

  const exportReports = () => {
    const formData = {
      countries: userRoleData.userSelectedCountry,
      role: selectedRole,
      organization: userOrg,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionExportAssesmentData(formData));
  };

  const checkAssessmentStatusLabel: any = (id: any) => {
    let statusLabel: string = "";
    switch (id) {
      case 1:
        statusLabel = t("assessment.assessorDraft");
        break;
      case 2:
        statusLabel = t("assessment.reAssigned");
        break;
      case 3:
        statusLabel = t("assessment.pendingValidation");
        break;
      case 4:
        statusLabel = t("assessment.validated");
        break;
      case 5:
        statusLabel = t("assessment.inProgressValidation");
        break;
    }
    return statusLabel;
  };

  const handleProgramFilter = ([event]: any) => {
    setValue("selectedProgramFilter", event.target.value);
    const userRequestData = {
      country: listOrgWiseCountry,
      programId: event.target.value != 0 ? event.target.value : null,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(
      actionFetchAdminAllAssesmentStatusCount(userRequestData)
    );
    return event.target.value;
  }

  return (
    <Container maxWidth={"xl"}>
      <>
        {userRoleData && (
          <>
            <SubHeaderWrapper>
              <Box pt={1}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Grid container justify="flex-start" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography component="span" className="common-label">
                          {`${t("assessment.allAssesments")}  (${allAssessmentCount})`}
                        </Typography>
                      </Grid>
                      {!assessmentTypeFilter &&
                        <Grid item>
                          <FormContext {...methods}>
                            <CustomDropdown className="arabic-outlined-dropdown">
                              <FormSelect
                                name="selectedProgramFilter"
                                options={programList}
                                label={t("common.program")}
                                hideselectoption={true}
                                onChange={handleProgramFilter}
                                defaultValue={programList.length > 0 ? programList[0].id : ""}
                                variant={"outlined"}
                              />
                            </CustomDropdown>
                          </FormContext>
                        </Grid>}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify="flex-end">
                      {isUserAdmin && (
                        <Grid item>
                          <Tooltip title={`${t("common.downloadReport")}`}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => exportReports()}
                            >
                              <DownloadIconCustom className="arMarginR0 arMarginL10" />
                              {t("common.exportToExcel")}
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </SubHeaderWrapper>
            {scale && userOrg ? (
              <Suspense fallback={<SectionLoader visible={isSectionLoaderVisible} />}>
                <MapComponent
                  userRoleData={userRoleData}
                  scale={scale}
                  miCountryData={miCountryData}
                  miRegionData={miRegionData}
                  userOrg={userOrg}
                  programList={programList}
                  listOrgWiseCountry={listOrgWiseCountry}
                />
              </Suspense>
            ) : null}
            <AdminRsaOverviewTabComponent
              userRoleData={userRoleData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
              currentAssessmentId={currentAssessmentId}
              showTabs={showTabs}
              setShowTabs={setShowTabs}
            />
          </>
        )}
      </>
    </Container>
  );
}

export default AdminRSAOverview;
