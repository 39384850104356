import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { actionSaveMasterDynamicFormFieldDataDetail } from "../actions";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import {
  FormWrapper,
  FullWidthContainer,
  FormToolbar,
  PageWrapper,
} from "../../../styles/common";
import Paper from "@material-ui/core/Paper";
import FormInput from "../../../components/form-controls/input";
import FormSelect from "../../../components/form-controls/select";
import FormSelectAutoComplete from "../../../components/form-controls/select-autocomplete";
import FormDatePicker from "../../../components/form-controls/datepicker";
import { required, format } from "redux-form-validators";
import { getLocalDateInUTCFormat } from "utils/data";
import { useTranslation } from "react-i18next";

function MasterMenuFormComponent(props: any) {
  const {
    editFormValues,
    isEdit,
    masterMenuFormFieldAndData,
    formKey,
    handleCloseDilogue,
    userId,
  } = props;
  const methods = useForm();
  const { t } = useTranslation();
  const { setValue } = methods;
  const [formFields, setFormFields] = useState([]);
  const [parentMenuData, setParentMenuData] = useState([]);

  const dispatch = useDispatch();
  let fieldData: any;
  let parentColumnData: any;
  useEffect(() => {
    if (isEdit) {
      if (formFields.length) {
        formFields.map((item: any, index: any) => {
          const fieldName = item.controlId;
          const fieldValue = editFormValues[`${fieldName}`];
          if (fieldValue !== undefined) {
            setValue(fieldName, fieldValue);
          }
        });
      }
    }
  }, [formFields]);

  useEffect(() => {
    generateMenuFields();
  }, []);
  const generateMenuFields = () => {
    if (
      masterMenuFormFieldAndData &&
      Object.keys(masterMenuFormFieldAndData).length
    ) {
      fieldData = masterMenuFormFieldAndData.dynamicFormFieldsList.filter(
        (item: any) => {
          if (item.showInListing) {
            return item;
          }
        }
      );
      parentColumnData = fieldData.filter((item: any) => {
        if (item.parentTableColumns) {
          return item;
        }
      });
      if (parentColumnData.length) {
        setParentMenuData(parentColumnData[0].parentTable);
      } else {
        setFormFields(fieldData);
      }
    }
  };

  const { handleSubmit } = methods;
  const onSubmit = (data: any) => {
    data.MenuName = formKey;
    if (isEdit) {
      data.formId = editFormValues.formId;
      const rowData = masterMenuFormFieldAndData.dynamicFormDetailsList.filter(
        (item: any) => item.formId === editFormValues.formId
      );
      data.createdDate = rowData[0].createdDate
        ? rowData[0].createdDate
        : getLocalDateInUTCFormat(null);
      data.updatedBy = userId;
    } else {
      data.formId = 0;
      data.createdBy = userId;
    }
    dispatch(actionSaveMasterDynamicFormFieldDataDetail(data));
    handleCloseDilogue();
  };

  const handleSelectChange = ([event]: any) => {
    return event.target.value;
  };
  return (
    <PageWrapper>
      <FullWidthContainer>
        <Paper elevation={3}>
          <FormWrapper>
            <FormContext {...methods}>
              <form>
                <Grid container spacing={2}>
                  {formFields &&
                    formFields.length > 0 &&
                    formFields.map((item: any, index: any) => {
                      const requiredValidation = item.isRequired
                        ? [
                          required({
                            message: `${item.controlLabel} is required`,
                          }),
                        ]
                        : [];
                      if (item.parentTableColumns) {
                        const validateParams = [...requiredValidation];
                        return (
                          <FormSelect
                            key={index}
                            name={item.controlId}
                            label={item.controlLabel}
                            data={parentMenuData}
                            required={item.isRequired}
                            validate={validateParams}
                          />
                        );
                      } else if (item.controlType.toLowerCase() === "text") {
                        const regexValidation = item.validationPatern
                          ? [
                            format({
                              with: item.validationPatern,
                              message: "Incorrect format",
                            }),
                          ]
                          : [];

                        const validateParams = [
                          ...requiredValidation,
                          ...regexValidation,
                        ];
                        return (
                          <FormInput
                            key={index}
                            name={item.controlId}
                            label={item.controlLabel}
                            multiline={false}
                            required={item.isRequired}
                            validate={validateParams}
                          />
                        );
                      } else if (item.controlType.toLowerCase() === "select") {
                        const optionData = handleSelectChange(item);
                        const validateParams = [...requiredValidation];
                        return (
                          <FormSelect
                            key={index}
                            name={item.controlId}
                            label={item.controlLabel}
                            data={optionData}
                            required={item.isRequired}
                            validate={validateParams}
                          />
                        );
                      } else if (
                        item.controlType.toLowerCase() === "checkbox"
                      ) {
                        const optionData = handleSelectChange(item);
                        const validateParams = [...requiredValidation];
                        return (
                          <FormSelectAutoComplete
                            key={index}
                            name={item.controlId}
                            label={item.controlLabel}
                            data={optionData}
                            required={item.isRequired}
                            validate={validateParams}
                          />
                        );
                      } else if (item.controlType.toLowerCase() === "date") {
                        const validateParams = [...requiredValidation];
                        return (
                          <FormDatePicker
                            key={index}
                            name={item.controlId}
                            label={item.controlLabel}
                            required={item.isRequired}
                            validate={validateParams}
                          />
                        );
                      } else {
                        return;
                      }
                    })}
                </Grid>
              </form>
            </FormContext>
          </FormWrapper>
        </Paper>
      </FullWidthContainer>
      <FormToolbar>
        {isEdit ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.update")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.submit")}
          </Button>
        )}
        &nbsp;
        <Button
          onClick={handleCloseDilogue}
          variant="contained"
          color="default"
        >
          {t("common.cancel")}
        </Button>
      </FormToolbar>
    </PageWrapper>
  );
}

export default MasterMenuFormComponent;
