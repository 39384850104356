import { LANGUAGE } from "constants/data";

export const API_ERROR_MESSAGE = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Erreur lors de l'obtention des données";
      break;
    case LANGUAGE.SPANISH:
      message = "Error al obtener datos";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Ошибка при получении данных";
      break;
    case LANGUAGE.ARABIC:
      message = "خطأ أثناء إحضار البيانات";
      break;
    default:
      message = "Error while getting data";
      break;
  }
  return message;
};

export const DATA_SAVE_SUCCESS = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Données enregistrées avec succès";
      break;
    case LANGUAGE.SPANISH:
      message = "Datos guardados exitosamente";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Данные успешно сохранены";
      break;
    case LANGUAGE.ARABIC:
      message = "تم حفظ المعلومات بنجاح";
      break;
    default:
      message = "Data saved succesfully";
      break;
  }
  return message;
};

export const DATA_UPDATE_SUCCESS = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Données mises à jour avec succès";
      break;
    case LANGUAGE.SPANISH:
      message = "Datos actualizados exitosamente";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Данные успешно обновлены";
      break;
    case LANGUAGE.ARABIC:
      message = "تم تحديث البيانات بنجاح";
      break;
    default:
      message = "Data updated succesfully";
      break;
  }
  return message;
};

export const DATA_DELETE_SUCCESS = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Données supprimées avec succès";
      break;
    case LANGUAGE.SPANISH:
      message = "Datos eliminados exitosamente";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Данные успешно удалены";
      break;
    case LANGUAGE.ARABIC:
      message = "تم حذف البيانات بنجاح";
      break;
    default:
      message = "Data deleted succesfully";
      break;
  }
  return message;
};

export const API_ERROR_DUPLICATE = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Il existe déjà des données utilisant ce nom";
      break;
    case LANGUAGE.SPANISH:
      message = "Ya existen datos con este nombre";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Данные с таким названием уже существуют.";
      break;
    case LANGUAGE.ARABIC:
      message = "هناك بيانات موجودة تحتوي على هذا الاسم بالفعل ";
      break;
    default:
      message = "Data with this name already exists.";
      break;
  }
  return message;
};

export const API_ERROR_USER_EXIST = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Il existe déjà un utilisateur utilisant cette adresse électronique";
      break;
    case LANGUAGE.SPANISH:
      message = "Ya existe un usuario con esta dirección de correo electrónico";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Пользователь с таким адресом электронной почты уже существует.";
      break;
    case LANGUAGE.ARABIC:
      message = "يوجد مستخدم بنفس هذا البريد الإلكتروني بالفعل ";
      break;
    default:
      message = "A user with this email address already exists.";
      break;
  }
  return message;
};

export const ENTITY_ASSOCIATED_MESSAGE = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Élément impossible à supprimer car en cours d’utilisation";
      break;
    case LANGUAGE.SPANISH:
      message = "No puede eliminar este elemento porque está en uso";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Вы не можете удалить этот элемент, так как он используется в настоящий момент.";
      break;
    case LANGUAGE.ARABIC:
      message = "لا يمكنك حذف هذا العنصر لأنه قيد الاستخدام .";
      break;
    default:
      message = "You cannot delete this item because it's in use.";
      break;
  }
  return message;
};

export const ACCESS_REQUEST_SUCCESS = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "Demande d’accès envoyée";
      break;
    case LANGUAGE.SPANISH:
      message = "Solicitud de acceso enviada con éxito";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Запрос на получение доступа успешно отправлен.";
      break;
    case LANGUAGE.ARABIC:
      message = "طلب الدخول قد أُرسل بنجاح ";
      break;
    default:
      message = "Access Request sent successfully";
      break;
  }
  return message;
};

export const INDICATOR_SET_NOT_AVAILABLE = () => {
  const languageSelected = localStorage.getItem("language");
  let message = "";
  switch (languageSelected) {
    case LANGUAGE.FRENCH:
      message = "L’indicateur paramétré avec ce Programme et cette Portée n’est pas disponible";
      break;
    case LANGUAGE.SPANISH:
      message = "El indicador establecido con este Programa y Ámbito de aplicación no está disponible";
      break;
    case LANGUAGE.RUSSIAN:
      message = "Индикатор, установленный для этой программы и области действия, недоступен.";
      break;
    case LANGUAGE.ARABIC:
      message = "مجموعة المؤشرات مع هذا البرنامج والنطاق غير متوفرة ";
      break;
    default:
      message = "Indicator set with this Program and Scope is not available";
      break;
  }
  return message;
};