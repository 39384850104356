import React, { useEffect, useState, } from "react";
import { useDispatch } from "react-redux";
import { Tabs, Tab, Box, Grid, Typography, Tooltip, FormControl } from "@material-ui/core";
import {
  actionUpdateAssesmentQuestionDetailData,
  actionFetchAssesmentValidationCommentData
} from "../actions";
import { useTranslation } from "react-i18next";
import {
  ListTitle,
  EditAssessmentAppBar,
  TabWrapper,
  Areaofworklabel,
  ListCustom,
  AssessmentSubHeader,
  BoxShadowWrapper,
  HtmlTooltip,
  AssessmentTooltipDetails,
  InfoOutlinedIconCustom,
  CommentIconCustom,
  FormControlValidation,
  AssessmentTabularData,
  ValidationTabularData,
  StyledInputLabel,
  CustomDropdown,
  LevelsLegend,
  BookmarkIconCustom,
  ScrollToTopButton
} from "../../../styles/common";
import { ListItem, MenuItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import AssessmentQuestionCommentComponent from "../assessment-question-comment";
import Select from "@material-ui/core/Select";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, LEVEL_DESCRIPTION, USER } from "constants/data";
import FormCheckbox from "../../../components/form-controls/checkbox";
import { FormContext, useForm } from "react-hook-form";
import "../index.css";
import _ from "lodash";
import { getFilteredAOWData, getValidationStatusFlag } from "./helper";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function AssesmentQuestionTabsComponent(props: any) {
  const {
    assesmentDetailData,
    editFormValues,
    userId,
    isEditAssessment,
    selectedAssessmentCode,
    selectedAssessmentStatusId,
    selectedRegion,
    selectedCountry,
    selectedSupplyChain,
    selectedScope,
    selectedAssessmentType,
    checkAssessmentStatusLabel,
    selectedRole,
    validationStatusData,
    loginUserData,
    setIsValidateEnabled,
    setIsReAssignEnabled,
    closeToasterParticularTimeInterval,
    openToaster,
    setLoaderVisibility,
    selectedSubRegion,
    setIsUpdatedByOther
  } = props;
  const emptySubAreaOfWork: any = { subAreaOfWorkId: null };
  const emptyTopic: any = { topicId: null };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentAssessmentId, setCurrentAssessmentId]: any = useState("")
  const [currentAssessmentStatus, setCurrentAssessmentStatus]: any = useState("")
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData]: any = useState([]);
  const [tabContainerData, setTabContainerData]: any = useState([]);
  const [areaOfWorkList, setAreaOfWorkList]: any = useState([]);
  const [selectedAreaOfWork, setSelectedAreaOfWork] = useState("");
  const [areaOfWorkListArray, setAreaOfWorkListArray]: any = useState([]);
  const [selectedAreaOfWorkLable, setSelectedAreaOfWorkLable] = useState("");
  const [questionCommentData, setQuestionCommentData]: any = useState({});
  const [questionCommentDialogOpen, setQuestionCommentDialogOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState("");
  const [assessmentDynamicRowDataList, setAssessmentDynamicRowDataList]: any = useState([]);
  const [questionComment, setQuestionComment] = useState("");
  const [assessmentDynamicHeaderList, setAssessmentDynamicHeaderList] = useState([]);
  const [selectedAowData, setSelectedAowData]: any = useState(null);
  const [levelList, setLevelList]: any = useState([]);
  const [tabLevelId, setTabLevelId]: any = useState(0);
  const methods = useForm();
  const { setValue } = methods;
  const questionCommentDialogTitle = t("assessment.assessmentQuestionComment");
  const [allSubAreaOfWork, setAllSubAreaOfWork] = useState([]);
  const [allTopics, setTopics] = useState([]);
  const [subAreOfWorkFilter, setSubAreOfWorkFilter] = useState(emptySubAreaOfWork);
  const [topicFilter, setTopicFilter] = useState(emptyTopic);
  const [isDispatchUpdateCall, setIsDispatchUpdateCall] = useState(false);
  const [visibleTopButton, setVisibleTopButton] = useState(false);

  const toggleVisibleButton = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      const scrolled = scrollableDiv.scrollTop;
      if (scrolled > 20) {
        setVisibleTopButton(true);
      }
      else if (scrolled <= 20) {
        setVisibleTopButton(false);
      }
    }
  };

  const scrollToTopDiv = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    let areaOfWorkDataList: any = [];
    if (
      assesmentDetailData &&
      assesmentDetailData.hasOwnProperty("assesementDetails")
    ) {
      setCurrentAssessmentId(assesmentDetailData.assessmentData.assesmentId);
      setCurrentAssessmentStatus(assesmentDetailData.assessmentData.assessmentStatusId);
      assesmentDetailData.assesementDetails.map((rowData: any) => {
        areaOfWorkDataList.push({
          name: rowData.areaOfWork,
          id: rowData.areaOfWorkId,
          validationStatus: getValidationStatusFlag(rowData),
          areaOfWorkStatus: ASSESSMENT.QUESTION_STATUS_COLOR.NOT_STARTED,
        });
      });
      setAreaOfWorkList(areaOfWorkDataList);
      setAreaOfWorkListArray(areaOfWorkDataList);
      setAssessmentDynamicHeaderList(
        assesmentDetailData.dynamicColumnHeaderList
      );
      setAssessmentDynamicRowDataList(assesmentDetailData.assesementDetails);
      dispatch(setLoaderVisibility(false));

      // set filter list of all sub area of works and all topics as default
      const allSAOFW = assesmentDetailData.assesementDetails[0].levels[0].subAreaOfWorks;
      let allTopics: any = [];
      assesmentDetailData.assesementDetails[0].levels[0].subAreaOfWorks.forEach((sub: any) => {
        allTopics = [...allTopics, ...sub.questionDetails]
      });
      setAllSubAreaOfWork(allSAOFW);
      setTopics(allTopics)
    }
  }, [assesmentDetailData]);

  useEffect(() => {
    if (
      assessmentDynamicHeaderList &&
      assessmentDynamicHeaderList.length &&
      assessmentDynamicRowDataList &&
      assessmentDynamicRowDataList.length &&
      areaOfWorkListArray &&
      areaOfWorkListArray.length
    ) {
      setLevelList(assessmentDynamicHeaderList);
      if (areaOfWorkListArray.length) {
        if (selectedAreaOfWork === "") {
          setSelectedAreaOfWork(areaOfWorkListArray[0].id);
          setSelectedAreaOfWorkLable(areaOfWorkListArray[0].name);
        } else {
          const areaOfWorkData = areaOfWorkListArray.filter(
            (areaOfWork: any) => areaOfWork.id === selectedAreaOfWork
          );
          if (areaOfWorkData.length) {
            setSelectedAreaOfWorkLable(areaOfWorkData[0].name);
          }
        }
        handleChangeDetectionData(selectedAreaOfWork, areaOfWorkListArray);
      }
    }
  }, [assessmentDynamicRowDataList]);

  useEffect(() => {
    if (!_.isEmpty(selectedAowData) && isDispatchUpdateCall) {
      const assessmentStatusId = currentAssessmentStatus === ASSESSMENT.STATUS.SEND_FOR_VALIDATION &&
        selectedRole.toLowerCase().trim() === USER.ROLE.VALIDATOR.toLowerCase()
        ? ASSESSMENT.STATUS.IN_PROGRESS_VALIDATION
        : currentAssessmentStatus
      const updateFormData = {
        ...selectedAowData,
        id: currentAssessmentId,
        assessmentStatusId: assessmentStatusId
      };
      dispatch(actionUpdateAssesmentQuestionDetailData(updateFormData));
      setIsDispatchUpdateCall(false);
      if (assessmentStatusId !== currentAssessmentStatus)
        setIsUpdatedByOther(true);

      setCurrentAssessmentStatus(assessmentStatusId);
      handleReAssignButton(areaOfWorkListArray, tabData);
      handleValidateButton(areaOfWorkListArray, tabData);

      // set filters and call tab render change
      const filteredData = getFilteredAOWData(selectedAowData, subAreOfWorkFilter, topicFilter);
      handleLevelTabRerenderChange(
        { ...filteredData },
        tabValue
      );
    }
  }, [selectedAowData])

  const handleChangeDetectionData = (
    selectedAreaOfWork: any,
    areaOfWorkDataList: any
  ) => {
    let levelType = `${ASSESSMENT.LEVEL}${tabValue + 1}`;
    if (
      selectedRole.toLowerCase().trim() ===
      USER.ROLE.ASSESSOR.toLowerCase().trim()
    ) {
      handleSelectedAreaOfWorkTabSelection_Assessor(
        selectedAreaOfWork,
        areaOfWorkDataList,
        levelType
      );
    } else {
      handleSelectedAreaOfWorkTabSelection_Validator(
        selectedAreaOfWork,
        areaOfWorkDataList,
        levelType
      );
    }
  };
  const generateTabs = () => {
    return tabData.map((item: any, index: any) => {
      return (
        <Tab
          key={index}
          label={handleTabLabel(item)}
          {...a11yProps({ index })}
        />
      );
    });
  };

  const createToolTip = (val: any) => {
    const value = val.toLowerCase();
    const description: any = LEVEL_DESCRIPTION[value];
    const num = value.match(/\d+/g);
    const levelCls = `maturity_no ${value}`;
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <AssessmentTooltipDetails>
              <Typography component="div" className="maturity-inner">
                <Typography component="div" className={levelCls}>
                  <Typography component="p">
                    {t("level.maturityLevel")}
                  </Typography>
                  <Typography component="h1">{num}</Typography>
                </Typography>
                <Typography component="div" className="maturity_details">
                  <Typography component="div" className="maturity_title">
                    {t("common.resultInterPretation")}
                  </Typography>
                  <Typography component="div" className="maturity_desc">
                    {t("level." + description)}
                  </Typography>
                </Typography>
              </Typography>
            </AssessmentTooltipDetails>
          </React.Fragment>
        }
      >
        <InfoOutlinedIconCustom />
      </HtmlTooltip>
    );
  };

  const handleTabLabel = (item: any) => {
    const titleText = `level.${item.title.toLowerCase().replace(/\s+/g, "")}`;
    const label = t(titleText);
    return (
      <>
        <Typography component="span" className="level-label">
          {label}
        </Typography>
        <Typography component="span" className="level-label toolTipIcon">
          {createToolTip(item.tabValueField)}
        </Typography>
      </>
    );
  };


  // New Function Tab Level Change
  const handleLevelTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabValue(newValue);
    const levelType = `${ASSESSMENT.LEVEL}${newValue + 1}`;
    const fiterLevel = levelList.filter((level: any) => level.field === levelType);
    if (fiterLevel.length)
      setTabLevelId(fiterLevel[0].id);

    // get tab change on filtered data
    const filteredData = getFilteredAOWData(selectedAowData, subAreOfWorkFilter, topicFilter);
    const filtterTabData = filteredData.levels.filter((item: any) => item.levelName.replace(/ /g, "") === levelType);
    if (filtterTabData.length)
      setTabContainerData(filtterTabData[0]);

    hideQuestionCommentDialogForm();
  };

  const handleLevelTabRerenderChange = (
    newAow: any,
    newValue: number
  ) => {
    setTabValue(newValue);
    const levelType = `${ASSESSMENT.LEVEL}${newValue + 1}`;
    const fiterLevel = levelList.filter((level: any) => level.field === levelType);
    if (fiterLevel.length)
      setTabLevelId(fiterLevel[0].id);

    const filtterTabData = newAow.levels.filter((item: any) => item.levelName.replace(/ /g, "") === levelType);
    if (filtterTabData.length)
      setTabContainerData(filtterTabData[0]);

    hideQuestionCommentDialogForm();
  };


  const handleAreaOfWorkChange = (areaOfWorkId: any) => {
    const levelType = `${ASSESSMENT.LEVEL}1`;
    setSelectedAreaOfWork(areaOfWorkId);
    const areaOfWorkLable: any = areaOfWorkList.filter(
      (area: any) => area.id === areaOfWorkId
    );
    if (areaOfWorkLable.length)
      setSelectedAreaOfWorkLable(areaOfWorkLable[0].name);

    const AowData = assessmentDynamicRowDataList.filter(
      (a: any) => a.areaOfWorkId === areaOfWorkId
    );
    if (AowData.length)
      setSelectedAowData(AowData[0])

    if (
      selectedRole.toLowerCase().trim() ===
      USER.ROLE.ASSESSOR.toLowerCase().trim()
    ) {
      handleSelectedAreaOfWorkTabSelection_Assessor(
        areaOfWorkId,
        areaOfWorkList,
        levelType
      );
    } else {
      handleSelectedAreaOfWorkTabSelection_Validator(
        areaOfWorkId,
        areaOfWorkList,
        levelType
      );
    }
    // set sub area of work filter list on change of area of work 
    const data = assesmentDetailData.assesementDetails.filter((assess: any) => assess.areaOfWorkId === areaOfWorkId)
    if (data.length) {
      const allSAOFW = data[0].levels[0].subAreaOfWorks;
      let allTopics: any = [];
      data[0].levels[0].subAreaOfWorks.forEach((sub: any) => {
        allTopics = [...allTopics, ...sub.questionDetails]
      });
      setAllSubAreaOfWork(allSAOFW);
      setTopics(allTopics)
    } else {
      setAllSubAreaOfWork([]);
      setTopics([])
    }
    setSubAreOfWorkFilter(emptySubAreaOfWork);
    setTopicFilter(emptyTopic);
  };

  // New Function For Handle Area Of work and  Tab selection for Assessor
  const handleSelectedAreaOfWorkTabSelection_Assessor = (
    selectedAreaOfWork: any,
    areaOfWorkDataList: any,
    levelType: any
  ) => {
    const tabContainData: any = assessmentDynamicRowDataList.filter(
      (a: any) =>
        a.areaOfWorkId === (selectedAreaOfWork === "" ? areaOfWorkDataList[0].id : selectedAreaOfWork)
    );
    let tempTabs: any = [];
    let tempTabIndexIncrement = 0;
    if (tabContainData.length)
      setSelectedAowData(tabContainData[0]);

    let tempTabValue = 0;
    assessmentDynamicHeaderList.filter((column: any, index: any) => {
      if (column.field.match(ASSESSMENT.LEVEL)) {
        const filtterTabData = tabContainData[0].levels.filter((item: any) => item.levelName.replace(/ /g, "") === column.field);
        const questionData = filtterTabData[0];
        //default bind first level tab
        if (column.field === `${ASSESSMENT.LEVEL}1`) {
          setTabContainerData(questionData);
        }
        tempTabs.push({
          tabIndex: tempTabIndexIncrement,
          title: column.title,
          tabValueField: column.field,
          description: column.description,
        });
        tempTabIndexIncrement++;
      }
    });
    if (tempTabValue === 0) {
      setTabValue(tempTabValue);
      const fiterLevel = levelList.filter((level: any) => level.field === levelType);
      if (fiterLevel.length)
        setTabLevelId(fiterLevel[0].id);
    }
    setTabData(tempTabs);
    hideQuestionCommentDialogForm();
  };

  const handleSelectedAreaOfWorkTabSelection_Validator = (
    selectedAreaOfWork: any,
    areaOfWorkDataList: any,
    levelType: any
  ) => {
    const tabContainData = assessmentDynamicRowDataList.filter(
      (assessmentData: any) => assessmentData.areaOfWorkId ===
        (selectedAreaOfWork === "" ? areaOfWorkDataList[0].id : selectedAreaOfWork));
    let tempTabs: any = [];
    let tempTabIndexIncrement = 0;
    let tempTabValue = 0;
    if (tabContainData.length)
      setSelectedAowData(tabContainData[0]);

    assessmentDynamicHeaderList.filter((column: any, index: any) => {
      if (column.field.match(ASSESSMENT.LEVEL)) {
        const filtterTabData = tabContainData[0].levels.filter((item: any) => item.levelName.replace(/ /g, "") === column.field);

        //default bind first level tab
        if (column.field === `${ASSESSMENT.LEVEL}1` && filtterTabData.length) {
          setTabContainerData(filtterTabData[0]);
        }
        tempTabs.push({
          tabIndex: tempTabIndexIncrement,
          title: column.title,
          tabValueField: column.field,
          description: column.description,
        });
        tempTabIndexIncrement++;
      }
    });

    if (tempTabValue === 0) {
      setTabValue(tempTabValue);
      const fiterLevel = levelList.filter((level: any) => level.field === levelType);
      if (fiterLevel.length)
        setTabLevelId(fiterLevel[0].id);
    }

    setTabData(tempTabs);
    handleValidateButton(areaOfWorkDataList, tempTabs);
    handleReAssignButton(areaOfWorkDataList, tempTabs);
    hideQuestionCommentDialogForm();
  };


  //New Function Handle Validate Button Enabled
  const handleValidateButton = (areaOfWorkDataList: any, tempTabs: any) => {
    let allAgreeQueArr: any = [];
    let allSelectQueArr: any = [];
    let allDisAgreeQueArr: any = [];
    for (let i = 0; i < areaOfWorkDataList.length; i++) {
      const tabContainData = assessmentDynamicRowDataList.filter(
        (area: any) => area.areaOfWorkId === areaOfWorkDataList[i].id
      );
      //looping throgh all quetion to check agree question
      if (tabContainData.length) {
        for (let j = 0; j < tempTabs.length; j++) {
          tabContainData[0].levels.map((level: any, levelIndex: any) => {
            level.subAreaOfWorks.map((subAow: any, subIndex: any) => {
              const questionValidationAgreeData = subAow.questionDetails.filter(
                (data: any) =>
                  data.validationStatusId === ASSESSMENT.QUESTION_VALIDATION_STATUS.AGREE
              );
              const questionValidationDisAgreeData = subAow.questionDetails.filter(
                (data: any) =>
                  data.validationStatusId === ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE
              );
              const questionSelectedData = subAow.questionDetails.filter(
                (data: any) =>
                  data.isQuestionCheck === true
              );

              if (questionSelectedData.length) {
                allSelectQueArr = [...allSelectQueArr, ...questionSelectedData];
                if (questionValidationAgreeData.length) {
                  allAgreeQueArr = [...allAgreeQueArr, ...questionValidationAgreeData];
                }
                if (questionValidationDisAgreeData.length) {
                  allDisAgreeQueArr = [...allDisAgreeQueArr, ...questionValidationDisAgreeData];
                }
              }
            });
          });
        }
      }
    }
    if (setIsReAssignEnabled) {
      if (allAgreeQueArr.length === allSelectQueArr.length && allDisAgreeQueArr.length == 0) {
        setIsValidateEnabled(true);
      } else {
        setIsValidateEnabled(false);
      }
    }
  };

  const handleReAssignButton = (areaOfWorkDataList: any, tempTabs: any) => {
    let isAnyDisAgreeQuestion = true;
    let isAnyDisAgreeQuestionCommentEmpty = false;

    for (let i = 0; i < areaOfWorkDataList.length; i++) {
      if (isAnyDisAgreeQuestionCommentEmpty === false) {
        const tabContainData = assessmentDynamicRowDataList.filter(
          (area: any) => area.areaOfWorkId === areaOfWorkDataList[i].id
        );
        if (tabContainData.length) {
          for (let j = 0; j < tempTabs.length; j++) {
            if (isAnyDisAgreeQuestionCommentEmpty === false) {
              tabContainData[0].levels.map((level: any, levelIndex: any) => {
                const tempSubAreaOfWorksArray = level.subAreaOfWorks.slice();
                tempSubAreaOfWorksArray.map((subAow: any, subIndex: any) => {
                  const questionValidationDisAgreeData = subAow.questionDetails.filter(
                    (data: any) =>
                      data.validationStatusId ===
                      ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE
                  );
                  if (
                    questionValidationDisAgreeData.length
                  ) {
                    for (let k = 0; k < questionValidationDisAgreeData.length; k++) {
                      if (
                        questionValidationDisAgreeData[k].validationComment
                      ) {
                        isAnyDisAgreeQuestion = false;
                      } else {
                        isAnyDisAgreeQuestion = true;
                        isAnyDisAgreeQuestionCommentEmpty = true;
                        break;
                      }
                    }
                  }
                });
              });
            } else {
              break;
            }
          }
        }

      } else {
        break;
      }
    }
    if (isAnyDisAgreeQuestionCommentEmpty) {
      const message = `${t("assessment.assessmentCommentEnter")}`;
      dispatch(openToaster(message, false));
      closeToasterParticularTimeInterval();
    }
    if (setIsReAssignEnabled) {
      if (isAnyDisAgreeQuestion || isAnyDisAgreeQuestionCommentEmpty) {
        setIsReAssignEnabled(false);
      } else {
        setIsReAssignEnabled(true);
      }
    }
  };

  const handleAssessmentDataUpdate = (
    questionId: any,
    input: any,
    type: any
  ) => {
    const level = `${ASSESSMENT.LEVEL}${tabValue + 1}`;
    for (let i = 0; i < assessmentDynamicRowDataList.length; i++) {
      if (assessmentDynamicRowDataList[i].areaOfWorkId === selectedAreaOfWork) {
        const questionData = assessmentDynamicRowDataList[i][level];
        if (type != "RV") {
          const question = questionData.QuestionList.filter(
            (data: any) => data.QuestionId === questionId
          );
          if (question.length) {
            switch (type) {
              case "QC":
                question[0].IsQuestionCheck = input == 0 ? false : true;
                question[0].validationStatusId =
                  ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED;
                break;
              case "VS":
                question[0].validationStatusId = input;
                break;
              case "CO":
                question[0].validationComment = input;
                break;
            }
            break;
          }
        } else if (questionData && questionData.QuestionList.length) {
          for (let j = 0; j < questionData.QuestionList.length; j++) {
            questionData.QuestionList[j].validationStatusId = input;
          }
          break;
        }
      }
    }
    setAssessmentDynamicRowDataList([...assessmentDynamicRowDataList]);
  };
  // New Function Handle Question Comment
  const handleQuestionComment = (question: any) => {
    setSelectedCommentId(question.assessmentDetailId);
    setQuestionCommentDialogOpen(true);
    setQuestionCommentData(question);
    dispatch(
      actionFetchAssesmentValidationCommentData(question.assessmentDetailId)
    );
    setQuestionComment(question.validationComment);
  };

  // New Function Handle Question Comment Dialog
  const hideQuestionCommentDialogForm = () => {
    if (questionCommentDialogOpen) {
      setQuestionCommentDialogOpen(false);
      if (
        questionComment !== "" &&
        questionCommentData.validationComment === "" &&
        selectedCommentId !== ""
      ) {
        handleAssessmentDataUpdate(
          questionCommentData.QuestionId,
          questionComment,
          "CO"
        );
      }
      setQuestionComment("");
      setSelectedCommentId("");
    }
  };


  // New Function to show Validator Output
  const handleValidatorOutput = (question: any) => {
    let bgColor = "grayBgClass";
    let text = ""
    switch (question.otherLevelValidationStatusId) {
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED:
        bgColor = "grayBgClass";
        break;
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.AGREE:
        bgColor = "greenBgClass";
        text = "Agree";
        break;
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE:
        bgColor = "redBgClass";
        text = "Disagree";
        break;
    }
    return { bgColor, text };
  };

  const handleNAChange = ([event]: any) => {
    const tempLevels: any = [];
    let aowNo: any = 0;
    //check if click on sub area of work
    if (event.target.name.match("sub")) {
      //get index no of SAW
      const elementName = event.target.name;
      const subNoTemp = elementName.split("-");
      aowNo = parseInt(subNoTemp[0]);
      const subNo = parseInt(subNoTemp[2]);
      //const topicNo = parseInt(subNoTemp[3]);
      //iterating though cuurent AOW levels obj to modify it
      selectedAowData.levels.map((level: any, levelIndex: any) => {
        const tempSubAreaOfWorksArr = level.subAreaOfWorks.slice();
        // marked checked/unchecked current subAOW 
        tempSubAreaOfWorksArr[subNo - 1].isNACheck = event.target.checked;
        const tempSubAow: any = [];
        let filterQueArr: any = [];
        //looping though its children to mark them checked/unchecked as its parent
        tempSubAreaOfWorksArr.map((subAow: any, subIndex: any) => {
          const tempQueArr = subAow.questionDetails.slice();
          //checking which no of Sub Aow checked and finding its childern 
          if (subNo === subIndex + 1) {
            filterQueArr = tempQueArr.map((que: any, queIndex: any) => {
              if (tabValue === levelIndex) {
                setValue(`${aowNo}-topic-${subIndex + 1}-${queIndex + 1}`, event.target.checked);
                setValue(`${aowNo}-select-${subIndex + 1}-${queIndex + 1}`, false);
              }
              //reseting validator output for this sub area of work 
              tempQueArr[queIndex].validationStatusId = ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED;

              return {
                ...que,
                "isQuestionCheck": false,
                "isNACheck": event.target.checked,
                "isQuestionDisabled": event.target.checked,
                "selectedLevelId": 0
              }
            });
            // creating temp obj of SubAOW to update main AOW obj
            tempSubAow.push({
              ...subAow,
              questionDetails: filterQueArr
            });
          } else {
            // other than checked subAow taking as it is as there are no change in it
            tempSubAow.push({
              ...subAow
            });
          }
        });
        //updating all level 
        tempLevels.push({
          ...level,
          subAreaOfWorks: tempSubAow
        });
      });
      //setting up final obj
      setSelectedAowData({
        ...selectedAowData,
        levels: tempLevels
      });
      handleLevelTabRerenderChange(
        {
          ...selectedAowData,
          levels: tempLevels
        },
        tabValue
      );

    } else if (event.target.name.match("topic")) {
      //check if click on topic
      //get index no of SAW and topic which checked
      const elementName = event.target.name;
      const topicNoArray = elementName.split("-");
      aowNo = parseInt(topicNoArray[0]);
      const subNo = parseInt(topicNoArray[2]);
      const topicNo = parseInt(topicNoArray[3]);

      //iterating though cuurent AOW levels obj to modify it
      selectedAowData.levels.map((level: any, levelIndex: any) => {
        const tempSubAow: any = [];
        const tempSubAreaOfWorksArray = level.subAreaOfWorks.slice();
        let isAnyUnCheckedTopic = false;
        //looping though topics to mark them checked/unchecked for perticuler subAOW
        tempSubAreaOfWorksArray.map((subAow: any, subIndex: any) => {
          const tempQueArr = subAow.questionDetails.slice();
          if (subNo === subIndex + 1) {
            tempQueArr[topicNo - 1].isQuestionCheck = false;
            tempQueArr[topicNo - 1].isNACheck = event.target.checked;
            tempQueArr[topicNo - 1].isQuestionDisabled = event.target.checked;
            tempQueArr[topicNo - 1].selectedLevelId = 0;
            setValue(`${aowNo}-select-${subIndex + 1}-${topicNo}`, false);

            //reseting validator output for this quetion
            tempQueArr[topicNo - 1].validationStatusId = ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED;

            // checking if any one topic is unchecked
            if (!event.target.checked) {
              isAnyUnCheckedTopic = true;
            }
          }
          //checking all que are marked checked then mark SubAOW checked
          const temp = tempQueArr.filter((tQue: any) => tQue.isNACheck === true);
          if (temp.length == tempQueArr.length) {
            subAow = {
              ...subAow,
              isNACheck: true
            }
            if (subNo === subIndex + 1) {
              setValue(`${aowNo}-subAow-${subIndex + 1}`, true);
            }
          }
          //checking if any que are marked unchecked then mark SubAOW unchecked
          if (isAnyUnCheckedTopic) {
            subAow = {
              ...subAow,
              isNACheck: false
            }
            if (subNo === subIndex + 1) {
              setValue(`${aowNo}-subAow-${subIndex + 1}`, false);
            }
          }

          // creating temp obj of SubAOW to update main AOW obj
          tempSubAow.push({
            ...subAow,
            questionDetails: tempQueArr
          });
        });
        //updating all level 
        tempLevels.push({
          ...level,
          subAreaOfWorks: tempSubAow
        });
      });
      //setting up final obj
      setSelectedAowData({
        ...selectedAowData,
        levels: tempLevels
      });

      handleLevelTabRerenderChange(
        {
          ...selectedAowData,
          levels: tempLevels
        },
        tabValue
      );
    }
    updateOriginalDataListWithAowData(tempLevels, aowNo);
    setIsDispatchUpdateCall(true);
    return event.target.checked;
  };

  // update original assessment list with AOW changes
  const updateOriginalDataListWithAowData = (tempLevels: any, aowId: any) => {
    let tempDataList = [...assessmentDynamicRowDataList];
    tempDataList = tempDataList.map(aow => {
      if (aow.areaOfWorkId === aowId) {
        return { ...aow, levels: tempLevels }
      }
      return aow
    })
    setAssessmentDynamicRowDataList(tempDataList);
  }

  const getBackGroundColor = (que: any) => {
    let levelNo = 0;
    if (que.isNACheck) {
      levelNo = 6
    } else {
      const filterLevel = levelList.filter((level: any) => level.id === que.selectedLevelId);
      if (filterLevel.length) {
        levelNo = filterLevel[0].index - 1;
      }
    }

    let colorClass: string = "";
    switch (levelNo) {
      case 0:
        colorClass = "";
        break;
      case 1:
        colorClass = "red";
        break;
      case 2:
        colorClass = "lightRed";
        break;
      case 3:
        colorClass = "orange";
        break;
      case 4:
        colorClass = "lightGreen";
        break;
      case 5:
        colorClass = "green";
        break;
      case 6:
        colorClass = "grey";
        break;
    }
    return colorClass;
  }
  const handleSelectChange = ([event]: any) => {
    const elementName = event.target.name;
    const topicNoArray = elementName.split("-");
    // const aowNo = parseInt(topicNoArray[0]);
    const subNo = parseInt(topicNoArray[2]);
    const topicNo = parseInt(topicNoArray[3]);
    const tempLevels: any = [];
    selectedAowData.levels.map((level: any, levelIndex: any) => {
      const tempSubAow: any = [];
      const tempSubAreaOfWorksArray = level.subAreaOfWorks.slice();
      //looping though topics to mark them checked/unchecked for perticuler subAOW
      tempSubAreaOfWorksArray.map((subAow: any, subIndex: any) => {
        const tempQueArr = subAow.questionDetails.slice();
        if (subNo === subIndex + 1) {
          if (levelIndex === tabValue) {
            tempQueArr[topicNo - 1].isQuestionCheck = event.target.checked;
          } else {
            tempQueArr[topicNo - 1].isQuestionDisabled = event.target.checked;
          }
          if (event.target.checked) {
            tempQueArr[topicNo - 1].selectedLevelId = tabLevelId === 0 ? levelList[1].id : tabLevelId;
          } else {
            tempQueArr[topicNo - 1].selectedLevelId = 0;
          }

          //reseting validator output for this quetion
          tempQueArr[topicNo - 1].validationStatusId = ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED;
        }
        // creating temp obj of SubAOW to update main AOW obj
        tempSubAow.push({
          ...subAow,
          questionDetails: tempQueArr
        });
      });
      //updating all level 
      tempLevels.push({
        ...level,
        subAreaOfWorks: tempSubAow
      });
    });
    setSelectedAowData({
      ...selectedAowData,
      levels: tempLevels
    });
    handleLevelTabRerenderChange(
      {
        ...selectedAowData,
        levels: tempLevels
      },
      tabValue
    );
    setIsDispatchUpdateCall(true);
    return event.target.checked;
  };

  // get actual row index from original AOW data for subarea of work and topic
  const getOriginalIndex = (type: String, id: any) => {
    let AowData = assessmentDynamicRowDataList.filter(
      (a: any) => a.areaOfWorkId === selectedAreaOfWork
    );
    if (type === "subAreaOfWorks") {
      return AowData[0].levels[0].subAreaOfWorks.findIndex((ele: any) => ele.subAreaOfWorkId === id);
    } else if (type = "questionDetails") {
      for (var i = 0; i < AowData[0].levels[0].subAreaOfWorks.length; i++) {
        const index: any = AowData[0].levels[0].subAreaOfWorks[i].questionDetails.findIndex(
          (ele: any) => ele.topicId === id
        )
        if (index !== -1) return index;
      }
    }
  }

  // New Function View  Assessor Question
  const getQuestionView_Assessor = () => {
    return (
      <>
        <AssessmentTabularData onScroll={toggleVisibleButton} id="scrollableDiv">
          <Grid container alignItems="center" className="ATD-Row solid-border-bottom">
            <Grid item md={1} lg={1} xl={1}>
              <Typography component="div" className="text-center">
                <Typography component="span">
                  {t("common.na")}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={7} lg={7} xl={8}>
              <Typography component="span">
                {""}
              </Typography>
            </Grid>
            <Grid item md={4} lg={4} xl={3}>
              <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
                <Grid item>
                  <Typography component="div" className="width96">
                    <Typography component="strong" className="strong-text">
                      {t("common.select")}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="div" className="width96">
                    <Typography component="strong" className="strong-text">
                      {t("assessment.validation")}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="div" className="width90">
                    <Typography component="strong" className="strong-text">
                      {t("common.comment")}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {
            tabContainerData.subAreaOfWorks.map((subAow: any) => {
              let index1: any = getOriginalIndex("subAreaOfWorks", subAow.subAreaOfWorkId);
              return (
                <FormContext {...methods} key={subAow.subAreaOfWorkId}>
                  <Grid container justify="flex-start" alignItems="center" className="ATD-Row solid-border-bottom">
                    <Grid item md={1} lg={1} xl={1}>
                      <Typography component="div" className="text-center">
                        <FormCheckbox
                          name={`${tabContainerData.areaOfWorkId}-subAow-${index1 + 1}`}
                          label={""}
                          defaultValue={subAow.isNACheck}
                          color="primary"
                          onChange={handleNAChange}
                          className="arRotateY180"
                          disabled={!isEditAssessment}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={11} lg={11} xl={11}>
                      <Typography component="span" className="sub-area-of-work-heading">
                        {index1 + 1}{". "} {subAow.subAreaOfWork}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography component={"div"} className="ATD-Ind-Row solid-border-bottom">
                    {
                      subAow.questionDetails.map((question: any) => {
                        let index2: any = getOriginalIndex("questionDetails", question.topicId);
                        return (
                          question && (
                            <Grid key={question.topicId} container alignItems="center" className="ATD-Row dashed-border-bottom">
                              <Grid item md={1} lg={1} xl={1}>
                                <Typography component={"div"} className="text-center">
                                  <FormCheckbox
                                    name={`${tabContainerData.areaOfWorkId}-topic-${index1 + 1}-${index2 + 1}`}
                                    label={""}
                                    color="primary"
                                    defaultValue={question.isNACheck}
                                    onChange={handleNAChange}
                                    checked={question.isNACheck}
                                    className="arRotateY180"
                                    disabled={!isEditAssessment}
                                  />
                                </Typography>
                              </Grid>

                              <Grid item md={7} lg={7} xl={8}>
                                <Grid container alignItems="center">
                                  <Grid item md={6}>
                                    <Typography component={"div"} className={`topicName ${getBackGroundColor(question)}`}>
                                      <Typography component="span">
                                        {index1 + 1} {"."}{index2 + 1} {" "} {question.topicName}
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Typography component="span">
                                      {question.questionName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item md={4} lg={4} xl={3}>
                                <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
                                  <Grid item>
                                    <Typography component={"div"} className="text-center width96">
                                      <FormCheckbox
                                        name={`${tabContainerData.areaOfWorkId}-select-${index1 + 1}-${index2 + 1}`}
                                        label={""}
                                        color="primary"
                                        defaultValue={question.isQuestionCheck}
                                        checked={question.isQuestionCheck}
                                        disabled={!isEditAssessment || question.isQuestionDisabled}
                                        onChange={handleSelectChange}
                                        className="arRotateY180"
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography component={"div"} className="text-center width96">
                                      <Typography
                                        component="span"
                                        className={`validatorBgBox topicName ${handleValidatorOutput(question).bgColor}`}
                                      >
                                        {handleValidatorOutput(question).text}
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography component={"div"} className="text-center width90">
                                      <Tooltip title={`${t("common.comment")}`}>
                                        <Typography
                                          component="span"
                                          aria-label={t("common.comment")}
                                        >
                                          <CommentIconCustom
                                            onClick={() => handleQuestionComment(question)}
                                            className={
                                              question.validationComment
                                                ? "validationCommentBlue arRotateY180"
                                                : "validationCommentGrey arRotateY180"
                                            }
                                          />
                                        </Typography>
                                      </Tooltip>
                                    </Typography>
                                    {selectedCommentId === question.assessmentDetailId && (
                                      <>
                                        <div className="customOverlay"></div>
                                        <AssessmentQuestionCommentComponent
                                          userId={userId}
                                          questionCommentDialogOpen={
                                            questionCommentDialogOpen
                                          }
                                          questionCommentDialogTitle={
                                            questionCommentDialogTitle
                                          }
                                          hideQuestionCommentDialogForm={
                                            hideQuestionCommentDialogForm
                                          }
                                          questionCommentData={questionCommentData}
                                          loginUserData={loginUserData}
                                          editFormValues={editFormValues}
                                          checkAssessmentStatusLabel={
                                            checkAssessmentStatusLabel
                                          }
                                          isEditAssessment={isEditAssessment}
                                          setQuestionComment={setQuestionComment}
                                          questionComment={questionComment}
                                        ></AssessmentQuestionCommentComponent>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        );
                      })}
                  </Typography>
                </FormContext>
              )
            })
          }

          {
            visibleTopButton &&
            <Tooltip title={`${t("Back to Top")}`}>
              <ScrollToTopButton onClick={scrollToTopDiv}>
                <ArrowUpwardIcon />
              </ScrollToTopButton>
            </Tooltip>
          }

        </AssessmentTabularData >
      </>
    );
  };

  // New Function Handle Question Validation status
  const handleValidationStatusChange = (event: any, question: any) => {
    const elementName = event.target.name;
    const topicNoArray = elementName.split("-");
    const subNo = parseInt(topicNoArray[2]);
    const topicNo = parseInt(topicNoArray[3]);
    const tempLevels: any = [];
    selectedAowData.levels.map((level: any, levelIndex: any) => {
      const tempSubAow: any = [];
      const tempSubAreaOfWorksArray = level.subAreaOfWorks.slice();
      //looping though topics to change validation status for perticuler subAOW
      tempSubAreaOfWorksArray.map((subAow: any, subIndex: any) => {
        const tempQueArr = subAow.questionDetails.slice();
        if (subNo === subIndex + 1) {
          if (levelIndex === tabValue) {
            tempQueArr[topicNo - 1].validationStatusId = event.target.value;
          }
          tempQueArr[topicNo - 1].otherLevelValidationStatusId = event.target.value;
        }
        // creating temp obj of SubAOW to update main AOW obj
        tempSubAow.push({
          ...subAow,
          questionDetails: tempQueArr
        });
      });
      //updating all level 
      tempLevels.push({
        ...level,
        subAreaOfWorks: tempSubAow
      });
    });
    setSelectedAowData({
      ...selectedAowData,
      levels: tempLevels
    });
    handleLevelTabRerenderChange(
      {
        ...selectedAowData,
        levels: tempLevels
      },
      tabValue
    );
    setIsDispatchUpdateCall(true);
  };

  // New Funtion to Handle Question Validation Status color
  const returnQuestionValidationStatusColor = (questionValidationStatus: Number, isDisabled: Boolean = false) => {
    let validationClass: any = "";
    switch (+questionValidationStatus) {
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.AGREE:
        validationClass = `${ASSESSMENT.QUESTION_VALIDATION_STATUS_LABEL.AGREE}Validation`;
        break;
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE:
        validationClass = `${ASSESSMENT.QUESTION_VALIDATION_STATUS_LABEL.DIS_AGREE}Validation`;
        break;
      case 0:
      case ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED:
        validationClass = `${ASSESSMENT.QUESTION_VALIDATION_STATUS_LABEL.NOT_SELECTED}Validation`;
        break;
    }
    if (isDisabled)
      validationClass += " disabledValidation"
    return validationClass;
  };

  const getValidationStatus = (topicId: any) => {
    let isPresent: Number = ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED;
    selectedAowData.levels.some((level: any) => {
      level.subAreaOfWorks.some((sub: any) => {
        sub.questionDetails.some((que: any) => {
          if (que.topicId === topicId) {
            if (que.validationStatusId != ASSESSMENT.QUESTION_VALIDATION_STATUS.NOT_SELECTED) {
              isPresent = que.validationStatusId;
              return
            }
          }
        })
      })
    });
    return isPresent
  }

  // New function get Question View Validator
  const getQuestionView_Validator = () => {
    return (
      <>
        <ValidationTabularData onScroll={toggleVisibleButton} id="scrollableDiv">
          <Grid container alignItems="center" className="VTD-Row solid-border-bottom">
            <Grid item md={1} lg={1} xl={1}>
              <Typography component="div" className="text-center">
                <Typography component="span">
                  {t("common.na")}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={7} lg={7} xl={8}>
              <Typography component="strong" className="strong-text">
                {""}
              </Typography>
            </Grid>
            <Grid item md={4} lg={4} xl={3}>
              <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
                <Grid item className="width100">
                  <Typography component="strong" className="strong-text">
                    {t("common.select")}
                  </Typography>
                </Grid>
                <Grid item className="width124">
                  <Typography component="strong" className="strong-text">
                    {t("assessment.validation")}
                  </Typography>
                </Grid>
                <Grid item className="width90">
                  <Typography component="strong" className="strong-text">
                    {t("common.comment")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {tabContainerData.subAreaOfWorks.map((subAow: any) => {
            let index1: any = getOriginalIndex("subAreaOfWorks", subAow.subAreaOfWorkId);
            return (
              <FormContext {...methods} key={subAow.subAreaOfWorkId}>
                <Grid container justify="flex-start" alignItems="center" className="VTD-Row solid-border-bottom">
                  <Grid item md={1} lg={1} xl={1}>
                    <Typography component="div" className="text-center">
                      <FormCheckbox
                        name={`${tabContainerData.areaOfWorkId}-subAow-${index1 + 1}`}
                        label={""}
                        defaultValue={subAow.isNACheck}
                        color="primary"
                        onChange={handleNAChange}
                        className="arRotateY180"
                        disabled={true}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={11} lg={11} xl={11}>
                    <Typography component="span" className="sub-area-of-work-heading">
                      {index1 + 1}{". "} {subAow.subAreaOfWork}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography component={"div"} className="VTD-Row solid-border-bottom">
                  {
                    subAow.questionDetails.map((question: any) => {
                      let index2: any = getOriginalIndex("questionDetails", question.topicId);
                      const questionValidationStatus = getValidationStatus(question.topicId);
                      return (
                        question && (
                          <Grid key={question.topicId} container alignItems="center" className="VTD-Row dashed-border-bottom">
                            <Grid item md={1} lg={1} xl={1}>
                              <Typography component={"div"} className="text-center">
                                <FormCheckbox
                                  name={`${tabContainerData.areaOfWorkId}-topic${index1 + 1}-${index2 + 1}`}
                                  label={""}
                                  color="primary"
                                  defaultValue={question.isNACheck}
                                  onChange={handleNAChange}
                                  checked={question.isNACheck}
                                  className="arRotateY180"
                                  disabled={true}
                                />
                              </Typography>
                            </Grid>
                            <Grid item md={7} lg={7} xl={8}>
                              <Grid container alignItems="center">
                                <Grid item md={6}>
                                  <Typography component="div" className={`topicName ${getBackGroundColor(question)}`}>
                                    <Typography component="span">
                                      {index1 + 1} {"."}{index2 + 1} {" "} {question.topicName}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item md={6}>
                                  <Typography component="span">
                                    {question.questionName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={4} lg={4} xl={3}>
                              <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
                                <Grid item>
                                  <Typography component={"div"} className="text-center width100">
                                    <FormCheckbox
                                      name={`${tabContainerData.areaOfWorkId}-select${index1 + 1}-${index2 + 1}`}
                                      label={""}
                                      color="primary"
                                      defaultValue={question.isQuestionCheck}
                                      checked={question.isQuestionCheck}
                                      disabled={true}
                                      onChange={handleSelectChange}
                                      className="arRotateY180"
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography component={"div"} className="text-center width124">
                                    <FormControlValidation
                                      className={returnQuestionValidationStatusColor(
                                        questionValidationStatus, !isEditAssessment || question.isQuestionDisabled || question.isNACheck
                                      )}
                                    >
                                      <Select
                                        name={`${tabContainerData.areaOfWorkId}-validate-${index1 + 1}-${index2 + 1}`}
                                        value={questionValidationStatus}
                                        defaultValue={questionValidationStatus || ""}
                                        disabled={!isEditAssessment || question.isQuestionDisabled || question.isNACheck || (!question.isNACheck && !question.isQuestionCheck)}
                                        onChange={(event: any) =>
                                          handleValidationStatusChange(event, question)
                                        }
                                      >
                                        {validationStatusData.map((item: any) => (
                                          <MenuItem key={item.id} value={item.id} className="font-size-14">
                                            {item.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControlValidation>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography component={"div"} className="text-center width90">
                                    <Tooltip title={`${t("common.comment")}`}>
                                      <Typography
                                        component="span"
                                        aria-label={t("common.comment")}
                                      >
                                        <CommentIconCustom
                                          onClick={() => handleQuestionComment(question)}
                                          className={
                                            question.validationComment
                                              ? "validationCommentBlue arRotateY180"
                                              : "validationCommentGrey arRotateY180"
                                          }
                                        />
                                      </Typography>
                                    </Tooltip>
                                  </Typography>
                                  {selectedCommentId === question.assessmentDetailId && (
                                    <>
                                      <div className="customOverlay"></div>
                                      <AssessmentQuestionCommentComponent
                                        userId={userId}
                                        questionCommentDialogOpen={
                                          questionCommentDialogOpen
                                        }
                                        questionCommentDialogTitle={
                                          questionCommentDialogTitle
                                        }
                                        hideQuestionCommentDialogForm={
                                          hideQuestionCommentDialogForm
                                        }
                                        questionCommentData={questionCommentData}
                                        loginUserData={loginUserData}
                                        editFormValues={editFormValues}
                                        checkAssessmentStatusLabel={
                                          checkAssessmentStatusLabel
                                        }
                                        isEditAssessment={isEditAssessment}
                                        setQuestionComment={setQuestionComment}
                                        questionComment={questionComment}
                                        setSelectedAowData={setSelectedAowData}
                                        selectedAowData={selectedAowData}
                                        handleReAssignButton={handleReAssignButton}
                                        areaOfWorkDataList={areaOfWorkListArray}
                                        tabData={tabData}
                                      ></AssessmentQuestionCommentComponent>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      );
                    })
                  }
                </Typography>
              </FormContext>
            )
          })
          }

          {
            visibleTopButton &&
            <Tooltip title={`${t("Back to Top")}`}>
              <ScrollToTopButton onClick={scrollToTopDiv}>
                <ArrowUpwardIcon />
              </ScrollToTopButton>
            </Tooltip>
          }
        </ValidationTabularData >
      </>
    );
  };

  // New Function generate tab panel html data
  const generateTabsPanel = (): any => {
    if (tabData && tabData.length) {
      return tabData.map((item: any, index: any) => {
        return (
          <TabPanel key={index} tabValue={tabValue} index={index}>
            <Typography component="div" className="marginM24">
              {tabContainerData.subAreaOfWorks.length ? (selectedRole.toLowerCase().trim() ===
                USER.ROLE.ASSESSOR.toLowerCase().trim()
                ? getQuestionView_Assessor()
                : getQuestionView_Validator()) : null
              }
            </Typography>
          </TabPanel>
        );
      });
    }
  };

  // New Function  generate Area Of Work List html
  const generateAreaOfWorkList = (): any => {
    if (areaOfWorkList.length) {
      return areaOfWorkList.map((area: any, index: any) => {
        return (
          area && (
            <ListItem
              key={"div" + index}
              className={
                area.id === selectedAreaOfWork ? "selected-area-of-work arTextRight" : "arTextRight"
              }
              onClick={() => {
                handleAreaOfWorkChange(area.id);
              }}
            >
              {
                (selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase().trim()
                  && (selectedAssessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED)
                  && (area.validationStatus))
                &&
                <>
                  <BookmarkIconCustom fillColor={"#C23A22"} />
                </>
              }
              <ListItemText key={"divdes" + area.id} className={(selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase().trim()
                && (selectedAssessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED)
                && (area.validationStatus)) ? "" : ((selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase().trim() && (selectedAssessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED)) ? "marginL20" : "")}>{area.name}</ListItemText>
            </ListItem>
          )
        );
      });
    }
  };

  //handle subarea of work filter
  const handleSubAreaFilterChange = (event: any) => {
    const subArea = event.target.value === 0 ? emptySubAreaOfWork : event.target.value;

    setSubAreOfWorkFilter(subArea);
    setTopics(event.target.value.questionDetails);
    setTopicFilter(emptyTopic)

    const filteredData = getFilteredAOWData(selectedAowData, subArea, emptyTopic);
    let allTopics: any = []
    filteredData.levels[0].subAreaOfWorks.forEach((sub: any) => {
      allTopics = [...allTopics, ...sub.questionDetails]
    });
    setTopics(allTopics);
    handleLevelTabRerenderChange(
      { ...filteredData },
      tabValue
    );
  }

  //handle topic filter
  const handleTopicFilterChange = (event: any) => {
    const topic = event.target.value === 0 ? emptyTopic : event.target.value;
    setTopicFilter(topic);
    const filteredData = getFilteredAOWData(selectedAowData, subAreOfWorkFilter, topic);
    handleLevelTabRerenderChange(
      { ...filteredData },
      tabValue
    );
  }

  return (
    <BoxShadowWrapper>
      <AssessmentSubHeader>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className="padding20-15"
        >
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.assessmentCode")}: `}
            </Typography>
            <Typography component="span">{selectedAssessmentCode}</Typography>
          </Grid>
          <Grid item className="region-info-text">
            <Typography component="strong" className="strong-text">
              {selectedRegion}
              {"  |  "} {selectedCountry}
              {"  |  "} {selectedSupplyChain}
              {"  |  "} {selectedScope}
              {selectedSubRegion && ` |  ${selectedSubRegion}`}
              {"  |  "}{selectedAssessmentType === ASSESSMENT_TYPE_CONST.NORMAL
                ? t("common.normal")
                : t("common.resiliency")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.status")}: `}
            </Typography>
            <Typography component="span">
              {checkAssessmentStatusLabel(selectedAssessmentStatusId)}
            </Typography>
          </Grid>
        </Grid>
      </AssessmentSubHeader>
      <Grid container>
        <Grid item xs={3} xl={2} className="assessment-list">
          <Typography component="div">
            <ListTitle>{t("common.areaOfWork")}</ListTitle>
          </Typography>
          <ListCustom>{generateAreaOfWorkList()}</ListCustom>
        </Grid>
        <Grid item xs={9} xl={10}>
          <TabWrapper>
            <Box className="borderT" py={2}>
              <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                <Grid item xs={12} md={5} lg={6}>
                  <Areaofworklabel>{selectedAreaOfWorkLable} </Areaofworklabel>
                </Grid>
                <Grid item xs={12} md={7} lg={6}>
                  <Grid container justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={5}>
                      <CustomDropdown className="arabic-outlined-dropdown">
                        <FormControl fullWidth={true} variant={"outlined"}>
                          <StyledInputLabel htmlFor={""} className="font-size-14">
                            {t("common.subAreaOfWork")}
                          </StyledInputLabel>
                          <Select
                            value={subAreOfWorkFilter.subAreaOfWorkId === null ? 0 : subAreOfWorkFilter}
                            onChange={handleSubAreaFilterChange}
                            label={t("common.subAreaOfWork")}
                          >
                            <MenuItem value={0}>
                              <Tooltip title={`${t("common.selectAll")}`}>
                                <Typography component={"span"} className="font-size-14">
                                  {t("common.selectAll")}
                                </Typography>
                              </Tooltip>
                            </MenuItem>
                            {
                              allSubAreaOfWork.map((item: any) => (
                                <MenuItem key={item.subAreaOfWorkId} value={item}>
                                  <Tooltip title={item.subAreaOfWork}>
                                    <Typography component={"span"} className="font-size-14">
                                      {item.subAreaOfWork}
                                    </Typography>
                                  </Tooltip>
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </CustomDropdown>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <CustomDropdown className="arabic-outlined-dropdown">
                        <FormControl fullWidth={true} variant={"outlined"}>
                          <StyledInputLabel htmlFor={""} className="font-size-14">
                            {t("common.topics")}
                          </StyledInputLabel>
                          <Select
                            value={topicFilter.topicId === null ? 0 : topicFilter}
                            onChange={handleTopicFilterChange}
                            label={t("common.topics")}
                          >
                            <MenuItem value={0}>
                              <Tooltip title={`${t("common.selectAll")}`}>
                                <Typography component={"span"} className="font-size-14">
                                  {t("common.selectAll")}
                                </Typography>
                              </Tooltip>
                            </MenuItem>
                            {
                              allTopics.map((item: any) => (
                                <MenuItem key={item.topicId} value={item}>
                                  <Tooltip title={item.topicName}>
                                    <Typography component={"span"} className="font-size-14">
                                      {item.topicName}
                                    </Typography>
                                  </Tooltip>
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </CustomDropdown>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                <Grid item>
                  {
                    (selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase().trim() && (selectedAssessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED))
                    &&
                    <>
                      <BookmarkIconCustom fillColor={"#C23A22"} />
                      <Typography component={"span"}>{t("common.disAgree")}</Typography>
                    </>
                  }
                </Grid>
                <Grid item>
                  <LevelsLegend>
                    <Grid container alignItems="center" justify="flex-end" spacing={2}>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-na">{t("common.na")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level0">{t("level.level0")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level1">{t("level.level1")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level2">{t("level.level2")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level3">{t("level.level3")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level4">{t("level.level4")}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component={"span"} className="legend-item legend-level5">{t("level.level5")}</Typography>
                      </Grid>
                    </Grid>
                  </LevelsLegend>
                </Grid>
              </Grid>
            </Box>
            <Typography component={"div"}>
              <EditAssessmentAppBar position="static">
                <Tabs
                  value={tabValue}
                  onChange={handleLevelTabChange}
                  aria-label="simple tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {generateTabs()}
                </Tabs>
              </EditAssessmentAppBar>
              {generateTabsPanel()}
            </Typography>
          </TabWrapper>
        </Grid>
      </Grid >
    </BoxShadowWrapper >
  );
}
export default AssesmentQuestionTabsComponent;
