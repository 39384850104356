export const AUTH_TOKEN: string = "AUTH_TOKEN";
export const APP_NAME: string = "VFCalc";
export const BASE_PATH: string = (window as any).SiteSettings.API_BASE_PATH;

// ROUTES
export const NEW_REQUEST: string = "/new-request";
export const ALL_REQUESTS: string = "/all-request";
export const ABOUT_US: string = "/about-us";
export const PRE_LOGIN: string = "/pre-login";
export const UN_REGISTERD_USER: string = "/un-register-user";
export const MASTER: string = "/master";
export const QUESTION: string = "/question";
export const USER_PROFILE: string = "/users";
export const ASSESMENT: string = "/assessment";
export const HOME_PATH: string = "/";
export const HOME_PATH_AFTER_LOGIN: string = "/home";
export const ASSESSMENT_OVERVIEW: string = "/overview";
export const MATURITY_INDEX: string = "/maturityindex";
export const HOW_TO_JOIN: string = "/how-to-join";
export const AREA_WORK_TOOL = "/areaofwork-tools";
export const REPORT: string = "/report";
export const VC_REPORT: string = "/vc-report"
export const HOME: string = "/ui-home";
export const LOGIN_PATH: string = "/Home/Index";
export const ERROR_PATH: string = "/ui-error";
export const SIGN_OUT_PATH: string = "/api/accounts/SignOut";
export const AOW_MANAGEMENT: string = "/aow-management";
export const COUNTRY_HIERARCHY: string = `/country-hierarchy`
export const VC_ASSESMENT: string = "/vc-assessment";
