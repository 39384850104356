import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchData } from "./api";
import { actionFetchDataSuccess } from "./actions";
import { ActionTypes } from "./constants";

function* watchGetData() {
  yield takeLatest(ActionTypes.ALL_REQUESTS_FETCH_DATA, getData);
}

function* getData(action: any) {
  try {
    const response = yield call(fetchData);
    const responseData = response.data;
    yield put(actionFetchDataSuccess(responseData));
  } catch (err) {
    console.log(err);
  }
}

export default function* allRequestSaga() {
  yield all([watchGetData()]);
}
