import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Typography } from "@material-ui/core";

const CarouselComponent = (props: any) => {
    const { imageList } = props;
    const imageSlideTime: any = (window as any).imageSlideTime;

    const imageListing = imageList.map((item: any) => {
        return (
            <Typography component="div" key={item.id}>
                <Typography component="img" src={item.url} alt={item.caption} loading="lazy" />
            </Typography>
        )
    });

    return (
        <>
            <Carousel
                animationHandler={"fade"}
                autoPlay={true}
                interval={imageSlideTime}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                stopOnHover={true}
            >
                {imageListing}
            </Carousel>
        </>
    );
};
export default CarouselComponent;