import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionFetchMasterAreaOfWorkToolsData, actionFetchMasterAreaOfWorkToolSingleDataSuccess } from "../actions";
import AreaOfWorkToolGrid from "./area-of-work-tool-table/index";
import { Grid, Typography } from "@material-ui/core";
import AreaOfWorkFormComponent from "./area-of-work-form";
import { Container } from "@material-ui/core";
import { BoxShadowWrapper, SubHeaderWrapper } from "styles/common";
import { useTranslation } from "react-i18next";
import { setLoaderVisibility } from "store/common/actions";

function AreaOfWorkToolComponent(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const masterAreaOfWorkToolsData = useSelector(
    (state: any) => state.masterForm.masterAreaOfWorkToolsData
  );
  const loginUserData = useSelector(
    (state: any) => state.header.loginUserData
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const areaOfWorklist = useSelector(
    (state: any) => state.common.listAreaOfWork
  );
  const subAreaOfWorklist = useSelector(
    (state: any) => state.common.listSubAreaOfWork
  );
  const applicationsList = useSelector(
    (state: any) => state.scsHub.applicationsList
  );

  const [userId, setUserId] = useState("");
  const [showGrid, setShowGrid] = useState(true);
  const [IsEdit, setIsEdit] = useState(false);
  const [gridsData, setGridsData]: any = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [currentItem, setCurrentItem] = useState({});
  const [expandedAOWId, setExpandedAOWId] = useState(0);
  const [expandedSubAOWId, setExpandedSubAOWId] = useState(0);

  useEffect(() => {
    if (loginUserData) {
      setUserId(loginUserData.id);
    }
  }, [loginUserData]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    dispatch(actionFetchMasterAreaOfWorkToolsData());
  }, [dispatch]);

  useEffect(() => {
    if (masterAreaOfWorkToolsData.length) {
      setGridsData(masterAreaOfWorkToolsData);
      setFirstTimeCall(false);
    }
  }, [masterAreaOfWorkToolsData]);

  useEffect(() => {
    if (masterAreaOfWorkToolsData.length && firstTimeCall === false) {
      let areaOfWorkToolsList: any = [];
      areaOfWorkToolsList = masterAreaOfWorkToolsData.filter((x: any) => x.language == languageSelected);
      setGridsData(areaOfWorkToolsList);
      dispatch(setLoaderVisibility(false));
    }
    dispatch(actionFetchMasterAreaOfWorkToolsData());
  }, [languageSelected]);


  const showHideGridAndForm = (request: boolean) => {
    setShowGrid(!request);
    setIsEdit(false);
    if (request === false) {
      setCurrentItem(false);
      dispatch(actionFetchMasterAreaOfWorkToolSingleDataSuccess(null));
      setIsEdit(false);
    }
  };

  const editFormvalues = (request: boolean) => {
    setIsEdit(request);
  };


  return (
    <Container maxWidth={"xl"}>
      {
        showGrid ? (
          <>
            <SubHeaderWrapper>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography component="span" className="common-label">
                    {t("areaOfWorkTool.areaOfWorkTools")}
                  </Typography>
                </Grid>
              </Grid>
            </SubHeaderWrapper>
            <BoxShadowWrapper>
              <AreaOfWorkToolGrid
                editFormvalues={editFormvalues}
                showHideGridAndForm={showHideGridAndForm}
                userId={userId}
                gridsData={gridsData}
                setCurrentItem={setCurrentItem}
                currentItem={currentItem}
                expandedAOWId={expandedAOWId}
                setExpandedAOWId={setExpandedAOWId}
                expandedSubAOWId={expandedSubAOWId}
                setExpandedSubAOWId={setExpandedSubAOWId}
              />
            </BoxShadowWrapper>
          </>
        ) : (
          <>
            <AreaOfWorkFormComponent
              editFormvalues={editFormvalues}
              IsEdit={IsEdit}
              showHideGridAndForm={showHideGridAndForm}
              areaOfWorklist={areaOfWorklist}
              userId={userId}
              subAreaOfWorklist={subAreaOfWorklist}
              setCurrentItem={setCurrentItem}
              currentItem={currentItem}
              applicationsList={applicationsList}
            />
          </>
        )}
    </Container>
  );
}

export default AreaOfWorkToolComponent;
