import { Box, Button, Grid, Tooltip, Typography } from "@material-ui/core";
import { ASSESSMENT } from "constants/data";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab"; import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BoxShadowWrapper, DownloadIconCustom, ScmmAppBar } from "styles/common";
import AssessmentTableComponent from "../assessment-table";
import AssessmentOverviewTools from "containers/assessment-overview-tools";
import { actionExportAssesmentResultData } from "../actions";
import { useDispatch } from "react-redux";
import AssessmentOverviewDetails from "containers/assessment-overview-details";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function AssessmentTabComponent(props: any) {
    const {
        assesmentData,
        setIsEditAssessment,
        functionEditAssesmentDetailData,
        formTitle,
        functionAssesmentLevelData,
        setCanShowOverview,
        canShowOverview,
        checkAssessmentStatusLabel,
        setAssessmentTotalCount,
        setTabValueSelected,
        tabValueSelected,
    } = props;

    const { t } = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);
    const [editorDraftData, setEditorDraftData] = useState([]);
    const [lableEditorDraft, setlableEditorDraft]: any = useState(0);
    const [submittedData, setSubmittedData] = useState([]);
    const [lableSubmitted, setlableSubmittedData]: any = useState(0);
    const [selectedAssessmentRow, setSelectedAssessmentRow] = useState();
    const [assessmentStatusId, setAssessmentStatusId] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const assDraftData = assesmentData.filter(
            (assesement: any) =>
                assesement.assessmentStatusId === ASSESSMENT.STATUS.DRAFT
        );
        setEditorDraftData(assDraftData);
        setlableEditorDraft(`(${assDraftData.length})`);

        const assReAssgined = assesmentData.filter(
            (assesement: any) =>
                assesement.assessmentStatusId === ASSESSMENT.STATUS.SUBMITTED
        );
        setSubmittedData(assReAssgined);
        setlableSubmittedData(`(${assReAssgined.length})`);
        setAssessmentTotalCount(assesmentData.length);
        setTabValue(tabValueSelected);
    }, [assesmentData]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (tabValue === newValue) {
            setCanShowOverview(canShowOverview);
        } else {
            setCanShowOverview(false);
        }
        setTabValue(newValue);
        setTabValueSelected(newValue);
    };

    const showReportAssesment = () => {
        // this function will used for export excel.
        dispatch(actionExportAssesmentResultData(selectedAssessmentRow));
    };

    return (
        <>
            <BoxShadowWrapper>
                <ScmmAppBar position="static">
                    <Tabs value={tabValue} onChange={handleChange} aria-label="Tabs">
                        <Tab
                            label={`${t("assessment.vcAssessorDraft")} ${lableEditorDraft}`}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={`${t("assessment.submitted")} ${lableSubmitted}`}
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </ScmmAppBar>
                <TabPanel value={tabValue} index={0}>
                    <Typography component="div" className="marginM24">
                        <AssessmentTableComponent
                            isEditAssessment={true}
                            assessmentFilterData={editorDraftData}
                            isDraftTab={true}
                            setIsEditAssessment={setIsEditAssessment}
                            functionEditAssesmentDetailData={functionEditAssesmentDetailData}
                            functionAssesmentLevelData={functionAssesmentLevelData}
                            setCanShowOverview={setCanShowOverview}
                            formTitle={formTitle}
                            setSelectedAssessmentRow={setSelectedAssessmentRow}
                            setAssessmentStatusId={setAssessmentStatusId}
                        />
                    </Typography>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Typography component="div" className="marginM24">
                        <AssessmentTableComponent
                            isEditAssessment={false}
                            assessmentFilterData={submittedData}
                            isDraftTab={false}
                            setIsEditAssessment={setIsEditAssessment}
                            functionEditAssesmentDetailData={functionEditAssesmentDetailData}
                            functionAssesmentLevelData={functionAssesmentLevelData}
                            setCanShowOverview={setCanShowOverview}
                            formTitle={formTitle}
                            setAssessmentStatusId={setAssessmentStatusId}
                            setSelectedAssessmentRow={setSelectedAssessmentRow}
                        />
                    </Typography>
                </TabPanel>
            </BoxShadowWrapper>
            {canShowOverview &&
                <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                        <Tooltip title={`${t("common.downloadReport")}`}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => showReportAssesment()}
                            >
                                <DownloadIconCustom className="arMarginR0 arMarginL10" />
                                {t("common.exportToExcel")}
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>}
            {
                assessmentStatusId === ASSESSMENT.STATUS.SUBMITTED ? (
                    <>
                        <AssessmentOverviewTools
                            canShowOverview={canShowOverview}
                            checkAssessmentStatusLabel={checkAssessmentStatusLabel}
                        ></AssessmentOverviewTools>
                    </>) : (
                    <>
                        <AssessmentOverviewDetails
                            canShowOverview={canShowOverview}
                            checkAssessmentStatusLabel={checkAssessmentStatusLabel}
                        ></AssessmentOverviewDetails>
                    </>)
            }

        </>
    );
};


