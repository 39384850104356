import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VisibilityIconCustom,
  EditIconCustom,
  BoxShadowWrapper,
  IndicatorsSubHeader,
  AddIconCustom,
  DeleteIconCustom,
  DialogClose,
  DialogButtonPrimary,
  ArrowForwardIosIconCustom,
  TableHeadIndicator,
} from "../../../styles/common";
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableRow, TableCell, } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./index.css";
import MaterialTable from "material-table";
import { AOW_MANAGEMENT_CONST } from "constants/data";
import { ConfirmDialog } from "components/common/confirm-dialog.component";
import { actionDeleteIndicatorSetTopic, actionSaveNonAssignedTopics } from "../actions";
import { useDispatch } from 'react-redux';
import { FormContext, useForm } from "react-hook-form";
import * as yup from "yup";
import FormSelect from "components/form-controls/select";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from 'react-redux';
import { commonHeaderStyle } from "../question-set-table/styles";

function QuestionBySetIdTableComponent(props: any) {
  const {
    questionBySetIdData,
    showQuestionTable,
    isHistoryMode,
    questionSetGenCode,
    supplyChainName,
    scopeName,
    questionSetAreaOfWork,
    questionSetId,
    listAreaOfWork,
    listSubAreaOfWork,
    setTopicName,
    setSubAOWName,
    setAowName
  } = props;
  const dispatch = useDispatch();
  const nonAssignedTopicList = useSelector(
    (state: any) => state.question.nonAssignedTopicList
  );
  const { t } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteForm, setDeleteForm]: any = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [currentRowData, setCurrentRowData]: any = useState(null);
  const [expandedAOWId, setExpandedAOWId] = useState(0);
  const [expandedSubAOWId, setExpandedSubAOWId] = useState(0);

  const validationSchema = yup.object().shape({
    topic: yup.string().trim().required(t("validationMessage.nameValidation"))
  });

  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { handleSubmit, errors } = methods;

  const handlerEditRowData = (rowData: any) => {
    const filterAow = listAreaOfWork.filter((item: any) => item.id.toString() === rowData.areaOfWorkId.toString());
    setAowName(filterAow[0].label)
    const filterSubAow = listSubAreaOfWork.filter((item: any) => item.id.toString() === rowData.subAreaOfWorkId.toString());
    setSubAOWName(filterSubAow[0].label)
    setTopicName(rowData.topicName);
    showQuestionTable(rowData);
  };

  const isLastTopicToDelete = (rowData: any) => {
    const topics = questionBySetIdData.dynamicRowDataList.filter((row: any) => (
      rowData.areaOfWorkId === row.areaOfWorkId &&
      rowData.subAreaOfWorkId === row.subAreaOfWorkId &&
      rowData.topicId && row.topicId &&
      rowData.topicId !== row.topicId
    ))
    return !topics.length ? true : false
  }

  const handlerDeleteRowData = (event: any, rowData: any) => {
    setExpandedAOWId(rowData.areaOfWorkId);
    setExpandedSubAOWId(rowData.subAreaOfWorkId)
    setOpenConfirmDialog(true);
    setDeleteForm({
      id: rowData.indicatorTopicId,
      questionSetId: questionSetId
    });
  };

  const handlerCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  }

  const deleteTopicItem = () => {
    dispatch(actionDeleteIndicatorSetTopic(deleteForm));
    handlerCloseConfirmDialog();
  }

  const dialogOpenFunction = () => {
    setDialogOpen(true);
  };

  const addTopicList = (event: any, rowData: any) => {
    setExpandedAOWId(rowData.areaOfWorkId);
    setExpandedSubAOWId(rowData.subAreaOfWorkId)
    setCurrentRowData(rowData);
    const filterData = nonAssignedTopicList.filter((item: any) => item.id === rowData.id);
    if (filterData.length) {
      const tempTopicList = filterData[0].topics.map((topic: any) => {
        return {
          id: topic.id,
          label: topic.name
        }
      })
      setTopicList(tempTopicList);
    }
    dialogOpenFunction();
  }

  const dialogClose = () => {
    setDialogOpen(false);
  };
  const onSubmitTopicAdd = (data: any) => {
    const formData = {
      "questionSetId": questionSetId,
      "topicId": data.topic,
      "areaOfWorkId": currentRowData.areaOfWorkId,
      "subAreaOfWorkId": currentRowData.subAreaOfWorkId
    }
    dispatch(actionSaveNonAssignedTopics(formData))
    dialogClose();
  }
  const checkAddTopicDisable = (rowData: any) => {
    const filterData = nonAssignedTopicList.filter((item: any) => item.id === rowData.id);
    if (filterData.length) {
      if (filterData[0].topics.length) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  return (
    <>

      <BoxShadowWrapper>
        <IndicatorsSubHeader>
          <Grid container alignItems="center" justify="space-between" wrap="nowrap" className="padding20-15" spacing={2}>
            <Grid item>
              <Typography component="strong">
                {`${t("indicators.indicatorSetCode")}: `}
              </Typography>
              <Typography component="span">{questionSetGenCode}</Typography>
            </Grid>
            <Grid item>
              <Typography component="strong">
                {`${t("common.program")}: `}
              </Typography>
              <Typography component="span">{supplyChainName}</Typography>
            </Grid>
            {
              scopeName !== '' ?
                (<Grid item>
                  <Typography component="strong" className="strong-text">
                    {`${t("common.scope")}: `}
                  </Typography>
                  <Typography component="span">{scopeName}</Typography>
                </Grid>)
                : (
                  <Grid item>
                    <Typography component="strong" className="strong-text">
                      {`${t("common.areaOfWork")}: `}
                    </Typography>
                    <Typography component="span">{questionSetAreaOfWork}</Typography>
                  </Grid>
                )
            }
          </Grid>
        </IndicatorsSubHeader>
        <Typography component="div" className="indicatorTblWrapper level-text-center">
          <MaterialTable
            columns={[
              {
                title: t("common.areaOfWork"),
                field: "areaOfWork",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("common.subAreaOfWork"),
                field: "subAreaOfWork",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("common.topic"),
                field: "topicName",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("level.level1"),
                field: "level1",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("level.level2"),
                field: "level2",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("level.level3"),
                field: "level3",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("level.level4"),
                field: "level4",
                cellStyle: {
                  padding: "10px 15px"
                }
              },
              {
                title: t("level.level5"),
                field: "level5",
                cellStyle: {
                  padding: "10px 15px"
                }
              }
            ]}
            data={questionBySetIdData.dynamicRowDataList}
            options={{
              filtering: false,
              paging: false,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
              draggable: false,
              search: false,
              sorting: false,
              toolbar: false,
              headerStyle: commonHeaderStyle,
              defaultExpanded: (rowData: any) => (rowData.id === expandedAOWId || rowData.id === expandedSubAOWId)
                ? true
                : false,
            }}
            parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}

            actions={[
              (rowData: any) => ({
                icon: () => isHistoryMode ? <VisibilityIconCustom /> : <EditIconCustom />,
                onClick: (event, rowData) => handlerEditRowData(rowData),
                tooltip: isHistoryMode ? t("common.view") : t("common.edit"),
                hidden: rowData.type !== AOW_MANAGEMENT_CONST.TOPIC ? true : false
              }),
              (rowData: any) => ({
                icon: () => <AddIconCustom />,
                tooltip: t("master.addTopic"),
                onClick: (event, rowData: any) => { addTopicList(event, rowData) },
                hidden: (rowData.type !== AOW_MANAGEMENT_CONST.SUB_AOW || isHistoryMode),
                disabled: checkAddTopicDisable(rowData)
              }),
              (rowData: any) => ({
                icon: () => <DeleteIconCustom />,
                tooltip: t("master.deleteTopic"),
                onClick: (event, rowData: any) => { handlerDeleteRowData(event, rowData) },
                hidden: (rowData.type !== AOW_MANAGEMENT_CONST.TOPIC || isHistoryMode) ? true : false,
                disabled: isLastTopicToDelete(rowData)
              }),
            ]}
            localization={{
              body: {
                filterRow: {
                  filterTooltip: t("pagination.filter"),
                },
                emptyDataSourceMessage: t("common.noDataAvailable")
              },
              pagination: {
                firstTooltip: t("pagination.firstPage"),
                previousTooltip: t("pagination.prevPage"),
                nextTooltip: t("pagination.nextPage"),
                lastTooltip: t("pagination.lastPage"),
                labelRowsSelect: t("common.rows"),
              },
            }}
            icons={{
              DetailPanel: forwardRef((props: any, ref: any) => (
                <ArrowForwardIosIconCustom {...props} ref={ref} />
              ))
            }}
            components={{
              Header: () => {
                return (
                  <>
                    <TableHeadIndicator>
                      <TableRow>
                        <TableCell colSpan={4}>&nbsp;</TableCell>
                        <TableCell colSpan={5} className="text-center">{t("indicators.indicatorNoMsg")}</TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="black-bg">&nbsp;</TableCell>
                        <TableCell className="black-bg">{t("common.areaOfWork")}</TableCell>
                        <TableCell className="black-bg">{t("common.subAreaOfWork")}</TableCell>
                        <TableCell className="black-bg">{t("common.topic")}</TableCell>
                        <TableCell className="blue-bg">{t("level.level1")}</TableCell>
                        <TableCell className="blue-bg">{t("level.level2")}</TableCell>
                        <TableCell className="blue-bg">{t("level.level3")}</TableCell>
                        <TableCell className="blue-bg">{t("level.level4")}</TableCell>
                        <TableCell className="blue-bg">{t("level.level5")}</TableCell>
                        <TableCell className="blue-bg">{t("common.actions")}</TableCell>
                      </TableRow>
                    </TableHeadIndicator>
                  </>
                )
              }
            }}
          />
        </Typography>
        <>
          <Dialog
            open={dialogOpen}
            onClose={dialogClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth={"xs"}
            fullWidth={true}
          >
            <>
              <DialogTitle id="alert-dialog-title">
                {t("master.addTopic")}
                <DialogClose aria-label="close" onClick={dialogClose}>
                  <CloseIcon />
                </DialogClose>
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText component="div" id="alert-dialog-description" className="nomargin">
                  <FormContext {...methods}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <FormSelect
                          name="topic"
                          options={topicList}
                          hideselectoption="true"
                          label={t("common.topic")}
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                    </Grid>
                  </FormContext>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="padding16-24">
                <Grid container alignItems="center" justify="flex-end" spacing={2}>
                  <Grid item>
                    <DialogButtonPrimary
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onSubmitTopicAdd)}
                    >
                      {t("common.add")}
                    </DialogButtonPrimary>
                  </Grid>
                </Grid>
              </DialogActions>
            </>
          </Dialog>
        </>
        <ConfirmDialog
          open={openConfirmDialog}
          close={handlerCloseConfirmDialog}
          title={t("indicators.topicDeleteIndictorMsg")}
          content=""
          yes={t("common.yes")}
          no={t("common.cancel")}
          ok={deleteTopicItem}
        />
      </BoxShadowWrapper>
    </>
  );
}

export default QuestionBySetIdTableComponent;
