import { ActionTypes } from "./constants";

export const actionMasterGetQuestionSetAreaOfWork = () => ({
    payload: {},
    type: ActionTypes.MASTER_GET_QUESTION_SET_AREA_OF_WORK,
});

export const actionMasterGetQuestionSetAreaOfWorkSuccess = (data: any) => ({
    payload: { data },
    type: ActionTypes.MASTER_GET_QUESTION_SET_AREA_OF_WORK_SUCCESS,
});

export const actionUpdateAssesmentSubmitedStatusData = (
    assesmentData: any
) => ({
    payload: { assesmentData },
    type: ActionTypes.ASSESMENT_SUBMITTED_STATUS_DATA,
});

export const actionUpdateAssesmentSubmitedStatusDataSuccess = (
    data: any
) => ({
    payload: {
        data,
    },
    type:
        ActionTypes.ASSESMENT_SUBMITTED_STATUS_DATA_SUCCESS,
});

export const actionExportAssesmentResultData = (assessmentId: any) => (
    {
        payload: { assessmentId },
        type: ActionTypes.EXPORT_SELECTED_ASSESSMENT_DATA,
    }
)

export const actionDeleteAssessment = (data: any) => ({
    payload: { data },
    type: ActionTypes.ASSESSMENT_DELETE_BY_ID,
});

