import { ActionTypes } from "./constants";

export const actionFetchData = () => ({
  payload: {},
  type: ActionTypes.ALL_REQUESTS_FETCH_DATA,
});

export const actionFetchDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.ALL_REQUESTS_FETCH_DATA_SUCCESS,
});

export const actionSetUserDetails = (emailId: any, name: any, roleId: any) => ({
  payload: {
    emailId,
    name,
    roleId,
  },
  type: ActionTypes.ALL_REQUESTS_SET_USER_DETAILS,
});
