import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowDropDownIconCustom,
  ArrowDropUpIconCustom,
  BoxShadowWrapper,
  RemoveIconCustom,
} from "styles/common";
import { useTranslation } from "react-i18next";
import FormSelect from "components/form-controls/select";
import { ASSESSMENT } from "constants/data";
import { commonCellStyle, commonHeaderStyle, } from "../../../styles/materialStyles";
import MaterialTable from "material-table";
import { setLoaderVisibility } from "store/common/actions";

function AssessmentOverviewScoreComponent(props: any) {
  const {
    programList,
    selectedCountryIdList,
    getProgramData,
    isDefaultCallProgramScoreData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm();
  const { setValue, getValues } = methods;
  const programCurrentScoreData = useSelector(
    (state: any) => state.assesmentCreate.ProgramCurrentScoreData
  );
  const [programScore, setProgramScore]: any = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  useEffect(() => {
    if (programList.length) {
      const programValue = getValues("program");
      if (!programValue) {
        setValue("program", programList[0].id);
        if (isDefaultCallProgramScoreData) {
          getProgramData(
            ASSESSMENT.STATUS.VALIDATED,
            selectedCountryIdList,
            programList[0].id
          );
          setFirstTimeCall(false);
        }
      }
    }
  }, [programList]);

  useEffect(() => {
    if (firstTimeCall === false) {
      const programValue = getValues("program");
      getProgramData(
        ASSESSMENT.STATUS.VALIDATED,
        selectedCountryIdList,
        programValue
      );
    }
  }, [languageSelected]);

  useEffect(() => {
    if (programCurrentScoreData) {
      if (programCurrentScoreData.length) {
        setProgramScore(
          programCurrentScoreData[0].assessmentScoreViewModelList
        );
      } else {
        setProgramScore([]);
      }
      dispatch(setLoaderVisibility(false));
    }
  }, [programCurrentScoreData]);

  const handelProgramChange = ([event]: any) => {
    getProgramData(
      ASSESSMENT.STATUS.VALIDATED,
      selectedCountryIdList,
      event.target.value
    );
    return event.target.value;
  };

  return (
    <>
      {programList.length ? (
        <FormContext {...methods}>
          <BoxShadowWrapper className="padding16-24 minHeight310">
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className="margin-bottom-20"
            >
              <Grid item sm={1} className="margin-top-16">
                <Typography component="strong" className="strong-text">
                  {t("common.program")}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <FormSelect
                  name="program"
                  options={programList}
                  onChange={handelProgramChange}
                  hideselectoption="true"
                />
              </Grid>
            </Grid>
            <>
              <MaterialTable
                columns={[
                  {
                    title: t("common.areaOfWork"),
                    field: "areaOfWork",
                    filtering: false,
                    cellStyle: commonCellStyle,
                  },
                  {
                    title: t("common.baselineScore"),
                    field: "baseLineScore",
                    filtering: false,
                    headerStyle: {
                      textAlign: "center",
                    },
                    cellStyle: {
                      ...commonCellStyle,
                      textAlign: "center",
                    },
                  },
                  {
                    title: t("common.currentScore"),
                    field: "currentScore",
                    filtering: false,
                    headerStyle: {
                      textAlign: "center",
                    },
                    cellStyle: {
                      ...commonCellStyle,
                      textAlign: "center",
                    },
                  },
                  {
                    title: t("common.progress"),
                    filtering: false,
                    headerStyle: {
                      textAlign: "center",
                    },
                    cellStyle: {
                      ...commonCellStyle,
                      textAlign: "center",
                    },
                    render: (rowData) => {
                      return rowData.baseLineScore < rowData.currentScore ? (
                        <ArrowDropUpIconCustom />
                      ) : rowData.baseLineScore === rowData.currentScore ? (
                        <RemoveIconCustom />
                      ) : (
                        <ArrowDropDownIconCustom />
                      );
                    },
                  },
                ]}
                data={programScore}
                options={{
                  paging: false,
                  emptyRowsWhenPaging: false,
                  showTitle: false,
                  actionsColumnIndex: -1,
                  draggable: false,
                  search: false,
                  toolbar: false,
                  actionsCellStyle: commonCellStyle,
                  headerStyle: commonHeaderStyle,
                }}
                localization={{
                  body: {
                    filterRow: {
                      filterTooltip: t("pagination.filter"),
                    },
                    emptyDataSourceMessage: t("common.noDataAvailable")
                  },
                  pagination: {
                    firstTooltip: t("pagination.firstPage"),
                    previousTooltip: t("pagination.prevPage"),
                    nextTooltip: t("pagination.nextPage"),
                    lastTooltip: t("pagination.lastPage"),
                    labelRowsSelect: t("common.rows"),
                  },
                }}
              />
            </>
          </BoxShadowWrapper>
        </FormContext>
      ) : (
        <BoxShadowWrapper className="padding16-24">
          <Grid
            container
            justify="center"
            alignItems="center"
            className="minHeight310"
          >
            <Grid item sm={12} className="text-center">
              <Typography component="strong" className="strong-text">
                {t("newAssessmentOverview.noAssessmentsAvailable")}
              </Typography>
            </Grid>
          </Grid>
        </BoxShadowWrapper>
      )}
    </>
  );
}

export default AssessmentOverviewScoreComponent;
