
export const ActionTypes = {
    MASTER_GET_QUESTION_SET_AREA_OF_WORK: "MASTER_GET_QUESTION_SET_AREA_OF_WORK",
    MASTER_GET_QUESTION_SET_AREA_OF_WORK_SUCCESS: "MASTER_GET_QUESTION_SET_AREA_OF_WORK_SUCCESS",
    ASSESMENT_SUBMITTED_STATUS_DATA: "ASSESMENT_SUBMITTED_STATUS_DATA",
    ASSESMENT_SUBMITTED_STATUS_DATA_SUCCESS: "ASSESMENT_SUBMITTED_STATUS_DATA_SUCCESS",
    EXPORT_SELECTED_ASSESSMENT_DATA: "EXPORT_SELECTED_ASSESSMENT_DATA",
    ASSESSMENT_DELETE_BY_ID: "ASSESSMENT_DELETE_BY_ID"
}

export const GET_QUESTION_SET_AREA_OF_WORK: string = `api/QuestionSet/GetQuestionSetAreaOfWork`;
export const UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS: string = `/api/Assessment/UpdateAssessmentDetailsValidationStatus`;
export const GET_EXPORTED_ASSESSMENT_DATA: string = `/api/Assessment/ExportAssessmentScoring`;
export const DELETE_ASSESSMENT: string = `/api/Assessment/Delete`;
