import React, { forwardRef, useEffect, useState } from "react";
import Container from "@material-ui/core/Container/Container";
import { Box, Dialog, Grid, Tooltip, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import { commonCellStyle, commonHeaderStyle } from "../../../styles/materialStyles";
import { useTranslation } from "react-i18next";
import { EditIconCustom, AddIconCustom, DeleteIconCustom, SubHeaderWrapper, BoxShadowWrapper, CommonBigCard, ArrowForwardIosIconCustom, CustomDropdown } from "../../../styles/common";
import { useDispatch, useSelector } from 'react-redux';
import { actionDeleteMasterCountryHierarchyData, actionFetchAOWtopicHierarchy, actionFetchMasterCountryHierarchyData, actionFetchMasterCountryHierarchyDataSuccess, } from "../actions";
import { FormContext, useForm } from "react-hook-form";
import HierarchyCreateFormComponent from "./hierarchy-createform";
import { COUNTRY_HIERARCHY_CONST } from "constants/data";
import { ConfirmDialog } from "components/common/confirm-dialog.component";
import FormSelect from "components/form-controls/select";


function CountryHierarchyComponent(props: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const methods = useForm();
    const aowHirerarchyData = useSelector(
        (state: any) => state.masterForm.aowHirerarchyData
    );
    const languageSelected = useSelector(
        (state: any) => state.header.languageSelected
    );
    const listOrgWiseCountry = useSelector(
        (state: any) => state.common.listOrgWiseCountry
    );
    const countryHierarchyData = useSelector(
        (state: any) => state.masterForm.countryHierarchyData
    )
    const [countryHierarchy, setCountryHierarchy] = useState([]);
    const [IsEdit, setIsEdit] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [countryId, setCountryId] = useState();
    const [currentType, setCuurentType] = useState(0);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteForm, setDeleteForm]: any = useState(null);
    const [expandedSubNationalId, setExpandedSubNationalId] = useState("0");

    useEffect(() => {
        return () => {
            dispatch(actionFetchMasterCountryHierarchyDataSuccess([]));
        };
    }, []);

    useEffect(() => {
        if (languageSelected) {
            dispatch(actionFetchAOWtopicHierarchy());
        }
    }, [languageSelected]);

    useEffect(() => {
        if (countryHierarchyData.length) {
            setCountryHierarchy(countryHierarchyData);
        } else {
            setCountryHierarchy([]);
        }
    }, [countryHierarchyData])

    const handlerEditRowData = (event: any, rowData: any) => {
        setExpandedSubNationalId(rowData.parentId);
        setIsEdit(true);
        setCuurentType(rowData.type);
        setCurrentItem(rowData);
        showDialoagForm();
    };


    const handlerAddRowData = (event: any, rowData: any) => {
        setExpandedSubNationalId(rowData.id);
        setIsEdit(false);
        setCuurentType(rowData.type + 1);
        setCurrentItem(rowData);
        showDialoagForm();
    };

    const handlerDeleteRowData = (event: any, rowData: any) => {
        setExpandedSubNationalId(rowData.parentId);
        setOpenConfirmDialog(true);
        setCuurentType(rowData.type);
        const form = {
            "id": rowData.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? rowData.subnationalId : rowData.districtId,
            "isDeleted": true,
            "countryId": countryId
        }
        setDeleteForm(form);
    };

    const deleteRowItem = () => {
        dispatch(actionDeleteMasterCountryHierarchyData(deleteForm));
        handlerCloseConfirmDialog();
    }
    const handelCountryChange = ([event]: any) => {
        let selectedCountryValue = event.target.value;
        if (selectedCountryValue) {
            setCountryId(selectedCountryValue);
            dispatch(actionFetchMasterCountryHierarchyData(selectedCountryValue));
            return selectedCountryValue;
        }
        else {
            setCountryHierarchy([]);
        }
    }

    const showDialoagForm = () => {
        setDialogOpen(true);
    };

    const dialogClose = () => {
        setIsEdit(false);
        setCuurentType(0);
        setCurrentItem({});
        setDialogOpen(false);
    };

    const hideDialoagForm = () => {
        setDialogOpen(false);
    };

    const handlerCloseConfirmDialog = () => {
        setDeleteForm(null);
        setCuurentType(0);
        setOpenConfirmDialog(false);
    }

    return (
        <Container maxWidth={"md"}>
            <>
                <FormContext {...methods}>
                    <SubHeaderWrapper>
                        <Box>
                            <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography component="span" className="common-label">
                                        {t("master.countryAreaHierarchy")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                                        <Grid item>
                                            <Typography component={"strong"} className="strong-text">
                                                {t("common.selectCountryArea")} :
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <CustomDropdown className="arabic-outlined-dropdown">
                                                <FormSelect
                                                    name="country"
                                                    label={t("common.countryArea")}
                                                    onChange={(event: any) => handelCountryChange(event)}
                                                    options={listOrgWiseCountry}
                                                    variant={"outlined"}
                                                />
                                            </CustomDropdown>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </SubHeaderWrapper>
                    <BoxShadowWrapper>
                        {
                            countryHierarchy.length ? (
                                <Typography component={"div"} className="hide-table-scrolling">
                                    <MaterialTable
                                        columns={[
                                            {
                                                field: "countryName",
                                                title: t("common.countryArea"),
                                                editable: 'never',
                                                render: (rowData: any) => {
                                                    return <>
                                                        <Tooltip title={rowData.countryName}>
                                                            <Typography component={"span"}>
                                                                {rowData.countryName}
                                                            </Typography>
                                                        </Tooltip>
                                                    </>
                                                },
                                                cellStyle: {
                                                    ...commonCellStyle,
                                                    minWidth: "230px",
                                                    maxWidth: "230px",
                                                },
                                                filtering: false,
                                            },
                                            {
                                                field: 'countryId',
                                                hidden: true
                                            },
                                            {
                                                field: "subnationalName",
                                                title: t("common.subNational"),
                                                render: (rowData: any) => {
                                                    return <>
                                                        <Tooltip title={rowData.subnationalName}>
                                                            <Typography component={"span"}>
                                                                {rowData.subnationalName}
                                                            </Typography>
                                                        </Tooltip>
                                                    </>
                                                },
                                                cellStyle: {
                                                    ...commonCellStyle,
                                                    minWidth: "230px",
                                                    maxWidth: "230px",
                                                },
                                            },
                                            {
                                                field: 'subnationalId',
                                                hidden: true
                                            },
                                            {
                                                field: "districtName",
                                                title: t("common.district"),
                                                render: (rowData: any) => {
                                                    return <>
                                                        <Tooltip title={rowData.districtName}>
                                                            <Typography component={"span"}>
                                                                {rowData.districtName}
                                                            </Typography>
                                                        </Tooltip>
                                                    </>
                                                },
                                                cellStyle: {
                                                    ...commonCellStyle,
                                                    minWidth: "230px",
                                                    maxWidth: "230px",
                                                },
                                            },
                                            {
                                                field: 'districtId',
                                                hidden: true
                                            },
                                            {
                                                field: 'type',
                                                hidden: true
                                            },
                                        ]}
                                        data={countryHierarchyData}
                                        options={{
                                            filtering: false,
                                            addRowPosition: 'first',
                                            pageSizeOptions: [50, 100, 150, 200, 250],
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            grouping: false,
                                            actionsColumnIndex: -1,
                                            search: false,
                                            paging: aowHirerarchyData.length > 5 ? true : false,
                                            toolbar: false,
                                            headerStyle: commonHeaderStyle,
                                            defaultExpanded: (rowData: any) => (rowData.id === "1" || rowData.id === expandedSubNationalId)
                                                ? true
                                                : false,
                                            draggable: false,
                                        }}
                                        icons={{
                                            DetailPanel: forwardRef((props: any, ref: any) => (
                                                <ArrowForwardIosIconCustom {...props} ref={ref} />
                                            ))
                                        }}
                                        actions={[
                                            (rowData: any) => ({
                                                icon: () => <AddIconCustom className={(rowData.type === COUNTRY_HIERARCHY_CONST.DISTRICT) ? "zero-opacity" : ""} />,
                                                tooltip: rowData.type === COUNTRY_HIERARCHY_CONST.NATIONAL ? t("master.addSubNational")
                                                    : rowData.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? t("master.addDistrict") : "",
                                                onClick: (event, rowData: any) => { handlerAddRowData(event, rowData) },
                                                disabled: rowData.type === COUNTRY_HIERARCHY_CONST.DISTRICT
                                            }),
                                            (rowData: any) => ({
                                                icon: () => <EditIconCustom />,
                                                tooltip: rowData.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? t("master.editSubNational") : t("master.editDistrict"),
                                                onClick: (event, rowData) => handlerEditRowData(event, rowData),
                                                hidden: rowData.type === COUNTRY_HIERARCHY_CONST.NATIONAL
                                            }),
                                            (rowData: any) => ({
                                                icon: () => <DeleteIconCustom />,
                                                tooltip: rowData.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? t("master.deleteSubNational") : t("master.deleteDistrict"),
                                                onClick: (event, rowData) => handlerDeleteRowData(event, rowData),
                                                hidden: rowData.type === COUNTRY_HIERARCHY_CONST.NATIONAL
                                            }),
                                        ]}
                                        parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}
                                        localization={{
                                            header: {
                                                actions: t("common.actions"),
                                            },
                                            body: {
                                                editTooltip: t("common.edit"),
                                                deleteTooltip: t("common.delete"),
                                                filterRow: {
                                                    filterTooltip: t("pagination.filter"),
                                                },
                                                editRow: {
                                                    saveTooltip: t("common.save"),
                                                    cancelTooltip: t("common.cancel"),
                                                    deleteText: t("common.confirmationMsg")
                                                },
                                                emptyDataSourceMessage: t("common.noRecords"),
                                            },
                                            pagination: {
                                                firstTooltip: t("pagination.firstPage"),
                                                previousTooltip: t("pagination.prevPage"),
                                                nextTooltip: t("pagination.nextPage"),
                                                lastTooltip: t("pagination.lastPage"),
                                                labelRowsSelect: t("common.rows"),
                                            }
                                        }}
                                    />
                                </Typography>
                            ) :
                                <CommonBigCard>
                                    <Grid container justify="center" className="paddingTB15">
                                        <Grid item>
                                            <Typography component={"span"}>
                                                {t("master.countrySelectionMessage")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CommonBigCard>
                        }
                    </BoxShadowWrapper>
                    <>
                        <Dialog
                            open={dialogOpen}
                            onClose={dialogClose}
                            disableBackdropClick={true}
                            disableEscapeKeyDown={true}
                            maxWidth={"xs"}
                            fullWidth={true}
                        >
                            <HierarchyCreateFormComponent
                                countryId={countryId}
                                currentItem={currentItem}
                                IsEdit={IsEdit}
                                dialogClose={dialogClose}
                                dialogOpen={dialogOpen}
                                hideDialoagForm={hideDialoagForm}
                                currentType={currentType} />
                        </Dialog>

                    </>

                    <ConfirmDialog
                        open={openConfirmDialog}
                        close={handlerCloseConfirmDialog}
                        title={currentType === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL ? t("master.subNationalDeleteMsg") : t("master.districtDeleteMsg")}
                        content=""
                        yes={t("common.yes")}
                        no={t("common.cancel")}
                        ok={deleteRowItem}
                    />
                </FormContext>
            </>
        </Container>
    );
}

export default CountryHierarchyComponent;
