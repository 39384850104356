import { createMuiTheme } from "@material-ui/core/styles";
import { PRIMARY_COLOR, PRIMARY_BUTTON_COLOR, WHITE_COLOR,  } from "./constants/color";

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        background: `${PRIMARY_COLOR} !important`,
      },
    },
    MuiButton: {
      containedPrimary: {
        background: `${PRIMARY_BUTTON_COLOR} !important`,
        textTransform: `capitalize`,
      },
      outlinedPrimary: {
        color: `${PRIMARY_BUTTON_COLOR} !important`,
        borderColor:`${PRIMARY_BUTTON_COLOR} !important`,
        backgroundColor: `${WHITE_COLOR}`,
        textTransform: `capitalize`,
      },
    },
  },
});
export default theme;
