import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { actionHeaderUserDropDownShow } from "./actions";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any) {

    const dispatch = useDispatch();

    const isUserSettingPopUpShow = useSelector(
        (state: any) => state.header.isUserSettingPopUpShow
    );


    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */

        function handleClickOutside(event: any) {
            const isSelectList: boolean = event.target.classList.contains("LanguageSelect")

            if (ref.current && !ref.current.contains(event.target) && !isUserSettingPopUpShow && !isSelectList) {
                dispatch(actionHeaderUserDropDownShow(false));
            } else {
                dispatch(actionHeaderUserDropDownShow(true));
            }


        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isUserSettingPopUpShow]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props: any) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideAlerter;
