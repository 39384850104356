export const ActionTypes = {
  COMMON_SET_PAGE_TITLE: "COMMON_SET_PAGE_TITLE",
  COMMON_FETCH_COUNTRY_DATA: "COMMON_FETCH_COUNTRY_DATA",
  COMMON_FETCH_COUNTRY_DATA_SUCCESS: "COMMON_FETCH_COUNTRY_DATA_SUCCESS",
  COMMON_SET_LOADER_VISIBILITY: "SET_LOADER_VISIBILITY",
  COMMON_SET_SECTION_LOADER_VISIBILITY: "SET_SECTION_LOADER_VISIBILITY",
  COMMON_SET_TOASTER_VISIBILITY: "SET_TOASTER_VISIBILITY",
  COMMON_SET_DIALOG_VISIBILITY: "SET_DIALOG_VISIBILITY",
  COMMON_GET_USER_DETAILS: "GET_USER_DETAILS",
  COMMON_SUCCESS_GET_USER_DETAILS: "SUCCESS_GET_USER_DETAILS",
  COMMON_USER_LOGGED_IN: "USER_LOGGED_IN",
  COMMON_SET_BROWSER_TITLE: "SET_BROWSER_TITLE",
  COMMON_GET_USER_ROLE_DETAILS: "COMMON_GET_USER_ROLE_DETAILS",
  COMMON_GET_USER_ROLE_DETAILS_SUCCESS: "COMMON_GET_USER_ROLE_DETAILS_SUCCESS",
  COMMON_SET_UNICEF_REGION: "COMMON_SET_UNICEF_REGION",
  COMMON_SET_UNICEF_COUNTRY: "COMMON_SET_UNICEF_COUNTRY",
  COMMON_SET_WHO_REGION: "COMMON_SET_WHO_REGION",
  COMMON_SET_WHO_COUNTRY: "COMMON_SET_WHO_COUNTRY",
  COMMON_SET_PROGRAM: "COMMON_SET_PROGRAM",
  COMMON_SET_SCOPE: "COMMON_SET_SCOPE",
  COMMON_SET_AREA_OF_WORK: "COMMON_SET_AREA_OF_WORK",
  COMMON_SET_SUB_AREA_OF_WORK: "COMMON_SET_SUB_AREA_OF_WORK",
  COMMON_SET_ORG_WISE_REGION: "COMMON_SET_ORG_WISE_REGION",
  COMMON_SET_ORG_WISE_COUNTRY: "COMMON_SET_ORG_WISE_COUNTRY",
  COMMON_SET_VALIDATION_STATUS_LIST: "COMMON_SET_VALIDATION_STATUS_LIST",
  COMMON_SET_CURRENT_ROUTE: "COMMON_SET_CURRENT_ROUTE",
  COMMON_SET_USER_ASSIGNED_PROGRAMS: "COMMON_SET_USER_ASSIGNED_PROGRAMS",
};

export const GET_COUNTRY_DETAIL: string = `/api/MasterDetails/GetCountryDetails`;
export const GET_USER_ROLE_DETAILS: string = `/api/RoleDetails/GetRoleDetails`;
export const GET_MATURITY_INDEX_DATA: string = `/api/MaturityScore/GetMaturityScores`;
