import React, { useEffect, useState } from "react";
import { Button, Container, Typography } from "@material-ui/core";
import { BoxShadowWrapper, RequestAccessArea, } from "./assets/styles";
import ApplicationList from "./application-list";
import AccessRequestPage from "./access-request";
import MainHomeComponent from "../containers/main-home";
import "./assets/styles.css";
import ScsHeaderComponent from "./scs-header";
import ScsLandingPage from "./scs-landing";
import { useSelector, useDispatch } from "react-redux";
import { actionFetchApplicationsList, actionFetchSCSUserDetails, actionSetShowAppList, actionSetShowSCMM } from "./actions";
import _ from "lodash";
import { Loader } from "components/common/loader.component";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { Authentication } from "components/authentication";
import { actionFetchCountryData } from "store/common/actions";
import { Helmet } from "react-helmet-async";
import { TAB_TITLES } from "constants/data";

const changeLanguage = (lng: any) => {
  i18n.changeLanguage(lng);
};

function SCSHomePage(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAuthenticatedComponent = Authentication();
  const isUserAuthenticated = useSelector(
    (state: any) => state.userProfile.isAuthenticated
  );
  const applicationsList = useSelector(
    (state: any) => state.scsHub.applicationsList
  );
  const scsUserDetails = useSelector(
    (state: any) => state.scsHub.scsUserDetails
  );
  const accessRequestSent = useSelector(
    (state: any) => state.scsHub.accessRequestSent
  );
  const showSCMM = useSelector(
    (state: any) => state.scsHub.showSCMM
  );
  const showAppList = useSelector(
    (state: any) => state.scsHub.showAppList
  );
  const isLoaderVisible = useSelector(
    (state: any) => state.common.isLoaderVisible
  );
  const countryList = useSelector(
    (state: any) => state.common.countryData
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );

  var canShowSCMM = localStorage.getItem("showSCMM");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [listOfApp, setListOfApp] = useState([]);
  const [noAccessAppList, setNoAccessAppList] = useState([]);
  const [pendingRequestsApp, setPendingRequestsApp] = useState([]);
  const [title, setTitle] = useState(TAB_TITLES.SCS_HUB);

  useEffect(() => {
    if (languageSelected !== "") {
      changeLanguage(languageSelected);
    }
  }, [languageSelected]);

  const showHideForm = (request: boolean) => {
    dispatch(actionSetShowAppList(!request));
  }

  useEffect(() => {
    if (canShowSCMM === 'true') {
      dispatch(actionSetShowSCMM(true))
    }
  }, [])

  const setAppTitle = (data: any) => {
    let appTitle = title;
    if (data.aliaseName.includes("VC"))
      appTitle = TAB_TITLES.VC;
    else if (data.aliaseName.includes("SCMM"))
      appTitle = TAB_TITLES.SCMM;
    setTitle(appTitle);
  }

  useEffect(() => {
    if (selectedAppDetails.id) {
      setAppTitle(selectedAppDetails)
    }
  }, [selectedAppDetails])

  useEffect(() => {
    if (accessRequestSent) {
      dispatch(actionFetchSCSUserDetails())
      dispatch(actionSetShowAppList(true));
    }
  }, [accessRequestSent])

  useEffect(() => {
    if (isUserAuthenticated) {
      setIsAuthenticated(true);
      dispatch(actionFetchApplicationsList());
      dispatch(actionFetchSCSUserDetails());
      dispatch(actionFetchCountryData());
    } else {
      setIsAuthenticated(false);
    }
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (applicationsList.length) {
      setListOfApp(applicationsList);
    } else {
      setListOfApp([]);
    }
  }, [applicationsList]);

  useEffect(() => {
    if (!_.isEmpty(scsUserDetails) && applicationsList.length) {
      if (scsUserDetails.applications.length) {
        const fillterList = applicationsList.filter((obj: any) => !scsUserDetails.applications.find((obj2: any) => obj.id === obj2.id));
        setNoAccessAppList(fillterList)
      } else {
        setNoAccessAppList(applicationsList);
        dispatch(actionSetShowAppList(false));
      }
      if (scsUserDetails.pendingRequests.length) {
        setPendingRequestsApp(scsUserDetails.pendingRequests)
      }
    }
  }, [scsUserDetails, applicationsList])

  const goToSCMM = (data: any) => {
    setAppTitle(data);
    dispatch(actionSetShowSCMM(true));
    localStorage.setItem("showSCMM", "true");
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!showSCMM || !isAuthenticated ? (<ScsHeaderComponent />) : null}
      <Loader visible={isLoaderVisible} />
      {isAuthenticated && isAuthenticatedComponent ? (
        <Typography component="div" >
          {showSCMM ? (
            <>
              <MainHomeComponent />
            </>) : (
            <>
              {showAppList ?
                <Container maxWidth={"xl"}>
                  <BoxShadowWrapper className="margin-top-20 position-relative">
                    <RequestAccessArea>
                      <Button variant="contained" color="primary" onClick={() => showHideForm(showAppList)}>
                        {t("scsHub.requestAccess")}
                      </Button>
                    </RequestAccessArea>
                    {listOfApp.length && scsUserDetails !== null ? (
                      <ApplicationList goToSCMM={goToSCMM} listOfApp={listOfApp} scsUserDetails={scsUserDetails} />
                    ) : null}
                  </BoxShadowWrapper>
                </Container>
                :
                <Container maxWidth={"xl"}>
                  <BoxShadowWrapper className="margin-top-20">
                    {listOfApp.length && scsUserDetails !== null ? (
                      <AccessRequestPage
                        showHideForm={showHideForm}
                        listOfApp={noAccessAppList}
                        scsUserDetails={scsUserDetails}
                        countryList={countryList}
                        pendingRequestsApp={pendingRequestsApp} />
                    ) : null}
                  </BoxShadowWrapper>
                </Container>
              }
            </>
          )}

        </Typography>
      ) : (
        <ScsLandingPage />
      )}
    </>
  );
}

export default SCSHomePage;
