import api from "../../utils/api";
import {
  GET_ADMIN_ASSESSMENTS_DETAILS, GET_ADMIN_ALL_COUNTRY_ASSESSMENTS_STATUS_COUNT, GET_ADMIN_ASSESSMENT_STATUS_BY_COUNTRY,
} from "./constants";

/**
 * Function for getting some data
 */

export const fetchAdminAssesmentsDetailData = async (data: any) => {
  const response = await api.post(`${GET_ADMIN_ASSESSMENTS_DETAILS}`, data);
  return response;
};

export const fetchAdminAllAssesmentsDetailCount = (lookUp: any) => {
  return api.get(`${GET_ADMIN_ALL_COUNTRY_ASSESSMENTS_STATUS_COUNT}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchAdminAssesmentStatusDataByCountry = (lookUp: any) => {
  return api.post(`${GET_ADMIN_ASSESSMENT_STATUS_BY_COUNTRY}`, lookUp).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};