export const ActionTypes = {
  ADMIN_SELECTED_REGION: "ADMIN_SELECTED_REGION",
  ADMIN_SELECTED_COUNTRY: "ADMIN_SELECTED_COUNTRY",

  ADMIN_MAP_VIEW: "ADMIN_MAP_VIEW",

  ADMIN_FETCH_ASSESSMENT_DATA: "ADMIN_FETCH_ASSESSMENT_DATA",
  ADMIN_FETCH_ASSESSMENT_DATA_SUCESS: "ADMIN_FETCH_ASSESSMENT_DATA_SUCESS",

  ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT:
    "ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT",
  ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT_SUCESS:
    "ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT_SUCESS",

  ADMIN_MAP_COUNTRY_AVG_MI_DATA: "ADMIN_MAP_COUNTRY_AVG_MI_DATA",
  ADMIN_MAP_REGION_AVG_MI_DATA: "ADMIN_MAP_REGION_AVG_MI_DATA",


};

export const GET_ADMIN_ASSESSMENTS_DETAILS: string = `/api/Assessment/GetAssessmentDetails`;
export const GET_ADMIN_ALL_COUNTRY_ASSESSMENTS_STATUS_COUNT: string = `/api/Assessment/GetAllAssessmentByStatusForMap`;
export const GET_ADMIN_ASSESSMENT_STATUS_BY_COUNTRY: string = `/api/Assessment/GetAllAssessmentByCountriesForMap`;