import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { actionSetPageTitle } from "../../store/common/actions";
import { Container, Grid } from "@material-ui/core";
import { BoxShadowWrapper, AboutUsContent, AboutBgImage, GeneralResponsiveImage } from "styles/common";
import { useTranslation } from "react-i18next";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import About_bg from '../../styles/images/about_bg.png';
import rainbow_EN from '../../styles/images/rainbow_EN.png';
import rainbow_FR from '../../styles/images/rainbow_FR.png';
import rainbow_SP from '../../styles/images/rainbow_SP.png';
import maturity_model_EN from '../../styles/images/maturity_model_EN.png';
import maturity_model_FR from '../../styles/images/maturity_model_FR.png';
import maturity_model_SP from '../../styles/images/maturity_model_SP.png';
import { LANGUAGE } from "constants/data";

function AboutUs(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = "About Project Name";
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(actionSetPageTitle(title));
  }, [dispatch]);

  return (
    <>
      <Container maxWidth={"xl"}>
        <Typography component="div" className="paddingT20">
          <BoxShadowWrapper>
            <AboutUsContent>
              <Typography component="h1" className="page-title">{t("aboutUs.pageTitle")}</Typography>
              <Typography component="div" className="image-section">
                <AboutBgImage src={About_bg} />
              </Typography>
              <Grid container justify="center">
                <Grid item md={8} sm={10}>
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle1")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc1") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle2")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc2") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle3")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc3") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle4")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc4") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle5")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc5") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle6")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc6") }} />

                      <GeneralResponsiveImage
                        src={
                          languageSelected === LANGUAGE.ARABIC
                            ? rainbow_EN
                            : languageSelected === LANGUAGE.FRENCH
                              ? rainbow_FR
                              : languageSelected === LANGUAGE.SPANISH
                                ? rainbow_SP
                                : languageSelected === LANGUAGE.RUSSIAN
                                  ? rainbow_EN
                                  : rainbow_EN
                        }
                        className="margin-top-15" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle7")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc7") }} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8a-content"
                      id="panel8a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle8")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc8") }} />
                      <GeneralResponsiveImage
                        src={
                          languageSelected === LANGUAGE.ARABIC
                            ? maturity_model_EN
                            : languageSelected === LANGUAGE.FRENCH
                              ? maturity_model_FR
                              : languageSelected === LANGUAGE.SPANISH
                                ? maturity_model_SP
                                : languageSelected === LANGUAGE.RUSSIAN
                                  ? maturity_model_EN
                                  : maturity_model_EN
                        }
                        className="margin-top-15" />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel9a-content"
                      id="panel9a-header"
                    >
                      <Typography component="span">{t("aboutUs.tabTitle9")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div" dangerouslySetInnerHTML={{ __html: t("aboutUs.tabDesc9") }} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </AboutUsContent>
          </BoxShadowWrapper>
        </Typography>
      </Container>
    </>
  );
}

export default AboutUs;
