import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogClose,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../styles/common";
import CloseIcon from "@material-ui/icons/Close";
import Setting from "../registered-user/setting";
import LanguagSelectionComponent from "./language";
import { DialogActions, Tooltip } from "@material-ui/core";
import {
  actionHeaderUserSettingPopUpShow,
  actionHeaderUserSettingClicked,
} from "./actions";
import { USER } from "constants/data";
import OrganizationSelectionComponent from "./organization";
import SystemSelectionComponent from "./system";

function UserDropdown(props: any) {
  const {
    loggedInUser,
    logout,
    selectedRole,
    selectedRegion,
    selectedCountry,
    userId,
    allOrganization,
    selectedPrograms,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const logoutUser = () => {
    logout();
  };

  const allPrograms: any = (window as any).allPrograms;
  const [userAssignedPrograms, setUserAssignedPrograms] = useState([]);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [clicked, doClicked] = useState(false);
  const [showSystemDropdown, setShowSystemDropdown] = useState(true);

  const userRoleObj = useSelector(
    (state: any) => state.userProfile.saveRegisterUserRoleData
  );
  const isClicked = useSelector(
    (state: any) => state.header.isUserSettingClicked
  );
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const scsUserDetails = useSelector(
    (state: any) => state.scsHub.scsUserDetails
  );

  useEffect(() => {
    if (scsUserDetails.applications && scsUserDetails.applications.length == 1)
      setShowSystemDropdown(false);
    else {
      scsUserDetails.applications && scsUserDetails.applications.forEach((app: any) => {
        if (!app.roles.length) {
          setShowSystemDropdown(false);
        }
      })
    }
  }, [scsUserDetails])

  useEffect(() => {
    if (selectedPrograms.programs) {
      const userAssignedPrograms = selectedPrograms.programs.filter((program: any) => {
        return allPrograms.length && Number(program.id) !== Number(allPrograms[0].id) && Number(program.id) !== Number(allPrograms[1].id)
      });
      setUserAssignedPrograms(userAssignedPrograms);
    }
  }, [selectedPrograms]);

  const openRoleSetting = () => {
    setRoleDialogOpen(true);
    doClicked(false);
    dispatch(actionHeaderUserSettingPopUpShow(true));
  };
  const closeRoleSetting = () => {
    setRoleDialogOpen(false);
    dispatch(actionHeaderUserSettingPopUpShow(false));
  };
  const applyClick = () => {
    dispatch(actionHeaderUserSettingClicked(!isClicked));
    doClicked(true);
  };
  const [showSetting, setShowSetting] = useState(false);
  const isRoleAssigned = useSelector(
    (state: any) => state.header.isRoleAssigned
  );
  const isRegisteredUser = useSelector(
    (state: any) => state.header.isRegisteredUser
  );
  const isDeleted = useSelector((state: any) => state.header.isDeleted);
  useEffect(() => {
    if (
      isDeleted !== undefined &&
      isRoleAssigned !== undefined &&
      isRegisteredUser !== undefined
    ) {
      if (!isDeleted || !isRoleAssigned || !isRegisteredUser) {
        localStorage.removeItem("userSelectedData");
        setShowSetting(false);
      } else {
        setShowSetting(true);
      }
    }
  }, [isDeleted, isRoleAssigned, isRegisteredUser]);

  useEffect(() => {
    if (userRoleObj && userRoleObj.length) {
    }
  }, [userRoleObj]);

  return (
    <Typography component="div" className="dd-container">
      <Dialog
        open={roleDialogOpen}
        onClose={closeRoleSetting}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {t("home.changeSettings")}
          <DialogClose aria-label="close" onClick={() => closeRoleSetting()}>
            <CloseIcon />
          </DialogClose>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <Setting
            applyBtn={closeRoleSetting}
            applyClick={clicked}
            showBtn={false}
            directionValue={"row"}
          />
        </DialogContent>
        <DialogActions className="padding16-24">
          <Grid container alignItems="center" justify="flex-end" spacing={2}>
            <Grid item>
              <DialogButtonDefault
                variant="contained"
                color="default"
                onClick={closeRoleSetting}
              >
                {t("common.cancel")}
              </DialogButtonDefault>
            </Grid>
            <Grid item>
              <DialogButtonPrimary
                variant="contained"
                color="primary"
                autoFocus={true}
                onClick={() => applyClick()}
              >
                {t("setting.apply")}
              </DialogButtonPrimary>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Grid container={true} direction="row" className="py-16">
        <Grid item={true} xs={3}>
          <Typography component="div" className="text-center">
            <Avatar className="dd-big-avatar">
              {loggedInUser.name
                ? loggedInUser.name.substring(0, 1).toUpperCase()
                : ""}
              {loggedInUser.lastName
                ? loggedInUser.lastName.substring(0, 1).toUpperCase()
                : ""}
            </Avatar>
          </Typography>
        </Grid>
        <Grid item={true} xs={9}>
          <Typography
            variant="h6"
            component="h6"
            color="inherit"
            className="dd-user-name"
          >
            {loggedInUser.name} {loggedInUser.lastName}
          </Typography>

          {selectedRole !== "" && (
            <Typography component="ul" className="dd-role-list">
              {selectedRole !== USER.ROLE.SD_ADMIN &&
                selectedRole !== USER.ROLE.GLOBAL_ADVISOR && (
                  <Typography component="li">
                    <Tooltip title={organizationSelected}>
                      <Typography component="span">
                        {organizationSelected}
                      </Typography>
                    </Tooltip>
                  </Typography>
                )}
              <Typography component="li">
                <Tooltip title={selectedRole}>
                  <Typography component="span">
                    {selectedRole}
                  </Typography>
                </Tooltip>
              </Typography>
              {selectedRole !== USER.ROLE.SD_ADMIN &&
                selectedRole !== USER.ROLE.GLOBAL_ADVISOR &&
                selectedRole !== USER.ROLE.VC_ASSESSOR && (
                  <Typography component="li">
                    <Tooltip title={`${selectedRegion} ${`${selectedCountry}` ? "-" : ""} ${selectedCountry}`}>
                      <Typography component="span">
                        {selectedRegion} {selectedCountry ? "-" : ""} {selectedCountry}
                      </Typography>
                    </Tooltip>
                  </Typography>
                )}
              {(selectedRole === USER.ROLE.VALIDATOR ||
                selectedRole === USER.ROLE.ASSESSOR) && selectedPrograms && (
                  <Typography component="li" className="white-space-normal">
                    <Typography component={"div"} className="programs-div">
                      {
                        selectedPrograms.isAllSelected ?
                          <Tooltip title={` ${t('common.all')}`}>
                            <Typography component="span">
                              {t('common.all')}
                            </Typography>
                          </Tooltip>
                          :
                          <>
                            {
                              userAssignedPrograms.map((program: any, index: any) =>
                                <>
                                  <Tooltip title={program.name}>
                                    <Typography component="span" key={index}>
                                      {program.name + (index < userAssignedPrograms.length - 1 ? ', ' : '')}
                                    </Typography>
                                  </Tooltip>
                                </>
                              )
                            }
                          </>
                      }
                    </Typography>
                  </Typography>
                )}
            </Typography>
          )}

          {showSetting &&
            selectedRole !== USER.ROLE.SD_ADMIN &&
            selectedRole !== "" &&
            selectedRole !== USER.ROLE.VC_ASSESSOR && (
              <Tooltip title={`${t("home.changeSettings")}`}>
                <Typography
                  component="span"
                  className="dd-change-setting"
                  onClick={openRoleSetting}
                >
                  {t("home.changeSettings")}
                </Typography>
              </Tooltip>
            )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container={true} alignItems="center" wrap="nowrap" className="dd-email-text-container">
        <Grid item={true}>
          <Tooltip title={`${t("common.email")}`}>
            <Typography component="span" className="dd-email-text-title">
              {t("common.email")}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item={true}>
          <Typography component="span" className="dd-email-text">
            &nbsp;:&nbsp;
          </Typography>
        </Grid>
        <Grid item={true}>
          <Tooltip title={`${loggedInUser.email}`}>
            <Typography component="span" className="dd-email-text">
              {`${loggedInUser.email}`}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider />

      {showSystemDropdown && selectedRole !== USER.ROLE.SD_ADMIN &&
        <>
          <Grid container>
            <Grid item xs={12}>
              <SystemSelectionComponent />
            </Grid>
          </Grid>
          <Divider />
        </>}

      <Grid container>
        <Grid item xs={12}>
          <LanguagSelectionComponent />
        </Grid>
      </Grid>
      <Divider />
      {selectedRole === USER.ROLE.SD_ADMIN ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <OrganizationSelectionComponent
                selectedRole={selectedRole}
                selectedRegion={selectedRegion}
                selectedCountry={selectedCountry}
                userId={userId}
                allOrganization={allOrganization}
              />
            </Grid>
          </Grid>
          <Divider />
        </>
      ) : (
        ""
      )}

      <Grid container>
        <Grid item xs={12}>
          <Tooltip title={`${t("home.logout")}`}>
            <Typography
              component="span"
              className="dd-logout-button"
              onClick={logoutUser}
            >
              {t("home.logout")}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </Typography>
  );
}

export default UserDropdown;
