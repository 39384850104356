import React, { useState, forwardRef } from "react";
import MaterialTable from "material-table";
import { AddIconCustom, ArrowForwardIosIconCustom, DeleteIconCustom, EditIconCustom } from "../../../../styles/common";
import { useTranslation } from "react-i18next";
import { actionDeleteMasterAreaOfWorkToolDetail, actionFetchMasterAreaOfWorkToolSingleData } from "containers/master/actions";
import { useDispatch } from "react-redux";
import { ConfirmDialog } from "components/common/confirm-dialog.component";
import { actionCellStyle, commonCellStyle, commonHeaderStyle, maxWidthCellStyle } from "../../../../styles/materialStyles";
import { Tooltip, Typography } from "@material-ui/core";

function AreaOfWorkToolGrid(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    userId,
    showHideGridAndForm,
    editFormvalues,
    gridsData,
    setCurrentItem,
    expandedAOWId,
    setExpandedAOWId,
    expandedSubAOWId,
    setExpandedSubAOWId
  } = props;

  const [deleteRowData, setDeleteRowData] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleAddRowData = (event: any, rowData: any) => {
    setExpandedAOWId(rowData.areaOfWorkId);
    setExpandedSubAOWId(rowData.subAreaOfWorkId);
    setCurrentItem(rowData);
    showHideGridAndForm(true);
    editFormvalues(true);
  }
  const handleEditRowData = (event: any, rowData: any) => {
    setExpandedAOWId(rowData.areaOfWorkId);
    setExpandedSubAOWId(rowData.subAreaOfWorkId);
    setCurrentItem(rowData);
    dispatch(actionFetchMasterAreaOfWorkToolSingleData(rowData.toolLanguageId));
    showHideGridAndForm(true);
    editFormvalues(true);
  };
  const handleOpenConfirmDialog = (value: any) => {
    setExpandedAOWId(value.areaOfWorkId);
    setExpandedSubAOWId(value.subAreaOfWorkId);
    value.isDeleted = true;
    value.updatedBy = userId;
    setDeleteRowData(value);
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const handleDeleteRowData = () => {
    setOpenConfirmDialog(true);
    dispatch(actionDeleteMasterAreaOfWorkToolDetail(deleteRowData));
    handleCloseConfirmDialog();
  };

  return (
    <Typography component="div" className="AoWToolTable">
      <ConfirmDialog
        open={openConfirmDialog}
        close={handleCloseConfirmDialog}
        title={t("indicators.areYouSureDelete")}
        content=""
        ok={handleDeleteRowData}
      />
      <Typography component={"div"} className="action-column-style hide-table-scrolling">
        <MaterialTable
          data={gridsData}
          columns={[
            {
              field: 'areaOfWorkId',
              hidden: true
            },
            {
              field: 'subAreaOfWorkId',
              hidden: true
            },
            {
              field: 'toolId',
              hidden: true
            },
            {
              title: t("common.areaOfWork"),
              field: 'areaOfWork',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.areaOfWork}>
                    <Typography component={"span"}>
                      {rowData.areaOfWork}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: {
                ...commonCellStyle,
                ...maxWidthCellStyle,
                maxWidth: "200px",
              },
              filtering: false,
            },
            {
              title: t("common.subAreaOfWork"),
              field: 'subAreaOfWork',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.subAreaOfWork}>
                    <Typography component={"span"}>
                      {rowData.subAreaOfWork}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: {
                ...commonCellStyle,
                ...maxWidthCellStyle,
                maxWidth: "200px",
              },
            },
            {
              title: t("areaOfWorkTool.tool"),
              field: 'toolName',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.toolName}>
                    <Typography component={"span"}>
                      {rowData.toolName}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: {
                ...commonCellStyle,
                ...maxWidthCellStyle,
                maxWidth: "200px",
              },
            },
            {
              title: t("areaOfWorkTool.section"),
              field: 'application',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.application}>
                    <Typography component={"span"}>
                      {rowData.application}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: {
                ...commonCellStyle,
                ...maxWidthCellStyle,
                maxWidth: "200px",
              },
            },
            {
              title: t("areaOfWorkTool.description"),
              field: 'description',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.description}>
                    <Typography component={"span"}>
                      {rowData.description}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: {
                ...commonCellStyle,
                minWidth: "300px",
                maxWidth: "300px",
              },
              filtering: false,
            },
            {
              title: t("areaOfWorkTool.link"),
              field: 'link',
              render: (rowData: any) => {
                return <>
                  <Tooltip title={rowData.link}>
                    <Typography component={"span"}>
                      {rowData.link}
                    </Typography>
                  </Tooltip>
                </>
              },
              cellStyle: commonCellStyle,
              filtering: false,
            },
          ]}
          parentChildData={(row: any, rows: any) => rows.find((a: any) => a.id === row.parentId)}
          actions={[
            rowData => ({
              icon: () => <AddIconCustom />,
              tooltip: t("areaOfWorkTool.addTool"),
              onClick: (event: any, rowData: any) => {
                handleAddRowData(event, rowData)
              },
              hidden: (rowData.areaOfWork === null && rowData.subAreaOfWork != null ? false : true)
            }),
            rowData => ({
              icon: () => <AddIconCustom style={{ opacity: 0, }} />,
              disabled: true,
              onClick: () => { },
              hidden: (rowData.areaOfWork === null && rowData.subAreaOfWork != null ? false : true)
            }),
            rowData => ({
              icon: () => <EditIconCustom />,
              tooltip: t("areaOfWorkTool.editTool"),
              onClick: (event: any, rowData: any) => {
                handleEditRowData(event, rowData)
              },
              hidden: (rowData.areaOfWork === null && rowData.subAreaOfWork === null ? false : true)
            }),
            rowData => ({
              icon: () => <DeleteIconCustom />,
              tooltip: t("areaOfWorkTool.deleteTool"),
              onClick: (event: any, rowData: any) => handleOpenConfirmDialog(rowData),
              hidden: (rowData.areaOfWork === null && rowData.subAreaOfWork === null ? false : true)
            }),
          ]}
          icons={{
            DetailPanel: forwardRef((props: any, ref: any) => (
              <ArrowForwardIosIconCustom {...props} ref={ref} />
            ))
          }}
          options={{
            filtering: false,
            paging: gridsData.length > 5 ? true : false,
            pageSizeOptions: [10, 20, 50],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            grouping: false,
            actionsColumnIndex: -1,
            search: false,
            toolbar: false,
            headerStyle: commonHeaderStyle,
            actionsCellStyle: actionCellStyle,
            defaultExpanded: (rowData: any) => (rowData.id === expandedAOWId || rowData.id === expandedSubAOWId)
              ? true
              : false,
          }}
          localization={{
            header: {
              actions: t("common.actions"),
            },
            body: {
              filterRow: {
                filterTooltip: t("pagination.filter"),
              },
              emptyDataSourceMessage: t("common.noDataAvailable")
            },
            pagination: {
              firstTooltip: t("pagination.firstPage"),
              previousTooltip: t("pagination.prevPage"),
              nextTooltip: t("pagination.nextPage"),
              lastTooltip: t("pagination.lastPage"),
              labelRowsSelect: t("common.rows"),
            }
          }}
        />
      </Typography>
    </Typography>
  );
}

export default AreaOfWorkToolGrid;
