import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { actionHeaderUserSetting } from "../header/actions";
import {
  actionFetchAssesmentData,
  actionSaveAssesmentData,
  actionFetchAssesmentByIdData,
  actionUpdateAssesmentValidationStatusData,
  actionFetchAssesmentLevelByIdData,
  actionDetailsSaveData,
  actionSaveAssesmentDataSuccess,
  actionUpdateAssesmentQuestionResetAssessorStatusData,
  actionFetchAssesmentByIdDataSuccess,
  actionExportAssesmentData,
  actionFetchAssessmentProgramScoreByIdData,
  actionAssessmentOverviewAVData,
  actionFetchAllAssesmentData,
  actionSetEditableScreen,
} from "../assessment-create/actions";

import {
  actionSetPageTitle,
  closeToaster,
  openToaster,
  setLoaderVisibility,
} from "../../store/common/actions";
import {
  DownloadIconCustom,
  ArrowBackIosIconCustom,
  SubHeaderWrapper,
} from "../../styles/common";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import AssessmentOverviewTabComponent from "./assessment-overview-tab";
import AssesmentCreateFormComponent from "../assessment-create/assessment-create-form";
import AssesmentQuestionTabsComponent from "../assessment-create/assessment-question-tab";
import { Toaster } from "../../components/common/toaster.component";
import Grid from "@material-ui/core/Grid";
import { ConfirmDialog } from "../../components/common/confirm-dialog.component";
import { Container, IconButton, Tooltip } from "@material-ui/core";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, COMMON_CONSTANT, USER } from "constants/data";

function AssessmentOverview(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  )

  const assesmentData = useSelector(
    (state: any) => state.assesmentCreate.assesmentData
  );

  const allAssessmentDetails = useSelector(
    (state: any) => state.assesmentCreate.allAssessmentDetails
  );

  const assesmentDetailData = useSelector(
    (state: any) => state.assesmentCreate.assesmentDetailData
  );
  const saveAssesmentData = useSelector(
    (state: any) => state.assesmentCreate.saveAssesmentData
  );

  const userSelectedSettingData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const loginUserData = useSelector((state: any) => state.header.loginUserData);

  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const listValidationStatus = useSelector(
    (state: any) => state.common.listValidationStatus
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const formTitle = useSelector((state: any) => state.common.pageTitle);
  const roleData = useSelector((state: any) => state.common.roleData);
  const listScope = useSelector((state: any) => state.common.listScope);
  const userAssignedPrograms = useSelector((state: any) => state.common.userAssignedPrograms);

  const [editFormValues, setEditFormValues]: any = useState({});
  const [showAssesmentTable, setShowAssesmentTable] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogTitle = t("assessment.createNewAssesment");
  const assesmentOveriewTitle = t("assessment.assessmentOverview");
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
  const [userId, setUserId] = useState("");
  const [selectedAssessmentCode, setSelectedAssessmentCode] = useState("");
  const [selectedSupplyChain, setSelectedSupplyChain] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [
    selectedAssessmentStatusId,
    setSelectedAssessmentStatusId,
  ] = useState();
  const [isEditAssessment, setIsEditAssessment] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedCountryIdList, setSelectedCountryIdList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [currentAssesmentId, setCurrentAssesmentId] = useState();
  const [isPendingValidation, setIsPendingValidation] = useState(false);
  const [titleConfirmDialog, setTitleConfirmDialog]: any = useState("");
  const [dialogType, setDialogType] = useState("SV");

  const [isValidateEnabled, setIsValidateEnabled] = useState(false);
  const [isReAssignEnabled, setIsReAssignEnabled] = useState(false);
  const [areaOfWorkListCheck, setAreaOfWorkListCheck]: any = useState([]);
  const [tabDataCheck, setTabDataCheck]: any = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [canShowOverview, setCanShowOverview]: any = useState(false);
  const [tabValueSelected, setTabValueSelected]: any = useState(0);
  const [userSelectedData, setUserSelectedData]: any = useState(null);
  const [isUpdatedByOther, setIsUpdatedByOther]: any = useState(false);
  const [programList, setProgramList]: any = useState([]);
  const [labelProgramCount, setLabelProgramCount]: any = useState(0);
  const [userProgramList, setUserProgramList]: any = useState([]);
  const [selectedSubRegion, setSelectedSubRegion]: any = useState();
  const [selectedAssessmentType, setSelectedAssessmentType]: any = useState();

  const allScopeData: any = (window as any).allScopeData;

  const userLocalData: any = JSON.parse(
    localStorage.getItem("userSelectedData") || "{}"
  );

  useEffect(() => {
    defaultMethodCall();
  }, [dispatch]);

  const defaultMethodCall = () => {
    dispatch(actionSetPageTitle(t("assessment.createNewAssesment")));
    dispatch(actionHeaderUserSetting(true));
    setFirstTimeCall(false);
  };

  useEffect(() => {
    if (Object.keys(userLocalData).length > 0) {
      dispatch(actionFetchAllAssesmentData({
        "roleId": userLocalData.userSelectedRole,
        "countryIds": userLocalData.userSelectedCountry,
      }));
    }
    return () => {
      dispatch(actionSaveAssesmentDataSuccess(null));
      dispatch(actionFetchAssesmentByIdDataSuccess([]));
    };
  }, []);

  useEffect(() => {
    if (userSelectedSettingData && allScopeData.length) {
      fetchAssesmeDataByCountry(userSelectedSettingData.userSelectedData);
      setShowAssesmentTable(true);
    } else if (localStorage.getItem("userSelectedData")) {
      const userSettingData: any = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
      );
      if (
        userSettingData.hasOwnProperty("userSelectedCountry") &&
        allScopeData.length
      ) {
        fetchAssesmeDataByCountry(userSettingData);
      }
      setShowAssesmentTable(true);
    }
  }, [userSelectedSettingData]);

  useEffect(() => {
    if (
      saveAssesmentData &&
      saveAssesmentData.hasOwnProperty("isIndicatorSetConflict") &&
      saveAssesmentData.isIndicatorSetConflict === false &&
      firstTimeCall === false
    ) {
      setShowAssesmentTable(false);
      setCurrentAssesmentId(saveAssesmentData.id);
      dispatch(actionFetchAssesmentByIdData(saveAssesmentData.id));
      setEditFormValues(saveAssesmentData);
      setAssesmentEditLable(saveAssesmentData);
      setIsEditAssessment(true);
      dispatch(setLoaderVisibility(true));
      dispatch(actionFetchAssesmentData({
        "roleId": userSelectedData.userSelectedRole,
        "countryIds": selectedCountryIdList,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      }));
    } else if (
      saveAssesmentData &&
      saveAssesmentData.hasOwnProperty("isIndicatorSetConflict") &&
      saveAssesmentData.isIndicatorSetConflict === true &&
      firstTimeCall === false
    ) {
      dispatch(openToaster(t("assessment.indicatorSetNotAvailable"), false));
      closeToasterParticularTimeInterval();
      dispatch(setLoaderVisibility(false));
    }
  }, [saveAssesmentData, assessmentTypeFilter]);

  useEffect(() => {
    if (
      assesmentDetailData &&
      assesmentDetailData.hasOwnProperty("dynamicColumnHeaderList") &&
      firstTimeCall === false
    ) {
      setShowAssesmentTable(false);
    }
  }, [assesmentDetailData]);

  useEffect(() => {
    if (userAssignedPrograms.length && listScope.length) {
      setUserProgramList(userAssignedPrograms);
      setIsAddButtonDisable(false);
    }
  }, [userAssignedPrograms, listScope]);

  useEffect(() => {
    if (loginUserData) {
      setUserId(loginUserData.id);
    }
  }, [loginUserData]);

  useEffect(() => {
    if (userSelectedData && roleData && roleData.length) {
      const role = roleData.filter(
        (roles: any) => roles.id === userSelectedData.userSelectedRole
      );
      if (role.length) {
        setSelectedRole(role[0].name);
      }
    }
  }, [roleData, userSelectedData]);

  useEffect(() => {
    if (firstTimeCall === false) {
      if (currentAssesmentId) {
        if (canShowOverview) {
          dispatch(actionFetchAssesmentLevelByIdData(currentAssesmentId));
        }
        if (!showAssesmentTable) {
          dispatch(actionFetchAssesmentByIdData(currentAssesmentId));
        }
      }
      if (selectedCountryIdList.length) {
        dispatch(setLoaderVisibility(true));
        defaultMethodCall();
        dispatch(actionFetchAssesmentData({
          "roleId": userSelectedData.userSelectedRole,
          "countryIds": selectedCountryIdList,
          "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        }));
      }
    }
  }, [languageSelected]);

  useEffect(() => {
    if (firstTimeCall === false) {
      setCanShowOverview(false);
      if (currentAssesmentId && !showAssesmentTable) {
        dispatch(actionFetchAssesmentByIdData(currentAssesmentId));
      }

      if (selectedCountryIdList.length) {
        dispatch(setLoaderVisibility(true));
        defaultMethodCall();
        dispatch(actionFetchAssesmentData({
          "roleId": userSelectedData.userSelectedRole,
          "countryIds": selectedCountryIdList,
          "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        }));
      }
    }
  }, [assessmentTypeFilter]);

  useEffect(() => {
    if (firstTimeCall === false && assesmentData && currentAssesmentId) {
      const assessmentRowData = assesmentData.filter(
        (assessment: any) => assessment.id === currentAssesmentId
      );
      if (assessmentRowData.length) {
        setAssesmentEditLable(assessmentRowData[0]);
        dispatch(actionDetailsSaveData(assessmentRowData[0]));
      }
    }
  }, [assesmentData]);

  const showDialoagForm = () => {
    setDialogOpen(true);
  };
  const hideDialoagForm = () => {
    setDialogOpen(false);
  };

  const getProgramData = (
    statusId: any,
    countryArrayList: any,
    programId: any
  ): any => {
    dispatch(
      actionFetchAssessmentProgramScoreByIdData({
        status: statusId,
        country: countryArrayList,
        programId: programId,
        "assessmentType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      })
    );
  };

  useEffect(() => {
    if (assesmentData.length && selectedCountryIdList.length) {
      let tempProgramArr: any = [];
      assesmentData.map((item: any, index: any) => {
        if (item.assessmentStatusId === ASSESSMENT.STATUS.VALIDATED) {
          tempProgramArr.push({
            id: item.supplyChainId,
            label: item.supplyChain,
          });
        }
      });
      let unq_programArr = [
        ...new Map(
          tempProgramArr.map((item: any) => [item["id"], item])
        ).values(),
      ];
      setProgramList(unq_programArr);
      setLabelProgramCount(unq_programArr.length);
      if (unq_programArr.length === 0) {
        dispatch(setLoaderVisibility(false));
      } else {
        fetchValidatedAssessmentStatusData(
          selectedCountryIdList,
          unq_programArr
        );
      }
    } else {
      setLabelProgramCount(0);
      setProgramList([]);
    }
  }, [assesmentData]);

  useEffect(() => {
    dispatch(actionSetEditableScreen(false));
  }, [])

  const showAssesmentSetTable = (id: any) => {
    if (isUpdatedByOther) {
      setIsUpdatedByOther(false);
      dispatch(setLoaderVisibility(true));
      dispatch(actionFetchAssesmentData({
        "roleId": userSelectedData.userSelectedRole,
        "countryIds": selectedCountryIdList,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      }));
    }
    setShowAssesmentTable(true);
    setCanShowOverview(false);
    // 
    dispatch(actionSetEditableScreen(false));
  };

  const fetchAssesmeDataByCountry = (userSelectedData: any) => {
    dispatch(setLoaderVisibility(true));
    setSelectedCountryIdList(userSelectedData.userSelectedCountry);
    setUserSelectedData(userSelectedData);
    dispatch(actionFetchAssesmentData({
      "roleId": userSelectedData.userSelectedRole,
      "countryIds": userSelectedData.userSelectedCountry,
      "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    }));
  };

  const fetchValidatedAssessmentStatusData = (
    userSelectedCountryList: any,
    programList: any
  ) => {
    const assessmentRequestData = {
      country: userSelectedCountryList,
      status: ASSESSMENT.STATUS.VALIDATED,
      scopeId: allScopeData[0].id,
      programId: programList[0].id,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionAssessmentOverviewAVData(assessmentRequestData));
  };

  const showAssesmentDetailTable = (formData: any) => {
    dispatch(actionSaveAssesmentData(formData));
    dialogClose();
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  const functionEditAssesmentDetailData = (rowValues: any) => {
    setEditFormValues(rowValues);
    setAssesmentEditLable(rowValues);
    dispatch(actionFetchAssesmentByIdData(rowValues.id));
    setCurrentAssesmentId(rowValues.id);
  };

  const functionAssesmentLevelData = (rowData: any) => {
    dispatch(actionDetailsSaveData(rowData));
    dispatch(actionFetchAssesmentLevelByIdData(rowData.id));
    setCurrentAssesmentId(rowData.id);
  };

  const setAssesmentEditLable = (editValues: any) => {
    setSelectedAssessmentCode(editValues.assessmentCode);
    setSelectedSupplyChain(editValues.supplyChain);
    setSelectedScope(editValues.scope);
    setSelectedRegion(editValues.region);
    setSelectedCountry(editValues.country);
    setSelectedSubRegion(editValues.countryHierarchyName);
    setSelectedAssessmentStatusId(editValues.assessmentStatusId);
    setSelectedAssessmentType(editValues.assessmentType);
    dispatch(actionSaveAssesmentDataSuccess(null));
  };

  const closeToasterFunction = () => {
    dispatch(closeToaster());
  };

  const showReportAssesment = () => {
    // this function will used for export excel.
    const formData = {
      countries: selectedCountryIdList,
      role: selectedRole,
      organization: userSelectedData.organization,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionExportAssesmentData(formData));
  };

  const handlerOpenAssessmentSendValidationConfirmDialog = () => {
    setTitleConfirmDialog(t("assessment.areYouSureSendForValidation"));
    setDialogType("SV");
    setOpenConfirmDialog(true);
  };
  const handlerOpenAssessmentValidatorValidateConfirmDialog = () => {
    setTitleConfirmDialog(t("assessment.areYouSureValidate"));
    setDialogType("V");
    setOpenConfirmDialog(true);
  };
  const handlerOpenAssessmentValidatorReAssignConfirmDialog = () => {
    setTitleConfirmDialog(t("assessment.areYouSureReAssign"));
    setDialogType("RA");
    setOpenConfirmDialog(true);
  };

  const handleIndicatorResetAssesorStatus = (): any => {
    let resetIndicatorAssessmentList: any = [];
    for (let i = 0; i < areaOfWorkListCheck.length; i++) {
      const tabContainData = assesmentDetailData.dynamicRowDataList.filter(
        (area: any) => area.AreaOfWorkId === areaOfWorkListCheck[i].id
      );
      let isAnyDisAgreeQuestion = false;
      for (let j = 0; j < tabDataCheck.length; j++) {
        const questionLevelData =
          tabContainData[0][tabDataCheck[j].tabValueField].QuestionList;

        const questionValidationDisAgreeData = questionLevelData.filter(
          (data: any) =>
            data.ValidationStatusId ===
            ASSESSMENT.QUESTION_VALIDATION_STATUS.DIS_AGREE.toString()
        );
        if (questionLevelData.length && questionValidationDisAgreeData.length) {
          isAnyDisAgreeQuestion = true;
        }
        if (isAnyDisAgreeQuestion && j < tabDataCheck.length) {
          for (let k = j + 1; k < tabDataCheck.length; k++) {
            const tabContainData = assesmentDetailData.dynamicRowDataList.filter(
              (area: any) => area.AreaOfWorkId === areaOfWorkListCheck[i].id
            );
            const questionLevelData =
              tabContainData[0][tabDataCheck[k].tabValueField].QuestionList;

            const questionIsCheckData = questionLevelData.filter(
              (data: any) => data.IsQuestionCheck === true
            );

            questionIsCheckData.map((question: any) => {
              resetIndicatorAssessmentList.push(question.AssessmentDetailId);
            });
          }
          break;
        }
      }
    }
    return resetIndicatorAssessmentList;
  };

  const handlerCloseAssessmentSendValidationConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const handleSendAssessmentForValidation = () => {
    const formData = {
      id: editFormValues.id,
      assessmentStatusId: ASSESSMENT.STATUS.SEND_FOR_VALIDATION,
      updatedBy: userId,
      countryDataList: [selectedCountryIdList[0]],
    };
    let assessmentStatusId = ASSESSMENT.STATUS.SEND_FOR_VALIDATION;
    switch (dialogType) {
      case "RA":
        assessmentStatusId = ASSESSMENT.STATUS.RE_ASSIGNED;
        break;
      case "V":
        assessmentStatusId = ASSESSMENT.STATUS.VALIDATED;
        break;
    }
    formData.assessmentStatusId = assessmentStatusId;
    dispatch(actionUpdateAssesmentValidationStatusData(formData));
    if (dialogType == "RA") {
      const resetData = handleIndicatorResetAssesorStatus();
      if (resetData.length) {
        const resetFormData: any = {
          id: resetData,
          updatedBy: userId,
        };
        dispatch(
          actionUpdateAssesmentQuestionResetAssessorStatusData(resetFormData)
        );
      }
    }
    setShowAssesmentTable(true);
    setOpenConfirmDialog(false);
    setCanShowOverview(false);
  };
  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFunction();
    }, COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
  };

  const checkAssessmentStatusLabel: any = (id: any) => {
    let statusLabel: string = "";
    switch (id) {
      case 1:
        statusLabel = t("assessment.assessorDraft");
        break;
      case 2:
        statusLabel = t("assessment.reAssigned");
        break;
      case 3:
        if (
          selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase()
        ) {
          statusLabel = t("assessment.sentForValidation");
        } else {
          statusLabel = t("assessment.pendingValidation");
        }
        break;
      case 4:
        statusLabel = t("assessment.validated");
        break;
      case 5:
        statusLabel = t("assessment.inProgressValidation");
        break;
    }
    return statusLabel;
  };
  return (
    <Container maxWidth={"xl"}>
      <Toaster
        message={toasterMessage}
        open={isToasterVisible}
        close={closeToasterFunction}
        success={isSuccess}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        close={handlerCloseAssessmentSendValidationConfirmDialog}
        title={titleConfirmDialog}
        content=""
        ok={handleSendAssessmentForValidation}
      />
      {showAssesmentTable ? (
        <>
          <SubHeaderWrapper>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography component="span" className="common-label">
                  {assesmentOveriewTitle}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item>
                    <Tooltip title={`${t("common.downloadReport")}`}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => showReportAssesment()}
                        disabled={isAddButtonDisable}
                        className="display-hidden"
                      >
                        <DownloadIconCustom className="arMarginR0 arMarginL10" />
                        {t("common.exportToExcel")}
                      </Button>
                    </Tooltip>
                  </Grid>
                  {selectedRole.toLowerCase().trim() ===
                    USER.ROLE.ASSESSOR.toLowerCase().trim() && (
                      <Grid item>
                        <Tooltip title={`${t("assessment.createAssesment")}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => showDialoagForm()}
                            disabled={isAddButtonDisable}
                          >
                            {t("assessment.createAssesment")}
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </SubHeaderWrapper>

          <AssessmentOverviewTabComponent
            assesmentData={assesmentData}
            functionEditAssesmentDetailData={functionEditAssesmentDetailData}
            functionAssesmentLevelData={functionAssesmentLevelData}
            formTitle={formTitle}
            setIsEditAssessment={setIsEditAssessment}
            selectedRole={selectedRole}
            checkAssessmentStatusLabel={checkAssessmentStatusLabel}
            setIsPendingValidation={setIsPendingValidation}
            canShowOverview={canShowOverview}
            setCanShowOverview={setCanShowOverview}
            tabValueSelected={tabValueSelected}
            setTabValueSelected={setTabValueSelected}
            labelProgramCount={labelProgramCount}
            programList={programList}
            selectedCountryIdList={selectedCountryIdList}
            getProgramData={getProgramData}
          ></AssessmentOverviewTabComponent>

          <AssesmentCreateFormComponent
            assesmentData={allAssessmentDetails}
            formTitle={formTitle}
            dialogClose={dialogClose}
            scopeData={listScope}
            userProgramList={userProgramList}
            userId={userId}
            closeToasterFunction={closeToasterFunction}
            showAssesmentDetailTable={showAssesmentDetailTable}
            selectedCountryIdList={selectedCountryIdList}
            dialogOpen={dialogOpen}
            dialogTitle={dialogTitle}
            hideDialoagForm={hideDialoagForm}
            selectedSubRegion={selectedSubRegion}
          ></AssesmentCreateFormComponent>
        </>
      ) : (
        <>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="paddingTB15"
          >
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton
                    color="default"
                    onClick={() => showAssesmentSetTable(currentAssesmentId)}
                    className="padding10"
                  >
                    <ArrowBackIosIconCustom className="arRotate180" />
                  </IconButton>
                </Grid>
                <Grid>
                  <Typography
                    component="span"
                    className="common-label padding10-5"
                  >
                    {isEditAssessment
                      ? t("common.editAssessment")
                      : t("common.viewAssessment")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {selectedRole.toLowerCase().trim() ===
              USER.ROLE.ASSESSOR.toLowerCase().trim() ? (
              <Grid item>
                {selectedAssessmentStatusId !== ASSESSMENT.STATUS.VALIDATED && isEditAssessment && (
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlerOpenAssessmentSendValidationConfirmDialog()
                        }
                        disabled={
                          !isEditAssessment
                        }
                      >
                        {t("assessment.sendForValidation")}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid item>
                {selectedAssessmentStatusId !== ASSESSMENT.STATUS.VALIDATED && isEditAssessment && (
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlerOpenAssessmentValidatorReAssignConfirmDialog()
                        }
                        disabled={!isEditAssessment || !isReAssignEnabled}
                      >
                        {t("assessment.reAssign")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlerOpenAssessmentValidatorValidateConfirmDialog()
                        }
                        disabled={!isEditAssessment || !isValidateEnabled}
                      >
                        {t("assessment.validate")}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <>
            <AssesmentQuestionTabsComponent
              showAssesmentDetailTable={showAssesmentDetailTable}
              assesmentDetailData={assesmentDetailData}
              editFormValues={editFormValues}
              userId={userId}
              isEditAssessment={isEditAssessment}
              selectedAssessmentCode={selectedAssessmentCode}
              selectedRegion={selectedRegion}
              selectedCountry={selectedCountry}
              selectedSupplyChain={selectedSupplyChain}
              selectedScope={selectedScope}
              selectedRole={selectedRole}
              selectedAssessmentType={selectedAssessmentType}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
              validationStatusData={listValidationStatus}
              loginUserData={loginUserData}
              isPendingValidation={isPendingValidation}
              setIsPendingValidation={setIsPendingValidation}
              selectedCountryIdList={selectedCountryIdList}
              selectedAssessmentStatusId={selectedAssessmentStatusId}
              setIsValidateEnabled={setIsValidateEnabled}
              setIsReAssignEnabled={setIsReAssignEnabled}
              setAreaOfWorkListCheck={setAreaOfWorkListCheck}
              setTabDataCheck={setTabDataCheck}
              closeToasterParticularTimeInterval={
                closeToasterParticularTimeInterval
              }
              openToaster={openToaster}
              isDataCheck={true}
              setLoaderVisibility={setLoaderVisibility}
              setIsUpdatedByOther={setIsUpdatedByOther}
            ></AssesmentQuestionTabsComponent>
          </>
        </>
      )}
    </Container>
  );
}

export default AssessmentOverview;
