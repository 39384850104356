import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import {
  fetchAssessmentCreateDetailData,
  saveAssessmentCreateData,
  fetchAssessmentBYIdDetailData,
  updateAssesmentValidationStatusData,
  updateAssesmentQuestionDetailData,
  updateAssesmentQuestionCommentData,
  fetchAssesmentLevelByIdData,
  fetchAssesmentValidationStatusData,
  updateAssesmentQuestionValidationCommentDetailData,
  fetchAssesmentQuestionValidationCommentBYIdDetailData,
  updateAssesmentQuestionResetValidationDetailData,
  updateAssesmentQuestionResetAssessorStatusDetailData,
  exportAssesmentDetailData,
  fetchProgramCurrentBYIdDetailData,
  getAssessmentReportData,
  getAssessmentReportRegionData,
  deleteAssesmentDetailById,
  getAssessmentScoringData
} from "./api";
import {
  actionFetchAssesmentDataSuccess,
  actionSaveAssesmentDataSuccess,
  actionFetchAssesmentData,
  actionFetchAssesmentByIdDataSuccess,
  actionFetchAssesmentByIdData,
  actionUpdateAssesmentValidationStatusDataSuccess,
  actionUpdateAssesmentQuestionDetailDataSuccess,
  actionUpdateAssesmentQuestionCommentDataSuccess,
  actionFetchAssesmentLevelByIdDataSuccess,
  actionFetchAssesmentValidationStatusDataSuccess,
  actionUpdateAssesmentQuestionValidationCommentDetailDataSuccess,
  actionFetchAssesmentValidationCommentDataSuccess,
  actionUpdateAssesmentQuestionResteValidationStatusDataSuccess,
  actionUpdateAssesmentInProgressValidationStatusDataSuccess,
  actionUpdateAssesmentQuestionResetAssessorStatusDataSuccess,
  actionExportAssesmentDataSuccess,
  actionFetchAssessmentProgramScoreByIdDataSuccess,
  actionFetchAssessmentReportSuccess,
  actionFetchAssessmentReportRegionSuccess,
  actionFetchAllAssesmentData,
  actionFetchAllAssesmentDataSuccess,
  actionAssessmentScoringDataSuccess,
} from "./actions";
import { ActionTypes } from "./constants";
import {
  setLoaderVisibility,
  openToaster,
  closeToaster,
  setSectionLoaderVisibility,
} from "../../store/common/actions";
import {
  API_ERROR_MESSAGE,
  DATA_DELETE_SUCCESS,
  DATA_SAVE_SUCCESS,
  DATA_UPDATE_SUCCESS,
  INDICATOR_SET_NOT_AVAILABLE,
} from "../../store/common/message";
import {
  getLocalDateFromMoment,
  getLocatDateInDateTimeFormat,
} from "../../utils/data";
import { ASSESSMENT_TYPE_CONST, COMMON_CONSTANT, IS_INDICATOR_SET_CONFLICT } from "constants/data";
import { actionFetchAdminAllAssesmentStatusCountSuccess, actionFetchAdminAssessmentDataSuccess } from "containers/admin-rsa-overview/actions";
import { fetchAdminAssesmentsDetailData, fetchAdminAssesmentStatusDataByCountry } from "containers/admin-rsa-overview/api";

function* watchGetAllAssesmentData() {
  yield takeLatest(
    ActionTypes.FETCH_ALL_ASSESSMENT_DETAILS,
    getAllAssesmentData
  );
}

function* getAllAssesmentData(action: any) {
  try {
    const response = yield call(
      fetchAssessmentCreateDetailData,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData && responseData.length) {
      responseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    } else {
      yield put(setLoaderVisibility(false));
    }

    yield put(actionFetchAllAssesmentDataSuccess(responseData));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssesmentData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_FETCH_ASSEMENT_DATA,
    getAssesmentData
  );
}

function* getAssesmentData(action: any) {
  try {
    const response = yield call(
      fetchAssessmentCreateDetailData,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData && responseData.length) {
      responseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    } else {
      yield put(setLoaderVisibility(false));
    }

    yield put(actionFetchAssesmentDataSuccess(responseData));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssesementDetailByIdData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_FETCH_ASSESMENT_BY_ID_DATA,
    getAssesementDetailByIdData
  );
}

function* getAssesementDetailByIdData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchAssessmentBYIdDetailData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssesmentByIdDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchSaveAssesmentData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_SAVE_ASSESMENT_DATA,
    saveAssesmentData
  );
}

function* saveAssesmentData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveAssessmentCreateData,
      action.payload.assesmentData
    );
    const responseData = response.data;
    yield put(actionSaveAssesmentDataSuccess(responseData));
    if (responseData && !responseData.isIndicatorSetConflict) {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    yield put(setLoaderVisibility(false));
  } catch (err) {
    if (err.response.data.detail === IS_INDICATOR_SET_CONFLICT) {
      yield put(openToaster(INDICATOR_SET_NOT_AVAILABLE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchUpdateAssesmentValidateStatusData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_VALIDATION_STATUS_DATA,
    updateAssesmentValidateStatusData
  );
}

function* updateAssesmentValidateStatusData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateAssesmentValidationStatusData,
      action.payload.assesmentData
    );
    const responseData = response.data;
    yield put(actionUpdateAssesmentValidationStatusDataSuccess(responseData));
    if (response.status === 204) {
      const assessmentTypeFilter = JSON.parse(localStorage.getItem('currentAssessmentType') || 'false');
      const userData: any = JSON.parse(localStorage.getItem("userSelectedData") || "{}");
      yield put(
        actionFetchAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": action.payload.assesmentData.countryDataList,
          "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        })
      );
      yield put(
        actionFetchAllAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": userData.userSelectedCountry
        })
      );
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentQuestDetailData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_DETAIL_DATA,
    updateAssesmentQuestDetailData
  );
}

function* updateAssesmentQuestDetailData(action: any) {
  try {
    const response = yield call(
      updateAssesmentQuestionDetailData,
      action.payload.assesmentQuestionData
    );
    const responseData = response.data;
    yield put(actionUpdateAssesmentQuestionDetailDataSuccess(responseData));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentQuestCommentData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_COMMENT_DATA,
    updateAssesmentQuestCommentData
  );
}

function* updateAssesmentQuestCommentData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateAssesmentQuestionCommentData,
      action.payload.assesmentQuestionCommentData
    );
    const responseData = response.data;
    yield put(actionUpdateAssesmentQuestionCommentDataSuccess(responseData));
    yield put(
      actionFetchAssesmentByIdData(
        action.payload.assesmentQuestionCommentData.assesmentId
      )
    );
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssesementLevelByIdData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_DETAILS_FETCH_ASSESMENT_LEVEL_BY_ID_DATA,
    getAssesementLevelByIdData
  );
}

function* getAssesementLevelByIdData(action: any) {
  try {
    yield put(setSectionLoaderVisibility(true));
    const response = yield call(
      fetchAssesmentLevelByIdData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssesmentLevelByIdDataSuccess(responseData));
    yield put(setSectionLoaderVisibility(false));
  } catch (err) {
    yield put(setSectionLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssesmentValidationStatusData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_ASSESSMENT_VALIDATION_STATUS_DATA,
    getAssesmentValidationStatusData
  );
}

function* getAssesmentValidationStatusData(action: any) {
  try {
    const response = yield call(
      fetchAssesmentValidationStatusData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssesmentValidationStatusDataSuccess(responseData));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentQuestionValidationCommentDetailData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_STATUS_DATA,
    updateAssesmentQuestValidationCommentDetailData
  );
}

function* updateAssesmentQuestValidationCommentDetailData(action: any) {
  try {
    const response = yield call(
      updateAssesmentQuestionValidationCommentDetailData,
      action.payload.assesmentQuestionData
    );
    const responseData = response.data;
    yield put(
      actionUpdateAssesmentQuestionValidationCommentDetailDataSuccess(
        responseData
      )
    );
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssesmentValidationCommentData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA,
    getAssesmentValidationCommentData
  );
}

function* getAssesmentValidationCommentData(action: any) {
  try {
    yield put(setSectionLoaderVisibility(true));
    const response = yield call(
      fetchAssesmentQuestionValidationCommentBYIdDetailData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssesmentValidationCommentDataSuccess(responseData));
    yield put(setSectionLoaderVisibility(false));
  } catch (err) {
    yield put(setSectionLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentQuestionResetValidationStatusDetailData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA,
    updateAssesmentQuestionResetValidationStatusDetailData
  );
}

function* updateAssesmentQuestionResetValidationStatusDetailData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateAssesmentQuestionResetValidationDetailData,
      action.payload.assesmentQuestionData
    );
    const responseData = response.data;
    yield put(
      actionUpdateAssesmentQuestionResteValidationStatusDataSuccess(
        responseData
      )
    );
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentInProgressValidateStatusData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_IN_PROGRESS_ASSESMENT_VALIDATION_STATUS_DATA,
    updateAssesmentInProgressValidateStatusData
  );
}

function* updateAssesmentInProgressValidateStatusData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateAssesmentValidationStatusData,
      action.payload.assesmentData
    );
    const responseData = response.data;
    yield put(
      actionUpdateAssesmentInProgressValidationStatusDataSuccess(responseData)
    );
    if (response.status === 204) {
      const assessmentTypeFilter = JSON.parse(localStorage.getItem('currentAssessmentType') || 'false');
      const userData: any = JSON.parse(localStorage.getItem("userSelectedData") || "{}");
      yield put(
        actionFetchAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": action.payload.assesmentData.countryDataList,
          "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        })
      );
      yield put(
        actionFetchAllAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": userData.userSelectedCountry
        })
      );
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateAssesmentQuestionResetAssessorsStatusDetailData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_CREATE_UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA,
    updateAssesmentQuestionResetAssessorsStatusDetailData
  );
}

function* updateAssesmentQuestionResetAssessorsStatusDetailData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateAssesmentQuestionResetAssessorStatusDetailData,
      action.payload.assesmentQuestionData
    );
    const responseData = response.data;
    yield put(
      actionUpdateAssesmentQuestionResetAssessorStatusDataSuccess(responseData)
    );
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}
function* watchGetExportAssesementData() {
  yield takeLatest(
    ActionTypes.ASSESMENT_DETAILS_EXPORT_ASSESMENT_DETAIL_DATA,
    getExportAssesementData
  );
}

function* getExportAssesementData(action: any) {
  try {
    yield put(setLoaderVisibility(true));

    const response = yield call(
      exportAssesmentDetailData,
      action.payload.formData
    );
    const responseData = response.data;
    const fileName = `assessmentDetails_${getLocatDateInDateTimeFormat(
      null
    )}.xlsx`;

    setTimeout(() => {
      const file: any = new Blob([responseData], {
        type: "application/octet-stream",
      });
      const a: any = document.createElement("a");
      a.className = "link-download";
      document.body.appendChild(a);
      const url: any = window.URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);
    }, 0);
    yield put(actionExportAssesmentDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssessmentScoringData() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_SCORING_DATA,
    getAllAssessmentScoringData
  );
}

function* getAllAssessmentScoringData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      getAssessmentScoringData,
      action.payload.assessmentRequestData
    );
    const responseData = response.data;
    yield put(actionAssessmentScoringDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAllAssessmentOverviewData() {
  yield takeLatest(
    ActionTypes.ALL_ASSESSMENT_OVERVIEW_DATA,
    getAllAssessmentOverviewData
  );
}

function* getAllAssessmentOverviewData(action: any) {
  try {
    yield put(setLoaderVisibility(true));

    const statusResponse = yield call(
      fetchAdminAssesmentStatusDataByCountry,
      {
        countries: action.payload.requestData.userRequestData.country,
        programId: action.payload.requestData.userRequestData.programId || null,
        assessmentType: action.payload.requestData.userRequestData.assessmentType
      }
    );

    const statusResponseData = statusResponse.data;
    if (statusResponseData && statusResponseData.length) {
      statusResponseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    }
    yield put(actionFetchAdminAllAssesmentStatusCountSuccess(statusResponseData));

    const response = yield call(
      getAssessmentScoringData,
      action.payload.requestData.assessmentRequestData
    );
    const responseData = response.data;
    yield put(actionAssessmentScoringDataSuccess(responseData));

    const adminResponse = yield call(
      fetchAdminAssesmentsDetailData,
      action.payload.requestData.adminAssessmentRequestData
    );

    const adminResponseData = adminResponse.data;
    if (adminResponseData && adminResponseData.length) {
      adminResponseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    }
    yield put(actionFetchAdminAssessmentDataSuccess(adminResponseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetProgramCurrentScoreDetailByIdData() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_CUREENT_SCORE_BY_PROGRAM_ID,
    getProgramCurrentScoreDetailByIdData
  );
}

function* getProgramCurrentScoreDetailByIdData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchProgramCurrentBYIdDetailData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssessmentProgramScoreByIdDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssessmentReportData() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_REPORT_DATA,
    getAssessmentReportDatas
  );
}

function* getAssessmentReportDatas(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getAssessmentReportData, action.payload.lookUp);
    const responseData = response.data;
    yield put(actionFetchAssessmentReportRegionSuccess([]));
    yield put(actionFetchAssessmentReportSuccess(responseData));
    if (responseData.length === 0) {
      yield put(setLoaderVisibility(false));
    }
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield put(setLoaderVisibility(false));
    console.log(err);
  }
}
function* watchGetAssessmentOverviewAVData() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_OVERVIEW_AV_DATA,
    getAssessmentOverviewAVData
  );
}

function* getAssessmentOverviewAVData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const programData = {
      status: action.payload.data.status,
      country: action.payload.data.country,
      programId: action.payload.data.programId,
      assessmentType: action.payload.data.assessmentType
    };

    const responseProgramStatus = yield call(
      fetchProgramCurrentBYIdDetailData,
      programData
    );

    const responseAssessmentStatus = yield call(
      getAssessmentScoringData,
      programData
    );
    const responseData = responseProgramStatus.data;
    yield put(actionFetchAssessmentProgramScoreByIdDataSuccess(responseData));

    const responseStatusData = responseAssessmentStatus.data;
    yield put(
      actionAssessmentScoringDataSuccess(responseStatusData)
    );
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAssessmentReportRegionData() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_REPORT_REGION_DATA,
    getAssessmentReportRegionDatas
  );
}

function* getAssessmentReportRegionDatas(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      getAssessmentReportRegionData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchAssessmentReportSuccess([]));
    yield put(actionFetchAssessmentReportRegionSuccess(responseData));

    if (responseData.length === 0) {
      yield put(setLoaderVisibility(false));
    }
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield put(setLoaderVisibility(false));
    console.log(err);
  }
}

function* watchDeleteAssessmentDataById() {
  yield takeLatest(
    ActionTypes.ASSESSMENT_DATA_DELETE_BY_ID,
    deleteAssessmentDataById
  );
}

function* deleteAssessmentDataById(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(deleteAssesmentDetailById, action.payload.data);
    if (response.data.isSuccess) {
      const assessmentTypeFilter = JSON.parse(localStorage.getItem('currentAssessmentType') || 'false');
      const userData: any = JSON.parse(localStorage.getItem("userSelectedData") || "{}");

      yield put(
        actionFetchAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": userData.userSelectedCountry,
          "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
        })
      );

      yield put(
        actionFetchAllAssesmentData({
          "roleId": userData.userSelectedRole,
          "countryIds": userData.userSelectedCountry
        })
      );
      yield put(openToaster(DATA_DELETE_SUCCESS(), true));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    yield put(setLoaderVisibility(false));
    console.log(err);
  }
}

export default function* assesementCreateSaga() {
  yield all([
    watchGetAssesmentData(),
    watchGetAllAssesmentData(),
    watchSaveAssesmentData(),
    watchGetAssesementDetailByIdData(),
    watchUpdateAssesmentValidateStatusData(),
    watchUpdateAssesmentQuestDetailData(),
    watchUpdateAssesmentQuestCommentData(),
    watchGetAssesementLevelByIdData(),
    watchGetAssesmentValidationStatusData(),
    watchUpdateAssesmentQuestionValidationCommentDetailData(),
    watchGetAssesmentValidationCommentData(),
    watchUpdateAssesmentQuestionResetValidationStatusDetailData(),
    watchUpdateAssesmentInProgressValidateStatusData(),
    watchUpdateAssesmentQuestionResetAssessorsStatusDetailData(),
    watchGetExportAssesementData(),
    watchGetProgramCurrentScoreDetailByIdData(),
    watchGetAssessmentReportData(),
    watchGetAssessmentReportRegionData(),
    watchGetAssessmentOverviewAVData(),
    watchDeleteAssessmentDataById(),
    watchGetAssessmentScoringData(),
    watchGetAllAssessmentOverviewData()
  ]);
}
