import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Toaster } from "../../components/common/toaster.component";
import { useTranslation } from "react-i18next";
import { closeToaster } from "../../store/common/actions";
import { actionSaveUnRegisterUserData } from "../user-profile/actions";
import { UserInfoPanel, DialogButtonPrimary } from "styles/common";


function UnRegisterUserPage(props: any) {
  const dispatch = useDispatch();
  const { loginUserData } = props;
  const { t } = useTranslation();
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const saveUnRegisterUserData = useSelector(
    (state: any) => state.userProfile.saveUnRegisterUserData
  );

  useEffect(() => { }, [saveUnRegisterUserData]);

  const sentMail = () => {
    let formData: any = {
      userEmail: loginUserData.email,
      isDeleted: 0,
      createdBy: "",
      firstName: toTitleCase(loginUserData.name),
      lastName: toTitleCase(loginUserData.lastName),
      userCountryRoleMappings: [],
    };
    dispatch(actionSaveUnRegisterUserData(formData));
    // window.open(`mailto:${mailto}?subject=${subject}&body=${bodyMessage}`);
  };
  const toTitleCase = (str: string) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <UserInfoPanel>
      <Toaster
        message={toasterMessage}
        open={isToasterVisible}
        close={closeToaster}
        success={isSuccess}
      />
      {loginUserData && loginUserData.email !== "" && (
        <>
          <>
            <Typography component="span" className="txt-user-name">{t("unRegisterUser.hiText")}{" "}{toTitleCase(loginUserData.name)}{" "}{toTitleCase(loginUserData.lastName)}</Typography>
            {loginUserData.id !== "" && !saveUnRegisterUserData && (
              <Typography
                component="span"
                dangerouslySetInnerHTML={{
                  __html: t("unRegisterUser.requestInprocess"),
                }}
              />
            )}
            {saveUnRegisterUserData && (
              <Typography
                component="span"
                dangerouslySetInnerHTML={{
                  __html: t("unRegisterUser.pageRequestSent"),
                }}
              />
            )}
            {loginUserData.id === "" && !saveUnRegisterUserData && (
              <Typography
                component="span"
                dangerouslySetInnerHTML={{
                  __html: t("unRegisterUser.pageSentMail"),
                }}
              />
            )}
          </>
          {loginUserData.id === "" && !saveUnRegisterUserData && (
            <Typography component="div" className="padding-top-10">
              <DialogButtonPrimary
                onClick={sentMail}
                color="primary"
                variant="contained"
              >
                {t("unRegisterUser.accessRequest")}
              </DialogButtonPrimary>
            </Typography>
          )}
        </>
      )}
    </UserInfoPanel>
  );
}

export default UnRegisterUserPage;
