import { ActionTypes } from "./constants";
const initialState = {
  questionSetCode: "",
  questionSetData: [],
  questionSetDataById: [],
  saveQuestionSetData: [],
  deleteQuestion: [],
  questionBySetIdData: [],
  questionBySetIdAreaOfWorkData: [],
  saveQuestionData: [],
  swipeQuestionData: [],
  questionDataByQuestionId: [],
  questionSetCompletedData: [],
  questionSetCopyData: false,
  questionAreaLevelCommentDataById: [],
  updateQuestionAreaLevelCommentById: [],
  nonAssignedTopicList: []
};

export const questionReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.QUESTION_GET_QUESTION_SET_CODE_DATA_SUCCESS:
      return {
        ...state,
        questionSetCode: action.payload.data || "",
      };
    case ActionTypes.QUESTION_GET_QUESTION_SET_DATA_SUCCESS:
      return {
        ...state,
        questionSetData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_GET_QUESTION_SET_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        questionSetDataById: action.payload.data || [],
      };
    case ActionTypes.QUESTION_SAVE_QUESTION_SET_DATA_SUCCESS:
      return {
        ...state,
        saveQuestionSetData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA_SUCCESS:
      return {
        ...state,
        questionBySetIdData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA_SUCCESS:
      return {
        ...state,
        questionBySetIdAreaOfWorkData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_SAVE_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        saveQuestionData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_SWIPE_QUESTION_SEQUENCE_DATA_SUCCESS:
      return {
        ...state,
        swipeQuestionData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_DELETE_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        deleteQuestion: action.payload.data || [],
      };
    case ActionTypes.QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA_SUCCESS:
      return {
        ...state,
        questionDataByQuestionId: action.payload.data || [],
      };
    case ActionTypes.QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA_SUCCESS:
      return {
        ...state,
        questionSetCompletedData: action.payload.data || [],
      };
    case ActionTypes.QUESTION_COPY_QUESTION_SET_DATA_SUCCESS:
      return {
        ...state,
        questionSetCopyData: action.payload.data || false,
      };
    case ActionTypes.QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        questionAreaLevelCommentDataById: action.payload.data || [],
      };
    case ActionTypes.QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS:
      return {
        ...state,
        updateQuestionAreaLevelCommentById: action.payload.data || [],
      };
    case ActionTypes.QUESTION_GET_NON_ASSIGNED_TOPIC_SUCCESS:
      return {
        ...state,
        nonAssignedTopicList: action.payload.data || [],
      };
    default:
      return state;
  }
};
