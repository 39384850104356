import { useDispatch } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { history } from "../../store/config";
import { SIGN_OUT_PATH } from "../../constants/api";
import { DialogButtonPrimary, HomeRightSideWrapper, ResponsiveBgImage, UserInfoPanel } from "../../styles/common";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../styles/images/HomeBackground.png";
import { actionSetAuthenticationSuccess } from "../../containers/user-profile/actions";
import React from "react";

function ErrorComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        dispatch(actionSetAuthenticationSuccess(null));
        window.location.replace(SIGN_OUT_PATH);
        history.replace(SIGN_OUT_PATH);
        window.location.reload();
    };

    return (
        <>
            <Grid container alignItems="stretch">
                <Grid item xs={7} className="position-relative">
                    <Typography component="div" className="display-flex">
                        <ResponsiveBgImage src={BackgroundImage} alt="Background Image" />
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <HomeRightSideWrapper>
                        <Grid container direction="row" justify="space-around">
                            <Grid item lg={8} xs={11}>
                                <Typography component="div">
                                    <UserInfoPanel>
                                        <Typography component="span" className="txt-user-name">
                                            {t("unRegisterUser.hiText")}
                                        </Typography>
                                        <Typography
                                            component="span"
                                            dangerouslySetInnerHTML={{
                                                __html: t("errorPage.errorMessage"),
                                            }}
                                        />
                                        <DialogButtonPrimary
                                            variant="contained"
                                            color="primary"
                                            onClick={logout}
                                            className="marginT20"
                                        >
                                            {t("home.logout")}
                                        </DialogButtonPrimary>
                                    </UserInfoPanel>
                                </Typography>
                            </Grid>
                        </Grid>
                    </HomeRightSideWrapper>
                </Grid>
            </Grid>
        </>
    );
}

export default ErrorComponent;