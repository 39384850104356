import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {
  FullWidthContainer,
  FormToolbar,
  WarningLabel,
} from "../../../styles/common";
import FormInput from "../../../components/form-controls/input";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import {
  DialogClose,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../../styles/common";

import { actionUpdateQuestionAreaLevelCommentByIdDetail } from "../actions";
import * as yup from "yup";
import { testInputValueWithRegex } from "utils/security";

function QuestionAreaLevelCommentFormComponent(props: any) {
  const {
    dialogClose,
    userId,
    dialogOpen,
    isAreaLevelCommentEmpty,
    questionSetId,
    currentIndicatorTopicId,
    questionBySetIdAreaOfWorkData,
    tabId
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let levelId = 1;
  const validateSchema = yup.object().shape({
    nameEn: yup
      .string()
      .trim()
      .required(t("indicators.pleaseEnterTitleDescription"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameFr: yup
      .string()
      .trim()
      .required(t("indicators.pleaseEnterTitleDescription"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameEs: yup
      .string()
      .trim()
      .required(t("indicators.pleaseEnterTitleDescription"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameRu: yup
      .string()
      .trim()
      .required(t("indicators.pleaseEnterTitleDescription"))
      .test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    nameAr: yup
      .string()
      .trim()
      .required(t("indicators.pleaseEnterTitleDescription")).test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
  });
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors } = methods;

  const { setValue } = methods;
  const questionAreaLevelCommentDataById = useSelector(
    (state: any) => state.question.questionAreaLevelCommentDataById
  );

  useEffect(() => {
    if (
      questionAreaLevelCommentDataById &&
      questionAreaLevelCommentDataById.length
    ) {
      if (questionAreaLevelCommentDataById[0].nameEn) {
        setValue("nameEn", questionAreaLevelCommentDataById[0].nameEn);
      }
      if (questionAreaLevelCommentDataById[0].nameEn) {
        setValue("nameEs", questionAreaLevelCommentDataById[0].nameEs);
      }
      if (questionAreaLevelCommentDataById[0].nameFr) {
        setValue("nameFr", questionAreaLevelCommentDataById[0].nameFr);
      }
      if (questionAreaLevelCommentDataById[0].nameRu) {
        setValue("nameRu", questionAreaLevelCommentDataById[0].nameRu);
      }
      if (questionAreaLevelCommentDataById[0].nameAr) {
        setValue("nameAr", questionAreaLevelCommentDataById[0].nameAr);
      }
    } else {
      setValue("nameEn", "");
      setValue("nameEs", "");
      setValue("nameFr", "");
      setValue("nameRu", "");
      setValue("nameAr", "");

    }
  }, [questionAreaLevelCommentDataById]);

  const onSubmit = (data: any) => {
    let tabDatafilter = [];
    let levelType = `${"Level" + tabId}`;
    tabDatafilter = questionBySetIdAreaOfWorkData.filter(
      (a: any) => a.field === levelType
    );
    if (tabDatafilter.length) {
      levelId = tabDatafilter[0].fielId;
    }
    data.id = questionAreaLevelCommentDataById.length ? questionAreaLevelCommentDataById[0].id : null;
    data.questionSetId = questionSetId;
    data.questionSetTopicId = currentIndicatorTopicId;
    data.levelId = questionAreaLevelCommentDataById.length ? questionAreaLevelCommentDataById[0].levelId : levelId;
    data.updatedBy = userId;
    data.isDeleted = false;
    data.nameEn = data.nameEn.trim();
    data.nameEs = data.nameEs.trim();
    data.nameFr = data.nameFr.trim();
    data.nameRu = data.nameRu.trim();
    data.nameAr = data.nameAr.trim();
    dispatch(actionUpdateQuestionAreaLevelCommentByIdDetail(data));
    dialogClose();
  };

  return (
    <>
      <FullWidthContainer>
        <Dialog
          open={dialogOpen}
          onClose={dialogClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title" className="padding16-24 arfloatLeft">
            {isAreaLevelCommentEmpty
              ? t("indicators.addTitle")
              : t("indicators.editTitle")}
            <DialogClose aria-label="close" onClick={() => dialogClose()}>
              <CloseIcon />
            </DialogClose>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="alert-dialog-description"
              className="nomargin"
            ></DialogContentText>
            <Typography component="span">
              <WarningLabel>{t("indicators.editDescription")}</WarningLabel>
            </Typography>
            <FormToolbar>
              <FormContext {...methods}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">
                        {t("common.language")}: {t("language.english")}
                      </Typography>
                      <FormInput
                        type="text"
                        name="nameEn"
                        label={t("indicators.areaLevelDescription")}
                        multiline
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 250 }}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">
                        {t("common.language")}: {t("language.french")}
                      </Typography>
                      <FormInput
                        type="text"
                        name="nameFr"
                        label={t("indicators.areaLevelDescription")}
                        multiline
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 250 }}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">
                        {t("common.language")}: {t("language.spanish")}
                      </Typography>
                      <FormInput
                        type="text"
                        name="nameEs"
                        label={t("indicators.areaLevelDescription")}
                        multiline
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 250 }}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">
                        {t("common.language")}: {t("language.russian")}
                      </Typography>
                      <FormInput
                        type="text"
                        name="nameRu"
                        label={t("indicators.areaLevelDescription")}
                        multiline
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 250 }}
                        rows={2}
                        className="direction-ltr"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="span" className="language-title">
                        {t("common.language")}: {t("language.arabic")}
                      </Typography>
                      <FormInput
                        type="text"
                        name="nameAr"
                        label={t("indicators.areaLevelDescription")}
                        multiline
                        required={true}
                        errorobj={errors}
                        inputProps={{ maxLength: 250 }}
                        rows={2}
                        className="direction-rtl"
                      />
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </FormToolbar>
          </DialogContent>
          <DialogActions className="padding16-24">
            <Grid container alignItems="center" justify="flex-end" spacing={2}>
              <Grid item>
                <DialogButtonDefault
                  variant="contained"
                  color="default"
                  onClick={dialogClose}
                >
                  {t("common.cancel")}
                </DialogButtonDefault>
              </Grid>
              <Grid item>
                <DialogButtonPrimary
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  {isAreaLevelCommentEmpty
                    ? t("common.add")
                    : t("common.update")}
                </DialogButtonPrimary>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </FullWidthContainer>
    </>
  );
}

export default QuestionAreaLevelCommentFormComponent;
