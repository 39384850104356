import api from "../../utils/api";
import {
  GET_MASTER_MENU_DYNAMIC_FORM_FIELD,
  GET_MASTER_ALL_MENU_DYNAMIC_FORM_FIELD,
  SAVE_DYNAMIC_FORM_FIELD_DATA,
  DELETE_DYNAMIC_FORM_FIELD_DATA,
  GET_DYNAMIC_FORM_FIELD_DATA,
  GET_MATURITY_INDEX_DATA,
  SAVE_MATURITY_INDEX_DATA,
  DELETE_MATURITY_INDEX_DATA,
  UPDATE_MATURITY_INDEX_DATA,
  SAVE_AREA_OF_WORK_TOOL_DATA,
  DELETE_AREA_OF_WORK_TOOL_DATA,
  UPDATE_AREA_OF_WORK_TOOL_DATA,
  GET_AREA_OF_WORK_TOOL_DATA,
  GET_AREA_OF_WORK_TOOL_SINGLE_DATA,
  GET_MASTER_AOW_TOPIC_HIERARCHY,
  GET_AREA_OF_WORK_TOOLS_DATA,
  GET_MASTER_AOW_OR_TOPIC,
  SAVE_MASTER_AOW_OR_TOPIC,
  GET_COUNTRY_HIERARCHY,
  SAVE_COUNTRY_HIERARCHY,
  UPDATE_COUNTRY_HIERARCHY,
  DELETE_COUNTRY_HIERARCHY,
  DELETE_TOPIC_AOW_HIERARCHY,
  GET_EXPORT_REPORT_TABLE_DETAILS,
  GET_USER_COUNTRY_LISTING,
  GET_VC_STATISTICS_DATA,
} from "./constants";

/**
 * Function for getting some data
 */
export const fetchMasterMenuDynamicFormFieldDetails = (lookUp: any) => {
  return api.get(`${GET_MASTER_MENU_DYNAMIC_FORM_FIELD}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};
export const fetchMasterMenuAllDynamicFormFieldAndDataDetails = (
  lookUp: any
) => {
  return api.get(`${GET_MASTER_ALL_MENU_DYNAMIC_FORM_FIELD}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchDynamicFormFieldDataDetails = (lookUp: any) => {
  return api.get(`${GET_DYNAMIC_FORM_FIELD_DATA}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveMasterDynamicFormFieldDataDetails = (formData: any) => {
  return api.post(`${SAVE_DYNAMIC_FORM_FIELD_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const deleteMasterDynamicFormRecordDataDetails = (formData: any) => {
  return api.post(`${DELETE_DYNAMIC_FORM_FIELD_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchMasterMaturityIndexData = (lookUp: any) => {
  return api.get(`${GET_MATURITY_INDEX_DATA}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const updateMasterMaturityIndexData = (formData: any) => {
  return api.post(`${UPDATE_MATURITY_INDEX_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveMasterMaturityIndexData = (formData: any) => {
  return api.post(`${SAVE_MATURITY_INDEX_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const deleteMasterMaturityIndexData = (formData: any) => {
  return api
    .delete(`${DELETE_MATURITY_INDEX_DATA}`, {
      data: formData,
    })
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};


export const fetchMasterAreaOfWorkToolData = (lookUp: any, roleId: any) => {
  return api.get(`${GET_AREA_OF_WORK_TOOL_DATA}/${lookUp}?roleId=${roleId}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchMasterAreaOfWorkToolSingleData = (lookUp: any) => {
  return api.get(`${GET_AREA_OF_WORK_TOOL_SINGLE_DATA}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const updateMasterAreaOfWorkToolData = async (formData: any) => {
  const response = await api.put(`${UPDATE_AREA_OF_WORK_TOOL_DATA}`, formData);
  return response;
};

export const saveMasterAreaOfWorkToolData = async (formData: any) => {
  const response = await api.post(`${SAVE_AREA_OF_WORK_TOOL_DATA}`, formData);
  return response;
};

export const deleteMasterAreaOfWorkToolData = (formData: any) => {
  return api
    .delete(`${DELETE_AREA_OF_WORK_TOOL_DATA}`, {
      data: formData,
    })
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getMasterAowTopic = async () => {
  const response = await api.get(`${GET_MASTER_AOW_TOPIC_HIERARCHY}`);
  return response;
};

export const getMasterAowOrTopicDetails = async (type: any, formId: any, topicId: any) => {
  const response = await api.get(`${GET_MASTER_AOW_OR_TOPIC}?type=${type}&formId=${formId}&topicId=${topicId ? topicId : ``}`);
  return response;
};

export const fetchMasterAreaOfWorkToolsData = () => {
  return api.get(`${GET_AREA_OF_WORK_TOOLS_DATA}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveMasterAreaOfWorkOrTopicData = async (data: any) => {
  const response = await api.post(`${SAVE_MASTER_AOW_OR_TOPIC}`, data);
  return response;
};


export const getMasterCountryHierarchy = async (id: any) => {
  const response = await api.get(`${GET_COUNTRY_HIERARCHY}/${id}`);
  return response;
};

export const postMasterCountryHierarchy = async (formData: any) => {
  const response = await api.post(`${SAVE_COUNTRY_HIERARCHY}`, formData);
  return response;
};

export const updateMasterCountryHierarchy = async (formData: any) => {
  const response = await api.post(`${UPDATE_COUNTRY_HIERARCHY}`, formData);
  return response;
};

export const deleteMasterCountryHierarchy = async (formData: any) => {
  const response = await api.post(`${DELETE_COUNTRY_HIERARCHY}`, formData);
  return response;
};

export const deleteMasterTopicAowHierarchy = async (topicId: any) => {
  const response = await api.delete(`${DELETE_TOPIC_AOW_HIERARCHY}?topicId=${topicId}`);
  return response;
};

export const exportReportTableDetailData = (formData: any) => {
  return api
    .post(`${GET_EXPORT_REPORT_TABLE_DETAILS}`, formData, { responseType: "blob" })
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getUserAndCountryList = () => {
  return api
    .get(`${GET_USER_COUNTRY_LISTING}`)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getVCStatictsData = (formData: any) => {
  return api
    .post(`${GET_VC_STATISTICS_DATA}`, formData)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

