import api from "../utils/api";
import {
  GET_APPLICATIONS_LIST, GET_SCS_USER_DETAILS, POST_APP_LOG_HISTORY, POST_SCS_USER_ACCESS_REQUEST
} from "./constants";

/**
 * Function for getting some data
 */
export const getApplicationsList = async () => {
  const response = await api.get(`${GET_APPLICATIONS_LIST}`);
  return response;
};

export const getSCSUserDetails = async () => {
  const response = await api.get(`${GET_SCS_USER_DETAILS}`);
  return response;
};

export const postUserAccessReques = async (userData: any) => {
  const response = await api.post(`${POST_SCS_USER_ACCESS_REQUEST}`, userData,);
  return response;
};

export const postUserApplicationClick = async (appDetail: any) => {
  const response = await api.post(`${POST_APP_LOG_HISTORY}`, appDetail);
  return response;
};
