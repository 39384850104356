import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAdminAssesmentsDetailData,
  fetchAdminAssesmentStatusDataByCountry,
} from "./api";
import {
  actionFetchAdminAssessmentDataSuccess,
  actionFetchAdminAllAssesmentStatusCountSuccess,
} from "./actions";
import { ActionTypes } from "./constants";
import { setLoaderVisibility, openToaster } from "../../store/common/actions";
import { API_ERROR_MESSAGE } from "../../store/common/message";
import { getLocalDateFromMoment } from "../../utils/data";

function* watchGetAdminAssesmentData() {
  yield takeLatest(
    ActionTypes.ADMIN_FETCH_ASSESSMENT_DATA,
    getAdminAssesmentData
  );
}

function* getAdminAssesmentData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchAdminAssesmentsDetailData,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData && responseData.length) {
      responseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    }
    yield put(actionFetchAdminAssessmentDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetAdminAllAssesmentCountData() {
  yield takeLatest(
    ActionTypes.ADMIN_FETCH_ALL_COUNTRY_ASSESSMENT_COUNT,
    getAdminAllAssesmentCountData
  );
}

function* getAdminAllAssesmentCountData(action: any) {
  try {
    let response: any = null;
    response = yield call(
      fetchAdminAssesmentStatusDataByCountry,
      {
        countries: action.payload.data.country,
        programId: action.payload.data.programId || null,
        assessmentType: action.payload.data.assessmentType
      }
    );


    const responseData = response.data;
    if (responseData && responseData.length) {
      responseData.map((up: any) => {
        up.createdDate = getLocalDateFromMoment(up.createdDate);
        up.updatedDate = up.updatedDate
          ? getLocalDateFromMoment(up.updatedDate)
          : "";
      });
    }
    yield put(actionFetchAdminAllAssesmentStatusCountSuccess(responseData));
  } catch (err) {
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

export default function* adminRsaSaga() {
  yield all([
    watchGetAdminAssesmentData(),
    watchGetAdminAllAssesmentCountData(),
  ]);
}
