import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchCountryDetails, fetchUserRoleData } from "./api";
import {
  actionFetchCountryDataSuccess,
  actionFetchUserRoleDataSuccess,
} from "./actions";
import { ActionTypes } from "./constants";

function* watchCountryDetails() {
  yield takeLatest(ActionTypes.COMMON_FETCH_COUNTRY_DATA, getCountryDetails);
}

function* getCountryDetails(action: any) {
  try {
    const response = yield call(fetchCountryDetails);
    const responseData = response.data;
    yield put(actionFetchCountryDataSuccess(responseData));
  } catch (err) {
    console.log(err);
  }
}

function* watchUserROleDetails() {
  yield takeLatest(
    ActionTypes.COMMON_GET_USER_ROLE_DETAILS,
    getUserRoleDetails
  );
}

function* getUserRoleDetails(action: any) {
  try {
    const response = yield call(fetchUserRoleData, action.payload.lookUp);
    const responseData = response.data;
    yield put(actionFetchUserRoleDataSuccess(responseData));
  } catch (err) {
    console.log(err);
  }
}

export default function* commonSaga() {
  yield all([watchCountryDetails(), watchUserROleDetails()]);
}
