export const ActionTypes = {
  MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA:
    "MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA",
  MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA_SUCCESS:
    "MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA_SUCCESS",
  MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA:
    "MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA",
  MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA_SUCCESS:
    "MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA_SUCCESS",

  MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA:
    "MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA",
  MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
    "MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA_SUCCESS",

  MASTER_MENU_EXPORT_REPORT_TABLE_DATA:
    "MASTER_MENU_EXPORT_REPORT_TABLE_DATA",
  MASTER_MENU_EXPORT_REPORT_TABLE_DATA_SUCCESS:
    "MASTER_MENU_EXPORT_REPORT_TABLE_DATA_SUCCESS",

  MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA:
    "MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA",
  MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
    "MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA_SUCCESS",
  MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA:
    "MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA",
  MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA_SUCCESS:
    "MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA_SUCCESS",
  MASTER_MATURITY_INDEX_DATA: "MASTER_MATURITY_INDEX_DATA",
  MASTER_MATURITY_INDEX_DATA_SUCCESS: "MASTER_MATURITY_INDEX_DATA_SUCCESS",
  MASTER_MATURITY_INDEX_SAVE: "MASTER_MATURITY_INDEX_SAVE",
  MASTER_MATURITY_INDEX_SAVE_SUCCESS: "MASTER_MATURITY_INDEX_SAVE_SUCCESS",
  MASTER_MATURITY_INDEX_DELETE: "MASTER_MATURITY_INDEX_DELETE",
  MASTER_MATURITY_INDEX_DELETE_SUCCESS: "MASTER_MATURITY_INDEX_DELETE_SUCCESS",
  MASTER_MATURITY_INDEX_CALL: "MASTER_MATURITY_INDEX_CALL",
  MASTER_MATURITY_INDEX_DATA_UPDATE: "MASTER_MATURITY_INDEX_DATA_UPDATE",
  MASTER_AREA_OF_WORK_TOOL_DATA: "MASTER_AREA_OF_WORK_TOOL_DATA",
  MASTER_AREA_OF_WORK_TOOL_DATA_SUCCESS: "MASTER_AREA_OF_WORK_TOOL_DATA_SUCCESS",
  MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA: "MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA",
  MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA_SUCCESS: "MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA_SUCCESS",
  MASTER_AREA_OF_WORK_TOOL_SAVE: "MASTER_AREA_OF_WORK_TOOL_SAVE",
  MASTER_AREA_OF_WORK_TOOL_SAVE_SUCCESS: "MASTER_AREA_OF_WORK_TOOL_SAVE_SUCCESS",
  MASTER_AREA_OF_WORK_TOOL_DELETE: "MASTER_AREA_OF_WORK_TOOL_DELETE",
  MASTER_AREA_OF_WORK_TOOL_DELETE_SUCCESS: "MASTER_AREA_OF_WORK_TOOL_DELETE_SUCCESS",
  MASTER_AREA_OF_WORK_TOOL_CALL: "MASTER_AREA_OF_WORK_TOOL_CALL",
  MASTER_AREA_OF_WORK_TOOL_DATA_UPDATE: "MASTER_AREA_OF_WORK_TOOL_DATA_UPDATE",
  MASTER_AREA_OF_WORK_TOOL_UPDATE_SUCCESS: "MASTER_AREA_OF_WORK_TOOL_UPDATE_SUCCESS",

  MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY: "MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY",
  MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY_SUCCESS: "MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY_SUCCESS",

  MASTER_AOW_TOPIC_DETAILS: "MASTER_AOW_TOPIC_DETAILS",
  MASTER_AOW_TOPIC_DETAILS_SUCCESS: "MASTER_AOW_TOPIC_DETAILS_SUCCESS",

  MASTER_AREA_OF_WORK_TOOLS_DATA: "MASTER_AREA_OF_WORK_TOOLS_DATA",
  MASTER_AREA_OF_WORK_TOOLS_DATA_SUCCESS: "MASTER_AREA_OF_WORK_TOOLS_DATA_SUCCESS",

  MASTER_SAVE_AOW_OR_TOPIC: "MASTER_SAVE_AOW_OR_TOPIC",
  MASTER_SAVE_AOW_OR_TOPIC_SUCCESS: "MASTER_SAVE_AOW_OR_TOPIC_SUCCESS",

  MASTER_GET_COUNTRY_HIERARCHY: "MASTER_GET_COUNTRY_HIERARCHY",
  MASTER_GET_COUNTRY_HIERARCHY_SUCCESS: "MASTER_SAVE_COUNTRY_HIERARCHY_SUCCESS",

  MASTER_SAVE_COUNTRY_HIERARCHY: "MASTER_SAVE_COUNTRY_HIERARCHY",
  MASTER_SAVE_COUNTRY_HIERARCHY_SUCCESS: "MASTER_SAVE_COUNTRY_HIERARCHY_SUCCESS",

  MASTER_UPDATE_COUNTRY_HIERARCHY: "MASTER_UPDATE_COUNTRY_HIERARCHY",
  MASTER_UPDATE_COUNTRY_HIERARCHY_SUCCESS: "MASTER_UPDATE_COUNTRY_HIERARCHY_SUCCESS",

  MASTER_DELETE_COUNTRY_HIERARCHY: "MASTER_DELETE_COUNTRY_HIERARCHY",
  MASTER_DELETE_COUNTRY_HIERARCHY_SUCCESS: "MASTER_DELETE_COUNTRY_HIERARCHY_SUCCESS",

  MASTER_DELETE_TOPIC_AOW_HIERARCHY: "MASTER_DELETE_TOPIC_AOW_HIERARCHY",
  MASTER_DELETE_TOPIC_AOW_HIERARCHY_SUCCESS: "MASTER_DELETE_TOPIC_AOW_HIERARCHY_SUCCESS",

  MASTER_USER_COUNTRY_LIST: "MASTER_USER_COUNTRY_LIST",
  MASTER_USER_COUNTRY_LIST_SUCCESS: "MASTER_USER_COUNTRY_LIST_SUCCESS",

  MASTER_VC_REPORT_STATS: "MASTER_VC_REPORT_STATS",
  MASTER_VC_REPORT_STATS_SUCCESS: "MASTER_VC_REPORT_STATS_SUCCESS"

};

export const GET_MASTER_MENU_DYNAMIC_FORM_FIELD: string = `/api/MasterDetails/GetDynamicFormFields`;
export const GET_MASTER_ALL_MENU_DYNAMIC_FORM_FIELD: string = `/api/MasterDetails/GetAllDynamicMasterData`;
export const GET_DYNAMIC_FORM_FIELD_DATA: string = `/api/MasterDetails/GetDynamicFormDetails`;
export const SAVE_DYNAMIC_FORM_FIELD_DATA: string = `/api/MasterDetails/SaveDynamicFormDetails`;
export const DELETE_DYNAMIC_FORM_FIELD_DATA: string = `/api/MasterDetails/DeleteDynamicFormDetails`;
export const GET_MATURITY_INDEX_DATA: string = `/api/MaturityScore/GetMaturityScores`;
export const SAVE_MATURITY_INDEX_DATA: string = `/api/MaturityScore/SaveMaturityScore`;
export const DELETE_MATURITY_INDEX_DATA: string = `/api/MaturityScore/DeleteMaturityScore`;
export const UPDATE_MATURITY_INDEX_DATA: string = `/api/MaturityScore/UpdateMaturityScore`;
export const GET_AREA_OF_WORK_TOOL_DATA: string = `/api/AreaofWorkTool/GetAreaOfWorkTools`;
export const GET_AREA_OF_WORK_TOOL_SINGLE_DATA: string = `/api/AreaofWorkTool/GetAreaOfWorkTool`;
export const SAVE_AREA_OF_WORK_TOOL_DATA: string = `/api/AreaofWorkTool/SaveAreaOfWorkTool`;
export const DELETE_AREA_OF_WORK_TOOL_DATA: string = `/api/AreaofWorkTool/DeleteAreaOfWorkTool`;
export const UPDATE_AREA_OF_WORK_TOOL_DATA: string = `/api/AreaofWorkTool/UpdateAreaOfWorkTool`;
export const GET_AREA_OF_WORK_TOOLS_DATA: string = `/api/AreaOfWorkTool/GetAreaOfWorkTools`;
export const GET_MASTER_AOW_TOPIC_HIERARCHY: string = `/api/AreaOfWorkManagement/GetAowTopicHierarchy`
export const GET_MASTER_AOW_OR_TOPIC: string = `/api/AreaOfWorkManagement/GetAowTopic`
export const SAVE_MASTER_AOW_OR_TOPIC: string = `/api/AreaOfWorkManagement/SaveAowTopic`
export const GET_COUNTRY_HIERARCHY: string = `/api/MasterDetails/GetCountryHierarchy`
export const SAVE_COUNTRY_HIERARCHY: string = `/api/MasterDetails/SaveCountryHierarchy`
export const UPDATE_COUNTRY_HIERARCHY: string = `/api/MasterDetails/UpdateCountryHierarchy`
export const DELETE_COUNTRY_HIERARCHY: string = `/api/MasterDetails/DeleteCountryHierarchy`
export const DELETE_TOPIC_AOW_HIERARCHY: string = `api/AreaOfWorkManagement/DeleteTopic`
export const GET_EXPORT_REPORT_TABLE_DETAILS: string = `api/Assessment/ExportAssessmentReportData`;
export const GET_USER_COUNTRY_LISTING: string = `/api/Statistics/GetData`;
export const GET_VC_STATISTICS_DATA: string = `/api/Statistics/Get`;

