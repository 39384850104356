import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { ScmmAppBar } from "../../../styles/common";
import { GridTableWrapper } from "../../assessment-create/style";
import RsaAssesementTableComponent from "../rsa-assessment-table";
import { ASSESSMENT } from "constants/data";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RsaAssessmentTabComponent(props: any) {
  const {
    assesmentData,
    functionEditAssesmentDetailData,
    functionAssesmentLevelData,
    formTitle,
    setIsEditAssessment,
    setIsPendingValidation,
    canShowOverview,
    setCanShowOverview,
    setTabValueSelected,
    tabValueSelected,
    setShowDetails,
    setAssessmentTabValue,
    assessmentTabValue
  } = props;
  const { t } = useTranslation();

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );
  const [editorDraftData, setEditorDraftData] = useState([]);
  const [lableEditorDraft, setlableEditorDraft]: any = useState(0);
  const [reAssignedData, setReAssignedData] = useState([]);
  const [lableReAssigened, setlableReAssigened]: any = useState(0);
  const [sendForValidationData, setsendForValidationData] = useState([]);
  const [lableSendForValidation, setlableSendForValidation]: any = useState(0);
  const [validatedData, setValidatedData] = useState([]);
  const [lableValidated, setlableValidated]: any = useState(0);
  const [inProgressValidationData, setInProgressValidationData] = useState([]);
  const [lableInProgressValidation, setLableInProgressValidation]: any = useState(0);

  useEffect(() => {
    if (assesmentData)
      handleTabLevelData_RoleWise();
  }, [assesmentData]);

  useEffect(() => {
    setCanShowOverview(false);
  }, [assessmentTypeFilter]);

  const handleTabLevelData_RoleWise = () => {
    tabLevelData_Assessor();
    setAssessmentTabValue(tabValueSelected);
  };

  const tabLevelData_Assessor = () => {
    const assDraftData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.DRAFT
    );
    setEditorDraftData(assDraftData);
    setlableEditorDraft(`(${assDraftData.length})`);

    const assReAssgined = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED
    );
    setReAssignedData(assReAssgined);
    setlableReAssigened(`(${assReAssgined.length})`);

    const assSendForValidation = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.SEND_FOR_VALIDATION
    );
    setsendForValidationData(assSendForValidation);
    setlableSendForValidation(`(${assSendForValidation.length})`);

    const assInProgressValidation = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.IN_PROGRESS_VALIDATION
    );
    setInProgressValidationData(assInProgressValidation);
    setLableInProgressValidation(`(${assInProgressValidation.length})`);

    const assValidated = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.VALIDATED
    );
    setValidatedData(assValidated);
    setlableValidated(`(${assValidated.length})`);
  };


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (assessmentTabValue === newValue) {
      setCanShowOverview(canShowOverview);
    } else {
      setCanShowOverview(false);
    }
    setAssessmentTabValue(newValue);
    setTabValueSelected(newValue);
    setCanShowOverview(false);
  };

  const tabs_Assessor = () => {
    return (
      <>
        <ScmmAppBar position="static">
          <Tabs value={assessmentTabValue} onChange={handleChange} aria-label="Tabs">
            <Tab
              label={`${t("assessment.assessorsDraft")} ${lableEditorDraft}`}
              {...a11yProps(0)}
            />
            <Tab
              label={`${t("assessment.reAssigned")} ${lableReAssigened}`}
              {...a11yProps(1)}
            />
            <Tab
              label={`${t(
                "assessment.pendingValidation"
              )} ${lableSendForValidation}`}
              {...a11yProps(2)}
            />
            <Tab
              label={`${t("assessment.inProgressValidation")} ${lableInProgressValidation}`}
              {...a11yProps(3)}
            />
            <Tab
              label={`${t("assessment.validated")} ${lableValidated}`}
              {...a11yProps(4)}
            />
          </Tabs>
        </ScmmAppBar>
        <TabPanel value={assessmentTabValue} index={0}>
          <GridTableWrapper>
            <RsaAssesementTableComponent
              assessmentFilterData={editorDraftData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              setIsEditAssessment={setIsEditAssessment}
              isPendingValidation={false}
              setIsPendingValidation={setIsPendingValidation}
              setCanShowOverview={setCanShowOverview}
              setShowDetails={setShowDetails}
            ></RsaAssesementTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={assessmentTabValue} index={1}>
          <GridTableWrapper>
            <RsaAssesementTableComponent
              assessmentFilterData={reAssignedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              setIsEditAssessment={setIsEditAssessment}
              setCanShowOverview={setCanShowOverview}
              setShowDetails={setShowDetails}
            ></RsaAssesementTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={assessmentTabValue} index={2}>
          <GridTableWrapper>
            <RsaAssesementTableComponent
              assessmentFilterData={sendForValidationData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              setIsEditAssessment={setIsEditAssessment}

              setCanShowOverview={setCanShowOverview}
              setShowDetails={setShowDetails}
            ></RsaAssesementTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={assessmentTabValue} index={3}>
          <GridTableWrapper>
            <RsaAssesementTableComponent
              assessmentFilterData={inProgressValidationData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              setIsEditAssessment={setIsEditAssessment}

              setCanShowOverview={setCanShowOverview}
              setShowDetails={setShowDetails}
            ></RsaAssesementTableComponent>
          </GridTableWrapper>
        </TabPanel>
        <TabPanel value={assessmentTabValue} index={4}>
          <GridTableWrapper>
            <RsaAssesementTableComponent
              assessmentFilterData={validatedData}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              functionAssesmentLevelData={functionAssesmentLevelData}
              formTitle={formTitle}
              setIsEditAssessment={setIsEditAssessment}

              setCanShowOverview={setCanShowOverview}
              setShowDetails={setShowDetails}
            ></RsaAssesementTableComponent>
          </GridTableWrapper>
        </TabPanel>
      </>
    );
  };


  return (
    <>
      {tabs_Assessor()}
    </>
  );
}
