import React from "react";
import MaterialTable from "material-table";
import { FullWidthContainer } from "../../../styles/common";
import { useTranslation } from "react-i18next";

function MaterialTableGrid(props: any) {
  const { t } = useTranslation();
  const { gridData, showFilter } = props;
  return (
    <FullWidthContainer>
      <MaterialTable
        columns={[
          {
            field: "userId",
            title: "User Id",
          },
          {
            field: "title",
            title: "Title",
          },
          {
            field: "body",
            title: "Description",
          },
        ]}
        data={gridData}
        title="All Request Data"
        style={{
          minWidth: "650px",
          overflow: "auto",
        }}
        options={{
          filtering: showFilter,
          pageSizeOptions: [10, 20, 50],
          pageSize: 10,
          emptyRowsWhenPaging: false,
        }}
        localization={{
          body: {
            filterRow: {
              filterTooltip: t("pagination.filter"),
            },
            emptyDataSourceMessage: t("common.noDataAvailable")
          },
          pagination: {
            firstTooltip: t("pagination.firstPage"),
            previousTooltip: t("pagination.prevPage"),
            nextTooltip: t("pagination.nextPage"),
            lastTooltip: t("pagination.lastPage"),
            labelRowsSelect: t("common.rows"),
          }
        }}
      />
    </FullWidthContainer>
  );
}

export default MaterialTableGrid;
