import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../store/config";

import { useTranslation } from "react-i18next";
import {
  TotalBlock,
  DraftBlock,
  SentVBlock,
  ValidatedBlock,
  ReAssignedBlock,
  OverviewBlock,
  AvgMMIndexBlock,
  ProgramBlock,
} from "styles/common";
import { Typography } from "@material-ui/core";
import {
  calculateMaxWeightedScore,
  calculateMaturityModelIndex,
} from "utils/data";
import { actionSaveClickedStatus } from "containers/assessment-create/actions";
import { ASSESMENT } from "constants/api";
import "../index.css";
import { ASSESSMENT, STATUS_TABS, USER } from "constants/data";
import AssessmentOverviewScoreComponent from "../assessment-overview-score";

export default function AssessmentOverviewTabComponent(props: any) {
  const {
    assesmentData,
    selectedRole,
    labelProgramCount,
    programList,
    selectedCountryIdList,
    getProgramData,
  } = props;
  const { t } = useTranslation();

  const assessmentScoringData = useSelector(
    (state: any) => state.assesmentCreate.assessmentScoringData
  );
  const maturityIndexData = useSelector(
    (state: any) => state.masterForm.mastermaturityIndexData
  );

  const [labelTotalAssessment, setlabelTotalAssessment]: any = useState(0);
  const [labelEditorDraft, setlabelEditorDraft]: any = useState(0);
  const [labelReAssigened, setlabelReAssigened]: any = useState(0);
  const [labelSendForValidation, setlabelSendForValidation]: any = useState(0);
  const [labelValidated, setlabelValidated]: any = useState(0);
  const [labelAvgMMIndex, setLabelAvgMMIndex]: any = useState(0);
  const [
    labelValidatorInProgressDraft,
    setlabelValidatorInProgressDraft,
  ]: any = useState(0);
  const [maturityIndexTable, setMaturityIndexTable] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    handleTabLevelData();
  }, [assesmentData]);

  useEffect(() => {
    if (programList.length === 0) {
      setLabelAvgMMIndex(0);
      setMaturityIndexTable([]);
    }
  }, [programList]);

  useEffect(() => {
    if (maturityIndexTable.length) {
      let avgMaturitIndexValue: any = 0;
      maturityIndexTable.map((item: any) => {
        avgMaturitIndexValue += Number(item);
      });

      avgMaturitIndexValue = avgMaturitIndexValue / maturityIndexTable.length;

      if (avgMaturitIndexValue >= 100) {
        setLabelAvgMMIndex(avgMaturitIndexValue.toFixed(0));
      } else {
        setLabelAvgMMIndex(avgMaturitIndexValue.toFixed(1));
      }
    } else {
      setLabelAvgMMIndex(0);
    }
  }, [maturityIndexTable]);

  useEffect(() => {
    if (
      assessmentScoringData &&
      assessmentScoringData.length
    ) {
      let bindMaturityIndexCalcTable: any = [];
      assessmentScoringData.map((assessmentData: any) => {

        let bindMaturityTableData: any = [];
        let tempTotalMaxWightedScore = 0;
        assessmentData.assessmentScoring.map((item: any, index: any) => {
          maturityIndexData.filter((maturityRow: any) => {
            if (maturityRow.areaId === item.areaOfWorkId) {
              maturityRow.mmScore = item.score;
              maturityRow.maxWeightScore = calculateMaxWeightedScore(
                maturityRow.maxScore,
                maturityRow.weight
              );
              tempTotalMaxWightedScore += maturityRow.maxWeightScore;
              bindMaturityTableData.push(maturityRow);
            }
          });
        });
        if (bindMaturityTableData.length) {
          let maturitIndexValue: any = 0;
          bindMaturityTableData.map((item: any) => {
            maturitIndexValue += calculateMaturityModelIndex(
              item.mmScore,
              item.weight,
              tempTotalMaxWightedScore
            );
          });
          bindMaturityIndexCalcTable.push(Number(maturitIndexValue.toFixed(1)));
        }
      });
      setMaturityIndexTable(bindMaturityIndexCalcTable);
    } else {
      setLabelAvgMMIndex(0);
      setMaturityIndexTable([]);
    }
  }, [assessmentScoringData]);

  const handleTabLevelData = () => {
    const assDraftData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.DRAFT
    );
    setlabelEditorDraft(`${assDraftData.length}`);

    const assReAssgined = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.RE_ASSIGNED
    );
    setlabelReAssigened(`${assReAssgined.length}`);

    const assSendForValidation = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.SEND_FOR_VALIDATION
    );

    const assValidated = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId === ASSESSMENT.STATUS.VALIDATED
    );

    setlabelValidated(`${assValidated.length}`);

    const valDraftInProgressData = assesmentData.filter(
      (assesement: any) =>
        assesement.assessmentStatusId ===
        ASSESSMENT.STATUS.IN_PROGRESS_VALIDATION
    );
    setlabelValidatorInProgressDraft(`${valDraftInProgressData.length}`);

    if (
      selectedRole.toLowerCase().trim() ===
      USER.ROLE.ASSESSOR.toLowerCase().trim()
    ) {
      const sendInProgressDataLength =
        assSendForValidation.length + valDraftInProgressData.length;
      setlabelSendForValidation(`${sendInProgressDataLength}`);
      setlabelTotalAssessment(`${assesmentData.length}`);
    } else {
      setlabelSendForValidation(`${assSendForValidation.length}`);
      const totalAssessment = assesmentData.length - assDraftData.length;
      setlabelTotalAssessment(`${totalAssessment}`);
    }
  };

  const handelClickOnStatus = (type: any) => {
    dispatch(actionSaveClickedStatus(type));
    history.push(ASSESMENT);
  };

  const tabs_Assessor = () => {
    return (
      <>
        <OverviewBlock>
          <TotalBlock className="arMarginR0 arMarginL10">
            <Typography component="span">{t("common.total")}</Typography>
            <Typography component="strong" className="strong-text">{labelTotalAssessment}</Typography>
          </TotalBlock>
          <ProgramBlock className="arMarginR0 arMarginL10">
            <Typography component="span">
              {t("newAssessmentOverview.noOfPrograms")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelProgramCount}</Typography>
          </ProgramBlock>
          <AvgMMIndexBlock className="arMarginR0 arMarginL10">
            <Typography component="span">
              {t("assessment.averageMaturityIndex")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelAvgMMIndex}</Typography>
          </AvgMMIndexBlock>

          <DraftBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.ASSESSOR.DRAFT);
            }}
          >
            <Typography component="span">
              {t("assessment.assessorsDraft")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelEditorDraft}</Typography>
          </DraftBlock>
          <ReAssignedBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.ASSESSOR.RE_ASSIGEND);
            }}
          >
            <Typography component="span">
              {t("assessment.reAssigned")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelReAssigened}</Typography>
          </ReAssignedBlock>
          <SentVBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.ASSESSOR.SENT_FOR_VALIDATION);
            }}
          >
            <Typography component="span">
              {t("assessment.sentForValidation")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelSendForValidation}</Typography>
          </SentVBlock>
          <ValidatedBlock
            className="hand-pointer arMarginR0"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.ASSESSOR.VALIDATED);
            }}
          >
            <Typography component="span">
              {t("assessment.validated")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelValidated}</Typography>
          </ValidatedBlock>
        </OverviewBlock>
      </>
    );
  };

  const tabs_Validator = () => {
    return (
      <>
        <OverviewBlock>
          <TotalBlock className="arMarginR0 arMarginL10">
            <Typography component="span">{t("common.total")}</Typography>
            <Typography component="strong" className="strong-text">{labelTotalAssessment}</Typography>
          </TotalBlock>
          <ProgramBlock className="arMarginR0 arMarginL10">
            <Typography component="span">
              {t("newAssessmentOverview.noOfPrograms")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelProgramCount}</Typography>
          </ProgramBlock>
          <AvgMMIndexBlock className="arMarginR0 arMarginL10">
            <Typography component="span">
              {t("assessment.averageMaturityIndex")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelAvgMMIndex}</Typography>
          </AvgMMIndexBlock>
          <SentVBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.VALIDATOR.PENDING);
            }}
          >
            <Typography component="span">
              {t("assessment.pendingValidation")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelSendForValidation}</Typography>
          </SentVBlock>
          <DraftBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.VALIDATOR.IN_PROGRESS);
            }}
          >
            <Typography component="span">
              {t("assessment.inProgressValidation")}
            </Typography>
            <Typography component="strong" className="strong-text">
              {labelValidatorInProgressDraft}
            </Typography>
          </DraftBlock>
          <ReAssignedBlock
            className="hand-pointer arMarginR0 arMarginL10"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.VALIDATOR.RE_ASSIGEND);
            }}
          >
            <Typography component="span">{t("assessment.reAssign")}</Typography>
            <Typography component="strong" className="strong-text">{labelReAssigened}</Typography>
          </ReAssignedBlock>
          <ValidatedBlock
            className="hand-pointer arMarginR0"
            onClick={() => {
              handelClickOnStatus(STATUS_TABS.VALIDATOR.VALIDATED);
            }}
          >
            <Typography component="span">
              {t("assessment.validated")}
            </Typography>
            <Typography component="strong" className="strong-text">{labelValidated}</Typography>
          </ValidatedBlock>
        </OverviewBlock>
      </>
    );
  };
  return (
    <>
      {selectedRole.toLowerCase().trim() ===
        USER.ROLE.ASSESSOR.toLowerCase().trim()
        ? tabs_Assessor()
        : tabs_Validator()}

      <AssessmentOverviewScoreComponent
        programList={programList}
        selectedCountryIdList={selectedCountryIdList}
        getProgramData={getProgramData}
        isDefaultCallProgramScoreData={false}
      />
    </>
  );
}
