import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../store/config";
import { LOGIN_PATH, ERROR_PATH } from "../../constants/api";
import { actionFetchAuthentication, actionSetAuthenticationSuccess } from "../../containers/user-profile/actions";

export const Authentication = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [
        isAuthenticationRequestSent,
        setIsAuthenticationRequestSent,
    ] = useState(false);

    let isUserAuthenticated = useSelector(
        (state: any) => state.userProfile.isAuthenticated
    );

    useEffect(() => {
        if (!isUserAuthenticated && !isAuthenticationRequestSent) {
            dispatch(actionFetchAuthentication());
            setIsAuthenticationRequestSent(true);
        }
    }, [isUserAuthenticated]);

    useEffect(() => {
        if (
            isUserAuthenticated &&
            isUserAuthenticated !== isAuthenticated &&
            isAuthenticationRequestSent
        ) {
            setIsAuthenticated(true);
        }
        else if (isUserAuthenticated && !isAuthenticationRequestSent) {
            setIsAuthenticationRequestSent(true);
            setIsAuthenticated(true);
        }
        else {
            if (isUserAuthenticated !== null && !isUserAuthenticated) {
                dispatch(actionSetAuthenticationSuccess(null));
                if (location.pathname === ERROR_PATH) {
                    if (!isUserAuthenticated && isAuthenticationRequestSent) {
                        history.push(ERROR_PATH);
                    } else {
                        history.replace(LOGIN_PATH);
                        window.location.reload();
                    }
                }
            }
            setIsAuthenticated(false);
        }
    }, [isUserAuthenticated]);
    return isAuthenticated;
};
