import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FormWrapper,
  FullWidthContainer,
  FormToolbar,
  PageWrapper,
} from "../../styles/common";
import Button from "@material-ui/core/Button";

import FormInput from "../../components/form-controls/input";
import FormSelect from "../../components/form-controls/select";
import FormSelectAutoComplete from "../../components/form-controls/select-autocomplete";
import FormDatePicker from "../../components/form-controls/datepicker";
import FormCheckbox from "../../components/form-controls/checkbox";
import FormRadio from "../../components/form-controls/radio";

import { actionSetPageTitle } from "../../store/common/actions";
import * as yup from "yup";

const ValidationSchema = yup.object().shape({
  name1V: yup.string().trim().required("Name 1 Validation Field is Required"),
  name2V: yup
    .number()
    .typeError("Name 2 validation field must be a number")
    .positive("Name 2 validation field must be greater than zero")
    .required("Name 2 validation field is required"),
  name3V: yup.string().required("Name 3V Validation is Required"),
  name5V: yup
    .date()
    .typeError("Name 5 validation field must be a date")
    .required("Name 5 validation field is required"),
  name4V: yup.array().nullable().required("Name 4V required"),
});

function NewRequestComponent(props: any) {
  const dispatch = useDispatch();
  const methods = useForm({
    validationSchema: ValidationSchema,
  });

  useEffect(() => {
    const title = "New Request";
    dispatch(actionSetPageTitle(title));
  }, [dispatch]);

  const { handleSubmit, errors } = methods;
  const onSubmit = (data: any) => {
    console.log(data);
  };
  const selectData = [
    {
      id: "10",
      label: "Ten",
    },
    {
      id: "20",
      label: "Twenty",
    },
    {
      id: "30",
      label: "Thirty",
    },
  ];

  const radioGroupOptions = [
    {
      value: "female",
      label: "Female",
    },
    {
      value: "male",
      label: "Male",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const handleSelectChange = ([event]: any) => {
    console.log(event.target.value);
    return event.target.value;
  };

  return (
    <PageWrapper>
      <FormToolbar>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          SUBMIT
        </Button>
      </FormToolbar>

      <FullWidthContainer>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Form Panel</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FormWrapper>
              <FormContext {...methods}>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormInput name="name1" label="Name 1" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        name="name1V"
                        label="Name 1 Validation"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput type="number" name="name2" label="Name 2" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        type="number"
                        name="name2V"
                        label="Name 2 Validation"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelect
                        name="name3"
                        label="Name 3"
                        options={selectData}
                        onChange={handleSelectChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelect
                        name="name3V"
                        label="Name 3 Validation"
                        options={selectData}
                        onChange={handleSelectChange}
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectAutoComplete
                        name="name4"
                        label="Name 4"
                        options={selectData}
                        isMulti
                        // onChange={handleSelectChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormSelectAutoComplete
                        name="name4V"
                        label="Name 4 Validation"
                        options={selectData}
                        isMulti
                        required={true}
                        errorobj={errors}
                        // onChange={handleSelectChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormDatePicker name="name5" label="Name 5" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormDatePicker
                        name="name5V"
                        label="Name 5 Validation"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckbox name="name6" label="Name 6" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormRadio
                        name="name7"
                        label="Name 7"
                        options={radioGroupOptions}
                      />
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </FormWrapper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </FullWidthContainer>
    </PageWrapper>
  );
}

export default NewRequestComponent;
