import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import {
  fetchMasterMenuDynamicFormFieldDetails,
  fetchMasterMenuAllDynamicFormFieldAndDataDetails,
  saveMasterDynamicFormFieldDataDetails,
  deleteMasterDynamicFormRecordDataDetails,
  fetchDynamicFormFieldDataDetails,
  fetchMasterMaturityIndexData,
  saveMasterMaturityIndexData,
  deleteMasterMaturityIndexData,
  updateMasterMaturityIndexData,
  fetchMasterAreaOfWorkToolData,
  saveMasterAreaOfWorkToolData,
  updateMasterAreaOfWorkToolData,
  deleteMasterAreaOfWorkToolData,
  fetchMasterAreaOfWorkToolSingleData,
  getMasterAowTopic,
  fetchMasterAreaOfWorkToolsData,
  getMasterAowOrTopicDetails,
  saveMasterAreaOfWorkOrTopicData,
  getMasterCountryHierarchy,
  postMasterCountryHierarchy,
  updateMasterCountryHierarchy,
  deleteMasterCountryHierarchy,
  deleteMasterTopicAowHierarchy,
  exportReportTableDetailData,
  getUserAndCountryList,
  getVCStatictsData
} from "./api";
import {
  actionFetchMasterMenuFormFieldDetailSuccess,
  actionFetchMasterMenuAllDynamicFieldAndDataDetailSuccess,
  actionSaveMasterDynamicFormFieldDataDetailSuccess,
  actionFetchMasterMenuAllDynamicFieldAndDataDetail,
  actionDeleteMasterDynamicFormFieldDataDetailSuccess,
  actionFetchDynamicFormDataDetailSuccess,
  actionFetchMasterMaturityIndexDataSuccess,
  actionSaveMasterMaturityIndexDetailSuccess,
  actionDeleteMasterMaturityIndexDetailSuccess,
  actionIsMaturityIndexCall,
  actionDeleteMasterMaturityIndexDetail,
  actionFetchMasterAreaOfWorkToolDataSuccess,
  actionSaveMasterAreaOfWorkToolDetailSuccess,
  actionDeleteMasterAreaOfWorkToolDetailSuccess,
  actionFetchMasterAreaOfWorkToolSingleDataSuccess,
  actionFetchAOWtopicHierarchySuccess,
  actionFetchAOWtopicDetailsSuccess,
  actionFetchMasterAreaOfWorkToolsDataSuccess,
  actionFetchMasterAreaOfWorkToolsData,
  actionSaveAOWOrtopicDetailsSuccess,
  actionFetchAOWtopicHierarchy,
  actionFetchDynamicFormDataDetail,
  actionFetchMasterCountryHierarchyDataSuccess,
  actionFetchMasterCountryHierarchyData,
  actionUpdateMasterAreaOfWorkToolDataSuccess,
  actionExportReportTableDataSuccess,
  actionUserAndCountryListSuccess,
  actionGetVCReportStatsSuccess
} from "./actions";
import { ActionTypes } from "./constants";
import {
  setLoaderVisibility,
  openToaster,
  closeToaster,
} from "../../store/common/actions";
import {
  API_ERROR_MESSAGE,
  DATA_SAVE_SUCCESS,
  DATA_DELETE_SUCCESS,
  DATA_UPDATE_SUCCESS,
  API_ERROR_DUPLICATE,
  ENTITY_ASSOCIATED_MESSAGE,
} from "../../store/common/message";
import { COMMON_CONSTANT, DYNAMIC_MASTER, IS_DUPLICATE, IS_ENTITY_ASSOCIATED } from "constants/data";
import { getLocatDateInDateTimeFormat } from "utils/data";

function* watchMasterMenuDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_DYNAMIC_FORM_FIELD_FETCH_DATA,
    getMasterMenuDetails
  );
}

function* getMasterMenuDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchMasterMenuDynamicFormFieldDetails,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchMasterMenuFormFieldDetailSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchMasterMenuAllFormFieldAndDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_ALL_DYNAMIC_FORM_FIELD_AND_FETCH_DATA,
    getMasterMenuAllFormFieldAndDataDetails
  );
}

function* getMasterMenuAllFormFieldAndDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchMasterMenuAllDynamicFormFieldAndDataDetails,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(
      actionFetchMasterMenuAllDynamicFieldAndDataDetailSuccess(responseData)
    );
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
  }
}

function* watchDynamicFormDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_GET_DYNAMIC_FORM_FIELD_DATA,
    getDynamicFormDataDetails
  );
}

function* getDynamicFormDataDetails(action: any) {
  try {
    const response = yield call(
      fetchDynamicFormFieldDataDetails,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchDynamicFormDataDetailSuccess(responseData));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}
function* watchSaveMasterDynamicFormFieldDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_SAVE_DYNAMIC_FORM_FIELD_DATA,
    saveMasterDynamiceFormFieldDataDetail
  );
}

function* saveMasterDynamiceFormFieldDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveMasterDynamicFormFieldDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionSaveMasterDynamicFormFieldDataDetailSuccess(responseData));
    const responseList = yield call(
      fetchMasterMenuAllDynamicFormFieldAndDataDetails,
      action.payload.formData.MenuName
    );
    const responseListData = responseList.data;
    yield put(
      actionFetchMasterMenuAllDynamicFieldAndDataDetailSuccess(responseListData)
    );

    yield put(setLoaderVisibility(false));
    if (
      action.payload.formData.formId !== undefined &&
      action.payload.formData.formId > 0
    ) {
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    } else {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      if (
        action.payload.formData.MenuName.toLowerCase().trim() ===
        DYNAMIC_MASTER.AREA_OF_WORK
      ) {
        yield put(actionIsMaturityIndexCall(true));
      }
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchDeleteMasterDynamicFormRecordDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_DELETE_DYNAMIC_FORM_FIELD_DATA,
    deleteMasterDynamiceFormRecordDataDetail
  );
}

function* deleteMasterDynamiceFormRecordDataDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    let maturityIndexResponse = null;
    if (
      action.payload.formData.MenuName.toLowerCase().trim() ===
      DYNAMIC_MASTER.AREA_OF_WORK
    ) {
      maturityIndexResponse = yield call(fetchMasterMaturityIndexData, "-1");
    }
    const response = yield call(
      deleteMasterDynamicFormRecordDataDetails,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(
      actionDeleteMasterDynamicFormFieldDataDetailSuccess(responseData)
    );
    yield put(
      actionFetchMasterMenuAllDynamicFieldAndDataDetail(
        action.payload.formData.MenuName
      )
    );
    if (
      action.payload.formData.MenuName.toLowerCase().trim() ===
      DYNAMIC_MASTER.AREA_OF_WORK
    ) {
      const maturityRowData = maturityIndexResponse.data.filter((item: any) => {
        if (item.areaId === action.payload.formData.formId) {
          return item;
        }
      });
      if (maturityRowData && maturityRowData.length) {
        const formDataMaturityIndex = {
          id: maturityRowData[0].id,
          isDeleted: true,
          updatedBy: action.payload.formData.updatedBy,
        };
        yield put(actionDeleteMasterMaturityIndexDetail(formDataMaturityIndex));
      }
    }
    yield put(setLoaderVisibility(false));
    yield put(openToaster(DATA_DELETE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetMasterMaturityIndexDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MATURITY_INDEX_DATA,
    getMasterMaturityIndexDetails
  );
}

function* getMasterMaturityIndexDetails(action: any) {
  try {
    const response = yield call(
      fetchMasterMaturityIndexData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchMasterMaturityIndexDataSuccess(responseData));
    if (!responseData.length)
      yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchSaveMasterMaurityIndexDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MATURITY_INDEX_SAVE,
    saveMasterMaurityIndexDataDetails
  );
}

function* saveMasterMaurityIndexDataDetails(action: any) {
  try {
    const response = yield call(
      saveMasterMaturityIndexData,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionSaveMasterMaturityIndexDetailSuccess(responseData));
    yield put(actionIsMaturityIndexCall(false));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchDeleteMasterMaurityIndexDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MATURITY_INDEX_DELETE,
    deleteMasterMaurityIndexDataDetails
  );
}

function* deleteMasterMaurityIndexDataDetails(action: any) {
  try {
    const response = yield call(
      deleteMasterMaturityIndexData,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(actionDeleteMasterMaturityIndexDetailSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateMasterMaturityIndexDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_MATURITY_INDEX_DATA_UPDATE,
    updateMasterMaturityIndexDataDetails
  );
}

function* updateMasterMaturityIndexDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    yield call(updateMasterMaturityIndexData, action.payload.formData);
    yield put(setLoaderVisibility(false));
    if (
      action.payload.formData.formId !== undefined &&
      action.payload.formData.formId > 0
    ) {
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
    } else {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}
function* watchGetMasterAreaOfWorkToolDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA,
    getMasterAreaOfWorkToolDetails
  );
}

function* getMasterAreaOfWorkToolDetails(action: any) {
  try {
    const response = yield call(
      fetchMasterAreaOfWorkToolData,
      action.payload.lookUp,
      action.payload.roleId
    );
    const responseData = response.data;
    yield put(actionFetchMasterAreaOfWorkToolDataSuccess(responseData));
    if (!responseData.length)
      yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchGetMasterAreaOfWorkToolSingleDetail() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOL_SINGLE_DATA,
    getMasterAreaOfWorkTooSingleDetail
  );
}

function* getMasterAreaOfWorkTooSingleDetail(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      fetchMasterAreaOfWorkToolSingleData,
      action.payload.lookUp
    );
    const responseData = response.data;
    yield put(actionFetchMasterAreaOfWorkToolSingleDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchSaveAreaOfWorkToolDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOL_SAVE,
    saveAreaOfWorkToolDataDetails
  );
}

function* saveAreaOfWorkToolDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveMasterAreaOfWorkToolData,
      action.payload.formData
    );
    yield put(actionSaveMasterAreaOfWorkToolDetailSuccess(true));
    if (response) {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      yield put(actionFetchMasterAreaOfWorkToolsData());
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    if (err.response.data.detail === IS_DUPLICATE) {
      yield put(openToaster(API_ERROR_DUPLICATE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchDeleteAreaOfWorkToolDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOL_DELETE,
    deleteAreaOfWorkToolDataDetails
  );
}

function* deleteAreaOfWorkToolDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      deleteMasterAreaOfWorkToolData,
      action.payload.formData
    );
    const responseData = response.data;
    yield put(openToaster(DATA_DELETE_SUCCESS(), true));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    yield put(actionDeleteMasterAreaOfWorkToolDetailSuccess(responseData));
    yield put(actionFetchMasterAreaOfWorkToolsData());
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchUpdateMasterAreaOfWorkToolDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOL_DATA_UPDATE,
    updateMasterAreaOfWorkToolDataDetails
  );
}

function* updateMasterAreaOfWorkToolDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    yield call(updateMasterAreaOfWorkToolData, action.payload.formData);
    if (
      action.payload.formData[0].toolLanguageId != ""
    ) {
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
      yield put(actionUpdateMasterAreaOfWorkToolDataSuccess(true));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    yield put(actionFetchMasterAreaOfWorkToolsData());
    yield put(setLoaderVisibility(false));
  } catch (err) {
    if (err.response.data.detail === IS_DUPLICATE) {
      yield put(openToaster(API_ERROR_DUPLICATE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchGetMasterAreaOfWorkTopicHierarchy() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_MANAGEMENT_HIERARCHY,
    getMasterAreaOfWorkTopicHierarchy
  );
}

function* getMasterAreaOfWorkTopicHierarchy(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getMasterAowTopic);
    const responseData = response.data;
    yield put(actionFetchAOWtopicHierarchySuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchGetMasterAreaOfWorkTopicDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AOW_TOPIC_DETAILS,
    getMasterAreaOfWorkTopicDetails
  );
}

function* getMasterAreaOfWorkTopicDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getMasterAowOrTopicDetails,
      action.payload.type,
      action.payload.formId,
      action.payload.topicId);
    const responseData = response.data;
    yield put(actionFetchAOWtopicDetailsSuccess(responseData));
    yield put(closeToaster());
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}
function* watchGetMasterAreaOfWorkToolsDetails() {
  yield takeLatest(
    ActionTypes.MASTER_AREA_OF_WORK_TOOLS_DATA,
    getMasterAreaOfWorkToolsDetails
  );
}

function* getMasterAreaOfWorkToolsDetails(action: any) {
  try {
    const response = yield call(fetchMasterAreaOfWorkToolsData);
    const responseData = response.data;
    yield put(actionFetchMasterAreaOfWorkToolsDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchSaveAowORTopicDataDetails() {
  yield takeLatest(
    ActionTypes.MASTER_SAVE_AOW_OR_TOPIC,
    saveAowORTopicDataDetails
  );
}

function* saveAowORTopicDataDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      saveMasterAreaOfWorkOrTopicData,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData) {
      yield put(actionSaveAOWOrtopicDetailsSuccess(responseData.isSuccess));
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      yield put(actionFetchDynamicFormDataDetail(-1));
      yield put(actionFetchAOWtopicHierarchy());
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    if (err.response.data.detail === IS_DUPLICATE) {
      yield put(openToaster(API_ERROR_DUPLICATE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield put(setLoaderVisibility(false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchGetMasterCountryHierarchyDetails() {
  yield takeLatest(
    ActionTypes.MASTER_GET_COUNTRY_HIERARCHY,
    getMasterCountryHierarchyDetails
  );
}

function* getMasterCountryHierarchyDetails(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getMasterCountryHierarchy, action.payload.data);
    const responseData = response.data;
    yield put(actionFetchMasterCountryHierarchyDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchSaveCountryHierarchyData() {
  yield takeLatest(
    ActionTypes.MASTER_SAVE_COUNTRY_HIERARCHY,
    saveCountryHierarchyData
  );
}

function* saveCountryHierarchyData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      postMasterCountryHierarchy,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData) {
      yield put(openToaster(DATA_SAVE_SUCCESS(), true));
      yield put(actionFetchMasterCountryHierarchyData(action.payload.data.countryId));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    if (err.response.data.detail === IS_DUPLICATE) {
      yield put(openToaster(API_ERROR_DUPLICATE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchUpdateCountryHierarchyData() {
  yield takeLatest(
    ActionTypes.MASTER_UPDATE_COUNTRY_HIERARCHY,
    updateCountryHierarchyData
  );
}

function* updateCountryHierarchyData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      updateMasterCountryHierarchy,
      action.payload.data
    );
    const responseData = response.data;
    if (responseData) {
      yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
      yield put(actionFetchMasterCountryHierarchyData(action.payload.data.countryId));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    if (err.response.data.detail === IS_DUPLICATE) {
      yield put(openToaster(API_ERROR_DUPLICATE(), false));
    } else {
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    console.log(err);
    yield put(closeToaster());
  }
}

function* watchDeleteCountryHierarchyData() {
  yield takeLatest(
    ActionTypes.MASTER_DELETE_COUNTRY_HIERARCHY,
    deleteCountryHierarchyData
  );
}

function* deleteCountryHierarchyData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      deleteMasterCountryHierarchy,
      action.payload.data
    );
    if (response) {
      yield put(openToaster(DATA_DELETE_SUCCESS(), true));
      yield put(actionFetchMasterCountryHierarchyData(action.payload.data.countryId));
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    console.log(err);
    yield put(closeToaster());
  }
}

function* watchDeleteTopicAowHierarchyData() {
  yield takeLatest(
    ActionTypes.MASTER_DELETE_TOPIC_AOW_HIERARCHY,
    deleteTopicAowHierarchyData
  );
}

function* deleteTopicAowHierarchyData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(
      deleteMasterTopicAowHierarchy,
      action.payload.data
    );
    if (response) {
      yield put(openToaster(DATA_DELETE_SUCCESS(), true));
      yield put(actionFetchAOWtopicHierarchy());
      yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
      yield put(closeToaster());
    }
    yield put(setLoaderVisibility(false));
  } catch (err) {
    if (err.response.data.detail === IS_ENTITY_ASSOCIATED) {
      yield put(setLoaderVisibility(false));
      yield put(openToaster(ENTITY_ASSOCIATED_MESSAGE(), false));
    } else {
      yield put(setLoaderVisibility(false));
      yield put(openToaster(API_ERROR_MESSAGE(), false));
    }
    yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    yield put(closeToaster());
    console.log(err);
  }
}

function* watchGetExportReportTableData() {
  yield takeLatest(
    ActionTypes.MASTER_MENU_EXPORT_REPORT_TABLE_DATA,
    getExportReportTableData
  );
}

function* getExportReportTableData(action: any) {
  try {
    yield put(setLoaderVisibility(true));

    const response = yield call(
      exportReportTableDetailData,
      action.payload.formData
    );
    const responseData = response.data;
    const fileName = `reportDetails_${getLocatDateInDateTimeFormat(
      null
    )}.xlsx`;

    setTimeout(() => {
      const file: any = new Blob([responseData], {
        type: "application/octet-stream",
      });
      const a: any = document.createElement("a");
      a.className = "link-download";
      document.body.appendChild(a);
      const url: any = window.URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      a.click();
      document.body.removeChild(a);
    }, 0);
    yield put(actionExportReportTableDataSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    yield put(setLoaderVisibility(false));
    yield put(openToaster(API_ERROR_MESSAGE(), false));
    console.log(err);
  }
}

function* watchGetMasterCountryUserList() {
  yield takeLatest(
    ActionTypes.MASTER_USER_COUNTRY_LIST,
    getMasterCountryUserList
  );
}

function* getMasterCountryUserList(action: any):any {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getUserAndCountryList);
    const responseData = response.data;
    const users = responseData.users.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)); 
    const countries = responseData.countries.sort((a: any, b: any) => (a > b ? 1 : -1));    
    yield put(actionUserAndCountryListSuccess({countries, users}));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

function* watchGetMasterVCReportStatsData() {
  yield takeLatest(
    ActionTypes.MASTER_VC_REPORT_STATS,
    getMasterVCReportStatsData
  );
}

function* getMasterVCReportStatsData(action: any) {
  try {
    yield put(setLoaderVisibility(true));
    const response = yield call(getVCStatictsData, action.payload.formData);
    const responseData = response.data;
    yield put(actionGetVCReportStatsSuccess(responseData));
    yield put(setLoaderVisibility(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderVisibility(false));
  }
}

export default function* masterFormSaga() {
  yield all([
    watchMasterMenuDetails(),
    watchMasterMenuAllFormFieldAndDataDetails(),
    watchDynamicFormDataDetails(),
    watchSaveMasterDynamicFormFieldDataDetails(),
    watchDeleteMasterDynamicFormRecordDataDetails(),
    watchGetMasterMaturityIndexDetails(),
    watchSaveMasterMaurityIndexDataDetails(),
    watchDeleteMasterMaurityIndexDataDetails(),
    watchUpdateMasterMaturityIndexDataDetails(),
    watchGetMasterAreaOfWorkToolDetails(),
    watchSaveAreaOfWorkToolDataDetails(),
    watchDeleteAreaOfWorkToolDataDetails(),
    watchUpdateMasterAreaOfWorkToolDataDetails(),
    watchGetMasterAreaOfWorkToolSingleDetail(),
    watchGetMasterAreaOfWorkTopicHierarchy(),
    watchGetMasterAreaOfWorkTopicDetails(),
    watchGetMasterAreaOfWorkToolsDetails(),
    watchSaveAowORTopicDataDetails(),
    watchGetMasterCountryHierarchyDetails(),
    watchSaveCountryHierarchyData(),
    watchUpdateCountryHierarchyData(),
    watchDeleteCountryHierarchyData(),
    watchDeleteTopicAowHierarchyData(),
    watchGetExportReportTableData(),
    watchGetMasterCountryUserList(),
    watchGetMasterVCReportStatsData()
  ]);
}
