import React from "react";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { BoxShadowWrapper, GeneralResponsiveImage, HowToJoinContent, } from "styles/common";
import { useTranslation } from "react-i18next";
import registration_EN from '../../styles/images/registration_EN.jpg';
import verification_EN from '../../styles/images/verification_EN.jpg';
import { LANGUAGE } from "constants/data";

function HowToJoin(props: any) {
  const { t } = useTranslation();
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );

  return (
    <>
      <Container maxWidth={"xl"}>
        <Typography component="div" className="paddingT20">
          <BoxShadowWrapper>
            <HowToJoinContent>
              <Typography component="h1" className="page-title">{t("HowToJoin.pageTitle")}</Typography>
              <Typography component="p" className="main-desc">{t("HowToJoin.pageDescriptiion")}</Typography>
              <Grid container justify="center">
                <Grid item md={8} sm={10}>
                  <Typography component="h3" className="step-title">{t("HowToJoin.step1Title")}</Typography>
                  <Typography component="div" dangerouslySetInnerHTML={{ __html: t("HowToJoin.step1Desc") }} />
                  <GeneralResponsiveImage
                    src={
                      languageSelected === LANGUAGE.ARABIC
                        ? registration_EN
                        : languageSelected === LANGUAGE.FRENCH
                          ? registration_EN
                          : languageSelected === LANGUAGE.SPANISH
                            ? registration_EN
                            : languageSelected === LANGUAGE.RUSSIAN
                              ? registration_EN
                              : registration_EN
                    }
                    className="margin-top-15 paddingB20" />
                  <Typography component="h3" className="step-title">{t("HowToJoin.step2Title")}</Typography>
                  <Typography component="div" dangerouslySetInnerHTML={{ __html: t("HowToJoin.step2Desc") }} />
                  <GeneralResponsiveImage
                    src={
                      languageSelected === LANGUAGE.ARABIC
                        ? verification_EN
                        : languageSelected === LANGUAGE.FRENCH
                          ? verification_EN
                          : languageSelected === LANGUAGE.SPANISH
                            ? verification_EN
                            : languageSelected === LANGUAGE.RUSSIAN
                              ? verification_EN
                              : verification_EN
                    }
                    className="margin-top-15 paddingB20" />
                  <Typography component="h3" className="step-title">{t("HowToJoin.step3Title")}</Typography>
                  <Typography component="div" dangerouslySetInnerHTML={{ __html: t("HowToJoin.step3Desc") }} className="paddingB20" />
                  <Typography component="h3" className="step-title">{t("HowToJoin.step4Title")}</Typography>
                  <Typography component="div" dangerouslySetInnerHTML={{ __html: t("HowToJoin.step4Desc") }} />
                </Grid>
              </Grid>
            </HowToJoinContent>
          </BoxShadowWrapper>
        </Typography>
      </Container>
    </>
  );
}

export default HowToJoin;
