import styled from "styled-components";
import MaterialTable from "material-table";
import { UNICEF_BLUE, DARK_GRAY, RED_COLOR } from "constants/color";

export const StyledTable: any = styled(MaterialTable)`
  && {
    color: ${RED_COLOR};
    width: 200px;
    '&:nth-of-type(odd)': {
        backgroundColor: #f0f0f0,
      },
      background-color: ${RED_COLOR};
  }
`;

export const commonTableStyle = {
  minWidth: "650px",
  overflow: "auto",
}

export const commonCellStyle = {
  padding: "2px 15px",
  fontSize: "14px",
};

export const commonHeaderStyle: any = {
  backgroundColor: "#C7EAF7",
  color: `${DARK_GRAY}`,
  whiteSpace: "nowrap",
  fontWeight: 500,
  fontSize: "14px",
  padding: "10px 15px",
};

export const headerStyleLighter = {
  backgroundColor: `${UNICEF_BLUE}`,
}