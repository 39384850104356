import { MuiThemeProvider } from "@material-ui/core/styles";
import { Router } from "react-router-dom";
import * as React from "react";
import { Provider } from "react-redux";
import theme from "./app-theme";
import { configStore, history } from "./store/config";
import withClearCache from "./ClearCache";
import "./styles/index.css";
import SCSHomePage from "scs-hub";
import { HelmetProvider } from "react-helmet-async";

const store = configStore();
const ClearCacheComponent = withClearCache(SCSHomePage);

function App() {
  return (
    <HelmetProvider>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router history={history}>
            <ClearCacheComponent />
          </Router>
        </Provider>
      </MuiThemeProvider>
    </HelmetProvider>
  );
}

export default App;
