import { IconButton } from "@material-ui/core";
import Error from "@material-ui/icons/Error";
import * as React from "react";
import {
  TOASTER_ALIGNMENT_HORIZONTAL,
  TOASTER_ALIGNMENT_TOP
} from "../../constants/data";
import {
  Close,
  StyledToasterError,
  StyledToasterSuccess
} from "../../styles/common";

export const Toaster: any = ({
  message,
  open,
  success,
  close,
}: {
  message: any;
  open: boolean;
  close: any;
  success: boolean;
}) => {
  return success
    ? SuccessToaster(open, message, close)
    : ErrorToaster(open, message, close);
};

const SuccessToaster = (open: any, message: any, close: any) => {
  return (
    <StyledToasterSuccess
      anchorOrigin={{
        horizontal: TOASTER_ALIGNMENT_HORIZONTAL,
        vertical: TOASTER_ALIGNMENT_TOP
      }}
      success={true.toString()}
      open={open}
      message={
        <div className="check-error">
          <span className="err-icon">
            <Error />
          </span>
          <span className="msg">{message}</span>
        </div>
      }
      action={[
        <IconButton
          className="close"
          key="close"
          aria-label="Close"
          color="secondary"
          onClick={close}
        >
          <Close />
        </IconButton>
      ]}
      autoHideDuration={2000}
    />
  );
};

const ErrorToaster = (open: any, message: any, close: any) => {
  return (
    <StyledToasterError
      anchorOrigin={{
        horizontal: TOASTER_ALIGNMENT_HORIZONTAL,
        vertical: TOASTER_ALIGNMENT_TOP
      }}
      open={open}
      success={false.toString()}
      message={
        <div className="check-error">
          <span className="err-icon">
            <Error />
          </span>
          <span className="msg">{message}</span>
        </div>
      }
      action={[
        <IconButton
          className="close"
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={close}
        >
          <Close />
        </IconButton>
      ]}
      autoHideDuration={2000}
    />
  );
};
