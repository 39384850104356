import { Box, Container, Grid, Typography } from "@material-ui/core";
import FormDatePicker from "../../../components/form-controls/datepicker";
import FormSelect from "../../../components/form-controls/select";
import { REPORT_TYPE_FILTER_OPTIONS } from "../../../constants/data";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../../store/common/actions";
import {
  BoxShadowWrapper,
  DialogButtonPrimary,
  StatisticsTile,
} from "../../../styles/common";
import * as yup from "yup";
import { actionGetVCReportStats, actionGetVCReportStatsSuccess, actionUserAndCountryList, actionUserAndCountryListSuccess } from "../actions";
import FormSelectAutoComplete from "components/form-controls/select-autocomplete";

function ReportComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userAndCountryList = useSelector(
    (state: any) => state.masterForm.userAndCountryList
  );
  const vcReportData = useSelector(
    (state: any) => state.masterForm.vcReportData
  );
  const [selectedType, setSelectedType] = useState(null);

  const validationSchema = yup.object().shape({
    dateFrom: yup.date().typeError(t("validationMessage.dateValid")),
    dateTo: yup
      .date()
      .typeError(t("validationMessage.dateValid"))
      .min(yup.ref("dateFrom"), t("validationMessage.dateRange")),
    type: yup.string().nullable(true),
    userId: selectedType === REPORT_TYPE_FILTER_OPTIONS[1].id
      ? yup.object().required(t("validationMessage.selectUser")).typeError(t("validationMessage.selectUser"))
      : yup.object().nullable(true),
    country: selectedType == REPORT_TYPE_FILTER_OPTIONS[2].id
      ? yup.object().required(t("validationMessage.selectCountryArea")).typeError(t("validationMessage.selectCountryArea"))
      : yup.object().nullable(true),
  });

  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { handleSubmit, errors } = methods;
  const { setValue, watch } = methods;

  const watchSelectedType = watch("type", "");
  const watchSelectedUser = watch("userId", "");
  const watchSelectedCountry = watch("country", "");

  let countryDataList: any = userAndCountryList && userAndCountryList.countries.map((x: any) => {
    return { id: x, label: x }
  }) || [];

  useEffect(() => {
    setValue("dateFrom", moment().subtract(15, "d").format("YYYY-MM-DD"));
    setValue("dateTo", moment().format("YYYY-MM-DD"));
    setValue("type", REPORT_TYPE_FILTER_OPTIONS[0].id);
    dispatch(actionUserAndCountryList());
    return () => {
      dispatch(actionUserAndCountryListSuccess(""));
      dispatch(actionGetVCReportStatsSuccess(""));
    };
  }, []);;

  useEffect(() => {
    dispatch(actionGetVCReportStatsSuccess(""));
  }, [watchSelectedType, watchSelectedUser, watchSelectedCountry])

  const onSubmit = (data: any) => {
    if (watchSelectedType !== REPORT_TYPE_FILTER_OPTIONS[0].id && (!watchSelectedUser && !watchSelectedCountry)) {
      setValue("type", REPORT_TYPE_FILTER_OPTIONS[0].id);
    }
    const formData = {
      startDate: moment(data.dateFrom).format(),
      endDate: moment(data.dateTo).format(),
      ...(data.country && { country: data.country.value }),
      ...(data.userId && { userId: data.userId.value }),
    }
    dispatch(actionGetVCReportStats(formData));
    dispatch(setLoaderVisibility(false));
  };


  const handleTypeChange = ([event]: any) => {
    setSelectedType(event.target.value);
    return event.target.value;
  };

  return (
    <Container maxWidth={"xl"}>
      <FormContext {...methods}>
        <>
          <BoxShadowWrapper className="margin-top-20">
            <Grid container alignItems="center" justify="center">
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  className="padding0-16-24 margin-top-0"
                  alignItems="center"
                >
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateFrom"
                      label={t("common.fromDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateTo"
                      label={t("common.toDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>
                  <Grid item sm={2}>
                    {/* type */}
                    <FormSelect
                      name="type"
                      label={t("common.type")}
                      options={REPORT_TYPE_FILTER_OPTIONS}
                      errorobj={errors}
                      hideselectoption="false"
                      onChange={handleTypeChange}
                    />
                  </Grid>
                  {watchSelectedType === REPORT_TYPE_FILTER_OPTIONS[1].id &&
                    <Grid item sm={2}>
                      {/* users */}
                      <FormSelectAutoComplete
                        name="userId"
                        label={t("menuItems.users")}
                        placeholder={t("common.select")}
                        options={userAndCountryList.users}
                        optionsLabelKey="name"
                        errorobj={errors}
                        variant="standard"
                        required={true}
                      />
                    </Grid>}
                  {watchSelectedType === REPORT_TYPE_FILTER_OPTIONS[2].id &&
                    <Grid item sm={2}>
                      {/* country */}
                      <FormSelectAutoComplete
                        name="country"
                        label={t("common.countryArea")}
                        placeholder={t("common.select")}
                        options={countryDataList}
                        variant="standard"
                        required={true}
                        errorobj={errors}
                      />
                    </Grid>}
                  <Grid item sm={2} className="text-center">
                    <DialogButtonPrimary
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("setting.apply")}
                    </DialogButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BoxShadowWrapper>
          {
            vcReportData && Object.keys(vcReportData).length ? (
              <>
                <BoxShadowWrapper>
                  <Box py={5}>
                    {
                      !watchSelectedUser && !watchSelectedCountry &&
                      <>
                        <Grid container alignItems="stretch" justify="space-evenly">
                          <Grid item>
                            <StatisticsTile>
                              <Typography component="span" className="stat-title">
                                {t("setting.noOfUsersInTimePeriod")}
                              </Typography>
                              <Typography component="span" className="stat-count">
                                {vcReportData.usersCount}
                              </Typography>
                            </StatisticsTile>
                          </Grid>
                          <Grid item>
                            <StatisticsTile>
                              <Typography component="span" className="stat-title">
                                {t("setting.noOfAssessmentsInTimePeriod")}
                              </Typography>
                              <Typography component="span" className="stat-count">
                                {vcReportData.noOfAssessment}
                              </Typography>
                              {
                                vcReportData.assessmentHistory && vcReportData.assessmentHistory.map((assess: any, index: any) => (
                                  <Typography key={index} component="div" className="stat-subtitle-block">
                                    <Typography component="span" className="stat-subtitle">
                                      {assess.assessmentType}
                                    </Typography>
                                    <Typography component="span" className="stat-subtitle-count">
                                      {assess.count}
                                    </Typography>
                                  </Typography>
                                ))
                              }
                            </StatisticsTile>
                          </Grid>
                        </Grid>
                      </>
                    }
                    {
                      watchSelectedUser &&
                      <Grid container alignItems="stretch" justify="center">
                        <Grid item>
                          <StatisticsTile>
                            <Typography component="span" className="stat-title">
                              {t("setting.userSpecificStats")}
                            </Typography>
                            <Typography component="span" className="stat-count">
                              {vcReportData.noOfAssessment}
                            </Typography>
                            {
                              vcReportData.assessmentHistory && vcReportData.assessmentHistory.map((assess: any, index: any) => (
                                <Typography key={index} component="div" className="stat-subtitle-block">
                                  <Typography component="span" className="stat-subtitle">
                                    {assess.assessmentType}
                                  </Typography>
                                  <Typography component="span" className="stat-subtitle-count">
                                    {assess.count}
                                  </Typography>
                                </Typography>
                              ))
                            }
                          </StatisticsTile>
                        </Grid>
                      </Grid>
                    }
                    {
                      watchSelectedCountry &&
                      <Grid container alignItems="stretch" justify="center">
                        <Grid item>
                          <StatisticsTile>
                            <Typography component="span" className="stat-title">
                              {t("setting.countrySpecificStats", { name: watchSelectedCountry.value })}
                            </Typography>
                            <Typography component="span" className="stat-count">
                              {vcReportData.usersCount}
                            </Typography>
                          </StatisticsTile>
                        </Grid>
                      </Grid>
                    }
                  </Box>
                </BoxShadowWrapper>
              </>
            ) : (
              <>
                <BoxShadowWrapper>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="minHeight340"
                  >
                    <Grid item>
                      <Typography component="strong" className="strong-text">
                        {t("common.noDataAvailable")}
                      </Typography>
                    </Grid>
                  </Grid>
                </BoxShadowWrapper>
              </>
            )
          }
        </>
      </FormContext>
    </Container >
  );
}
export default ReportComponent;
