import { Button, Container, Grid, Tooltip, Typography } from "@material-ui/core";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, COMMON_CONSTANT } from "constants/data";
import { actionDetailsSaveData, actionExportAssesmentData, actionFetchAllAssesmentData, actionFetchAssesmentByIdData, actionFetchAssesmentByIdDataSuccess, actionFetchAssesmentData, actionFetchAssesmentLevelByIdData, actionSaveAssesmentData, actionSaveAssesmentDataSuccess, actionSetEditableScreen } from "containers/assessment-create/actions";
import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeToaster, openToaster, setLoaderVisibility } from "store/common/actions";
import { ArrowBackIosIconCustom, DownloadIconCustom, SubHeaderWrapper } from "styles/common";
import { actionMasterGetQuestionSetAreaOfWork, actionUpdateAssesmentSubmitedStatusData } from "./actions";
import AssesmentFormComponent from "./assesment-form";
import AssesmentQuestionTabsComponent from "./assessment-question-tab";
import AssessmentTabComponent from "./assessment-tab";
import { actionHeaderUserSetting } from "containers/header/actions";
import { ConfirmDialog } from "components/common/confirm-dialog.component";

function VCAssesmentComponent(props: any) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const userSelectedSettingData = useSelector(
		(state: any) => state.userProfile.selectedObj
	);
	const roleData = useSelector((state: any) => state.common.roleData);
	const assesmentData = useSelector(
		(state: any) => state.assesmentCreate.assesmentData
	);
	const allAssessmentDetails = useSelector(
		(state: any) => state.assesmentCreate.allAssessmentDetails
	);
	const assesmentDetailData = useSelector(
		(state: any) => state.assesmentCreate.assesmentDetailData
	);
	const saveAssesmentData = useSelector(
		(state: any) => state.assesmentCreate.saveAssesmentData
	);
	const questionSetAreaOfWorkData = useSelector(
		(state: any) => state.vcAssessment.questionSetAreaOfWorkData
	);
	const loginUserData = useSelector((state: any) => state.header.loginUserData);
	const listValidationStatus = useSelector(
		(state: any) => state.common.listValidationStatus
	);
	const assessmentTypeFilter = useSelector(
		(state: any) => state.header.assessmentTypeFilter
	);
	const languageSelected = useSelector(
		(state: any) => state.header.languageSelected
	);
	const formTitle = useSelector((state: any) => state.common.pageTitle);

	const [selectedRole, setSelectedRole] = useState("");
	const [userSelectedData, setUserSelectedData]: any = useState(null);
	const [showAssesmentTable, setShowAssesmentTable] = useState(true);
	const [assessmentTotalCount, setAssessmentTotalCount] = useState(0);
	const [tabValueSelected, setTabValueSelected]: any = useState(0);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [firstTimeCall, setFirstTimeCall] = useState(true);
	const [isEditAssessment, setIsEditAssessment] = useState(false);
	const [currentAssesmentId, setCurrentAssesmentId] = useState();
	const [editFormValues, setEditFormValues]: any = useState({});
	const [areaOfworks, setAreaOfWorks]: any = useState([]);
	const [userId, setUserId] = useState("");
	const [isUpdatedByOther, setIsUpdatedByOther]: any = useState(false);
	const [selectedAssessmentCode, setSelectedAssessmentCode] = useState("");
	const [selectedSupplyChain, setSelectedSupplyChain] = useState("");
	const [selectedScope, setSelectedScope] = useState("");
	const [selectedAssessmentStatusId, setSelectedAssessmentStatusId] = useState();
	const [selectedAssessmentType, setSelectedAssessmentType]: any = useState();
	const [canShowOverview, setCanShowOverview]: any = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [titleConfirmDialog, setTitleConfirmDialog]: any = useState("");

	const userLocalData: any = JSON.parse(
		localStorage.getItem("userSelectedData") || "{}"
	);

	const dialogTitle = t("assessment.createAssesment");

	useEffect(() => {
		defaultMethodCall();
	}, [dispatch]);

	const defaultMethodCall = () => {
		dispatch(actionHeaderUserSetting(true));
		setFirstTimeCall(false);
	};

	useEffect(() => {
		if (loginUserData) {
			setUserId(loginUserData.id);
		}
	}, [loginUserData]);

	useEffect(() => {
		if (Object.keys(userLocalData).length > 0) {
			dispatch(actionFetchAllAssesmentData({
				"assesementType": ASSESSMENT_TYPE_CONST.VC
			}));
		}
		return () => {
			dispatch(actionSaveAssesmentDataSuccess(null));
			dispatch(actionFetchAssesmentByIdDataSuccess([]));
		};
	}, []);

	useEffect(() => {
		if (firstTimeCall === false) {
			setCanShowOverview(false);
			if (currentAssesmentId) {
				if (!showAssesmentTable) {
					dispatch(actionFetchAssesmentByIdData(currentAssesmentId));
				}
			}
		}
	}, [assessmentTypeFilter]);

	useEffect(() => {
		if (firstTimeCall === false) {
			if (currentAssesmentId) {
				if (canShowOverview) {
					dispatch(actionFetchAssesmentLevelByIdData(currentAssesmentId));
				}
				if (!showAssesmentTable) {
					dispatch(actionFetchAssesmentByIdData(currentAssesmentId));
				}
			}
		}
	}, [languageSelected]);

	useEffect(() => {
		if (userSelectedSettingData) {
			fetchAssesmeDataByCountry(userSelectedSettingData.userSelectedData);
			setShowAssesmentTable(true);
		} else if (localStorage.getItem("userSelectedData")) {
			const userSettingData: any = JSON.parse(
				localStorage.getItem("userSelectedData") || "{}"
			);
			if (userSettingData.hasOwnProperty("userSelectedCountry")) {
				fetchAssesmeDataByCountry(userSettingData);
			}
			setShowAssesmentTable(true);
		}
	}, [userSelectedSettingData]);

	useEffect(() => {
		if (questionSetAreaOfWorkData) {
			const tempAreaOfWorks = questionSetAreaOfWorkData.map((item: any) => {
				return { ...item, label: item.name }
			});
			setAreaOfWorks(tempAreaOfWorks);
		}
	}, [questionSetAreaOfWorkData]);


	useEffect(() => {
		if (userSelectedData && roleData && roleData.length) {
			const role = roleData.filter(
				(roles: any) => roles.id === userSelectedData.userSelectedRole
			);
			if (role.length) {
				setSelectedRole(role[0].name);
			}
		}
	}, [roleData, userSelectedData]);

	const fetchAssesmeDataByCountry = (userSelectedData: any) => {
		setUserSelectedData(userSelectedData);
		//dispatch(setLoaderVisibility(true));
		dispatch(
			actionFetchAssesmentData({
				assesementType: ASSESSMENT_TYPE_CONST.VC
			})
		);
		dispatch(actionMasterGetQuestionSetAreaOfWork());
	};

	const setAssesmentEditLable = (editValues: any) => {
		setSelectedAssessmentCode(editValues.assessmentCode);
		setSelectedSupplyChain(editValues.supplyChain);
		setSelectedScope(editValues.scope);
		setSelectedAssessmentStatusId(editValues.assessmentStatusId);
		setSelectedAssessmentType(editValues.assessmentType);
		dispatch(actionSaveAssesmentDataSuccess(null));
	};

	useEffect(() => {
		if (
			saveAssesmentData &&
			saveAssesmentData.hasOwnProperty("isIndicatorSetConflict") &&
			saveAssesmentData.isIndicatorSetConflict === false &&
			firstTimeCall === false
		) {
			setShowAssesmentTable(false);
			setCurrentAssesmentId(saveAssesmentData.id);
			dispatch(actionFetchAssesmentByIdData(saveAssesmentData.id));
			setEditFormValues(saveAssesmentData);
			setAssesmentEditLable(saveAssesmentData);
			setIsEditAssessment(true);
			dispatch(setLoaderVisibility(true));
			dispatch(
				actionFetchAssesmentData({
					assesementType: ASSESSMENT_TYPE_CONST.VC
				})
			);
			dispatch(actionFetchAllAssesmentData({
				"assesementType": ASSESSMENT_TYPE_CONST.VC
			}));
		} else if (
			saveAssesmentData &&
			saveAssesmentData.hasOwnProperty("isIndicatorSetConflict") &&
			saveAssesmentData.isIndicatorSetConflict === true &&
			firstTimeCall === false
		) {
			dispatch(openToaster(t("assessment.indicatorSetNotAvailable"), false));
			closeToasterParticularTimeInterval();
			dispatch(setLoaderVisibility(false));
		}
	}, [saveAssesmentData]);

	useEffect(() => {
		if (
			assesmentDetailData &&
			assesmentDetailData.hasOwnProperty("assesementDetails") &&
			firstTimeCall === false
		) {
			setShowAssesmentTable(false);
		}
	}, [assesmentDetailData]);

	const closeToasterFunction = () => {
		dispatch(closeToaster());
	};

	const closeToasterParticularTimeInterval = () => {
		setTimeout(function () {
			closeToasterFunction();
		}, COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
	};

	const showDialoagForm = () => {
		setDialogOpen(true);
	};

	const dialogClose = () => {
		setDialogOpen(false);
	};

	const showAssesmentDetailTable = (formData: any) => {
		dispatch(actionSaveAssesmentData(formData));
		dialogClose();
	};

	const checkAssessmentStatusLabel: any = (id: any) => {
		let statusLabel: string = "";
		switch (id) {
			case 1:
				statusLabel = t("assessment.vcAssessorDraft");
				break;
			case 6:
				statusLabel = t("assessment.submitted");
				break;
		}
		return statusLabel;
	};

	const showAssesmentSetTable = (id: any) => {
		if (isUpdatedByOther) {
			setIsUpdatedByOther(false);
			dispatch(
				actionFetchAssesmentData({
					assesementType: ASSESSMENT_TYPE_CONST.VC
				})
			);
		}
		setShowAssesmentTable(true);
		setCanShowOverview(false);
		// set editable to false
		dispatch(actionSetEditableScreen(false));
	};

	const functionEditAssesmentDetailData = (rowValues: any) => {
		setEditFormValues(rowValues);
		setAssesmentEditLable(rowValues);
		dispatch(actionFetchAssesmentByIdData(rowValues.id));
		setCurrentAssesmentId(rowValues.id);
	};

	const functionAssesmentLevelData = (rowData: any) => {
		dispatch(actionDetailsSaveData(rowData));
		dispatch(actionFetchAssesmentLevelByIdData(rowData.id));
		setCurrentAssesmentId(rowData.id);
	};

	const handleSubmitAssessment = () => {
		setTitleConfirmDialog(t("assessment.areYouSureToSubmit"));
		setOpenConfirmDialog(true);
	};

	const handlerCloseAssessmentSubmitConfirmDialog = () => {
		setOpenConfirmDialog(false);
	}

	const handleSubmitAssessmentOK = () => {
		const formData = {
			id: editFormValues.id,
			assessmentStatusId: ASSESSMENT.STATUS.SUBMITTED,
			updatedBy: userId,
			countryDataList: [],
		};
		let assessmentStatusId = ASSESSMENT.STATUS.SUBMITTED;
		formData.assessmentStatusId = assessmentStatusId;
		dispatch(actionUpdateAssesmentSubmitedStatusData(formData));
		setShowAssesmentTable(true);
		setOpenConfirmDialog(false);
		setCanShowOverview(false);
	};

	const showReportAssesment = () => {
		// this function will used for export excel.
		const formData = {
			countries: [],
			role: selectedRole.trim(),
			organization: userSelectedData.organization,
			assessmentType: ASSESSMENT_TYPE_CONST.VC
		};
		dispatch(actionExportAssesmentData(formData));
	};

	return (
		<Container maxWidth={"xl"}>
			<ConfirmDialog
				open={openConfirmDialog}
				close={handlerCloseAssessmentSubmitConfirmDialog}
				title={titleConfirmDialog}
				content=""
				ok={handleSubmitAssessmentOK}
			/>
			{showAssesmentTable ? (
				<>

					<SubHeaderWrapper>
						<Grid container justify="space-between" alignItems="center">
							<Grid item>
								<Typography component="span" className="common-label">
									{`${t("assessment.allAssesments")}  (${assessmentTotalCount})`}
								</Typography>
							</Grid>
							<Grid item>
								<Grid container spacing={2} justify="flex-end">
									<Grid item>
										<Tooltip title={`${t("common.downloadReport")}`}>
											<Button
												variant="outlined"
												color="primary"
												onClick={() => showReportAssesment()}
											>
												<DownloadIconCustom className="arMarginR0 arMarginL10" />
												{t("common.exportToExcel")}
											</Button>
										</Tooltip>
									</Grid>

									<Grid item>
										<Tooltip title={`${t("assessment.createAssesment")}`}>
											<Button
												variant="contained"
												color="primary"
												onClick={() => showDialoagForm()}
											>
												{t("assessment.createAssesment")}
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</SubHeaderWrapper>
					<AssessmentTabComponent
						assesmentData={assesmentData}
						setAssessmentTotalCount={setAssessmentTotalCount}
						tabValueSelected={tabValueSelected}
						setTabValueSelected={setTabValueSelected}
						setLoaderVisibility={setLoaderVisibility}
						selectedRole={selectedRole}
						setIsEditAssessment={setIsEditAssessment}
						functionEditAssesmentDetailData={functionEditAssesmentDetailData}
						functionAssesmentLevelData={functionAssesmentLevelData}
						formTitle={formTitle}
						setCanShowOverview={setCanShowOverview}
						checkAssessmentStatusLabel={checkAssessmentStatusLabel}
						canShowOverview={canShowOverview}
					></AssessmentTabComponent>
					<AssesmentFormComponent
						assesmentData={allAssessmentDetails}
						showAssesmentDetailTable={showAssesmentDetailTable}
						closeToasterFunction={closeToasterFunction}
						dialogClose={dialogClose}
						dialogOpen={dialogOpen}
						dialogTitle={dialogTitle}
						areaOfworks={areaOfworks}
					></AssesmentFormComponent>
				</>
			) :
				<>
					<Grid
						container
						alignItems="center"
						justify="space-between"
						className="paddingTB15"
					>
						<Grid item>
							<Grid container alignItems="center">
								<Grid item>
									<IconButton
										color="default"
										onClick={() => showAssesmentSetTable(currentAssesmentId)}
										className="padding10"
									>
										<ArrowBackIosIconCustom className="arRotate180" />
									</IconButton>
								</Grid>
								<Grid item>
									<Typography
										component="span"
										className="common-label padding10-5"
									>
										{isEditAssessment
											? t("common.editAssessment")
											: t("common.viewAssessment")}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							{
								selectedAssessmentStatusId !== ASSESSMENT.STATUS.SUBMITTED &&
								isEditAssessment && (
									<Grid container justify="flex-end" spacing={2}>
										<Grid item>
											<Button
												variant="contained"
												color="primary"
												onClick={handleSubmitAssessment}
												disabled={
													!isEditAssessment
												}
											>
												{t("common.submit")}
											</Button>
										</Grid>
									</Grid>
								)}
						</Grid>

					</Grid>
					<>
						<AssesmentQuestionTabsComponent
							assesmentDetailData={assesmentDetailData}
							editFormValues={editFormValues}
							userId={userId}
							isEditAssessment={isEditAssessment}
							selectedAssessmentCode={selectedAssessmentCode}
							selectedSupplyChain={selectedSupplyChain}
							selectedScope={selectedScope}
							selectedRole={selectedRole}
							selectedAssessmentType={selectedAssessmentType}
							checkAssessmentStatusLabel={checkAssessmentStatusLabel}
							validationStatusData={listValidationStatus}
							loginUserData={loginUserData}
							selectedAssessmentStatusId={selectedAssessmentStatusId}
							closeToasterParticularTimeInterval={
								closeToasterParticularTimeInterval
							}
							openToaster={openToaster}
							isDataCheck={true}
							setLoaderVisibility={setLoaderVisibility}
							setIsUpdatedByOther={setIsUpdatedByOther}
						></AssesmentQuestionTabsComponent>
					</>
				</>
			}
		</Container >
	);
}

export default VCAssesmentComponent;
