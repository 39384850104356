export const PRIMARY_COLOR: string = "#1CABE2";
export const PRIMARY_BUTTON_COLOR: string = "#374EA1";
export const SECONDARY_COLOR: string = "#FFFFFF";
export const PRIMARY_BUTTON_BG: string = "#0078D4";
export const PRIMARY_LABEL_COLOR: string = "#888888";
export const ERROR_COLOR: string = "#cc0000";
export const TOASTER_ERROR: string = "#d32f2f";
export const TOASTER_SUCCESS: string = "green";
export const SECONDARY_BUTTON_BG: string = "#f50057";
export const BLACK_COLOR: string = "#000";
export const WHITE_COLOR: string = "#FFF";
export const DARK_GRAY: string = "#4A494A";
export const LIGHT_GRAY: string = "#F3F3F3";
export const UNICEF_BLUE: string = "#E0F2F9";
export const ORANGE_COLOR: string = "#F16A21";
export const UNICEF_GREEN: string = "128,189,65";
export const DARK_GRAY_BG: string = "74,73,74";
export const BAR_FILLED: string = "#1BAAE1";
export const BAR_EMPTY: string = "rgba(118, 118, 118, 0.2)";
export const RED_COLOR: string ="#E1221A";