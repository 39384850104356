import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import logo from "../../styles/images/uniceflogo.svg";
import DrawerComponent from "./drawer";
import Typography from "@material-ui/core/Typography";
import Account from "./account";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  VerticalLine,
  CustomNavBar,
  LandingLanguageBar,
  CustomNavBarSdAdmin,
  UsersTypeSwitch,
} from "../../styles/common";
import { useSelector, useDispatch } from "react-redux";
import {
  actionHeaderSetOrganization,
  actionSetAssessmentTypeFilter,
} from "./actions";
import { useTranslation } from "react-i18next";
import {
  sortObjectDataByAsending,
  removeWhiteSpaceInString,
} from "../../utils/data";
import PreLoginPage from "../pre-login";
import "./index.css";
import { Container } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

import { history } from "../../store/config";
import {
  HOME_PATH,
  ABOUT_US,
  HOW_TO_JOIN,
  MATURITY_INDEX,
  QUESTION,
  AREA_WORK_TOOL,
  LOGIN_PATH,
  SIGN_OUT_PATH,
  ERROR_PATH,
  HOME_PATH_AFTER_LOGIN,
  AOW_MANAGEMENT,
  COUNTRY_HIERARCHY,
  REPORT,
  VC_REPORT,
} from "../../constants/api";
import LanguagSelectionComponent from "./language";
import OutsideAlerter from "./click-outside";
import { Toaster } from "components/common/toaster.component";
import {
  actionSetAreaOfWorkList,
  actionSetCurrentRoute,
  actionSetOrgWiseCountry,
  actionSetOrgWiseRegion,
  actionSetProgramList,
  actionSetScopeList,
  actionSetSubAreaOfWorkList,
  actionSetUnicefCountry,
  actionSetUnicefRegion,
  actionSetUserAssignedProgramList,
  actionSetValidationStatusList,
  actionSetWhoCountry,
  actionSetWhoRegion,
  closeToaster,
  setLoaderVisibility,
} from "store/common/actions";
import Link from "@material-ui/core/Link/Link";
import {
  DYNAMIC_MASTER,
  LANGUAGE,
  LANGUAGE_CONSTANT,
  MENU_DATA,
  USER,
} from "constants/data";
import { actionSetAuthenticationSuccess } from "containers/user-profile/actions";
import { UHeader } from "./styles";
import _ from "lodash";
import Switch from "@material-ui/core/Switch";

function HeaderComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  let [masterMenuData, setMasterMenuData] = useState([]);
  const currentRoute = useSelector((state: any) => state.common.currentRoute);
  const pageRoute = window.location.pathname;
  const [loggedInUser, setLoggedInUser] = useState({
    name: "",
    email: "",
    userName: "",
    firstName: "",
    lastName: "",
  });

  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const selectedObj = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const [instructionGuide, setInstructionGuide]: any = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPrograms, setSelectedPrograms]: any = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const roleData = useSelector((state: any) => state.common.roleData);
  const countryData = useSelector((state: any) => state.common.countryData);

  let showAssessmentTable = useSelector(
    (state: any) => state.assesmentCreate.showAssessmentTable
  )

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );

  const listUnicefRegion = useSelector(
    (state: any) => state.common.listUnicefRegion
  );
  const listUnicefCountry = useSelector(
    (state: any) => state.common.listUnicefCountry
  );
  const listWhoRegion = useSelector((state: any) => state.common.listWhoRegion);
  const listWhoCountry = useSelector(
    (state: any) => state.common.listWhoCountry
  );
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const localSelectedData = JSON.parse(
    localStorage.getItem("userSelectedData") || "{}"
  );
  const isUserSetting = useSelector((state: any) => state.header.isUserSetting);
  const dynamicMasterFormData = useSelector(
    (state: any) => state.masterForm.dynamicMasterFormData
  );
  const assessmentValidationStatusData = useSelector(
    (state: any) => state.assesmentCreate.assessmentValidationStatusData
  );

  const loginUserData = useSelector((state: any) => state.header.loginUserData);
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const auth = useSelector((state: any) => state.userProfile.isAuthenticated);
  const isUserAuthenticated = useSelector(
    (state: any) => state.userProfile.isAuthenticated
  );
  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );

  const allOrganization: any = (window as any).allOrganization;
  const checkBoxState: any = localStorage.getItem("checkBoxState");
  const [isUserDataFromDBSet, setIsUserDataFromDBSet] = useState(true);
  const [userId, setUserId] = useState("");
  let listOfCountry: any = [];
  let listOfRegions: any = [];
  let listOfWhoCountry: any = [];
  let listOfWhoRegions: any = [];
  let listOfScope: any = [];
  let listOfSupplyChain: any = [];
  let listOfAreaOfWork: any = [];
  let listValidationStatus: any = [];
  let listOfSubAreaOfWork: any = [];
  let userAssignedProhramList: any = [];

  const guideName = `instructionFiles/Maturity Model 2.0 Guidelines_2020_FINAL_${instructionGuide[0]}.pdf`;

  useEffect(() => {
    if (
      loginUserData &&
      listUnicefRegion.length &&
      listUnicefCountry.length &&
      listWhoRegion.length &&
      listWhoCountry.length &&
      organizationSelected
    ) {
      let regionListOrgWise: any = listUnicefRegion;
      let countryListOrgWise: any = listUnicefCountry;
      if (
        organizationSelected.toLowerCase() ===
        allOrganization[1].id.toLowerCase()
      ) {
        regionListOrgWise = listWhoRegion;
        countryListOrgWise = listWhoCountry;
      }
      dispatch(actionSetOrgWiseRegion(regionListOrgWise));
      dispatch(actionSetOrgWiseCountry(countryListOrgWise));
    }
  }, [
    loginUserData,
    listUnicefRegion,
    listUnicefCountry,
    listWhoRegion,
    listWhoCountry,
    organizationSelected,
  ]);

  useEffect(() => {
    if (loginUserData && isUserDataFromDBSet && isUserAuthenticated) {
      setLoggedInUser({
        name: loginUserData.name,
        email: loginUserData.email,
        userName: loginUserData.userName,
        firstName: loginUserData.name,
        lastName: loginUserData.lastName,
      });

      const programObj: any = {
        isAllSelected: loginUserData.isAllProgramAssigned,
        programs: loginUserData.programs,
      };
      setSelectedPrograms(programObj);

      setIsUserDataFromDBSet(false);
      setUserId(loginUserData.id);
      if (loginUserData.userSettings) {
        let userSelectedData = JSON.parse(loginUserData.userSettings);
        if (userSelectedData.organization != organizationSelected) {
          dispatch(actionHeaderSetOrganization(userSelectedData.organization));
        }
      } else if (loginUserData.organization) {
        dispatch(actionHeaderSetOrganization(loginUserData.organization));
        localStorage.setItem("organization", loginUserData.organization);
      } else {
        dispatch(actionHeaderSetOrganization(allOrganization[0].id));
        localStorage.setItem("organization", allOrganization[0].id);
      }
    }
  }, [loginUserData, isUserAuthenticated]);

  useEffect(() => {
    if (
      selectedObj &&
      selectedObj.hasOwnProperty("userSelectedData") &&
      roleData.length
    ) {
      changeMenu(selectedObj.userSelectedData.userSelectedRole);
    } else {
      if (localSelectedData) {
        if (
          localSelectedData.hasOwnProperty("userSelectedRole") &&
          roleData.length
        ) {
          changeMenu(localSelectedData.userSelectedRole);
        }
      }
    }
  }, [selectedObj, roleData]);

  useEffect(() => {
    if (
      dynamicMasterFormData &&
      dynamicMasterFormData.length &&
      !_.isEmpty(selectedPrograms)
    ) {
      const scope = dynamicMasterFormData.filter(
        (form: any) =>
          form.menuName.toLowerCase().trim() === DYNAMIC_MASTER.SCOPE
      );
      const supplyChain = dynamicMasterFormData.filter(
        (form: any) =>
          form.menuName.toLowerCase().trim() === DYNAMIC_MASTER.SUPPLY_CHAIN
      );

      const areaOfWork = dynamicMasterFormData.filter(
        (form: any) =>
          form.menuName.toLowerCase().trim() === DYNAMIC_MASTER.AREA_OF_WORK
      );

      const subAreaOfWork = dynamicMasterFormData.filter(
        (form: any) =>
          form.menuName.toLowerCase().trim() === DYNAMIC_MASTER.SUB_AREA_OF_WORK
      );
      scope.map((sc: any) => {
        listOfScope.push({
          id: sc.formId.toString(),
          label:
            languageSelected === LANGUAGE.FRENCH
              ? sc.fld3
              : languageSelected === LANGUAGE.SPANISH
                ? sc.fld5
                : languageSelected === LANGUAGE.RUSSIAN
                  ? sc.fld7
                  : languageSelected === LANGUAGE.ARABIC
                    ? sc.fld9
                    : sc.fld1,
        });
      });

      supplyChain.map((sch: any) => {
        listOfSupplyChain.push({
          id: sch.formId.toString(),
          label:
            languageSelected === LANGUAGE.FRENCH
              ? sch.fld3
              : languageSelected === LANGUAGE.SPANISH
                ? sch.fld5
                : languageSelected === LANGUAGE.RUSSIAN
                  ? sch.fld7
                  : languageSelected === LANGUAGE.ARABIC
                    ? sch.fld9
                    : sch.fld1,
        });
        //filltered program list as per user
        if (selectedPrograms.programs) {
          const filterProgram = selectedPrograms.programs.filter(
            (item: any) => item.id === sch.formId
          );
          if (filterProgram.length) {
            userAssignedProhramList.push({
              id: sch.formId.toString(),
              label:
                languageSelected === LANGUAGE.FRENCH
                  ? sch.fld3
                  : languageSelected === LANGUAGE.SPANISH
                    ? sch.fld5
                    : languageSelected === LANGUAGE.RUSSIAN
                      ? sch.fld7
                      : languageSelected === LANGUAGE.ARABIC
                        ? sch.fld9
                        : sch.fld1,
            });
          }
        }
      });

      areaOfWork.map((area: any) => {
        listOfAreaOfWork.push({
          id: area.formId.toString(),
          label:
            languageSelected === LANGUAGE.FRENCH
              ? area.fld3
              : languageSelected === LANGUAGE.SPANISH
                ? area.fld5
                : languageSelected === LANGUAGE.RUSSIAN
                  ? area.fld7
                  : languageSelected === LANGUAGE.ARABIC
                    ? area.fld9
                    : area.fld1,
        });
      });

      subAreaOfWork.map((area: any) => {
        listOfSubAreaOfWork.push({
          id: area.formId.toString(),
          label:
            languageSelected === LANGUAGE.FRENCH
              ? area.fld3
              : languageSelected === LANGUAGE.SPANISH
                ? area.fld5
                : languageSelected === LANGUAGE.RUSSIAN
                  ? area.fld7
                  : languageSelected === LANGUAGE.ARABIC
                    ? area.fld9
                    : area.fld1,
        });
      });
      dispatch(actionSetProgramList(listOfSupplyChain));
      dispatch(actionSetScopeList(listOfScope));
      dispatch(actionSetAreaOfWorkList(listOfAreaOfWork));
      dispatch(actionSetSubAreaOfWorkList(listOfSubAreaOfWork));
      dispatch(actionSetUserAssignedProgramList(userAssignedProhramList));
    }
  }, [dynamicMasterFormData, languageSelected, selectedPrograms]);

  useEffect(() => {
    if (
      assessmentValidationStatusData &&
      assessmentValidationStatusData.length
    ) {
      assessmentValidationStatusData.map((validationStatus: any) => {
        listValidationStatus.push({
          id: validationStatus.id,
          label:
            languageSelected === LANGUAGE.FRENCH
              ? validationStatus.nameFr
              : languageSelected === LANGUAGE.SPANISH
                ? validationStatus.nameEs
                : languageSelected === LANGUAGE.RUSSIAN
                  ? validationStatus.nameRu
                  : languageSelected === LANGUAGE.ARABIC
                    ? validationStatus.nameAr
                    : validationStatus.name,
        });
      });
      dispatch(actionSetValidationStatusList(listValidationStatus));
    }
  }, [assessmentValidationStatusData, languageSelected]);

  useEffect(() => {
    languageSelected === LANGUAGE.ARABIC
      ? setInstructionGuide([
        LANGUAGE_CONSTANT.ARABIC_CONSTANT,
        ...instructionGuide,
      ])
      : languageSelected === LANGUAGE.FRENCH
        ? setInstructionGuide([
          LANGUAGE_CONSTANT.FRENCH_CONSTANT,
          ...instructionGuide,
        ])
        : languageSelected === LANGUAGE.SPANISH
          ? setInstructionGuide([
            LANGUAGE_CONSTANT.SPANISH_CONSTANT,
            ...instructionGuide,
          ])
          : languageSelected === LANGUAGE.RUSSIAN
            ? setInstructionGuide([
              LANGUAGE_CONSTANT.RUSSIAN_CONSTANT,
              ...instructionGuide,
            ])
            : setInstructionGuide([
              LANGUAGE_CONSTANT.ENGLISH_CONSTANT,
              ...instructionGuide,
            ]);
  }, [languageSelected]);

  const goToPath = (path: any) => (event: any) => {
    history.push(path);
    const tempcurrentRoute = window.location.pathname;
    dispatch(actionSetCurrentRoute(tempcurrentRoute));
  };

  const changeMenu = (role: any) => {
    const tempRoleData: any[] = roleData.filter(
      (roleE: any) => roleE.id === role
    );
    const data = removeWhiteSpaceInString(tempRoleData[0].name.trim());
    setMasterMenuData(
      MENU_DATA[data]
    );
  };

  const toggleDrawer = () => {
    setDrawerVisibility(!isDrawerVisible);
  };

  const logout = () => {
    dispatch(actionSetAuthenticationSuccess(null));
    dispatch(setLoaderVisibility(false));
    window.localStorage.clear();
    window.sessionStorage.clear();
    localStorage.setItem("language", languageSelected);
    localStorage.setItem("checkBoxState", checkBoxState);
    history.replace(SIGN_OUT_PATH);
    window.location.replace(SIGN_OUT_PATH);
    window.location.reload();
  };

  const login = () => {
    if (checkBoxState === "true") {
      loginRedirectMethod();
    } else {
      setDialogOpen(true);
    }
  };
  const loginRedirectMethod = () => {
    dispatch(setLoaderVisibility(true));
    localStorage.setItem("loginPage", "1");
    history.replace(LOGIN_PATH);
    window.location.reload();
  };

  const dialogClose = () => {
    setDialogOpen(false);
  };

  // This function used for render dynamic Master Menu
  const generateMenus = () => {
    return masterMenuData && masterMenuData.map((item: any, i) => {
      return (
        <Typography
          component="span"
          className={
            "MenuItem" + (pageRoute === item.path ? " ActiveMenuItem" : "")
          }
          onClick={goToPath(item.path)}
          key={i}
        >
          {t("menuItems." + item.menuName)}
        </Typography>
      );
    });
  };

  useEffect(() => {
    if (
      selectedObj !== null &&
      selectedObj.hasOwnProperty("userSelectedData") &&
      roleData.length &&
      listOrgWiseCountry.length
    ) {
      setUserDetails(selectedObj.userSelectedData);
    } else {
      if (localSelectedData && roleData.length && listOrgWiseCountry.length) {
        if (localSelectedData.hasOwnProperty("userSelectedRole")) {
          setUserDetails(localSelectedData);
        }
      }
    }
  }, [selectedObj, roleData, listOrgWiseCountry]);

  useEffect(() => {
    functionOrgWiseRegionCountry();
  }, [countryData]);

  const setUserDetails = (userData: any) => {
    const { userSelectedRole, userSelectedCountry, userSelectedRegion } =
      userData;
    const tempRoleData = roleData.filter(
      (role: any) => role.id === userSelectedRole
    );
    if (tempRoleData.length) {
      setSelectedRole(tempRoleData[0].name.trim());
    }
    if (Array.isArray(userSelectedCountry)) {
      if (userSelectedCountry.length > 1) {
        setSelectedCountry("");
      } else if (userSelectedCountry.length === 1) {
        const tempCountryData = listOrgWiseCountry.filter(
          (country: any) => country.id === userSelectedCountry[0]
        );
        if (tempCountryData.length)
          setSelectedCountry(tempCountryData[0].label.trim());
      } else {
        setSelectedCountry("");
      }
    } else {
      const tempCountryData = listOrgWiseCountry.filter(
        (country: any) => country.id === userSelectedCountry
      );
      if (tempCountryData.length)
        setSelectedCountry(tempCountryData[0].label.trim());
    }
    setSelectedRegion(userSelectedRegion);
  };

  const functionOrgWiseRegionCountry = () => {
    if (countryData && countryData.length) {
      countryData.map((country: any) => {
        //for Unicef Organization
        if (country.isActive) {
          listOfCountry.push({
            key: country.id,
            id: country.id,
            value: country.id,
            label: country.name,
            region: country.region,
            isoalpha3code: country.isoalpha3code,
          });

          if (listOfRegions.length === 0) {
            listOfRegions.push({
              key: country.region,
              id: country.region,
              label: country.region,
            });
          } else if (listOfRegions.length) {
            const filterListRegion = listOfRegions.filter(
              (reg: any) => reg.id === country.region
            );
            if (filterListRegion.length == 0) {
              listOfRegions.push({
                key: country.region,
                id: country.region,
                label: country.region,
              });
            }
          }
        }
        //End Unicef Organization

        //for WHO Organization
        if (country.whoRegion && country.isActiveWho) {
          listOfWhoCountry.push({
            key: country.id,
            id: country.id,
            value: country.id,
            label: country.name,
            region: country.whoRegion,
            isoalpha3code: country.isoalpha3code,
          });
        }

        if (listOfWhoRegions.length === 0 && country.whoRegion) {
          listOfWhoRegions.push({
            key: country.whoRegion,
            id: country.whoRegion,
            label: country.whoRegion,
          });
        } else if (listOfWhoRegions.length && country.whoRegion) {
          const filterListRegion = listOfWhoRegions.filter(
            (reg: any) => reg.id === country.whoRegion
          );
          if (filterListRegion.length == 0) {
            listOfWhoRegions.push({
              key: country.whoRegion,
              id: country.whoRegion,
              label: country.whoRegion,
            });
          }
        }
        //end of WHO Organization
      });
      listOfRegions = sortObjectDataByAsending(listOfRegions, "label");
      listOfCountry = sortObjectDataByAsending(listOfCountry, "label");
      listOfWhoRegions = sortObjectDataByAsending(listOfWhoRegions, "label");
      listOfWhoCountry = sortObjectDataByAsending(listOfWhoCountry, "label");
      dispatch(actionSetUnicefRegion(listOfRegions));
      dispatch(actionSetUnicefCountry(listOfCountry));
      dispatch(actionSetWhoRegion(listOfWhoRegions));
      dispatch(actionSetWhoCountry(listOfWhoCountry));
    }
  };

  const [open, setOpen] = React.useState(false);
  const [openMasters, setOpenMasters] = React.useState(false);
  const [openReports, setOpenReports] = React.useState(false);
  const anchorRefMasters = React.useRef<HTMLButtonElement>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const anchorRefReports = React.useRef<HTMLButtonElement>(null);

  const handleMastersMenuToggle = () => {
    setOpenMasters((prevOpenMasters) => !prevOpenMasters);
  };

  const handleReportsMenuToggle = () => {
    setOpenReports((prevOpenReports) => !prevOpenReports);
  };

  const handleCommonMenuToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMasters = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRefMasters.current &&
      anchorRefMasters.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMasters(false);
  };

  const handleCloseReports = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRefReports.current &&
      anchorRefReports.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenReports(false);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDownMasters(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const isMasterPage = () => {
    if (
      pageRoute === MATURITY_INDEX ||
      pageRoute === QUESTION ||
      pageRoute === AREA_WORK_TOOL ||
      pageRoute === AOW_MANAGEMENT
    ) {
      return true;
    } else {
      return false;
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const prevOpenMasters = React.useRef(openMasters);
  useEffect(() => {
    if (prevOpenMasters.current === true && openMasters === false) {
      anchorRefMasters.current!.focus();
    }
    prevOpenMasters.current = openMasters;
  }, [openMasters]);

  useEffect(() => {
    if (localStorage.getItem('currentAssessmentType')) {
      const type: any = JSON.parse(localStorage.getItem("currentAssessmentType") || 'false')
      dispatch(actionSetAssessmentTypeFilter(type));
    }
    else {
      localStorage.setItem('currentAssessmentType', JSON.stringify(false))
    }
  }, [])

  const handleAssessmentTypeFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (showAssessmentTable)
      return
    dispatch(actionSetAssessmentTypeFilter(event.target.checked));
    localStorage.setItem('currentAssessmentType', JSON.stringify(event.target.checked))
  };

  return (
    <React.Fragment>
      <DrawerComponent
        visible={isDrawerVisible}
        setVisibility={setDrawerVisibility}
      />
      <Toaster
        message={toasterMessage}
        open={isToasterVisible}
        close={closeToaster}
        success={isSuccess}
      />
      <Dialog
        open={dialogOpen}
        onClose={dialogClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
      >
        <DialogContent className="nopadding">
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <PreLoginPage
            dialogClose={dialogClose}
            loginRedirectMethod={loginRedirectMethod}
          ></PreLoginPage>
        </DialogContent>
      </Dialog>
      {window.location.pathname === ERROR_PATH ||
        window.location.pathname === HOME_PATH_AFTER_LOGIN ? (
        <>
          <UHeader position="relative" className={"transperent-header"}>
            <Toolbar disableGutters={true}>
              <Container maxWidth={"xl"} className="position-relative">
                <Grid container={true} justify="flex-end">
                  <Grid item>
                    <LandingLanguageBar className="errorpage">
                      <LanguagSelectionComponent />
                    </LandingLanguageBar>
                  </Grid>
                </Grid>
              </Container>
            </Toolbar>
          </UHeader>
        </>
      ) : (
        <>
          {isUserSetting ? (
            <div>
              <AppBar position="relative">
                <Toolbar disableGutters={true}>
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={toggleDrawer}
                    className={auth === false ? "nodisplay" : "nodisplay"}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Container maxWidth={"xl"} className="position-relative">
                    <Grid
                      container={true}
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item={true}>
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          justify="flex-start"
                        >
                          <Grid item>
                            <Typography
                              component="img"
                              src={logo}
                              alt="Unicef Logo"
                            />
                          </Grid>
                          <Grid item>
                            <VerticalLine />
                          </Grid>
                          <Grid item>
                            <Typography
                              component="h5"
                              color="inherit"
                              className="app-name"
                            >
                              {selectedAppDetails && Object.keys(selectedAppDetails).length ? selectedAppDetails.name : t("applicationTitle.appName")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item={true}>
                        <Grid container alignItems="center" justify="flex-end" spacing={2}>
                          <Grid item>
                            {selectedRole !== USER.ROLE.VC_ASSESSOR && (
                              <UsersTypeSwitch>
                                <Typography component={"span"} className={`font-size-14 ${!assessmentTypeFilter ? 'selected-user-type' : ''}`}>
                                  {t("common.normal")}
                                </Typography>
                                <Switch
                                  size="small"
                                  checked={assessmentTypeFilter}
                                  onChange={handleAssessmentTypeFilter}
                                  name="assesmentTypeSwitch"
                                  color="default"
                                />
                                <Typography component={"span"} className={`font-size-14 ${assessmentTypeFilter ? 'selected-user-type' : ''}`}>
                                  {t("common.resiliency")}
                                </Typography>
                              </UsersTypeSwitch>)
                            }
                          </Grid>
                          <Grid item>
                            {auth ? (
                              <OutsideAlerter>
                                <Account
                                  loggedInUser={loggedInUser}
                                  logout={logout}
                                  selectedRole={selectedRole}
                                  selectedRegion={selectedRegion}
                                  selectedPrograms={selectedPrograms}
                                  selectedCountry={selectedCountry}
                                  userId={userId}
                                />
                              </OutsideAlerter>
                            ) : (
                              <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                  <LandingLanguageBar>
                                    <LanguagSelectionComponent />
                                  </LandingLanguageBar>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color="inherit"
                                    onClick={login}
                                    className="text-capitalize"
                                  >
                                    <AccountCircleOutlinedIcon className="marginR10 arMarginL10R0" />
                                    {t("home.login")}
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Container>
                </Toolbar>
              </AppBar>
              {selectedRole === USER.ROLE.SD_ADMIN && (
                <CustomNavBar>
                  <Grid container alignItems="center" justify="center">
                    <Grid item={true}>
                      <CustomNavBarSdAdmin>
                        {auth ? (
                          <>
                            <Typography
                              component="div"
                              className="Menubar"
                            >
                              {generateMenus()}
                              {selectedRole === USER.ROLE.SD_ADMIN && (
                                <>
                                  <Button
                                    ref={anchorRefReports}
                                    aria-controls={
                                      openMasters
                                        ? "menu-list-grow-masters"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleReportsMenuToggle}
                                    className={
                                      "MenuItem" +
                                      (isMasterPage()
                                        ? " ActiveMenuItem"
                                        : "")
                                    }
                                  >
                                    {t("menuItems.report")}
                                    <ExpandMoreIcon />
                                  </Button>
                                  <Button
                                    ref={anchorRefMasters}
                                    aria-controls={
                                      openMasters
                                        ? "menu-list-grow-masters"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onClick={handleMastersMenuToggle}
                                    className={
                                      "MenuItem" +
                                      (isMasterPage()
                                        ? " ActiveMenuItem"
                                        : "")
                                    }
                                  >
                                    {t("menuItems.masters")}
                                    <ExpandMoreIcon />
                                  </Button>
                                </>
                              )}
                              <Button
                                ref={anchorRef}
                                aria-controls={
                                  open
                                    ? "menu-list-grow-guide"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onClick={handleCommonMenuToggle}
                                className="MenuItem"
                              >
                                {t("menuItems.guide")}
                                <ExpandMoreIcon />
                              </Button>
                            </Typography>

                            {/* SD admin Reports Menu Popper starts */}
                            <Popper
                              open={openReports}
                              anchorEl={anchorRefReports.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleCloseReports}
                                    >
                                      <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow-guide"
                                        className="SubMenuBlock"
                                        onKeyDown={handleListKeyDown}
                                      >
                                        <MenuItem
                                          onClick={handleCloseReports}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              REPORT
                                            )}
                                          >
                                            {t("menuItems.SCMMReports")}
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleCloseReports}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              VC_REPORT
                                            )}
                                          >
                                            {t("menuItems.VCStatistics")}
                                          </Typography>
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                            {/* SD admin Reports Menu Popper ends */}

                            {/* Masters Menu Popper starts */}
                            <Popper
                              open={openMasters}
                              anchorEl={anchorRefMasters.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleCloseMasters}
                                    >
                                      <MenuList
                                        autoFocusItem={openMasters}
                                        id="menu-list-grow-masters"
                                        className="SubMenuBlock"
                                        onKeyDown={
                                          handleListKeyDownMasters
                                        }
                                      >
                                        <MenuItem
                                          onClick={handleCloseMasters}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              MATURITY_INDEX
                                            )}
                                          >
                                            {t("common.maturityIndex")}
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleCloseMasters}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(QUESTION)}
                                          >
                                            {t(
                                              "menuItems.indicatorSet"
                                            )}
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleCloseMasters}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              AREA_WORK_TOOL
                                            )}
                                          >
                                            {t("menuItems.tools")}
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleCloseMasters}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              AOW_MANAGEMENT
                                            )}
                                          >
                                            {t(
                                              "menuItems.aowManagement"
                                            )}
                                          </Typography>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleCloseMasters}
                                        >
                                          <Typography
                                            component="span"
                                            onClick={goToPath(
                                              COUNTRY_HIERARCHY
                                            )}
                                          >
                                            {t(
                                              "menuItems.countryAreaHierarchy"
                                            )}
                                          </Typography>
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                            {/* Masters Menu Popper ends */}
                            {/* Common Guide Menu Popper starts */}
                            <Popper
                              open={open}
                              anchorEl={anchorRef.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleClose}
                                    >
                                      <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow-guide"
                                        className="SubMenuBlock"
                                        onKeyDown={handleListKeyDown}
                                      >
                                        <MenuItem onClick={handleClose}>
                                          <Link
                                            href={guideName}
                                            target="_blank"
                                          >
                                            {t(
                                              "menuItems.instructions"
                                            )}
                                          </Link>
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                            {/* Common Guide Menu Popper ends */}
                          </>
                        ) : (
                          <Typography
                            component="div"
                            className="Menubar"
                          >
                            <Typography
                              component="span"
                              className={
                                "MenuItem" +
                                (currentRoute === HOME_PATH
                                  ? " ActiveMenuItem"
                                  : "")
                              }
                              onClick={goToPath(HOME_PATH)}
                            >
                              {t("menuItems.home")}
                            </Typography>
                            <Typography
                              component="span"
                              className={
                                "MenuItem" +
                                (currentRoute === ABOUT_US
                                  ? " ActiveMenuItem"
                                  : "")
                              }
                              onClick={goToPath(ABOUT_US)}
                            >
                              {t("menuItems.about")}
                            </Typography>
                            <Typography
                              component="span"
                              className={
                                "MenuItem" +
                                (currentRoute === HOW_TO_JOIN
                                  ? " ActiveMenuItem"
                                  : "")
                              }
                              onClick={goToPath(HOW_TO_JOIN)}
                            >
                              {t("menuItems.howToJoin")}
                            </Typography>
                          </Typography>
                        )}
                      </CustomNavBarSdAdmin>
                    </Grid>
                  </Grid>
                </CustomNavBar>
              )}
              {selectedRole !== USER.ROLE.SD_ADMIN && (
                <CustomNavBar>
                  <Grid container alignItems="center" justify="center">
                    <Grid item>
                      {auth ? (
                        <>
                          <Typography component="div" className="Menubar">
                            {generateMenus()}
                            {selectedRole === USER.ROLE.SD_ADMIN && (
                              <Button
                                ref={anchorRefMasters}
                                aria-controls={
                                  openMasters
                                    ? "menu-list-grow-masters"
                                    : undefined
                                }
                                aria-haspopup="true"
                                onClick={handleMastersMenuToggle}
                                className={
                                  "MenuItem" +
                                  (isMasterPage() ? " ActiveMenuItem" : "")
                                }
                              >
                                {t("menuItems.masters")}
                                <ExpandMoreIcon />
                              </Button>
                            )}
                            {selectedRole !== USER.ROLE.VC_ASSESSOR &&
                              <Button
                                ref={anchorRef}
                                aria-controls={
                                  open ? "menu-list-grow-guide" : undefined
                                }
                                aria-haspopup="true"
                                onClick={handleCommonMenuToggle}
                                className="MenuItem"
                              >
                                {t("menuItems.guide")}
                                <ExpandMoreIcon />
                              </Button>}
                          </Typography>

                          {/* Masters Menu Popper starts */}
                          <Popper
                            open={openMasters}
                            anchorEl={anchorRefMasters.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener
                                    onClickAway={handleCloseMasters}
                                  >
                                    <MenuList
                                      autoFocusItem={openMasters}
                                      id="menu-list-grow-masters"
                                      className="SubMenuBlock"
                                      onKeyDown={handleListKeyDownMasters}
                                    >
                                      <MenuItem onClick={handleCloseMasters}>
                                        <Typography
                                          component="span"
                                          onClick={goToPath(MATURITY_INDEX)}
                                        >
                                          {t("common.maturityIndex")}
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem onClick={handleCloseMasters}>
                                        <Typography
                                          component="span"
                                          onClick={goToPath(QUESTION)}
                                        >
                                          {t("menuItems.indicatorSet")}
                                        </Typography>
                                      </MenuItem>
                                      <MenuItem onClick={handleCloseMasters}>
                                        <Typography
                                          component="span"
                                          onClick={goToPath(AREA_WORK_TOOL)}
                                        >
                                          {t("menuItems.tools")}
                                        </Typography>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                          {/* Masters Menu Popper ends */}
                          {/* Common Guide Menu Popper starts */}
                          <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow-guide"
                                      className="SubMenuBlock"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <MenuItem onClick={handleClose}>
                                        <Link href={guideName} target="_blank">
                                          {t("menuItems.instructions")}
                                        </Link>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                          {/* Common Guide Menu Popper ends */}
                        </>
                      ) : (
                        <Typography component="div" className="Menubar">
                          <Typography
                            component="span"
                            className={
                              "MenuItem" +
                              (currentRoute === HOME_PATH
                                ? " ActiveMenuItem"
                                : "")
                            }
                            onClick={goToPath(HOME_PATH)}
                          >
                            {t("menuItems.home")}
                          </Typography>
                          <Typography
                            component="span"
                            className={
                              "MenuItem" +
                              (currentRoute === ABOUT_US
                                ? " ActiveMenuItem"
                                : "")
                            }
                            onClick={goToPath(ABOUT_US)}
                          >
                            {t("menuItems.about")}
                          </Typography>
                          <Typography
                            component="span"
                            className={
                              "MenuItem" +
                              (currentRoute === HOW_TO_JOIN
                                ? " ActiveMenuItem"
                                : "")
                            }
                            onClick={goToPath(HOW_TO_JOIN)}
                          >
                            {t("menuItems.howToJoin")}
                          </Typography>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </CustomNavBar>
              )}
            </div>
          ) : (
            <>
              {auth ? (
                <OutsideAlerter>
                  <Account
                    loggedInUser={loggedInUser}
                    logout={logout}
                    selectedRole={selectedRole}
                    selectedRegion={selectedRegion}
                    selectedCountry={selectedCountry}
                  />
                </OutsideAlerter>
              ) : (
                <>
                  <Button
                    color="inherit"
                    onClick={login}
                    className="text-capitalize"
                  >
                    <AccountCircleOutlinedIcon className="marginR10 arMarginL10R0" />
                    {t("home.login")}
                  </Button>
                </>
              )}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default HeaderComponent;
