import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  actionHeaderSetLanguageData,
  actionHeaderUserDropDownShow,
} from "../actions";
import { StyledSelect } from "../styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useEffect } from "react";

function LanguagSelectionComponent(props: any) {
  const dispatch = useDispatch();
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );

  
  const allLanguages: any = (window as any).allLanguages;
  useEffect(() => {
    if (languageSelected !== null) {
      if (languageSelected === 'ar') {
        document.body.classList.add('ArabicStyle');
      }
      else{
        document.body.classList.remove('ArabicStyle');
      }
    }
  }, [languageSelected]);
  const handleChangeLanguage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    localStorage.setItem("language", event.target.value as string);
    dispatch(actionHeaderSetLanguageData(event.target.value as string));
    dispatch(actionHeaderUserDropDownShow(false));
  };

  return (
    <>
      <StyledSelect value={languageSelected} onChange={handleChangeLanguage}>
        {allLanguages &&
          allLanguages.length &&
          allLanguages.map((language: any) => {
            return (
              <MenuItem
                key={language.id}
                value={language.id}
                className="LanguageSelect font-size-14"
              >
                {language.name}
              </MenuItem>
            );
          })}
      </StyledSelect>
    </>
  );
}

export default LanguagSelectionComponent;
