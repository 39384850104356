import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import QuestionFormComponent from "../question-form/index";
import QuestionColumnDrgableContainer from "../question-drag-drop/question-column-dragable-container";
import { DragDropContext } from "react-beautiful-dnd";
import { ConfirmDialog } from "../../../components/common/confirm-dialog.component";
import {
  TabContainer,
  FormToolbar,
  EditAssessmentAppBar,
  BoxShadowWrapper,
  EditIconCustom,
  IndicatorsSubHeader,
  AssessmentTooltipDetails,
  InfoOutlinedIconCustom,
  HtmlTooltip,
  IndicatorViewTable,
  QuestionDataContainer,
  AddIconCustom,
} from "../../../styles/common";
import QuestionAreaLevelCommentFormComponent from "../question-area-level-comment-form";
import {
  actionGetQuestionByQuestionIdDetail,
  actionSwipeQuestionSequenceDataDetail,
  actionDeleteQuestionDataDetails,
  actionGetQuestionAreaLevelCommentByIdDataDetail,
} from "../actions";
import Grid from "@material-ui/core/Grid";
import { TabPanelInner } from "./styles";
import Typography from "@material-ui/core/Typography";
import { LEVEL_DESCRIPTION } from "constants/data";
import { Tooltip } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabValue: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box className="nopadding">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function QuestionTabsComponent(props: any) {
  const {
    questionBySetIdAreaOfWorkTabData,
    questionBySetIdAreaOfWorkData,
    areaOfWorkId,
    questionSetId,
    isHistoryMode,
    closeToasterFun,
    userId,
    supplyChainName,
    scopeName,
    currentIndicatorTopicId,
    topicName,
    aowName,
    subAOWName
  } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [tabData, setTabData] = useState([]);
  const [tabContainerData, setTabContainerData] = useState([]);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [isEditAreaLevelComment, setIsEditAreaLevelComment] = useState(false);
  const [isAreaLevelCommentEmpty, setIsAreaLevelCommentEmpty] = useState(false);
  let dialogTitle = t("indicators.createNewEditIndicator");
  let dialogDescrition = t("indicators.dialogIndicatorDesc");
  let levelType = "";
  let tabContainData = [];
  let questionIdsData: any = [];
  useEffect(() => {
    if (
      questionBySetIdAreaOfWorkTabData &&
      questionBySetIdAreaOfWorkTabData.hasOwnProperty("dynamicColumnHeaderList")
    ) {
      const tempTabs = questionBySetIdAreaOfWorkTabData.dynamicColumnHeaderList.filter(
        (column: any): any => {
          if (column.field.match("level")) {
            return true;
          }
        }
      );
      setTabData(tempTabs);
    }
  }, [questionBySetIdAreaOfWorkTabData]);

  useEffect(() => {
    if (questionBySetIdAreaOfWorkData && questionBySetIdAreaOfWorkData.length) {
      levelType = `${"Level" + (tabValue + 1)}`;
      tabContainData = questionBySetIdAreaOfWorkData.filter(
        (a: any) => a.field === levelType
      );
      setTabContainerData(tabContainData);
    }
  }, [questionBySetIdAreaOfWorkData]);

  const generateTabs = () => {
    return tabData.map((item: any, index: any) => {
      return (
        <Tab
          key={index}
          label={handleTabLabel(item)}
          {...a11yProps({ index })}
        />
      );
    });
  };
  const createToolTip = (val: any) => {
    const value = val.toLowerCase();
    const description: any = LEVEL_DESCRIPTION[value];
    const num = value.match(/\d+/g);
    const levelCls = `maturity_no ${value}`;
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <AssessmentTooltipDetails>
              <Typography component="div" className="maturity-inner">
                <Typography component="div" className={levelCls}>
                  <Typography component="p">
                    {t("level.maturityLevel")}
                  </Typography>
                  <Typography component="h1">{num}</Typography>
                </Typography>
                <Typography component="div" className="maturity_details">
                  <Typography component="div" className="maturity_title">
                    {t("level.resultInterpretation")}
                  </Typography>
                  <Typography component="div" className="maturity_desc">
                    {t("level." + description)}
                  </Typography>
                </Typography>
              </Typography>
            </AssessmentTooltipDetails>
          </React.Fragment>
        }
      >
        <InfoOutlinedIconCustom />
      </HtmlTooltip>
    );
  };

  const handleTabLabel = (item: any) => {
    const titleText = `level.${item.title.toLowerCase().replace(/\s+/g, "")}`;
    const label = t(titleText);
    return (
      <>
        <Typography component="span" className="level-label">
          {label}
        </Typography>
        <Typography component="span" className="level-label toolTipIcon">
          {createToolTip(item.field)}
        </Typography>
      </>
    );
  };

  const hadleEditRowData = (rowData: any) => {
    setIsEditAreaLevelComment(false);
    dispatch(actionGetQuestionByQuestionIdDetail(rowData.questionId));
    setDialogOpen(true);
    setIsEditQuestion(true);
  };

  const handlerOpenConfirmDialogDelete = (event: any, value: any) => {
    const questionId = value.questionId;
    value.Id = questionId;
    value.CreatedBy = userId;
    value.currentIndicatorTopicId = currentIndicatorTopicId;
    value.questionSetId = questionSetId

    setDeleteRowData(value);
    setOpenConfirmDialog(true);
  };
  const handlerCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const handlerDeleteRowData = () => {
    setOpenConfirmDialog(true);
    dispatch(actionDeleteQuestionDataDetails(deleteRowData));
    handlerCloseConfirmDialog();
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const begin = tabContainerData[0][source.droppableId];
    const end = tabContainerData[0][destination.droppableId];
    if (begin === end) {
      var questionIds: any = [];
      tabContainerData.map((question: any, index: any) => {
        question.questionData.map((que: any) => {
          if (
            que.sequence === source.index ||
            que.sequence === destination.index
          ) {
            questionIds.push(que.questionId);
          }
        });
      });
      if (!isHistoryMode) {
        const formData = {
          id1: questionIds[0],
          id2: questionIds[1],
          questionSetId: questionSetId,
          areaOfWorkId: areaOfWorkId,
          updatedBy: userId,
        };
        dispatch(actionSwipeQuestionSequenceDataDetail(formData));
      }
      return;
    } else {
      return;
    }
  };

  const showTableHistoryQuestion = (questionList: any) => {
    if (questionList && questionList.questionData.length) {
      return questionList.questionData.map((question: any, index: any) => {
        return (
          <Typography component="tr">
            <Typography component="td">
              {index + 1}. {question.questionName}
            </Typography>
          </Typography>
        );
      });
    }
  };

  const handleEditAreaLevelComment = (
    areaLevelCommentId: any,
    areaLevelComment: any
  ) => {
    setIsEditAreaLevelComment(true);
    setIsAreaLevelCommentEmpty(areaLevelComment ? false : true);
    setDialogOpen(true);
    if (areaLevelCommentId) {
      dispatch(
        actionGetQuestionAreaLevelCommentByIdDataDetail(areaLevelCommentId)
      );
    }
  };

  const generateTabsPanel = (): any => {
    if (tabData && tabData.length) {
      return tabData.map((item: any, index: any) => {
        return (
          <TabPanel key={index} tabValue={tabValue} index={index}>
            {tabContainerData.length &&
              tabContainerData.map((question: any, index: any) => {
                questionIdsData = question.questionData.map((que: any) => {
                  return que;
                });
                return (
                  question && (
                    <Typography component="div" key={"div" + index}>
                      <FormToolbar>
                        <Typography
                          component="div"
                          key={"divdes" + question.fielId}
                        >
                          <Grid
                            container
                            alignItems="flex-start"
                            justify="space-between"
                            wrap="nowrap"
                          >
                            <Grid
                              item
                              className={
                                !isHistoryMode ? "width95" : "full-width"
                              }
                            >
                              <Typography
                                component="span"
                                className="font-size-14"
                              >
                                {question.description}
                              </Typography>
                            </Grid>
                            <Grid item className="text-align-right padding10">
                              {!isHistoryMode && (
                                <Tooltip title={
                                  `${question.description
                                    ? t("indicators.editTitle")
                                    : t("indicators.addTitle")
                                  }`
                                }>
                                  <Typography
                                    component="span"
                                    aria-label="edit"
                                    onClick={() =>
                                      handleEditAreaLevelComment(
                                        question.descriptionId,
                                        question.description
                                      )
                                    }
                                  >

                                    {question.description ? (
                                      <EditIconCustom className="arRotate270" />
                                    ) : (
                                      <AddIconCustom />
                                    )}
                                  </Typography>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormToolbar>
                      {!isHistoryMode && (
                        <>
                          <Typography component="div" className="paddingT15">
                            <QuestionDataContainer>
                              <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    component="span"
                                    className="font-size-14 font-weight-medium"
                                  >
                                    {t("common.indicator")}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => showQuestionAddForm()}
                                    disabled={question.questionData.length === 1}
                                  >
                                    {t("indicators.addIndicator")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </QuestionDataContainer>
                          </Typography>

                          <TabPanelInner>
                            <DragDropContext onDragEnd={onDragEnd}>
                              <TabContainer>
                                <QuestionColumnDrgableContainer
                                  key={question.fielId}
                                  column={"questionData"}
                                  questionIdsData={questionIdsData}
                                  hadleEditRowData={hadleEditRowData}
                                  handlerOpenConfirmDialogDelete={
                                    handlerOpenConfirmDialogDelete
                                  }
                                  isHistoryMode={isHistoryMode}
                                />
                              </TabContainer>
                            </DragDropContext>
                          </TabPanelInner>
                        </>
                      )}
                      {isHistoryMode && (
                        <TabPanelInner>
                          <TabContainer>
                            <IndicatorViewTable>
                              <Typography component="thead">
                                <Typography component="th">
                                  {t("common.indicator")}
                                </Typography>
                              </Typography>
                              <Typography component="tbody">
                                {showTableHistoryQuestion(question)}
                              </Typography>
                            </IndicatorViewTable>
                          </TabContainer>
                        </TabPanelInner>
                      )}
                    </Typography>
                  )
                );
              })}
          </TabPanel>
        );
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    levelType = `${"Level" + (newValue + 1)}`;
    tabContainData = questionBySetIdAreaOfWorkData.filter(
      (a: any) => a.field === levelType
    );
    setTabContainerData(tabContainData);
  };

  const showQuestionAddForm = () => {
    setIsEditAreaLevelComment(false);
    dispatch(actionGetQuestionByQuestionIdDetail("-1"));
    setIsEditQuestion(false);
    setDialogOpen(true);
  };
  const dialogClose = () => {
    setDialogOpen(false);
    setIsEditQuestion(false);
    setIsEditAreaLevelComment(false);
  };
  return (
    <BoxShadowWrapper className="padding20">
      <ConfirmDialog
        open={openConfirmDialog}
        close={handlerCloseConfirmDialog}
        title={t("indicators.areYouSureDelete")}
        content=""
        ok={handlerDeleteRowData}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      />
      <IndicatorsSubHeader>
        <Grid container alignItems="center" justify="space-between" wrap="nowrap" spacing={2} className="paddingB20">
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.areaOfWork")}: `}
            </Typography>
            <Typography component="span">{aowName}</Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.subAreaOfWork")}: `}
            </Typography>
            <Typography component="span">{subAOWName}</Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.topic")}: `}
            </Typography>
            <Typography component="span">{topicName}</Typography>
          </Grid>
          <Grid item>
            <Typography component="strong" className="strong-text">
              {`${t("common.program")}: `}
            </Typography>
            <Typography component="span">{supplyChainName}</Typography>
          </Grid>
          {
            scopeName !== '' &&
            (<Grid item>
              <Typography component="strong" className="strong-text">
                {`${t("common.scope")}: `}
              </Typography>
              <Typography component="span">{scopeName}</Typography>
            </Grid>)
          }
        </Grid>
      </IndicatorsSubHeader>
      <EditAssessmentAppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          {generateTabs()}
        </Tabs>
      </EditAssessmentAppBar>
      {generateTabsPanel()}

      {isEditAreaLevelComment ? (
        <QuestionAreaLevelCommentFormComponent
          isEditAreaLevelComment={isEditAreaLevelComment}
          dialogClose={dialogClose}
          tabId={tabValue + 1}
          areaOfWorkId={areaOfWorkId}
          questionSetId={questionSetId}
          questionBySetIdAreaOfWorkData={questionBySetIdAreaOfWorkData}
          closeToasterFun={closeToasterFun}
          userId={userId}
          dialogTitle={dialogTitle}
          dialogOpen={dialogOpen}
          dialogDescrition={dialogDescrition}
          isAreaLevelCommentEmpty={isAreaLevelCommentEmpty}
          currentIndicatorTopicId={currentIndicatorTopicId}
        ></QuestionAreaLevelCommentFormComponent>
      ) : (
        <QuestionFormComponent
          isEditQuestion={isEditQuestion}
          dialogClose={dialogClose}
          tabId={tabValue + 1}
          areaOfWorkId={areaOfWorkId}
          questionSetId={questionSetId}
          questionBySetIdAreaOfWorkData={questionBySetIdAreaOfWorkData}
          closeToasterFun={closeToasterFun}
          userId={userId}
          dialogTitle={dialogTitle}
          dialogOpen={dialogOpen}
          dialogDescrition={dialogDescrition}
          currentIndicatorTopicId={currentIndicatorTopicId}
        ></QuestionFormComponent>
      )}
    </BoxShadowWrapper>
  );
}
export default QuestionTabsComponent;
