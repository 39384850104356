import { Box, Button, Container, Grid, Tooltip, Typography } from "@material-ui/core";
import FormDatePicker from "../../components/form-controls/datepicker";
import FormSelect from "../../components/form-controls/select";
import { AssessmentCountList, ASSESSMENT, ASSESSMENT_TYPE_CONST } from "../../constants/data";
import { actionFetchAssessmentReportData } from "../../containers/assessment-create/actions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../store/common/actions";
import {
  AssessmentSubHeader,
  BoxShadowWrapper,
  DialogButtonPrimary,
  DownloadIconCustom,
} from "../../styles/common";
import {
  calculateMaturityModelIndex,
  calculateMaxWeightedScore,
} from "../../utils/data";
import * as yup from "yup";
import ColumnChartComponent from "components/common/column-chart.component";
import ReportTableComponent from "components/common/report-table.component";
import { actionExportReportTableData } from "containers/master/actions";

function AssessmentReportComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userSelectedSettingData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );
  const assessmentReportList = useSelector(
    (state: any) => state.assesmentCreate.assessmentReportData
  );
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const maturityIndexData = useSelector(
    (state: any) => state.masterForm.mastermaturityIndexData
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );

  const listProgram = useSelector((state: any) => state.common.listProgram);
  const listScope = useSelector((state: any) => state.common.listScope);
  const allScopeData: any = (window as any).allScopeData;
  const allPrograms: any = (window as any).allPrograms;

  const validationSchema = yup.object().shape({
    supplyChain: yup.string().required(t("validationMessage.programSelect")),
    scope: yup.string().required(t("validationMessage.scopeSelect")),
    dateFrom: yup.date().typeError(t("validationMessage.dateValid")),
    dateTo: yup
      .date()
      .typeError(t("validationMessage.dateValid"))
      .min(yup.ref("dateFrom"), t("validationMessage.dateRange")),
  });
  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { handleSubmit, errors } = methods;
  const { setValue, getValues } = methods;
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [chartData, setChartData] = useState([]);
  const [keyCount, setKeyCount] = useState(0);
  const [show, setShow] = useState(true);
  const [selectedsupplyChainData, setselectedsupplyChainData] = useState();
  const [selectedScope, setSelectedScope] = useState();
  const [selectedScopeId, setSelectedScopeId] = useState();
  const [labelAvgMMIndex, setLabelAvgMMIndex]: any = useState(0);
  const [programList, setProgramList]: any = useState([]);
  const [scopeList, setScopeList]: any = useState([]);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [isProgramDisabled, setProgramDisabled] = useState(false);
  const [isScopeDisabled, setScopeDisabled] = useState(false);

  let areaOfWorks: any = [];
  let areaWorkDataList: any = [];
  let chartHeaders: any = [];
  let assessmentList: any = [];
  let listOfScope: any = [];

  let allOption: any = {
    id: "-1",
    label: t("common.all"),
  };

  const showHideChart = (request: boolean) => {
    setShow(request);
  };

  const fetchAssesmeDataByCountry = (userSelectedData: any) => {
    const userCountry = userSelectedData.userSelectedCountry;
    setCountryList(userCountry);
    const suppyChain: any = allPrograms.length && assessmentTypeFilter ? allPrograms[0].id : programList[0].id;
    const scope: any = assessmentTypeFilter ? allScopeData[0].id : scopeList[0].id;

    setValue("supplyChain", suppyChain);
    setValue("scope", scope);
    setValue("dateFrom", moment().subtract(15, "d").format("YYYY-MM-DD"));
    setValue("dateTo", moment().format("YYYY-MM-DD"));
    setValue("noAssessment", AssessmentCountList[0].id);

    setFirstTimeCall(false);
  };

  useEffect(() => {
    if (assessmentReportList && assessmentReportList.length) {
      populateChart();
      maturityIndexCalculated();
    } else {
      setChartData([]);
    }
  }, [assessmentReportList]);

  useEffect(() => {
    if (chartData.length) {
      showHideChart(true);
      dispatch(setLoaderVisibility(false));
    } else {
      showHideChart(false);
    }
  }, [chartData]);

  useEffect(() => {
    if (
      userSelectedSettingData &&
      programList.length &&
      scopeList.length
    ) {
      fetchAssesmeDataByCountry(userSelectedSettingData.userSelectedData);
    } else if (localStorage.getItem("userSelectedData")) {
      const userSettingData: any = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
      );
      if (
        programList.length &&
        scopeList.length &&
        userSettingData.hasOwnProperty("userSelectedCountry")
      ) {
        fetchAssesmeDataByCountry(userSettingData);
      }
    }
  }, [programList, scopeList, userSelectedSettingData, assessmentTypeFilter]);

  useEffect(() => {
    if (listProgram.length && listScope.length) {
      listOfScope.push(allOption);
      listOfScope = [...listOfScope, ...listScope];

      setScopeList(listOfScope);
      getProgramList(listProgram, listScope, assessmentTypeFilter);
    }
  }, [listProgram, listScope]);

  const getProgramList = (listProgram: any, listScope: any, assessmentTypeFilter: Boolean) => {
    if (listProgram.length && listScope.length) {
      let programs: any = [...listProgram];
      programs.unshift(allOption);

      if (!assessmentTypeFilter)
        programs = programs.filter((l: any) => allPrograms.length && l.id !== allPrograms[0].id);
      setProgramList(programs);
    }
  }

  useEffect(() => {
    if (programList.length && scopeList.length) {
      if (firstTimeCall === false) {
        callFunctionOnLanguageChange();
      }
    }
  }, [languageSelected]);

  useEffect(() => {
    getProgramList(listProgram, listScope, assessmentTypeFilter);
    let supplyChain: any = "-1";
    let scope: any = "-1"
    if (!assessmentTypeFilter) {
      // noraml type
      setProgramDisabled(false);
      setScopeDisabled(false);
    } else {
      supplyChain = allPrograms.length ? allPrograms[0].id : '';
      scope = allScopeData[0].id
      setProgramDisabled(true);
      setScopeDisabled(true);
    }
    setValue("supplyChain", supplyChain);
    setValue("scope", scope);
  }, [assessmentTypeFilter])

  const callFunctionOnLanguageChange = () => {
    const programId = getValues("supplyChain");
    const scopeId = getValues("scope");
    const dateFrom = getValues("dateFrom");
    const dateTo = getValues("dateTo");
    const noAssessment = getValues("noAssessment");
    const assesmentRequestData = {
      supplyChain: programId,
      scope: scopeId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      noAssessment: noAssessment,
      countrys: countryList,
      status: ASSESSMENT.STATUS.VALIDATED,
    };
    getReportData(assesmentRequestData, countryList);
  };

  const onSubmit = (data: any) => {
    getReportData(data, countryList);
  };

  const getReportData = (data: any, countryIdList: any) => {
    const assesmentRequestData = {
      programId: data.supplyChain,
      scopeId: data.scope,
      dateFrom: moment(data.dateFrom).format("DD-MM-YYYY"),
      dateTo: moment(data.dateTo).format("DD-MM-YYYY"),
      noAssessment: data.noAssessment,
      countrys: countryIdList,
      status: ASSESSMENT.STATUS.VALIDATED,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    fetchAssessmentReport(assesmentRequestData);
  };

  const fetchAssessmentReport = (data: any) => {
    if (data) {
      dispatch(actionFetchAssessmentReportData(data));
    }
    programList.map((x: any) => {
      if (x.id === data.programId) {
        setselectedsupplyChainData(x.label);
      }
    });
    scopeList.map((item: any) => {
      if (item.id === data.scopeId) {
        setSelectedScope(item.label);
        setSelectedScopeId(item.id);
      }
    });

    if (data.countrys && data.countrys.length) {
      let country: any = listOrgWiseCountry.filter(
        (m: any) => m.id === data.countrys[0]
      );
      setSelectedCountry(country[0].label);
    }
  };

  const maturityIndexCalculated = () => {
    let bindMaturityIndexCalcTable: any = [];
    const scopeId = getValues("scope");
    if (
      assessmentReportList.length &&
      scopeId === allScopeData[0].id.toString()
    ) {
      assessmentReportList.map((assessments: any) => {
        let bindMaturityTableData: any = [];
        let tempTotalMaxWightedScore = 0;

        assessments.assessmentChartList.map((item: any, index: any) => {
          maturityIndexData.filter((maturityRow: any) => {
            if (maturityRow.areaId === item.areaOfWorkId && item.currentScore != 0) {
              maturityRow.mmScore = item.currentScore;
              maturityRow.maxWeightScore = calculateMaxWeightedScore(
                maturityRow.maxScore,
                maturityRow.weight
              );
              tempTotalMaxWightedScore += maturityRow.maxWeightScore;
              bindMaturityTableData.push(maturityRow);
            }
          });
        });
        if (bindMaturityTableData.length) {
          let maturitIndexValue: any = 0;
          bindMaturityTableData.map((item: any) => {
            maturitIndexValue += calculateMaturityModelIndex(
              item.mmScore,
              item.weight,
              tempTotalMaxWightedScore
            );
          });
          bindMaturityIndexCalcTable.push(Number(maturitIndexValue.toFixed(1)));
        }
      });
      if (bindMaturityIndexCalcTable.length) {
        let avgMaturitIndexValue: any = 0;
        bindMaturityIndexCalcTable.map((item: any) => {
          avgMaturitIndexValue += Number(item);
        });

        avgMaturitIndexValue =
          avgMaturitIndexValue / bindMaturityIndexCalcTable.length;
        setLabelAvgMMIndex(avgMaturitIndexValue.toFixed(1));
      } else {
        setLabelAvgMMIndex(0);
      }
    }
  };

  const populateChart = () => {
    let chartDataList: any = [];
    let counter: any;
    setChartData([]);
    counter = assessmentReportList.length;
    setKeyCount(counter);
    chartHeaders.push("areaOfWork");
    assessmentReportList[0].assessmentChartList.map((item: any) => {
      areaOfWorks.push(item.areaOfWork);
    });
    for (let i = 0; i < assessmentReportList.length; i++) {
      chartHeaders.push(assessmentReportList[i].assessmentCode);
      chartHeaders.push({ role: "annotation" });
      chartHeaders.push({ role: "tooltip", type: "string", p: { html: true } })
      assessmentList.push(assessmentReportList[i].assessmentCode);
    }
    counter++;
    chartDataList.push(chartHeaders);
    areaOfWorks.forEach((x: any) => {
      areaWorkDataList = [];
      areaWorkDataList.push(x);
      assessmentReportList.map((items: any) => {
        for (let i = 0; items.assessmentChartList; i++) {
          if (x == items.assessmentChartList[i].areaOfWork) {
            let currentScore: any = 0;
            if (items.assessmentChartList[i].currentScore > 0) {
              currentScore = Number(items.assessmentChartList[i].currentScore.toFixed(1));
            }
            areaWorkDataList.push(currentScore);
            areaWorkDataList.push(
              items.assessmentChartList[i].isNA ? `${t("common.na")}` : `${currentScore}`
            );
            // custom tooltip with subarea of works
            let str = "";
            items.assessmentChartList[i].subAreaOfWorks.forEach((sub: any) => {
              let subAreaScore: any = sub.isNA ? `${t("common.na")}` : 0;
              if (sub.score > 0) {
                subAreaScore = Number(sub.score.toFixed(1));
              }
              str += `<div class="tlp-saow">${sub.subAreaOfWork}: ${subAreaScore}</div>`
            })
            areaWorkDataList.push(
              `<div class="report-tooltip">
                <div class="tlp-asscode">${items.assessmentCode}</div>
                <div class="tlp-aow">${items.assessmentChartList[i].areaOfWork}: ${currentScore}</div>
                <div class="tlp-saow-outer">${str}</div>
                </div> `
            );
            break;
          }
        }
      });
      chartDataList.push(areaWorkDataList);
    });
    setChartData(chartDataList);
  };

  const exportReports = () => {
    // this function will used for export excel.
    let assesmentRequestData: any = {};
    const programId = getValues("supplyChain");
    const scopeId = getValues("scope");
    const dateFrom = getValues("dateFrom");
    const dateTo = getValues("dateTo");
    const noAssessment = getValues("noAssessment");
    const region = getValues("region");

    assesmentRequestData = {
      programId,
      scopeId,
      dateFrom: moment(dateFrom).format("DD-MM-YYYY"),
      dateTo: moment(dateTo).format("DD-MM-YYYY"),
      noAssessment: noAssessment,
      countries: countryList.length > 0 ? countryList : [],
      region: region || "",
      status: ASSESSMENT.STATUS.VALIDATED,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionExportReportTableData(assesmentRequestData));
  }

  return (
    <Container maxWidth={"xl"}>
      <FormContext {...methods}>
        <>
          <BoxShadowWrapper className="margin-top-20">
            <Grid container alignItems="center" justify="center">
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  className="padding16-24 margin-top-0"
                >
                  <Grid item sm={2}>
                    <FormSelect
                      name="supplyChain"
                      label={t("common.program")}
                      options={programList}
                      required={true}
                      errorobj={errors}
                      disabled={isProgramDisabled}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormSelect
                      name="scope"
                      label={t("common.scope")}
                      options={scopeList}
                      required={true}
                      errorobj={errors}
                      disabled={isScopeDisabled}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormSelect
                      name="noAssessment"
                      label={t("assessment.noOfAssessment")}
                      options={AssessmentCountList}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateFrom"
                      label={t("common.fromDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormDatePicker
                      disableFuture
                      autoOk={true}
                      name="dateTo"
                      label={t("common.toDate")}
                      required={true}
                      errorobj={errors}
                      variant="inline"
                      disableToolbar
                    />
                  </Grid>

                  <Grid item sm={2} className="text-center">
                    <DialogButtonPrimary
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t("setting.apply")}
                    </DialogButtonPrimary>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BoxShadowWrapper>
          {
            show ? (
              <>
                <Box pb={2}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Tooltip title={`${t("common.downloadReport")}`}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => exportReports()}
                        >
                          <DownloadIconCustom className="arMarginR0 arMarginL10" />
                          {t("common.exportToExcel")}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <BoxShadowWrapper>
                  <AssessmentSubHeader>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      className="padding20-15-0"
                    >
                      <Grid item>
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item className="region-info-text">
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="strong" className="strong-text"></Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="strong" className="strong-text">
                          {selectedsupplyChainData}
                          {"  |  "}
                          {selectedScope}
                          {"  |  "}
                          {selectedCountry}

                          {selectedScopeId === allScopeData[0].id.toString() && !assessmentTypeFilter
                            ? ` | ${t("common.overAllMaturityIndex") + labelAvgMMIndex} `
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AssessmentSubHeader>
                  <Box>
                    <ColumnChartComponent
                      keyCount={keyCount}
                      chartData={chartData}
                      yAxisScaleLength={6}
                    ></ColumnChartComponent>
                  </Box>
                  <Box pt={2}>
                    <ReportTableComponent
                      reportChartData={assessmentReportList}
                    />
                  </Box>
                </BoxShadowWrapper>
              </>
            ) : (
              <>
                <BoxShadowWrapper>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className="minHeight340"
                  >
                    <Grid item>
                      <Typography component="span">
                        {t("common.noDataAvailable")}
                      </Typography>
                    </Grid>
                  </Grid>
                </BoxShadowWrapper>
              </>
            )
          }
        </>
      </FormContext>
    </Container>
  );
}
export default AssessmentReportComponent;
