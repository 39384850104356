import api from "../../utils/api";
import {
  GET_QUESTION_SET_CODE,
  SAVE_QUESTION_SET_DATA,
  GET_QUESTION_SET_DATA,
  GET_QUESTION_BY_QUESTION_SET_ID,
  GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK,
  SAVE_QUESTION_DATA,
  SWIPE_QUESTION_SEQUENCE_DATA,
  DELETE_QUESTION_DATA,
  GET_QUESTION_BY_QUESTION_ID_QUESTION_DATA,
  COPY_QUESTION_SET_DATA,
  GET_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA,
  UPDATE_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA,
  DELETE_INDICATOR_SET_TOPIC,
  GET_NON_ASSIGNED_TOPICS,
  SAVE_NON_ASSIGNED_TOPICS,
  DELETE_QUESTION_SET_DATA,
} from "./constants";

/**
 * Function for getting some data
 */
export const fetchQuestionSetCode = () => {
  return api.get(`${GET_QUESTION_SET_CODE}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};
export const fetchQuestionSetDataDetails = (lookUp: any) => {
  return api.get(`${GET_QUESTION_SET_DATA}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveQuestionSetDataDetails = (formData: any) => {
  return api.post(`${SAVE_QUESTION_SET_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const copyQuestionSetDataDetails = (formData: any) => {
  return api.post(`${COPY_QUESTION_SET_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const deleteIndicatorSet = async (data: any) => {
  const response = await api.post(`${DELETE_QUESTION_SET_DATA}`, data);
  return response;
};


export const fetchQuestionByQuestionSetIdDetail = (questionSetId: any) => {
  return api.get(`${GET_QUESTION_BY_QUESTION_SET_ID}/${questionSetId}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchQuestionByQuestionSetIdAndAreaOfWorkDetail = (
  questionSetTopicId: any
) => {
  const url = `${GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK}?questionSetTopicId=${questionSetTopicId}`;
  return api.get(url).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const saveQuestionDataDetails = (formData: any) => {
  return api.post(`${SAVE_QUESTION_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const swipeQuestionSequenceDataDetails = (formData: any) => {
  return api.post(`${SWIPE_QUESTION_SEQUENCE_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const deleteQuestionDataDetails = (formData: any) => {
  return api.post(`${DELETE_QUESTION_DATA}`, formData).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchQuestionByQuestionIdDetail = (questionId: any) => {
  return api
    .get(`${GET_QUESTION_BY_QUESTION_ID_QUESTION_DATA}/${questionId}`)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getQuestionAreaLevelCommentByIdDataDetail = (lookUp: any) => {
  return api
    .get(`${GET_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA}/${lookUp}`)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const updateQuestionAreaLevelCommentByIdDataDetail = async (formData: any) => {
  const response = await api.post(`${UPDATE_QUESTION_AREA_LEVEL_COMMENT_BY_ID_DATA}`, formData);
  return response;
};

export const deleteIndicatorSetTopic = async (id: any) => {
  const response = await api.delete(`${DELETE_INDICATOR_SET_TOPIC}?id=${id}`);
  return response;
};

export const getNonAssignedTopicList = async (id: any) => {
  const response = await api.get(`${GET_NON_ASSIGNED_TOPICS}?questionSetId=${id}`);
  return response;
};

export const saveNonAssignedTopicList = async (formData: any) => {
  const response = await api.post(`${SAVE_NON_ASSIGNED_TOPICS}`, formData);
  return response;
};