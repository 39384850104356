import { ActionTypes } from "./constants";

export const actionFetchQuestionSetCode = () => ({
  payload: {},
  type: ActionTypes.QUESTION_GET_QUESTION_SET_CODE_DATA,
});

export const actionFetchQuestionSetCodeSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_QUESTION_SET_CODE_DATA_SUCCESS,
});

export const actionFetchQuestionSetDataDetails = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.QUESTION_GET_QUESTION_SET_DATA,
});

export const actionFetchQuestionSetDataDetailsSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_QUESTION_SET_DATA_SUCCESS,
});

export const actionGetQuestionSetDataByIdDetail = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.QUESTION_GET_QUESTION_SET_BY_ID_DATA,
});

export const actionGetQuestionSetDataByIdDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_QUESTION_SET_BY_ID_DATA_SUCCESS,
});

export const actionSaveQuestionSetDataDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_SAVE_QUESTION_SET_DATA,
});

export const actionSaveQuestionSetDataDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_SAVE_QUESTION_SET_DATA_SUCCESS,
});

export const actionCopyQuestionSetDataDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_COPY_QUESTION_SET_DATA,
});

export const actionCopyQuestionSetDataDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_COPY_QUESTION_SET_DATA_SUCCESS,
});

export const actionDeleteQuestionSetData = (data: any) => ({
  payload: { data },
  type: ActionTypes.QUESTION_DELETE_QUESTION_SET_DATA,
});

export const actionGetQuestionByQuestionSetIdDetail = (questionSetId: any) => ({
  payload: { questionSetId },
  type: ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA,
});

export const actionGetQuestionByQuestionSetIdDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_DATA_SUCCESS,
});

export const actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail = (data: any) => ({
  payload: { data },
  type:
    ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA,
});

export const actionGetQuestionByQuestionSetIdAndAreaOfWorkDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type:
    ActionTypes.QUESTION_GET_QUESTION_BY_QUESTION_SET_ID_AND_AREAOFWORK_DATA_SUCCESS,
});

export const actionSaveQuestionDataDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_SAVE_QUESTION_DATA,
});

export const actionSaveQuestionDataDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_SAVE_QUESTION_DATA_SUCCESS,
});

export const actionSwipeQuestionSequenceDataDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_SWIPE_QUESTION_SEQUENCE_DATA,
});

export const actionSwipeQuestionSequenceDataDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_SWIPE_QUESTION_SEQUENCE_DATA_SUCCESS,
});

export const actionDeleteQuestionDataDetails = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_DELETE_QUESTION_DATA,
});

export const actionDeleteQuestionDataDetailsSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_DELETE_QUESTION_DATA_SUCCESS,
});

export const actionGetQuestionByQuestionIdDetail = (questionId: any) => ({
  payload: { questionId },
  type: ActionTypes.QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA,
});

export const actionGetQuestionByQuestionIdDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_BY_QUESTION_ID_QUESTION_DATA_SUCCESS,
});

export const actionSaveQuestionCompletedDetail = (formData: any) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA,
});

export const actionSaveQuestionCompletedDetailSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_SAVE_QUESTION_SET_COMPLETED_DATA_SUCCESS,
});

export const actionGetQuestionAreaLevelCommentByIdDataDetail = (
  lookUp: any
) => ({
  payload: { lookUp },
  type: ActionTypes.QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA,
});

export const actionGetQuestionAreaLevelCommentByIdDataDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_GET_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS,
});

export const actionUpdateQuestionAreaLevelCommentByIdDetail = (
  formData: any
) => ({
  payload: { formData },
  type: ActionTypes.QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA,
});

export const actionUpdateQuestionAreaLevelCommentByIdDetailSuccess = (
  data: any
) => ({
  payload: {
    data,
  },
  type: ActionTypes.QUESTION_UPDATE_AREA_LEVEL_COMMENT_BY_ID_DATA_SUCCESS,
});

export const actionDeleteIndicatorSetTopic = (form: any) => ({
  payload: { form },
  type: ActionTypes.QUESTION_DELETE_INDICATOR_SET_TOPIC,
});

export const actionGetNonAssignedTopics = (data: any) => ({
  payload: { data },
  type: ActionTypes.QUESTION_GET_NON_ASSIGNED_TOPIC,
});

export const actionGetNonAssignedTopicsSuccess = (data: any) => ({
  payload: { data, },
  type: ActionTypes.QUESTION_GET_NON_ASSIGNED_TOPIC_SUCCESS,
});

export const actionSaveNonAssignedTopics = (data: any) => ({
  payload: { data },
  type: ActionTypes.QUESTION_SAVE_NON_ASSIGNED_TOPIC,
});

