import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  actionSetPageTitle,
  closeToaster,
  openToaster,
} from "../../store/common/actions";
import { useTranslation } from "react-i18next";
import {
  ArrowBackIosIconCustom,
  BoxShadowWrapper,
  SubHeaderWrapper,
  BreadcrumbsCustom,
  DialogButtonPrimary,
} from "../../styles/common";
import Typography from "@material-ui/core/Typography";
import QuestionSetTableComponent from "./question-set-table/index";
import QuestionSetFormComponent from "./question-set-form/index";
import QuestionBySetIdTableComponent from "./questionby-setId-table/index";
import QuestionTableComponent from "./question-table/index";
import { Toaster } from "../../components/common/toaster.component";
import Grid from "@material-ui/core/Grid";
import { ConfirmDialog } from "../../components/common/confirm-dialog.component";
import { getLocalDateFromMoment } from "../../utils/data";

import { actionHeaderUserSetting } from "../header/actions";
import {
  actionFetchQuestionSetDataDetails,
  actionGetQuestionSetDataByIdDetail,
  actionGetQuestionByQuestionSetIdDetail,
  actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail,
  actionSaveQuestionCompletedDetail,
  actionGetNonAssignedTopics,
} from "./actions";
import { Container, IconButton } from "@material-ui/core";
import { COMMON_CONSTANT, INDICATOR } from "constants/data";
import { AOW_MANAGEMENT } from "constants/api";
import { history } from "../../store/config";

function QuestionSetComponent(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const saveQuestionSetData = useSelector(
    (state: any) => state.question.saveQuestionSetData
  );
  const questionSetData = useSelector(
    (state: any) => state.question.questionSetData
  );
  const questionBySetIdData = useSelector(
    (state: any) => state.question.questionBySetIdData
  );
  const questionBySetIdAreaOfWorkData = useSelector(
    (state: any) => state.question.questionBySetIdAreaOfWorkData
  );
  const loginUserData = useSelector((state: any) => state.header.loginUserData);
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const formTitle = useSelector((state: any) => state.common.pageTitle);

  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const listProgram = useSelector((state: any) => state.common.listProgram);
  const listScope = useSelector((state: any) => state.common.listScope);
  const listAreaOfWork = useSelector((state: any) => state.common.listAreaOfWork);
  const listSubAreaOfWork = useSelector((state: any) => state.common.listSubAreaOfWork);

  const [
    questionBySetIdAreaOfWorkTabData,
    setQuestionBySetIdAreaOfWorkTabData,
  ] = useState([{}]);
  const [questionSetId, setQuestionSetId] = useState("");
  const [isEditQuestionSet, setIsEditQuestionSet] = useState(false);
  const [areaOfWorkId, setAreaOfWorkId] = useState(1);
  const [areaOfWork, setAreaOfWork] = useState("");
  const [questionSetGenCode, setQuestionSetGenCode] = useState("");
  const [showQueSetTable, setShowQueSetTable] = useState(true);
  const [isAddButtonShow, setIsAddButtonShow] = useState(true);
  const [isHistoryMode, setIsHistoryMode] = useState(false);
  const [showQueTable, setShowQueTable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const dialogTitle = t("indicators.addNewIndicatorSet");
  const [userId, setUserId] = useState("");
  const [scopeName, setScopeName] = useState("");
  const [supplyChainName, setSupplyChainName] = useState("");
  const [scopeId, setScopeId] = useState("");
  const [supplyChainId, setSupplyChainId] = useState("");
  const [questionSetAreaOfWorkId, setQuestionSetAreaOfWorkId] = useState("");
  const [questionSetAreaOfWork, setQuestionSetAreaOfWork] = useState("");
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [currentIndicatorTopicId, setCurrentIndicatorTopicId] = useState("");
  const [topicName, setTopicName]: any = useState("");
  const [subAOWName, setSubAOWName]: any = useState("");
  const [aowName, setAowName]: any = useState("");
  const [openAOWRedirectionDialog, setOpenAOWRedirectionDialog] = useState(false);

  useEffect(() => {
    defaultMethodCall();
  }, [dispatch]);

  const defaultMethodCall = () => {
    dispatch(actionSetPageTitle(t("indicators.indicatorSet")));
    dispatch(actionFetchQuestionSetDataDetails(`-1`));
    dispatch(actionHeaderUserSetting(true));
    setFirstTimeCall(false);
  };

  useEffect(() => {
    if (firstTimeCall === false) {
      defaultMethodCall();
      if (currentIndicatorTopicId !== "") {
        dispatch(
          actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(currentIndicatorTopicId)
        );
        dispatch(actionGetQuestionByQuestionSetIdDetail(questionSetId));
      }
    }
  }, [languageSelected]);

  useEffect(() => {
    if (
      saveQuestionSetData &&
      saveQuestionSetData.hasOwnProperty("isIndicatorSetConflict") &&
      saveQuestionSetData.isIndicatorSetConflict === false
    ) {
      setShowQueSetTable(false);
      setShowQueTable(false);
      getQuestionByQuestionSetIdData(saveQuestionSetData);
      setSupplyChainId(saveQuestionSetData.supplyChainId);
      setQuestionSetAreaOfWorkId(saveQuestionSetData.areaOfWorkId);
      setScopeId(saveQuestionSetData.scopeId);
      setScopeAndSupplyChainAndAreaOfWorkOnLanguageChange(
        saveQuestionSetData.supplyChainId,
        saveQuestionSetData.scopeId,
        saveQuestionSetData.areaOfWorkId,
        listProgram,
        listScope,
        listAreaOfWork
      );
    } else if (
      saveQuestionSetData &&
      saveQuestionSetData.hasOwnProperty("isIndicatorSetConflict") &&
      saveQuestionSetData.isIndicatorSetConflict === true
    ) {
      dispatch(
        openToaster(t("validationMessage.differentProgramScopeSelect"), false)
      );
      closeToasterParticularTimeInterval();
    }
  }, [saveQuestionSetData]);

  useEffect(() => {
    if (questionSetData && questionSetData.length > 0) {
      questionSetData.map((questionSet: any) => {
        questionSet.createdDate = getLocalDateFromMoment(
          questionSet.createdDate
        );
        questionSet.updatedDate = questionSet.updatedDate
          ? getLocalDateFromMoment(questionSet.updatedDate)
          : "";
      });
      setIsAddButtonShow(true);
    }
  }, [questionSetData]);

  useEffect(() => {
    if (listProgram.length && listScope.length && listAreaOfWork.length) {
      setScopeAndSupplyChainAndAreaOfWorkOnLanguageChange(
        supplyChainId,
        scopeId,
        questionSetAreaOfWorkId,
        listProgram,
        listScope,
        listAreaOfWork
      );
    }
  }, [listProgram, listScope, listAreaOfWork]);

  useEffect(() => {
    if (loginUserData) {
      setUserId(loginUserData.id);
    }
  }, [loginUserData]);

  const setScopeAndSupplyChainAndAreaOfWorkOnLanguageChange = (
    supplyChainId: any,
    scopeId: any,
    questionSetAreaOfWorkId: any,
    supplyChainData: any,
    scopeData: any,
    areaOfWorkData: any
  ) => {
    if (supplyChainId !== "") {
      const supplyChainFilter = supplyChainData.filter(
        (sch: any) => sch.id === supplyChainId.toString()
      );
      if (supplyChainFilter.length) {
        setSupplyChainName(supplyChainFilter[0].label);
      }

    }
    if (scopeId && scopeId !== "") {
      const scopeFilter = scopeData.filter(
        (sc: any) => sc.id === scopeId.toString()
      );
      if (scopeFilter.length) {
        setScopeName(scopeFilter[0].label);
      }
    }

    else if (questionSetAreaOfWorkId && questionSetAreaOfWorkId != "") {
      const questionSetAreaOfWork = areaOfWorkData.filter(
        (sch: any) => sch.id === questionSetAreaOfWorkId.toString()
      );
      if (questionSetAreaOfWork.length) {
        setQuestionSetAreaOfWork(questionSetAreaOfWork[0].label);
      }
    }
    if (areaOfWorkId.toString() !== "") {

      const areaOfWorkFilter = areaOfWorkData.filter(
        (sch: any) => sch.id === areaOfWorkId.toString()
      );
      if (areaOfWorkFilter.length) {
        setAreaOfWork(areaOfWorkFilter[0].label);
      }

    }
  };
  const showDialoagForm = () => {
    setDialogOpen(true);
    setIsEditQuestionSet(false);
  };

  const showQuestionSetTable = () => {
    if (showQueTable === false) {
      setShowQueSetTable(true);
    } else {
      setShowQueTable(false);
    }
  };

  const showQuestionSetQuestionTable = (data: any) => {
    setShowQueSetTable(false);
    setShowQueTable(false);
    getQuestionByQuestionSetIdData(data);
    setSupplyChainName(data.supplyChain);
    setScopeName(data.scope);
    setQuestionSetAreaOfWork(data.areaOfWork);
    setSupplyChainId(data.supplyChainId);
    setScopeId(data.scopeId);
  };
  const showQuestionTable = (data: any) => {
    setAreaOfWork(data.areaOfWork);
    setAreaOfWorkId(data.areaOfWorkId);
    setQuestionBySetIdAreaOfWorkTabData(questionBySetIdData);
    setCurrentIndicatorTopicId(data.indicatorTopicId)
    setShowQueSetTable(false);
    setShowQueTable(true);
    dispatch(
      actionGetQuestionByQuestionSetIdAndAreaOfWorkDetail(data.indicatorTopicId)
    );
  };
  const getQuestionByQuestionSetIdData = (data: any) => {
    setQuestionSetId(data.id);
    setQuestionSetGenCode(data.code);
    dispatch(actionGetQuestionByQuestionSetIdDetail(data.id));
    dispatch(actionGetNonAssignedTopics(data.id));
  };

  const getQuestionSetDataById = (data: any) => {
    dispatch(actionGetQuestionSetDataByIdDetail(data.id));
    setIsEditQuestionSet(true);
    setDialogOpen(true);
  };
  const dialogClose = () => {
    setDialogOpen(false);
  };
  const closeToasterFun = () => {
    dispatch(closeToaster());
  };

  const handlerOpenConfirmSaveIndicatorSet = () => {
    let isAllAreaHaveQuestion = true;
    let isSAOWHasTopic = true;

    for (let i = 0; i < questionBySetIdData.dynamicRowDataList.length; i++) {
      const assess = questionBySetIdData.dynamicRowDataList[i];
      if (assess.type === 2) {
        //check if each sub area of work has topic
        if (assess.tableData && assess.tableData.childRows === null) {
          isSAOWHasTopic = false;

          break;
        } else {
          //check no of indicator sets for each level and for each topic
          const childRows = assess.tableData.childRows;
          for (let j = 0; j < childRows.length; j++) {
            if (childRows[j].level1 === "0" || childRows[j].level2 === "0" || childRows[j].level3 === "0" || childRows[j].level4 === "0" || childRows[j].level5 === "0") {
              isAllAreaHaveQuestion = false;
              break;
            }
          }
        }
      }

      if (!isAllAreaHaveQuestion) {
        break;
      }
    }

    if (isAllAreaHaveQuestion && isSAOWHasTopic) {
      setOpenConfirmDialog(true);
    }
    else
      if (!isSAOWHasTopic) {
        setOpenAOWRedirectionDialog(true);
      }
      else if (!isAllAreaHaveQuestion) {
        dispatch(openToaster(t("indicators.checkAreaOfQuestionMessage"), false));
        closeToasterParticularTimeInterval();
      }
  };
  const handlerCloseIndicatorSetConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFun();
    }, COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
  };

  const saveQuestionSetCompleted = () => {
    const formData = {
      questionSetId: questionSetId,
      userId: userId,
      statusId: INDICATOR.STATUS.COMPLETED,
    };
    setOpenConfirmDialog(false);
    dispatch(actionSaveQuestionCompletedDetail(formData));
    handlerCloseIndicatorSetConfirmDialog();
    setShowQueSetTable(true);
  };
  const handleClickShowIndicatorSetTable = () => {
    setShowQueSetTable(true);
    setShowQueTable(false);
  };
  const handleClickShowIndicatorSetIdTable = () => {
    setShowQueSetTable(false);
    setShowQueTable(false);
  };
  const handleCloseAOWRedirectionDialog = () => {
    setOpenAOWRedirectionDialog(false);
  }
  const handleRedirectToAOW = () => {
    history.push(AOW_MANAGEMENT);
    setOpenAOWRedirectionDialog(false);
  }

  return (
    <Container maxWidth={"xl"}>
      <Toaster
        message={toasterMessage}
        open={isToasterVisible}
        close={closeToasterFun}
        success={isSuccess}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        close={handlerCloseIndicatorSetConfirmDialog}
        title={t("indicators.areYouSureSaveIndicatorSet")}
        content=""
        ok={saveQuestionSetCompleted}
      />
      <ConfirmDialog
        open={openAOWRedirectionDialog}
        close={handleCloseAOWRedirectionDialog}
        title={t("indicators.pleaseAddTopic")}
        content={t("indicators.continueToAddTopicToIndicatorSet")}
        ok={handleRedirectToAOW}
        isContinue={true}
      />
      {showQueSetTable ? (
        <>
          <SubHeaderWrapper>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography component="span" className="common-label">
                  {t("indicators.allIndicatorSets")}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => showDialoagForm()}
                  disabled={!isAddButtonShow}
                >
                  {t("indicators.addIndicatorSet")}
                </Button>
              </Grid>
            </Grid>
          </SubHeaderWrapper>
          <BoxShadowWrapper>
            <QuestionSetTableComponent
              questionSetData={questionSetData}
              formTitle={formTitle}
              showQuestionSetTable={showQuestionSetTable}
              showQuestionSetQuestionTable={showQuestionSetQuestionTable}
              questionSetId={questionSetId}
              isAddButtonShow={isAddButtonShow}
              setIsHistoryMode={setIsHistoryMode}
              getQuestionSetDataById={getQuestionSetDataById}
              userId={userId}
              closeToasterFun={closeToasterFun}
            ></QuestionSetTableComponent>
          </BoxShadowWrapper>

          <QuestionSetFormComponent
            questionSetData={questionSetData}
            formTitle={formTitle}
            dialogClose={dialogClose}
            closeToasterFun={closeToasterFun}
            scopeData={listScope}
            supplyChainData={listProgram}
            userId={userId}
            isEditQuestionSet={isEditQuestionSet}
            dialogOpen={dialogOpen}
            dialogTitle={dialogTitle}
          ></QuestionSetFormComponent>
        </>
      ) : (
        <>
          <Grid container alignItems="center">
            <Grid item>
              <BreadcrumbsCustom
                separator={
                  <NavigateNextIcon fontSize="small" className="arRotate180" />
                }
                aria-label="breadcrumb"
              >
                <Link
                  color="inherit"
                  onClick={handleClickShowIndicatorSetTable}
                >
                  {t("indicators.indicatorSet")}
                </Link>
                {showQueTable ? (
                  <Link
                    color="inherit"
                    onClick={handleClickShowIndicatorSetIdTable}
                  >
                    {isHistoryMode ? (
                      <> {t("common.view")} </>
                    ) : (
                      <>{t("common.edit")}</>
                    )}
                  </Link>
                ) : (
                  <Typography color="textPrimary">
                    {isHistoryMode ? (
                      <> {t("common.view")} </>
                    ) : (
                      <>{t("common.edit")}</>
                    )}
                  </Typography>
                )}
                {showQueTable && (
                  <Typography color="textPrimary">{areaOfWork}</Typography>
                )}
              </BreadcrumbsCustom>
            </Grid>
          </Grid>
          <SubHeaderWrapper>
            <Grid container justify="space-between">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      className="padding10"
                      color="default"
                      onClick={() => showQuestionSetTable()}
                    >
                      <ArrowBackIosIconCustom className="arRotate180" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography
                      component="span"
                      className="common-label padding10-5"
                    >
                      {!showQueTable ? (
                        <>
                          {" "}
                          {t("indicators.indicatorSet")}{" "}
                          {isHistoryMode ? (
                            <> {t("common.view")} </>
                          ) : (
                            <>{t("common.draft")}</>
                          )}
                        </>
                      ) : (
                        <>
                          {t("indicators.indicatorSetCode")}{" "}
                          {questionSetGenCode}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {!isHistoryMode && !showQueTable && (
                  <DialogButtonPrimary
                    variant="contained"
                    color="primary"
                    onClick={() => handlerOpenConfirmSaveIndicatorSet()}
                  >
                    {t("indicators.finaliseSet")}
                  </DialogButtonPrimary>
                )}
              </Grid>
            </Grid>
          </SubHeaderWrapper>
          {!showQueTable && (
            <QuestionBySetIdTableComponent
              questionBySetIdData={questionBySetIdData}
              showQuestionTable={showQuestionTable}
              isHistoryMode={isHistoryMode}
              questionSetGenCode={questionSetGenCode}
              supplyChainName={supplyChainName}
              scopeName={scopeName}
              questionSetAreaOfWork={questionSetAreaOfWork}
              questionSetId={questionSetId}
              listAreaOfWork={listAreaOfWork}
              listSubAreaOfWork={listSubAreaOfWork}
              setTopicName={setTopicName}
              setSubAOWName={setSubAOWName}
              setAowName={setAowName}
            ></QuestionBySetIdTableComponent>
          )}
          {showQueTable && (
            <QuestionTableComponent
              questionBySetIdAreaOfWorkData={questionBySetIdAreaOfWorkData}
              questionBySetIdAreaOfWorkTabData={questionBySetIdAreaOfWorkTabData}
              questionSetGenCode={questionSetGenCode}
              questionSetId={questionSetId}
              areaOfWorkId={areaOfWorkId}
              isHistoryMode={isHistoryMode}
              closeToasterFun={closeToasterFun}
              userId={userId}
              supplyChainName={supplyChainName}
              scopeName={scopeName}
              questionSetAreaOfWork={questionSetAreaOfWork}
              areaOfWork={areaOfWork}
              currentIndicatorTopicId={currentIndicatorTopicId}
              topicName={topicName}
              aowName={aowName}
              subAOWName={subAOWName}
            ></QuestionTableComponent>
          )}
        </>
      )}
    </Container>
  );
}

export default QuestionSetComponent;
