import React from "react";
import QuestionDragableContainer from "./question-dragable-container";
import { Droppable } from "react-beautiful-dnd";
import {
  DragDropContainer,
  QuestionListContainer,
} from "../../../styles/common";
import Grid from "@material-ui/core/Grid";

function QuestionColumnDrgableContainer(props: any) {
  const {
    questionIdsData,
    column,
    hadleEditRowData,
    handlerOpenConfirmDialogDelete,
    isHistoryMode,
  } = props;
  return (
    <>
      <Grid container alignItems="center" direction="column">
        <Grid item xl={12} className="full-width">
          <DragDropContainer>
            <Droppable droppableId={column}>
              {(provided) => (
                <div
                  className="QuestionListContainer"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <QuestionListContainer>
                    {questionIdsData.map((question: any, index: any) => (
                      <QuestionDragableContainer
                        key={question.questionId}
                        question={question}
                        index={question.sequence}
                        showSequence={index + 1}
                        hadleEditRowData={hadleEditRowData}
                        handlerOpenConfirmDialogDelete={
                          handlerOpenConfirmDialogDelete
                        }
                        isHistoryMode={isHistoryMode}
                      />
                    ))}
                  </QuestionListContainer>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContainer>
        </Grid>
      </Grid>
    </>
  );
}
export default QuestionColumnDrgableContainer;
