import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import { Toaster } from "../../components/common/toaster.component";
import { closeToaster } from "../../store/common/actions";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  actionSaveRegisterUserRoleData,
  actionSaveRegisterUserSelectedRoleData,
  actionUpdateUserSetting,
} from "../user-profile/actions";
import { FormWrapper, DialogButtonPrimary } from "../../styles/common";
import FormSelect from "../../components/form-controls/select";
import { sortObjectDataByAsending } from "../../utils/data";
import { HOME_PATH_AFTER_LOGIN, HOME_PATH } from "constants/api";
import { history } from "../../store/config";
import { ASSESSMENT_OVERVIEW } from "./../../constants/api";
import * as yup from "yup";
import {
  actionHeaderUserDropDownShow,
  actionHeaderUserSettingPopUpShow,
} from "containers/header/actions";
import { USER } from "constants/data";

function Setting(props: any) {
  const { t } = useTranslation();
  const validationSchemaUser = yup.object().shape({
    role: yup.string().required(t("setting.selectRole")),
    region: yup.string().required(t("setting.selectRegion")),
    country: yup.string().required(t("setting.selectCountryArea")),
  });
  const validationSchemaRSA = yup.object().shape({
    role: yup.string().required(t("setting.selectRole")),
    region: yup.string().required(t("setting.selectRegion")),
  });
  const validationSchemaAdmin = yup.object().shape({
    role: yup.string().required(t("setting.selectRole")),
  });

  const { applyBtn, directionValue, showBtn } = props;
  const dispatch = useDispatch();
  const [validationSchema, setValidationSchema]: any = useState({});
  const methods = useForm({
    validationSchema: validationSchema,
  });
  const { setValue } = methods;
  const userIdProfileSettingData = useSelector(
    (state: any) => state.userProfile.userIdProfileSettingData
  );
  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );

  const listOrgWiseRegion = useSelector(
    (state: any) => state.common.listOrgWiseRegion
  );

  const roleData = useSelector((state: any) => state.common.roleData);
  const isSuccess = useSelector((state: any) => state.common.isSuccess);
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const isClicked = useSelector(
    (state: any) => state.header.isUserSettingClicked
  );
  const organizationSelected = useSelector(
    (state: any) => state.header.organizationSelected
  );
  const selectedAppDetails = useSelector(
    (state: any) => state.header.appDetails
  );
  const scsUserDetails = useSelector(
    (state: any) => state.scsHub.scsUserDetails
  );

  const [userRoleObj, setUserRoleObj]: any = useState({
    roleList: [],
    countryList: [],
  });

  const [roleListMap, setRoleListMap]: any = useState([]);
  const [regionListMap, setRegionListMap]: any = useState([]);
  const [countryListMap, setCountryListMap]: any = useState([]);
  const [selectedRole, setSelectedRole]: any = useState([]);
  const [selectedRegion, setSelectedRegion]: any = useState([]);
  const [selectedCountry, setSelectedCountry]: any = useState([]);
  const [selectedRoleString, setSelectedRoleString]: any = useState([]);
  const currentRoute: any = window.location.pathname;
  const isHomePath = currentRoute.includes("/home");
  const { handleSubmit, errors } = methods;
  let userSelectedData: any = null;
  let userId: any = "";
  let listOfCountry: any = [];
  let listOfRoles: any = [];
  let listOfRegion: any = [];
  let userRoleDetails: any = [];
  let allCountryList: any = [];
  //let allRegionList: any = [];
  const element: HTMLElement = document.getElementsByClassName(
    "btn"
  )[0] as HTMLElement;

  useEffect(() => {
    if (props.applyClick) element.click();
  }, [props.applyClick, isClicked]);

  useEffect(() => {
    if (
      userIdProfileSettingData &&
      userIdProfileSettingData.length &&
      listOrgWiseCountry &&
      listOrgWiseCountry.length &&
      roleData &&
      roleData.length
    ) {
      userSelectedData = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
      );
      createRegionDataFun();
      createRoleDataFun();
      userRoleDetails = getValidAppRoles(listOfRoles);
      // creating role deatils data for each role which has assigned to them
      createRoleDataForEachRole();
      if (userRoleDetails.length) {
        //checking if user already store his selected data
        appendDataIfSelectedDataFun();
      }
      dispatch(actionSaveRegisterUserRoleData(listOfRoles));
    }
  }, [userIdProfileSettingData, roleData, listOrgWiseCountry]);

  const getValidAppRoles = (listOfRoles: any) => {
    const app = scsUserDetails.applications.filter((app: any) => app.id === selectedAppDetails.id);
    if (app.length > 0) {
      let userValidRoles = listOfRoles.filter((role: any) => {
        const isPresent = app[0].roles.find((appRole: any) => typeof role === 'string' ? appRole.name === role : appRole.id === role.id);
        if (isPresent) {
          return role;
        }
      })
      return userValidRoles;
    }
    return listOfRoles;
  }

  const createRegionDataFun = () => {
    userIdProfileSettingData[0].countryRoles.forEach(function (value: any) {
      //finding roles assigned region
      const tempRoleData = roleData.filter(
        (roleE: any) =>
          roleE.name.toLowerCase().trim() === value.role.toLowerCase().trim()
      );

      if (tempRoleData.length) {
        listOfRegion.push({
          value: value.region,
          label: value.region,
          id: value.region,
          key: tempRoleData[0].id,
        });
      }
    });
  };
  const createRoleDataFun = () => {
    userIdProfileSettingData[0].countryUsers.forEach(function (value: any) {
      const tempRoleData = roleData.filter(
        (roleE: any) =>
          roleE.id.toLowerCase().trim() === value.roleId.toLowerCase().trim()
      );

      if (tempRoleData.length) {
        if (listOfRoles.length == 0) {
          listOfRoles.push({
            key: tempRoleData[0].id,
            id: tempRoleData[0].id,
            label: tempRoleData[0].name.trim(),
            regions: [],
            countries: [],
          });
        } else if (
          listOfRoles.filter((role: any) => role.id === tempRoleData[0].id)
            .length === 0
        ) {
          listOfRoles.push({
            key: tempRoleData[0].id,
            id: tempRoleData[0].id,
            label: tempRoleData[0].name.trim(),
            regions: [],
            countries: [],
          });
        }
      }
      const tempCountryData = listOrgWiseCountry.filter(
        (country: any) => country.id === value.countryId
      );
      if (tempCountryData.length) {
        listOfCountry.push({
          key: tempCountryData[0].id,
          id: tempCountryData[0].id,
          value: tempCountryData[0].id,
          label: tempCountryData[0].label,
          region: tempCountryData[0].region,
          roleId: value.roleId,
        });
      }
    });
  };
  const createRoleDataForEachRole = () => {
    userRoleDetails.forEach(function (role: any) {
      const tempRoleMap = roleData.filter((roleM: any) => roleM.id === role.id);
      if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.SD_ADMIN.toLowerCase()
      ) {
        role.regions = listOrgWiseRegion;
        role.countries = listOrgWiseCountry;
      } else if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
      ) {
        const filterRegionList = listOfRegion.filter(
          (region: any) => region.key === role.id
        );
        if (filterRegionList.length) {
          role.regions = sortObjectDataByAsending(filterRegionList, "label");
        }
        const filterCountryList = listOrgWiseCountry.filter(
          (country: any) => country.region === role.regions[0].label
        );
        if (filterCountryList.length) {
          role.countries = sortObjectDataByAsending(filterCountryList, "label");
        }
      } else if (
        tempRoleMap.length &&
        tempRoleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
      ) {
        role.regions = listOrgWiseRegion;
        role.countries = listOrgWiseCountry;
      } else {
        const filterRegionList = listOfRegion.filter(
          (region: any) => region.key === role.id
        );
        if (filterRegionList.length) {
          role.regions = sortObjectDataByAsending(filterRegionList, "label");
        }
        const filterCountryList = listOfCountry.filter(
          (country: any) => country.roleId === role.id
        );
        if (filterCountryList.length) {
          role.countries = sortObjectDataByAsending(filterCountryList, "label");
        }
      }
    });
  };

  const checkSeletedDataIsValid = (value: any) => {
    userRoleDetails.map((role: any) => {
      if (role.id === value.userSelectedRole) {
        const roleMap = roleData.filter(
          (role: any) => role.id === value.userSelectedRole
        );
        if (
          roleMap.length &&
          roleMap[0].name.toLowerCase().trim() ===
          USER.ROLE.SD_ADMIN.toLowerCase()
        ) {
          reArrangeData(
            value.userSelectedRole,
            value.userSelectedRegion,
            value.userSelectedCountry
          );
        } else if (
          roleMap.length &&
          roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
        ) {
          const validRegion = role.regions.filter(
            (region: any) => region.id === value.userSelectedRegion
          );
          if (validRegion.length) {
            reArrangeData(
              value.userSelectedRole,
              value.userSelectedRegion,
              value.userSelectedCountry
            );
          }
        } else if (
          roleMap.length &&
          roleMap[0].name.toLowerCase().trim() ===
          USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
        ) {
          reArrangeData(
            value.userSelectedRole,
            value.userSelectedRegion,
            value.userSelectedCountry
          );
        } else {
          const validRegion = role.regions.filter(
            (region: any) => region.id === value.userSelectedRegion
          );
          if (validRegion.length) {
            const validCountry = role.countries.filter(
              (country: any) => country.id === value.userSelectedCountry
            );
            if (validCountry.length) {
              reArrangeData(
                value.userSelectedRole,
                value.userSelectedRegion,
                value.userSelectedCountry
              );
            }
          }
        }
      }
    });
  };
  const appendDataIfSelectedDataFun = () => {
    setUserRoleObj(userRoleDetails);
    setRoleListMap(userRoleDetails);
    //if user already has selected data then binding it to drop down according to role
    if (userSelectedData) {
      if (userSelectedData.hasOwnProperty("userSelectedRole")) {
        setValue("role", userSelectedData.userSelectedRole);
        setSelectedRole(userSelectedData.userSelectedRole);
        if (
          currentRoute === HOME_PATH_AFTER_LOGIN ||
          currentRoute === HOME_PATH ||
          isHomePath
        ) {
          checkSeletedDataIsValid(userSelectedData);
        }
        const roleMap = roleData.filter(
          (role: any) => role.id === userSelectedData.userSelectedRole
        );
        setSelectedRoleString(roleMap[0].name.toLowerCase().trim());
        userRoleDetails.forEach(function (role: any) {
          if (role.id === userSelectedData.userSelectedRole) {
            const roleMap = roleData.filter(
              (role: any) => role.id === userSelectedData.userSelectedRole
            );
            if (
              roleMap.length &&
              roleMap[0].name.toLowerCase().trim() ===
              USER.ROLE.SD_ADMIN.toLowerCase()
            ) {
              setValidationSchema(validationSchemaAdmin);
              setRegionListMap(role.regions);
              setSelectedRegion(userSelectedData.userSelectedRegion);
              setCountryListMap(listOrgWiseCountry);
              setSelectedCountry(userSelectedData.userSelectedCountry);
              const roleMap = roleData.filter(
                (role: any) => role.id === userSelectedData.userSelectedRole
              );
              if (
                roleMap.length &&
                roleMap[0].name.toLowerCase().trim() ===
                USER.ROLE.RSA.toLowerCase()
              ) {
                setValidationSchema(validationSchemaRSA);
                const tempCountryData = listOrgWiseCountry.filter(
                  (countryE: any) =>
                    countryE.region === userSelectedData.userSelectedRegion
                );
                allCountryList = [...allCountryList, ...tempCountryData];
                setCountryListMap(allCountryList);
                setSelectedCountry(userSelectedData.userSelectedCountry);
              } else {
                setValidationSchema(validationSchemaUser);
                const filterCountryList = role.countries.filter(
                  (x: any) =>
                    x.region === userSelectedData.userSelectedRegion[0]
                );
                setCountryListMap(filterCountryList);
                setValue("country", userSelectedData.userSelectedCountry[0]);
                setSelectedCountry(userSelectedData.userSelectedCountry);
              }
            } else if (role.id === userSelectedData.userSelectedRole) {
              const roleMap = roleData.filter(
                (role: any) => role.id === userSelectedData.userSelectedRole
              );
              if (
                roleMap.length &&
                roleMap[0].name.toLowerCase().trim() ===
                USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
              ) {
                setValidationSchema(validationSchemaAdmin);
                setRegionListMap(listOrgWiseRegion);
                setSelectedRegion(userSelectedData.userSelectedRegion);
                setCountryListMap(listOrgWiseCountry);
                setSelectedCountry(userSelectedData.userSelectedCountry);
              } else {
                setValidationSchema(validationSchemaUser);
                const filterRegionList = role.regions.filter(
                  (region: any) =>
                    region.key === userSelectedData.userSelectedRole
                );
                if (filterRegionList.length) {
                  setRegionListMap(filterRegionList);
                  setValue("region", userSelectedData.userSelectedRegion[0]);
                  setSelectedRegion(userSelectedData.userSelectedRegion[0]);
                }
                const filterCountryList = role.countries.filter(
                  (x: any) =>
                    x.region === userSelectedData.userSelectedRegion[0]
                );
                setCountryListMap(filterCountryList);
                setValue("country", userSelectedData.userSelectedCountry);
                setSelectedCountry(userSelectedData.userSelectedCountry);
              }
            }
          }
        });
      }
    } else {
      // //if user login first time then binding his role data to control
      createFirstTimeLoginDataFun();
    }
  };

  const createFirstTimeLoginDataFun = () => {
    setRegionListMap(userRoleDetails[0].regions);
    setCountryListMap(userRoleDetails[0].countries);
    setValue("role", userRoleDetails[0].id);
    setSelectedRole(userRoleDetails[0].id);

    const roleMap = roleData.filter(
      (roleE: any) => roleE.id === userRoleDetails[0].id
    );
    setSelectedRoleString(roleMap[0].name.toLowerCase().trim());
    if (
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.SD_ADMIN.toLowerCase()
    ) {
      setValidationSchema(validationSchemaAdmin);
      setSelectedRegion(userRoleDetails[0].regions);
      setSelectedCountry(userRoleDetails[0].countries);
      reArrangeData(
        userRoleDetails[0].id,
        userRoleDetails[0].regions,
        userRoleDetails[0].countries
      );
    } else if (
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
    ) {
      setValidationSchema(validationSchemaRSA);
      setValue("region", userRoleDetails[0].regions[0].id);
      setSelectedRegion(userRoleDetails[0].regions[0].id);
      const tempCountryData = listOrgWiseCountry.filter(
        (countryE: any) => countryE.region === userRoleDetails[0].regions[0].id
      );
      allCountryList = [...allCountryList, ...tempCountryData];
      setSelectedCountry(allCountryList);
      if (userRoleDetails.length === 1) {
        if (userRoleDetails[0].regions.length === 1) {
          reArrangeData(
            userRoleDetails[0].id,
            userRoleDetails[0].regions[0].id,
            userRoleDetails[0].countries
          );
        }
      }
    } else if (
      roleMap[0].name.toLowerCase().trim() ===
      USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
    ) {
      setValidationSchema(validationSchemaAdmin);
      setSelectedRegion(userRoleDetails[0].regions);
      setSelectedCountry(userRoleDetails[0].countries);
      reArrangeData(
        userRoleDetails[0].id,
        userRoleDetails[0].regions,
        userRoleDetails[0].countries
      );
    } else if (
      roleMap.length &&
      (roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.ASSESSOR.toLowerCase() ||
        roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.VALIDATOR.toLowerCase())
    ) {
      setValidationSchema(validationSchemaUser);
      setValue("region", userRoleDetails[0].regions[0].id);
      setSelectedRegion(userRoleDetails[0].regions[0].id);
      setValue("region", userRoleDetails[0].regions[0].id);
      const filterCountryList = userRoleDetails[0].countries.filter(
        (x: any) => userRoleDetails[0].regions[0].id === x.region
      );
      if (filterCountryList.length) {
        setCountryListMap(filterCountryList);
        setValue("country", filterCountryList[0].id);
        setSelectedCountry(filterCountryList[0].id);
      }

      //cheking if user has one role one region and one country assigned
      if (userRoleDetails.length === 1) {
        if (userRoleDetails[0].regions.length === 1) {
          if (userRoleDetails[0].countries.length === 1) {
            reArrangeData(
              userRoleDetails[0].id,
              userRoleDetails[0].regions[0].id,
              userRoleDetails[0].countries[0].id
            );
          }
        }
      }
    }
  };
  // if user change role
  const handleRoleChange = ([event]: any) => {
    if (event.target.value) {
      setValue("region", "");
      setValue("country", "");
      setCountryListMap([]);
      setRegionListMap([]);
      setSelectedRole(event.target.value);
      const roleMap = roleData.filter(
        (role: any) => role.id === event.target.value
      );
      setSelectedRoleString(roleMap[0].name.toLowerCase().trim());
      if (
        roleMap.length &&
        roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.SD_ADMIN.toLowerCase()
      ) {
        setValidationSchema(validationSchemaAdmin);
        setSelectedRegion(listOrgWiseRegion);
        setSelectedCountry(listOrgWiseCountry);
      } else if (
        roleMap.length &&
        roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
      ) {
        setValidationSchema(validationSchemaRSA);
        const filterRegionList = userRoleObj.filter(
          (role: any) => role.id === event.target.value
        );
        if (filterRegionList.length)
          setRegionListMap(filterRegionList[0].regions);
      } else if (
        roleMap.length &&
        roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
      ) {
        setValidationSchema(validationSchemaAdmin);
        setSelectedRegion(listOrgWiseRegion);
        setSelectedCountry(listOrgWiseCountry);
      } else {
        setValidationSchema(validationSchemaUser);
        const filterRegionList = userRoleObj.filter(
          (role: any) => role.id === event.target.value
        );
        if (filterRegionList.length)
          setRegionListMap(filterRegionList[0].regions);
      }

      return event.target.value;
    }
  };
  // if user change region
  const handleRegionChange = ([event]: any) => {
    if (event.target.value) {
      setValue("country", "");
      setSelectedRegion(event.target.value);
      const userRole = userRoleObj.filter(
        (role: any) => role.id === selectedRole
      );
      if (userRole.length) {
        const roleMap = roleData.filter(
          (role: any) => role.id === selectedRole
        );

        if (
          roleMap.length &&
          roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
        ) {
          setValidationSchema(validationSchemaRSA);
          const tempCountryData = listOrgWiseCountry.filter(
            (countryE: any) => countryE.region === event.target.value
          );
          allCountryList = [...allCountryList, ...tempCountryData];
          setSelectedCountry(allCountryList);
          setCountryListMap(allCountryList);
        } else {
          setValidationSchema(validationSchemaUser);
          const filterCountryList = userRole[0].countries.filter(
            (country: any) => event.target.value === country.region
          );
          if (filterCountryList.length) setCountryListMap(filterCountryList);
        }
      }

      return event.target.value;
    }
  };
  // if user change country
  const handleCountryChange = ([event]: any) => {
    if (event.target.value) {
      setSelectedCountry(event.target.value);
      return event.target.value;
    }
  };

  // arrange data for next page opration
  const reArrangeData = (Role: any, Region: any, Country: any) => {
    let regionArr: any = [];
    let countryArr: any = [];
    if (Array.isArray(Region)) {
      Region.map((region: any) => {
        if (region.id) {
          regionArr.push(region.id);
        } else {
          regionArr.push(region);
        }
      });
    } else {
      regionArr.push(Region);
    }
    if (Array.isArray(Country)) {
      Country.map((country: any) => {
        countryArr.push(country.id);
      });
    } else {
      countryArr.push(Country);
    }
    userSelectedData = {
      userSelectedRole: Role,
      userSelectedRegion: regionArr,
      userSelectedCountry: countryArr,
      organization: organizationSelected,
      userSelectedApp: selectedAppDetails.id
    };
    localStorage.setItem("userSelectedData", JSON.stringify(userSelectedData));

    const selectedObj = {
      userSelectedData: userSelectedData,
    };
    if (userIdProfileSettingData.length) {
      userId = userIdProfileSettingData[0].id;
    }
    const shareSetting = {
      id: userId,
      UserSettings: JSON.stringify(userSelectedData),
    };
    dispatch(actionSaveRegisterUserSelectedRoleData(selectedObj));
    dispatch(actionUpdateUserSetting(shareSetting));
    applyBtn();
    dispatch(actionHeaderUserSettingPopUpShow(false));
    dispatch(actionHeaderUserDropDownShow(false));
    history.push(ASSESSMENT_OVERVIEW);
  };

  const apply = () => {
    reArrangeData(selectedRole, selectedRegion, selectedCountry);
  };

  return (
    <div>
      <Toaster
        message={toasterMessage}
        open={isToasterVisible}
        close={closeToaster}
        success={isSuccess}
      />
      <Grid container>
        <Grid item sm={12}>
          <FormWrapper>
            <FormContext {...methods}>
              <form>
                <Grid
                  container
                  spacing={2}
                  direction={directionValue}
                  className="paddingTB20 text-align-left"
                  justify="flex-start"
                >
                  <Grid item xs={directionValue === "column" ? 12 : 4}>
                    <FormSelect
                      name="role"
                      label={t("common.role")}
                      options={roleListMap}
                      onChange={handleRoleChange}
                      required={true}
                      errorobj={errors}
                    />
                  </Grid>
                  {selectedRoleString !== USER.ROLE.SD_ADMIN.toLowerCase() &&
                    selectedRoleString !==
                    USER.ROLE.GLOBAL_ADVISOR.toLowerCase() && (
                      <Grid item xs={directionValue === "column" ? 12 : 4}>
                        <FormSelect
                          name="region"
                          label={t("common.region")}
                          options={regionListMap}
                          onChange={handleRegionChange}
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                    )}
                  {selectedRoleString !== USER.ROLE.SD_ADMIN.toLowerCase() &&
                    selectedRoleString !==
                    USER.ROLE.GLOBAL_ADVISOR.toLowerCase() &&
                    selectedRoleString !== USER.ROLE.RSA.toLowerCase() && (
                      <Grid item xs={directionValue === "column" ? 12 : 4}>
                        <FormSelect
                          name="country"
                          label={t("common.countryArea")}
                          options={countryListMap}
                          onChange={handleCountryChange}
                          required={true}
                          errorobj={errors}
                        />
                      </Grid>
                    )}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="margin-top-20"
                  justify="center"
                >
                  <Grid item className={showBtn ? "showBtn" : "hideBtn"}>
                    <DialogButtonPrimary
                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={handleSubmit(apply)}
                    >
                      {t("setting.apply")}
                    </DialogButtonPrimary>
                  </Grid>
                </Grid>
              </form>
            </FormContext>
          </FormWrapper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Setting;
