import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormSelect from "../../../components/form-controls/select";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import {
    DialogClose,
    DialogButtonDefault,
    DialogButtonPrimary,
} from "../../../styles/common";
import { useForm, FormContext } from "react-hook-form";
import * as yup from "yup";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, COMMON_CONSTANT } from "constants/data";
import { openToaster } from "store/common/actions";

export default function AssesmentFormComponent(props: any) {

    const {
        dialogClose,
        assesmentData,
        dialogOpen,
        dialogTitle,
        areaOfworks,
        closeToasterFunction,
        showAssesmentDetailTable
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const listProgram = useSelector(
        (state: any) => state.common.listProgram
    );
    const allPrograms: any = (window as any).allPrograms;
    const [supplyChain, setsupplyChain]: any = useState();

    const validationSchema = yup.object().shape({
        supplyChain: yup.string().required(t("validationMessage.programSelect")),
        areaOfWork: yup.string().required(t("validationMessage.assessmentTypeSelect")),
    });

    useEffect(() => {
        if (allPrograms.length) {
            setsupplyChain(allPrograms[1].id)
        }
    }, []);

    const methods = useForm({
        validationSchema: validationSchema,
    });

    const { handleSubmit, errors } = methods;

    const closeAssesmentDialog = () => {
        dialogClose();
    };

    const onSubmit = (data: any) => {
        const checkData = assesmentData.filter((assess: any) => {
            if (
                parseInt(assess.supplyChainId) === parseInt(data.supplyChain) &&
                parseInt(assess.areaOfWorkId) === parseInt(data.areaOfWork) &&
                assess.assessmentStatusId !== ASSESSMENT.STATUS.SUBMITTED &&
                assess.assessmentType === ASSESSMENT_TYPE_CONST.VC
            ) {
                return true;
            }
            return false;
        });
        if (checkData.length) {
            dispatch(
                openToaster(t("validationMessage.differentProgramAssessmentTypeSelect"), false)
            );
            closeToasterParticularTimeInterval();
            return;
        }
        const formData = {
            areaOfWorkId: data.areaOfWork,
            supplyChainId: data.supplyChain,
            assessmentStatusId: 1,
            assesementType: ASSESSMENT_TYPE_CONST.VC
        };
        showAssesmentDetailTable(formData);
    }

    const closeToasterParticularTimeInterval = () => {
        setTimeout(function () {
            closeToasterFunction();
        }, COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
    };

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={dialogClose}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                maxWidth={"xs"}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                    <DialogClose aria-label="close" onClick={closeAssesmentDialog}>
                        <CloseIcon />
                    </DialogClose>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description" className="nomargin">
                        <FormContext {...methods}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="supplyChain"
                                        label={t("common.program")}
                                        options={listProgram}
                                        hideselectoption="true"
                                        required={true}
                                        defaultValue={supplyChain}
                                        errorobj={errors}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="areaOfWork"
                                        label={t("assessment.assessmentType")}
                                        options={areaOfworks}
                                        required={true}
                                        errorobj={errors}
                                    />
                                </Grid>
                            </Grid>
                        </FormContext>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="padding16-24">
                    <Grid container alignItems="center" justify="flex-end" spacing={2}>
                        <Grid item>
                            <DialogButtonDefault
                                variant="contained"
                                color="default"
                                onClick={closeAssesmentDialog}
                            >
                                {t("common.cancel")}
                            </DialogButtonDefault>
                        </Grid>
                        <Grid item>
                            <DialogButtonPrimary
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t("common.create")}
                            </DialogButtonPrimary>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );

}
