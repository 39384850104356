import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BAR_FILLED } from "constants/color";
import styled from "styled-components";

export const BoxShadowWrapper = styled.div`
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #d1d6e0;
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 20px;
`;
export const SubHeaderWrapper = styled.div`
  padding: 10px 0;
`;
export const RequestAccessArea = styled.div`
  &&{
    position: absolute;
    top: 20px;
    right: 20px;

    @media screen and (max-width: 767px) {
      position: initial;
      text-align: right;
      padding: 20px;
    }
  }
`;
export const LPTitle = styled.div`
  && {
    text-align: center;
    h1 {
      font-size: 28px;
      font-weight: 600;
      color: #4A494A;
      padding: 20px 4% 10px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #4A494A;
      padding: 0 4%;
    }
    @media screen and (max-width: 1200px) {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;
export const SCSHubAppList = styled.div`
  &&{
    display: flex;
    flex-direction: column;
    width: 320px;
    margin: 0 auto;
    padding: 10px 0 20px;

    .app-item{
      margin: 10px 0;
      border: 2px solid #dadde9;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 14px;
      text-align: center;

      &:hover{
        background-color: #F3F3F3;
      }
    }
    .app-item-active{
      cursor: pointer;
      background-color: #1CABE2;
      color: #FFF;

      &:hover{
        background-color: #0982b1;
      }
    }
  }
`;

export const SCSHubAppBlock = styled.div`
  &&{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    

    .scs-app-item{
      flex: 1;
      position: relative;
      border-radius: 5px;
      padding: 15px;
      margin-left: 15px;
      margin-right: 15px;
      color: #FFF;
      overflow: hidden;
      min-height: 200px;


      .scs-header{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        .scs-app-title{
          padding: 15px;
        }
      }
      .scs-app-icon{
        width: 100px;
        height: 100px;
        margin: 15px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .scs-app-title{
        font-size: 1.25rem !important;
        font-weight: 500 !important;
        text-align: center;
        line-height: normal;
      }
      .scs-details{
        opacity: 0;
        
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
         
        transform: translateY(100%);

        .scs-app-desc{
          padding-top: 15px;
        }
      }
      &:hover {
        .scs-header{
          opacity: 0;
        }
        .scs-details{
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    .scs-app-item-active{
      cursor: pointer;
    }
    .app-blue{
      background-color: #1468B2;
    }
    .app-green{
      background-color: #7FCA9F;
    }
    .app-red{
      background-color: #EA6D63;
    }
    .app-yellow{
      background-color: #FFD15C;
    }
    .app-gray{
      background-color: #A6A6A6;
    }
    @media screen and (max-width: 767px){
      .scs-app-item{
        width: 100%;
        flex: auto;
        margin: 10px 0;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1200px){
      .scs-app-item{
        width: 40%;
        flex: none;
        margin: 15px 0;
        min-height: 180px;
      }
    }
    @media screen and (min-width: 1920px){
      .scs-app-item{
        min-height: 200px;
      }
    }
  }
`;

export const DialogButtonDefault = styled(Button)`
  && {
    background-color: #F3F3F3 !important;
    color: #000 !important;
    text-transform: capitalize;
  }
`;

export const DialogButtonPrimary = styled(Button)`
  && {
    background-color: #1CABE2 !important;
    &.MuiButton-contained.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
`;
export const LandingLanguageBar = styled.div`
  > div {
    color: #FFF !important;
    width: 100px !important;
    margin: 0 !important;
  }
  .MuiInput-underline:before {
    border: none !important;
  }
  .MuiInput-underline:after {
    border: none !important;
  }
  .MuiSelect-icon {
    color: #FFF !important;
  }
  &.errorpage{
    > div {
      color: #4A494A !important;
    }
   .MuiSelect-icon {
      color: #4A494A !important;
   }
  }
`;
export const VerticalLine = styled.div`
  && {
    width: 1px;
    height: 35px;
    background-color: #fff;
  }
`;
export const SCSHubTitle = styled.div`
  &&{
    padding: 0;
    font-size: 48px;
    color: #1CABE2;
    font-weight: 500;
    text-align: center;
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88vh;

    @media screen and (max-height: 700px){
      height: 83vh;
    }
  }
`;

export const AccessRequestGrid = styled.div`
  &&{

    table{
      width: 100%;
      border-spacing: 0;
      table-layout: fixed;

      thead{
        tr{
          th{
            background-color: #E0F2F9;
            color: #4A494A;
            white-space: nowrap;
            font-weight: 500;
            font-size: 14px;
            padding: 10px 10px;
            text-align: left;
          }
        }
      }
      tbody{
        tr{
          td{
            min-width: 180px;
            padding: 5px 10px 10px;
            font-size: 14px;
          }
          &:nth-child(even){
            background-color: #F8F8F8;
            &:hover{
              background-color: #F8F8F8;
            }
          }
          &:nth-child(odd):hover{
            background-color: #FFF;
          }
        }
      }
    }
  }
`;
export const ScsImageSlider = styled.div`
  &&{
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;

    .slider-container{
      width: 80%;
      max-width: 1870px;
    }

    @media screen and (max-height: 480px) {
      .slider-container{
        width: 80%;
       }
    }
    @media screen and (min-height: 481px) and (max-height: 657px){
      .slider-container{
       width: 80%;
      }
    }
    @media screen and (min-height: 658px) and (max-height: 768px){
      .slider-container{
       width: 84%;
      }
    }
    @media screen and (min-height: 769px) and (max-height: 992px){
      .slider-container{
       width: 88%;
      }
    }
    @media screen and (min-height: 993px) and (max-height: 1024px){
      .slider-container{
       width: 80%;
      }
    }
    @media screen and (min-height: 1025px) and (max-height: 1080px){
      .slider-container{
       width: 80%;
      }
    }
    @media screen and (min-height: 10810px){
      .slider-container{
       width: 80%;
      }
    }
    @media screen and (min-width: 1280px) and (max-width: 1280px){
      .slider-container{
       width: 96%;
      }
    }
    @media screen and (min-width: 1440px) and (max-width: 1440px){
      .slider-container{
       width: 96%;
      }
    }
  }

`;
export const ArrowBackIosIconCustom = styled(ArrowBackIosIcon)`
  && {
    color: ${BAR_FILLED};
    width: 16px;
    height: 16px;
  }
`;