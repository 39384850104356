import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import FormSelect from "../../../components/form-controls/select";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  DialogClose,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../../styles/common";
import { openToaster } from "../../../store/common/actions";
import {
  ASSESSMENT,
  ASSESSMENT_TYPE_CONST,
  COMMON_CONSTANT,
  COUNTRY_HIERARCHY_CONST,
  SCOPE_CONST,
} from "constants/data";
import { actionFetchMasterCountryHierarchyData } from "containers/master/actions";
import { actionFetchAllAssesmentData } from "../actions";

function AssesmentCreateFormComponent(props: any) {
  const {
    dialogClose,
    assesmentData,
    userProgramList,
    scopeData,
    userId,
    closeToasterFunction,
    showAssesmentDetailTable,
    selectedCountryIdList,
    dialogOpen,
    dialogTitle,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allScopeData: any = (window as any).allScopeData;
  const countryHierarchyData = useSelector(
    (state: any) => state.masterForm.countryHierarchyData
  );
  const listProgram = useSelector(
    (state: any) => state.common.listProgram
  );
  const [distRegionList, setDistRegionList]: any = useState([]);
  const [showDistRegionFiled, setShowDistRegionFiled]: any = useState(false);
  const [isDistrict, setIsDistrict]: any = useState(false);
  const [isScopeDisabled, setScopeDisabled] = useState(false);
  const [isProgramDisabled, setProgramDisabled] = useState(false);
  const [programList, setProgramList]: any = useState([]);
  const allPrograms: any = (window as any).allPrograms;

  const validationSchema = yup.object().shape({
    assessmentType: yup
      .string()
      .required(t("validationMessage.assessmentTypeSelect")),
    supplyChain: yup.string().required(t("validationMessage.programSelect")),
    scope: yup.string().required(t("validationMessage.scopeSelect")),
  });

  const methods = useForm({
    validationSchema: validationSchema,
  });

  const ASSESSMENT_TYPES = [
    {
      id: ASSESSMENT_TYPE_CONST.NORMAL,
      label: t("common.normal"),
    },
    {
      id: ASSESSMENT_TYPE_CONST.RESILIENCY,
      label: t("common.resiliency"),
    },
  ];

  const { handleSubmit, errors, setValue } = methods;
  const userSelectedData: any = JSON.parse(
    localStorage.getItem("userSelectedData") || "{}"
  );

  useEffect(() => {
    if (Object.keys(userSelectedData).length > 0 && userSelectedData.userSelectedCountry.length > 0) {
      dispatch(
        actionFetchMasterCountryHierarchyData(
          userSelectedData.userSelectedCountry[0]
        )
      );
    }
  }, []);

  useEffect(() => {
    if (userProgramList.length) {
      setProgramList(userProgramList)
    }
  }, [userProgramList])

  const onSubmit = (data: any) => {
    const checkData = assesmentData.filter((assess: any) => {
      if (
        parseInt(assess.supplyChainId) === parseInt(data.supplyChain) &&
        parseInt(assess.scopeId) === parseInt(data.scope) &&
        assess.assessmentStatusId !== ASSESSMENT.STATUS.VALIDATED &&
        assess.assessmentType === parseInt(data.assessmentType)
      ) {
        if (data.hasOwnProperty("regionDist")) {
          if (assess.countryHierarchyId === data.regionDist)
            return true
          else if (assess.countryHierarchyId === null && data.regionDist === "") {
            return true
          }
          else
            return false
        } else {
          return true;
        }
      } else {
        return false
      }
    });
    if (checkData.length) {
      dispatch(
        openToaster(t("validationMessage.differentAssessmentProgramScopeSelect"), false)
      );
      closeToasterParticularTimeInterval();
      return;
    }
    const formData = {
      scopeId: data.scope,
      supplyChainId: data.supplyChain,
      assessmentStatusId: 1,
      countryId: selectedCountryIdList[0],
      createdBy: userId,
      updatedBy: userId,
      assesementType: parseInt(data.assessmentType),
      countryHierarchyId: data.regionDist ? data.regionDist : null,
    };
    showAssesmentDetailTable(formData);
    dispatch(
      actionFetchAllAssesmentData({
        roleId: userSelectedData.userSelectedRole,
        countryIds: selectedCountryIdList,
      })
    );
  };

  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFunction();
    }, COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
  };

  const handelScopeChange = ([event]: any) => {
    setValue('regionDist', "")
    const filterScope = allScopeData.filter(
      (item: any) => parseInt(item.id) === parseInt(event.target.value)
    );

    if (filterScope.length && countryHierarchyData.length) {
      if (filterScope[0].label === SCOPE_CONST.DISTRICT) {
        setShowDistRegionFiled(true);
        setIsDistrict(true);
        const filterDistList = countryHierarchyData.filter(
          (item: any) => item.type === COUNTRY_HIERARCHY_CONST.DISTRICT
        );
        const filterDropDownDistList = filterDistList.map((sub: any) => {
          return { id: sub.districtId, label: sub.districtName };
        });
        setDistRegionList(filterDropDownDistList);
      } else if (filterScope[0].label === SCOPE_CONST.REGIONAL) {
        setShowDistRegionFiled(true);
        setIsDistrict(false);
        const filterRegionList = countryHierarchyData.filter(
          (item: any) => item.type === COUNTRY_HIERARCHY_CONST.SUB_NATIONAL
        );
        const filterDropDownRegionList = filterRegionList.map((sub: any) => {
          return { id: sub.subnationalId, label: sub.subnationalName };
        });
        setDistRegionList(filterDropDownRegionList);
      } else {
        setShowDistRegionFiled(false);
        setDistRegionList([]);
      }
    }

    return event.target.value;
  };

  const closeAssesmentDialog = () => {
    setShowDistRegionFiled(false);
    dialogClose();
  };

  const handleProgramChange = ([event]: any) => {
    const { value } = event.target;
    if (allPrograms.length && Number(value) === Number(allPrograms[0].id)) {
      setValue("scope", allScopeData[0].id);
      setScopeDisabled(true);
    } else {
      setScopeDisabled(false);
      setValue("scope", '');
    }
    return value
  }

  const handleAssessChange = ([event]: any) => {
    const { value } = event.target;
    if (value === ASSESSMENT_TYPES[0].id) {
      let newProgramList = [...programList];
      let index = newProgramList.findIndex((i: any) => allPrograms.length && i.id === allPrograms[0].id && i.id != allPrograms[1].id)
      if (index !== -1) {
        newProgramList = newProgramList.filter(i => allPrograms.length && i.id !== allPrograms[0].id && i.id != allPrograms[1].id)
        setProgramList(newProgramList)
      }
      setValue("supplyChain", "")
      setProgramDisabled(false)
      setValue("scope", "");
      setScopeDisabled(false);
    } else
      if (allPrograms.length && value === ASSESSMENT_TYPES[1].id) {
        setProgramList(listProgram)
        setValue("supplyChain", allPrograms[0].id)
        setProgramDisabled(true)
        setValue("scope", allScopeData[0].id);
        setScopeDisabled(true);
      }
      else {
        setValue("supplyChain", "")
        setProgramDisabled(false)
        setValue("scope", "");
        setScopeDisabled(false);
      }
    setShowDistRegionFiled(false)
    return value
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={dialogClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}
          <DialogClose aria-label="close" onClick={closeAssesmentDialog}>
            <CloseIcon />
          </DialogClose>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description" className="nomargin">
            <FormContext {...methods}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormSelect
                    name="assessmentType"
                    label={t("assessment.assessmentType")}
                    options={ASSESSMENT_TYPES}
                    onChange={handleAssessChange}
                    required={true}
                    errorobj={errors}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    name="supplyChain"
                    label={t("common.program")}
                    options={programList}
                    onChange={handleProgramChange}
                    defaultValue=''
                    hideselectoption="true"
                    required={true}
                    errorobj={errors}
                    disabled={isProgramDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormSelect
                    name="scope"
                    label={t("common.scope")}
                    options={scopeData}
                    onChange={handelScopeChange}
                    required={true}
                    errorobj={errors}
                    disabled={isScopeDisabled}
                  />
                </Grid>
                {showDistRegionFiled ? (
                  <Grid item xs={6}>
                    <FormSelect
                      name="regionDist"
                      label={
                        isDistrict
                          ? t("common.district")
                          : t("assessment.regional")
                      }
                      options={distRegionList}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </FormContext>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="padding16-24">
          <Grid container alignItems="center" justify="flex-end" spacing={2}>
            <Grid item>
              <DialogButtonDefault
                variant="contained"
                color="default"
                onClick={closeAssesmentDialog}
              >
                {t("common.cancel")}
              </DialogButtonDefault>
            </Grid>
            <Grid item>
              <DialogButtonPrimary
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {t("common.create")}
              </DialogButtonPrimary>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AssesmentCreateFormComponent;
