import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  QUESTION,
  USER_PROFILE,
  ASSESMENT,
} from "../../../constants/api";
import { history } from "../../../store/config";
import { Box } from "@material-ui/core";

function MasterMenuDrawerComponent(props: any) {
  // This function used for render dynamic Master Menu
  //const { masterMenuData } = props;
  //const generateMenus = () => {
  // return masterMenuData.map((item: any) => {
  //   const url = `${MASTER}/${item.menuName}`;
  //   return (
  //     <ListItem button={true} key={item.id} component="a" href={url}>
  //       <ListItemText primary={item.menuName} />
  //     </ListItem>
  //   );
  // });
  // };

  const goToPath = (path: any) => (event: any) => {
    history.push(path);
  };
  return (
    <Box pl={1}>
      <List component="div" disablePadding={true}>
        {/* This is Dynamic  Master Menu Code is commented  */}
        {/* {generateMenus()} */}
        <ListItem button={true} onClick={goToPath(QUESTION)}>
          <ListItemText primary="Indicator Sets" />
        </ListItem>
        <ListItem button={true} onClick={goToPath(USER_PROFILE)}>
          <ListItemText primary="User Profile" />
        </ListItem>
        <ListItem button={true} onClick={goToPath(ASSESMENT)}>
          <ListItemText primary="Assesment Create" />
        </ListItem>
      </List>
    </Box>
  );
}

export default MasterMenuDrawerComponent;
