export const ActionTypes = {
  SCS_HUB_GET_APPLICATIONS_LIST: "SCS_HUB_GET_APPLICATIONS_LIST",
  SCS_HUB_GET_APPLICATIONS_LIST_SUCCESS: "SCS_HUB_GET_APPLICATIONS_LIST_SUCCESS",

  SCS_HUB_GET_USER_DETAILS: "SCS_HUB_GET_USER_DETAILS",
  SCS_HUB_GET_USER_DETAILS_SUCCESS: "SCS_HUB_GET_USER_DETAILS_SUCCESS",

  SCS_HUB_POST_ACCESS_REQUEST: "SCS_HUB_POST_ACCESS_REQUEST",
  SCS_HUB_POST_ACCESS_REQUEST_SUCCESS: "SCS_HUB_POST_ACCESS_REQUEST_SUCCESS",

  SCS_HUB_POST_APP_LOG_HISTORY: "SCS_HUB_POST_APP_LOG_HISTORY",

  SCS_HUB_SHOW_SCMM: "SCS_HUB_SHOW_SCMM",
  SCS_HUB_SHOW_APP_LIST: "SCS_HUB_SHOW_APP_LIST"
};

export const SCS_FULL_NAME: string = `Supply Chain Strengthening Hub`;
export const GET_APPLICATIONS_LIST: string = `/api/scsHub/GetApplications`;
export const GET_SCS_USER_DETAILS: string = `/api/scsHub/GetUserDetails`;
export const POST_SCS_USER_ACCESS_REQUEST: string = `/api/scsHub/RaiseAccessRequest`;
export const POST_APP_LOG_HISTORY: string = `/api/UserDetails/LogHistory`;