import React, { useState } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AccessRequestGrid, ArrowBackIosIconCustom, DialogButtonDefault, DialogButtonPrimary, SubHeaderWrapper, } from "../assets/styles";
import FormInput from "../../components/form-controls/input";
import { FormContext } from "react-hook-form";
import { useForm } from "react-hook-form";
import FormCheckbox from "../../components/form-controls/checkbox";
import { useDispatch } from 'react-redux';
import { actionPostAccessRequest } from "scs-hub/actions";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { testInputValueWithRegex } from "utils/security";
import InfoIcon from '@material-ui/icons/Info';
import FormSelectAutoComplete from "components/form-controls/select-autocomplete";

function AccessRequestPage(props: any) {
  const { showHideForm, scsUserDetails, listOfApp, pendingRequestsApp, countryList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationData: any = (window as any).applicationData;
  let listOfCountry: any = []

  if (countryList.length) {
    if (countryList && countryList.length) {
      countryList.map((country: any) => {
        listOfCountry.push({
          key: country.id,
          id: country.id,
          value: country.id,
          label: country.name,
          region: country.region,
          isoalpha3code: country.isoalpha3code,
        });
      })

      listOfCountry.sort((a: any, b: any) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }
  }

  const [state, setState] = useState({
    app_0: false,
    app_1: false,
    app_2: false,
    app_3: false,
    app_4: false,
  });

  const validateSchema = yup.object().shape({
    app_0_countryDutyStation: yup.object().nullable(true),
    app_0_orgnization: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_0_reasonForApplying: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_0_preferredRole: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_0_preferredProgram: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_1_countryDutyStation: yup.object().nullable(true),
    app_1_orgnization: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_1_reasonForApplying: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_1_preferredRole: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_1_preferredProgram: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_2_countryDutyStation: yup.object().nullable(true),
    app_2_orgnization: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_2_reasonForApplying: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_2_preferredRole: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_2_preferredProgram: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_3_countryDutyStation: yup.object().nullable(true),
    app_3_orgnization: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_3_reasonForApplying: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_3_preferredRole: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_3_preferredProgram: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_4_countryDutyStation: yup.object().nullable(true),
    app_4_orgnization: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_4_reasonForApplying: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_4_preferredRole: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
    app_4_preferredProgram: yup.string().trim().test("Valid Input", t("validationMessage.tagNotAllow"), (value: any) => !testInputValueWithRegex(value)),
  })

  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, errors, reset, setError } = methods;
  //to find whill all app user selected
  const keys = Object.keys(state);
  const selectedAppList = keys.filter(function (key) {
    return state[key]
  });
  //end
  const handleChange = ([event]: any, app:any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    const VC_APP = applicationData[1].id;
    if (app.id === VC_APP && !event.target.checked && errors && errors.hasOwnProperty(`${event.target.name}_countryDutyStation`)) {
      let data = {};
      data[`${event.target.name}_countryDutyStation`]=null;
      reset(data);
    }
  };
  const createTableOfApplication = () => {
    const fillterRequestedList = listOfApp.filter((obj: any) => pendingRequestsApp.find((obj2: any) => obj.id === obj2.applicationId));
    return listOfApp.map((appName: any, index: any) => {
      let isProgramDisabled = false;
      if (appName.id === applicationData[1].id)
        isProgramDisabled = true;
      let isAccessRequestSent = [];
      if (fillterRequestedList.length) {
        isAccessRequestSent = fillterRequestedList.filter((accessApp: any) => accessApp.id === appName.id)
      }
      return (
        <Typography component="tr" key={index}>
          <Typography component="td">
            <FormCheckbox
              id={appName.id}
              name={`app_${index}`}
              label={appName.name}
              checked={state[`app_${index}`]}
              value={state[`app_${index}`]}
              onChange={(event:any)=>handleChange(event, appName)}
              color="primary"
              disabled={!appName.isActive || isAccessRequestSent.length > 0}
            />
          </Typography>
          <Typography component="td">
            <FormSelectAutoComplete
              name={`app_${index}_countryDutyStation`}
              placeholder={t("scsHub.countryDutyStation")}
              options={listOfCountry}
              errorobj={errors}
              isDisabled={!state[`app_${index}`]}
              variant="standard"
            />
            <FormInput
              className="hiddenTextFiled"
              type="hidden"
              name={`app_${index}_id`}
              label={"appId"}
              defaultValue={appName.id}
            />
          </Typography>
          <Typography component="td">
            <FormInput
              type="text"
              name={`app_${index}_orgnization`}
              label={t("scsHub.organization")}
              disabled={!state[`app_${index}`]}
              errorobj={errors}
            />
          </Typography>
          <Typography component="td">
            <FormInput
              type="text"
              name={`app_${index}_reasonForApplying`}
              label={t("scsHub.reasonForApplying")}
              disabled={!state[`app_${index}`]}
              errorobj={errors}
            />
          </Typography>
          <Typography component="td">
            <FormInput
              type="text"
              name={`app_${index}_preferredRole`}
              label={t("scsHub.preferredRole")}
              disabled={!state[`app_${index}`]}
              errorobj={errors}
              fullWidth={false}
              className="width-80"
            />
            {appName.prefferedRole.length ? (
              <Tooltip className="paddingT15" title={appName.prefferedRole.join(', ').toString()}>
                <InfoIcon className="infoIconBg" />
              </Tooltip>) : null}
          </Typography>
          <Typography component="td">
            <FormInput
              type="text"
              name={`app_${index}_preferredProgram`}
              label={t("scsHub.preferredProgram")}
              disabled={isProgramDisabled || !state[`app_${index}`]}
              errorobj={errors}
              fullWidth={false}
              className="width-80"
            />
            {appName.prefferedPrograms.length || appName.aliaseName === applicationData[1].aliaseName ? (
              <Tooltip className="paddingT15" title={appName.prefferedPrograms.length ? appName.prefferedPrograms.join(', ').toString() : t("scsHub.preferredVCProgram")}>
                <InfoIcon className="infoIconBg" />
              </Tooltip>
            )
              : null}
          </Typography>
        </Typography>
      )
    })
  }

  //this return obje with same prifix
  const findValueByPrefix = (object: any, prefix: string) => {
    return Object.keys(object).filter(function (k) {
      return k.indexOf(prefix) == 0;
    }).reduce(function (newData, k) {
      newData[k] = object[k];
      return newData;
    }, {});
  }
  //end

  const onSubmit = (data: any) => {
    const requestAppArr: any = [];
    let isError = false;
    selectedAppList.forEach((app: any) => {
      const filterValue = findValueByPrefix(data, app);
      const VC_APP = applicationData[1].id;
      if(filterValue[`${app}_id`]===VC_APP && !filterValue[`${app}_countryDutyStation`]){
        setError(`${app}_countryDutyStation`,"required", t("validationMessage.country/dutyStation"));
        isError=true;
      }

      const requestAppObj = {
        "id": scsUserDetails.userId,
        "applicationId": filterValue[`${app}_id`],
        "orgnization": filterValue[`${app}_orgnization`],
        "countryDutyStation": filterValue[`${app}_countryDutyStation`] ? filterValue[`${app}_countryDutyStation`].label : "",
        "reasonForApplying": filterValue[`${app}_reasonForApplying`],
        "preferredRole": filterValue[`${app}_preferredRole`],
        "preferredProgram": filterValue[`${app}_preferredProgram`]
      }
      requestAppArr.push(requestAppObj);
    });

    const formValue = {
      "userId": scsUserDetails.userId,
      "firstName": scsUserDetails.firstName,
      "lastName": scsUserDetails.lastName,
      "email": scsUserDetails.email,
      "requestApplications": requestAppArr
    }
    if(!isError)
      dispatch(actionPostAccessRequest(formValue));
  }

  return (
    <>
      <SubHeaderWrapper>
        <Grid container justify="space-between" alignItems="center" className="padding0-10">
          <Grid item>
            <IconButton
              className="padding10"
              color="default"
              onClick={() => showHideForm(false)}
            >
              <ArrowBackIosIconCustom className="arRotate180" />
            </IconButton>
            <Typography component="span" className="common-label">
              {t("scsHub.accessRequest")}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item>
                <Typography component="strong" className="strong-text">
                  {t("scsHub.userName")} :
                </Typography>
                <Typography component="span">
                  {scsUserDetails.firstName} {scsUserDetails.lastName}
                </Typography>
                <Typography component="span" className="marginLR10">|</Typography>
                <Typography component="strong" className="strong-text">
                  {t("scsHub.emailId")} :
                </Typography>
                <Typography component="span">
                  {scsUserDetails.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SubHeaderWrapper>
      <FormContext {...methods}>
        <AccessRequestGrid>
          <Typography component="table">
            <Typography component="thead">
              <Typography component="tr">
                <Typography component="th">{t("scsHub.application")}</Typography>
                <Typography component="th">{t("scsHub.countryDutyStation")}</Typography>
                <Typography component="th">{t("scsHub.organization")}</Typography>
                <Typography component="th">{t("scsHub.reasonForApplying")}</Typography>
                <Typography component="th">{t("scsHub.preferredRole")}</Typography>
                <Typography component="th">{t("scsHub.preferredProgram")}</Typography>
              </Typography>
            </Typography>
            <Typography component="tbody">
              {createTableOfApplication()}
            </Typography>
          </Typography>
        </AccessRequestGrid>
        <Grid container spacing={2} justify="flex-end" className="padding20">
          <Grid item>
            <DialogButtonDefault variant="contained" color="default" onClick={() => showHideForm(false)}>{t("common.cancel")}</DialogButtonDefault>
          </Grid>
          <Grid item>
            <DialogButtonPrimary variant="contained" color="primary" disabled={selectedAppList.length === 0} onClick={handleSubmit(onSubmit)}>{t("common.submit")}</DialogButtonPrimary>
          </Grid>
        </Grid>
      </FormContext>
    </>
  );
}

export default AccessRequestPage;
