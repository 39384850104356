import axios from "axios";
import { BASE_PATH } from "../constants/api";
import { decryptData, encryptData } from "utils/security";
import { getStringAfterSubstring, getStringBeforeSubstring } from "utils/data";
import { GET_ACCOUNT_IS_AUTHENTICATED } from "containers/header/constants";

const api = axios.create({
    baseURL: BASE_PATH,
});

api.interceptors.request.use(
    (config: any) => {
        config.headers.Language = localStorage.getItem("language");
        config.headers["Content-Type"] = "application/json";
        config.headers["Cache-Control"] = "no-store, private";
        config.headers["X-XSS-Protection"] = "0";
        // Encryption for GET calls
        if (config.method === "get") {
            // Check if api URl includes any query params
            let queryParams = "";
            if (config.url.indexOf("?") !== -1) {
                // Encrypt the query params data
                queryParams = getStringAfterSubstring(config.url, "?");
                const encryptedData = encryptData(queryParams);
                const mainUrl = getStringBeforeSubstring(config.url, "?");
                const finalUrl = `${mainUrl}?p=${encryptedData}`;
                config.url = finalUrl;
            }
        }

        // Encryption for POST calls
        if (config.method === "post" || config.method === "put") {
            config.data = encryptData(JSON.stringify(config.data, undefined, 1));
        }
        return config;
    },
    (err) => {
        console.log(err);
        Promise.reject(err);
    }
);


api.interceptors.response.use(
    (config: any) => {
        if (
            config.config.method === "get" &&
            config.config.url !== GET_ACCOUNT_IS_AUTHENTICATED
        ) {
            // Decrypt response data
            let responseData = config.data;
            if (!(config.data instanceof Blob)) {
                responseData = decryptData(responseData);
                config.data = JSON.parse(responseData);
            }
        }

        if (
            (config.config.method === "post" || config.config.method === "delete" || config.method === "put") &&
            config.status === 200 &&
            config.data
        ) {
            // Decrypt response data
            let responseData = config.data;
            if (!(config.data instanceof Blob)) {
                responseData = decryptData(responseData);
                config.data = JSON.parse(responseData);
            }
        }
        return config;
    },
    (error) => {
        if (error.response.status !== 500) {
            let responseData = error.response.data;
            responseData = decryptData(responseData);
            error.response.data = JSON.parse(responseData);
            throw error;
        } else {
            throw error;
        }
    }
);
export default api;
