import { AppBar, Button, Toolbar, Typography, Container, Grid } from "@material-ui/core";
import { Authentication } from "components/authentication";
import { LOGIN_PATH, SIGN_OUT_PATH } from "constants/api";
import { actionSetAuthenticationSuccess } from "containers/user-profile/actions";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { closeToaster, setLoaderVisibility } from "store/common/actions";
import { history } from "../store/config";
import logo from "./assets/images/uniceflogo.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { LandingLanguageBar, VerticalLine } from "./assets/styles";
import { SCS_FULL_NAME } from "./constants";
import { Toaster } from "components/common/toaster.component";
import { useTranslation } from "react-i18next";
import LanguagSelectionComponent from "containers/header/language";

function ScsHeaderComponent(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = Authentication();
  const toasterMessage = useSelector(
    (state: any) => state.common.toasterMessage
  );
  const isToasterVisible = useSelector(
    (state: any) => state.common.isToasterVisible
  );
  const isSuccess = useSelector((state: any) => state.common.isSuccess);

  const login = () => {
    localStorage.removeItem("userSelectedData");
    dispatch(setLoaderVisibility(true));
    localStorage.setItem("loginPage", "1");
    history.replace(LOGIN_PATH);
    window.location.reload();
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(actionSetAuthenticationSuccess(null));
    window.location.replace(SIGN_OUT_PATH);
    history.replace(SIGN_OUT_PATH);
    window.location.reload();
    dispatch(setLoaderVisibility(false));
  };

  return (
    <>
      <Toaster message={toasterMessage} open={isToasterVisible} close={closeToaster} success={isSuccess} />
      <AppBar position="relative">
        <Toolbar disableGutters={true}>
          <Container maxWidth={"xl"} className="position-relative">
            <Grid container={true} direction="row" alignItems="center" justify="space-between">
              <Grid item={true}>
                <Grid container spacing={1} alignItems="center" justify="flex-start">
                  <Grid item>
                    <Typography component="img" src={logo} alt="Unicef Logo" />
                  </Grid>
                  <Grid item>
                    <VerticalLine />
                  </Grid>
                  <Grid item>
                    <Typography component="h5" color="inherit" className="app-name">
                      {SCS_FULL_NAME}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container alignItems="center" justify="flex-end">
                  <Grid item>
                    <LandingLanguageBar>
                      <LanguagSelectionComponent />
                    </LandingLanguageBar>
                  </Grid>
                  <Grid item>
                    {
                      isAuthenticated ?
                        <Button color="inherit" className="text-capitalize" onClick={logout}>
                          <AccountCircleOutlinedIcon className="marginR10 arMarginL10R0" />
                          {t("home.logout")}
                        </Button>
                        :
                        <Button color="inherit" onClick={login} className="text-capitalize">
                          <AccountCircleOutlinedIcon className="marginR10 arMarginL10R0" />
                          {t("home.login")}
                        </Button>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default ScsHeaderComponent;