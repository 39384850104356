
import { ActionTypes } from "./constants";

const initialState = {
  applicationsList: [],
  scsUserDetails: null,
  accessRequestSent: false,
  showSCMM: false,
  showAppList: true
};

export const scsHubReducer = (state: any = initialState, action: any) => {
  switch (action.type) {

    case ActionTypes.SCS_HUB_GET_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        applicationsList: action.payload.data || [],
      };
    case ActionTypes.SCS_HUB_GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        scsUserDetails: action.payload.data || null,
      };
    case ActionTypes.SCS_HUB_POST_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        accessRequestSent: action.payload.data || null,
      };
    case ActionTypes.SCS_HUB_SHOW_SCMM:
      return {
        ...state,
        showSCMM: action.payload.data || null,
      };
    case ActionTypes.SCS_HUB_SHOW_APP_LIST:
      return {
        ...state,
        showAppList: action.payload.data || null,
      };

    default:
      return state;
  }
};
