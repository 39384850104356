import api from "../../utils/api";
import {
  UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS,
  UPDATE_ASSESMENT_QUESTION_DETAIL,
  UPDATE_ASSESMENT_QUESTION_COMMENT,
  GET_ASSESSMENT_LEVEL_DETAILS,
  GET_ASSESSMENT_VALIDATION_STATUS,
  GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA,
  UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA,
  UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA,
  UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA,
  GET_EXPORT_ASSESMENT_DETAILS,
  GET_ASSESSMENT_CURRENT_SCORE_BY_PROGRAM_ID_DETAILS,
  GET_ASSESSMENT_REPORT,
  GET_ASSESSMENT_REGION_REPORT,
  DELETE_ASSESSMENT_BY_ID,
  GET_ASSESSMENT_BY_ASSESSMENT_ID_DETAILS,
  GET_ASSESSMENT_CREATE_DETAILS,
  SAVE_ASSESSMENT_CREATE_DETAILS,
  GET_ASSESSMENT_SCORING,
} from "./constants";

/**
 * Function for getting some data
 */

export const fetchAssessmentCreateDetailData = async (data: any) => {
  const response = await api.post(`${GET_ASSESSMENT_CREATE_DETAILS}`, data);
  return response;
};

export const saveAssessmentCreateData = async (assesmentData: any) => {
  const response = await api.post(`${SAVE_ASSESSMENT_CREATE_DETAILS}`, assesmentData);
  return response;
};

export const fetchAssessmentBYIdDetailData = (assessmentId: any) => {
  return api.get(`${GET_ASSESSMENT_BY_ASSESSMENT_ID_DETAILS}?assessmentId=${assessmentId}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const updateAssesmentValidationStatusData = (assesmentData: any) => {
  return api
    .post(`${UPDATE_ASSESMENT_DETAIL_VALIDATION_STATUS}`, assesmentData)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const updateAssesmentQuestionDetailData = (
  assesmentQuestionData: any
) => {
  return api
    .post(`${UPDATE_ASSESMENT_QUESTION_DETAIL}`, assesmentQuestionData)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const updateAssesmentQuestionCommentData = (
  assesmentQuestionCommentData: any
) => {
  return api
    .post(`${UPDATE_ASSESMENT_QUESTION_COMMENT}`, assesmentQuestionCommentData)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const fetchAssesmentLevelByIdData = (lookUp: any) => {
  return api.get(`${GET_ASSESSMENT_LEVEL_DETAILS}?assessmentId=${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const fetchAssesmentValidationStatusData = (lookUp: any) => {
  return api.get(`${GET_ASSESSMENT_VALIDATION_STATUS}/${lookUp}`).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const updateAssesmentQuestionValidationCommentDetailData = (
  assesmentQuestionData: any
) => {
  return api
    .post(
      `${UPDATE_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA}`,
      assesmentQuestionData
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const fetchAssesmentQuestionValidationCommentBYIdDetailData = (
  lookUp: any
) => {
  return api
    .get(`${GET_ASSESMENT_QUESTION_VALIDATION_COMMENT_DATA}/${lookUp}`)
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const updateAssesmentQuestionResetValidationDetailData = (
  assesmentQuestionData: any
) => {
  return api
    .post(
      `${UPDATE_ASSESMENT_QUESTION_RESET_VALIDATION_STATUS_DATA}`,
      assesmentQuestionData
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const updateAssesmentQuestionResetAssessorStatusDetailData = (
  assesmentQuestionData: any
) => {
  return api
    .post(
      `${UPDATE_ASSESMENT_QUESTION_RESET_ASSESSOR_STATUS_DATA}`,
      assesmentQuestionData
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const exportAssesmentDetailData = (formData: any) => {
  return api
    .post(`${GET_EXPORT_ASSESMENT_DETAILS}`, formData, { responseType: "blob" })
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getAssessmentScoringData = (
  assesmentStatusRequestData: any
) => {
  return api
    .post(
      `${GET_ASSESSMENT_SCORING}`,
      assesmentStatusRequestData
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const fetchProgramCurrentBYIdDetailData = (lookUp: any) => {
  return api.post(`${GET_ASSESSMENT_CURRENT_SCORE_BY_PROGRAM_ID_DETAILS}`, lookUp).then(
    (resp: any) => {
      return resp;
    },
    (err: any) => {
      console.log(err);
      return err;
    }
  );
};

export const getAssessmentReportData = (
  lookUp: any
) => {
  return api
    .post(
      `${GET_ASSESSMENT_REPORT}`,
      lookUp
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const getAssessmentReportRegionData = (
  lookUp: any
) => {
  return api
    .post(
      `${GET_ASSESSMENT_REGION_REPORT}`,
      lookUp
    )
    .then(
      (resp: any) => {
        return resp;
      },
      (err: any) => {
        console.log(err);
        return err;
      }
    );
};

export const deleteAssesmentDetailById = async (data: any) => {
  const response = await api.delete(`${DELETE_ASSESSMENT_BY_ID}`, { data: JSON.stringify(data) });
  return response;
};
