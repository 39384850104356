import { LANGUAGE } from "constants/data";
import { ActionTypes } from "./constants";

export const actionFetchMasterMenuData = (lookUp: any) => ({
  payload: { lookUp },
  type: ActionTypes.HEADER_MASTER_MENU_FETCH_DATA,
});

export const actionFetchMasterMenuDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_MASTER_MENU_FETCH_DATA_SUCCESS,
});

export const actionFetchLoginData = () => ({
  type: ActionTypes.HEADER_LOGIN_USER_FETCH_DATA,
});

export const actionFetchLoginDataSuccess = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_LOGIN_USER_FETCH_DATA_SUCCESS,
});

export const actionHeaderSetLanguageData = (language: any) => ({
  payload: {
    language:
      localStorage.getItem("language") == null
        ? LANGUAGE.ENGLISH
        : localStorage.getItem("language"),
  },
  type: ActionTypes.HEADER_LANGUAGE_SET_DATA,
});

export const actionHeaderSetOrganization = (org: string) => ({
  payload: {
    data: org,
  },
  type: ActionTypes.HEADER_SET_ORGANIZATION,
});

export const actionSetUserLoginData = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_SET_LOGIN_USER_DATA_SUCCESS,
});

export const actionHeaderUserSetting = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_IS_USER_SETTING_SUCCESS,
});

export const actionHeaderUserDropDownShow = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_USER_DROPDOWN_SHOW,
});

export const actionHeaderUserSettingPopUpShow = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_USER_SETTING_POPUP_SHOW,
});

export const actionHeaderUserSettingClicked = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.HEADER_USER_SETTING_CLICKED,
});

export const actionSetAssessmentTypeFilter = (data: boolean) => ({
  payload: {
    data,
  },
  type: ActionTypes.ASSESSMENT_TYPE_FILTER_TOGGLE,
});

export const actionSetApplicationDetails = (data: any) => ({
  payload: {
    data
  },
  type: ActionTypes.HEADER_SET_APP_DETAILS
})

export const actionSetApplicationRegisterUser = (data: any) => ({
  payload: {
    data
  },
  type: ActionTypes.HEADER_SET_REGISTER_USER
})
