import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actionDeleteMasterDynamicFormFieldDataDetail } from "../actions";
import { PageWrapper } from "../../../styles/common";
import { FullWidthContainer } from "../../../styles/common";
import MaterialTable from "material-table";
import { ConfirmDialog } from "../../../components/common/confirm-dialog.component";
import { useTranslation } from "react-i18next";

function MasterMenuTableComponent(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [tblHeader, setTblHeader] = useState([]);
  const [tblData, setTblData] = useState([{}]);
  const [parentMenuData, setParentMenuData] = useState([]);
  const [deleteRowData, setDeleteRowData] = useState({});
  const { functionEditForm, masterMenuFormFieldAndData, userId } = props;
  let fieldData: any;
  let parentColumnData: any;
  useEffect(() => {
    generateMenuFields();
  }, [masterMenuFormFieldAndData]);

  const generateMenuFields = () => {
    // Filter values based on key showInListing
    fieldData = masterMenuFormFieldAndData.dynamicFormFieldsList.filter(
      (item: any) => {
        if (item.showInListing) {
          return item;
        }
      }
    );
    parentColumnData = fieldData.filter((item: any) => {
      if (item.parentTableColumns) {
        return item;
      }
    });
    if (parentColumnData.length) {
      setParentMenuData(parentColumnData[0].parentTable);
    } else {
      generateTableHeader(fieldData);
    }
  };
  const generateTableHeader = (values: any) => {
    const tblHeaderTemp = values.map((item: any) => {
      let name = item.controlLabel;
      // This is a special scenario to handle the word with 'Id' in it. It will only occur for columns who have a foreign key in it.
      if (parentColumnData.length) {
        name = item.controlLabel;
        if (!name) {
          name = item.controlLabel;
        }
      }
      return {
        field: item.controlId,
        title: name,
      };
    });
    if (parentColumnData.length) {
      generateTableDataWithParent(tblHeaderTemp);
    } else {
      generateTableData(tblHeaderTemp);
    }

    setTblHeader(tblHeaderTemp);
  };

  const generateTableData = (tblHeaderValues: any) => {
    const listDynamicForm = masterMenuFormFieldAndData.dynamicFormDetailsList;
    const tblHeaderLength = tblHeaderValues.length;
    const listDynamicFormLength = listDynamicForm.length;
    const tblDataTemp = [];

    for (let i = 0; i < listDynamicFormLength; i++) {
      let tblRowData = {};
      for (let j = 0; j < tblHeaderLength; j++) {
        const controlField = tblHeaderValues[j].field;
        const data = listDynamicForm[i][controlField];
        tblRowData = {
          ...tblRowData,
          [`${controlField}`]: data,
          formId: listDynamicForm[i].formId,
        };
      }
      tblDataTemp.push(tblRowData);
    }
    tblData.sort((a: any, b: any) => (a.fld1 > b.fld1 ? 1 : -1));
    setTblData(tblDataTemp);
  };

  const generateTableDataWithParent = (tblHeaderValues: any) => {
    const listDynamicForm = masterMenuFormFieldAndData.dynamicFormDetailsList;
    const tblHeaderLength = tblHeaderValues.length;
    const listDynamicFormLength = listDynamicForm.length;
    const tblDataTemp = [];
    const parentColumn = parentColumnData[0].parentTableColumns;
    for (let i = 0; i < listDynamicFormLength; i++) {
      let tblRowData = {};
      for (let j = 0; j < tblHeaderLength; j++) {
        const controlField = tblHeaderValues[j].field;
        let data = "";
        const tmpData = parentMenuData.filter((item: any) => {
          if (listDynamicForm[i][controlField] === item.formId.toString()) {
            return item;
          }
        });

        if (tmpData.length) {
          data = tmpData[0][parentColumn];
          tblRowData = {
            ...tblRowData,
            [`${controlField}`]: data,
            formId: listDynamicForm[i].formId,
            parentFormId: tmpData[0]["formId"],
          };
        } else {
          data = listDynamicForm[i][controlField];
          tblRowData = {
            ...tblRowData,
            [`${controlField}`]: data,
            formId: listDynamicForm[i].formId,
          };
        }
      }
      tblDataTemp.push(tblRowData);
    }
    tblDataTemp.sort((a: any, b: any) => (a.fld1 > b.fld1 ? 1 : -1));
    setTblData(tblDataTemp);
  };

  const handlerEditRowData = (event: any, rowData: any) => {
    functionEditForm(rowData);
  };

  const handlerOpenConfirmDialog = (value: any) => {
    value.MenuName = props.formKey;
    value.updatedBy = userId;
    setDeleteRowData(value);
    setOpenConfirmDialog(true);
  };
  const handlerCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const handlerDeleteRowData = () => {
    setOpenConfirmDialog(true);
    dispatch(actionDeleteMasterDynamicFormFieldDataDetail(deleteRowData));
    handlerCloseConfirmDialog();
  };
  return (
    <PageWrapper>
      <ConfirmDialog
        open={openConfirmDialog}
        close={handlerCloseConfirmDialog}
        title= {t("indicators.areYouSureDelete")}
        content=""
        ok={handlerDeleteRowData}
      />
      {tblHeader && tblHeader.length > 0 && (
        <FullWidthContainer>
          <MaterialTable
            columns={tblHeader}
            data={tblData}
            title={props.formTitle}
            style={{
              minWidth: "650px",
              overflow: "auto",
            }}
            options={{
              filtering: true,
              pageSizeOptions: [10, 20, 50],
              pageSize: 10,
              emptyRowsWhenPaging: false,
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, rowData) => handlerEditRowData(event, rowData),
              },
              {
                icon: "delete",
                tooltip: "Delete",
                onClick: (event, rowData) => handlerOpenConfirmDialog(rowData),
              },
            ]}
            localization={{
              body: {
                filterRow: {
                  filterTooltip: t("pagination.filter"),
                },
                emptyDataSourceMessage: t("common.noDataAvailable")
              },
              pagination: {
                firstTooltip: t("pagination.firstPage"),
                previousTooltip: t("pagination.prevPage"),
                nextTooltip: t("pagination.nextPage"),
                lastTooltip: t("pagination.lastPage"),
                labelRowsSelect: t("common.rows"),
              },
            }}
          />
        </FullWidthContainer>
      )}
    </PageWrapper>
  );
}

export default MasterMenuTableComponent;
