import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { sortObjectDataByAsending } from "utils/data";
import { actionSaveAdminSelectedRegion } from "../actions";
import {
  BoxShadowWrapper,
  AssessmentSubHeader,
  ScrollableScmmAppBar,
} from "styles/common";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  actionDetailsSaveData,
  actionFetchAssessmentProgramScoreByIdData,
} from "containers/assessment-create/actions";
import { ASSESSMENT_TYPE_CONST, MAP_CONSTANTS } from "constants/data";
import AssessmentOverviewScoreComponent from "containers/assessment-overview/assessment-overview-score";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "1220px",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function AdminRsaOverviewTabComponent(props: any) {
  const { t } = useTranslation();
  const {
    currentAssessmentId,
    setShowTabs,
    showTabs,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [isCountryView, setIsCountryView] = useState(false);

  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );
  const adminSelectedRegion = useSelector(
    (state: any) => state.admin.adminSelectedRegion
  );
  const adminSelectedCountry = useSelector(
    (state: any) => state.admin.adminSelectedCountry
  );
  const adminAssesmentData = useSelector(
    (state: any) => state.admin.adminAssesmentData
  );
  const userSelectedSettingData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  );

  const adminMapView = useSelector((state: any) => state.admin.adminMapView);
  const listProgram = useSelector((state: any) => state.common.listProgram);
  const adminAllAssesmentStatus = useSelector(
    (state: any) => state.admin.adminAllAssesmentStatus
  );

  useEffect(() => {
    if (adminMapView) {
      if (adminMapView === MAP_CONSTANTS.COUNTRY) {
        setIsCountryView(true);
      } else {
        setIsCountryView(false);
      }
    }
  }, [adminMapView]);

  useEffect(() => {
    if (
      adminAssesmentData &&
      adminAssesmentData.length &&
      currentAssessmentId
    ) {
      const lastRowData = adminAssesmentData.filter(
        (data: any) => data.id === currentAssessmentId
      );
      dispatch(actionDetailsSaveData(lastRowData[0]));
    }
  }, [adminAssesmentData]);

  useEffect(() => {
    if (adminSelectedRegion && listOrgWiseCountry.length) {
      const filterCountryList = listOrgWiseCountry.filter(
        (country: any) => country.region === adminSelectedRegion
      );
      if (filterCountryList.length) {
        setTabValue(0);
        setCountriesList(sortObjectDataByAsending(filterCountryList, "label"));
        setShowTabs(true);
      }
    }
  }, [adminSelectedRegion, listOrgWiseCountry]);

  useEffect(() => {
    if (adminSelectedCountry) {
      countriesList.map((item: any, i) => {
        if (item.label.trim() === adminSelectedCountry) {
          setTabValue(i);
        }
      });
    }
  }, [adminSelectedCountry]);

  useEffect(() => {
    dispatch(actionSaveAdminSelectedRegion(""));
    setShowTabs(false);

  }, [userSelectedSettingData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const filterProgramData = (country: any) => {
    let filterProgamList: any = [];
    const programData: any = adminAllAssesmentStatus.filter(
      (cr: any) => cr.countryId === country
    );
    if (programData.length) {
      listProgram.map((prog: any) => {
        programData[0].programList.map((countryProg: any) => {
          if (countryProg === parseInt(prog.id)) {
            filterProgamList.push(prog);
          }
        });
      });
    }
    return filterProgamList;
  };
  const createTabHeader = (list: any) => {
    return list.map((item: any, i: any) => {
      return <Tab label={item.label} {...a11yProps(i)} key={i} />;
    });
  };
  const getProgramData = (
    statusId: any,
    countryArrayList: any,
    programId: any
  ): any => {
    dispatch(
      actionFetchAssessmentProgramScoreByIdData({
        status: statusId,
        country: countryArrayList,
        programId: programId,
        assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      })
    );
  };
  const createTabPanel = (list: any) => {
    return list.map((item: any, index: any) => {
      return (
        <TabPanel value={tabValue} index={index} key={index}>
          <Typography component="div" className="marginM24">
            <AssessmentOverviewScoreComponent
              programList={filterProgramData(item.id)}
              selectedCountryIdList={[item.id]}
              getProgramData={getProgramData}
              isDefaultCallProgramScoreData={true}
            />
          </Typography>
        </TabPanel>
      );
    });
  };
  return (
    <>
      {showTabs && (
        <>
          {isCountryView && (
            <>
              <BoxShadowWrapper>
                <AssessmentSubHeader>
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    className="padding20-15 border-bottom"
                  >
                    <Grid item>
                      <Typography component="strong" className="strong-text">
                        {`${t("RSAData.selectedRegion")}: `}
                      </Typography>
                      <Typography component="span">
                        {adminSelectedRegion}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="strong" className="strong-text paddingLR24">
                        {`|`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="strong" className="strong-text">
                        {`${t("common.selectedCountriesAreas")}: `}
                      </Typography>
                      <Typography component="span">
                        {countriesList.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </AssessmentSubHeader>
                <div className={classes.root}></div>
                <ScrollableScmmAppBar position="static">
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                  >
                    {createTabHeader(countriesList)}
                  </Tabs>
                </ScrollableScmmAppBar>
                {createTabPanel(countriesList)}
              </BoxShadowWrapper>

            </>
          )}
        </>
      )}
    </>
  );
}
