import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import {
  getDuplicateArrayElements,
  sortObjectDataByAsending,
} from "../../../utils/data";
import { useTranslation } from "react-i18next";
import {
  WarningLabel,
  BigContainer,
  CustomDevider,
  GreenFormControlLabel,
  BlueSubHeader,
  UserContainer,
  AddRoleButton,
  DialogButtonDefault,
  DialogButtonPrimary,
} from "../../../styles/common";
import FormInput from "../../../components/form-controls/input";
import UserProfileRegionComponent from "./user-profile-region";
import { actionSaveUserProfileData } from "../actions";
import { openToaster } from "../../../store/common/actions";
import Typography from "@material-ui/core/Typography";
import FormCheckbox from "../../../components/form-controls/checkbox";
import Switch from "@material-ui/core/Switch";
import * as yup from "yup";
import { COMMON_CONSTANT, USER, VALID_INPUT } from "constants/data";
import FormSelect from "components/form-controls/select";
import { testInputValueWithRegex } from "utils/security";
import _ from "lodash";

function UserProfileFormComponent(props: any) {
  const {
    editFormValues,
    isEdit,
    showHideTableAndForm,
    roleData,
    listRoles,
    fieldsRegionArray,
    counterRegion,
    setCounterRegion,
    setFieldsRegionArray,
    closeToasterFun,
    userId,
    userName,
    createdDate,
    modifiedDate,
    organizationValue,
    setOrganizationValue,
    allOrganization,
    listRegions,
    listWhoRegions,
    listCountry,
    listWhoCountry,
    regionListOrgWise,
    setRegionListOrgWise,
    countryListOrgWise,
    setCountryListOrgWise,
    setIsRefreshTable
  } = props;

  const allPrograms: any = (window as any).allPrograms;
  const applicationData: any = (window as any).applicationData;
  const listPrograms = useSelector((state: any) => state.common.listProgram);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [validateSchema, setValidateSchema]: any = useState({});
  const methods = useForm({
    validationSchema: validateSchema,
  });
  const { handleSubmit, setValue, getValues, errors } = methods;

  const saveUserData = useSelector(
    (state: any) => state.userProfile.saveUserData
  );
  let listOfCountry: any = [];
  const [userStatus, setUserStatus]: any = useState(true);
  const [userStatusLabel, setUserStatusLabel]: any = useState(
    t("common.active")
  );
  const [isSaveData, setIsSaveData] = useState(false);
  const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);
  const [programList, setProgramList] = useState([]);

  let roleSelected: any = [];
  let showMessge: boolean = false;

  useEffect(() => {
    setValidateSchema(
      yup.object().shape({
        firstName: yup
          .string()
          .trim()
          .required(t("validationMessage.firstName"))
          .test(
            VALID_INPUT,
            t("validationMessage.tagNotAllow"),
            (value: any) => !testInputValueWithRegex(value)
          ),
        lastName: yup.string().trim().required(t("validationMessage.lastName"))
          .test(
            VALID_INPUT,
            t("validationMessage.tagNotAllow"),
            (value: any) => !testInputValueWithRegex(value)
          ),
        userEmail: yup
          .string()
          .trim()
          .required(t("validationMessage.emailRequired"))
          .email(t("validationMessage.emailValid"))
          .test(
            VALID_INPUT,
            t("validationMessage.tagNotAllow"),
            (value: any) => !testInputValueWithRegex(value)
          ),
      })
    );
  }, []);

  useEffect(() => {
    if (isSaveData && saveUserData && saveUserData.length === 0) {
      showHideTableAndForm(false);
    }
  }, [saveUserData]);

  useEffect(() => {
    const tempProgramList = listPrograms.filter((prog: any) => allPrograms.length && prog.id !== allPrograms[0].id && prog.id !== allPrograms[1].id);

    const selectAllOption = { id: "0", label: t("common.selectAll") };
    tempProgramList.splice(0, 0, selectAllOption);

    setProgramList(tempProgramList);
  }, [listPrograms]);

  useEffect(() => {
    if (isAdminChecked && fieldsRegionArray && fieldsRegionArray.length === 1) {
      const selectedRole = `userRegion[${fieldsRegionArray[0]["counterRegion"]}].role`;
      setValue(selectedRole, fieldsRegionArray[0].setRoleUser[0].id);
    } else if (
      isRemoveClicked &&
      fieldsRegionArray &&
      fieldsRegionArray.length
    ) {
      fieldsRegionArray.map((fields: any) => {
        if (fields.setRoleUser.length) {
          const selectedRole = `userRegion[${fields.counterRegion}].role`;
          const selectedRegion = `userRegion[${fields.counterRegion}].region`;
          const selectedCountry = `userRegion[${fields.counterRegion}].country`;
          const selectedProgram = `userRegion[${fields.counterRegion}].program`;
          setValue(selectedRole, fields.setRoleUser[0].id);
          setValue(selectedRegion, fields.setRegionUser);
          setValue(selectedCountry, fields.setCountryUser);
          setValue(selectedProgram, fields.setPrograms);
        }
      });
      setIsRemoveClicked(false);
    }
  }, [isAdminChecked, isRemoveClicked]);

  useEffect(() => {
    if (
      editFormValues &&
      Object.keys(editFormValues).length > 1 &&
      editFormValues.hasOwnProperty("id")
    ) {
      setValue("firstName", editFormValues.firstName);
      setValue("isEmailSend", editFormValues.isEmailSend);
      setValue("lastName", editFormValues.lastName);
      setValue("userEmail", editFormValues.userEmail);
      if (editFormValues.organization) {
        setValue("organization", editFormValues.organization);
      }
      const userStatus = editFormValues.isDeleted ? false : true;
      const userStatusLabel = userStatus
        ? t("common.active")
        : t("common.active");
      setUserStatusLabel(userStatusLabel);
      setUserStatus(userStatus);
      let roleEdit: any = [];
      editFormValues.countryUsers.map((user: any) => {
        if (!roleEdit.includes(user.roleId)) {
          roleEdit.push(user.roleId);
        }
      });
      if (fieldsRegionArray.length) {
        fieldsRegionArray.map((fields: any) => {
          let selectorRole = "";
          let selectorRegion = "";
          let selectorCountry = "";
          let selectorPrograms = "";
          if (fields.setRoleUser.length) {
            if (
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.SD_ADMIN.toLowerCase()
            ) {
              selectorRole = `userRegion[${[fields.counterRegion]}].role`;
              setValue(selectorRole, fields.setRoleUser[0].id);
              setIsAdminChecked(true);
            }
            if (
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.ASSESSOR.toLowerCase() ||
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.VALIDATOR.toLowerCase()
            ) {
              selectorRole = `userRegion[${fields.counterRegion}].role`;
              setValue(selectorRole, fields.setRoleUser[0].id);

              selectorRegion = `userRegion[${fields.counterRegion}].region`;
              setValue(selectorRegion, fields.setRegionUser);

              selectorCountry = `userRegion[${fields.counterRegion}].country`;
              setValue(selectorCountry, fields.setCountryUser);

              selectorPrograms = `userRegion[${fields.counterRegion}].program`;
              setValue(selectorPrograms, fields.setPrograms);
            }
            if (
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.RSA.toLowerCase()
            ) {
              selectorRole = `userRegion[${fields.counterRegion}].role`;
              setValue(selectorRole, fields.setRoleUser[0].id);

              selectorRegion = `userRegion[${fields.counterRegion}].region`;
              setValue(selectorRegion, fields.setRegionUser);
            }
            if (
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
            ) {
              selectorRole = `userRegion[${fields.counterRegion}].role`;
              setValue(selectorRole, fields.setRoleUser[0].id);
            }
            if (
              fields.setRoleUser[0].name.toLowerCase().trim() ===
              USER.ROLE.VC_ASSESSOR.toLowerCase()
            ) {
              selectorRole = `userRegion[${fields.counterRegion}].role`;
              setValue(selectorRole, fields.setRoleUser[0].id);
            }
          }
        });
      }
    }
  }, [editFormValues]);

  const handleCancel = () => {
    setIsRefreshTable(true);
    showHideTableAndForm(false);
  }
  const onSubmit = (data: any) => {
    let formData: any = {
      userEmail: data.userEmail.trim(),
      isDeleted: !userStatus,
      createdBy: userId,
      updatedBy: userId,
      firstName: data.firstName,
      lastName: data.lastName,
      isEmailSend: data.isEmailSend,
      organization: data.organization,
      userCountryRoleMappings: [],
    };
    showMessge = false;
    let userCountryRoleMappings: any = [];
    if (data.hasOwnProperty("userRegion")) {
      callFunctionAddUserRoleMapping(
        data,
        userCountryRoleMappings,
        data.organization
      );
    }

    if (isEdit && !showMessge) {
      callFunctionEditCondition(
        formData,
        userCountryRoleMappings,
        data.organization
      );
    }
    if (userCountryRoleMappings.length && !showMessge) {
      if (getDuplicateArrayElements(roleSelected).length === 0) {
        if (checkRoleWiseProgramSelectionValidation(userCountryRoleMappings)) {
          formData.userCountryRoleMappings = userCountryRoleMappings;
          dispatch(actionSaveUserProfileData(formData));
          setIsSaveData(true);
        }
        else {
          dispatch(openToaster(t("validationMessage.samePrograms"), false));
          closeToasterParticularTimeInterval();
        }
      } else {
        dispatch(openToaster(t("validationMessage.duplicateRole"), false));
        closeToasterParticularTimeInterval();
      }
    } else if (!showMessge) {
      dispatch(openToaster(t("validationMessage.oneRoleSelect"), false));
      closeToasterParticularTimeInterval();
    }
  };


  const checkRoleWiseProgramSelectionValidation = (
    userCountryRoleMappings: any
  ): boolean => {

    let isValidSelection = true;
    const userCountryMapping = userCountryRoleMappings.filter(
      (userMap: any) => userMap.isDeleted === false
    );
    if (userCountryMapping.length > 1) {
      let accessorRole = listRoles.filter((roleE: any) =>
        roleE.label.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase());

      let validatorRole: any = listRoles.filter((roleE: any) =>
        roleE.label.toLowerCase().trim() === USER.ROLE.VALIDATOR.toLowerCase());

      let accessorUserMapaingRole = userCountryMapping.filter((item: any) => {
        return item.roleId === accessorRole[0].id
      });

      let validatorUserMapaingRole = userCountryMapping.filter((item: any) => {
        return item.roleId === validatorRole[0].id
      });

      if (accessorUserMapaingRole.length && validatorUserMapaingRole.length) {

        if (accessorUserMapaingRole[0].programIds.length !== validatorUserMapaingRole[0].programIds.length) {
          return false;
        }

        if (!_.isEmpty(_.difference(accessorUserMapaingRole[0].programIds.sort(), validatorUserMapaingRole[0].programIds.sort()))) {
          return false;
        }
      }
    }

    return isValidSelection;
  }

  const callFunctionAddUserRoleMapping = (
    data: any,
    userCountryRoleMappings: any,
    organizationValue: any
  ): [] => {

    data.userRegion.forEach((userMapping: any) => {
      if (userMapping && userMapping.role) {
        let roleMap = roleData.filter(
          (role: any) => role.id === userMapping.role
        );

        roleSelected.push(userMapping.role);
        if (
          roleMap &&
          roleMap[0].name.toLowerCase().trim() ===
          USER.ROLE.SD_ADMIN.toLowerCase()
        ) {
          userCountryRoleMappings.push({
            roleId: userMapping.role,
            countryId: null,
            region: null,
            isDeleted: false,
            organization: null,
            programIds: [],
          });
        } else if (
          roleMap &&
          (roleMap[0].name.toLowerCase().trim() ===
            USER.ROLE.ASSESSOR.toLowerCase() ||
            roleMap[0].name.toLowerCase().trim() ===
            USER.ROLE.VALIDATOR.toLowerCase())
        ) {

          if (userMapping.region !== null && userMapping.region.length) {
            let programIds: any = [];
            if (userMapping.program && userMapping.program.length) {
              programIds = userMapping.program.map((prog: any) => {
                return parseInt(prog.value);
              });
            }

            if (userMapping.country !== null && userMapping.country.length) {
              userMapping.country.forEach((country: any) => {
                let countryMap = countryListOrgWise.filter(
                  (a: any) => a.id === country.value
                );
                userCountryRoleMappings.push({
                  roleId: userMapping.role,
                  countryId: countryMap[0].id,
                  region: countryMap[0].region,
                  isDeleted: false,
                  organization: organizationValue,
                  programIds: programIds
                });
              });
            } else {
              dispatch(
                openToaster(t("validationMessage.countrySelect"), false)
              );
              closeToasterParticularTimeInterval();
              showMessge = true;
              return;
            }
            if (programIds === null || !programIds.length) {
              dispatch(
                openToaster(t("validationMessage.programSelect"), false)
              );
              closeToasterParticularTimeInterval();
              showMessge = true;
              return;
            }
          } else {
            dispatch(openToaster(t("validationMessage.regionSelect"), false));
            closeToasterParticularTimeInterval();
            showMessge = true;
            return;
          }
        } else if (
          roleMap &&
          roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
        ) {
          if (userMapping.region !== null && userMapping.region.length) {
            userMapping.region.forEach((region: any) => {
              userCountryRoleMappings.push({
                roleId: userMapping.role,
                countryId: null,
                region: region.value,
                isDeleted: false,
                organization: organizationValue,
                programIds: [],
              });
            });
          } else {
            dispatch(openToaster(t("validationMessage.regionSelect"), false));
            closeToasterParticularTimeInterval();
            showMessge = true;
            return;
          }
        } else if (
          roleMap &&
          roleMap[0].name.toLowerCase().trim() ===
          USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
        ) {
          userCountryRoleMappings.push({
            roleId: userMapping.role,
            countryId: null,
            region: null,
            isDeleted: false,
            organization: organizationValue,
            programIds: [],
          });
        }
        else if (
          roleMap &&
          roleMap[0].name.toLowerCase().trim() ===
          USER.ROLE.VC_ASSESSOR.toLowerCase()
        ) {
          userCountryRoleMappings.push({
            roleId: userMapping.role,
            countryId: null,
            region: null,
            isDeleted: false,
            organization: organizationValue,
            programIds: [],
          });
        }
      } else {
        dispatch(openToaster(t("validationMessage.roleSelect"), false));
        closeToasterParticularTimeInterval();
        showMessge = true;
        return;
      }
    });
    return userCountryRoleMappings;
  };

  const callFunctionEditCondition = (
    formData: any,
    userCountryRoleMappings: any,
    organizationValue: any
  ): [] => {
    formData.id = editFormValues.id;
    let roleEdit: any = [];
    editFormValues.countryUsers.map((user: any) => {
      if (!roleEdit.includes(user.roleId)) {
        roleEdit.push(user.roleId);
      }
    });
    if (roleEdit.length) {
      roleSelected.push(roleEdit);
      roleEdit.map((role: any) => {
        let rolename = roleData.filter((roleE: any) => roleE.id === role);
        let userCountry = userCountryRoleMappings.filter(
          (country: any) => country.roleId === role
        );
        if (
          rolename &&
          rolename[0].name.toLowerCase().trim() ===
          USER.ROLE.SD_ADMIN.toLowerCase()
        ) {
          if (userCountry.length) {
            userCountry.map((userselect: any) => {
              let editRoleData = editFormValues.countryUsers.filter(
                (d: any) => d.roleId === userselect.roleId
              );
              if (editRoleData && editRoleData.length) {
                userselect.id = editRoleData[0].id;
                userselect.organization = null;
              }
            });
          } else {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            editRoleData[0].isDeleted = true;
            editRoleData[0].organization = null;
            userCountryRoleMappings.push(editRoleData[0]);
          }
        }

        if (
          (rolename &&
            rolename[0].name.toLowerCase().trim() ===
            USER.ROLE.ASSESSOR.toLowerCase()) ||
          (rolename &&
            rolename[0].name.toLowerCase().trim() ===
            USER.ROLE.VALIDATOR.toLowerCase())
        ) {
          if (userCountry.length) {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            if (editRoleData.length) {
              editRoleData.map((editData: any) => {
                let editRole = userCountry.filter(
                  (userSelect: any) =>
                    userSelect.roleId === editData.roleId &&
                    userSelect.countryId === editData.countryId
                );
                if (editRole.length) {
                  editRole[0].id = editData.id;
                  editRoleData[0].organization = organizationValue;
                } else {
                  editData.isDeleted = true;
                  editData.organization = organizationValue;
                  userCountryRoleMappings.push(editData);
                }
              });
            }
          } else {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            editRoleData.map((editRole: any) => {
              editRole.isDeleted = true;
              editRole.organization = organizationValue;
              userCountryRoleMappings.push(editRole);
            });
          }
        }
        if (
          rolename &&
          rolename[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
        ) {
          if (userCountry.length) {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            if (editRoleData.length) {
              editRoleData.map((editData: any) => {
                let editRole = userCountry.filter(
                  (userSelect: any) =>
                    userSelect.roleId === editData.roleId &&
                    userSelect.region.toLowerCase().trim() ===
                    editData.region.toLowerCase().trim()
                );
                if (editRole.length) {
                  editRole[0].id = editData.id;
                  editRole[0].organization = organizationValue;
                } else {
                  editData.isDeleted = true;
                  editData.organization = organizationValue;
                  userCountryRoleMappings.push(editData);
                }
              });
            }
          } else {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            editRoleData.map((editRole: any) => {
              editRole.isDeleted = true;
              editRole.organization = organizationValue;
              userCountryRoleMappings.push(editRole);
            });
          }
        }
        if (
          (rolename &&
            rolename[0].name.toLowerCase().trim() ===
            USER.ROLE.GLOBAL_ADVISOR.toLowerCase()) ||
          (rolename &&
            rolename[0].name.toLowerCase().trim() ===
            USER.ROLE.VC_ASSESSOR.toLowerCase())
        ) {
          if (userCountry.length) {
            userCountry.map((userselect: any) => {
              let editRoleData = editFormValues.countryUsers.filter(
                (d: any) => d.roleId === userselect.roleId
              );
              if (editRoleData && editRoleData.length) {
                userselect.id = editRoleData[0].id;
                userselect.organization = organizationValue;
              }
            });
          } else {
            let editRoleData = editFormValues.countryUsers.filter(
              (d: any) => d.roleId === role
            );
            editRoleData[0].isDeleted = true;
            editRoleData[0].organization = null;
            userCountryRoleMappings.push(editRoleData[0]);
          }
        }
      });
    }
    return userCountryRoleMappings;
  };

  const closeToasterParticularTimeInterval = () => {
    setTimeout(function () {
      closeToasterFun();
    }, COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
  };

  const handleSelectRoleChange = ([event]: any, indexArray: any) => {
    let roleMap = roleData.filter(
      (role: any) => role.id === event.target.value
    );
    if (
      roleMap.length &&
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.SD_ADMIN.toLowerCase()
    ) {
      const regArray: any = fieldsRegionArray.filter(
        (item: any) => item.counterRegion === indexArray
      );

      setValue(`userRegion[${regArray[0]["counterRegion"]}].region`, null);
      setValue(`userRegion[${regArray[0]["counterRegion"]}].country`, null);
      setValue(`userRegion[${regArray[0]["counterRegion"]}].program`, null);
      regArray[0].isCountryDisable = true;
      regArray[0].isRegionDisable = true;
      regArray[0].isRegionRequired = true;
      regArray[0].isCountryRequired = true;
      regArray[0].isProgramRequired = false;
      regArray[0].setRoleUser = [
        {
          id: event.target.value,
          isSelectable: false,
          name: roleMap[0].name,
        },
      ];
      const values: any = [...regArray];
      setFieldsRegionArray(values);
      setIsAdminChecked(true);
    } else if (
      roleMap.length &&
      roleMap[0].name.toLowerCase().trim() === USER.ROLE.RSA.toLowerCase()
    ) {
      fieldsRegionArray.map((item: any) => {
        if (item.counterRegion === indexArray) {
          setValue(`userRegion[${item["counterRegion"]}].region`, null);
          setValue(`userRegion[${item["counterRegion"]}].country`, null);
          setValue(`userRegion[${item["counterRegion"]}].program`, null);
          item.isCountryDisable = true;
          item.isRegionDisable = false;
          item.isRegionRequired = true;
          item.isCountryRequired = false;
          item.isProgramRequired = false;
          item.setRoleUser = [
            {
              id: event.target.value,
              isSelectable: false,
              name: roleMap[0].name,
            },
          ];
        }
      });
      setFieldsRegionArray([...fieldsRegionArray]);
      setIsAdminChecked(false);
    } else if (
      roleMap.length &&
      roleMap[0].name.toLowerCase().trim() ===
      USER.ROLE.GLOBAL_ADVISOR.toLowerCase()
    ) {
      fieldsRegionArray.map((item: any) => {
        if (item.counterRegion === indexArray) {
          setValue(`userRegion[${item["counterRegion"]}].region`, null);
          setValue(`userRegion[${item["counterRegion"]}].country`, null);
          setValue(`userRegion[${item["counterRegion"]}].program`, null);
          item.isCountryDisable = true;
          item.isRegionDisable = true;
          item.isRegionRequired = false;
          item.isCountryRequired = false;
          item.isProgramRequired = false;
          item.setRoleUser = [
            {
              id: event.target.value,
              isSelectable: false,
              name: roleMap[0].name,
            },
          ];
        }
      });
      setFieldsRegionArray([...fieldsRegionArray]);
      setIsAdminChecked(false);
    } else if (
      roleMap.length &&
      (roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.ASSESSOR.toLowerCase() ||
        roleMap[0].name.toLowerCase().trim() ===
        USER.ROLE.VALIDATOR.toLowerCase())
    ) {
      fieldsRegionArray.map((item: any) => {
        if (item.counterRegion === indexArray) {
          setValue(`userRegion[${item["counterRegion"]}].region`, null);
          setValue(`userRegion[${item["counterRegion"]}].country`, null);
          setValue(`userRegion[${item["counterRegion"]}].program`, null);
          item.isCountryDisable = false;
          item.isRegionDisable = false;
          item.isRegionRequired = true;
          item.isCountryRequired = true;
          item.isProgramRequired = true;
          item.setRoleUser = [
            {
              id: event.target.value,
              isSelectable: false,
              name: roleMap[0].name,
            },
          ];

          const selectedPrograms = programList
            .filter((prog: any) => { return prog.id !== "0"; })
            .map((prog: any) => {
              return { ...prog, value: prog.id };
            });

          item.setPrograms = selectedPrograms;
          setTimeout(() => {
            setValue(`userRegion[${item["counterRegion"]}].program`, selectedPrograms);
          }, 0);

        }
      });
      setFieldsRegionArray([...fieldsRegionArray]);
      setIsAdminChecked(false);
    } else if (
      roleMap.length &&
      roleMap[0].name.toLowerCase().trim() ===
      USER.ROLE.VC_ASSESSOR.toLowerCase()
    ) {
      fieldsRegionArray.map((item: any) => {
        if (item.counterRegion === indexArray) {
          setValue(`userRegion[${item["counterRegion"]}].region`, null);
          setValue(`userRegion[${item["counterRegion"]}].country`, null);
          setValue(`userRegion[${item["counterRegion"]}].program`, null);
          item.isCountryDisable = true;
          item.isRegionDisable = true;
          item.isRegionRequired = false;
          item.isCountryRequired = false;
          item.isProgramRequired = false;
          item.setRoleUser = [
            {
              id: event.target.value,
              isSelectable: false,
              name: roleMap[0].name,
            },
          ];
        }
      });
      setFieldsRegionArray([...fieldsRegionArray]);
      setIsAdminChecked(false);
    }
    return event.target.value;
  };

  const handleSelectRegionChange = ([event]: any, indexArray: any) => {
    let selectedRegionList: any = [];
    if (event && event !== null && event.length) {
      selectedRegionList.push(event);
    } else {
      selectedRegionList = [];
    }
    let selectorCountry = `userRegion[${[indexArray]}].country`;
    let selectorCountryData = getValues(selectorCountry);
    if (selectedRegionList.length) {
      for (let i = 0; i < selectedRegionList[0].length; i++) {
        countryListOrgWise.map((country: any) => {
          if (country.region === selectedRegionList[0][i].value) {
            if (
              listOfCountry.filter((a: any) => a.id === country.id).length === 0
            ) {
              listOfCountry.push({
                key: country.id,
                id: country.id,
                label: country.label,
                region: country.region,
              });
            }
          }
        });
      }
    } else {
      listOfCountry = [];
    }
    if (listOfCountry.length) {
      listOfCountry = sortObjectDataByAsending(listOfCountry, "label");
    }
    fieldsRegionArray.map((item: any, index: any) => {
      if (item.counterRegion === indexArray) {
        item.countryList = listOfCountry;
        if (selectedRegionList.length) {
          item.setRegionUser = selectedRegionList[0];
        } else {
          item.setRegionUser = selectedRegionList;
        }
      }
    });
    let selectCountryData: any = [];
    if (selectorCountryData && selectorCountryData.length) {
      selectorCountryData.map((country: any) => {
        let checkCountry = listOfCountry.filter(
          (liCountry: any) => liCountry.id === country.value
        );
        if (checkCountry.length) {
          selectCountryData.push(country);
        }
      });
      setValue(selectorCountry, selectCountryData);
    }
    setFieldsRegionArray([...fieldsRegionArray]);
    return event;
  };

  const handleSelectCountryChange = ([event]: any, indexArray: any) => {
    let selectedCountryList: any = [];
    if (event && event !== null && event.length) {
      selectedCountryList.push(event);
    } else {
      selectedCountryList = [];
    }
    fieldsRegionArray.map((item: any, index: any) => {
      if (item.counterRegion === indexArray) {
        if (selectedCountryList.length) {
          item.setCountryUser = selectedCountryList[0];
        } else {
          item.setCountryUser = selectedCountryList;
        }
      }
    });
    return event;
  };

  const handleSelectProgramChange = ([event]: any, indexArray: any) => {
    let selectedPrograms: any = [];

    if (event && event !== null && event.length) {
      const isAllSelected = event.filter((item: any) => { return item.value === "0" });
      if (isAllSelected && isAllSelected.length) {
        selectedPrograms = programList
          .filter((prog: any) => { return prog.id !== "0"; })
          .map((prog: any) => {
            return { ...prog, value: prog.id };
          });
      }
      else {
        selectedPrograms = event;
      }
    }

    fieldsRegionArray.map((item: any, index: any) => {
      if (item.counterRegion === indexArray) {
        item.setPrograms = selectedPrograms
      }
    });

    return selectedPrograms;
  };

  const handleRegionAppend = () => {
    let values: any = [...fieldsRegionArray];
    values.push({
      region: "",
      country: "",
      role: "",
      countryList: [],
      regionList: regionListOrgWise,
      counterRegion: counterRegion,
      isCountryDisable: false,
      isRegionDisable: false,
      isRoleRequired: true,
      isRegionRequired: false,
      isCountryRequired: false,
      setRoleUser: [],
      setRegionUser: [],
      setCountryUser: [],
      isProgramRequired: false,
      setPrograms: []
    });
    setFieldsRegionArray([...values]);
    setFieldsRegionArray(values);
    setCounterRegion((prevCounter: any) => prevCounter + 1);
  };

  const handleRegionRemove = (indexArray: any) => {
    const regArray = fieldsRegionArray.filter(
      (item: any, index: any) => item.counterRegion !== indexArray
    );
    const values: any = [...regArray];
    setFieldsRegionArray(values);
    setIsAdminChecked(false);
    setIsRemoveClicked(true);
  };

  const handleUserStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkStatus = event.target.checked;
    setUserStatus(checkStatus);
    const userStatusLable = checkStatus
      ? t("common.active")
      : t("common.active");
    setUserStatusLabel(userStatusLable);
  };

  const handleSelectOrgChange = ([event]: any) => {
    let regionListOrgWise: any = listRegions;
    let countryListOrgWise: any = listCountry;
    if (event.target.value != "") {
      if (
        event.target.value.toLowerCase() === allOrganization[1].id.toLowerCase()
      ) {
        regionListOrgWise = listWhoRegions;
        countryListOrgWise = listWhoCountry;
      }

      setOrganizationValue(organizationValue);
      setRegionListOrgWise(regionListOrgWise);
      setCountryListOrgWise(countryListOrgWise);
      functionSetOnlyOneRegionArray(regionListOrgWise);
    }
    return event.target.value;
  };

  const functionSetOnlyOneRegionArray = (regionListOrgWise: any) => {
    const regArray: any = fieldsRegionArray.filter(
      (item: any) => item.counterRegion === counterRegion - 1
    );
    setValue(`userRegion[${regArray[0]["counterRegion"]}].role`, null);
    setValue(`userRegion[${regArray[0]["counterRegion"]}].region`, null);
    setValue(`userRegion[${regArray[0]["counterRegion"]}].country`, null);
    setValue(`userRegion[${regArray[0]["counterRegion"]}].program`, null);
    regArray[0].regionList = regionListOrgWise;
    regArray[0].countryList = [];
    regArray[0].isCountryDisable = false;
    regArray[0].isRegionDisable = false;
    regArray[0].isRegionRequired = true;
    regArray[0].isCountryRequired = true;
    regArray[0].isProgramRequired = true;
    regArray[0].isRoleRequired = true;
    regArray[0].setRoleUser = [];
    const values: any = [...regArray];
    setFieldsRegionArray(values);
  };
  return (
    <>
      <FormContext {...methods}>
        <form>
          <BlueSubHeader>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Typography component="strong" className="strong-text">
                  {`${t("userProfile.registrationDate")}: `}
                </Typography>
                <Typography component="span">{createdDate}</Typography>
              </Grid>
              {isEdit && (
                <Grid item>
                  <Typography component="strong" className="strong-text">
                    {`${t("userProfile.modifiedDate")}: `}
                  </Typography>
                  <Typography component="span">{modifiedDate}</Typography>
                </Grid>
              )}
              {isEdit && (
                <Grid item>
                  <Typography component="strong" className="strong-text">
                    {`${t("userProfile.lastModified")}: `}
                  </Typography>
                  <Typography component="span">{userName}</Typography>
                </Grid>
              )}

              <Grid item>
                <GreenFormControlLabel
                  label={userStatusLabel}
                  control={
                    <Switch
                      checked={userStatus}
                      onChange={handleUserStatusChange}
                      name="userStatus"
                    />
                  }
                  className="nomargin arUserSwitch"
                />
              </Grid>
            </Grid>
          </BlueSubHeader>
          <BigContainer>
            <UserContainer>
              <Grid container>
                <Grid item xs={10}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <FormInput
                            name="firstName"
                            disabled={isEdit && editFormValues.firstName}
                            label={t("userProfile.firstName")}
                            required={true}
                            errorobj={errors}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <FormInput
                            name="lastName"
                            disabled={isEdit && editFormValues.lastName}
                            label={t("userProfile.lastName")}
                            required={true}
                            errorobj={errors}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <FormInput
                            name="userEmail"
                            disabled={isEdit}
                            label={t("common.email")}
                            required={true}
                            errorobj={errors}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      {!isAdminChecked && (
                        <FormSelect
                          name="organization"
                          options={allOrganization}
                          onChange={(event: any) =>
                            handleSelectOrgChange(event)
                          }
                          required={!isAdminChecked}
                          label={t("common.organization")}
                          defaultValue={organizationValue}
                          hideselectoption="true"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2} className="text-center">
                  <FormCheckbox
                    name="isEmailSend"
                    label={t("userProfile.emailAlerts")}
                    defaultValue={true}
                    color="primary"
                    className="arRotateY180"
                  />
                </Grid>
              </Grid>
            </UserContainer>
            <CustomDevider />
            <UserContainer>
              <Grid container>
                <Grid item>
                  <Typography component="label">
                    <WarningLabel>
                      {t("userProfile.assignTheRole")}
                    </WarningLabel>
                  </Typography>
                </Grid>
              </Grid>
              <UserProfileRegionComponent
                fieldsRegionArray={fieldsRegionArray}
                listRoles={listRoles}
                handleSelectRoleChange={handleSelectRoleChange}
                handleSelectRegionChange={handleSelectRegionChange}
                handleSelectCountryChange={handleSelectCountryChange}
                handleRegionRemove={handleRegionRemove}
                listPrograms={programList}
                handleSelectProgramChange={handleSelectProgramChange}
              ></UserProfileRegionComponent>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <AddRoleButton
                    onClick={() => handleRegionAppend()}
                    disabled={isAdminChecked || fieldsRegionArray.length === applicationData.length}
                  >
                    {"+"} {t("userProfile.addRole")}
                  </AddRoleButton>
                </Grid>
              </Grid>
            </UserContainer>
          </BigContainer>
        </form>
      </FormContext>

      <CustomDevider />

      <Grid container spacing={2} justify="flex-end" className="padding20">
        <Grid item>
          <DialogButtonDefault
            variant="contained"
            color="default"
            onClick={() => handleCancel()}
          >
            {t("common.cancel")}
          </DialogButtonDefault>
        </Grid>
        <Grid item>
          {isEdit ? (
            <DialogButtonPrimary
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t("common.update")}
            </DialogButtonPrimary>
          ) : (
            <DialogButtonPrimary
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t("common.submit")}
            </DialogButtonPrimary>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UserProfileFormComponent;
