import { ASSESMENT, ASSESSMENT_OVERVIEW, REPORT, USER_PROFILE, VC_ASSESMENT } from "./api";

export const ATTACHMENT_DELIMETER: string = "_";
export const GRID_HEIGHT_REMOVAL: number = 224;
export const TECH_SCREENING_STATUS: number = 14;
export const ASSESSMET_APPLICATION_STATUS: string =
  "Assesment Application Status";
export const VALID_INPUT: string = "Valid Input";

export const STATUS_DATA = {
  DRAFTED: {
    NAME: "Drafted",
    VALUE: 1,
  },
  FINALIZED_ACTIONED: {
    NAME: "Finalised: Actioned",
    VALUE: 7,
  },
  FINALIZED_CANCELLED: {
    NAME: "Finalised: Cancelled",
    VALUE: 6,
  },
  FINALIZED_REJECTED: {
    NAME: "Finalised: Rejected",
    VALUE: 5,
  },
  PENDING_CE_ISSUANCE: {
    NAME: "T1: Pending CE Issuance",
    VALUE: 4,
  },
  PENDING_PC_SCREENING: {
    NAME: "Pending PC Screening",
    VALUE: 3,
  },
  PENDING_PS_SCREENING: {
    NAME: "Pending PS Screening",
    VALUE: 2,
  },
  SO_CREATED: {
    NAME: "T5: SO Created",
    VALUE: 9,
  },
};

export const TOASTER_ALIGNMENT_TOP: string = "bottom";
export const TOASTER_ALIGNMENT_HORIZONTAL: string = "right";

export const PRIORITY_LIST = [
  { id: "1", label: "1", key: "one" },
  { id: "2", label: "2", key: "two" },
  { id: "3", label: "3", key: "three" },
];

export const STATUS_TABS = {
  VALIDATOR: {
    PENDING: 0,
    IN_PROGRESS: 1,
    RE_ASSIGEND: 2,
    VALIDATED: 3,
  },
  ASSESSOR: {
    DRAFT: 0,
    RE_ASSIGEND: 1,
    SENT_FOR_VALIDATION: 2,
    VALIDATED: 3,
  },
};

export const LANGUAGE = {
  ARABIC: "ar",
  ENGLISH: "en",
  FRENCH: "fr",
  SPANISH: "sp",
  RUSSIAN: "ru",
};

export const LANGUAGE_CONSTANT = {
  ARABIC_CONSTANT: "Arabic",
  ENGLISH_CONSTANT: "English",
  FRENCH_CONSTANT: "French",
  SPANISH_CONSTANT: "Spanish",
  RUSSIAN_CONSTANT: "Russian",
};

export const MAP_CONSTANTS = {
  DRAFT: "Draft",
  SEND_FOR_VALIDATION: "Send For Validation",
  COUNTRY: "country",
  REGION: "region",
  REGION_LIST: {
    EAPR: "EAPR",
    ECAR: "ECAR",
    ESAR: "ESAR",
    LACR: "LACR",
    MENA: "MENA",
    WCAR: "WCAR",
    ROSA: "ROSA",
  },
  REGION_LIST_WHO: {
    EURO: "EURO",
    PAHO: "PAHO",
    WPRO: "WPRO",
    AFRO: "AFRO",
    EMRO: "EMRO",
    SEARO: "SEARO",
  },
};

export const LEVEL_DESCRIPTION = {
  level1: "level1Desc",
  level2: "level2Desc",
  level3: "level3Desc",
  level4: "level4Desc",
  level5: "level5Desc",
};

export const DYNAMIC_MASTER = {
  SUPPLY_CHAIN: "supplychain",
  SCOPE: "scope",
  LEVEL: "level",
  AREA_OF_WORK: "areaofwork",
  SUB_AREA_OF_WORK: "subareaofwork",
};

export const INDICATOR = {
  STATUS: {
    DRAFT: 1,
    COMPLETED: 2,
  },
};

export const ASSESSMENT = {
  STATUS: {
    DRAFT: 1,
    RE_ASSIGNED: 2,
    SEND_FOR_VALIDATION: 3,
    VALIDATED: 4,
    IN_PROGRESS_VALIDATION: 5,
    SUBMITTED: 6
  },
  QUESTION_STATUS_COLOR: {
    NOT_STARTED: "#C9C9C9",
    IN_PROGRESS: "#FFC10D",
    COMPLETED: "#80BD41",
    ISSUES: "#E1221A",
  },
  QUESTION_VALIDATION_STATUS: {
    NOT_SELECTED: 1,
    AGREE: 2,
    DIS_AGREE: 3,
  },
  QUESTION_VALIDATION_STATUS_LABEL: {
    NOT_SELECTED: "notSelect",
    AGREE: "agree",
    DIS_AGREE: "disAgree",
  },
  LEVEL: "Level",
};

export const MENU_DATA = {
  SDAdmin: [
    { menuName: "overview", path: ASSESSMENT_OVERVIEW },
    { menuName: "assessment", path: ASSESMENT },
    { menuName: "users", path: USER_PROFILE },
  ],
  Assessor: [
    { menuName: "overview", path: ASSESSMENT_OVERVIEW },
    { menuName: "assessment", path: ASSESMENT },
    { menuName: "report", path: REPORT },
  ],
  Validator: [
    { menuName: "overview", path: ASSESSMENT_OVERVIEW },
    { menuName: "assessment", path: ASSESMENT },
    { menuName: "report", path: REPORT },
  ],
  RSA: [
    { menuName: "overview", path: ASSESSMENT_OVERVIEW },
    { menuName: "assessment", path: ASSESMENT },
    { menuName: "report", path: REPORT },
  ],
  GlobalAdvisor: [
    { menuName: "overview", path: ASSESSMENT_OVERVIEW },
    { menuName: "assessment", path: ASSESMENT },
    { menuName: "report", path: REPORT },
  ],
  VCAssessor: [{ menuName: "assessment", path: VC_ASSESMENT }],
};

export const USER = {
  ROLE: {
    SD_ADMIN: "SDAdmin",
    ASSESSOR: "Assessor",
    VALIDATOR: "Validator",
    RSA: "RSA",
    GLOBAL_ADVISOR: "Global Advisor",
    VC_ASSESSOR: "VC Assessor",
  },
};

export const COMMON_CONSTANT = {
  SHOW_TOSTER_TIME_INTERVEL: 4000,
  SHOW_TOSTER_MESSAGE_TIME_INTERVEL: 10000,
};

export const AssessmentCountList = [
  { id: "2", label: "2" },
  { id: "3", label: "3" },
  { id: "4", label: "4" },
  { id: "5", label: "5" },
];

export const AssessmentColorList = [
  "#A0C3E6",
  "#88ABE2",
  "#02ADFA",
  "#2A78AD",
  "#A0C3E6",
  "#88ABE2",
  "#02ADFA",
  "#2A78AD",
  "#A0C3E6",
  "#88ABE2",
];

export const AOW_MANAGEMENT_CONST = {
  AOW: 1,
  SUB_AOW: 2,
  TOPIC: 3,
};

export const COUNTRY_HIERARCHY_CONST = {
  NATIONAL: 1,
  SUB_NATIONAL: 2,
  DISTRICT: 3,
};

export const IS_DUPLICATE: string = "IsDuplicate";
export const IS_ENTITY_ASSOCIATED: string = "EntityIsAssociated";
export const IS_INDICATOR_SET_CONFLICT: string = "IndicatorSetConflict";
export const IS_USER_EXIST: string = "UserAlreadyExists";

export const ASSESSMENT_TYPE_CONST = {
  NORMAL: 1,
  RESILIENCY: 2,
  VC: 3
};

export const SCOPE_CONST = {
  DISTRICT: "District",
  REGIONAL: "Regional/Provincial",
  NATIONAL: "National",
};

export const REPORT_TYPE_FILTER_OPTIONS = [
  { id: "-1", label: "Select" },
  { id: "User", label: "User" },
  { id: "Country/ Area", label: "Country/ Area" }
]

export const TAB_TITLES = {
  SCS_HUB: "SCS-Hub",
  SCMM: "SCMM",
  VC: "VC Toolbox"
}
