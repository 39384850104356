import { ASSESSMENT_TYPE_CONST, COMMON_CONSTANT } from "constants/data";
import { actionFetchAllAssesmentData, actionFetchAssesmentData } from "containers/assessment-create/actions";
import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import { closeToaster, openToaster, setLoaderVisibility } from "store/common/actions";
import { API_ERROR_MESSAGE, DATA_DELETE_SUCCESS, DATA_UPDATE_SUCCESS } from "store/common/message";
import { getLocatDateInDateTimeFormat } from "utils/data";
import { actionMasterGetQuestionSetAreaOfWorkSuccess, actionUpdateAssesmentSubmitedStatusDataSuccess } from "./actions";
import { deleteAssesmentById, getExportedAssessmentData, getQuestionSetAreaOfWorks, updateAssesmentValidationStatusData } from "./api";
import { ActionTypes } from "./constants";

function* watchGetQuestionSetAreaOfWorks() {
    yield takeLatest(
        ActionTypes.MASTER_GET_QUESTION_SET_AREA_OF_WORK,
        getAllQuestionSetAreaOfWorks
    );
}

function* getAllQuestionSetAreaOfWorks(action: any) {
    try {
        yield put(setLoaderVisibility(true));
        const response = yield call(getQuestionSetAreaOfWorks);
        const responseData = response.data;
        yield put(actionMasterGetQuestionSetAreaOfWorkSuccess(responseData));
        yield put(setLoaderVisibility(false));
    } catch (err) {
        console.log(err);
        yield put(setLoaderVisibility(false));
    }
}

function* watchUpdateAssesmentValidateStatusData() {
    yield takeLatest(
        ActionTypes.ASSESMENT_SUBMITTED_STATUS_DATA,
        updateAssesmentValidateStatusData
    );
}

function* updateAssesmentValidateStatusData(action: any) {
    try {
        yield put(setLoaderVisibility(true));
        const response = yield call(
            updateAssesmentValidationStatusData,
            action.payload.assesmentData
        );
        const responseData = response.data;
        yield put(actionUpdateAssesmentSubmitedStatusDataSuccess(responseData));
        if (response.status === 204) {
            const userData: any = JSON.parse(localStorage.getItem("userSelectedData") || "{}");
            yield put(
                actionFetchAssesmentData({
                    "roleId": userData.userSelectedRole,
                    "countryIds": action.payload.assesmentData.countryDataList,
                    "assesementType": ASSESSMENT_TYPE_CONST.VC
                })
            );
            yield put(
                actionFetchAllAssesmentData({
                    "roleId": userData.userSelectedRole,
                    "countryIds": userData.userSelectedCountry
                })
            );
            yield put(openToaster(DATA_UPDATE_SUCCESS(), true));
        } else {
            yield put(openToaster(API_ERROR_MESSAGE(), false));
        }
        yield put(setLoaderVisibility(false));
        yield delay(COMMON_CONSTANT.SHOW_TOSTER_MESSAGE_TIME_INTERVEL);
        yield put(closeToaster());
    } catch (err) {
        yield put(setLoaderVisibility(false));
        yield put(openToaster(API_ERROR_MESSAGE(), false));
        console.log(err);
    }
}

function* watchGetExportAssesementData() {
    yield takeLatest(
        ActionTypes.EXPORT_SELECTED_ASSESSMENT_DATA,
        getExportAssesementData
    );
}

function* getExportAssesementData(action: any) {
    try {
        yield put(setLoaderVisibility(true));
        const response = yield call(
            getExportedAssessmentData,
            action.payload.assessmentId
        );
        const responseData = response.data;
        const fileName = `assessmentDetails_${getLocatDateInDateTimeFormat(
            null
        )}.xlsx`;

        setTimeout(() => {
            const file: any = new Blob([responseData], {
                type: "application/octet-stream",
            });
            const a: any = document.createElement("a");
            a.className = "link-download";
            document.body.appendChild(a);
            const url: any = window.URL.createObjectURL(file);
            a.href = url;
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
        }, 0);
        yield put(setLoaderVisibility(false));
    } catch (err) {
        yield put(setLoaderVisibility(false));
        yield put(openToaster(API_ERROR_MESSAGE(), false));
        console.log(err);
    }
}

function* watchDeleteAssessmentDataById() {
    yield takeLatest(
        ActionTypes.ASSESSMENT_DELETE_BY_ID,
        deleteAssessmentDataById
    );
}

function* deleteAssessmentDataById(action: any) {
    try {
        yield put(setLoaderVisibility(true));
        const response = yield call(deleteAssesmentById, action.payload.data);
        if (response.data.isSuccess) {
            yield put(
                actionFetchAssesmentData({
                    "assesementType": ASSESSMENT_TYPE_CONST.VC
                })
            );
            yield put(
                actionFetchAllAssesmentData({
                    "assesementType": ASSESSMENT_TYPE_CONST.VC
                })
            );
            yield put(openToaster(DATA_DELETE_SUCCESS(), true));
            yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
            yield put(closeToaster());
        }
        yield put(setLoaderVisibility(false));
    } catch (err) {
        yield put(openToaster(API_ERROR_MESSAGE(), false));
        yield delay(COMMON_CONSTANT.SHOW_TOSTER_TIME_INTERVEL);
        yield put(closeToaster());
        yield put(setLoaderVisibility(false));
        console.log(err);
    }
}

export default function* vcAssessmentSaga() {
    yield all([
        watchGetQuestionSetAreaOfWorks(),
        watchUpdateAssesmentValidateStatusData(),
        watchGetExportAssesementData(),
        watchDeleteAssessmentDataById()
    ]);
}
