import React from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  QuestionDataContainer,
  DragIndicatorIconCustom,
  EditIconCustom,
  DeleteIconCustom,
} from "../../../styles/common";
import { Grid, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

function QuestionDragableContainer(props: any) {
  const {
    question,
    index,
    hadleEditRowData,
    handlerOpenConfirmDialogDelete,
    isHistoryMode,
    showSequence,
  } = props;
  const { t } = useTranslation();
  const hadleEditRowDatas = (rowData: any) => {
    hadleEditRowData(rowData);
  };
  const hadleDeleteRowDatas = (event: any, rowData: any) => {
    handlerOpenConfirmDialogDelete(event, rowData);
  };

  return (
    <>
      <Draggable draggableId={question.questionId} index={index}>
        {(provided) => (
          <div
            className="QuestionDataContainer"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <QuestionDataContainer>
              <Grid container alignItems="flex-start" justify="space-between">
                <Grid item xs={11}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item>
                      <DragIndicatorIconCustom />
                    </Grid>
                    <Grid item>
                      <Typography component="span" className="font-size-14">
                        {showSequence}
                      </Typography>
                    </Grid>
                    <Grid item className="width95">
                      <Typography component="span" className="font-size-14">
                        {question.questionName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      {!isHistoryMode && (
                        <Tooltip title={`${t("indicators.editIndicator")}`}>
                          <Typography
                            aria-label="edit"
                            onClick={() => hadleEditRowDatas(question)}

                          >
                            <EditIconCustom className="arRotate270" />
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item>
                      {!isHistoryMode && (
                        <Tooltip title={`${t("indicators.deleteIndicator")}`}>
                          <Typography
                            aria-label="edit"
                            onClick={(event: any) =>
                              hadleDeleteRowDatas(event, question)
                            }
                          >
                            <DeleteIconCustom />
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </QuestionDataContainer>
          </div>
        )}
      </Draggable>
    </>
  );
}

export default QuestionDragableContainer;
