import * as React from "react";
import {
  LoaderContainer,
  OverlayContainer,
  SectionPageProgress,
} from "../../styles/common";

export const SectionLoader: any = (props: any) => (
  <LoaderContainer visible={props.visible}>
    <OverlayContainer/>
    <SectionPageProgress thickness={8} />
  </LoaderContainer>
);
