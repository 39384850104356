import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { actionFetchAdminAssessmentData } from "../admin-rsa-overview/actions";
import {
  actionDetailsSaveData,
  actionFetchAssesmentLevelByIdData,
  actionFetchAssesmentByIdData,
  actionSaveAssesmentDataSuccess,
  actionExportAssesmentData,
  actionSetEditableScreen,
} from "../assessment-create/actions";
import { setLoaderVisibility } from "../../store/common/actions";
import { Container, Grid, IconButton, Button, Tooltip } from "@material-ui/core";
import RsaAssessmentTabComponent from "./rsa-assessment-tab/rsa-assessment-tab";
import AssesmentQuestionTabsComponent from "../assessment-create/assessment-question-tab";
import {
  ArrowBackIosIconCustom,
  ScrollableScmmAppBar,
  DownloadIconCustom,
  SubHeaderWrapper,
  BoxShadowWrapper,
} from "../../styles/common";
import { ASSESSMENT, ASSESSMENT_TYPE_CONST, USER } from "constants/data";
import { sortObjectDataByAsending } from "utils/data";
import AssessmentOverviewDetails from "containers/assessment-overview-details";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "1220px",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RsaAssessmentsComponent(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [showTabs, setShowTabs] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [canShowOverview, setCanShowOverview]: any = useState(false);
  const [userRoleData, setUserRoleData]: any = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [tabValueSelected, setTabValueSelected]: any = useState(0);
  const [showDetails, setShowDetails]: any = useState(false);
  const [editFormValues, setEditFormValues]: any = useState({});
  const [selectedAssessmentCode, setSelectedAssessmentCode] = useState("");
  const [selectedSupplyChain, setSelectedSupplyChain] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [currentCuntryArray, setCurrentCuntryArray] = useState([]);
  const [currentAssessmentID, setCurrentAssessmentID] = useState("");
  const [currentRole, setCurrentRole] = useState("");
  const [isPendingValidation, setIsPendingValidation] = useState(false);
  const [firstTimeCall, setFirstTimeCall] = useState(true);
  const [isUpdatedByOther, setIsUpdatedByOther]: any = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedSubRegion, setSelectedSubRegion]: any = useState();
  const [assessmentTabValue, setAssessmentTabValue]: any = React.useState(0);
  const [selectedAssessmentType, setSelectedAssessmentType]: any = useState();

  const [
    selectedAssessmentStatusId,
    setSelectedAssessmentStatusId,
  ] = useState();

  const assessmentTypeFilter = useSelector(
    (state: any) => state.header.assessmentTypeFilter
  )

  const listOrgWiseCountry = useSelector(
    (state: any) => state.common.listOrgWiseCountry
  );

  const adminAssesmentData = useSelector(
    (state: any) => state.admin.adminAssesmentData
  );

  const userSelectedSettingData = useSelector(
    (state: any) => state.userProfile.selectedObj
  );

  const assesmentDetailData = useSelector(
    (state: any) => state.assesmentCreate.assesmentDetailData
  );
  const listValidationStatus = useSelector(
    (state: any) => state.common.listValidationStatus
  );
  const languageSelected = useSelector(
    (state: any) => state.header.languageSelected
  );
  const loginUserData = useSelector((state: any) => state.header.loginUserData);


  useEffect(() => {
    setFirstTimeCall(false);
  }, [dispatch]);

  useEffect(() => {
    if (userSelectedSettingData) {
      userSettingDataSet(userSelectedSettingData.userSelectedData);
    } else if (localStorage.getItem("userSelectedData")) {
      const userSelectedData: any = JSON.parse(
        localStorage.getItem("userSelectedData") || "{}"
      );
      userSettingDataSet(userSelectedData);
    }
  }, [userSelectedSettingData]);

  useEffect(() => {
    if (
      userRoleData &&
      listOrgWiseCountry.length &&
      currentCuntryArray.length > 0 &&
      selectedRegion
    ) {
      let countryList: any = [];
      selectedRegion.map((region) => {
        const filterCountryList = listOrgWiseCountry.filter(
          (country: any) => country.region === region
        );
        countryList = [...countryList, ...filterCountryList];
      });

      if (countryList.length) {
        setCountriesList(sortObjectDataByAsending(countryList, "label"));
      }
      dispatch(actionFetchAdminAssessmentData({
        "countryIds": currentCuntryArray,
        "roleId": selectedRole,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      }));
    }
  }, [userRoleData, listOrgWiseCountry, currentCuntryArray, selectedRegion, assessmentTypeFilter]);


  const userSettingDataSet = (userSelectedData: any) => {
    setUserRoleData(userSelectedData);
    setSelectedRole(userSelectedData.userSelectedRole);
    setSelectedRegion(userSelectedData.userSelectedRegion);
    setCurrentCuntryArray(userSelectedData.userSelectedCountry);
  };

  useEffect(() => {
    if (languageSelected && currentCuntryArray && currentCuntryArray.length) {
      dispatch(actionFetchAdminAssessmentData({
        "countryIds": currentCuntryArray,
        "roleId": selectedRole,
        "assesementType": assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
      }));
    }
  }, [languageSelected, assessmentTypeFilter]);

  useEffect(() => {
    if (adminAssesmentData && adminAssesmentData.length) {
      if (!showTabs) setShowTabs(false);
      if (currentAssessmentID) {
        const lastRowData = adminAssesmentData.filter(
          (data: any) => data.id === currentAssessmentID
        );
        if (canShowOverview) {
          dispatch(actionDetailsSaveData(lastRowData[0]));
        }
        if (showDetails) {
          dispatch(actionFetchAssesmentByIdData(lastRowData[0].id));
        }
      }
    }
  }, [adminAssesmentData]);

  useEffect(() => {
    if (
      assesmentDetailData &&
      assesmentDetailData.hasOwnProperty("dynamicColumnHeaderList") &&
      firstTimeCall === false
    ) {
      setShowTabs(false);
      setCanShowOverview(false);
      setShowDetails(true);
    }
  }, [assesmentDetailData]);

  const functionAssesmentLevelData = (rowData: any) => {
    dispatch(actionDetailsSaveData(rowData));
    dispatch(actionFetchAssesmentLevelByIdData(rowData.id));
    setCurrentAssessmentID(rowData.id);
  };
  const functionEditAssesmentDetailData = (rowValues: any) => {
    setEditFormValues(rowValues);
    setAssesmentEditLable(rowValues);
    dispatch(actionFetchAssesmentByIdData(rowValues.id));
    setCurrentAssessmentID(rowValues.id);
    setCurrentRole(userRoleAsPerStatus(rowValues.assessmentStatusId));
  };

  const setAssesmentEditLable = (editValues: any) => {
    setSelectedAssessmentCode(editValues.assessmentCode);
    setSelectedSupplyChain(editValues.supplyChain);
    setSelectedScope(editValues.scope);
    setSelectedCountry(editValues.country);
    setSelectedSubRegion(editValues.countryHierarchyName);
    setSelectedAssessmentStatusId(editValues.assessmentStatusId);
    setSelectedAssessmentType(editValues.assessmentType)
    dispatch(actionSaveAssesmentDataSuccess(null));
  };

  const userRoleAsPerStatus = (id: any) => {
    if (
      id === ASSESSMENT.STATUS.DRAFT ||
      id === ASSESSMENT.STATUS.RE_ASSIGNED
    ) {
      return USER.ROLE.ASSESSOR;
    } else {
      return USER.ROLE.VALIDATOR;
    }
  };
  const checkAssessmentStatusLabel: any = (id: any) => {
    let statusLabel: string = "";
    switch (id) {
      case 1:
        statusLabel = t("assessment.assessorDraft");
        break;
      case 2:
        statusLabel = t("assessment.reAssigned");
        break;
      case 3:
        if (
          selectedRole.toLowerCase().trim() === USER.ROLE.ASSESSOR.toLowerCase()
        ) {
          statusLabel = t("assessment.sentForValidation");
        } else {
          statusLabel = t("assessment.pendingValidation");
        }
        break;
      case 4:
        statusLabel = t("assessment.validated");
        break;
      case 5:
        statusLabel = t("assessment.inProgressValidation");
        break;
    }
    return statusLabel;
  };

  useEffect(() => {
    dispatch(actionSetEditableScreen(false));
  }, [])

  const showAssesmentSetTable = (id: any) => {
    if (isUpdatedByOther) {
      setIsUpdatedByOther(false);
    }
    setShowTabs(true);
    setCanShowOverview(false);
    setShowDetails(false);
    // set redux editable screen as false
    dispatch(actionSetEditableScreen(false));
  };

  const exportReports = () => {
    // this function will used for export excel.
    const formData = {
      countries: currentCuntryArray,
      role: selectedRole,
      organization: userRoleData.organization,
      assessmentType: assessmentTypeFilter ? ASSESSMENT_TYPE_CONST.RESILIENCY : ASSESSMENT_TYPE_CONST.NORMAL
    };
    dispatch(actionExportAssesmentData(formData));
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setTabValue(newValue);
    setCanShowOverview(false);
  };
  const createTabHeader = (list: any) => {
    return list.map((item: any, i: any) => {
      return <Tab label={item.label} {...a11yProps(i)} key={i} />;
    });
  };
  const filterCountryData = (country: any) => {
    return adminAssesmentData.filter(
      (assesment: any) => assesment.countryId === country.id
    );
  };
  const createTabPanel = (list: any) => {
    return list.map((item: any, i: any) => {
      return (
        <TabPanel value={value} index={i} key={i}>
          <Typography component="div" className="marginM24">
            <RsaAssessmentTabComponent
              assesmentData={filterCountryData(item)}
              functionAssesmentLevelData={functionAssesmentLevelData}
              tabValue={tabValue}
              canShowOverview={canShowOverview}
              setCanShowOverview={setCanShowOverview}
              tabValueSelected={tabValueSelected}
              setTabValueSelected={setTabValueSelected}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
              functionEditAssesmentDetailData={functionEditAssesmentDetailData}
              setIsEditAssessment={false}
              setShowDetails={setShowDetails}
              setAssessmentTabValue={setAssessmentTabValue}
              assessmentTabValue={assessmentTabValue}
            />
          </Typography>
        </TabPanel>
      );
    });
  };

  return (
    <>
      <Container maxWidth={"xl"}>
        {showTabs && (
          <>
            <SubHeaderWrapper>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography component="span" className="common-label">
                    {`${t("assessment.allAssesments")} (${adminAssesmentData.length})`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Tooltip title={`${t("common.downloadReport")}`}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => exportReports()}
                        >
                          <DownloadIconCustom />
                          {t("common.exportToExcel")}
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SubHeaderWrapper>
            <div className={classes.root}></div>
            <BoxShadowWrapper>
              <ScrollableScmmAppBar position="static" className="border-bottom">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs"
                >
                  {createTabHeader(countriesList)}
                </Tabs>
              </ScrollableScmmAppBar>
              {createTabPanel(countriesList)}
            </BoxShadowWrapper>
            <AssessmentOverviewDetails
              canShowOverview={canShowOverview}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
            ></AssessmentOverviewDetails>
          </>
        )}
        {showDetails && (
          <>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="paddingTB15"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      color="default"
                      onClick={() => showAssesmentSetTable("")}
                      className="padding10"
                    >
                      <ArrowBackIosIconCustom className="arRotate180" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography
                      component="span"
                      className="common-label padding10-5"
                    >
                      {t("common.viewAssessment")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AssesmentQuestionTabsComponent
              showAssesmentDetailTable={showDetails}
              assesmentDetailData={assesmentDetailData}
              editFormValues={editFormValues}
              userId={null}
              isEditAssessment={false}
              selectedAssessmentCode={selectedAssessmentCode}
              selectedRegion={selectedRegion}
              selectedCountry={selectedCountry}
              selectedSupplyChain={selectedSupplyChain}
              selectedScope={selectedScope}
              selectedRole={currentRole}
              selectedAssessmentType={selectedAssessmentType}
              checkAssessmentStatusLabel={checkAssessmentStatusLabel}
              validationStatusData={listValidationStatus}
              loginUserData={loginUserData}
              isPendingValidation={isPendingValidation}
              setIsPendingValidation={setIsPendingValidation}
              selectedCountryIdList={[]}
              selectedAssessmentStatusId={selectedAssessmentStatusId}
              setIsValidateEnabled={false}
              setIsReAssignEnabled={false}
              setAreaOfWorkListCheck={[]}
              setTabDataCheck={[]}
              closeToasterParticularTimeInterval={false}
              openToaster={false}
              isDataCheck={false}
              setLoaderVisibility={setLoaderVisibility}
              setIsUpdatedByOther={setIsUpdatedByOther}
              selectedSubRegion={selectedSubRegion}
            ></AssesmentQuestionTabsComponent>
          </>
        )}
      </Container>
    </>
  );
}
